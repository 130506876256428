import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EnquiryCallsService {
  baseApiUrl = environment.baseApiUrl2;
  callerData = new BehaviorSubject<any>(null);
  endCall = new BehaviorSubject<any>(null);
  leadInfo = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);


  userData = JSON.parse(`${localStorage.getItem("userData")}`);

  constructor(
    private http: HttpClientService){}

  checkLead(from, to){
    return this.http.get(`${this.baseApiUrl}communication/leadByPhone/`+from+'/'+to)
  }

  updateIncomingCallDetails(body) {
    return this.http.post(`${this.baseApiUrl}communication/updateIncomingCallDetails`, body);
  }
}
