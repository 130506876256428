<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" [opened]="isShowing" position="end" class="pf w45 z-top"
    [hidden]="!isShowing" style="z-index: 1111 !important;">
    <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
        <div class="d-flex">
            <div class="close hand" (click)="toggleSideNav();"><span aria-hidden="true">&times;</span></div>
            <div class="modal-title mr-auto">Create Event</div>
        </div>
    </div>

    <div class="calendar-add-block" style="padding: 55px 25px;">

        <form [formGroup]="event_form">
            <div class="form-group">
                <label>Event Heading <span style="color: red;">*</span></label>

                <input class="form-control" type="text" placeholder="Type your Event name" required
                    formControlName="EventTitle" />
            </div>
            <div class="form-group">
                <label>Event Description <span style="color: red;">*</span></label>
                <textarea class="form-control sub-input" placeholder="Describe about Event" required
                    formControlName="EventDescription"></textarea>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="form-group">
                        <label>Add Members
                        </label>
                        <!-- <div style="float: right;">  <img style="cursor: pointer;" src="../../../assets/img/info.svg" title="Add upto 10 member mail id"> </div> -->

                        <div class="">
                            <div class="members-list">
                                <div class="add-member d-flex mr-2 mt-2 align-items-center"
                                    *ngFor="let item of membersEmail;let i = index">
                                    <div class="mr-2 d-flex mr-2 align-items-center justify-content-center">
                                    </div>
                                    <div class="member-name">{{item}}</div>
                                    <div class="member-close" style="cursor: pointer;" (click)="removeMemberEmails(i)">
                                        <img src="../../../assets/img/close.svg" class="img8">
                                    </div>
                                </div>

                            </div>
                            <div class="align-items-center" *ngIf="membersEmail && membersEmail.length < 10">

                                <input type="email" class="form-control" (keyup)="enterKey($event)"
                                    placeholder="Enter Members mail id" formControlName="Members" />
                            </div>
                            <div style="clear:both"></div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <label>Member's Mobile Number
                        </label>
                        <div class="row">
                            <div class="col-md-3 pr-2">
                                <div class="form-group">
                                    <button type="button" class="form-control dropdown-toggle" style="width:60px"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        +<span class="country-code">{{ selectedCountryCode }}</span>
                                    </button>

                                    <ul class="form-control dropdown-menu">
                                        <div>
                                            <input type="search" (input)="onInput($event)" formControlName="CountryCode"
                                                class="form-control search-all"
                                                style="height: auto; padding: 4px 12px;width: 93%;margin: 0 9px;"
                                                placeholder="Search " autocomplete="off" />
                                            <li *ngFor="let country of countries" style="cursor: pointer;">
                                                <span href="#" (click)="selectCountry(country)">
                                                    <img src="{{ country.flag || country.flags.svg || country.flags.png }}"
                                                        alt="" style="height: 15px;width: 20px;"
                                                        (click)="selectCountry(country)">
                                                    <span style="padding-left: 8px" (click)="selectCountry(country)">{{
                                                        country.name }} (+{{
                                                        country.callingCodes[0] }})</span>
                                                </span>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-9 pl-0">
                                <div class="form-group ml-2">
                                    <input type="text" class="form-control" formControlName="MobileNumber"
                                        placeholder="Type One Member's mobile number" />
                                </div>
                            </div>
                        </div>


                        <!-- <input type="text" class="form-control" required formControlName="mobile_no"
                        placeholder="Type your mobile number" /> -->

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-3">
                    <div class="form-group">
                        <label>Start Date <span style="color: red;">*</span></label>
                        <!-- <div class="form-control"> -->
                        <input type="date" [min]="startMinDate" class="form-control" placeholder="MM/DD/YYYY"
                            formControlName="StartDate" (change)="selectedStartDate($event)" />


                        <!-- </div> -->
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <div class="form-group">
                        <label>From Time <span style="color: red;">*</span></label>
                        <input type="time" list="times" class="form-control" required placeholder="From Time"
                            formControlName="StartTime" (change)="selectedStartTime($event)" />
                        <datalist id="times">
                            <option value="{{item.time}}" *ngFor="let item of startTimeDataset">
                        </datalist>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <div class="form-group">
                        <label>End Date <span style="color: red;">*</span></label>
                        <div class="form-control">
                            {{selectedDate | date:'medium'}}

                        </div>
                        <!-- <input type="date" readonly [min]="endMinDate" class="form-control" required placeholder="End Date"
                            formControlName="EndDate" /> -->
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <div class="form-group">
                        <label>To Time <span style="color: red;">*</span></label>
                        <!-- <div class="form-control"> -->
                        <input type="time" list="endtimes" class="form-control" required placeholder="To Time"
                            formControlName="EndTime" />
                        <datalist id="endtimes">
                            <option value="{{item.time}}" *ngFor="let item of endTimeDataset">
                        </datalist>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <label>Event Type <span style="color: red;">*</span></label>
                    <div class="form-group">
                        <select class="form-control" required formControlName="EventType">
                            <option [ngValue]="null" [disabled]="true">Select Event type
                            </option>
                            <option value="General Meetings">General Meetings</option>
                            <option value="Conference">Conference</option>
                            <option value="Board Meeting">Board Meeting</option>
                            <option value="Client Meeting">Client Meeting</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <label>Meeting Location <span style="color: red;">*</span></label>
                    <div class="form-group">
                        <select class="form-control" required formControlName="EventLocation">
                            <option [ngValue]="null" [disabled]="true">Select Location</option>
                            <option value="Google Meet" [disabled]="true">Google Meet</option>
                            <option value="MicroSoft Teams" [disabled]="true">MicroSoft Teams</option>
                            <option value="Zoom">Zoom</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <label>Your Message</label>
                    <div class="form-group">
                        <textarea class="form-control sub-input" placeholder="Start typing.."
                            formControlName="Message"></textarea>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-md-6 col-sm-6">

            </div>
            <div class="col-md-6 col-sm-6 text-right">
                <button class="btn btn-light btn-sm mr-2" (click)="toggleSideNav();">Cancel</button>

                <button class="btn btn-primary btn-sm" (click)="addEvent();">Create</button>
            </div>
        </div>
    </div>
</mat-sidenav>
<div class="gbg mt-3 ml-2 mr-2">
    <div class="container-fluid">
        <div class="row mb-2">

            <div class="col-md-3">
                <app-project-change (projectChange)="projectChange($event)"></app-project-change>
            </div>
            <div class="ml-auto text-right d-flex">

                <div class="mr-2">
                    <button (click)="show()" class="btn btn-outline-secondary btn-sm"
                        style="border:0">Invitation</button>
                    <div id="myDropdown" class="dropdown-content dropdown-menu-right">

                        <div style="position:relative">
                            <div style="position:absolute;right:5px;top:5px;" (click)="show()">
                                <img src="../../../assets/img/close.svg" class="img11 hand">
                            </div>
                        </div>
                        <a class="float-left cursor" (click)="copyText()"> <i class="far fa-clone"></i> Copy
                            Link</a>
                        <a class="float-left cursor" (click)="showShareForm()" data-toggle="modal"
                            data-target="#shareDetails"><i class="far fa-paper-plane"></i> Send invitation</a>


                    </div>
                </div>

                <div class="mr-3">
                    <mat-sidenav-container>
                        <mat-sidenav-content>
                            <button class="btn btn-primary btn-sm" (click)="toggleSideNav();resetForm()">
                                <img class="img12" src="../../../assets/img/add-w.svg" />
                            </button>
                        </mat-sidenav-content>
                    </mat-sidenav-container>
                </div>
            </div>
        </div>
        <!-- Calendar Module starts -->
        <div class="row mb-3">
            <!--Upcoming Events starts-->
            <div class="col-md-4 col-sm-4">
                <div class="calendar_upcoming-events p-3" style="height: 690px !important;overflow: auto;">
                    <div class="mb-3">
                        <div class="header-12">Upcoming events</div>
                        <div class="sub-text">Don’t miss scheduled events</div>
                    </div>

                    <div class="event-block mb-2 p-3" *ngFor="let item of upcomingEventList;">
                        <div class="float-right">
                            <img style="cursor: pointer;" src="../../../assets/img/info.svg" data-toggle="modal"
                                data-target="#showEventModal" (click)="showEventDetails(item)">
                        </div>
                        <div class="d-flex align-items-center mb-2">

                            <div class="purple-dot mr-1"></div>
                            <div class="purple-text">
                                {{item.StartTime}}-{{item.EndTime}}

                            </div>

                        </div>
                        <div class="event-block-head txt-oflow-head">{{item.EventTitle}}</div>
                        <p class="subtext2 txt-oflow-desc">{{item.EventDescription}}</p>
                    </div>
                    <div class="event-block mb-2 p-3" *ngIf="!upcomingEventList.length">
                        <div class="event-block-head">
                            <div class="d-flex flex-column text-center">
                                <div><img src="../../../assets/images/no-events.svg" class="img50 mb-2"></div>
                                <div>No upcoming event for today!</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="event-block mb-2 p-3">
                        <div class="d-flex align-items-center mb-2">
                            <div class="yellow-dot mr-1"></div>
                            <div class="yellow-text">12:00-14:00</div>
                        </div>
                        <div class="event-block-head txt-oflow-head">Design new pages</div>
                        <p class="subtext2 txt-oflow-desc">Design new pages for the website</p>
                    </div>

                    <div class="event-block mb-2 p-3">
                        <div class="d-flex align-items-center mb-2">
                            <div class="red-dot mr-1"></div>
                            <div class="red-text">9:00-13:00</div>
                        </div>
                        <div class="event-block-head txt-oflow-head">Design new UI and check sales</div>
                        <p class="subtext2 txt-oflow-desc">Find new pages and check analytics</p>
                    </div> -->


                </div>
            </div>
            <!--Upcoming Events Ends-->

            <!--Calendar view starts-->

            <div class="col-md-8 col-sm-8">
                <div class="calendar_compo p-3">
                    <!-- <div *ngIf="isLoading ==true">
                        <td colspan="8">
                            <div class="norecords">
                                <img src="../../../../assets/img/waiting.gif" />

                                <div>Loading Data<br />Please wait while we fetch the data for
                                    you</div>
                            </div>
                        </td>
                    </div> -->

                    <div id="ec" class="col"></div>

                </div>
            </div>
            <!--Calendar view ends-->
        </div>

        <!-- <div class="row py-2">
            <div class="col-md-6 col-sm-6">
                <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#update-event">Update Event</button>
            </div>
        </div> -->
        <hr class="hr-style my-4">

        <!--Confirmed ends -->

        <!-- Modal for Create Event starts-->
        <div class="modal fade" id="createEventModal" tabindex="-1" role="dialog"
            aria-labelledby="createEventModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="width:650px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createEventModalLabel">Create Event</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body ">
                        <div class="p-2">
                            <form [formGroup]="event_form">
                                <div class="form-group">
                                    <label>Event Heading <span style="color: red;">*</span></label>

                                    <input class="form-control heading-18-input" type="text" placeholder="Event heading"
                                        required formControlName="EventTitle" />
                                </div>
                                <div class="form-group">
                                    <label>Event Description <span style="color: red;">*</span></label>
                                    <textarea class="form-control sub-input" placeholder="Event Describe" required
                                        formControlName="EventDescription"></textarea>
                                </div>
                                <div class="form-group">
                                    <label>Add Members <span style="color: red;">*</span></label>
                                    <!-- <div class="form-group">
                                        <input type="text" class="form-control" required
                                            placeholder="Enter Members mail id" formControlName="Members" />
                                    </div> -->
                                    <div class="">
                                        <div class="members-list">
                                            <div class="add-member d-flex mr-2 mt-2 align-items-center"
                                                *ngFor="let item of membersEmail;let i = index">
                                                <div class="mr-2 d-flex mr-2 align-items-center justify-content-center">
                                                </div>
                                                <div class="member-name">{{item}}</div>
                                                <div class="member-close" style="cursor: pointer;"
                                                    (click)="removeMemberEmails(i)">
                                                    <img src="../../../assets/img/close.svg" class="img8">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="mt-2 align-items-center">

                                            <input type="text" class="form-control" (keyup)="enterKey($event)"
                                                placeholder="Enter Members mail id" formControlName="Members" />
                                        </div>
                                        <div style="clear:both"></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-sm-3">
                                        <div class="form-group">
                                            <label>Start Date <span style="color: red;">*</span></label>
                                            <!-- <div class="form-control"> -->
                                            <input type="date" class="form-control" placeholder="Start Date"
                                                formControlName="StartDate" />
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3">
                                        <div class="form-group">
                                            <label>From Time <span style="color: red;">*</span></label>
                                            <!-- <div class="form-control"> -->
                                            <input type="time" class="form-control" required placeholder="From Time"
                                                formControlName="StartTime" />
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3">
                                        <div class="form-group">
                                            <label>End Date <span style="color: red;">*</span></label>
                                            <!-- <div class="form-control"> -->
                                            <input type="date" class="form-control" placeholder="End Date"
                                                formControlName="EndDate" />
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3">
                                        <div class="form-group">
                                            <label>To Time <span style="color: red;">*</span></label>
                                            <!-- <div class="form-control"> -->
                                            <input type="time" class="form-control" required placeholder="To Time"
                                                formControlName="EndTime" />
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-sm-4">
                                        <label>Event Type <span style="color: red;">*</span></label>
                                        <div class="form-group">
                                            <select class="form-control" required formControlName="EventType">
                                                <option [ngValue]="null" [disabled]="true">Select Event type
                                                </option>
                                                <option value="General Meetings">General Meetings</option>
                                                <option value="Conference">Conference</option>
                                                <option value="Board Meeting">Board Meeting</option>
                                                <option value="Client Meeting">Client Meeting</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <label>Add Location <span style="color: red;">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" required
                                                placeholder="Enter Location" formControlName="EventLocation" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <label>Your Message</label>
                                        <div class="form-group">
                                            <textarea class="form-control sub-input" placeholder="Start typing.."
                                                formControlName="Message"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row">
                                <div class="col-md-6 col-sm-6">

                                </div>
                                <div class="col-md-6 col-sm-6 text-right">
                                    <button class="btn btn-light btn-sm mr-2" data-dismiss="modal">Cancel</button>

                                    <button class="btn btn-primary btn-sm" (click)="addEvent()">Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal for Create Event ends-->
        <!-- Modal for Update Event -->
        <div class="modal fade" id="update-event" tabindex="-1" role="dialog" aria-labelledby="createEventModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="width:650px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createEventModalLabel">Update Event</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body ">
                        <div class="p-2">
                            <div class="form-group" style="margin-bottom: 0;">
                                <input class="form-control heading-18-input" style="padding: 0;border:0" type="text"
                                    value="Meeting with a client" />
                            </div>
                            <div class="form-group">
                                <textarea class="form-control sub-input" style="padding: 0;border:0"
                                    placeholder="">Sample content They define what is the problem, who are the users, and what are the constraints and timeframes. Personally, the smaller these requirements meetings, the better, for instance.</textarea>
                            </div>
                            <div class="form-group">
                                <label>Add Members</label>
                                <div class="">
                                    <div class="members-list">
                                        <div class="add-member d-flex mr-2 align-items-center">
                                            <div class="circle mr-2 d-flex align-items-center justify-content-center">JK
                                            </div>
                                            <div class="member-name">Krishna Jakkamsetti</div>
                                            <div class="member-close">
                                                <img src="../../../assets/img/close.svg" class="img8">
                                            </div>
                                        </div>
                                        <div class="add-member d-flex mr-2 align-items-center">
                                            <div
                                                class="circle mr-2 d-flex mr-2 align-items-center justify-content-center">
                                                VS</div>
                                            <div class="member-name">Vidya Sagar</div>
                                            <div class="member-close ml-auto">
                                                <img src="../../../assets/img/close.svg" class="img8">
                                            </div>
                                        </div>
                                        <div class="add-member d-flex mr-2 align-items-center">
                                            <div
                                                class="circle mr-2 d-flex align-items-center justify-content-center align-items-center">
                                                SK</div>
                                            <div class="member-name">Srinivas Kandula</div>
                                            <div class="member-close ml-auto">
                                                <img src="../../../assets/img/close.svg" class="img8">
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <button class="btn btn-outline-secondary btn-sm">
                                                <img src="../../../assets/img/add-g.svg" />
                                            </button>
                                        </div>
                                    </div>
                                    <div style="clear:both"></div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label>Date</label>
                                        <div class="form-control">
                                            02/08/2022
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label>From Time</label>
                                        <div class="form-control">
                                            02:30 PM
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label>Date</label>
                                        <div class="form-control">
                                            02/08/2022
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label>From Time</label>
                                        <div class="form-control">
                                            04:30 PM
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4 col-sm-4">
                                    <label>Event Type</label>
                                    <div class="form-group">
                                        <select class="form-control">
                                            <option>
                                                <div class="purple-dot mr-1 mr-2"></div>General Meetings
                                            </option>
                                            <option>Conference</option>
                                            <option>Board Meeting</option>
                                            <option>Client Meeting</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <label>Add Location</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Location" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <label>Your Message</label>
                                    <div class="form-group">
                                        <textarea class="form-control sub-input"
                                            placeholder="Start typing.."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6"></div>
                                <div class="col-md-6 col-sm-6 text-right">
                                    <button class="btn btn-light btn-sm mr-2">Cancel</button>
                                    <button class="btn btn-primary btn-sm">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="showEventModal" tabindex="-1" role="dialog" aria-labelledby="showEventModal"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="width:650px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showEventModal">Event Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body event-view " *ngIf="showEventDetail">
                        <div class="pl-2 pr-2 pt-1 pb-2">
                            <div class="form-group pb-3">
                                <div class="d-flex">
                                    <div>
                                        <div class="form-control heading-18-input" style="padding-left: 0;border:0">
                                            {{showEventDetail.EventTitle}}
                                            <p class="sub-input mb-2" style="padding: 0;border:0">
                                                {{showEventDetail.EventDescription}}
                                            </p>
                                        </div>
                                    </div>

                                    <!-- <div class="ml-auto mr-2">
                                        <div style="width:50px;">
                                            <img src="../../../assets/img/universityhub.jpeg" style="width:40px">
                                        </div>
                                    </div> -->
                                </div>
                            </div>

                            <div class="form-group mt-2">
                                <label><b>Members</b></label>
                                <div class="form-group">

                                 
                                    <p class="form-control sub-input" style="padding: 0;border:0">
                                      {{showEventDetail.Members}}</p>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>Start Date</b></label>
                                        <div class="form-control">
                                            {{showEventDetail.StartDate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>From Time</b></label>
                                        <div class="form-control">
                                            {{showEventDetail.StartTime}}

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>End Date</b> </label>
                                        <div class="form-control">
                                            {{showEventDetail.EndDate}}

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>To Time</b></label>
                                        <div class="form-control">
                                            {{showEventDetail.EndTime}}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Event Type</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.EventType || '--'}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Meeting Location</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.EventLocation || '--'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="showEventDetail.meetingDetails!=null">
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Host Email</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.meetingDetails.HostEmail}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Start Time</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.meetingDetails.StartTime | date: "medium"}}</p>
                                    </div>

                                </div>
                            </div>
                            <div class="row" *ngIf="showEventDetail.meetingDetails!=null">
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Meeting Link</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            <a href="{{showEventDetail.meetingDetails.StartLink}}" target="_blank"
                                                [ngStyle]="{
                                                'pointer-events': checkMettingLinkDuration(showEventDetail)
                                               }">Start
                                                Meeting</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Password</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.meetingDetails.Password}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <label><b>Your Message</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.Message}}</p>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6">

                                </div>
                                <div class="col-md-6 col-sm-6 text-right">
                                    <!-- <button class="btn btn-light btn-sm mr-2" data-dismiss="modal">Cancel</button> -->

                                    <!-- <button class="btn btn-primary btn-sm" (click)="addEvent()">Create</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="shareDetails" tabindex="-1" role="dialog" aria-labelledby="shareDetails"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="width:650px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="shareDetails">Sender Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="p-2">
                            <form action="" [formGroup]="share_event_details">
                                <!-- <div class="form-group">
                                    <textarea class="form-control sub-input" style="padding: 0;border:0" placeholder=""
                                        formControlName="Message"></textarea>
                                </div> -->
                                <div class="form-group">
                                    <label>Add Members <span style="color: red;">*</span>
                                    </label>
                                    <!-- <div style="float: right;">  <img style="cursor: pointer;" src="../../../assets/img/info.svg" title="Add upto 10 member mail id"> </div> -->

                                    <div class="">
                                        <div class="members-list mt-1">
                                            <div class="add-member d-flex mr-2 mt-1 mb-2 align-items-center"
                                                *ngFor="let item of shareMembersEmail;let i = index">
                                                <div class="mr-2 d-flex mr-2 align-items-center justify-content-center">
                                                </div>
                                                <div class="member-name">{{item}}</div>
                                                <div class="member-close" style="cursor: pointer;"
                                                    (click)="removeShareMemberEmails(i)">
                                                    <img src="../../../assets/img/close.svg" class="img8">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="align-items-center">

                                            <input type="email" class="form-control" (keyup)="enterKeyShare($event)"
                                                placeholder="Enter Members mail id" formControlName="Members" />
                                        </div>
                                        <div style="clear:both"></div>
                                    </div>
                                </div>

                            </form>
                            <div class="row">
                                <div class="col-md-6 col-sm-6">

                                </div>
                                <div class="col-md-6 col-sm-6 text-right">
                                    <button class="btn btn-light btn-sm mr-2" data-dismiss="modal">Cancel</button>

                                    <button class="btn btn-primary btn-sm" (click)="shareDetails()">Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal " id="myModal" aria-labelledby="myModal" aria-hidden="true" tabindex="-1" role="dialog"
            [style.display]="showModal ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content" style="width:650px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="myModal">Event Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="showModal=false">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body event-view ">
                        <div class="p-2">
                            <div class="form-group mb-0" style="overflow: auto;">
                                <p class="form-control heading-18-input pl-0" style="border:0">
                                    {{showEventDetail.EventTitle}}</p>
                            </div>
                            <div class="form-group" style="overflow: auto;">
                                <p class="form-control sub-input" style="padding: 0;border:0">
                                    {{showEventDetail.EventDescription}}</p>
                            </div>


                            <div class="form-group">
                                <label><b>Members</b></label>
                                <div class="form-group">
                                    <p class="form-control sub-input" style="padding: 0;border:0">
                                        {{showEventDetail.Members || '--'}}</p>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>Start Date</b></label>
                                        <div class="form-control">
                                            {{showEventDetail.StartDate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>From Time</b></label>
                                        <div class="form-control">
                                            {{showEventDetail.StartTime}}

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>End Date</b> </label>
                                        <div class="form-control">
                                            {{showEventDetail.EndDate}}

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3">
                                    <div class="form-group">
                                        <label><b>To Time</b></label>
                                        <div class="form-control">
                                            {{showEventDetail.EndTime}}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Event Type</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.EventType || '--'}}</p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Meeting Location</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.EventLocation || '--'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="showEventDetail.meetingDetails!=null">
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Host Email</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.meetingDetails.HostEmail}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Start Time</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.meetingDetails.StartTime | date: "medium"}}</p>
                                    </div>

                                </div>
                            </div>
                            <div class="row" *ngIf="showEventDetail.meetingDetails!=null">
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Meeting Link</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">

                                            <a href="{{showEventDetail.meetingDetails.StartLink}}" target="_blank"
                                                [ngStyle]="{
                                                'pointer-events': checkMettingLinkDuration(showEventDetail)
                                               }">Start
                                                Meeting </a>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <label><b>Password</b></label>
                                    <div class="form-group">
                                        <p class="form-control sub-input" style="padding: 0;border:0">
                                            {{showEventDetail.meetingDetails.Password}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                </div>
                                <div class="col-md-6 col-sm-6 text-right">
                                    <!-- <button class="btn btn-light btn-sm mr-2" data-dismiss="modal"
                                        (click)="showModal=false">Cancel</button> -->

                                    <!-- <button class="btn btn-primary btn-sm" (click)="addEvent()">Create</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal for Update Event ends -->
        <div class="p-5"></div>
    </div>
</div>