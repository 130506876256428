<div class="m-3 d-flex align-items-center">
    <div class="header-12">{{is_realTimeReport ? 'Realtime Analytics overview (Last 30 Minutes Record)':'Analytics overview'}}</div>
    <div class="pr-0 ml-auto" *ngIf="!is_realTimeReport" (click)="hideDate()">
        <select class="form-control cdd" id="" [(ngModel)]="filterValue"
            (change)="updateDateFilter($event)">
            <option value="all">All</option>
            <option value="24hrs">24 hrs</option>
            <option value="72hrs">72 hrs</option>
            <option value="7days">07 days</option>
            <option value="14days">14 days</option>
            <option value="30days">30 days</option>
            <option value="custom">Custom</option>
          </select>
    </div>
    
    <div *ngIf="showDate" class="datePicker">
        <div class="custom-date" style="background: #fff; height: 74px; border: 1px solid #ccc" >
          <mat-form-field class="mr-3">
            <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
              (dateInput)="getStartDate('input', $event)" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="mr-3">
            <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
              (dateInput)="getEndDate('input', $event)" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
          </mat-form-field>
          <button class="btn btn-secondary btn-sm" (click)="filterData()">
            Submit
          </button>
        </div>
    </div>

      
</div> 
<div class="px-3" *ngIf="!is_realTimeReport">
    <div class="g-card-full">
        <div class="card">
            <div class="card-body">
                <div class="g-card-top d-flex align-items-center">
                    <div class="mr-1">
                        <select class="form-control header-12 pl-1" (change)="onChangeUsers($event,'country')">
                            <option value="activeUsers">Users</option>
                            <option value="newUsers">New Users</option>
                            <!-- <option>Returning Users</option> -->
                        </select>
                    </div>
                    <div class="header-12">by</div> 
                    <div class="mx-1 header-12">Country</div>
                </div>
                <div class="d-flex">
                    <div style="width:50%">
                        <div *ngIf="loadingMainReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                            <div class="norecords">
                                <img class="loadingData" src="../../../../assets/img/waiting.gif" />
                  
                                <div>
                                  Loading Data<br />Please wait while we fetch the data for you
                                </div>
                              </div>
                        </div>
                        <div  id="Geo_map" style="width: 500px; height: 400px;"></div>
                    </div>
                    <div style="width:50%" class="overflowtable">
                        <table class="table table-md table-borderless table-md" style="width:400px;margin:0 auto">
                            <tr class="border-bottom border-top">
                                <th class="header-12">Country</th>
                                <th class="header-12 text-right">Users</th>
                            </tr>
                            <tbody *ngFor="let item of countryReport">
                                <tr [ngbPopover]="popContent"
                                    (mouseenter)="popover_header='USERS';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                                    container="body"
                                    triggers="mouseenter:mouseleave">
                                    <th>{{item.dimensionValues[0].value}}</th>
                                    <td class="text-right">{{item.metricValues[0].value}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="py-0">
                                        <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>
                                    </td>
                                </tr>
                            </tbody>
                        
                        </table>
                        <div *ngIf="loadingMainReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                            <div class="norecords">
                                <img class="loadingData" src="../../../../assets/img/waiting.gif" />
                  
                                <div>
                                  Loading Data<br />Please wait while we fetch the data for you
                                </div>
                              </div>
                        </div>
                        <div *ngIf="countryReport && !countryReport.length && loadingMainReport != true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                            <p style="padding: 15px;"> No data available</p>
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
    </div>
    
    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Users in last 30 minutes
                    </div>
                </div>
                <div >
                    <ngx-charts-bar-vertical
                    [view]="view"
                    [scheme]="colorScheme"
                    [results]="ViewData"
                    [gradient]="gradient">
                    </ngx-charts-bar-vertical>

                </div>
              <div >
                <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto" *ngIf="loadingCountryReport != true">
                    <tr class="border-bottom border-top">
                        <th class="header-12">Top Countries</th>
                        <th class="header-12 text-right">Users</th>
                    </tr>
                    <tbody *ngFor="let item of countryRealTimeReport">
                        <tr [ngbPopover]="popContent1"
                            (mouseenter)="popover_header='USERS';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                            container="body"
                            triggers="mouseenter:mouseleave">
                        <!-- triggers="mouseenter:mouseleave" -->
                            <th >{{item.dimensionValues[0].value}}</th>
                            <td class="text-right" ngbPopover="You see, I show up on hover!"  popoverTitle="Pop title">{{item.metricValues[0].value}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="py-0">
                                <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
                <div *ngIf="loadingCountryReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <div class="norecords">
                        <img class="loadingData" src="../../../../assets/img/waiting.gif" />
          
                        <div>
                          Loading Data<br />Please wait while we fetch the data for you
                        </div>
                      </div>
                </div>
                <div *ngIf="countryRealTimeReport && !countryRealTimeReport.length && loadingCountryReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <p style="padding: 15px;"> No data available</p>
                </div>
              </div>
              <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link" (click)="getRealTimeRecords(1)">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div>
            </div>
            </div>
        </div>
    </div>

    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Users by AUDIANCE
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 overflowtable">
                <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto" >
                    <tr class="border-bottom border-top">
                        <th class="header-12">AUDIANCE</th>
                        <th class="header-12 text-right">Users</th>
                    </tr>
                    <tbody *ngFor="let item of audienceReport">
                        <tr [ngbPopover]="popContent"
                            (mouseenter)="popover_header='AUDIANCE';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                            container="body"
                            triggers="mouseenter:mouseleave">
                            <th>{{item.dimensionValues[0].value}}</th>
                            <td class="text-right">{{item.metricValues[0].value}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="py-0">
                                <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="loadingMainReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <div class="norecords">
                        <img class="loadingData" src="../../../../assets/img/waiting.gif" />
          
                        <div>
                          Loading Data<br />Please wait while we fetch the data for you
                        </div>
                      </div>
                </div>
                <div *ngIf="audienceReport && !audienceReport.length && loadingMainReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <p style="padding: 15px;"> No data available</p>
                </div>
                </div>
     
              <!-- <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div> -->
            </div>
        </div>
        </div>
    </div>

    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Users by CITY
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 overflowtable">
                    <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto">
                        <tr class="border-bottom border-top">
                            <th class="header-12">Country</th>
                            <th class="header-12 text-right">Users</th>
                        </tr>
                        <tbody *ngFor="let item of cityReport">
                            <tr [ngbPopover]="popContent"
                                (mouseenter)="popover_header='USERS';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                                container="body"
                                triggers="mouseenter:mouseleave">
                                <th>{{item.dimensionValues[0].value}}</th>
                                <td class="text-right">{{item.metricValues[0].value}}</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="py-0">
                                    <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="loadingMainReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                        <div class="norecords">
                            <img class="loadingData" src="../../../../assets/img/waiting.gif" />
            
                            <div>
                            Loading Data<br />Please wait while we fetch the data for you
                            </div>
                        </div>
                    </div>
                    <div *ngIf=" cityReport && !cityReport.length && loadingMainReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                        <p style="padding: 15px;"> No data available</p>
                    </div>
                </div>
     
              <!-- <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div> -->
            </div>
        </div>
        </div>
    </div>

    <!-- <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Views by Page title and screen class
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 ">
                <table class="table table-md table-borderless table-md" style="width:400px;margin:0 auto">
                    <tr class="border-bottom border-top">
                        <th class="header-12">Page title and screen cla..
                        </th>
                        <th class="header-12 text-right">Views</th>
                    </tr>
                    <tr>
                        <td>Admin Portal - Campbellsville</td>
                        <td class="text-right">752</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="py-0">
                            <div class="progress">
                                <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Accessque</td>
                        <td class="text-right">50</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="py-0">
                            <div class="progress">
                                <div class="progress-bar w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Referral</td>
                        <td class="text-right">10</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="py-0">
                            <div class="progress">
                                <div class="progress-bar w-10" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                        </td>
                    </tr>
                </table>
            </div>
     
              <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div> -->
    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Views by Event Name and Event Count
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 overflowtable">
                <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto">
                    <tr class="border-bottom border-top">
                        <th class="header-12">EVENT NAME
                        </th>
                        <th class="header-12 text-right">EVENT COUNT</th>
                    </tr>
                    <tbody *ngFor="let item of eventNameReport">
                        <tr [ngbPopover]="popContent"
                            (mouseenter)="popover_header='EVENT COUNT';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                            container="body"
                            triggers="mouseenter:mouseleave">
                            
                            <th>{{item.dimensionValues[0].value}}</th>
                            <td class="text-right">{{item.metricValues[0].value}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="py-0">
                                <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="loadingMainReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <div class="norecords">
                        <img class="loadingData" src="../../../../assets/img/waiting.gif" />
          
                        <div>
                          Loading Data<br />Please wait while we fetch the data for you
                        </div>
                      </div>
                </div>
                <div *ngIf="eventNameReport && !eventNameReport.length && loadingMainReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <p style="padding: 15px;"> No data available</p>
                </div>
            </div>
     
              <!-- <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div> -->
            </div>
        </div>
        </div>
    </div>
</div>
<div class="px-3" *ngIf="is_realTimeReport">
    
    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Users in last 30 minutes
                    </div>
                </div>
                <div >
                    <ngx-charts-bar-vertical
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="ViewData"
                        [gradient]="gradient">
                    </ngx-charts-bar-vertical>
                </div>
              <div>
                <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto" *ngIf="loadingCountryReport != true">
                    <tr class="border-bottom border-top">
                        <th class="header-12">Top Countries</th>
                        <th class="header-12 text-right">Users</th>
                    </tr>
                    <tbody *ngFor="let item of countryRealTimeReport">
                        <tr [ngbPopover]="popContent1"
                            (mouseenter)="popover_header='USERS';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                            container="body"
                            triggers="mouseenter:mouseleave">
                            <th>{{item.dimensionValues[0].value}}</th>
                            <td class="text-right">{{item.metricValues[0].value}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="py-0">
                                <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="loadingCountryReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <div class="norecords">
                        <img class="loadingData" src="../../../../assets/img/waiting.gif" />
          
                        <div>
                          Loading Data<br />Please wait while we fetch the data for you
                        </div>
                      </div>
                </div>
                <div *ngIf="countryRealTimeReport && !countryRealTimeReport.length && loadingCountryReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <p style="padding: 15px;"> No data available</p>
                </div>
                <div class="d-flex mb-2 p-ab">
                    <div class="ml-auto">
                        <a class="card-link" (click)="getRealTimeRecords(2)">View Report
                            <span>
                                <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                            </span>
                        </a>
                    </div>
                </div>
              </div>
             
            </div>
            </div>
        </div>
    </div>

    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Users by AUDIANCE
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 overflowtable">
                <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto" *ngIf="loadingAudianceReport != true">
                    <tr class="border-bottom border-top">
                        <th class="header-12">AUDIANCE</th>
                        <th class="header-12 text-right">Users</th>
                    </tr>
                    <tbody *ngFor="let item of audienceRealTimeReport">
                        <tr [ngbPopover]="popContent1"
                            (mouseenter)="popover_header='AUDIANCE';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                            container="body"
                            triggers="mouseenter:mouseleave">
                            <th>{{item.dimensionValues[0].value}}</th>
                            <td class="text-right">{{item.metricValues[0].value}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="py-0">
                                <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="loadingAudianceReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <div class="norecords">
                        <img class="loadingData" src="../../../../assets/img/waiting.gif" />
          
                        <div>
                          Loading Data<br />Please wait while we fetch the data for you
                        </div>
                      </div>
                </div>
                <div *ngIf="audienceRealTimeReport && !audienceRealTimeReport.length && loadingAudianceReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <p style="padding: 15px;"> No data available</p>
                </div>
                </div>
     
             
            </div>
        </div>
        </div>
    </div>
    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Users by CITY
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 overflowtable">
                <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto" *ngIf="loadingCityReport != true">
                    <tr class="border-bottom border-top">
                        <th class="header-12">Country</th>
                        <th class="header-12 text-right">Users</th>
                    </tr>
                    <tbody *ngFor="let item of cityRealTimeReport">
                        <tr [ngbPopover]="popContent1"
                            (mouseenter)="popover_header='USERS';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                            container="body"
                            triggers="mouseenter:mouseleave">
                            <th>{{item.dimensionValues[0].value}}</th>
                            <td class="text-right">{{item.metricValues[0].value}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="py-0">
                                <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="loadingCityReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <div class="norecords">
                        <img class="loadingData" src="../../../../assets/img/waiting.gif" />
          
                        <div>
                          Loading Data<br />Please wait while we fetch the data for you
                        </div>
                      </div>
                </div>
                <div *ngIf="cityRealTimeReport && !cityRealTimeReport.length && loadingCityReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <p style="padding: 15px;"> No data available</p>
                </div>
                </div>
     
              <!-- <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div> -->
            </div>
        </div>
        </div>
    </div>

    <!-- <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Views by Page title and screen class
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 ">
                <table class="table table-md table-borderless table-md" style="width:400px;margin:0 auto">
                    <tr class="border-bottom border-top">
                        <th class="header-12">Page title and screen cla..
                        </th>
                        <th class="header-12 text-right">Views</th>
                    </tr>
                    <tr>
                        <td>Admin Portal - Campbellsville</td>
                        <td class="text-right">752</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="py-0">
                            <div class="progress">
                                <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Accessque</td>
                        <td class="text-right">50</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="py-0">
                            <div class="progress">
                                <div class="progress-bar w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Referral</td>
                        <td class="text-right">10</td>
                    </tr>
                    <tr>
                        <td colspan="2" class="py-0">
                            <div class="progress">
                                <div class="progress-bar w-10" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                        </td>
                    </tr>
                </table>
            </div>
     
              <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div> -->
    <div class="g-card">
        <div class="card">
            <div class="card-body">
                <div class="c-height">
                <div class="g-card-top d-flex align-items-center p-2">
                    <div class="header-12">
                        Views by Event Name and Event Count
                    </div>
                </div> 
                <div class="px-2 pt-3 pb-2 overflowtable">
                <table class="table table-md table-borderless table-md" style="width:100%;margin:0 auto" *ngIf="loadingEventReport != true">
                    <tr class="border-bottom border-top">
                        <th class="header-12">EVENT NAME
                        </th>
                        <th class="header-12 text-right">EVENT COUNT</th>
                    </tr>
                    <tbody *ngFor="let item of eventNameRealTimeReport">
                        <tr [ngbPopover]="popContent1"
                            (mouseenter)="popover_header='EVENT COUNT';popover_title=item.dimensionValues[0].value;popover_value=item.metricValues[0].value"
                           
                            triggers="mouseenter:mouseleave">
                            <th>{{item.dimensionValues[0].value}}</th>
                            <td class="text-right" >{{item.metricValues[0].value}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="py-0">
                                <progress id="file" class="progressBar"  value="{{item.metricValues[0].value}}" max="100">  </progress>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="loadingEventReport == true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <div class="norecords">
                        <img class="loadingData" src="../../../../assets/img/waiting.gif" />
          
                        <div>
                          Loading Data<br />Please wait while we fetch the data for you
                        </div>
                      </div>
                </div>
                <div *ngIf="eventNameRealTimeReport && !eventNameRealTimeReport.length && loadingEventReport!=true" style="text-align: center;font-size: 17px;border-top: 1px solid #dee2e6;">
                    <p style="padding: 15px;"> No data available</p>
                </div>
            </div>
     
              <!-- <div class="d-flex mb-2 p-ab">
                <div class="ml-auto">
                    <a class="card-link">View realtime
                        <span>
                            <img src="../../../../assets/img/arrow_forward.svg" class="img15 mx-1">
                        </span>
                    </a>
                </div>
            </div> -->
            </div>
        </div>
        </div>
    </div>
</div>
<div class="clr"></div>

<!-- For space only -->
<div class="p-4"></div>
<!-- For space only -->
<ng-template #popContent>
    <div class="h-card">
        <div class="">
            <p>{{payload.startDate|date:'medium'}} - {{payload.endDate|date:'medium'}}</p>
            <p style="text-transform:uppercase">{{popover_header}}</p>
            <div class="d-flex">
                <div class="txt-oflow"><b>{{popover_title}} </b></div>
                <div class="ml-auto l185"><b>{{popover_value}}</b></div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #popContent1>
    <div class="h-card">
        <div class="">
            <p>In Last 30 Minutes</p>
            <p style="text-transform:uppercase">{{popover_header}}</p>
            <div class="d-flex">
                <div class="txt-oflow"><b>{{popover_title}} </b></div>
                <div class="ml-auto l185"><b>{{popover_value}}</b></div>
            </div>
        </div>
    </div>
</ng-template>