import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CreateAgent } from './pages/create-agent/create-agent.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  userData: any
  snackBarRef:any
  constructor(
    private agentService: CreateAgent,
    private router: Router,
    private readonly snackbarRef: MatSnackBar,
  ) { }

  handleError(error) {
    this.userData = JSON.parse(localStorage.getItem("userData"));
   
    if (error.status === 401) {
      if (this.userData) {
        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Offline",
            fcmtoken: this.userData.fcmtoken,
            token: this.userData.token,
          })
          .subscribe((data) => {
           });
           this.snackbarRef.open("Already logged in on another device",'', {
            verticalPosition: "top",
            horizontalPosition: "end",
            duration: 3000,
            panelClass: ["error-snackbar"],
          });
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["/account/login"]);
      }
      else{
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["/account/login"]);
      }
    }
  }
}
