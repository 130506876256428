import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddUpdateViewTicketsComponent } from './add-update-view-tickets/add-update-view-tickets.component';

const routes: Routes = [
  { path: 'tickets/ticket-view', component: AddUpdateViewTicketsComponent, canActivate: [AuthGuard] },
  { path: 'tickets/ticket-view/:id', component: AddUpdateViewTicketsComponent, canActivate: [AuthGuard] },
  { path: 'tickets/ticket-views/:phoneNumber', component: AddUpdateViewTicketsComponent, canActivate: [AuthGuard] },
  { path: 'tickets/ticket-views/:phoneNumber/:email/:name', component: AddUpdateViewTicketsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketsRoutingModule { }
