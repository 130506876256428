import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "textpipe",
})
export class TextpipePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    switch (value) {
      case "notinterested":
        return "Not Intrerested";
      case "notanswered":
        return "Not Answered";
      case "pending":
        return "Pending";
      case "callback":
        return "Call Back";
      // case "interested":
      //   return "Qualified Lead";
      case "qualifiedhotlead":
        return "Qualified Hot lead";
      case "futurelead":
        return "Future Lead";
      case "callfailed":
        return "Call Failed";
      case "poorenglish":
        return "Poor English";
      case "interestedpending":
        return "Interested Pending";
      case "duplicate":
        return "Duplicate";
      case "Website":
        return "All";
      default:
        return value;
    }
    return null;
  }
}
