import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { MomentFormatService } from 'src/app/moment-format-service';
import { SharedService } from 'src/app/shared/shared.service';
import { UhLeadsService } from '../../uhleads.service';
declare const google: any;

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrls: ['./google-analytics.component.css']
})
export class GoogleAnalyticsComponent implements OnInit {
  eventNameReport:any;
  countryReport:any;
  cityReport:any;
  audienceReport:any;

  eventNameRealTimeReport:any;
  countryRealTimeReport:any;
  cityRealTimeReport:any;
  audienceRealTimeReport:any;

  loadingMainReport:any;
  loadingEventReport: boolean = false;
  loadingCountryReport: boolean = false;
  loadingCityReport: boolean = false;
  loadingAudianceReport: boolean = false;
  filterValue: string = 'all';

  payload:any={
    from:'',
    for:'activeusers',
    startDate:new Date(Date.now() - 864e5 * 365),
    endDate:new Date(Date.now() - 864e5)
  }

  reportInterval:any
  is_realTimeReport:Boolean=false
  ViewData:any=[]
  view: any[] = [400, 100];
  gradient = false;
  colorScheme = {
    domain: ['#1a73e8']
  };
  showButton: boolean = false;
  startDate: any;
  endDate: any;
  showDate: boolean = false;
  date1: any;
  date2: any;

  popover_header:any
  popover_title:any
  popover_value:any
  constructor(
    public uhleadsservice: UhLeadsService,
    public sharedService: SharedService,
    private momentService: MomentFormatService,
    private handleError: ErrorHandlerService,
  ) { 
   if(this.reportInterval){
    clearInterval(this.reportInterval)
   }
    google.charts.load('current', {
      'packages':['geochart'],
    });
  }

  ngOnInit(): void {
    this.sharedService.pageEvent.emit({
      pageName: "Analytics",
    });
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.getRunReport()
    this.getCountryWiseReport()
    this.getRealTimeCountryReportChart()
    setInterval(()=>{
      this.getRealTimeCountryReportChart()
    },60000)
    
  }
  getRunReport(){
    
    this.loadingMainReport=true
    this.uhleadsservice.getRunReports(this.payload).subscribe((resp:any)=>{
      if(resp.statusText==true){
        let reportData = resp.response.reports
        this.countryReport=reportData[0].rows
        this.audienceReport=reportData[1].rows
        this.eventNameReport=reportData[2].rows
        this.cityReport=reportData[3].rows
        this.setGooglChart(this.countryReport)
      
      }
      this.loadingMainReport=false

      // this.runReport = resp.reports[0].rowCount>0?resp.reports[0].rows:[]
      // 
    }, (error) => {
      
      this.handleError.handleError(error)
    });
  }
  getEventnameWiseReport(){
    this.loadingEventReport = true;
    this.payload.from='eventName'
    this.uhleadsservice.getRealTimeEentNameReport().subscribe((resp:any)=>{
      this.eventNameRealTimeReport = resp && resp.rowCount>0?resp.rows:[]
      this.loadingEventReport = false;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getCountryWiseReport(){
    this.payload.from='counrty'
    this.loadingCountryReport = true;
    this.uhleadsservice.getRealTimeCountryReport().subscribe((resp:any)=>{
      this.countryRealTimeReport = resp && resp.rowCount>0?resp.rows:[]
   
      
      this.loadingCountryReport = false;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getCityWiseReport(){
    this.payload.from='city'
    this.loadingCityReport = true;
    this.uhleadsservice.getRealTimeCityReport(this.payload).subscribe((resp:any)=>{
      this.cityRealTimeReport = resp && resp.rowCount>0?resp.rows:[]
      this.loadingCityReport = false;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getAudianceWiseReport(){
    this.payload.from='audienceName'
    // this.payload.for='activeusers'
    this.loadingAudianceReport = true;
    this.uhleadsservice.getRealTimeAudienceNameReport().subscribe((resp:any)=>{
      this.audienceRealTimeReport = resp && resp.rowCount>0?resp.rows:[]
      this.loadingAudianceReport = false;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  onChangeUsers(Event:any,from:any){
    if(from==='country'){
      this.payload.for=Event.target.value
      this.getRunReport();
    }
  }

  getRealTimeRecords(_for?:any){
    if(_for==1){
      this.is_realTimeReport=true
      if(!this.reportInterval){
        this.getEventnameWiseReport()
        this.getCountryWiseReport()
        this.getCityWiseReport()
        this.getAudianceWiseReport()
      }
      this.reportInterval = setInterval(()=>{
        this.getEventnameWiseReport()
        this.getCountryWiseReport()
        this.getCityWiseReport()
        this.getAudianceWiseReport()
      },60000)
    }
    else if(_for==2){
      this.getRunReport()
      this.is_realTimeReport=false
      clearInterval(this.reportInterval)
    }
  }
  //Country Realtime report chart
  getRealTimeCountryReportChart(){
    this.payload.from='counrty'
    this.loadingCountryReport = true;
    this.uhleadsservice.getRealTimeCountryReportChart(this.payload).subscribe((resp:any)=>{
      
      let data =  resp && resp.rowCount>0?resp.rows:[]
      let tempViewData=[]
      for (let j = 29; j > 0; j--) {
        for (let i = 0; i < data.length; i++) {
          if(j==data[i].dimensionValues[1].value){
              tempViewData.push(
              {
                "name": "Users "+j+" mins Ago",
                "value": data[i].metricValues[0].value
              })
            }
          else{
            tempViewData.push(
              {
                "name": "Users "+j+" mins Ago",
                "value":0
              })
          }
        }
      }
      
      this.ViewData=tempViewData
      Object.assign(this,  {tempViewData} )

      this.loadingCountryReport = false;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  setGooglChart(Data?:any){
    
   
    let googlDataTable=[['Country','Users']]
    Data.forEach((Element:any)=>{
      let name=Element.dimensionValues[0].value
      let value=parseInt(Element.metricValues[0].value)
      googlDataTable.push([Element.dimensionValues[0].value,value])
    });

    google.charts.setOnLoadCallback(drawRegionsMap);
    
    function drawRegionsMap() {
      var data = google.visualization.arrayToDataTable(googlDataTable);

      var options = {
        colorAxis: {colors: ['#aecbfa','#174ea6']}
      };

      var chart = new google.visualization.GeoChart(document.getElementById('Geo_map'));

      chart.draw(data, options);
    }
  }
  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.payload.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.payload.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );
      this.payload.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
       this.payload.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
       this.payload.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
       this.payload.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
       this.payload.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
       this.payload.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    if(!this.showDate){
      this.getRunReport()
      this.getCountryWiseReport()
      this.getRealTimeCountryReportChart()    
    }
  }
  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }
  filterData() {
    // this.onClick(null);
    this.payload.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.payload.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.payload.endDate < this.payload.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.getRunReport()
    this.getCountryWiseReport()
    this.getRealTimeCountryReportChart()    
      this.showDate = false;
    }
  }
   hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }
}
