import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  baseApiUrl = environment.baseApiUrl2;
  userData = JSON.parse(`${localStorage.getItem("userData")}`);

  constructor(
    private http: HttpClientService){}

  getSounds(data) {
    return this.http.post(`${this.baseApiUrl}listOfSounds`,data);
  }

  updateNotificationAlert(data){
    return this.http.put(`${this.baseApiUrl}sounds/updateRingTone`,data);
  }

  updateSubscription(data){
    return this.http.post(`${this.baseApiUrl}notification_subscribe`,data);
  }
}
