import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";
import { HttpParams } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class RecruiterleadsService {

  baseApiUrl = environment.SMSapiurl;
  baseApiUrl2 = environment.baseApiUrl2;
  baseApiUrl3 = environment.baseApiUrl;
  webAppUrl = environment.webAppUrl;

  callStatusRecruiter = new BehaviorSubject<any>(null);
  startCallRecruiter = new BehaviorSubject<any>(null);
  stopCallRecruiter = new BehaviorSubject<any>(null);
  startTimerRecruiter = new BehaviorSubject<any>(null);
  getCallSidRecruiter = new BehaviorSubject<any>(null);
  conferenceCallRecruiter = new BehaviorSubject<any>(null);

  public unreadCountRecBehaviorSubject = new BehaviorSubject<any>(null);
  public unreadCountRecObservable = this.unreadCountRecBehaviorSubject.asObservable();
  public viewReceivedLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public viewReceivedLeadObservable = this.viewReceivedLeadBehaviorSubject.asObservable();
  public addLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public addLeadObservable = this.addLeadBehaviorSubject.asObservable();

  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
    CallStatus: [""],
  });

  addLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
  });
  universitiesList = [
    {
      id: 1,
      name: 'University of West Los Angeles',
      terms: ['Spring', 'Spring-2', 'Summer', 'Fall']
    },
    {
      id: 2,
      name: 'St Francis College',
      terms: ['Spring', 'Summer', 'Fall', 'Spring-1', 'Spring-2', 'Summer-1', 'Summer-2', 'Fall-1', 'Fall-2'],
    },
    {
      id: 3,
      name: 'Campbellsville University',
      terms: ['Spring-1', 'Spring-2', 'Summer-1', 'Summer-2', 'Fall-1', 'Fall-2'],
    },
    {
      id: 5,
      name: 'Anderson University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 6,
      name: 'University of the Cumberlands',
      terms: ['Spring', 'Summer', 'Fall', 'Spring-1', 'Spring-2', 'Summer-1', 'Summer-2', 'Fall-1', 'Fall-2'],
    },
    {
      id: 7,
      name: 'Adelphi University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 8,
      name: 'Cumberland University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 9,
      name: 'Golden Gate University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 10,
      name: 'Faulkner University',
      terms: ['Spring', 'Summer', 'Fall', 'Spring-1', 'Spring-2', 'Summer-1', 'Summer-2', 'Fall-1', 'Fall-2'],
    },
    {
      id: 11,
      name: 'University of Toledo',
      terms: ['Spring', 'Summer', 'Fall', 'Spring-1', 'Spring-2', 'Summer-1', 'Summer-2', 'Fall-1', 'Fall-2'],
    },
    {
      id: 12,
      name: 'Marshall University',
      terms: ['Spring', 'Summer', 'Fall'],
    },
    {
      id: 13,
      name: 'University of Lousville',
      terms: ['Spring', 'Summer', 'Fall'],
    },
  ];
  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) { }


  //new
  getLeadsRecruiter(data) {
    return this.http.post(`${this.baseApiUrl3}getUniversityDashboardLeadsRecruiter`, data);
  }

  getTopBarCountRecruiter(obj) {
    return this.http.post(`${this.baseApiUrl3}getUniversityTopBarCountsRecruiter`, obj);
  }

  getLeadRecruiter(id) {
    return this.http.post(`${this.baseApiUrl3}getUhubDashboardLeadRecruiter`, { id: id });
  }

  assignLeadsRecruiter(data) {
    return this.http.post(`${this.baseApiUrl3}assignUhubLeadRecruiter`, data);
  }

  getCountByCallStatusRecruiter(data) {
    return this.http.post(`${this.baseApiUrl3}getCountByCallStatus`, data);
  }
  getUhubSearchDashboardLeadsRecruiter(data) {
    return this.http.post(`${this.baseApiUrl3}getUhubSearchDashboardLeadsRecruiter`, data);
  }
  
  getSmsRecruiter(id) {
    return this.http.get(`${this.baseApiUrl2}communication/uHubSMS/` + id);
  }


  sendSmsRecruiter(data, projectId) {
    return this.http.post(`${this.baseApiUrl}` + projectId + `/messaging-adapter/sendSMS`, data);
  }
  uploadDocumentRecruiter(data) {
    return this.http.post(`${this.baseApiUrl2}addCustomDocUploadLeads`, data);
  }
  uploadDocument1Recruiter(data, docId) {
    return this.http.post(`${this.baseApiUrl2}UploadDocumentLeads`, data);
  }
  deleteCustomDocumentRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}deleteCustomDocNameLeads`, data
    );
  }
  updateValidationStatusRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl2}DocumentValidation2Leads`,
      data
    );
  }
  getRecruiterDetailsRecruiter(data) {
    return this.http.post(
      `${this.webAppUrl}recruiter/getRecruityerDetailsCRM`, data
    );
  }
  getWebappStudentDetailsRecruiter(data) {
    const url: string = this.webAppUrl + 'student/getStudentLoginDetail?contactId='+data;
    return this.http.get(url);
  }
  counsellingCompletedRecruiter(data) {
    // return this.http.post(
    //   `${this.baseApiUrl2}wp/university/counsellingCompleted`,
    //   data
    // );

  }
  sendUniMailRecruiter(data) {
    return this.http.post(
      `${this.baseApiUrl3}leadUniversityAttachment`, data
    );
  }
  uploadFileAndGetLinkRecruiter(data){
    const url = `${this.baseApiUrl2}UploadDocumentV2`;
    return this.http.post(url,data)
  }
  getAllUniversityRecruiter() {
    const url = `${this.baseApiUrl2}/universitryhub/listOfUniversities`;
    return this.http.get(url);
  }
  sendNotificationRecruiter(data) {
    const url = `${this.baseApiUrl2}/wp/university/recruiter/sendNotification`;
    return this.http.post(url, data);
  }
  updateTransferCustomDocRecruiter(data) {
    const url = `${this.baseApiUrl2}/uhub/updateTransferCustomDoc`;
    return this.http.post(url, data);
  }
  
  getLeadInfoUsingUniversityId(uuid){
    const url = `${this.baseApiUrl2}wp/university/getLeadDetailsByUniversityId`;
    return this.http.post(url, {
      uuid: uuid
    });
  }
  getDocNameByDocId(data) {
    const url = `${this.baseApiUrl2}/getDocNameByDocId`;
    return this.http.post(url, data);
  }
}
