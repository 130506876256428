<div class="text-left mt-2" style="padding: 0 2rem;">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="containers">
  <div class="content-containers mt-3" style="margin: 0 30px">
    <input
      type="file"
      (change)="convertFile($event)"
      style="display: none"
      #uploadFile
      accept=".xlsx"
    />
    <!-- <span>telemarketing</span> -->
    <button
      ngbTooltip="Attachments"
      placement="top"
      tooltipClass="my-custom-class"
      class="btn btn-secondary btn-sm float-left"
      (click)="openFileUpload()"
    >
      <img src="../../../../assets/img/attach-w.svg" class="img13" />
    </button>
    <div style="margin-left: 20px; float: left">
      <input
        type="text"
        class="form-control"
        style="
          height: 16px;
          padding: 0px;
          background: none;
          font-size: 13px;
          color: #999;
          border: 0;
        "
        [ngClass]="{ 'is-valid': fileData.length !== 0 }"
        [(ngModel)]="filePath"
        placeholder=""
      />
      <div class="valid-feedback" *ngIf="!error">
        {{ numberOfRecords + " " + "Inserted Successfully" }}
      </div>
      <div style="color: red; font-size: 12px" *ngIf="error">
        {{ errorMessage }}
      </div>
    </div>

    <!-- <div class="valid-feedback">Looks good!</div> -->

    <div style="float: right">
      <a
        ngbTooltip="Download Sample"
        placement="top"
        tooltipClass="my-custom-class"
        href="{{ downloadLink }}"
        class="btn btn-secondary btn-sm"
        ><img src="../../../../assets/img/download-w.svg" class="img13"
      /></a>
    </div>
    <div style="clear: both"></div>
  </div>
  <div class="right-content-right-box-bottom-container">
    <div class="bottom-content-box-header-container">
      <div class="ticket-requested-list-container">
        <div class="requsted-list-heading-container">
          <div class="table-scroll data-table">
            <table class="table table-md">
              <thead style="position: relative">
                <tr>
                  <td colspan="8" style="padding: 8px">
                    <div class="table-search-content">
                      <div class="indived-search">
                        <input
                          type="text"
                          class="form-control search-all"
                          [(ngModel)]="search"
                          placeholder="Search..."
                          style="padding-left: 15px"
                        />
                      </div>
                      <div class="pagenation-right d-flex">
                        <!-- placeholder="select value" -->
                        <select
                          class="form-select mx-2 form-select-dark"
                          [(ngModel)]="statusValue"
                          (change)="dropdownSelect()"
                          
                        >
                          <option value="" disabled selected>
                            Select your option
                          </option>
                          <option>Information</option>
                          <option>Feedback</option>
                        </select>
                        <ngb-pagination
                          [(page)]="page"
                          [pageSize]="pageSize"
                          [collectionSize]="callData.length"
                          [maxSize]="5"
                          [boundaryLinks]="true"
                          class="pagenation-right"
                        ></ngb-pagination>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr style="background: #eaf5fd; border-bottom: none">
                  <th>
                    Phone
                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('RecipientNumber')"
                    />
                  </th>
                  <th>
                    Caller Id

                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('CallerId')"
                    />
                  </th>
                  <th>
                    Type
                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('Type')"
                    />
                  </th>
                  <th >
                    Call Answered

                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('CallAnswered')"
                    />
                  </th>
                  <th>
                    Module

                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('Module')"
                    />
                  </th>
                  <th>
                    Redial Count

                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('RedialCounter')"
                    />
                  </th>
                  <th>
                    FeedBack

                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('Feedback')"
                    />
                  </th>
                  <th>
                    Scheduled Time

                    <img
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer; margin-left: 10px"
                      (click)="sortOrder('ScheduledCallTimeUTC')"
                    />
                  </th>
                </tr>

                <tr
                  *ngFor="
                    let item of callData
                      | filter: search
                      | slice: (page - 1) * pageSize:page * pageSize
                      | sort: orderFeild:orderString;
                    let id = index
                  "
                >
                  <td class="id-col-mod">
                    <span> {{ item["RecipientNumber"] }}</span>
                  </td>
                  <td>{{ item["CallerId"] || "-" }}</td>
                  <td>{{ item["Type"] || "-" }}</td>
                  <td>{{ item["CallAnswered"] || "No" }}</td>
                  <td>{{ item["Module"] || "-" }}</td>
                  <td>{{ item["RedialCounter"] || 0 }}</td>
                  <td>
                    {{
                      item["Feedback"]
                        ? (item["NumberConfig"] | valuehelper: item["Feedback"])
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      item["ScheduledCallTimeUTC"] != null
                        ? (item["ScheduledCallTimeUTC"]
                          | date: "medium")
                        : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
