import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransfersService } from '../transfers.service';

@Component({
  selector: 'app-pagination-custom',
  templateUrl: './pagination-custom.component.html',
  styleUrls: ['./pagination-custom.component.css']
})
export class PaginationCustomComponent implements OnInit {
  pageNo: Number = 1;
  @Input() totalCount: any;
  totalPages: Number;
  @Output() currPage = new EventEmitter();
  onPageChange(pageNo: number) {
    this.pageNo = pageNo;
    // this.currPage.next(this.pageNo);
    this.transferService.pageNumber.next(this.pageNo);
  }

  constructor(private transferService: TransfersService) { }

  ngOnInit(): void {
    this.transferService.totalPages.subscribe((resp: any)=>{
      this.totalPages = resp
    })
    this.onPageChange(1);
    document.getElementById("firstPage").click();
  }

}
