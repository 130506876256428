import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attachments'
})
export class AttachmentsPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    const attachments=value.split(".");
    switch(attachments[attachments.length-1]){
      case "pdf":
        return "pdf";
      case "docx":
        return "word";
      case "zip":
        return "zip"
      case "xlsx":
        return "excel"
      case "png":
        return "image"
      case "jpeg":
        return "image"
      default:
        return "word"
    }
    return null;
  }

}
