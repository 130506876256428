import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  OnDestroy,
  ViewChildren,
  ElementRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { SharedService } from "src/app/shared/shared.service";
import { UhLeadsService } from "../uhleads.service";
import * as xlsx from "xlsx";
import { DOCUMENT } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { LayoutService } from "src/app/layout/layout.service";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { MomentFormatService } from "src/app/moment-format-service";
import { post } from "jquery";
import { MarketingService, InputSMSData } from "../marketing/marketing.service";
import { RoleService } from "../role-management/role.service";
import { CreateAgent } from "../create-agent/create-agent.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { ReferreeService } from "../referree/referree.service";
var sortJsonArray = require("node_modules/sort-json-array");
import { Exceldata } from "../marketing/smsmarketing/models/ExecelData.model";
import { UhubDashboardService } from "./uhub-dashboard.service";
import { environment } from "src/environments/environment";
import { AbstractControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";

import { AuthService } from "src/app/account/auth/auth.service";
import { Subscription } from "rxjs";
import { ProjectService } from "../project-management/project.service";
import { DemopageComponent } from "../marketing/demopage/demopage.component";
import { NotificationService } from "@swimlane/ngx-ui";
import { NotificationsService } from "src/app/notification.service";
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
const countryCodes = require("../../../assets/allcountryDetails.json");
import { id } from "@swimlane/ngx-charts/utils/id";
import { TransfersService } from "../trasnfers/transfers.service";

@Component({
  selector: "app-uhub-dashboard",
  templateUrl: "./uhub-dashboard.component.html",
  styleUrls: ["./uhub-dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class UhubDashboardComponent implements OnInit {
  userData: any;
  projectId: any;
  admin: any;
  public error: boolean = false;
  public errorMessage: string = "";
  endDate: any;
  startDate: any;
  date1: any;
  date2: any;
  sideViewerData: any = { Activities: [], CallDetails: [] };
  currentLeadId: any;
  activityfilterString: string = "";
  minItems = 5;
  leadSearch: string = "";
  currentCard: string = "total";
  sortColumn = "createdAt";
  sortOrder = "des";
  smsText: string;
  smsList: [] = [];
  smsListWp: [] = [];
  projectLists: any;
  public filterValue = "all";
  showDate: boolean = false;
  filterString: string;
  LeadList: any[] = [];
  leadType: string = "total";
  cardleadType: string = "total";
  tab!: string;
  @ViewChild("uploadFile") uploadFile;
  @ViewChild("sendUniversityDoc") sendUniversityDoc;
  @ViewChild("leadNotAssigned") leadNotAssigned;
  @ViewChild("closeButton") closeButton;
  @ViewChild("closeButton2") closeButton2;
  @ViewChild("closeButtonCustom") closeButtonCustom;
  @ViewChild("closeButtonCustom5") closeButtonCustom5;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  @ViewChild("totalRecords", { static: true }) totalRecords: ElementRef;
  @ViewChild("sideNav", { static: true }) sideNav;
  topBarCount: {
    total: number;
    facebook: number;
    web: number;
    followup: number;
    pending: number;
    followupDate: number;
    counsellingpending: number;
    counsellingcompleted: number;
    interested: number;
    duplicate: number;
    enrolled: number;
    assignedToMe: number;
    TransferStudent: number;
    InternationalStudent: number;
  } = {
      total: 0,
      facebook: 0,
      web: 0,
      followup: 0,
      pending: 0,
      followupDate: 0,
      counsellingpending: 0,
      counsellingcompleted: 0,
      interested: 0,
      duplicate: 0,
      enrolled: 0,
      assignedToMe: 0,
      TransferStudent: 0,
      InternationalStudent: 0,
    };

  leadtoEdit: {
    LeadId: string;
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    University: string;
    CallStatus: string;
    AgentMail: string;
  };

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };

  private colors = [
    [{ bg: "blueviolet", color: "white" }],
    [{ bg: "AntiqueWhite", color: "white" }],
    [{ bg: "Coral", color: "white" }],
    [{ bg: "Crimson", color: "white" }],
    [{ bg: "DarkOrange", color: "white" }],
    [{ bg: "DeepPink", color: "white" }],
    [{ bg: "#c5924e", color: "white" }],
  ];

  DIyear: any;
  DIterms: any;
  DIyearArray: any = [];
  recruiterDetails: any;
  public intakeListArray: any = [];

  public filePath: any;
  public fileData: Exceldata[] = [];
  public uploaded: boolean = false;
  public insertCount: number = 0;
  public callData: any[] = [];
  public startCall: boolean = false;
  public stopCall: boolean = false;
  public startIndex: number = 0;
  public statusValue = "all";
  public clearTime: any;
  UhubList: any[] = [];
  UhubList1: any;
  allLeads: any;
  public fileSource = environment.fileServer;
  public downloadLink: string;
  public page: any = 1;
  public pageSize: any = 12;
  selectedAll: boolean = false;
  leadIds = [];
  dialerScreen1 = "dialer";
  callStatus: string = "Calling..";
  newStatus: any;
  selectedLead: any;
  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;
  leadsLoading: boolean = false;
  noLeadsFound: boolean = false;
  sidenavLoading: boolean = false;
  agentList: any;
  pageNumber: Number = 1;
  totalPages: Number = 0;
  callStatusFilter: String = "all";
  public currentLead: any;
  public isUpdateLead: boolean = false;
  public submitted: boolean = false;
  defaultAgent = "default";
  selectedAgent: any;
  totalAgents: any;
  agentName: any;
  GBPagentList: any;

  public search: string;
  isLoading: boolean = true;
  noRecords: boolean = false;

  file: File[] = [];
  customDocName: string;
  docUploadError: string;
  docUploadError2: string = "Select files to upload.";
  customFileList: File[] = [];
  customDocLeadId: any;
  selectedFiles: File[] = [];
  docUploadID: any;
  docUploadStatus: boolean = false;
  uploadSuccess: string = "none";
  documentsList2: any;
  validationStatus: boolean = false;
  documentComments: string;
  currentStatus: any;
  docUrl: any;
  currentItem: any;
  countries = [];
  selectedCountryCode = 1;
  selectedCountry = "";
  testConfig: countUpTimerConfigModel;
  comments: any;

  followUpdate: Date = new Date();

  updateLeadComments1: boolean = false;
  UhubCallSid: any;
  uniList: any[] = [];
  currentGUIDCommentsAttachment: any[] = [];
  transferCustomUniversity: any;
  transferCustomUniversityProg: any;
  uni: any;
  customDocNameCode: any;
  selectCountry(country) {
    this.selectedCountryCode = country.callingCodes[0];
    this.selectedCountry = country.alpha2Code;
    this.marketingService.createLead.get("countryCode").patchValue(this.selectedCountryCode)
  }
  allUniversities: any[] = [];
  currentProject: any;
  selectIndex = 0;
  customDocList: any[] = []

  constructor(
    private SharedService: SharedService,
    private momentService: MomentFormatService,
    private companyService: RoleService,
    private uhleadsservice: UhLeadsService,
    private ReferreeService: ReferreeService,
    public marketingService: MarketingService,
    private readonly handleError: ErrorHandlerService,
    private router: Router,
    public layoutService: LayoutService,
    public UhubDashboardService: UhubDashboardService,
    private readonly modalService: NgbModal,
    private readonly snackbarRef: MatSnackBar,
    private agentService: CreateAgent,
    private NotificationService: NotificationsService,
    private transferService: TransfersService,

    @Inject(DOCUMENT) private domService: HTMLDocument,
    public countupTimerService: CountupTimerService
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }

  ngOnInit(): void {
    this.followUpdate.setDate(this.followUpdate.getDate() + 2);
    this.fetchCountries();
    this.SharedService.pageEvent.emit({
      pageName: "University Hub Dashboard",
    });
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    this.getAgentNames();
    this.getListofUniversities();
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(moment(new Date(new Date(Date.now() - 864e5 * 365)).getTime()).startOf('day').toString());
    this.endDate = this.momentService.dashboardDateFormat(moment((
      new Date(new Date(Date.now() + 864e5))
    )).endOf('day').toString());

    this.userData = JSON.parse(localStorage.getItem("userData"));

    this.companyService.projectAdmin(this.userData["companyId"]).subscribe(
      (projects) => {
        this.projectId = projects.filter(
          (x) => x.Name == "University Hub"
        )[0].id;
        // this.projectId = data;
        if (this.projectId) {
          this.SharedService.getProjectAdmin(this.projectId).subscribe(
            (data: any) => {
              this.admin = data;
              setTimeout(() => {
                this.getTopBarCount();
                this.onClick(1);
                this.getUniversalRecords();
                // this.getLead();
              }, 500);

              this.uhleadsservice.pageNumber.subscribe((pageNo: any) => {
                this.pageNumber = pageNo;
                if (this.leadType != "filter") {
                  this.getLeadsByCards(this.cardleadType);
                } else if (this.leadType == "filter") {
                  let payload = {
                    admin: this.admin,
                    ProjectId: this.projectId,
                    start: "2020-08-02 09:40:25+00",
                    end: "2025-08-03 09:40:25+00",
                    page: this.pageNumber,
                    leadType: "All",
                    mail: "All",
                    callStatus: "All",
                    course: "All",
                    enrollmentYear: "All",
                    enrollmentTerm: "All",
                    export: false,
                    search: this.search,
                  };
                  this.searchLeads1(payload);
                }
              });
              this.layoutService.postComments.subscribe((data) => {
                if (data) {
                  this.postComments1(data.item, data.value);
                }
              });
              this.uhleadsservice
                .getAgentList(this.projectId, this.userData.id)
                .subscribe(
                  (data: any) => {
                    this.agentList = data;
                  },
                  (error) => {
                    this.handleError.handleError(error);
                  }
                );
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );

    this.marketingService
      .getExcelLink(this.fileSource + "/TemplateDocuments/accessque.xlsx")
      .subscribe(
        (data) => {
          this.downloadLink = data["url"];
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );

    let d = new Date();
    this.DIyearArray.push(d.getFullYear());
    let year = d.getFullYear();
    for (let i = 0; i < 4; i++) {
      year = year + 1;
      this.DIyearArray.push(year);
    }

    // server-side search

    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.pageNumber = 1;
          this.searchLeads(this.input.nativeElement.value, this.pageNumber);
        })
      )
      .subscribe();

    let stopCallsSub = this.layoutService.stopCalls.subscribe((data) => {
      if (data) {
        this.stopCallListener();
        this.startCall = false;
      }
    });
    this.UhubDashboardService.startTimer.subscribe((res) => {
      this.callStatus = "inProgress";
      this.startTimer();
    });
    this.layoutService.getCallSid.subscribe((sid: any) => {
      this.UhubCallSid = sid;
    });
    let redial = this.layoutService.redialContact.subscribe((data) => {
      if (data) {
        clearTimeout(this.clearTime);
      }
    });
    let callEndSub = this.layoutService.callEnd.subscribe((data) => {
      if (this.statusValue === "interested") {
        this.stopCall = true;
        this.startCall = false;
        return;
      }
      if (this.startCall && this.callData.length !== 0) {
      } else {
        this.startCall = false;
      }
    });
    this.layoutService.currentStatus.subscribe((data) => {
      this.currentStatus = data;
    });
    this.UhubDashboardService.callStatus.subscribe((status) => {
      this.callStatus = status;
    });

    this.UhubDashboardService.unreadCountRecObservable.subscribe((data) => {
      if (data) {
        this.LeadList.forEach(element => {
          if (Number(element.id) === Number(data.id)) {
            element.unreadCount = data.count;
          }
        });
      }
    });
    this.UhubDashboardService.viewReceivedLeadObservable.subscribe((data) => {
      if (data) {
        this.selectIndex = 0;
        setTimeout(() => {
          this.selectIndex = 2;
          this.sideNav.open();
          this.sideNavData({ id: data });
          setTimeout(() => {
            this.markNotificationRead(data);
          }, 1000);
        }, 500);
      }
    });
    this.UhubDashboardService.addLeadObservable.subscribe(async (data) => {
      if (data) {
        await this.getTopBarCount();
        await this.getLeads(
          this.admin,
          this.projectId,
          this.userData.mail,
          this.cardleadType,
          this.startDate,
          this.endDate,
          this.pageNumber,
          this.callStatusFilter
        );
        this.selectIndex = 2;
        await this.sideNavData({ id: data })
      }
    })
  }

  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }
  onInputActivity(event: any) {
    if (this.leadSearch != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }

  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let fromNumber = "";
    this.companyService.projectAdmin(environment.companyId).subscribe((res: any) => {
      this.projectLists = res;
      for (let i = 0; i < res.length; i++) {
        if (res[i].Name == "University Hub") {
          fromNumber = res[i].TwilioNumber;
        }
      }
      let payload = {
        text: this.smsText,
        from: fromNumber,
        to: "+" + item.CountryCode + item.PhoneNumber,
        AgentMail: this.userData.mail,
      };
      this.UhubDashboardService.sendSms(payload, this.projectId).subscribe(
        (res: any) => {
          this.uhleadsservice.showInfo("Message successfully sent.", "Sent");
          this.UhubDashboardService.getSms(this.currentLeadId).subscribe(
            (smsList: any) => {
              this.smsList = smsList.SMSDetails;
              (<HTMLInputElement>document.getElementById(HTMLid)).value = "";
            }
          );
        }
      );
    });
  }

  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(moment(
        new Date(new Date(Date.now())).getTime()
      ).endOf('day').toString());
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(moment((
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      )).startOf('day').toString());

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(moment((
        new Date(new Date(Date.now() - 864e5)).getTime()
      )).startOf('day').toString());

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(moment((
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      )).startOf('day').toString());

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(moment((
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      )).startOf('day').toString());

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(moment((
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      )).startOf('day').toString());

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(moment((
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      )).startOf('day').toString());

      this.showDate = false;
    }
    this.getTopBarCount();
    this.getLeads(
      this.admin,
      this.projectId,
      this.userData.mail,
      this.cardleadType,
      this.startDate,
      this.endDate,
      this.pageNumber,
      this.callStatusFilter
    );
    // write here
    // this.getReferralList(
    //   this.admin,
    //   this.projectId,
    //   this.userData.mail,
    //   "all",
    //   this.startDate,
    //   this.endDate
    // );
  }

  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }

  filterData() {
    // this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(moment((
      new Date(this.date2.value).getTime()
    )).startOf('day').toString());
    this.endDate = this.momentService.dashboardDateFormat(moment((
      new Date(this.date1.value).getTime()
    )).endOf('day').toString());

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.getTopBarCount();
      this.getLeadsByCards(this.cardleadType);
      this.getUniversalRecords();
    }
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(moment((
      new Date(this.startDate).getTime()
    )).startOf('day').toString());
  }

  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(moment((
      new Date(this.endDate).getTime()
    )).endOf('day').toString());
  }

  getLeadsByCards(cardValue) {
    this.search = '';
    this.filterString = "";
    this.LeadList = [];
    this.leadType = cardValue;
    this.statusValue = "all";
    this.callStatusFilter = "all";
    if (
      this.leadType == "pending" ||
      this.leadType == "followup" ||
      this.leadType == "interested"
    ) {
      this.callStatusFilter = this.leadType;
      this.statusValue = this.leadType;
    }
    this.getTopBarCount();
    this.getLeads(
      this.admin,
      this.projectId,
      this.userData.mail,
      cardValue,
      this.startDate,
      this.endDate,
      this.pageNumber,
      this.callStatusFilter
    );
  }

  onClick(check: any) {
    this.uhleadsservice.pageNumber.next(1);
    if (check) {
      this.tab = "tab" + check;
    } else {
      this.tab = "";
    }
  }

  convertFile(e) {
    this.filePath = e.target.files[0]["name"].toString();
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "First Name" && sheet_data.length !== 0) {
        this.fileData =
          this.marketingService.convert2dArraytoObject(sheet_data);
        this.marketingService
          .postCallData({
            data: this.fileData,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
            type: "Excel",
          })
          .subscribe(
            (data) => {
              this.uploaded = true;
              this.error = false;
              this.insertCount = data["count"];
              setTimeout(() => {
                this.uploaded = false;
                this.filePath = "";
              }, 5000);
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }

  redirectToUpload() {
    this.router.navigate(["./upload/" + this.projectId]);
  }

  startCallListener() {
    this.UhubDashboardService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
      type: 'uhub'
    });
  }

  followUpCall(data) {
    if (!this.startCall) {
      this.layoutService.marketingCall.next({
        id: data["id"],
        number: "+" + data["CountryCode"] + data["PhoneNumber"],
        name: data["FirstName"],
        email: data["Email"],
        comments: data["Comments"],
        module: data["Module"],
        city: data["city"],
        nextCaller: null,
        lead: data,
      });
      this.startCall = true;
    }
  }

  followUpCall1(data, item) {
    if (!this.startCall) {
      this.layoutService.marketingCallDocs.next({
        id: data["id"],
        number: "+" + data["CountryCode"] + data["PhoneNumber"],
        name: data["FirstName"],
        email: data["Email"],
        comments: data["Comments"],
        module: data["Module"],
        city: data["city"],
        nextCaller: null,
        lead: data,
        item: item,
      });
      this.startCall = true;
    }
  }

  startCallListener2(data, index) {
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);
    this.newStatus = false;
    this.callData[0] = data;
    this.selectedLead = data;
    this.marketingId = {
      id: data.id,
      status: data.CallStatus,
      name: data.FirstName,
      email: data.Email,
      phoneNumber: "+" + data.CountryCode + data.PhoneNumber,
      module: data.Module,
      city: "",
      nextCaller: this.LeadList.length > 1 ? 1 : 0,
      lead: 0,
    };
    if (this.LeadList.length > 1) {
      var nextFirstname = this.LeadList[index + 1]?.FirstName ? this.LeadList[index + 1]?.FirstName : '';
      var nextLastname = this.LeadList[index + 1]?.LastName ? this.LeadList[index + 1]?.LastName : '';
      var nextCallerCity = this.LeadList[index + 1]?.City ? this.LeadList[index + 1]?.City : '';
      var nextCallerCountryCode = this.LeadList[index + 1]?.CountryCode ? this.LeadList[index + 1]?.CountryCode.replace("+", "") : '';
      var nextCallerPhoneNumber = this.LeadList[index + 1]?.PhoneNumber ? this.LeadList[index + 1]?.PhoneNumber : '';

      this.nextCallerName = nextFirstname;
      this.nextCallerCity = nextCallerCity;
      this.nextCallerNumber = nextCallerCountryCode ? "+" + nextCallerCountryCode + nextCallerPhoneNumber : '';
      this.startIndex = index + 1;
    }

    this.UhubDashboardService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
      type: 'uhub'
    });
    this.startCall = true;
    this.patchEditValues(data);
  }
  stopCalls() {
    this.UhubDashboardService.startCall.next(null);
    this.UhubDashboardService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = "Call Ended";
  }
  stopCallListener() {
    this.stopCall = true;
    this.startCall = false;
    if (this.statusValue !== "pending") {
      this.startIndex += 1;
    }
    clearTimeout(this.clearTime);
  }

  public onInput(event: any) {
    if (this.search != "") {
    } else if (this.search == "") {
      this.totalRecords.nativeElement.click();
    }
  }

  searchLeads(value, page) {
    this.LeadList = [];
    this.noRecords = false;
    this.isLoading = true;
    let payload = {
      admin: this.admin,
      ProjectId: this.projectId,
      start: "2020-08-02 09:40:25+00",
      end: "2025-08-03 09:40:25+00",
      page: page,
      leadType: "All",
      mail: "All",
      callStatus: "All",
      course: "All",
      enrollmentYear: "All",
      enrollmentTerm: "All",
      export: false,
      search: value,
    };

    if (value.length > 0) {
      this.leadType = "filter";
      this.UhubDashboardService.getUhubSearchDashboardLeads(payload).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.totalResults <= 0) {
            this.noRecords = true;
          }
          let newTotal = ~~(Number(res.totalResults) / 12) + 1;
          this.uhleadsservice.totalPages.next(Number(newTotal));
          var test1 = res.data;
          var test2 = test1.map((obj) => ({
            ...obj,
            name: obj?.FirstName + " " + obj?.LastName,
          }));
          this.LeadList = test2;
          for (let i = 0; i <= this.LeadList.length; i++) {
            this.LeadList[i].unreadCount = 0;
            this.LeadList[i].AssignedtoName = this.getAgentName(
              this.LeadList[i].Assignedto
            );
            this.LeadList[i].AssignedtoInitial = this.getAgentInitial(
              this.LeadList[i].Assignedto
            );
            this.LeadList[i].AssignedtoColor = this.getAgentColor(
              this.LeadList[i].Assignedto
            );
            this.LeadList[i].AssignedtoImageURL = this.getAgentphoto(
              this.LeadList[i].Assignedto
            );
            if (this.LeadList[i].Channel == 'WebApp') {
              let name = this.LeadList[i].Miscellaneous.form_name;
              this.LeadList[i].RecruiterName = name;
              this.LeadList[i].Miscellaneous.form_name = 'Recruiter';
              this.LeadList[i].FormName = 'Recruiter';
            } else {
              this.LeadList[i].FormName = this.LeadList[i].Miscellaneous.form_name;
              this.LeadList[i].RecruiterName = '-';
            }
            if (this.LeadList[i].transferDetails.length > 0) {
              this.LeadList[i].UniversityCount = this.LeadList[i]?.transferDetails[0]?.universityInfo?.length || 0;
            } else {
              this.LeadList[i].UniversityCount = 0;
            }
          }

        },
        (error) => {
          this.isLoading = false;
          this.noRecords = true;
          this.handleError.handleError(error);
        }
      );
    } else {
      this.leadType = "all";
      this.cardleadType = 'all';
      this.getTopBarCount();
      this.getLeadsByCards("total");
    }
  }

  searchLeads1(payload) {
    this.LeadList = [];
    this.leadType = "filter";
    this.UhubDashboardService.getUhubSearchDashboardLeads(payload).subscribe(
      (res: any) => {
        let newTotal = ~~(Number(res.totalResults) / 12) + 1;
        this.uhleadsservice.totalPages.next(Number(newTotal));
        var test1 = res.data;
        var test2 = test1.map((obj) => ({
          ...obj,
          name: obj?.FirstName + " " + obj?.LastName,
        }));
        this.LeadList = test2;
        for (let i = 0; i < this.LeadList.length; i++) {
          this.LeadList[i].unreadCount = 0;
          this.LeadList[i].AssignedtoName = this.getAgentName(
            this.LeadList[i].Assignedto
          );
          this.LeadList[i].AssignedtoInitial = this.getAgentInitial(
            this.LeadList[i].Assignedto
          );
          this.LeadList[i].AssignedtoColor = this.getAgentColor(
            this.LeadList[i].Assignedto
          );
          this.LeadList[i].AssignedtoImageURL = this.getAgentphoto(
            this.LeadList[i].Assignedto
          );
          if (this.LeadList[i].Channel == 'WebApp') {
            let name = this.LeadList[i].Miscellaneous.form_name;
            this.LeadList[i].RecruiterName = name;
            this.LeadList[i].Miscellaneous.form_name = 'Recruiter'
          }
          // this.LeadList[i].FormName =
          //   this.LeadList[i].Miscellaneous.form_name;
        }

      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  dropdownSelect(event) {
    if (event == "all") {
      this.cardleadType = "total";
    } else {
      this.callStatusFilter = event;
      this.leadType = "dropdown";
      this.cardleadType = event;
      this.onClick(null);
    }
    this.uhleadsservice.pageNumber.next(1);
  }

  getTopBarCount() {
    var postData = {
      AgentMail: this.userData.mail,
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
    };

    this.UhubDashboardService.getTopBarCount(postData).subscribe(
      (res: any) => {
        if (this.leadType == "total") {
          this.totalPages = res.total;
        } else if (this.leadType == "facebook") {
          this.totalPages = res.facebook;
        } else if (this.leadType == "Website") {
          this.totalPages = res.web;
        } else if (this.leadType == "callback") {
          this.totalPages = res.followup;
        } else if (this.leadType == "pending") {
          this.totalPages = res.pending;
        } else if (this.leadType == "pending") {
          this.totalPages = res.pending;
        } else if (this.leadType == "interested") {
          this.totalPages = res.interested;
        } else if (this.leadType == "counsellingpending") {
          this.totalPages = res.counsellingpending;
        } else if (this.leadType == "counsellingcompleted") {
          this.totalPages = res.counsellingcompleted;
        } else if (this.leadType == "QualifiedLead") {
          this.totalPages = res.qualifiedLead;
        } else if (this.leadType == "QualifiedHotLead") {
          this.totalPages = res.qualifiedHotLead;
        } else if (this.leadType == "assignedto") {
          this.totalPages = res.assignedToMe;
        } else if (this.leadType == "InternationalStudent") {
          this.totalPages = res.InternationalStudent;
        } else if (this.leadType == "duplicate") {
          this.totalPages = res.duplicate;
        } else if (this.leadType == "enrolled") {
          this.totalPages = res.enrolled;
        } else if (this.leadType == "TransferStudent") {
          this.totalPages = res.TransferStudent;
        } else if (this.leadType == "dropdown") {
          this.UhubDashboardService.getCountByCallStatus({
            status: this.callStatusFilter,
            ProjectId: this.projectId,
            start: this.startDate,
            end: this.endDate,
          }).subscribe((count: any) => {
            this.totalPages = count.count;
            this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
            this.uhleadsservice.totalPages.next(this.totalPages);
          });
        }
        this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
        this.uhleadsservice.totalPages.next(this.totalPages);
        this.topBarCount = res;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getUniversalRecords() { }

  getLeads(admin, projectId, mail, leadType, start, end, page, callStatus) {
    this.isLoading = true;
    this.cardleadType = leadType;
    let payload = {
      admin: admin,
      mail: mail,
      ProjectId: projectId,
      leadType: leadType,
      start: start,
      end: end,
      page: page,
      callStatus: callStatus,
    };

    this.UhubDashboardService.getLeads(payload).subscribe(
      async (res: any) => {
        this.isLoading = false;
        var test1 = res.data;
        var test2 = test1.map((obj) => ({
          ...obj,
          name: obj.FirstName + " " + obj.LastName,
        }));
        this.LeadList = test2;
        this.callData = [...this.LeadList];

        for (let i = 0; i <= this.LeadList.length; i++) {
          this.LeadList[i].unreadCount = 0;
          this.LeadList[i].AssignedtoName = this.getAgentName(
            this.LeadList[i].Assignedto
          );
          this.LeadList[i].AssignedtoInitial = this.getAgentInitial(
            this.LeadList[i].Assignedto
          );
          this.LeadList[i].AssignedtoColor = this.getAgentColor(
            this.LeadList[i].Assignedto
          );
          this.LeadList[i].AssignedtoImageURL = this.getAgentphoto(
            this.LeadList[i].Assignedto
          );
          if (this.LeadList[i].Channel == 'WebApp') {
            let name = this.LeadList[i].Miscellaneous.form_name;
            this.LeadList[i].RecruiterName = name;
            this.LeadList[i].Miscellaneous.form_name = 'Recruiter';
            this.LeadList[i].FormName = 'Recruiter';
          } else {
            this.LeadList[i].FormName = this.LeadList[i].Miscellaneous.form_name;
            this.LeadList[i].RecruiterName = '-';
          }
          if (this.LeadList[i].transferDetails.length > 0) {
            this.LeadList[i].UniversityCount = this.LeadList[i]?.transferDetails[0]?.universityInfo?.length || 0;
          } else {
            this.LeadList[i].UniversityCount = 0;
          }
        }


        if (this.LeadList.length == 0) {
          this.noRecords = true;
        }
        this.callData = [...this.LeadList];
        this.sortTableData(this.sortColumn, this.sortOrder);

        this.LeadList.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
            a[key] === "" ? (a[key] = "-") : a[key];
          });
        });
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.LeadList.length; i++) {
        this.leadIds.push(this.LeadList[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  toggleSort(column, order) {
    if (this.sortOrder == "asc") {
      this.sortOrder = "des";
    } else {
      this.sortOrder = "asc";
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {
    var test1 = sortJsonArray(this.LeadList, this.sortColumn, this.sortOrder);
    var test2 = test1.map((obj) => ({
      ...obj,
      name: obj?.FirstName + " " + obj?.LastName,
    }));
    this.LeadList = test2;
  }

  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  openEditLead(content, lead) { }

  updateLead() { }

  sideNavData(data) {
    this.sideViewerData = [];
    this.sidenavLoading = true;
    this.activityfilterString = "";
    this.getCustomDocList(data);
    this.minItems = 5;
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.UhubDashboardService.getLead(data.id).subscribe(
      (res: any) => {
        this.UhubDashboardService.getSms(data.id).subscribe((smsList: any) => {
          this.smsList = smsList.SMSDetails;
          this.sideViewerData = res[0];
          this.getCustomList();
          if (this.sideViewerData.transferDetails.recruiterSalesforceId) {
            let paylaod = {
              recruiterSalesforceId:
                this.sideViewerData.transferDetails.recruiterSalesforceId,
            };
            this.UhubDashboardService.getRecruiterDetails(paylaod).subscribe(
              (Data: any) => {
                if (Data) {
                  this.recruiterDetails = Data;
                }
              }
            );
          }

          this.replaceNull(this.sideViewerData);
          this.replaceNull(this.sideViewerData.transferDetails);
          this.documentValidation(this.sideViewerData.id);
          this.sidenavLoading = false;

          this.currentLeadId = this.sideViewerData.id;
        });
        let wpObj = {
          phone: "+" + this.sideViewerData?.CountryCode.replace('+', '') + this.sideViewerData?.PhoneNumber
        }
        this.uhleadsservice.getSmsWp(wpObj).subscribe((wpsmsList: any) => {
          if (wpsmsList) {
            this.smsListWp = wpsmsList?.data;
          }
        });
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );

    //  }
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
      a[key] === "" ? (a[key] = "-") : a[key];
    });
  }

  updateComments(modal, item) {
    if ((item.Assignedto == null || item.Assignedto == undefined || item.Assignedto == '-' || item.Assignedto == '') && item.transferDetails.length > 0) {
      this.notAssignedModel(item);
    } else {

      this.layoutService.commentsForm.get("interested").clearValidators();
      this.layoutService.commentsForm.get("interested").updateValueAndValidity();
      this.layoutService.commentsForm
        .get("counsellingDate")
        .patchValue(
          this.layoutService.dateFormatForAddUpdate(
            this.layoutService.commentsForm.get("counsellingDate").value
          )
        );
      this.layoutService.commentsForm
        .get("callBack")
        .patchValue(
          this.layoutService.dateFormatForAddUpdate(
            this.layoutService.commentsForm.get("callBack").value
          )
        );
      this.layoutService.commentsForm
        .get("dob")
        .patchValue(
          this.layoutService.dateFormatForAddUpdate(
            this.layoutService.commentsForm.get("dob").value
          )
        );
      // this.layoutService.commentsForm.get('callBack').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('callBack').value))
      this.layoutService.commentsForm
        .get("interested")
        .valueChanges.subscribe(async (selectedValue) => {
          await this.layoutService.commentsForm.get("callBack").clearValidators();
          await this.layoutService.commentsForm
            .get("callBack")
            .updateValueAndValidity();
          await this.layoutService.commentsForm
            .get("counsellingDate")
            .clearValidators();
          await this.layoutService.commentsForm
            .get("counsellingDate")
            .updateValueAndValidity();
          await this.layoutService.commentsForm
            .get("assignedTo")
            .clearValidators();
          await this.layoutService.commentsForm
            .get("assignedTo")
            .updateValueAndValidity();
          if (selectedValue == "callback") {
            await this.layoutService.commentsForm
              .get("callBack")
              .setValidators([Validators.required]);
            this.layoutService.commentsForm
              .get("callBack")
              .updateValueAndValidity();
          } else if (selectedValue == "counsellingpending") {
            await this.layoutService.commentsForm
              .get("counsellingDate")
              .setValidators([Validators.required]);
            this.layoutService.commentsForm
              .get("counsellingDate")
              .updateValueAndValidity();
            await this.layoutService.commentsForm
              .get("assignedTo")
              .setValidators([Validators.required]);
            this.layoutService.commentsForm
              .get("assignedTo")
              .updateValueAndValidity();
          }
        });
      this.layoutService.onReset();
      this.currentLead = item;
      this.layoutService.commentsForm.patchValue({
        name: item["FirstName"],
        emailId: item["Email"],
        qualification: item["Education"],
        state: item["City"],
        countryCode: item["CountryCode"],
        phoneNumber: item["PhoneNumber"],
        dob: item["DOB"] ? this.layoutService.dateFormatForAddUpdate1(item["DOB"]) : null
      });
      this.marketingService
        .showDetails(this.currentLead["id"], this.statusValue)
        .subscribe(
          (data) => {
            this.layoutService.commentsForm.patchValue({
              comments:
                data["Phase"] === "demo"
                  ? data["DemoComments"] || ""
                  : data["Comments"] || "",
            });
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );

      this.layoutService.getCommentLead(item["id"]).subscribe(
        (data) => {
          if (data) {
            this.isUpdateLead = true;
          } else {
            this.isUpdateLead = false;
          }
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
      this.modalService.open(modal, { size: "xl" });

    }
  }

  async submit() {
    if (this.isUpdateLead) {
      if (this.layoutService.commentsForm.invalid) {
      } else {
        this.layoutService
          .updateCommentsBasedOnId(this.currentLead["id"])
          .subscribe(
            () => {
              this.updateLeadComments();
              this.snackbarRef.open("Update Successfully", "", {
                verticalPosition: "top",
                horizontalPosition: "end",
                duration: 5000,
                panelClass: ["success-snackbar"],
              });
              this.modalService.dismissAll();
              this.closeButton2.nativeElement.click();
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
      }
    } else {
      if (this.layoutService.commentsForm.invalid) {
      } else {
        this.layoutService.updateComments(this.currentLead["id"]).subscribe(
          () => {
            this.updateLeadComments();
            this.modalService.dismissAll();
            this.closeButton2.nativeElement.click();
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
      }
    }
    if (this.layoutService.commentsValue.name.length === 0) {
      return;
    }
    this.layoutService.submitted = true;
    this.submitted = this.layoutService.submitted;
    if (this.layoutService.commentsValue.interested !== "notinterested") {
      if (
        this.layoutService.commentsValue.counsellingDate &&
        (this.layoutService.commentsValue.interested === "QualifiedLead" ||
          this.layoutService.commentsValue.interested === "QualifiedHotLead")
      ) {
        await this.scheduleDemo();
        this.closeButton2.nativeElement.click();
      } else {
      }
    } else {
      if (this.layoutService.commentsValue.interested === "notinterested") {
        this.marketingService
          .updateLeadStatus({
            id: this.currentLead.id,
            phase: this.layoutService.commentsValue.interested,
          })
          .subscribe(
            () => {
              this.closeButton2.nativeElement.click();
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
      }
    }

    this.UhubDashboardService.sendNotification({
      type: 'lead-status-changed-crm',
      data: {
        uhub_id: this.currentLead["id"],
        status: this.layoutService.commentsValue.interested
      }
    }).subscribe((data: any) => {

    }, (err) => {

    })
  }

  updateLeadComments() {
    if (this.currentLead.phase !== "demo") {
      let callStatus = "interested";
      let futureLead = "no";
      if (this.layoutService.commentsValue.interested === "futurelead") {
        callStatus = "interested";
        futureLead = "yes";
      } else {
        callStatus = this.layoutService.commentsValue.interested;
        futureLead = "no";
      }
      this.layoutService
        .updateCallComments({
          id: this.currentLead["id"],
          agentMail: this.userData.mail,
          comments: this.layoutService.commentsValue.comments,
          followUpdate:
            this.layoutService.commentsValue.interested === "interested" &&
              this.layoutService.commentsValue.counsellingDate
              ? moment(
                this.layoutService.commentsValue.counsellingDate,
                "YYYY-MM-DDTHH:mm:SSZ"
              ).format("YYYY-MM-DDTHH:mm:SSZ")
              : this.layoutService.commentsValue.callBack,
          callStatus: this.layoutService.commentsValue.interested,
          futureLead: futureLead,
          assignedTo:
            this.layoutService.selectedAgent &&
              this.layoutService.selectedAgent.length !== 0
              ? this.layoutService.selectedAgent[0]
              : this.currentLead["Assignedto"],
        })
        .subscribe(
          (data) => {
            this.updateCallComments(
              this.layoutService.commentsValue.comments,
              this.currentLead["id"],
              this.layoutService.commentsValue.interested
            );
            this.getTopBarCount();
            if (this.leadType != "filter") {
              this.getLeads(
                this.admin,
                this.projectId,
                this.userData.mail,
                this.cardleadType,
                this.startDate,
                this.endDate,
                this.pageNumber,
                this.callStatusFilter
              );
            } else if (this.leadType == "filter") {
              let payload = {
                admin: this.admin,
                ProjectId: this.projectId,
                start: "2020-08-02 09:40:25+00",
                end: "2025-08-03 09:40:25+00",
                page: this.pageNumber,
                leadType: "All",
                mail: "All",
                callStatus: "All",
                course: "All",
                enrollmentYear: "All",
                enrollmentTerm: "All",
                export: false,
                search: this.search,
              };
              this.searchLeads1(payload);
            }
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
    }
  }

  updateCallComments(comments, id, callstatus) {
    // this.layoutService.getCallSid.subscribe((sid: any) => {
    let payload = {
      sid: this.UhubCallSid,
      comments: comments,
      id: id,
      callstatus: callstatus,
    };
    this.layoutService
      .updateCallCommentsNew(payload)
      .subscribe((res: any) => { });
    // });
  }

  scheduleDemo() {
    let dt = this.layoutService.commentsValue.counsellingDate;
    dt =
      moment(dt).format("dddd D MMM YY") + " at " + moment(dt).format("h:m a");
    let template: string = this.currentLead["Miscellaneous"]["form_name"]
      .toLowerCase()
      .includes("ielts")
      ? "ielts"
      : "usastudy";

    let num = Math.floor(Math.random() * 90000) + 10000;
    let room = "live-" + num.toString();
    let smsId = null;
    let callId = null;
    let mailId = null;

    let demoScheduled = `${environment.BaseURL}/videocalling/mainscreen/${this.projectId}/${room}`;
    const smsMarketingBefore6hr: InputSMSData = {
      ProjectId: this.projectId,
      Module: this.currentLead["Module"],
      RecipientNumber:
        "+" +
        this.currentLead["CountryCode"] +
        this.currentLead["PhoneNumber"].replace(/\n/g, ""),
      ScheduledSMSTime: moment(
        this.layoutService.commentsValue.counsellingDate,
        "YYYY-MM-DDTHH:mm:SSZ"
      ).format("YYYY-MM-DDTHH:mm:SSZ"),
      Type: "Information",
    };
    this.marketingService.getAutoDialCompany(this.projectId).subscribe(
      async (data) => {
        if (data) {
          let apiKey = data["API_KEY"];
          if (dt) {
            smsId = await this.marketingService.createAutoSmsScheduler(
              smsMarketingBefore6hr,
              apiKey
            );
          }
        }
        setTimeout(() => {
          this.marketingService
            .createDemo({
              ProjectId: this.projectId,
              Name: this.currentLead["FirstName"],
              AutoEmailId: mailId ? mailId["mailId"] : null,
              AutoCallId: callId ? callId["callId"] : null,
              AutoSMSId: smsId ? smsId["smsId"] : null,
              Comments: this.layoutService.commentsValue.comments,
              DateTime: moment(
                this.layoutService.commentsValue.counsellingDate,
                "YYYY-MM-DDTHH:mm:SSZ"
              ).format("YYYY-MM-DDTHH:mm:SSZ"),
              Presenter:
                this.layoutService.selectedAgent &&
                  this.layoutService.selectedAgent.length !== 0
                  ? this.layoutService.selectedAgent[0]["value"]
                  : this.currentLead["Assignedto"],
              Feedback: this.layoutService.commentsValue.interested,
              Url: demoScheduled + "/" + this.userData.workerFriendlyName,
              ClientURL: demoScheduled + "/" + this.currentLead["FirstName"],
              RoomName: room,
              LeadId: this.currentLead.id,
              agentEmail: this.userData.mail,
            })
            .subscribe(
              (demo) => {
                if (dt) {
                  this.marketingService
                    .updateDemo({
                      demoId: demo["id"],
                      leadId: this.currentLead.id,
                    })
                    .subscribe(
                      (data) => {
                        if (dt) {
                          this.layoutService.sendSmsForLead({
                            date: dt,
                            from: this.layoutService.twilioNumber,
                            projectId: this.projectId,
                            student_name: this.currentLead["FirstName"],
                            tosms: this.currentLead.PhoneNumber,
                          });
                        }
                        if (dt) {
                          this.marketingService.sendMailForDemo(
                            {
                              name: this.currentLead["FirstName"],
                              demoDate: moment(
                                this.layoutService.commentsValue.counsellingDate
                              ).format("MMMM Do YYYY, h:mm:ss a"),
                              url:
                                demoScheduled +
                                "/" +
                                this.currentLead["FirstName"],
                            },
                            this.currentLead.Email,
                            this.projectId,
                            template
                          );
                          this.updateLeadComments();
                        }
                      },
                      (error) => {
                        this.handleError.handleError(error);
                      }
                    );
                }
              },
              (error) => {
                this.handleError.handleError(error);
              }
            );
        }, 2000);
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.layoutService.commentsForm.controls;
  }

  get createLeads(): { [key: string]: AbstractControl } {
    return this.marketingService.createLead.controls;
  }

  assignAgentModal() {
    this.defaultAgent = "default";
    this.selectedAgent = null;
  }

  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }

  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning("Select leads to be assigned", "Alert!");
      return;
    }
    if (this.selectedAgent) {
      this.UhubDashboardService.assignLeads({
        AssignedTo: this.selectedAgent,
        InsertedBy: this.userData.mail,
        LeadIds: this.leadIds,
      }).subscribe(
        (res) => {
          this.uhleadsservice.showSuccess(
            "Successfully assigned the agents",
            "Success"
          );

          this.getTopBarCount();
          this.getLeads(
            this.admin,
            this.projectId,
            this.userData.mail,
            this.cardleadType,
            this.startDate,
            this.endDate,
            this.pageNumber,
            this.callStatusFilter
          );
          
          this.selectedAll = false;
          this.closeButton.nativeElement.click();
          document.getElementById("closeAssignButton").click();
          this.modalService.dismissAll();

          // Notify Recruiter
          this.leadIds.forEach(element => {
            this.UhubDashboardService.sendNotification({
              type: 'lead-assigned-crm',
              data: {
                uhub_id: element
              }
            }).subscribe((data: any) => {

            }, (err) => {

            })
          });

          this.leadIds = [];
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    } else {
      this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe(
      (data) => {
        this.totalAgents = data;

        this.uhleadsservice.getGBPAgentList().subscribe(
          (res: any) => {
            this.GBPagentList = res;
            for (let i = 0; i < this.GBPagentList.length; i++) {
              this.totalAgents.push({
                FirstName: this.GBPagentList[i].FIRST_NAME,
                LastName: this.GBPagentList[i].LAST_NAME,
                Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
                ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
              });
            }
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
        // this.totalAgents.push({FirstName: "Test", LastName: "Test", MailId: "test@test.com", ProfilePhote:"test.com"})
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getAgentName(email) {
    if (email !== null || email !== undefined) {
      let name = "";
      for (var k = 0; k < this.totalAgents.length; k++) {
        if (this.totalAgents[k].Mailid == email) {
          name =
            this.totalAgents[k].FirstName + " " + this.totalAgents[k].LastName;
          return name;
        }
      }
    } else {
      return '-'
    }

  }

  createLead() {
    this.submitted = true;
    if (this.marketingService.createLead.invalid) {
      return;
    }
    let fileData: Exceldata[] = [
      new Exceldata(
        this.marketingService.createLeadValue["firstName"],
        this.marketingService.createLeadValue["lastName"],
        this.marketingService.createLeadValue["countryCode"],
        this.marketingService.createLeadValue["phoneNumber"],
        this.marketingService.createLeadValue["email"],
        this.userData.mail,
        this.marketingService.createLeadValue["module"],
        this.marketingService.createLeadValue["channel"],
        {
          form_id: "",
          form_name: this.marketingService.createLeadValue["form_name"],
          "Choose Batch": "",
          "Training Mode": "",
        },
        this.marketingService.createLeadValue["comments"],
        this.marketingService.createLeadValue["createdAt"]
      ),
    ];
    this.marketingService
      .postCallData({
        data: fileData,
        createdBy: this.userData["mail"],
        ProjectId: this.projectId,
        type: "Manual",
      })
      .subscribe(
        (data) => {
          this.marketingService.resetCreateLead();
          this.submitted = false;
          this.modalService.dismissAll();
          this.getTopBarCount();
          this.onClick(1);
          this.getLeadsByCards(this.cardleadType);
          this.getUniversalRecords();
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
  }

  openCreateLead(content) {
    this.modalService.open(content, { size: "md" });
  }

  getAgentInitial(email) {
    if (email !== null || email !== undefined) {
      let initial = "";
      for (var k = 0; k < this.totalAgents.length; k++) {
        if (this.totalAgents[k].Mailid == email) {
          initial =
            this.totalAgents[k].FirstName.charAt(0) +
            this.totalAgents[k].LastName.charAt(0);
          return initial;
        }
      }
    } else {
      return '-'
    }
  }

  getAgentColor(email) {
    if (email !== null || email !== undefined) {
      let color = "";
      for (var k = 0; k < this.totalAgents.length; k++) {
        if (this.totalAgents[k].Mailid == email) {
          color =
            this.totalAgents[k].FirstName.charAt(0) +
            this.totalAgents[k].LastName.charAt(0);

          let randomIndex =
            this.colors[
            this.numberFromText(this.getAgentInitial(email)) %
            this.colors.length
            ];
          color = randomIndex[0]["bg"];
          return color;
        }
      }
    } else {
      return '-'
    }
  }

  getAgentphoto(email) {
    if (email !== null || email !== undefined) {
      let imageUrl = "";
      for (var k = 0; k < this.totalAgents.length; k++) {
        if (this.totalAgents[k].Mailid == email) {
          imageUrl = this.totalAgents[k].ProfilePhoto;
          return imageUrl;
        }
      }
    } else {
      return '-'
    }
  }

  numberFromText(text) {
    const charCodes = text
      .split("") // => ["A", "A"]
      .map((char) => char.charCodeAt(0)) // => [65, 65]
      .join(""); // => "6565"
    return parseInt(charCodes, 10);
  }

  onDIItermsChange(data) {
    this.DIterms = data;
  }

  onDIYearChange(data) {
    this.DIyear = data;
  }
  // Clear file and document name in custom document upload modal
  clearData(uniName?: any, uniProg?: any) {
    this.transferCustomUniversity = uniName;
    this.transferCustomUniversityProg = uniProg;
    if (uniName) {
      const index = this.allUniversities.findIndex(x => x?.university?.toLowerCase() === uniName?.toLowerCase());
      if (index > -1) {
        this.currentProject = this.allUniversities[index].uniProjectId;
      }
    }
    this.file = [];
    this.customDocName = "";
    this.customFileList = [];
  }

  selectFiles() {
    const frmData = new FormData();

    for (var i = 0; i < this.selectedFiles.length; i++) {
      frmData.append("fileUpload", this.selectedFiles[i]);
    }
  }

  getFileDetails(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.selectedFiles.push(e.target.files[i]);
    }
    if (this.selectedFiles.length > 0) {
      this.docUploadError2 = "Click on upload to start uploading your files";

      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
    }
    e.target.value = '';
  }

  async attachFileMainCommentGetDetails(e) {
    for await (const i of e.target.files) {
      this.currentGUIDCommentsAttachment.push(i);
    }
  }

  deleteFile(i) {
    this.selectedFiles.splice(i, 1);
  }

  clearSelectedFiles(item) {
    this.docUploadID = item.StudentdocId;
    this.docUploadStatus = false;
    this.selectedFiles = [];
    this.docUploadError2 = "Select files to upload.";
    this.uploadSuccess = "none";

    var x = document.getElementsByClassName(
      "docUploadError"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.color = "green";
    }
  }

  //Validate Uploaded file
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "image/jpeg") {
      return true;
    } else if (ext.toLowerCase() == "document") {
      return true;
    } else if (ext.toLowerCase() == "image/png") {
      return true;
    } else if (ext.toLowerCase() == "application/msword") {
      return true;
    } else if (ext.toLowerCase() == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return true;
    } else if (ext.toLowerCase() == "application/pdf") {
      return true;
    } else {
      this.uhleadsservice.showError("Please upload a valid file!", "Error!");
      return false;
    }
  }
  uploadCustomDocument(e) {
    // let fileList: FileList = event.target.files;
    // this.file = fileList[0];
    // event.target.value = '';
    // if (fileList.length > 0) {
    //   if (!this.validateFile(this.file.type)) {
    //     this.uhleadsservice.showError("Invalid file format!", "Error");
    //     return;
    //   }
    // }
    for (var i = 0; i < e.target.files.length; i++) {
      this.file.push(e.target.files[i]);
      console.log("e.target.files.length ::::: ", e.target.files[i])
      this.customFileList.push(e.target.files[i]);
    }
    if (this.customFileList.length > 0) {
      this.docUploadError = "Click on add to start uploading your files";
      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
    }
    e.target.value = '';
  }

  addCustomDocument(isTransfer?: boolean) {
  let check = true;
    this.customFileList.forEach((element) => {
      if (this.validateFile(element.type) == false) {
        this.docUploadError =
          "One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.";
        check = false;
        var x = document.getElementsByClassName(
          "docUploadError"
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < x.length; i++) {
          x[i].style.color = "red";
        }
      }
    });
    if (check == true) {
      this.docUploadError = "Please be patient while the document is uploaded...";
      if (this.customDocName == "") {
        this.docUploadError = "Document name cannot be empty!";
        return;
      } else {
        this.docUploadStatus = true;
        let formData: FormData = new FormData();
        formData.append("GUID", this.currentLeadId);
        formData.append("docName", this.customDocName);
        formData.append("Platform", "CRM");
        formData.append("MailId", this.userData.mail);
        formData.append('ProjectId', this.currentProject);
        Array.from(this.customFileList).forEach((file) =>
          formData.append("document", file, file.name)
        );
        this.UhubDashboardService.uploadDocument(formData).subscribe(
          (res: any) => {
            let response = res.status;
            this.docUploadStatus = false;
            if (response == 200) {
              this.docUploadError = "Custom document added";
              this.uhleadsservice.showSuccess(
                "Custom document added!",
                "Success"
              );
            } else if (response == 201) {
              this.docUploadError = "Uploaded a custom document";
              this.uhleadsservice.showSuccess(
                "Uploaded a custom document!",
                "Success"
              );
            } else if (response == 202) {
              this.docUploadError = "Document with given name already exists!";
              this.uhleadsservice.showError(
                "Document with given name already exists!",
                "Error"
              );
              return;
            }
            if (isTransfer){
              this.updatetransferUniversityProg(res.StudentdocId);
            }
            this.sideNavData({ id: this.currentLeadId });
            this.file = [];
            this.customDocName = "";
            this.closeButtonCustom.nativeElement.click();
            this.closeButtonCustom5.nativeElement.click();
            
          },
          (error) => {
            this.docUploadStatus = false;
            this.handleError.handleError(error);
          }
        );
      }
    }
  }

  deleteCustomDocument(docId, docName) {
    this.UhubDashboardService.deleteCustomDocument({
      StudentdocId: docId,
      docName: docName,
      Platform: "CRM",
      MailId: this.userData.mail,
    }).subscribe(
      (res: any) => {
        let response = res.status;
        if (response == 200) {
          this.sideNavData({ id: this.currentLeadId });
          this.uhleadsservice.showSuccess(
            "Custom document deleted successfully.",
            "Deleted Successfully!"
          );
        } else {
          this.uhleadsservice.showError("Something went wrong!", "Error!");
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  downLoadDocument(url, name) {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("type", "hidden");
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  fileUpload(event, item) {
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(file.type)) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append("studentdocid", item.StudentdocId);
      formData.append("document", file, file.name);
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);

      this.uhleadsservice.uploadDocument(formData, item.StudentdocId).subscribe(
        (res) => {
          this.uhleadsservice.showSuccess("File upload successful!", "Success");
          this.sideNavData({ id: this.currentLeadId });
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    }
  }

  openPreview(content, url) {
    // this.docUrl = '';
    this.docUrl = url;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  callFromDocs(data, item) {
    this.currentItem = item;
    this.startCallListener2(data, 0);
  }

  changeValidation(value, docId) {
    this.UhubDashboardService.updateValidationStatus({
      StudentdocId: docId,
      // admin: this.admin,
      admin: false,
      validationStatus: value,
      Platform: "CRM",
      MailId: this.userData.mail,
    }).subscribe(
      (res) => {
        this.documentValidation(this.currentLeadId);
        this.sideNavData({ id: this.currentLeadId });
        this.uhleadsservice.showSuccess("Document Status updated!", "Success!");
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  checkValidation() {
    if (this.sideViewerData.uploadlistofdocuments.length == 0) {
      this.validationStatus = false;
      return;
    }
    for (var i = 0; i < this.sideViewerData.uploadlistofdocuments.length; i++) {
      if (
        this.sideViewerData.uploadlistofdocuments[i].AgentValidation ==
        "revalidate" ||
        this.sideViewerData.uploadlistofdocuments[i].AgentValidation == "-"
      ) {
        this.validationStatus = false;
        return;
      } else if (
        this.sideViewerData.uploadlistofdocuments[i].AgentValidation ==
        "validated"
      ) {
        this.validationStatus = true;
        // open modal to confirm if agent wants to send mail to student or not
        // this.modalService.open(this.sendUniversityDoc,{ size: "md" });
        //AddNotification
      } else {
        this.validationStatus = false;
      }
    }
  }

  postComments(item, HTMLid, attachmentId?: any) {
    let body;
    this.documentComments = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    if (this.documentComments.length > 0) {
      const isAttachmentFile = (<HTMLInputElement>(
        document.getElementById(attachmentId)
      ));
      if (isAttachmentFile) {
        let attachmentFile = isAttachmentFile.files;
        if (attachmentFile.length > 0) {
          let files = attachmentFile[0];
          let check = true;
          if (this.validateFile(files.type) == false) {
            this.uhleadsservice.showError("One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.", '');
            check = false;
            return;
          }
          if (check == true) {
            let formData: FormData = new FormData();
            formData.append("document", files, files.name)
            this.UhubDashboardService.uploadFileAndGetLink(formData).subscribe(async (data: any) => {
              let attachCommentObj = [];
              attachCommentObj.push(
                data.url
              )
              body = {
                ProjectId: this.projectId,
                GUID: this.currentLeadId,
                StudentDocId: item.StudentdocId,
                Text: this.documentComments,
                Admin: this.admin,
                LeadId: this.currentLeadId,
                AgentMail: this.userData.mail,
                Platform: "CRM",
                Attachment: attachCommentObj
              };
              if (HTMLid == "leadComments") {
                body.StudentDocId = null;
              }
              this.addCommentsOnDoc(body, attachmentId, HTMLid);
            })
          }
        } else {
          body = {
            ProjectId: this.projectId,
            GUID: this.currentLeadId,
            StudentDocId: item.StudentdocId,
            Text: this.documentComments,
            Admin: this.admin,
            LeadId: this.currentLeadId,
            AgentMail: this.userData.mail,
            Platform: "CRM",
            Attachment: []
          };
          if (HTMLid == "leadComments") {
            body.StudentDocId = null;
          }
          this.addCommentsOnDoc(body, attachmentId, HTMLid);
        }
      } else {
        body = {
          ProjectId: this.projectId,
          GUID: this.currentLeadId,
          StudentDocId: item.StudentdocId,
          Text: this.documentComments,
          Admin: this.admin,
          LeadId: this.currentLeadId,
          AgentMail: this.userData.mail,
          Platform: "CRM",
          Attachment: []
        };
        if (HTMLid == "leadComments") {
          body.StudentDocId = null;
        }
        this.addCommentsOnDoc(body, attachmentId, HTMLid);
      }
    } else {
      this.uhleadsservice.showInfo('Please write something in comment box.', '')
    }
  }

  addCommentsOnDoc(body, attachmentId, HTMLid) {
    this.uhleadsservice.postDocumentComment(body).subscribe(
      (res) => {
        this.documentValidation(this.currentLeadId);
        this.sideNavData({ id: this.currentLeadId });
        this.uhleadsservice.showSuccess("Comment Posted!", "Success!");
        (<HTMLInputElement>document.getElementById(HTMLid)).value = "";

        this.UhubDashboardService.sendNotification({
          type: 'crm-comment',
          data: {
            uhub_id: body.GUID
          }
        }).subscribe((data: any) => {

        }, (err) => { });
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  documentValidation(id) {
    this.documentsList2 = this.sideViewerData.uploadlistofdocuments;

    this.documentsList2.forEach(function (a) {
      Object.keys(a).map((key) => {
        a[key] === null ? (a[key] = "-") : a[key];
      });
    });
    this.checkValidation();
  }

  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }

  uploadAttachments() {
    let check = true;
    this.selectedFiles.forEach((element) => {
      if (this.validateFile(element.type) == false) {
        this.docUploadError2 =
          "One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.";
        check = false;
        var x = document.getElementsByClassName(
          "docUploadError"
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < x.length; i++) {
          x[i].style.color = "red";
        }
      }
    });
    if (check == true) {
      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
      this.docUploadError2 =
        "Compressing your documents. Please wait for the process to finish";
      this.docUploadStatus = true;

      let formData: FormData = new FormData();
      formData.append("studentdocid", this.docUploadID);
      Array.from(this.selectedFiles).forEach((file) =>
        formData.append("document", file, file.name)
      );
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);

      this.UhubDashboardService.uploadDocument1(
        formData,
        this.docUploadID
      ).subscribe(
        (res: any) => {
          // this.uhleadsservice.showSuccess("File upload successful!", "Success");

          if (res.status == 200) {
            this.uploadSuccess = "success";
            this.sideNavData({ id: this.currentLeadId });
            this.uhleadsservice.showSuccess(
              "File upload successful!",
              "Success"
            );
            this.docUploadStatus = false;
            this.UhubDashboardService.sendNotification({
              type: 'doc-upload',
              data: {
                uhub_id: this.currentLeadId
              }
            }).subscribe((data: any) => {

            }, (err) => {
            });
            // document.getElementById("closeButton4").click();
            try {
              // document.getElementById("closeButton4").click();
            } catch (err) {
              this.uploadSuccess = "failure";
            }
          }
        },
        (err) => {
          this.docUploadStatus = false;
          this.uploadSuccess = "failure";
          this.handleError.handleError(err);
          if (err.error.status == 400) {
            this.uhleadsservice.showError(
              `The file ${err.error.file} is corrupted. Please upload valid files .`,
              ""
            );
          } else if (err.error.status == 500) {
            this.uhleadsservice.showError(
              err.error.message,
              ""
            );
          } else {
            this.uhleadsservice.showError(
              'Files not uploaded. Please check document extensions, upload supported documents.',
              ""
            );
          }
        }
      );
    }
  }

  cancelUpload() {
    document.getElementById("closeButton4").click();
  }

  postComments1(item, value) {
    var body = {
      ProjectId: this.projectId,
      GUID: this.currentLeadId,
      StudentDocId: item.StudentdocId,
      Text: value,
      Admin: this.admin,
      LeadId: this.currentLeadId,
      AgentMail: this.userData.mail,
      Platform: "CRM",
    };
    this.uhleadsservice.postDocumentComment(body).subscribe(
      (res) => {
        this.documentValidation(this.currentLeadId);
        this.sideNavData({ id: this.currentLeadId });
        this.uhleadsservice.showSuccess("Comment Posted!", "Success!");
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  fetchCountries() {
    this.countries = countryCodes;
  }

  onInput1(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countries = countryCodes.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countries = countryCodes;
    }
  }
  patchEditValues(item) {
    this.layoutService.commentsForm.get("interested").clearValidators();
    this.layoutService.commentsForm.get("interested").updateValueAndValidity();
    this.layoutService.commentsForm
      .get("counsellingDate")
      .patchValue(
        this.layoutService.dateFormatForAddUpdate(
          this.layoutService.commentsForm.get("counsellingDate").value
        )
      );
    this.layoutService.commentsForm
      .get("callBack")
      .patchValue(
        this.layoutService.dateFormatForAddUpdate(
          this.layoutService.commentsForm.get("callBack").value
        )
      );
    this.layoutService.commentsForm
      .get("dob")
      .patchValue(
        this.layoutService.dateFormatForAddUpdate(
          this.layoutService.commentsForm.get("dob").value
        )
      );
    // this.layoutService.commentsForm.get('callBack').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('callBack').value))
    this.layoutService.commentsForm
      .get("interested")
      .valueChanges.subscribe(async (selectedValue) => {
        await this.layoutService.commentsForm.get("callBack").clearValidators();
        await this.layoutService.commentsForm
          .get("callBack")
          .updateValueAndValidity();
        await this.layoutService.commentsForm
          .get("counsellingDate")
          .clearValidators();
        await this.layoutService.commentsForm
          .get("counsellingDate")
          .updateValueAndValidity();
        await this.layoutService.commentsForm
          .get("assignedTo")
          .clearValidators();
        await this.layoutService.commentsForm
          .get("assignedTo")
          .updateValueAndValidity();
        if (selectedValue == "callback") {
          await this.layoutService.commentsForm
            .get("callBack")
            .setValidators([Validators.required]);
          this.layoutService.commentsForm
            .get("callBack")
            .updateValueAndValidity();
        } else if (selectedValue == "counsellingpending") {
          await this.layoutService.commentsForm
            .get("counsellingDate")
            .setValidators([Validators.required]);
          this.layoutService.commentsForm
            .get("counsellingDate")
            .updateValueAndValidity();
          await this.layoutService.commentsForm
            .get("assignedTo")
            .setValidators([Validators.required]);
          this.layoutService.commentsForm
            .get("assignedTo")
            .updateValueAndValidity();
        }
      });
    this.layoutService.onReset();
    this.currentLead = item;
    this.layoutService.commentsForm.patchValue({
      name: item["FirstName"],
      emailId: item["Email"],
      qualification: item["Education"],
      state: item["City"],
      countryCode: item["CountryCode"],
      phoneNumber: item["PhoneNumber"],
      dob: this.layoutService.dateFormatForAddUpdate1(item["DOB"])
    });
    this.marketingService
      .showDetails(this.currentLead["id"], this.statusValue)
      .subscribe(
        (data) => {
          this.layoutService.commentsForm.patchValue({
            comments:
              data["Phase"] === "demo"
                ? data["DemoComments"] || ""
                : data["Comments"] || "",
          });
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );

    this.layoutService.getCommentLead(item["id"]).subscribe(
      (data) => {
        if (data) {
          this.isUpdateLead = true;
        } else {
          this.isUpdateLead = false;
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }
  async submitCallEdit() {
    this.comments = this.layoutService.commentsValue.comments;

    if (this.layoutService.commentsValue.name.length === 0) {
      return;
    } else {
      if (this.layoutService.commentsForm.invalid) {
        return;
      }
      this.layoutService.submitted = true;
      this.submitted = this.layoutService.submitted;

      if (
        this.layoutService.commentsValue.interested === "QualifiedLead" ||
        this.layoutService.commentsValue.interested === "QualifiedHotLead"
      ) {
        await this.scheduleDemo();
      }

      if (this.layoutService.commentsValue.interested !== "notinterested") {
        if (
          this.layoutService.commentsValue.counsellingDate &&
          (this.layoutService.commentsValue.interested === "QulaifiedLead" ||
            this.layoutService.commentsValue.interested === "QulaifiedHotLead")
        ) {
          //await this.scheduleDemo();
          if (this.updateLeadComments1) {
            this.layoutService
              .updateCommentsBasedOnId(this.marketingId.id)
              .subscribe(
                () => {
                  this.snackbarRef.open("Update Successfully", "", {
                    verticalPosition: "top",
                    horizontalPosition: "end",
                    // duration: 5000,
                    panelClass: ["success-snackbar"],
                  });
                },
                (error) => {
                  this.handleError.handleError(error);
                }
              );
          } else {
            this.layoutService.updateComments(this.marketingId.id).subscribe(
              () => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              },
              (error) => {
                this.handleError.handleError(error);
              }
            );
          }
        } else {
          if (this.updateLeadComments1) {
            this.updateCommentsCall();
            this.layoutService
              .updateCommentsBasedOnId(this.marketingId.id)
              .subscribe(
                () => {
                  this.snackbarRef.open("Update Successfully", "", {
                    verticalPosition: "top",
                    horizontalPosition: "end",
                    duration: 5000,
                    panelClass: ["success-snackbar"],
                  });
                },
                (error) => {
                  this.handleError.handleError(error);
                }
              );
          } else {
            this.layoutService.updateComments(this.marketingId.id).subscribe(
              () => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              },
              (error) => {
                this.handleError.handleError(error);
              }
            );
            this.updateCommentsCall();
          }
        }
      } else {
        if (this.layoutService.commentsValue.interested === "notinterested") {
          if (this.updateLeadComments1) {
            this.layoutService
              .updateCommentsBasedOnId(this.marketingId.id)
              .subscribe(
                () => {
                  this.snackbarRef.open("Update Successfully", "", {
                    verticalPosition: "top",
                    horizontalPosition: "end",
                    duration: 5000,
                    panelClass: ["success-snackbar"],
                  });
                },
                (error) => {
                  this.handleError.handleError(error);
                }
              );

            this.updateCommentsCall();
          } else {
            this.layoutService.updateComments(this.marketingId.id).subscribe(
              () => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              },
              (error) => {
                this.handleError.handleError(error);
              }
            );
            this.updateCommentsCall();
          }
        }
      }
    }
  }
  updateCommentsCall() {
    this.layoutService
      .updateCallComments({
        id: this.marketingId["id"],
        agentMail: this.userData.mail,
        comments: this.comments,
        followUpdate:
          this.layoutService.commentsValue.interested === "interested" &&
            this.layoutService.commentsValue.counsellingDate
            ? moment(
              this.layoutService.commentsValue.counsellingDate,
              "YYYY-MM-DDTHH:mm:SSZ"
            ).format("YYYY-MM-DDTHH:mm:SSZ")
            : this.layoutService.commentsValue.callBack,
        callStatus: this.layoutService.commentsValue.interested,
        futureLead: "futureLead",
        assignedTo:
          this.layoutService.selectedAgent &&
            this.layoutService.selectedAgent.length !== 0
            ? this.layoutService.selectedAgent[0]
            : this.marketingId.lead["Assignedto"],
      })
      .subscribe(
        (data) => {
          let payload = {
            sid: this.UhubCallSid,
            comments: this.comments,
            id: this.marketingId["id"],
            callstatus: this.layoutService.commentsValue.interested,
          };
          this.layoutService
            .updateCallCommentsNew(payload)
            .subscribe((res: any) => {
              this.layoutService.onReset();
              this.submitted = false;
              this.closeButton2.nativeElement.click();
            });
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
  }
  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }
  sendUniMailPopUp() {
    this.modalService.open(this.sendUniversityDoc, { size: "md" });
  }
  sendUniMailConfirm() {
    this.currentLeadId
    let paylaod = {
      GUID: this.currentLeadId
    };
    this.UhubDashboardService.sendUniMail(paylaod).subscribe(() => {
      this.uhleadsservice.showSuccess("Success", "Mail sent successfully!")
    })
  }
  startCall1() {
    this.layoutService.startCalls.next("startcalls");
  }
  conferenceCall(value: any) {
    this.uhleadsservice.conferenceCall.next({ value });
  }
  sendSmsWp(item, HTMLid) {

    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let payload = {
      text: this.smsText,
      from: environment.whatsApp_Sender,
      to: "+" + item.CountryCode.replace('+', '') + item.PhoneNumber,
      AgentMail: this.userData.mail,
    };

    this.uhleadsservice
      .sendWhatsapp(payload, environment.whatsApp_Sender_projectId)
      .subscribe((res: any) => {
        this.uhleadsservice.showInfo("Message successfully sent.", "Sent");
        let wpObj = {
          phone: "+" + item.CountryCode.replace('+', '') + item.PhoneNumber
        }
        this.uhleadsservice.getSmsWp(wpObj).subscribe((wpsmsList: any) => {
          if (wpsmsList) {
            this.smsListWp = wpsmsList?.data;
            (<HTMLInputElement>document.getElementById(HTMLid)).value = "";
          }
        });
      });
  }
  onInputC(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countries = countryCodes.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countries = countryCodes;
    }
  }
  getUniList(i) {
    this.uniList = i.transferDetails[0].universityInfo;
  }
  downloadCommentAttachment(data) {
    if (data && data?.Attachment && data?.Attachment?.length > 0) {
      data?.Attachment.forEach(element => {
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.setAttribute("type", "hidden");
        link.setAttribute("href", element);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  }
  getListofUniversities() {
    this.UhubDashboardService.getAllUniversity().subscribe((data: any) => {
      this.allUniversities = data.universities;
    });
  }
  notAssignedModel(item) {
    this.leadIds.push(item.id);
    this.modalService.open(this.leadNotAssigned, { size: "md" });
    // this.uhleadsservice.showInfo('Please assign the lead first .','The lead is not assigned.')
  }
  markNotificationRead(id) {
    this.NotificationService.markReadByGUID({
      "ProjectId": this.projectId,
      "GUID": id
    }).subscribe((data) => {
      this.UhubDashboardService.unreadCountRecBehaviorSubject.next(id);
      this.LeadList.forEach(element => {
        if (Number(element.id) === Number(id)) {
          element.unreadCount = 0;
        }
      });
    }, (err) => {

    })
  }
  updatetransferUniversityProg(docId) {
    if(this.transferCustomUniversity && this.transferCustomUniversityProg){
      this.UhubDashboardService.updateTransferCustomDoc({
        studentdocid: docId,
        university: this.transferCustomUniversity,
        program: this.transferCustomUniversityProg
      }).subscribe((data: any) => {
        
      })
    }
  }
  setUniversity(uni){
   this.uni = uni;
  }
  getCustomDocList(sidenavdata) {
    this.uhleadsservice.getCustomDocListNew(this.projectId).subscribe((data: any) => {
      if (data) {
        this.customDocList = data.response;
      }
    }, (error) => {

    })
  }
  customDocNameChange(value) {
    const index = this.customDocList.findIndex(x => x.name.toLowerCase().trim() == value.toLowerCase().trim());
    this.customDocNameCode = this.customDocList[index].code;
  }

  getCustomList() {
    this.customDocList.forEach(element => {
      const index = this.sideViewerData?.uploadlistofdocuments.findIndex(x => x.ProgramDoc == element.name);
      if (index > -1) {
        element.disabled = true;
      } else {
        element.disabled = false;
      }
    });
    this.customDocList.sort((x, y) => Number(x.disabled) - Number(y.disabled));
  }
}

