<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Application Details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Student Name</td>
            <td>
              {{ sideViewerData.first_name + " " + sideViewerData.last_name }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData.student_email }}</td>
          </tr>
          <tr>
            <td>CU ID Number</td>
            <td>{{ sideViewerData.id_number }}</td>
          </tr>
          <tr>
            <td>Address Line#1</td>
            <td>{{ sideViewerData.address_line1 ? sideViewerData.address_line1 : '-' }}</td>
          </tr>
          <tr>
            <td>Address Line#2</td>
            <td>{{ sideViewerData.address_line2 ? sideViewerData.address_line2 : '-' }}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{{ sideViewerData.city ? sideViewerData.city : '-' }}</td>
          </tr>
          <tr>
            <td>State</td>
            <td>{{ sideViewerData.state ? sideViewerData.state : '-' }}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{{ sideViewerData.country ? sideViewerData.country : '-' }}</td>
          </tr>
          <tr>
            <td>Zipcode</td>
            <td>{{ sideViewerData.zipcode ? sideViewerData.zipcode : '-' }}</td>
          </tr>
          <tr>
            <td>When would you like to begin your studies?</td>
            <td>{{ sideViewerData.when_like_to_begin_studies }}</td>
          </tr>
          <tr>
            <td>What Program ?</td>
            <td>{{ sideViewerData.what_program }}</td>
          </tr>
          <tr>
            <td>Application Status</td>
            <td>{{ sideViewerData.application_status }}</td>
          </tr>
          <tr>
            <td>Accepted Application Status</td>
            <td>{{ sideViewerData.accepted_app_status }}</td>
          </tr>
          <tr *ngIf="sideViewerData?.application_status == 'Reject' ">
            <td>Reject Reason</td>
            <td>{{ sideViewerData.reject_reason ? sideViewerData.reject_reason : '-' }}</td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height:200%">
            {{ sideViewerData?.Activities?.length > 0 ?
            sideViewerData?.Activities?.length : '0' }} Actions taken
          </div>
          <div class="ml-auto" style="width:180px;">
            <input class="searchActivity" type="text" style="font-size:13px;" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.Activities?.length)" />
          </div>
        </div>
        <div *ngFor="let i of sideViewerData?.Activities | slice: 0:minItems | searchFilterTable: activityfilterString">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i.Description }}
              <span class="sm-text ml-2">{{
                i.DateTime | date: "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore" *ngIf="minItems < sideViewerData?.Activities?.length"
          (click)="showmore(sideViewerData?.Activities?.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>


<div class="container-fluid">
  <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
    <div class="db-sm-header" style="line-height: 330%">Activity</div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>


  <!-- Carousel  -->

  <carousel [cellsToScroll]="5" style="padding-left: 30px; z-index: unset;" class="fixheight">

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showTotalApplications()">
        <div class="db-block w-175" (click)="onClick(1)" [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Total}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showIncompleteApplications()">
        <div class="db-block w-175" (click)="onClick(2)" [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Incomplete}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Incomplete</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showSubmittedApplications()">
        <div class="db-block w-175" (click)="onClick(3)" [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Submitted}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Submitted</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showInReviewApplications()">
        <div class="db-block w-175" (click)="onClick(4)" [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.InReview}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">In Review</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showGraduationHoldApplications()">
        <div class="db-block w-175" (click)="onClick(5)" [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.GraduationHold}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Graduation Hold</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showGradesHoldApplications()">
        <div class="db-block w-175" (click)="onClick(6)" [ngClass]="{ 'db-block-active': tab === 'tab6' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.GradesHold }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Grades Hold</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showAcceptedApplications()">
        <div class="db-block w-175" (click)="onClick(7)" [ngClass]="{ 'db-block-active': tab === 'tab7' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Accepted}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Accepted</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showDeferredApplications()">
        <div class="db-block w-175" (click)="onClick(8)" [ngClass]="{ 'db-block-active': tab === 'tab8' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Deferred}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Deferred</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showRejectApplications()">
        <div class="db-block w-175" (click)="onClick(9)" [ngClass]="{ 'db-block-active': tab === 'tab9' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Reject}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Reject</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showCOLDApplications()">
        <div class="db-block w-175" (click)="onClick(10)" [ngClass]="{ 'db-block-active': tab === 'tab10' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Cold}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">COLD</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showCancelledApplications()">
        <div class="db-block w-175" (click)="onClick(11)" [ngClass]="{ 'db-block-active': tab === 'tab11' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{topBarCounts?.Cancelled}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Cancelled</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell"></div>

  </carousel>

  <!-- End Carousel -->

  <!-- Activity -->

  <!-- End Carousel -->

  <!-- Activity -->
  <div>
    <div>
      <div>
        <div class="d-flex align-items-end">
          <div class="ml-3 header-12 mt-3">
            Total Registered
          </div>
          <div class="ml-auto mr-3">
            <div class="form-control form-control-dark" style="
                height: 0;
                padding: 0px;
                background: none;
                font-size: 13px;
                color: #999;
                border: 0;
              "></div>
            <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Assign to Agent
                    </h5>
                    <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" style="height: none;">
                    <div class="px-2">
                      <div class="py-2 header-lg">
                        <b>{{ leadIds.length }}</b> Students are selected to
                        assign.
                      </div>
                      <div class="d-flex mt-2 mb-3">
                        <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
                          <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                            (change)="agentSelected($event.target.value)">
                            <option value="default" disabled selected>
                              Select Agent
                            </option>
                            <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                              {{ agent.FirstName + " " + agent.LastName }}
                            </option>
                          </select>
                        </div>
                        <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
                          Assign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-containers" style="margin-left: 30px">
              <button data-toggle="modal" data-target="#exampleModal3" *ngIf="admin" (click)="assignAgentModal()"
                class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Assign" placement="top"
                tooltipClass="my-custom-class">
                <img src="../../../../assets/img/assign-w.svg" class="img13" />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="mt-1 ml-3 mr-3">
            <div class="row">
              <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
                <div class="table-responsive data-table mb-3">
                  <table class="table table-md wbg table-shadow" style="margin-bottom: 0px; width: 100%">
                    <thead>
                      <tr>
                        <td colspan="5">
                          <div class="indived-search" style="position: relative">

                            <input type="search" (input)="onInput($event)" #inputString class="form-control search-all"
                              style="padding: 4px 12px" [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                          </div>
                        </td>
                        <td colspan="10" class="vm">
                          <div class="pagenation-right d-flex justify-content-end align-items-center">
                            <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
                              (ngModelChange)="dropdownSelect($event)">
                              <option value="all">All</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Accepted, hold for grades">Accepted, hold for grades</option>
                              <option value="Accepted, graduation hold">Accepted, graduation hold</option>
                              <option value="Accepted early">Accepted early</option>
                              <option value="AcceptedS">Accepted</option>
                              <option value="Accepted, MSCS Approval Pending">Accepted, MSCS Approval Pending</option>
                              <option value="Accepted, business office hold">Accepted, business office hold</option>
                            </select>
                            <div class="float-right" style="color: #fff; font-size: 12px">
                              <app-custom-pagination></app-custom-pagination>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tr>
                      <th nowrap>
                        <div class="d-flex">
                          <div style="width: 20px;padding-left: 10px;" *ngIf="admin">
                            <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                              (change)="selectAllforAssignment()" />
                          </div>
                        </div>
                      </th>
                      <th>Actions</th>
                      <th nowrap>
                        <div class="d-flex">
                          <div>Created</div>
                          <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img
                                class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </div>
                      </th>
                      <th nowrap>
                        <div class="d-flex">
                          <div>Updated</div>
                          <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img
                                class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </div>
                      </th>
                      <th nowrap>
                        <div class="d-flex">
                          <div>Name</div>
                          <span><button class="sortButton" (click)="toggleSort('createdAt','asc')"><img
                                class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </div>
                      </th>
                      <th nowrap>
                        Email
                        <span><button class="sortButton" (click)="toggleSort('FirstName','asc')"><img class="img7 ml-1"
                              src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Application Status
                        <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img class="img7 ml-1"
                              src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Accepted Application Status
                        <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img class="img7 ml-1"
                              src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th style="width: 180px" nowrap>
                        I-20 End Date
                        <span><button class="sortButton" (click)="toggleSort('AssignedtoName','asc')"><img
                              class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                    </tr>

                    <tbody>
                      <tr *ngFor="
                      let item of activityTable;
                      let id = index
                    ">
                        <td class="vm" nowrap>
                          <div class="d-flex">
                            <div style="width: 20px;padding-left: 10px;" *ngIf="admin">
                              <input type="checkbox" name="lead" [checked]="selectedAll" class="selectLead"
                                id="selectLead" (change)="selectAgentforAssignment($event, item)" />
                            </div>
                          </div>
                        </td>
                        <td class="vm text-left" nowrap>
                          <!-- <mat-sidenav-container ngbTooltip="Overview" placement="top"
                          tooltipClass="my-custom-class" style="display: inline !important" >
                            <mat-sidenav-content>
                              <button class="viewLeadButton" (click)="sideNav.toggle(); sideNavData(item)" mat-button
                                ngbTooltip="Overview" placement="top" tooltipClass="my-custom-class">
                                <img class="img12" src="../../../../assets/img/view2.svg" />
                              </button>
                            </mat-sidenav-content>
                          </mat-sidenav-container>
                          <div ngbTooltip="Call" placement="top"
                            tooltipClass="my-custom-class" class="ml-3" style="display: inline; cursor: pointer"
                            (click)="openEditLead(editLead2, item)">
                            <img src="../../../../assets/img/edit.svg" class="img12" />
                          </div> -->

                          <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview"
                            placement="top" tooltipClass="my-custom-class">
                            <mat-sidenav-content style="display: inline !important">
                              <div style="display: inline !important; cursor: pointer"
                                (click)="sideNav.toggle(); sideNavData(item)">
                                <img src="../../../../assets/img/view2.svg" class="img13" />
                              </div>
                            </mat-sidenav-content>
                          </mat-sidenav-container>
                          <div ngbTooltip="Call" placement="top"
                            tooltipClass="my-custom-class" class="ml-3" style="display: inline; cursor: pointer"
                            (click)="openEditLead(editLead2, item)">
                            <img src="../../../../assets/img/call.svg" class="img13" />
                          </div>
                        </td>
                        <td class="vm" nowrap>
                          <div class="d-flex">
                            <div class="txt-oflow">{{item?.createdAt | date: 'dd MMM, yyyy'}}</div>
                          </div>
                        </td>
                        <td class="vm" nowrap>
                          <div class="txt-oflow">{{item?.updatedAt | date: 'dd MMM, yyyy'}}</div>
                        </td>
                        <td class="vm" nowrap>
                          <button class="btn btn-link btn-sm" style="position:relative"
                            (click)="openEditLead(editLead2, item)">
                            <div class="tooltip_title">
                              <div class="txt-oflow2">
                                <span>
                                  {{ item.first_name + " " + item.last_name | titlecase }}
                                </span>
                              </div>
                              <span class="tooltiptext">{{ item.first_name + " " + item.last_name | titlecase }}</span>
                            </div>
                          </button>
                          <!-- <div class="txt-oflow">
                            <button class="btn btn-link btn-sm" style="position:relative"
                              (click)="openEditLead(editLead2, item)">
                              {{ item.first_name + " " + item.last_name | titlecase }}
                            </button>
                          </div> -->

                        </td>
                        <td class="vm">
                          <div class="tooltip_title">
                            <div class="txt-oflow2">
                              <span>
                                {{ item.student_email | lowercase }}
                              </span>
                            </div>
                            <span class="tooltiptext">{{ item.student_email | lowercase }}</span>
                          </div>
                          <!-- <div class="txt-oflow">{{ item.student_email | lowercase }}</div> -->
                        </td>
                        <td class="text-capitalize vm">
                          <span *ngIf="item.application_status.toLowerCase() == 'incomplete'">Incomplete</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'submitted'">Submitted</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'in review'">In Review</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'graduation hold'">Graduation
                            Hold</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'grades hold'">Grades Hold</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'accepted'">Accepted</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'deferred'">Deferred</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'reject'">Reject</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'cold'">COLD</span>
                          <span *ngIf="item.application_status.toLowerCase() == 'cancelled'">Cancelled</span>
                        </td>
                        <td class="vm" nowrap>
                          <div class="txt-oflow">
                            <img
                              *ngIf="item.accepted_app_status == 'Accepted early' || item.accepted_app_status == 'AcceptedS'"
                              src="../../../assets/images/accept-status.svg" class="img16 mr-2">
                            <img *ngIf="item.accepted_app_status == 'Accepted, hold for grades'
                              || item.accepted_app_status == 'Accepted, graduation hold'
                              || item.accepted_app_status == 'Accepted, MSCS Approval Pending'
                              || item.accepted_app_status == 'Accepted, business office hold'"
                              src="../../../assets/images/hold-status.svg" class="img16 mr-2">
                            <img *ngIf="item.accepted_app_status == 'Not Applicable'"
                              src="../../../assets/images/reject-status.svg" class="img16 mr-2">
                            {{ item.accepted_app_status == 'AcceptedS' ? 'Accepted' : item.accepted_app_status }}
                          </div>
                        </td>
                        <td class="vm">
                          <!-- <div class="tooltip_title">
                            <div class="txt-oflow2"> -->
                          <span>
                            {{ item.program_enddate_listed | date: 'dd MMM, yyyy'}}
                          </span>
                          <!-- </div>
                            <span class="tooltiptext">{{ item.program_enddate_listed | date: 'dd MMM, yyyy'}}</span> -->
                          <!-- </div> -->
                        </td>
                      </tr>
                      <tr *ngIf="isLoading ==true">
                        <td colspan="10">
                          <div class="norecords">
                            <img src="../../../../assets/img/waiting.gif" />

                            <div>Loading Data<br />Please wait while we fetch the data for you</div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="noRecords == true">
                        <td colspan="10">
                          <div class="norecords">
                            <img src="../../../../assets/img/nodata.svg" />
                            <div>No records found.<br />Please select a different date range.</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>



                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- for Modal -->
        <!--Calling screen from Documents Validation-->
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <!-- Edit Lead New -->
          <ng-template #editLead2 let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Update Information</h4>
              <button type="button" id="closeButton3" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="background: #fff">
              <form class="pt-2" [formGroup]="cunextService.editLead" (ngSubmit)="updateLead()">

                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label>* ID</label>
                    <input class="form-control" name="id_number" formControlName="id_number"
                      [(ngModel)]="leadtoEdit.id_number" />
                    <div *ngIf="edited && editLeads['id_number'].errors" class="invalid-feedback">
                      <div *ngIf="editLeads['id_number'].errors['required']">ID Number is required</div>
                    </div>

                  </div>
                  <div class="form-group col-md-4">
                    <label>* First Name</label>
                    <input class="form-control" name="first_name" formControlName="first_name"
                      [(ngModel)]="leadtoEdit.first_name" />
                    <div *ngIf="edited && editLeads['first_name'].errors" class="invalid-feedback">
                      <div *ngIf="editLeads['first_name'].errors['required']">First Name is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-2">
                    <label>* Last Name</label>
                    <input class="form-control" name="last_name" formControlName="last_name"
                      [(ngModel)]="leadtoEdit.last_name" />
                    <div *ngIf="edited && editLeads['last_name'].errors" class="invalid-feedback">
                      <div *ngIf="editLeads['last_name'].errors['required']">Last Name is required</div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label>* Student Email</label>
                    <input type="text" name="student_email" class="form-control" formControlName="student_email"
                      [(ngModel)]="leadtoEdit.student_email" />

                    <div *ngIf="edited && editLeads['student_email'].errors" class="invalid-feedback">
                      <div *ngIf="editLeads['student_email'].errors['required']">Email is required</div>
                      <div *ngIf="editLeads['student_email'].errors['email']">
                        Email must be a valid email address
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Address Line1</label>
                    <input type="text" name="Address" class="form-control" formControlName="address_line1"
                      [(ngModel)]="leadtoEdit.address_line1" />

                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Address Line2</label>
                    <input type="text" name="Address" class="form-control" formControlName="address_line2"
                      [(ngModel)]="leadtoEdit.address_line2" />

                  </div>
                  <div class="form-group col-md-4">
                    <label for="">City </label>
                    <input type="text" name="city" class="form-control" formControlName="city"
                      [(ngModel)]="leadtoEdit.city" />
                  </div>

                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">State </label>
                    <input type="text" name="state" class="form-control" formControlName="state"
                      [(ngModel)]="leadtoEdit.state" />

                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Country </label>
                    <input type="text" name="country" class="form-control" formControlName="country"
                      [(ngModel)]="leadtoEdit.country" />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Zipcode </label>
                    <input type="text" name="zipcode" class="form-control" formControlName="zipcode"
                      [(ngModel)]="leadtoEdit.zipcode" />
                  </div>

                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputCity"><span class="mandi"> * </span> When would you like to begin
                      your studies?</label>
                    <select class="form-control" formControlName="when_like_to_begin_studies"
                      [(ngModel)]="leadtoEdit.when_like_to_begin_studies" id="exampleFormControlSelect2">
                      <option disabled>Choose one..</option>
                      <option>Begin date - July 1st, 2021</option>
                      <option>Begin date - August 23rd, 2021 </option>
                      <option>Begin date - October 18th, 2021</option>
                      <option>Begin date - January 10th, 2022</option>
                      <option>Begin date - March 14th, 2022</option>
                      <option>Begin date - November 5th, 2022</option>
                    </select>
                    <div *ngIf="edited && editLeads['when_like_to_begin_studies'].errors" class="invalid-feedback">
                      <div *ngIf="editLeads['when_like_to_begin_studies'].errors['required']">required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="inputCity"><span class="mandi"> * </span> What Program ?</label>
                    <select class="form-control" formControlName="what_program" [(ngModel)]="leadtoEdit.what_program"
                      id="exampleFormControlSelect4">
                      <option disabled>Choose one..</option>
                      <option>PMBA With Information Technology Management Track *CPT Eligible*
                      </option>
                      <option>PMBA With Human Resources Management Track *CPT Eligible*</option>
                      <option>PMBA With Healthcare Management Track *CPT Eligible*</option>
                      <option>PMBA With Marketing Track *CPT Eligible*</option>
                      <option>PMBA With International Business Track *CPT Eligible*</option>
                      <option>PMBA With Accounting Track *CPT Eligible*</option>
                      <option>PMBA With Computer Science Track *CPT Eligible*</option>
                      <option>PMBA With Entrepreneurial Leadership Track *CPT Eligible*</option>
                      <option>PMBA With No Track *CPT Eligible*</option>
                      <option>Master of Science in Computer Science *CPT Eligible*</option>
                      <option>Master of Management And Leadership *CPT Eligible*</option>
                      <option>Master of Science In Information Technology Management *CPT Eligible*
                      </option>
                      <option>Master of Science in Data Science and Artificial Intelligence *CPT
                        Eligible*</option>
                      <option>Master of Science in Cloud Computing *CPT Eligible*</option>
                      <option></option>
                    </select>
                    <div *ngIf="edited && editLeads['what_program'].errors" class="invalid-feedback">
                      <div *ngIf="editLeads['what_program'].errors['required']">required</div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputCity"><span class="mandi"> * </span> What is your current program
                      end date listed on your I-20 ?</label>
                    <input type="date" [(ngModel)]="leadtoEdit.program_enddate_listed"
                      formControlName="program_enddate_listed" class="form-control" id="inputCityx">
                  </div>
                  <div *ngIf="edited && editLeads['program_enddate_listed'].errors" class="invalid-feedback">
                    <div *ngIf="editLeads['program_enddate_listed'].errors['required']">required</div>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="exampleFormControlSelect1">Application Status
                    </label>
                    <select formControlName="application_status" [(ngModel)]="leadtoEdit.application_status"
                      class="form-control" id="exampleFormControlSelect1">
                      <option value="Incomplete">Incomplete</option>
                      <option value="Submitted">Submitted</option>
                      <option value="In Review">In Review</option>
                      <option value="Graduation Hold">Graduation Hold
                      </option>
                      <option value="Grades Hold">Grades Hold</option>
                      <option value="Accepted">Accepted</option>
                      <option value="Deferred">Deferred</option>
                      <option value="Reject">Reject</option>
                      <option value="COLD">COLD</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="disabledSelect">Accepted Application Status</label>
                    <select class="form-control" formControlName="accepted_app_status"
                      [(ngModel)]="leadtoEdit.accepted_app_status" id="disabledSelect">
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Accepted, hold for grades">
                        Accepted, hold for grades</option>
                      <option value="Accepted, graduation hold">
                        Accepted, graduation hold</option>
                      <option value="Accepted early">Accepted early
                      </option>
                      <option value="AcceptedS">Accepted</option>
                      <option value="Accepted, MSCS Approval Pending">Accepted, MSCS Approval Pending
                      </option>
                      <option value="Accepted, business office hold">Accepted, business office hold
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6"
                    *ngIf="leadtoEdit.application_status == 'Cancelled' || leadtoEdit.application_status == 'Reject'">
                    <label for="inputCity"><span class="mandi"> * </span> Reject Reason </label>
                    <textarea rows="1" cols="50" [(ngModel)]="leadtoEdit.reject_reason" formControlName="reject_reason"
                      class="form-control" id="inputCityx"></textarea>
                    <div *ngIf="rejectReason" class="invalid-feedback">
                      <div>Reason required</div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end">
                  <div class="mt-3">
                    <button class="btn btn-light btn-sm mr-2" type="button"
                      (click)="modal.dismiss('Cross click')">Cancel</button>
                    <button class="btn btn-primary btn-sm" type="submit">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
        </div>
      </div>