import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-calls-dashboard',
  templateUrl: './agent-calls-dashboard.component.html',
  styleUrls: ['./agent-calls-dashboard.component.css']
})
export class AgentCallsDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
