import { Template as Tp } from "./create-agent/models/template.model";
// import { HttpClientService } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { HttpClientService } from "../httpclient.service";

interface Template {
  Name: string;
  Creator: string;
  FromEmail: string;
  Design: Object;
  Html: string;
  companyId: any;
}
interface profileUpload {
  photo: string;
  id: number;
}
interface UpdateTemplate {
  Html: String;
  Design: Object;
  Active: boolean;
  id: String;
  FromEmail: string;
}
interface SendMail {
  To: string;
  From: { email: string; name: string };
  Subject: string;
  Body: object;
  ReplyTo: string;
}
@Injectable({
  providedIn: "root",
})
export class MailserviceService {
  baseApiUrl2: any = environment.baseApiUrl2;
  twilioApiUrl: any = environment.twilioApiUrl;

  htmlTemplate = new BehaviorSubject<string>("");

  constructor(private http: HttpClientService) { }

  createTemplate(body: Template) {
    return this.http.post(`${this.baseApiUrl2}createTemplate`, body);
  }
  getAllTemplates(companyId) {
    return this.http.get<Tp[]>(`${this.baseApiUrl2}allTemplates/${companyId}`);
  }
  getTemplateById(id) {
    return this.http.get(`${this.baseApiUrl2}getTemplateById/` + id);
  }
  updateTemplate(body: UpdateTemplate) {
    return this.http.put(
      `${this.baseApiUrl2}updateTemplate/` + body["id"],
      body
    );
  }
  getTemplateByName(name: string) {
    return this.http.get(`${this.baseApiUrl2}templateByName/` + name);
  }

  getTemplateCompany(name: string, companyId) {
    return this.http.get(`${this.baseApiUrl2}getTemplate/${companyId}/${name}`);
  }

  sendmail(body: SendMail, companyId) {
    return this.http.post<any>(
      `${this.twilioApiUrl}${companyId}/mail/send`,
      body
    );
  }
  getConfiguration(name) {
    return this.http
      .get<any>(`${this.baseApiUrl2}configureByName/${name}`)
      .pipe(
        map((data) => {
          return data["Value"];
        })
      );
  }
  updateConfiguration(body: { name: string; value: string }) {
    return this.http.put(`${this.baseApiUrl2}updateConfiguration`, body);
  }

  uploadFile(body: FormData) {
    return this.http.post(`${this.baseApiUrl2}fileUpload`, body);
  }

  updateProfilePhoto(body: profileUpload) {
    return this.http.put(`${this.baseApiUrl2}updateProfile`, body);
  }

  deleteTemplate(id) {
    return this.http.post(`${this.baseApiUrl2}deleteTemplates`, { id: id });
  }

  getMarketingTemplates(companyId) {
    return this.http.get<any[]>(
      `${this.baseApiUrl2}getMarketingTemplates/${companyId}`
    );
  }
}
