import { MarketingService } from "./../../marketing/marketing.service";
import { GoogledirectionsComponent } from "./../googledirections/googledirections.component";
import { MapsComponent } from "./../maps/maps.component";
import { AuthService } from "./../../../account/auth/auth.service";
import { NgForm } from "@angular/forms";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  NgZone,
  ElementRef,
  ContentChild,
  ViewChildren,
  OnDestroy,
} from "@angular/core";
import { SharedService } from "src/app/shared/shared.service";
import { TicketsService } from "../tickets.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { TicketHistory } from './models/tickethistory.model';
import { MailserviceService } from "../../mailservice.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
// import { MatMenuTrigger } from "@angular/material";
import { GroupService } from "../../group.service";
import { Subscription } from "rxjs";
import { CreateAgent } from "../../create-agent/create-agent.service";
import { LayoutService } from "src/app/layout/layout.service";
import { RoleService } from "../../role-management/role.service";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { ProjectService } from "../../project-management/project.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";

declare let $: any;

@Component({
  selector: "app-add-update-view-tickets",
  templateUrl: "./add-update-view-tickets.component.html",
  styleUrls: ["./add-update-view-tickets.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AddUpdateViewTicketsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("f", { static: true }) form: NgForm;
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
  @ViewChildren("latest") latestTags;

  tickethistory: any[] = [];
  isUpdate: boolean = false;
  assignedto: { name: string; id: string }[] = [];
  selectedassigned: any;
  dropdownSettings: IDropdownSettings = {};
  closeDropdownSelection = false;
  disabled = false;
  assignmentgroup: { name: string; id: string }[] = [];
  priority: any = [];
  urgency: any = [];
  selectedurgency: any = [];
  selectedpriority: any = [];
  selectedgroup: any[] = [];
  createdDate: any = "";
  updatedDate: any;
  followupdate: any;
  statusselected: any = [];
  status: any[] = ["open", "inprogress", "hold", "closed"];
  detial: any;
  desc: any;
  latestupdate: any;
  ReportedBy: any;
  urlid: any;
  ticketid: any;
  updatedby: any;
  template: any = "";
  emailView: any = "";
  isMail: boolean = false;
  ticketChannel: string = "accessque";
  customerTickets: any[] = [];
  smsMessage: any;
  userData: any;
  fromMail: string = "support@accessque.com";
  public agents: any[] = [];
  public ticketChat: any = null;
  public address: string = "";
  public latlng: any;
  public fromNumber: string;
  public loadMoreHistory: number = 5;

  public lastUpdate: string = "";

  customer: object = {
    id: "",
    mail: "",
    phoneNumber: "",
    preferedContact: "mail",
    lastContacted: "",
    profilePhoto: { downloadUrl: "assets/img/user.svg" },
  };

  public closeResult: string = "";
  public attachments: any[] = [];
  error = "";
  public lastStatus: any;
  public superAdmin: Boolean = false;
  public projectId: any;
  public adminSubscription: Subscription;
  public selectIndex: number = 0;
  public agentLocation: any = null;
  public project: Subscription;
  public currentProject: any = {};

  constructor(
    public sharedService: SharedService,
    public ticketsService: TicketsService,
    public datepipe: DatePipe,
    public router: Router,
    public route: ActivatedRoute,
    private mailService: MailserviceService,
    private modalService: NgbModal,
    private groupService: GroupService,
    private authService: AuthService,
    private agentService: CreateAgent,
    private readonly companyService: RoleService,
    private readonly marketingService: MarketingService,
    private readonly _snackBar: MatSnackBar,
    private readonly projectService: ProjectService,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,

  ) { }

  ngAfterViewInit() { }
  ngOnDestroy(): void {
      if (this.project) {
      this.project.unsubscribe();
    }
  }

  ngOnInit() {
    let value = JSON.parse(localStorage.getItem("userData"));
    this.userData = value;
    this.sharedService.saveAddress.subscribe((data) => {
      if (data !== null) {
        if (data["types"] === "ticket") {
          this.latlng = data["latlng"];
          this.address =
            data["address"] != null
              ? data["address"].replace(/['"]+/g, "")
              : "";
        }
      } else {
      }
    });

    // this.adminSubscription = this.authService.companyAdmin.subscribe((data) => {
    //   if (this.userData["companyId"] !== 0) {
    //     this.superAdmin = data;
    //   }
    // });

    this.agentService.getAgentNames().subscribe((data) => {
      this.agents = data;
    }, (error) => {
      this.handleError.handleError(error)
    });

    // this.project = this.sharedService.projectId.subscribe((data) => {
    //   if (data) {
    //     this.projectId = data;
    //     this.getGroupsAndAgent();
    //     this.projectService.getProject(this.projectId).subscribe((data) => {
    //       this.fromMail = data["SupportMail"];
    //       this.fromNumber = data["TwilioNumber"];
    //     });
    //     this.getTemplate(data);
    //     this.sharedService.currentProject.subscribe((data) => {
    //       if (data) {
    //         this.currentProject = data;
    //       }
    //     });
    //   }
    // });

    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.getGroupsAndAgent();
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.fromMail = data["SupportMail"];
        this.fromNumber = data["TwilioNumber"];
      }, (error) => {
        this.handleError.handleError(error)
      });
      this.getTemplate(this.projectId);
      this.sharedService.currentProject.subscribe((data) => {
        if (data) {
          this.currentProject = data;
        }
      });
    }, 500)

    this.route.params.subscribe((res) => {
      if (res["id"]) {
        this.urlid = res["id"];
        this.isUpdate = true;
        this.prefillfeild(res["id"]);
      } else {
        if (res["phoneNumber"]) {
          this.customer["phoneNumber"] = res["phoneNumber"];
          this.ticketChannel = "call";
          this.getCustomerByPhone(res["phoneNumber"]);
        }
        if (res["email"]) {
          this.customer["mail"] = res["email"];
        }
        if (res["name"]) {
          this.ReportedBy = res["name"];
        }
      }
    });

    if (!this.isUpdate) {
      this.ticketsService.getticketid().subscribe((res) => {
        this.ticketid = res["ticketnumber"];
      }, (error) => {
        this.handleError.handleError(error)
      });
    }

    this.updatedby = value["mail"];

    if (!this.isUpdate) {
      this.selectedassigned = value["mail"];
      this.groupService.getAgentGroups(value["id"]).subscribe((data) => {
        
        if (data[0] && this.userData["companyId"] !== 0) {
          this.form.form.patchValue({
            assignmentgroup: data[0]["id"],
          });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });

      this.selectedpriority.push("Priority 3");
      this.selectedurgency.push("low");
      this.statusselected.push("open");
      this.lastStatus = "open";
      const now = moment();
      this.createdDate = this.datepipe.transform(Date.now(), "medium").toString();
      this.updatedDate = this.createdDate;
      // this.customer["lastContacted"] = this.createdDate;
    }

    const now = moment().add(2, "days").toDate();

    this.followupdate = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };

    this.sharedService.pageEvent.emit({
      pageName: "Add ticket",
      togglePage: true,
    });

    this.dropdownSettings = {
      singleSelection: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
    };

    // let sub = this.sharedService.CompanyId.subscribe((data) => {
    //   this.companyId = data;
    //   if (value["companyId"] === 0) {
    //   }
    // });

    // if (!(value.companyId === 0)) {
    //   this.companyId = value.companyId;
    // }

    // this.agentService.GetAllGroups(this.companyId).subscribe((res: any[]) => {
    //   this.assignmentgroup = res.map((item) => {
    //     return { name: item["Name"], id: item["id"] };
    //   });
    // });

    // this.ticketsService.getUsersList(this.companyId).subscribe((res: any[]) => {
    //   this.assignedto = res.map((item) => {
    //     return { name: item["Mailid"], id: item["id"] };
    //   });
    // });

    this.ticketsService.getpriorityList().subscribe((res: any[]) => {
      this.priority = res.map((item) => {
        return item["Name"];
      });
    }, (error) => {
      this.handleError.handleError(error)
    });

    this.ticketsService.geturgencyList().subscribe((res: any[]) => {
      this.urgency = res.map((item) => {
        return item["Name"];
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  open(content, type) {
    
    this.modalService
      .open(content, { size: type === "phone" ? "md" : "lg" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    
  }

  mapContentOpen(type) {
    if (type === "customer") {
      this.modalService.open(MapsComponent);
      if (this.latlng) {
        this.sharedService.saveAddress.next({
          latlng: this.latlng,
          address: this.address.replace(/['"]+/g, ""),
          types: "ticket",
        });
      } else {
        this.sharedService.saveAddress.next({
          latlng: null,
          types: "ticket",
          address: null,
        });
      }
    } else {
      this.sharedService.traceLocation.next({
        source: this.agentLocation,
        destination: this.latlng,
      });
      this.modalService.open(GoogledirectionsComponent);
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getGroupsAndAgent() {
    this.agentService.GetAllGroups(this.projectId).subscribe((res: any[]) => {
      this.assignmentgroup = res.map((item) => {
        return { name: item["Name"], id: item["id"] };
      });
    }, (error) => {
      this.handleError.handleError(error)
    });

    this.ticketsService.getUsersList(this.projectId).subscribe((res: any[]) => {
      this.assignedto = res.map((item) => {
        return { name: item["Mailid"], id: item["id"] };
      });
     }, (error) => {
        this.handleError.handleError(error)
      });
  }

  prefillfeild(id) {
    return this.ticketsService.getTicketByID(id).subscribe((res) => {
      setTimeout(() => {
        this.form.form.patchValue({
          assignedto: res["Assignedto"],
          assignmentgroup: res["AssignmentGroup"],
          status: [res["RequestStatus"]],
          priority: [res["Priority"]],
          urgency: [res["Urgency"]],
        });
        this.ticketsService
          .getAgentLocation(res["Assignedto"])
          .subscribe((data) => {
            if (data["LatLng"]) {
              this.agentLocation = data["LatLng"];
            }
          }, (error) => {
            this.handleError.handleError(error)
          });
        this.lastStatus = res["RequestStatus"];
        let datecreate = res["CreatedDate"];
        let dateupdated = res["UpdatedDate"];
        this.createdDate = this.datepipe
          .transform(datecreate, "medium")
          .toString();
        this.updatedDate = this.datepipe
          .transform(dateupdated, "medium")
          .toString();
        this.detial = res["RequestDetail"];
        this.desc = res["RequestDescription"]
          ? res["RequestDescription"].replace(/^\s*\n/gm, "")
          : "";
        this.emailView =
          res["EmailDetails"] === null ? "" : res["EmailDetails"];
        
        this.lastUpdate = res["LatestUpdate"].replace(/^\s*\n/gm, "");
        this.latestupdate = "";
        this.ReportedBy = res["ReportedBy"];
        this.ticketid = res["RequestId"];
        this.ticketsService.getWebChat(this.ticketid).subscribe((data) => {
          if (data) {
            this.ticketChat = data["Conversation"];
            
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
        this.ticketChannel = res["TicketChannel"];

        let attachments = res["Attachements"]
          ? res["Attachements"].map((val) => JSON.parse(val))
          : [];
        for (let attach of attachments) {
          this.agentService
            .getSignedUrl({ url: attach["url"] })
            .subscribe((datas) => {
              this.attachments.push({
                name: attach["name"],
                url: datas["url"],
                downloadUrl: datas["url"],
              });
              
            }, (error) => {
              this.handleError.handleError(error)
            });
        }
        this.ticketsService.gettickethistory(this.ticketid).subscribe((res) => {
          this.tickethistory = res;
          
        }, (error) => {
          this.handleError.handleError(error)
        });

        this.ticketsService
          .getCustomerByTicket(this.ticketid)
          .subscribe((data) => {
            this.customer["mail"] = data["Mailid"];
            this.customer["phoneNumber"] = data["PhoneNumber"];
            this.customer["lastContacted"] = this.datepipe
              .transform(data["LastContacted"], "dd/MM/yy, hh:mm a")
              .toString();
            this.customer["preferedContact"] = data["PrefferedContact"];
            if (data["ProfilePhoto"]) {
              this.customer["profilePhoto"]["downloadUrl"] =
                data["ProfilePhoto"];
            }
            if (data["Address"]) {
              this.address = data["Address"].replace(/['"]+/g, "");
            }
            this.latlng = data["LatLng"];

            this.customer["id"] = data["id"];
            this.ticketsService
              .getCustomerTickets(data["id"], this.projectId)
              .subscribe((data) => {
                if (data.length > 0) {
                  this.customerTickets = data;
                }
              }, (error) => {
                this.handleError.handleError(error)
              });
            }, (error) => {
              this.handleError.handleError(error)
            });
      }, 3);
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  toggleClass() {
    $("#chatDiv").toggleClass("toggleChat");
    $("#chatSideBar").toggleClass("toggleSideBar");
    this.sharedService.pageEvent.emit({ togglePage: true });
  }

  submit(value, valid, form) {
    
    if (this.isMail) {
      if (!this.customer["mail"]) {
        this.selectIndex = 2;
        // setTimeout(() => {
        //   this.isMail = false;
        // }, 5000)
        return;
      }
    }
    if (valid) {
      const ticket: Object = {
        ticketid: this.ticketid,
        reportedby: value.reportedby,
        assignedto: value.assignedto,
        priority: value.priority[0],
        group: value.assignmentgroup,
        urgency: value.urgency[0],
        detail: this.detial,
        desc: this.desc,
        latestupdate: value.latestupdate,
        status: value.status[0],
        updatedby: this.updatedby,
        mail: this.customer["mail"],
        preferedContact: this.customer["preferedContact"],
        phoneNumber: this.customer["phoneNumber"],
        attachements: this.attachments,
        companyId: this.projectId,
        latlng: this.latlng,
        address: this.address.replace(/['"]+/g, ""),
        profilePhoto: this.customer["profilePhoto"],
        // ticketChannel:''
      };
      

      if (this.isMail || value.status[0] !== this.lastStatus) {
        let replacements = {
          ticketid: this.ticketid,
          activity: this.latestupdate,
          name: this.ReportedBy,
        };

        const result = this.template.replace(/{{([^{{]+)}}/g, (match, key) => {
          // If there's a replacement for the key, return that replacement with a `<br />`. Otherwise, return a empty string.
          return replacements[key] !== undefined ? replacements[key] : "";
        });
        const details = `<div style='padding:8px 0;'><strong>To:</strong> ${this.customer["mail"]
          }</div>
                          <div style='padding:8px 0;'><strong>From:</strong> support@accessque.com</div>
                          <div>
                          <div style='padding:8px 0;border-top:1px solid #f1f1f1;border-bottom:1px solid #f1f1f1'><strong>Subject:</strong> ${this.detial
          }: ${this.ticketid}</div><div>
                          <div style='padding:8px 0;'> <strong style="padding-right:4px">Time Stamp:</strong> 
                          ${this.datepipe
            .transform(Date.now(), "medium")
            .toString()} </div>
        `;
        const mailBody = this.emailView ? this.emailView : "";

        this.emailView =
          "<div><br></div>" +
          details +
          "<div><br></div>" +
          result +
          "<div><br></div>" +
          this.emailView || "" + "<div><br></div>";
        ticket["emailDetails"] = this.emailView || details + result;

        this.mailService
          .sendmail(
            {
              To: this.customer["mail"].trim(),
              From: {
                email: "support@accessque.com",
                name: this.currentProject["Name"],
              },
              Subject: this.detial + ` : ${this.ticketid}`,
              Body: {
                html:
                  result + "<div><br></div>" + mailBody ||
                  "" + "<div><br></div>",
              },
              ReplyTo: this.fromMail,
            },
            this.projectId
          )
          .subscribe((data) => {  }, (error) => {
            this.handleError.handleError(error)
          });
      }

      if (this.isUpdate) {
        this.ticketsService
          .updateCustomer(
            {
              mail: this.customer["mail"],
              preferedContact: this.customer["preferedContact"],
              customerName: this.ReportedBy,
              lastContacted: this.customer["lastContacted"],
              phoneNumber: this.customer["phoneNumber"],
              address: this.address.replace(/['"]+/g, ""),
              latlng: this.latlng,
              profilePhoto: this.customer["profilePhoto"],
            },
            this.customer["id"]
          )
          .subscribe(
            (data) => {
              
            }, (error) => {
              this.error = "something went wrong!! please try again";
              setTimeout(() => {
                this.error = "";
              }, 5000);
              this.handleError.handleError(error)
            });
        if (ticket["status"] === "open") {
          ticket["status"] = "inprogress";
        }

        return this.ticketsService.updateTicket(ticket, this.urlid).subscribe(
          (res) => {
            this.router.navigate(["/dashboard/dashboard-view"]);
            this._snackBar.open("Ticket Updated Successfully", "Close", {
              verticalPosition: "top",
              horizontalPosition: "end",
              duration: 5000,
            });
          }, (error) => {
            this.error = "something went wrong!! please try again";
            setTimeout(() => {
              this.error = "";
            }, 5000);
            this.handleError.handleError(error)
          });
      }

      ticket["ticketChannel"] = this.ticketChannel;

      this.ticketsService.createTicket(ticket).subscribe(
        (res) => {
          this.router.navigate(["/dashboard/dashboard-view"]);
          this._snackBar.open("Ticket Created Successfully", "Close", {
            verticalPosition: "top",
            horizontalPosition: "end",
            duration: 5000,
          });
        }, (error) => {
          this.error = "something went wrong!! please try again";
          setTimeout(() => {
            this.error = "";
          }, 5000);
          this.handleError.handleError(error)
        });
    } else {
      this.selectIndex = 0;
    }
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      closeDropDownOnSelection: this.closeDropdownSelection,
    });
  }

  onuserselect(event) {
    if (event) {
      this.ticketsService.getgroupsusers(event).subscribe((res) => {
        this.assignmentgroup = res.map((group) => ({
          name: group["Name"],
          id: group["id"],
        }));
      }, (error) => {
        this.handleError.handleError(error)
      });
      this.ticketsService.getAgentLocation(event).subscribe((res) => {
        if (res["LatLng"]) {
          this.agentLocation = res["LatLng"];
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
      this.statusselected =
        this.statusselected[0] === "unassigned"
          ? ["open"]
          : this.statusselected;
    }
  }

  ongroupselect(event) {
    this.ticketsService.getusersgroups(event).subscribe((res) => {
      this.assignedto = res.map((user) => ({
        name: user["Mailid"],
        id: user["id"],
      }));
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  customerSearch(value) {
    if (value) {
      this.ticketsService.getCustomerByMail(value).subscribe((data) => {
        if (!data["status"]) {
          this.ReportedBy = data["Name"];
          this.customer["phoneNumber"] = data["PhoneNumber"];
          this.customer["preferedContact"] = data["PrefferedContact"];
          this.customer["lastContacted"] = data["LastContacted"];
          
          this.customer["id"] = data["id"];
          this.latlng = data["LatLng"];
          this.customer["mail"] = data["Mailid"];
          if (data["Address"]) {
            this.address = data["Address"].replace(/['"]+/g, "");
          }
          this.ticketsService
            .getCustomerTickets(data["id"], this.projectId)
            .subscribe((data) => {
              if (data.length > 0) {
                this.customerTickets = data;
              }
            }, (error) => {
              this.handleError.handleError(error)
            });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
  }

  uploadFile(file: HTMLInputElement) {
    file.click();
  }

  getCustomerByPhone(value) {
    this.ticketsService.getCustomerByPhone(value).subscribe((data) => {
      if (!data["status"]) {
        this.ReportedBy = data["Name"];
        this.customer["phoneNumber"] = data["PhoneNumber"];
        this.customer["preferedContact"] = data["PrefferedContact"];
        this.customer["lastContacted"] = data["LastContacted"];
        this.customer["mail"] = data["Mailid"];
        
        this.customer["id"] = data["id"];
        this.latlng = data["LatLng"];
        if (data["Address"]) {
          this.address = data["Address"].replace(/['"]+/g, "");
        }
        this.ticketsService
          .getCustomerTickets(data["id"], this.projectId)
          .subscribe((data) => {
            if (data.length > 0) {
              this.customerTickets = data;
            }
          }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  uploadFiletoServer(value, type) {
    let reader = new FileReader();
    reader.readAsDataURL(value.target.files[0]);
    
    const body = new FormData();
    body.append("image", value.target.files[0]);
    this.mailService.uploadFile(body).subscribe((data) => {
      this.agentService
        .getSignedUrl({ url: data["url"] })
        .subscribe((datas) => {
          if (type === "attachments") {
            this.attachments.push({
              name: data["name"],
              url: data["url"],
              downloadUrl: datas["url"],
            });
          } else if (type === "profile") {
            this.customer["profilePhoto"] = {
              name: data["name"],
              url: data["url"],
              downloadUrl: datas["url"],
            };
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  changeTab() {
    this.selectIndex = 0;
  }

  sendSms() {
    this.marketingService
      .sendSms(
        {
          from: this.fromNumber,
          to: this.customer["phoneNumber"],
          text: this.smsMessage,
        },
        this.projectId
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll();
          this.smsMessage = "";
        }, (error) => {
          this.modalService.dismissAll();
          this.handleError.handleError(error)
        });
  }

  increaseText(tag: number, value) {
    let text = this.latestTags.toArray()[tag];
    
    
    if (text.nativeElement.getAttribute("content") === "more") {
      text.nativeElement.innerText = value;
      text.nativeElement.setAttribute("content", "less");
    } else {
      text.nativeElement.innerText = value.slice(0, 100);
      text.nativeElement.setAttribute("content", "more");
    }
  }

  getTemplate(projectId: any) {
    this.mailService
      .getTemplateCompany("ticket", projectId)
      .subscribe((data) => {
        this.template = data["Html"];
      }, (error) => {
        this.handleError.handleError(error)
      });
  }
  projectChange(data) {
    this.projectId = data;
    this.getGroupsAndAgent();
    this.projectService.getProject(this.projectId).subscribe((data) => {
      this.fromMail = data["SupportMail"];
      this.fromNumber = data["TwilioNumber"];
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.getTemplate(data);
    this.sharedService.currentProject.subscribe((data) => {
      if (data) {
        this.currentProject = data;
      }
    });
  }
}
