import { EmailsettingsComponent } from "./../emailsettings/emailsettings.component";
import { Router } from "@angular/router";
import { Template } from "./../create-agent/models/template.model";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { MailserviceService } from "../mailservice.service";
import { SharedService } from "src/app/shared/shared.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";

@Component({
  selector: "app-templates",
  templateUrl: "./templates.component.html",
  styleUrls: ["./templates.component.less"],
})
export class TemplatesComponent implements OnInit, OnDestroy {
  templates: Template[] = [];
  search: string;
  userData: any;
  projectId: any;
  currentTemplateHtml: any;
  project: Subscription;
  public page: any = 1;
  public pageSize: any = 8;

  constructor(
    private router: Router,
    private mailService: MailserviceService,
    private sharedService: SharedService,
    private handleError: ErrorHandlerService,
    private modalService: NgbModal, // private modalRef: NgbModalRef
    public projectchangeService: ProjectchangeService
  ) {}

  ngOnDestroy(): void {
    if (this.project) {
      this.project.unsubscribe();
    }
  }
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // if (this.userData["companyId"] !== 0) {
    //   this.companyId = this.userData["companyId"];
    //   this.fetchTemplates(this.companyId);
    // }
    // this.project = this.sharedService.projectId.subscribe((data) => {
    //   if (data) {
    //     this.projectId = data;
    //     this.fetchTemplates(data);
    //     // this.sharedService.getProjectAdmin(this.projectId).subscribe(data=>{
    //     //   this.admin
    //     // })
    //     // this.companId = data;
    //   }
    // });
    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.fetchTemplates(this.projectId);
    },500)
    this.sharedService.pageEvent.emit({ pageName: "Mail Templates" });
  }

  updateTemplate(id) {
    
    this.router.navigate(["/settings/email/", id]);
  }

  fetchTemplates(companyId) {
    this.mailService.getAllTemplates(companyId).subscribe((data) => {
      
      this.templates = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  editModal(templateId) {
    
    let modal = this.modalService.open(EmailsettingsComponent, { size: "xl" });
    modal.componentInstance.templateId = templateId;
    modal.result.then(
      (data) => {
        this.fetchTemplates(this.projectId);
      },
      (err) => {
        this.fetchTemplates(this.projectId);
      }
    );
  }

  open(content, html) {
    this.currentTemplateHtml = html;
    this.modalService.open(content, { size: "lg" });
  }

  deleteTemplate(id) {
    this.mailService.deleteTemplate(id).subscribe((data) => {
      this.fetchTemplates(this.projectId);
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  createTemplate() {
    let modal = this.modalService.open(EmailsettingsComponent, { size: "xl" });
    modal.result.then(
      (data) => {
        this.fetchTemplates(this.projectId);
      },
      (err) => {
        this.fetchTemplates(this.projectId);
      }
    );
  }
  projectChange(data) {
    this.projectId = data;
    this.fetchTemplates(data);    
  }
}
