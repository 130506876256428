<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Demo Screen</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismissAll()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="height: 455px; overflow-y: hidden">
  <div class="row">
    <div class="col-md-4">
      <div class="ndialer">
        <div>
          <div class="end-call">
            <span style="font-size: 14px; font-weight: 400">Demo Ended</span>
            <div class="custom-user-img my-3 mt-5">
              <img class="img80" src="assets/img/user-w.svg" alt="" />
            </div>
            <br />
            {{ name }}
          </div>
          <div class="update-text">Update the Comments</div>
        </div>

        <!--.......code for keypaid screen.........  -->

        <!--.......code for keypaid screen.........  -->
      </div>
    </div>
    <div class="col-md-8" style="position: relative">
      <div class="my-2">
        <!-- <button
          class="btn btn-primary btn-sm"
          style="position: absolute; right: 15px; top: 0px"
          [routerLink]="[
            '/tickets/ticket-views',
            marketingId.phoneNumber,
            marketingId['email'],
            marketingId['name']
          ]"
        >
          Create Ticket
        </button> -->
        <label for="comments" class="form-label" style="font-weight: bold"
          >Comments</label
        >
        <textarea
          class="form-control mb-3"
          style="font-size: 13px; border: 1px solid #ced4da"
          rows="4"
          [(ngModel)]="comments"
        ></textarea>
        <ng-container>
          <label class="form-label" style="font-weight: bold">Feedback</label>
          <div class="pt-2 pb-1">
            <ul style="padding: 0; margin: 0">
              <li class="d-inline-block mr-4">
                <input type="radio" value="interested" [(ngModel)]="callBack" />
                Interested
              </li>
              <li class="d-inline-block mr-4">
                <input
                  type="radio"
                  value="notinterested"
                  [(ngModel)]="callBack"
                />
                Not Interested
              </li>
              <li class="d-inline-block mr-4">
                <input type="radio" value="followup" [(ngModel)]="callBack" />
                Follow Up
              </li>
            </ul>
          </div>
          <hr
            style="height: 1px; border-top: 1px solid #ced4da; margin: 10px 0"
          />
          <div style="clear: both"></div>
          <!-- Interested Section starts-->
          <label
            class="form-label pt-1 pb-1"
            style="font-weight: bold"
            *ngIf="callBack === 'followup'"
            >Set Demo</label
          >
          <div style="clear: both"></div>
          <!--Dropdowns-->

          <!-- Interested Section ends-->

          <div class="form-row" *ngIf="callBack === 'followup'">
            <div class="form-group col-md-5">
              <label for="inputEmail4">Date & Time</label>
              <div
                class="form-group"
                style="margin-bottom: 0; font-size: 13px !important"
              >
                <app-date-time-picker
                  [(ngModel)]="followUpdate"
                  class="red"
                ></app-date-time-picker>
                <!-- name="followUpdate" -->
              </div>
            </div>
            <!-- <div class="col-md-2" *ngIf="callBack === 'interested'">
              <button
                class="btn btn-secondary btn-sm mt-4 float-left"
                (click)="scheduleDemo()"
              >
                Schedule
              </button>
            </div> -->
            <div class="col-md-2">
              <button
                class="btn btn-primary btn-sm mt-4 float-left"
                (click)="updateComments()"
              >
                Update
              </button>
            </div>
            <div style="clear: both"></div>
          </div>
          <div class="form-row" *ngIf="callBack !== 'followup'">
            <div class="col">
              <button
                class="btn btn-primary btn-sm mt-4 float-left"
                (click)="updateComments()"
              >
                Update
              </button>
            </div>
            <div style="clear: both"></div>
          </div>
        </ng-container>

        <div class="alertmess" role="alert" *ngIf="isMouSent">
          <img src="../assets/img/scheduled.svg" class="img18" /> &nbsp; MOU
          Sent Successfully
        </div>
        <div class="alertmess" role="alert" *ngIf="isUpdatedComments">
          <img src="../assets/img/scheduled.svg" class="img18" /> &nbsp;
          Comments Are Updated
        </div>
        <div class="alertmess" role="alert" *ngIf="isFollowUp">
          <img src="../assets/img/scheduled.svg" class="img18" /> &nbsp; Demo is
          Scheduled
        </div>

        <!-- <div class="alert alert-info text-center" role="alert">
          Calls Started 
        </div> -->
        <!-- <div class="form-group col-md-4">
            <label for="inputPassword4">Call Back</label>
            <select [(ngModel)]="callBack" class="form-control">
              <option>interested</option>
              <option>not interested</option>
            </select>
          </div> -->

        <div class="row">
          <div class="col-md-3"></div>
        </div>
      </div>
    </div>
  </div>
</div>
