import { Component, OnInit, ViewChild, Inject, ElementRef } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { SharedService } from "src/app/shared/shared.service";
import { RecruiterService } from "./recruiter.service";
import { UhLeadsService } from "../uhleads.service";
import * as xlsx from "xlsx";
import { DOCUMENT } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { LayoutService } from "src/app/layout/layout.service";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { MomentFormatService } from "src/app/moment-format-service";
import { post } from "jquery";
import { MarketingService } from "../marketing/marketing.service";
import { RoleService } from "../role-management/role.service";
import { CreateAgent } from "../create-agent/create-agent.service";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
import * as moment from "moment";
import { UhubDashboardService } from "../uhub-dashboard/uhub-dashboard.service";
var sortJsonArray = require("node_modules/sort-json-array");
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";


export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = "^[a-zA-Z /'.-]*$",
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}

@Component({
  selector: "app-recruiter",
  templateUrl: "./recruiter.component.html",
  styleUrls: ["./recruiter.component.css"],
})
export class RecruiterComponent implements OnInit {
  userData: any;
  projectId: any;
  admin: any;
  public error: boolean = false;
  public errorMessage: string = "";
  public cardType: string = "all";
  public filePath: any;
  public fileData: ReferralData[] = [];
  recruiterList: any[] = [];
  studentList: any[] = [];
  enrolledStudentList: any[] = [];
  public page: any = 1;
  public pageSize: any = 10;
  filterString: any;
  noLeadsFound: boolean = false;
  leadsLoading: boolean = true;
  totalRecords = 0;
  sideViewerData: any = [];
  tab!: string;
  studentSearch: string = '';

  topBarCount: {
    all: number;
    totalrecruiters: number;
    totalFreelancer: number;
    enrolled: number;
    registered: number;
    paymentpending: number;
    paymentscomplete: number;
  } = {
      all: 0,
      totalrecruiters: 0,
      totalFreelancer: 0,
      enrolled: 0,
      registered: 0,
      paymentpending: 0,
      paymentscomplete: 0,
    };
  leadType: string = "totalrecruiters";
  dialerScreen1 = "dialer";
  callStatus: string = "Calling..";
  newStatus: any;
  applicationStatus: any = "";
  public callData: any[] = [];
  public startIndex: number = 0;
  selectedLead: any;
  startCall: boolean = false;
  public stopCall: boolean = false;
  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };
  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;
  testConfig: countUpTimerConfigModel;
  public clearTime: any;
  minItems = 5;
  activityfilterString: string;
  smsText: string;
  projectLists: any;
  smsList: any;
  currentLeadIdCRM: any;
  callStatusRecruiterCRM: any;
  comments: any;
  currentCallSid: any;
  @ViewChild("closeButton2") closeButton2;
  search: any;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  cardleadType: string;
  updateRecruiter: FormGroup;
  recruiterCategory: any;
  public categories = ['Organization', 'Freelancer'];
  @ViewChild("closeButton3", { static: true }) closeButton3;
  public statusValue = "pending";
  docUrl: any;

  constructor(
    private SharedService: SharedService,
    public RecruiterService: RecruiterService,
    public countupTimerService: CountupTimerService,
    private momentService: MomentFormatService,
    private uhleadsservice: UhLeadsService,
    private uhubleadsservice: UhubDashboardService,
    private readonly modalService: NgbModal,
    public layoutService: LayoutService,
    public marketingService: MarketingService,
    public companyService: RoleService,
    private handleError: ErrorHandlerService,
    private agentService: CreateAgent,
    private router: Router,

    @Inject(DOCUMENT) private domService: HTMLDocument
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.SharedService.pageEvent.emit({
      pageName: "Recruiter",
    });
    this.getRecruiterDetails();
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    this.getLeadsByCards(this.leadType);
    this.onClick(1);
    this.RecruiterService.getCallSid.subscribe((data: any) => {
      this.currentCallSid = data;
    });
    this.RecruiterService.callStatus.subscribe((status) => {
      this.callStatus = status;
    });

    // server-side search
    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.page = 1;
          if (this.search.length > 0) {
            this.searchLeads();
          } else {
            this.getRecruiterDetails();
          }
        })
      )
      .subscribe();

    this.initForm();
  }
  getRecruiterDetails() {
    this.recruiterList = [];
    let payload = {
      page: this.page,
      cardType: this.cardType,
    };
    this.leadsLoading = true;
    this.RecruiterService.getRecruiterDetails(payload).subscribe(
      async (Data: any) => {
        if (Data?.count > 0) {
          if (Data && Data.rows.length > 0) {
            this.recruiterList = await Data.rows;
            this.recruiterList.forEach(element => {
              this.replaceNull(element)
            });
            this.totalRecords = await Data.count;
            this.leadsLoading = false;
            this.noLeadsFound = false;
          } else {
            this.leadsLoading = false;
            this.noLeadsFound = true;
          }
        } else {
          this.leadsLoading = false;
          this.noLeadsFound = true;
        }
      }
    ),
      (err) => {
        this.leadsLoading = false;
        this.noLeadsFound = true;
      };
    this.RecruiterService.startTimer.subscribe((res) => {

      this.callStatus = "inProgress";
      this.startTimer();

    });
  }
  getStudentList(Data: any) {
    this.studentList = Data;
  }
  onPageChange(event: any) {
    this.recruiterList = [];
    this.page = event;
    if (this.cardleadType !== 'filter') {
      this.getRecruiterDetails();
    } else {
      this.searchLeads();
    }
  }
  onClick(check: any) {
    this.uhleadsservice.pageNumber.next(1);
    if (check) {
      this.tab = "tab" + check;
    } else {
      this.tab = "";
    }
  }
  sideNavData(data) {
    this.sideViewerData = [];
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.sideViewerData = data;
    this.replaceNull(this.sideViewerData);
    this.currentLeadIdCRM = data?.salesForceDataCRM?.id;
    this.RecruiterService.getRecruiterSidenav({
      id: this.currentLeadIdCRM,
    }).subscribe((resp: any) => {
      this.sideViewerData.CallDetails = resp.CallDetails;
      this.sideViewerData.Activities = resp.Activities;
    });
    this.RecruiterService.getSms(this.currentLeadIdCRM).subscribe(
      (smsList: any) => {
        this.smsList = smsList.SMSDetails;
      }
    );
    // this.currentLeadId = this.sideViewerData.id;
  }
  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
      a[key] === "" ? (a[key] = "-") : a[key];
    });
  }

  getsignedDocument(id?: any) {
    // this.RecruiterService.getSignedDocument({id:id}).subscribe((Data:any)=>{
    //   let
    // })
  }
  getLeadsByCards(cardValue) {
    this.RecruiterService.getRecruiterLeadCount(cardValue).subscribe(
      (Data: any) => {
        if (Data) {
          this.topBarCount = Data;
        }
      }
    );
  }
  leadBycards(cardValue) {
    this.page = 1;
    this.cardType = cardValue;
    this.getRecruiterDetails();
  }
  startCallListener() {
    this.RecruiterService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
    });
  }
  startCallListener2(data, index) {
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);

    this.newStatus = false;
    this.callStatusRecruiterCRM = data?.salesForceDataCRM?.CallStatus;
    this.callData[0] = data;
    this.selectedLead = data;

    this.marketingId = {
      id: data?.salesForceDataCRM?.id,
      status: data?.salesForceDataCRM?.CallStatus,
      name: data?.firstName,
      email: data?.emailId,
      phoneNumber: data?.phone,
      module: data?.Module,
      city: data?.city,
      nextCaller: this.recruiterList.length > 1 ? 1 : 0,
      lead: 0,
    };
    if (this.recruiterList.length > 1) {
      var nextFirstname = this.recruiterList[index + 1]?.firstName
        ? this.recruiterList[index + 1]?.firstName
        : "";
      var nextLastname = this.recruiterList[index + 1]?.lastName
        ? this.recruiterList[index + 1]?.lastName
        : "";
      var nextCallerCity = this.recruiterList[index + 1]?.city
        ? this.recruiterList[index + 1]?.city
        : "";
      var nextCallerCountryCode = this.recruiterList[index + 1]?.phone
        ? this.recruiterList[index + 1]?.phone.replace("+", "")
        : "";

      this.nextCallerName = nextFirstname;
      this.nextCallerCity = nextCallerCity;
      this.nextCallerNumber = nextCallerCountryCode
        ? "+" + nextCallerCountryCode
        : "";
      this.startIndex = +1;
    }
    this.RecruiterService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
    });
    this.startCall = true;
  }

  stopCallListener() {

    this.RecruiterService.startCall.next(null);
    this.RecruiterService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.stopCall = true;
    this.startCall = false;
    // if (this.statusValue !== "pending") {
    //   this.startIndex += 1;
    // }
    this.startIndex += 1;
    clearTimeout(this.clearTime);
  }

  stopCurrentCall() {

    this.RecruiterService.startCall.next(null);
    this.RecruiterService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = "Call Ended";
  }
  onInputActivity(event: any) {
    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }

  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }
  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }
  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let fromNumber = "";
    this.companyService
      .projectAdmin(this.userData["companyId"])
      .subscribe((res: any) => {
        this.projectLists = res;
        this.projectId = res.filter((x) => x.Name == "Recruiter")[0].id;
        for (let i = 0; i < res.length; i++) {
          if (res[i].Name == "Recruiter") {
            fromNumber = res[i].TwilioNumber;

          }
        }
        let payload = {
          text: this.smsText,
          from: fromNumber,
          to: item.phone.includes("+") ? item.phone : "+" + item.phone,
          AgentMail: this.userData.mail,
        };

        this.uhubleadsservice
          .sendSms(payload, this.projectId)
          .subscribe((res: any) => {
            this.uhleadsservice.showInfo("Message successfully sent.", "Sent");
            this.RecruiterService.getSms(this.currentLeadIdCRM).subscribe(
              (smsList: any) => {
                this.smsList = smsList.SMSDetails;
                (<HTMLInputElement>document.getElementById(HTMLid)).value = "";
              }
            );
          });
      });
  }
  onStatusChange(value, id) {
    this.callStatusRecruiterCRM = value;
  }
  updateCallStatus(id) {
    let payload = {
      id: id,
      comments: this.comments,
      callStatus: this.callStatusRecruiterCRM,
      agentMail: this.userData.mail,
    };
    this.RecruiterService.updateCallComment(payload).subscribe(
      (data: any) => {
        this.updateCallCommentsNew();
      }
    );
  }
  updateCallCommentsNew() {
    let payload = {
      sid: this.currentCallSid,
      comments: this.comments,
      callstatus: this.callStatusRecruiterCRM
    }
    this.RecruiterService.updateCallCommentNew(payload).subscribe((data: any) => {
      this.closeButton2.nativeElement.click();
      this.recruiterList = [];
    this.leadsLoading = true;
    this.noLeadsFound = false;
    this.getRecruiterDetails();
    })
  }
  getStudents(type) {
    this.studentList = [];
    switch (type) {
      case 'enrolled':
        let enpayload = {
          type: 'all',
          recruiterId: 0
        }
        this.RecruiterService.getstudentsEnrolled(enpayload).subscribe((data: any) => {
          this.studentList = data;
        })
        break;
      case 'registered':
        let regpayload = {
          type: 'all',
          recruiterId: 0
        }
        this.RecruiterService.getstudentsRegistered(regpayload).subscribe((data: any) => {
          this.studentList = data;
        })
        break;
    }
  }
  conferenceCall(value: any) {
    this.RecruiterService.conferenceCall.next({ value });
  }
  callNext() { }

  searchLeads() {
    this.recruiterList = [];
    this.leadsLoading = true;
    this.noLeadsFound = false;
    this.cardleadType = 'filter';
    let payload = {
      search: this.search,
      page: this.page
    }
    this.RecruiterService.searchRecruiter(payload).subscribe(async (Data: any) => {
      if (Data?.count > 0) {
        if (Data && Data.rows.length > 0) {
          this.recruiterList = await Data.rows;
          this.totalRecords = await Data.count;
          this.leadsLoading = false;
          this.noLeadsFound = false;
        } else {
          this.leadsLoading = false;
          this.noLeadsFound = true;
        }
      } else {
        this.leadsLoading = false;
        this.noLeadsFound = true;
      }
    })
  }
  getStatus(status) {
    if (status === 'COMPLETION') {
      return `COMPLETED`
    } else if (status === 'counsellingcompleted') {
      return `COUNSELLING COMPLETED`
    } else if (status === 'enrolled') {
      return `ENROLLED`
    } else if (status === 'applicationsubmitted') {
      return `APPLICATION SUBMITTED`
    } else {
      return status;
    }
  }
  changeActive(id, value) {
    if (value.checked === true) {
      this.sideViewerData.accountActive = 1;
    } else {
      this.sideViewerData.accountActive = 0;
    }
    this.RecruiterService.updateActive({
      id: id,
      active: this.sideViewerData.accountActive
    }).subscribe((data) => {
    })
  }
  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }
  openEditLead(content, lead) {
    if (lead?.category == "FREELANCER") {
      this.recruiterCategory = 'Freelancer';
      this.updateRecruiter = new FormGroup({
        firstName: new FormControl(null, [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
        middleName: new FormControl(null, [Validators.pattern(REGEX.CHARACTER)]),
        lastName: new FormControl(null, [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
        emailId: new FormControl(null, [Validators.required, Validators.pattern(REGEX.EMAIL)]),
        phone: new FormControl(null, [Validators.required, Validators.pattern(REGEX.SPECIAL_PHONE)]),
        whatsappId: new FormControl(null),
        website: new FormControl(null, [Validators.pattern(REGEX.URL)]),
        street: new FormControl(null, Validators.required),
        country: new FormControl(null, Validators.required),
        state: new FormControl(null, Validators.required),
        territory: new FormControl(null, Validators.required),
        city: new FormControl(null, [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
        zip: new FormControl(null, [Validators.required]),
        experienceOverseasEducation: new FormControl(null, Validators.required),
        fbPageName: new FormControl(null),
        instagramHandle: new FormControl(null),
        linkedinURL: new FormControl(null),
        aboutUniversity: new FormControl(null, Validators.required),
        studentSendEveryYearNo: new FormControl(null, Validators.required),
        studentCountSendToHub: new FormControl(null, Validators.required),
        id: new FormControl(null)
      });
      this.updateRecruiter.patchValue({
        id: lead?.id,
        street: lead?.street,
        city: lead?.city,
        state: lead?.state,
        territory: lead?.territory,
        country: lead?.country,
        zip: lead?.zip,
        firstName: lead?.firstName,
        middleName: lead?.middleName,
        lastName: lead?.lastName,
        emailId: lead?.emailId,
        confirmEmailId: lead?.emailId,
        phone: lead?.phone,
        whatsappId: lead?.whatsappId,
        website: lead?.website,
        experienceOverseasEducation: lead?.experienceOverseasEducation,
        fbPageName: lead?.fbPageName,
        instagramHandle: lead?.instagramHandle,
        linkedinURL: lead?.linkedinURL,
        aboutUniversity: lead?.aboutUniversity,
        studentSendEveryYearNo: lead?.studentSendEveryYearNo,
        studentCountSendToHub: lead?.studentCountSendToHub
      });

    } else if (lead?.category == "ORGANIZATION") {
      this.recruiterCategory = 'Organization';
      this.updateRecruiter = new FormGroup({
        companyName: new FormControl(null, [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
        firstName: new FormControl(null, [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
        middleName: new FormControl(null, [Validators.pattern(REGEX.CHARACTER)]),
        lastName: new FormControl(null, [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
        emailId: new FormControl(null, [Validators.required, Validators.pattern(REGEX.EMAIL)]),
        phone: new FormControl(null, [Validators.required, Validators.pattern(REGEX.SPECIAL_PHONE)]),
        whatsappId: new FormControl(null),
        website: new FormControl(null, [Validators.pattern(REGEX.URL)]),
        street: new FormControl(null, Validators.required),
        country: new FormControl(null, Validators.required),
        state: new FormControl(null, Validators.required),
        territory: new FormControl(null, Validators.required),
        city: new FormControl(null, [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
        zip: new FormControl(null, [Validators.required]),
        experienceOverseasEducation: new FormControl(null, Validators.required),
        fbPageName: new FormControl(null),
        instagramHandle: new FormControl(null),
        linkedinURL: new FormControl(null),
        aboutUniversity: new FormControl(null, Validators.required),
        studentSendEveryYearNo: new FormControl(null, Validators.required),
        studentCountSendToHub: new FormControl(null, Validators.required),
        businessCertificate: new FormControl(null, Validators.required),
        businessCertificateType: new FormControl(null),
        businessCertificateFilename: new FormControl(null, Validators.required),
        isAcknowledged: new FormControl(null, Validators.required),
        id: new FormControl(null)
      });
      this.updateRecruiter.patchValue({
        id: lead?.id,
        street: lead?.street,
        city: lead?.city,
        state: lead?.state,
        territory: lead?.territory,
        country: lead?.country,
        zip: lead?.zip,
        companyName: lead?.companyName,
        firstName: lead?.firstName,
        middleName: lead?.middleName,
        lastName: lead?.lastName,
        emailId: lead?.emailId,
        confirmEmailId: lead?.emailId,
        phone: lead?.phone,
        whatsappId: lead?.whatsappId,
        website: lead?.website,
        experienceOverseasEducation: lead?.experienceOverseasEducation,
        fbPageName: lead?.fbPageName,
        instagramHandle: lead?.instagramHandle,
        linkedinURL: lead?.linkedinURL,
        aboutUniversity: lead?.aboutUniversity,
        studentSendEveryYearNo: lead?.studentSendEveryYearNo,
        studentCountSendToHub: lead?.studentCountSendToHub,
        isAcknowledged: lead?.isAcknowledged,
        businessCertificate: lead?.businessCertificate,
        businessCertificateType: lead?.businessCertificateType?.split('.').pop(),
        businessCertificateFilename: lead?.businessCertificateFilename
      });
    }
    this.modalService.open(content, { size: 'lg' });
  }

  initForm() {

  }
  updateRecruiterForm() {
    const requestBody: any = {
      category: this.recruiterCategory.toUpperCase(),
      roleId: this.roleId,
      recruiterCategory: this.recruiterCategory,
      ...this.updateRecruiter.getRawValue()
    };
    if (this.recruiterCategory == 'Organization') {
      this.RecruiterService.updateRecruiterOrganisation(requestBody).subscribe(() => {
        document.getElementById("closeButton3").click();
        this.getRecruiterDetails();
      });
    } else if (this.recruiterCategory == 'Freelancer') {
      this.RecruiterService.updateRecruiterFreelancer(requestBody).subscribe(() => {
        document.getElementById("closeButton3").click();
        this.getRecruiterDetails();
      });
    }
  }
  get roleId(): number {
    let id: number;
    if (this.recruiterCategory === this.categories[0]) {
      id = 5;
    }
    if (this.recruiterCategory === this.categories[1]) {
      id = 4;
    }
    return id;
  }
  dropdownSelect(event){
    this.cardType = this.statusValue;
    this.page = 1;
    this.getRecruiterDetails();
  }
  openPreview(content, url) {
    // this.docUrl = '';
    this.docUrl = url;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }
}
