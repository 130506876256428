import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Form, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-created-at',
  templateUrl: './created-at.component.html',
  styleUrls: ['./created-at.component.css']
})
export class CreatedAtComponent implements OnInit {
  showDropDown: boolean = true;
  tab: string = '';
  type: string;
  withForm: FormGroup;
  morethanForm: FormGroup;
  betweenFrom: FormGroup;
  @Output() shareCheckedListCreated = new EventEmitter();
  constructor(private fb:FormBuilder) { }

  ngOnInit() {
    this.type='';
    this.initwith();
    this.initmore();
    this.initbetween();
  }
  onClick(tab,type){
    this.type = type
    
    
    if(tab){
      this.tab = 'tab'+tab;
      
      
    } else {
      this.tab = ''
    }
  }
  save(){
    this.showDropDown = false;  
    if(this.type === 'within_the_last'){
      
      this.shareCheckedListCreated.emit(this.withForm.value);
    } else if(this.type === 'more_than'){
      
      this.shareCheckedListCreated.emit(this.morethanForm.value);
    } else if(this.type === 'in_between'){
      
      this.shareCheckedListCreated.emit(this.betweenFrom.value);
    }
  }
  cancel(){
    this.showDropDown = false; 
  }
  initwith(){
    this.withForm = this.fb.group({
      inputVal: [''],
      selectVal: ['minutes']
    })
  }
  initmore(){
    this.morethanForm = this.fb.group({
      inputVal: [''],
      selectVal: ['minutes']
    })
  }
  initbetween(){
    this.betweenFrom = this.fb.group({
      inputVal1: [''],
      inputVal2: ['']
    })
  }
}
