import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingpageComponent } from "./landingpage/landingpage.component";
import { LayoutComponent } from "./layout/layout.component";
import { MailResponseComponent } from "./mail-response/mail-response.component";
import { UhleadsComponent } from "./pages/uhleads/uhleads.component";
import { LoginComponent } from "./account/auth/login/login.component";
import { EventScheduleComponent } from "./pages/event-schedule/event-schedule.component";

const routes: Routes = [
  {
    path: "videocalling",
    loadChildren: () =>
      import("./videocalling/videocalling.module").then(
        (m) => m.VideocallingModule
      ),
  },
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  { path: "home", redirectTo: "/login", pathMatch: "full" },
  { path: "privacy", component: PrivacypolicyComponent },
  { path: "feedbackMail/:refid/:response", component: MailResponseComponent },
  { path: "login", component: LoginComponent },
  { path: "calendar/:agentName", component: EventScheduleComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
