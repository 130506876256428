// import { groups } from './data';
import { Observable } from "rxjs";
// import { HttpClientService } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Role } from "./models/role.model";
import { Group } from "./models/group.model";
import { Agent } from "./models/agent.model";
import { HttpClientService } from "src/app/httpclient.service";

@Injectable({
  providedIn: "root",
})
export class CreateAgent {
  baseApiUrl2: any = environment.baseApiUrl2;

  constructor(private http: HttpClientService) {}

  createAgent<Observable>(body: Object) {
    
    return this.http.post(`${this.baseApiUrl2}agentscreate`, body);
  }
  GetAllRoles<Observable>(id) {
    return this.http.get<Role[]>(`${this.baseApiUrl2}Role/company/${id}`);
  }
  GetAllGroups<Observable>(id) {
    return this.http.get<Group[]>(`${this.baseApiUrl2}getCompanyGroups/${id}`);
  }
  GetAllAgents(companyId) {
    return this.http.get<Agent[]>(
      `${this.baseApiUrl2}getActiveAgents/${companyId}`
    );
  }
  GetAgentbyid(id) {
    return this.http.get(`${this.baseApiUrl2}agentbyid/${id}`);
  }
  AgentUpdate(body: object) {
    return this.http.put(`${this.baseApiUrl2}updateagent`, body);
  }

  getContacts(companyId, userId) {
    return this.http.get<any[]>(
      `${this.baseApiUrl2}contacts/${companyId}/${userId}/agent`
    );
  }

  getAgentNames() {
    return this.http.get<any[]>(`${this.baseApiUrl2}getAgentNames`);
  }
  getSignedUrl(body: { url: string }) {
    return this.http.post(`${this.baseApiUrl2}getAwsSignedUrl`, body);
  }
  getMailGroups(companyId) {
    return this.http.get<any[]>(`${this.baseApiUrl2}mailGroups/${companyId}`);
  }
  updateStatus(body: { id: number; status: string, fcmtoken: string, token:string }) {
    return this.http.post(`${this.baseApiUrl2}updateStatus`, body);
  }
  getAgentManGroups(body: { roles: Role[] }) {
    return this.http.post<Group[]>(
      `${this.baseApiUrl2}getAgentManGroups`,
      body
    );
  }

  updateAgentStatus(body: { id: any }) {
    return this.http.post(`${this.baseApiUrl2}updateAgentStatus`, body);
  }

  getAgentRole(body: { projects: any[] }) {
    return this.http.post<any[]>(`${this.baseApiUrl2}getAgentRoles`, body);
  }
}
