<div *ngFor="let contact of contacts" class="d-flex" style="margin: 20px 8px">
  <div class="profile" *ngIf="!contact['ProfilePhoto']">
    {{ contact["FirstName"].slice(0, 1) }}
    <span
      class="{{ contact['Status'] !== null ? contact['Status'] : 'Offline' }}"
    ></span>
  </div>
  <span *ngIf="contact['ProfilePhoto']" class="profile">
    <img
      src="{{ contact['ProfilePhoto'] }}"
      alt="{{ contact['FirstName'] }}"
      style="
        display: inline-block;
        overflow: hidden;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
      "
    />
    <span
      class="{{ contact['Status'] !== null ? contact['Status'] : 'Offline' }}"
    ></span>
  </span>
  <div class="contact" style="flex: 1">
    <div class="d-flex justify-content-spacebetween">
      <div style="flex: 1; font-size: 15px">
        <span>{{
          contact["FirstName"] + " " + contact["LastName"] | titlecase
        }}</span>
        <span style="font-size: 14px; display: block; padding-top: 10px"
          >+{{ contact["PhoneNumber"] }}</span
        >
      </div>
      <div (click)="callAgent(contact['workerFriendlyName'])">
        <mat-icon>call</mat-icon>
      </div>
    </div>
    <div></div>
  </div>
</div>
