import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SocialmedialeadsService {
  
  baseApiUrl = environment.SMSapiurl;
  baseApiUrl2 = environment.baseApiUrl2;
  baseApiUrl3 = environment.baseApiUrl;
  webAppUrl = environment.webAppUrl;

  callStatusSocial = new BehaviorSubject<any>(null);
  startCallSocial = new BehaviorSubject<any>(null);
  stopCallSocial = new BehaviorSubject<any>(null);
  startTimerSocial = new BehaviorSubject<any>(null);
  getCallSidSocial = new BehaviorSubject<any>(null);
  conferenceCallSocial = new BehaviorSubject<any>(null);

  // FreJun
  callStatusFJSocial = new BehaviorSubject<any>(null);
  startCallFJSocial = new BehaviorSubject<any>(null);
  stopCallFJSocial = new BehaviorSubject<any>(null);
  startTimerFJSocial = new BehaviorSubject<any>(null);
  getCallSidFJSocial = new BehaviorSubject<any>(null);
  conferenceCallFJSocial = new BehaviorSubject<any>(null);

  public unreadCountRecBehaviorSubject = new BehaviorSubject<any>(null);
  public unreadCountRecObservable = this.unreadCountRecBehaviorSubject.asObservable();
  public viewReceivedLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public viewReceivedLeadObservable = this.viewReceivedLeadBehaviorSubject.asObservable();
  public addLeadBehaviorSubject = new BehaviorSubject<any>(null);
  public addLeadObservable = this.addLeadBehaviorSubject.asObservable();

  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
    CallStatus: [""],
  });

  addLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
  });

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) { }

  getLeadsSocial(data) {
    return this.http.post(`${this.baseApiUrl3}getUniversityDashboardLeadsSocial`, data);
  }

  getTopBarCountSocial(obj) {
    return this.http.post(`${this.baseApiUrl3}getUniversityTopBarCountsSocial`, obj);
  }

  getLeadSocial(id) {
    return this.http.post(`${this.baseApiUrl3}getUhubDashboardLead`, { id: id });
  }

  assignLeadsSocial(data) {
    return this.http.post(`${this.baseApiUrl3}assignUhubLead`, data);
  }

  getCountByCallStatusSocial(data) {
    return this.http.post(`${this.baseApiUrl3}getCountByCallStatus`, data);
  }

  getUhubSearchDashboardLeadsSocial(data) {
    return this.http.post(`${this.baseApiUrl3}getUhubSearchDashboardLeadsSocial`, data);
  }

  getSmsSocial(id) {
    return this.http.get(`${this.baseApiUrl2}communication/uHubSMS/` + id);
  }


  sendSmsSocial(data, projectId) {
    return this.http.post(`${this.baseApiUrl}` + projectId + `/messaging-adapter/sendSMS`, data);
  }

  uploadDocumentSocial(data) {
    return this.http.post(`${this.baseApiUrl2}addCustomDocUploadLeads`, data);
  }

  uploadDocument1Social(data, docId) {
    return this.http.post(`${this.baseApiUrl2}UploadDocumentLeads`, data);
  }

  deleteCustomDocumentSocial(data) {
    return this.http.post(
      `${this.baseApiUrl2}deleteCustomDocNameLeads`, data
    );
  }

  updateValidationStatusSocial(data) {
    return this.http.post(
      `${this.baseApiUrl2}DocumentValidation2Leads`,
      data
    );
  }

  getRecruiterDetailsSocial(data) {
    return this.http.post(
      `${this.webAppUrl}recruiter/getRecruityerDetailsCRM`, data
    );
  }

  getWebappStudentDetailsSocial(data) {
    const url: string = this.webAppUrl + 'student/getStudentLoginDetail?contactId='+data;
    return this.http.get(url);
  }

  counsellingCompletedSocial(data) {
    // return this.http.post(
    //   `${this.baseApiUrl2}wp/university/counsellingCompleted`,
    //   data
    // );

  }

  sendUniMailSocial(data) {
    return this.http.post(
      `${this.baseApiUrl3}leadUniversityAttachment`, data
    );
  }

  uploadFileAndGetLinkSocial(data){
    const url = `${this.baseApiUrl2}UploadDocumentV2`;
    return this.http.post(url,data)
  }

  getAllUniversitySocial() {
    const url = `${this.baseApiUrl2}/universitryhub/listOfUniversities`;
    return this.http.get(url);
  }

  sendNotificationSocial(data) {
    const url = `${this.baseApiUrl2}/wp/university/recruiter/sendNotification`;
    return this.http.post(url, data);
  }

  updateTransferCustomDocSocial(data) {
    const url = `${this.baseApiUrl2}/uhub/updateTransferCustomDoc`;
    return this.http.post(url, data);
  }
  getDocNameByDocId(data) {
    const url = `${this.baseApiUrl2}/getDocNameByDocId`;
    return this.http.post(url, data);
  }
}
