export class Exceldata {
  constructor(
    private firstName: string,
    private lastName: string,
    private countryCode: string,
    private phoneNumber: string,
    private email: string,
    private assignedTo?: string,
    private module?: string,
    private channel?: string,
    private Miscellaneous?: any,
    private CreatedDate?: any,
    private Comments?: any
  ) { }
}