import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {
  docUrl = '';
  documentsList = [
    {
      "name":"University Hub",
      "desc": "",
      "type": "doc",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/StudentDocs/1677239460277-UHUB-UniversityHubCRM-240223-0931.pdf"
  }, 
  {
      "name": "Recruiter",
      "desc": "",
      "type": "doc",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/files/1680251632530-uploads%5CUHUB-RECRUITERWEBAPP-310323-0721.pdf"
  },
  {
      "name": "Functional Overview of University Hub CRM",
      "desc": "",
      "type": "video",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/files/1680252963104-uploads%5CGMT20230301-083404_Recording_1920x1080+%281%29.mp4"
  },
  {
      "name": "Universities",
      "desc": "",
      "type": "video",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/files/1680253096791-uploads%5CGMT20230301-083404_Recording.cutfile.20230307111614675_1920x1080.mp4"
  },
  {
      "name": "Recruiters, Transfers & International Students",
      "desc": "",
      "type": "video",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/files/1680253228379-uploads%5CGMT20230303-083657_Recording_1920x1080.mp4"
  },
  {
      "name": "Referrals",
      "desc": "",
      "type": "video",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/files/1680253478494-uploads%5CGMT20230306-083436_Recording.cutfile.20230307112650979_2560x1440.mp4"
  },
  {
      "name": "Calendar",
      "desc": "",
      "type": "video",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/files/1680257457600-uploads%5CGMT20230313-083429_Recording.cutfile.20230331082731894_1920x1080.mp4"
  },
  {
      "name": "Marketing, Enquiries, My Team",
      "desc": "",
      "type": "video",
      "url": "https://crmprod-universityhub.s3.us-west-1.amazonaws.com/crmprod-universityhub/files/1680257566851-uploads%5CGMT20230310-083247_Recording_1920x1080+%281%29.mp4"
  },
];
  docLoaded = false;
  docType: any;
  constructor(
    private readonly modalService: NgbModal) { }

  ngOnInit(): void {
    this.openPreview(this.documentsList[0].url,"doc");
  }
  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }

  openPreview(url,type) {
    this.docLoaded = true;
    this.docType = type;
    this.docUrl = url;
  }
}
