import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/account/auth/auth.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { SharedService } from "src/app/shared/shared.service";
import * as xlsx from "xlsx";
import { ProjectService } from "../../project-management/project.service";
import { RoleService } from "../../role-management/role.service";
import { MarketingService } from "../marketing.service";
import { Exceldata } from "./models/ExecelData.model";

@Component({
  selector: "app-smsmarketing",
  templateUrl: "./smsmarketing.component.html",
  styleUrls: ["./smsmarketing.component.css"],
})
export class SmsmarketingComponent implements OnInit, OnDestroy {
  fileData: Exceldata[] = [];
  page: any = 1;
  pageSize: any = 8;
  smsData: any[] = [];
  search: any;
  phoneNumbers: any[] = [];
  @ViewChild("uploadFile") inputFile: ElementRef;
  userData: any;
  checked = false;
  closeResult: string;
  from: string = "+13342125481";
  sendingText: string;
  selectedItems: any[];
  comments: any;
  phoneNumber: any;
  id: any;
  statusValue = "pending";
  filePath: any = "";
  projectId: number;
  countries: any[];
  selectedCountryCode: any;
  public error: boolean = false;
  public errorMessage: string = "";
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  @ViewChild("uploadFile") uploadFile;
  public downloadLink: any;
  public admin: boolean = false;
  public isUploaded: boolean = false;
  public project: Subscription;

  constructor(
    private readonly sharedService: SharedService,
    private readonly marketingService: MarketingService,
    private readonly modalService: NgbModal,
    private readonly loginService: AuthService,
    private readonly projectService: ProjectService,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,

  ) { }

  ngOnDestroy(): void {
    if (this.project) {
      this.project.unsubscribe();
    }
  }

  ngOnInit() {
    this.sharedService.pageEvent.emit({ pageName: "SMS Marketing" });
    this.marketingService
      .getExcelLink("services/TemplateDocuments/accessque.xlsx")
      .subscribe((data) => {
        this.downloadLink = data["url"];
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.fetchCountries();
    this.userData = JSON.parse(localStorage.getItem("userData"));

    // this.project = this.sharedService.projectId.subscribe((data) => {
    //   if (data) {
    //     this.projectId = data;
    //     this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
    //       this.admin = data;
    //       this.getSmsData();
    //       this.getProject(this.projectId);
    //     });
    //   }
    // });
    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
        this.admin = data;
        this.getSmsData();
        this.getProject(this.projectId);
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, 500)
    // this.loginService.companyAdmin.subscribe((data) => {
    //   if (this.userData["companyId"] !== 0) {
    //     this.companyId = this.userData["companyId"];
    //     this.getCompany(this.companyId);
    //   }
    //   if (data) {
    //     this.admin = true;
    //   } else {
    //     this.admin = false;
    //   }
    //   this.getSmsData();
    // });
  }

  getProject(id) {
    this.projectService.getProject(id).subscribe((data) => {
      this.from = data["TwilioNumber"];
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getSmsData() {
    this.marketingService
      .getSmsData({
        SmsStatus: this.statusValue,
        admin: this.admin,
        mailId: this.userData["mail"],
        ProjectId: this.projectId,
      })
      .subscribe((data) => {
        for (let customer of data) {
          customer["checked"] = false;
        }
        this.smsData = data;
        
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  convertFile(e) {
    
    this.filePath = e.target.files[0]["name"].toString();
    
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      
      if (sheet_data[0][0] === "First Name" && sheet_data.length !== 0) {
        this.error = false;
        this.fileData =
          this.marketingService.convert2dArraytoObject(sheet_data);
        
        this.marketingService
          .postMarketingData({
            data: this.fileData,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
          })
          .subscribe((data) => {
            
            this.isUploaded = true;
            this.getSmsData();
            setTimeout(() => {
              this.isUploaded = false;
            }, 5000);
          }, (error) => {
            this.handleError.handleError(error)
          });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }

  openFileUpload() {
    this.inputFile.nativeElement.click();
  }

  singleChecked(id) {
    let datas = this.smsData.find((data) => data["id"] === id);
    datas["checked"] = true;
    
    // this.phoneNumbers.push(datas);
  }

  selectAll() {
    this.checked = !this.checked;
    this.smsData
      .slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
      .forEach((data) => (data["checked"] = this.checked));
    this.phoneNumbers = this.smsData.map((data) => {
      if (data["checked"]) {
        return data;
      }
    });
    
  }

  open(content) {
    this.modalService.open(content, { size: "md" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    this.smsData.forEach((item) => (item["checked"] = false));

    this.checked = false;
    this.phoneNumbers = [];
    this.phoneNumber = "";
    this.comments = "";
    this.id = "";
    
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  sendSms(content, id) {
    this.smsData.forEach((sms) => {
      if (sms["id"] === id) {
        sms["checked"] = true;
      }
    });
    this.phoneNumbers = this.smsData.filter((data) => data["checked"]);
    this.open(content);
  }

  sent() {
    this.phoneNumbers = this.smsData.filter((data) => data["checked"]);
    for (let number of this.phoneNumbers) {
      this.marketingService
        .sendSms(
          {
            from: this.from,
            to: "+" + number["CountryCode"] + number["PhoneNumber"],
            text: this.replaceString(
              number["FirstName"] + number["LastName"],
              this.sendingText
            ),
          },
          this.projectId
        )
        .subscribe((data) => {
          
          this.marketingService
            .updateSmsStatus({
              id: number["id"],
              messages: {
                body: this.replaceString(
                  number["FirstName"] + " " + number["LastName"],
                  this.sendingText
                ),
                sendAt: Date.now(),
                sender:
                  this.userData["firstName"] + " " + this.userData["lastName"],
                receiver: "+" + number["CountryCode"] + number["PhoneNumber"],
              },
              assignedTo: number["AssignedTo"] || this.userData["mail"],
            })
            .subscribe((data) => {
              
              this.getSmsData();
            }, (error) => {
              this.handleError.handleError(error)
            });
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
    this.modalService.dismissAll();
  }

  openUpdateSms(content, id) {
    let data = this.smsData.find((data) => data["id"] === id);
    this.phoneNumber = data["PhoneNumber"];
    this.comments = data["Comments"];
    this.id = id;
    this.open(content);
  }

  updateSms() {
    this.marketingService
      .updateSmsData({
        id: this.id,
        phoneNumber: this.phoneNumber,
        comments: this.comments,
      })
      .subscribe((data) => {
        this.modalService.dismissAll();
        this.getSmsData();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  replaceString(fullname, content): string {
    let replacements = {
      name: fullname,
    };

    const result = content.replace(/{{([^{{]+)}}/g, (match, key) => {
      // If there's a replacement for the key, return that replacement with a `<br />`. Otherwise, return a empty string.
      return replacements[key] !== undefined ? replacements[key] : "";
    });
    return result;
  }

  dropdownSelect() {
    this.getSmsData();
  }

  pageChange() {
    this.smsData.forEach((item) => (item["checked"] = false));

    this.checked = false;
    this.phoneNumbers = [];
  }

  selectCountry(country) {
    this.selectedCountryCode = country["code"];
  }

  fetchCountries() {
    this.sharedService.getCountries().subscribe((data) => {
      this.countries = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  createCustomer(value: Exceldata) {
    value["countryCode"] = this.selectedCountryCode;
    this.marketingService
      .postMarketingData({
        data: [value],
        createdBy: this.userData["mail"],
        ProjectId: this.projectId,
      })
      .subscribe((data) => {
        
        this.getSmsData();
        this.modalService.dismissAll();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }
  projectChange(data) {
    this.projectId = data;
    this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
      this.admin = data;
      this.getSmsData();
      this.getProject(this.projectId);
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
}
