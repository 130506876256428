import { MarketingModule } from "./marketing/marketing.module";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PagesRoutingModule } from "./pages-routing.module";
import { SharedModule } from "../shared/shared.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { TicketsModule } from "./tickets/tickets.module";
import { ChatModule } from "./chat/chat.module";
import { CreateAgentComponent } from "./create-agent/create-agent.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { RoleManagementComponent } from "./role-management/role-management.component";
// import { FilterPipe } from './filter.pipe';
import { GroupManagementComponent } from "./group-management/group-management.component";
import { EmailsettingsComponent } from "./emailsettings/emailsettings.component";
import { EmailEditorModule } from "angular-email-editor";
import { TemplatesComponent } from "./templates/templates.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDividerModule } from "@angular/material/divider";
import { ProfileComponent } from "./profile/profile.component";
// import {
//   MatCardModule,
//   MatCheckboxModule,
//   MatExpansionModule,
//   MatIconModule,
//   MatInputModule,
//   MatListModule,
//   MatSelectModule,
//   MatTabsModule,
// } from "@angular/material";
import { CustomTableComponent } from "./custom-table/custom-table.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ContactsComponent } from "./contacts/contacts.component";
import { CompanyManagementComponent } from "./company-management/company-management.component";
import { CalllogsComponent } from "./calllogs/calllogs.component";
import { MarketingRoutingModule } from "./marketing/marketing-routing.module";
import { CompanymodulesComponent } from "./companymodules/companymodules.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { SmsmodulesComponent } from "./smsmodules/smsmodules.component";
import { PageheadersPipe } from "./custom-table/pageheaders.pipe";
import { ProjectManagementComponent } from "./project-management/project-management.component";
import { RoleNamePipe } from "./create-agent/role-name.pipe";
import { HellosignComponent } from "./hellosign/hellosign.component";
import { ReferralComponent } from "./referral/referral.component";
import { ReferreeComponent } from "./referree/referree.component";
import { NgxTimerModule } from "ngx-timer";
import { DemoMaterialModule } from "./uhleads/material.module";
import { ActivityFilterPipe2 } from "../shared/activity2.pipe";
import { ReportsComponent } from "./reports/reports.component";
import { NgxEchartsModule } from 'ngx-echarts';
import { MultiSelectDropdownComponent } from "./reports/report-filter/multi-select-dropdown/multi-select-dropdown.component";
import { UpdatedAtComponent } from "./reports/report-filter/updated-at/updated-at.component";
import { CreatedAtComponent } from "./reports/report-filter/created-at/created-at.component";
import { IvyCarouselModule } from "angular-responsive-carousel";
import { ReportFilterComponent } from './reports/report-filter/report-filter.component';
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { DashboardReportsComponent } from './dashboard-reports/dashboard-reports.component';
import { SparklineComponent } from "../custom-charts/sparkline/sparkline.component";
import { TimelineFilterBarChartComponent } from "../custom-charts/timeline-filter-bar-chart/timeline-filter-bar-chart.component";
import { ComboChartComponent, ComboSeriesVerticalComponent } from "../custom-charts/combo-chart";
import { BubbleChartInteractiveModule } from "../custom-charts/bubble-chart-interactive";
import { NgxUIModule } from "@swimlane/ngx-ui";
import { NgxChartsModule } from "@swimlane/ngx-charts/ngx-charts.module";
import { UhleadsfilterComponent } from "./uhleads/uhleadsfilter/uhleadsfilter.component";
import { MonitoringComponent } from './monitoring/monitoring.component';
import { GoogleAnalyticsComponent } from "./google-analytics/google-analytics/google-analytics.component";
import { LayoutModule } from "../layout/layout.module";
import { AppAnalyticsComponent } from './dashboard-reports/app-analytics/app-analytics.component';
import { CallAnalyticsComponent } from './dashboard-reports/call-analytics/call-analytics.component';
import { ReportFilterComponent2 } from "./reports/report-filter2/report-filter.component";
import { TrasnfersModule } from "./trasnfers/trasnfers.module";
import { UploadComponent } from './upload/upload.component';
import { ProgressComponent } from "src/components/progress/progress.component";
import { CalendarComponent } from './calendar/calendar.component';
import { EventScheduleComponent } from './event-schedule/event-schedule.component';
import { AppointmentsComponent } from './calendar/appointment/appointment.component';
import { AutoCallSMSComponent } from './calendar/auto-call-sms/auto-call-sms.component';
import { RecruiterComponent } from './recruiter/recruiter.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { NgxDocViewerModule } from "ngx-doc-viewer";
export function chartModule(): any {
  return import('echarts');
}

@NgModule({
    declarations: [
        CreateAgentComponent,
        RoleManagementComponent,
        GroupManagementComponent,
        EmailsettingsComponent,
        TemplatesComponent,
        ProfileComponent,
        CustomTableComponent,
        ContactsComponent,
        ReferralComponent,
        ReferreeComponent,
        CompanyManagementComponent,
        CalllogsComponent,
        CompanymodulesComponent,
        SmsmodulesComponent,
        PageheadersPipe,
        ProjectManagementComponent,
        RoleNamePipe,
        HellosignComponent,
        ActivityFilterPipe2,
        ReportsComponent,
        MultiSelectDropdownComponent,
        UpdatedAtComponent,
        CreatedAtComponent,
        ReportFilterComponent,
        ReportFilterComponent2,
        DashboardReportsComponent,
        SparklineComponent,
        TimelineFilterBarChartComponent,
        ComboChartComponent,
        ComboSeriesVerticalComponent,
        UhleadsfilterComponent,
        MonitoringComponent,
        GoogleAnalyticsComponent,
        AppAnalyticsComponent,
        CallAnalyticsComponent,
        UploadComponent,
        ProgressComponent,
        CalendarComponent,
        EventScheduleComponent,
        AppointmentsComponent,
        AutoCallSMSComponent,
        RecruiterComponent,
        DocumentationComponent,
    ],
    imports: [
        CommonModule,
        PagesRoutingModule,
        DashboardModule,
        TicketsModule,
        ChatModule,
        SharedModule,
        NgbModule,
        FormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        EmailEditorModule,
        MatSnackBarModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        Ng2SearchPipeModule,
        MatTabsModule,
        DragDropModule,
        MatCheckboxModule,
        MatCardModule,
        MatListModule,
        MatButtonModule,
        MarketingRoutingModule,
        MarketingModule,
        TicketsModule,
        MatExpansionModule,
        ReactiveFormsModule,
        NgxTimerModule,
        MatSelectModule,
        DemoMaterialModule,
        IvyCarouselModule,
        BubbleChartInteractiveModule,
        NgxUIModule,
        NgxChartsModule,
        NgxEchartsModule.forRoot({
            echarts: chartModule
        }),
        LayoutModule,
        TrasnfersModule,
        NgxDocViewerModule
    ],
    exports: [
        Ng2SearchPipeModule,
        UhleadsfilterComponent,
        
        // FilterPipe,
    ]
})
export class PagesModule { }
