import { HttpClientService } from "src/app/httpclient.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClientModule } from "@angular/common/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class DashboardReportsService {
  baseApiUrl = environment.baseApiUrl2;
  userData = JSON.parse(`${localStorage.getItem("userData")}`);

  constructor(
    private http: HttpClientService,
    private http2: HttpClient,
    private toastr: ToastrService
  ) {}

  getStatusCounts(body) {
    return this.http.post(`${this.baseApiUrl}reports/statusCounts`, body);
  }

  getAssignedCounts(body) {
    return this.http.post(`${this.baseApiUrl}reports/assignedCounts`,body);
  }
  getAssignedCountsGBP(body) {
    return this.http.post(`${this.baseApiUrl}reports/assignedCountsGBP`,body);
  }
  
  getCourseCounts(body) {
    return this.http.post(`${this.baseApiUrl}reports/courseCounts`,body);
  }

  getIncomingCompleted(body) {
    return this.http.post(`${this.baseApiUrl}reports/incomingCompleted`,body);
  }

  getTopPerformers(body) {
    return this.http.post(`${this.baseApiUrl}reports/topPerformers`,body);
  }

  getPendingCounts(body){
    return this.http.post(`${this.baseApiUrl}reports/pendingCounts`,body);
  }

  getCallsPerDay(body){
    return this.http.post(`${this.baseApiUrl}reports/callsPerDay`,body);
  }

  getCallsInboundOutbound(body){
    return this.http.post(`${this.baseApiUrl}reports/callsInboundOutbound`,body);
  }

  getCallsInVsOut(body){
    return this.http.post(`${this.baseApiUrl}reports/callsInVsOut`,body);
  }
  getCallStatus(body){
    return this.http.post(`${this.baseApiUrl}reports/callStatus`,body);
  }





  getStatusCountsData(body) {
    return this.http.post(`${this.baseApiUrl}reports/statusCountsData`, body);
  }

  getIncomingCompletedData(body) {
    return this.http.post(`${this.baseApiUrl}reports/incomingCompletedData`,body);
  }
  courseData(body) {
    return this.http.post(`${this.baseApiUrl}reports/courseData`,body);
  }

  getCallsPerDayData(body){
    return this.http.post(`${this.baseApiUrl}reports/callsPerDayData`,body);
  }

  getProcessingtimeMax10(body){
    return this.http.post(`${this.baseApiUrl}reports/processingtimeMax10`,body);
  }

  getValidationtimeMax10(body){
    return this.http.post(`${this.baseApiUrl}reports/validationtimeMax10`,body);
  }

  getProcessingtimeMax10Data(body){
    return this.http.post(`${this.baseApiUrl}reports/processingtimeMax10Data`,body);
  }

  getValidationtimeMax10Data(body){
    return this.http.post(`${this.baseApiUrl}reports/validationtimeMax10Data`,body);
  }
  applicationsProcessedData(body){
    return this.http.post(`${this.baseApiUrl}reports/applicationsProcessedData`,body);
  }

}
