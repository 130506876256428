// import { map } from 'rxjs/operators';
// import { HttpClientService } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClientService } from "../httpclient.service";
import { CallLogs } from "../pages/calllogs/models/CallLogs.model";

interface CallLogInput {
  companyId: string | number;
  from: Date | string;
  to: Date | string;
  rowNumber: number;
  workerSid?: string;
}

interface CompanyIvr {
  projectName: string;
  twilioNumber: string;
  projectId: string;
}

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  baseApiUrl2: any = environment.baseApiUrl2;
  twilioApiUrl: any = environment.twilioApiUrl;

  constructor(private http: HttpClientService) {}

  getMenus() {
    return this.http.get<any[]>(`${this.baseApiUrl2}getAllMenus`);
  }

  getRoleMenus() {
    return this.http.get<any[]>(`${this.baseApiUrl2}getRoleMenus`);
  }

  updateMenu({ id, displayMenus }: { id: string; displayMenus: any[] }) {
    return this.http.post(`${this.baseApiUrl2}updateRoleMenu/${id}`, {
      displayMenus,
    });
  }

  getRoleMenuById(id) {
    return this.http.get<any[]>(`${this.baseApiUrl2}getRoleMenuById/${id}`);
  }

  getRoleByName(name: number) {
    return this.http.get<any[]>(`${this.baseApiUrl2}getRoleByName/${name}`);
  }

  createRoleMenu(body: { roleId: number; displayMenus: any[] }) {
    return this.http.post<any[]>(`${this.baseApiUrl2}createRoleMenus`, body);
  }

  getSideMenuRoles(body: { roles: any[]; superAdmin: boolean }) {
    return this.http.post<any[]>(`${this.baseApiUrl2}getSideMenuRoles`, body);
  }

  // getCallLogs(body: CallLogInput) {
  //   return this.http.post<CallLogs[]>(
  //     `${this.baseApiUrl2}getCallLogsByCompany`,
  //     body
  //   );
  // }
  getCallLogs(body: any) {
    return this.http.post<any[]>(
      `${this.baseApiUrl2}reports/calls`,
      body
    );
  }
  searchCallReports(body: any) {
    return this.http.post<any[]>(
      `${this.baseApiUrl2}reports/searchCallReports`,
      body
    );
  }
  getLiveCallLogs(id: any) {
    return this.http.get<any[]>(
      `${this.baseApiUrl2}reports/liveCalls/` + id,
    );
  }
  getRecentCallLogs(id: any) {
    return this.http.get<any[]>(
      `${this.baseApiUrl2}reports/recentCalls/` + id,
    );
  }
  callsCount(body: any) {
    return this.http.post<any[]>(
      `${this.baseApiUrl2}reports/callsCount`,
      body
    );
  }

  createIvr(body: CompanyIvr, projectId) {
    return this.http.post(`${this.twilioApiUrl}${projectId}/IVR/create`, body);
  }
}
