import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IncomingCallsService } from "./incoming-calls.service";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { UhLeadsService } from "src/app/pages/uhleads.service";

@Component({
  selector: "app-incoming-calls",
  templateUrl: "./incoming-calls.component.html",
  styleUrls: ["./incoming-calls.component.css"],
})
export class IncomingCallsComponent implements OnInit {
  userData: any;
  testConfig1: any;
  from: any;
  to: any;
  callSid: any;
  callStatus: any = 'Connected';
  city: string = '';
  newStatus: any;
  commentText: string ='';
  isNewEnquiry: boolean = true;

  leadInfo: any ={
    FirstName : '',
    LastName: '',
    Email: '',
    Address: '',
    City: '',
    State: '',
    Country: '',
    Zip: '',

  }

  constructor(
    private IncomingCallsService: IncomingCallsService,
    public dialogRef: MatDialogRef<IncomingCallsComponent>,
    public countupTimerService: CountupTimerService,
    private uhleadsservice: UhLeadsService,
    public incomingCallService: IncomingCallsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.testConfig1 = new countUpTimerConfigModel();
    this.testConfig1.timerClass = "test_Timer_class";
    this.testConfig1.timerTexts = new timerTexts();
    this.testConfig1.timerTexts.hourText = ":"; //default - hh
    this.testConfig1.timerTexts.minuteText = ":"; //default - mm
    this.testConfig1.timerTexts.secondsText = " ";
    //default - ss
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    this.startTimer();
    this.IncomingCallsService.callInfo.subscribe((data: any) => {
      if (data) {
        this.from = data.From;
        this.to = data.To;
        this.callSid = data.CallSid;
      }

      this.incomingCallService.checkenquiry(this.from,this.to).subscribe((resp:any)=>{

        if (resp.status == 201) {
          this.isNewEnquiry = true;
          this.leadInfo = {
            FirstName: "",
            LastName: "",
            Email: "",
            Address: "",
            City: "",
            State: "",
            Country: "",
            Zip: "",
          };
        } else if (resp.status == 200) {
          this.incomingCallService
            .getEnquiryById(resp.newInquiries[0].id, resp.ProjectId)
            .subscribe((res: any) => {

              this.isNewEnquiry = false;
              this.leadInfo = {
                id: res.id,
                FirstName: res.FirstName,
                LastName: res.LastName,
                Email: res.Email,
                Address: res.Address,
                City: res.City,
                State: res.State,
                Country: res.Country,
                Zip: res.Zip,
              };
            });
        }

      })
      
    });
    
    
  }

  endCall() {
    this.IncomingCallsService.endCall.next({ disconnect: true });
    this.countupTimerService.stopTimer();
    this.uhleadsservice.showInfo("", "Call has ended!")
    this.callStatus = 'Ended';
  }

  closeWindow() {
    this.dialogRef.close();
  }

  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.stopTimer();
    this.countupTimerService.startTimer(cdate);
  }
  onStatusChange(data) {
    this.newStatus = data;
  }
  resetCallingScreen() {
    this.commentText = "";
    this.newStatus = null;
  }

  updateComments() {

    if (!this.newStatus) {
    } else if(this.isNewEnquiry) {
      let postData = {
        To: this.to,
        From: this.from,
        FirstName: this.leadInfo.FirstName,
        LastName: this.leadInfo.LastName,
        Email: this.leadInfo.Email,
        Address: this.leadInfo.Address,
        City: this.leadInfo.City,
        Country: this.leadInfo.Country,
        State: this.leadInfo.State,
        Zip: this.leadInfo.Zip,
        CreatedBy: this.userData.mail,
        Comments: this.commentText,
        CallSid: this.callSid,
        CallStatus: this.newStatus
      }
      this.IncomingCallsService.createNewEnquiry(postData).subscribe((res:any)=>{
        this.uhleadsservice.showSuccess("New Enquiry created successfully.", "Created!");
        this.closeWindow();
      })
    }
    else{
      let postData = {
        id: this.leadInfo.id,
        comments: this.commentText,
        followupDate: null,
        callStatus: this.newStatus,
        CallSid: this.callSid,
        FirstName: this.leadInfo.FirstName,
        LastName: this.leadInfo.LastName,
        Email: this.leadInfo.Email,
        Address: this.leadInfo.Address,
        City: this.leadInfo.City,
        Country: this.leadInfo.Country,
        State: this.leadInfo.State,
        Zip: this.leadInfo.Zip
      }
      this.IncomingCallsService.updateCallComments(postData).subscribe((res:any)=>{
        this.uhleadsservice.showSuccess("Enquiry updated successfully.", "Updated!")
        this.closeWindow();
      })
    }
  }
}
