import {
  Component, ElementRef, OnInit, Output, EventEmitter,
  ViewChild
} from '@angular/core';
import { Client, Conversation } from "@twilio/conversations";
import { environment } from 'src/environments/environment';
declare const Twilio: any;
@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
  baseUrl = environment.socketUrl;
  currentIndex = 0;
  content = [
    { title: "Instant Calls", content: "Accessque Guide is a smart knowledge base that helps you harness the power of institutionalknowledge." },
    {
      title: "Live Chat", content: "Create a personal connection with customers looking for support.It\’ s fast and effective"
    },
    {
      title: "Social", content: "Be where they want to be — connect popular messaging apps to Accessque."
    },
    { title: "Mails", content: "Accessque Guide is a smart knowledge base that helps you harness the power of institutionalknowledge." },
    {
      title: "Integrated Voice", content: "Talk it out directly with customers and provide personalized solutions to complex issues."
    }
    , { title: "Tickets", content: "Accessque Guide is a smart knowledge base that helps you harness the power of institutionalknowledge." }

  ]
  client: any;
  channel: any;
  username: any = 'Test User 2';
  //  email:any = 'test@abc.com';
  //  description:any = 'Web chat request';
  //  phone:any = '9096652820';
  RolesContacted: any = 'Studentcalls';
  channelId: any;
  chatClient1: any;
  generalChannel: any;
  message: any = []
  name: any;
  email: any;
  description: any;
  phone: any;
  isIconShow: any
  showNote:Boolean=false
  @ViewChild("chatContainer") chatContainer!: ElementRef;
  // @Output() showchat: EventEmitter<Boolean> = new EventEmitter();
  is_showchat:Boolean = false
  scrollTop() {
    if (this.message && this.message.length > 0) {
      setTimeout(() => {
        this.chatContainer.nativeElement.scrollTop =
          this.chatContainer.nativeElement.scrollHeight;
      }, 20);
    }

  }
  typingNotification:any

  constructor() {

  }

  ngOnInit(): void {
  }
  async getToken() {

    let _data = {
      "identity": this.username
    }
    let url = `${environment.twilioApiUrl}${environment.companyId}/webChat/token`;
    let response = await fetch(url, { headers: { 'content-type': 'application/json' }, method: "POST", body: JSON.stringify(_data) });
    const data = await response.json();
    this.channelId = data.channelId;
    // Initialize the Chat client
    //Twilio.Chat.Client.create(data.token).then(client => {

    Twilio.Conversations.Client.create(data.token).then((client: any) => {
      this.chatClient1 = client;
      // chatClient.on('channelInvited', function(channel) {
      // 	// Join the channel that you were invited to
      // 	//channel.join();
      //   generalChannel = channel;
      // 	setupChannel();
      // });

      this.chatClient1.on("connectionStateChanged", (state: any) => {

        if (state == "connected") {
        }
        if (state == 'disconnecting') {
        }
        if (state == 'disconnected') {
        }
        if (state == 'denied') {
        }

      });

      // chatClient1.on("conversationJoined", (conversation) => {
      //   generalChannel = conversation;
      //   setupChannel();
      // });

      //chatClient.getSubscribedChannels().then(createOrJoinGeneralChannel);
      //let conversations = await chatClient1.getSubscribedConversations();
      this.createOrJoinGeneralChannel();
      this.refreshToken(this.username);

      // when the access token is about to expire, refresh it
      var that = this
      this.chatClient1.on('tokenAboutToExpire', function () {
        that.refreshToken(that.username);
      });

      // if the access token already expired, refresh it
      this.chatClient1.on('tokenExpired', function () {
        that.refreshToken(that.username);
      });

      // Alert the user they have been assigned a random username
      
      this.scrollTop()
    }).catch((error: any) => {
    });
  }



  async refreshToken(identity: any) {
    let _data = {
      "identity": identity
    }
    let url = `${environment.twilioApiUrl}${environment.companyId}/webChat/token`;
    let response = await fetch(url, { headers: { 'content-type': 'application/json' }, method: "POST", body: JSON.stringify(_data) });
    const data = await response.json();
    this.chatClient1.updateToken(data.token);

  }

  async createOrJoinGeneralChannel() {
    let that = this
    this.chatClient1.getConversationBySid(this.channelId)
      .then(function (channel: any) {
        that.generalChannel = channel;
        that.setupChannel();
      }).catch(function () {
        that.chatClient1.createConversation({
          //chatClient.createChannel({

          uniqueName: that.channelId,
          friendlyName: that.channelId,
          attributes: {
            UserName: that.username,
            EmailId: that.email,
            RequestDescription: that.description,
            Phone: that.phone,
            RolesContacted: that.RolesContacted,
            TwilioNumber: environment.TwilioNumber

          }

        }).then(function (channel: any) {
          that.generalChannel = channel;
          that.setupChannel();
        }).catch(function (channel: any) {
        });
      });

  }


  // Set up channel after it has been found
  setupChannel() {
    // Join the general channel
    let that = this
    this.generalChannel.join().then(async function (channel: any) {
    });

    this.generalChannel.on('messageAdded', function (message: any) {
      this.showNote=false
      that.message.push({
        body: message.body,
        author: message.author,
        timestamp: Date.now(),
        sid: message.sid,
      })
    });
    this.generalChannel.on("typingStarted", (member: any) => {
      this.typingNotification = member.identity + ' is typing ...';
    });

    this.generalChannel.on("typingEnded", (member:any) => {
      this.typingNotification = '';
    });
  }
  print(infoMessage: any, asHtml: any) {
  }

  submit() {
    this.showNote=true
    this.username = this.name
    this.getToken()

  }
  sendMessage() {
    if (this.chatMessage !== "") {
      this.generalChannel.sendMessage(this.chatMessage);
      this.chatMessage = "";
    }
    this.scrollTop()

  }
  chatMessage: any
  addEmoji(event: any) {
    this.chatMessage = this.chatMessage + event.emoji.native;
  }
  enterKey(event: any) {
    if (event.keyCode === 13) {
      this.sendMessage();
    }
  }
  showChat() {
    this.name = ''
    this.email = ''
    this.phone = ''
    this.description = ''
    this.is_showchat=!this.is_showchat
    this.message=[]
    // this.showchat.emit(false);
  }
}
