import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RoleService } from 'src/app/pages/role-management/role.service';
import { ProjectchangeService } from './projectchange.service';

@Component({
  selector: 'app-project-change',
  templateUrl: './project-change.component.html',
  styleUrls: ['./project-change.component.css']
})
export class ProjectChangeComponent implements OnInit {
  userData: any;
  companyId: any;
  projects: any[] = [];
  projectId: any;
  @Output() projectChange = new EventEmitter();

  constructor(
    private companyService: RoleService,
    private projectchangeService: ProjectchangeService
    ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.projects = this.projectchangeService._projects
    },1000);
  }
  projectChanged(data){    
    this.projectChange.emit(data);
  }
}
