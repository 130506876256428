import { SharedService } from "./../../shared/shared.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { CreateAgent } from "src/app/pages/create-agent/create-agent.service";
import { LayoutService } from "../layout.service";
import { SocketService } from "src/app/socket.service";
import { Subscription } from "rxjs";
import { ErrorHandlerService } from "src/app/error-handler.service";

@Component({
  selector: "app-agentscontacts",
  templateUrl: "./agemtscontacts.component.html",
  styleUrls: ["./agemtscontacts.component.css"],
})
export class AgemtscontactsComponent implements OnInit, OnDestroy {
  contacts: any[] = [];
  userData: any;
  profilePhotos: any;
  projectId: any;
  project: Subscription;
  constructor(
    private sharedService: SharedService,
    private agentService: CreateAgent,
    private layoutService: LayoutService,
    private handleError: ErrorHandlerService,
    private socketService: SocketService
  ) {
    this.initilization();

  }

  ngOnInit() {
    this.socketService.status.subscribe((data) => {
      if (this.projectId) {
        this.getContacts(this.projectId);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.project) {
      this.project.unsubscribe();
    }
  }

  initilization() {
    
    this.sharedService.totalAgents.subscribe((data) => {
      this.profilePhotos = data;
    });
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // if (this.userData.companyId !== 0) {
    //   this.getContacts(this.userData.companyId);
    //   this.companyId = this.userData.companyId;
    // };
    this.project = this.sharedService.projectId.subscribe((data) => {
      if (data) {
        this.projectId = data;
        this.getContacts(data);
      }
    });
  }

  getContacts(projectId) {
    this.agentService
      .getContacts(projectId, this.userData.id)
      .subscribe((data) => {
        this.contacts = data;
        this.sharedService.totalAgents.next(this.contacts);
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  callAgent(name) {
    this.layoutService.callAgent.next(`client:${name}`);
  }
}
