<div class="text-left mt-2" style="padding: 0 2rem;">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="containers">
  <div class="content-containers" style="margin-left: 30px; margin-right: 30px">
    <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />
    <!-- <span>telemarketing</span> -->
    <div class="d-flex float-left mt-3">
      <div ngbTooltip="Attachments" placement="top" tooltipClass="my-custom-class">
        <button class="btn btn-secondary btn-sm float-left" (click)="openFileUpload()">
          <img src="../../../../assets/img/attach-w.svg" class="img13" />
        </button>
        <!-- [ngClass]="{ 'is-valid': fileData.length !== 0 }" -->
        <div class="float-left ml-3">
          <div *ngIf="isUploaded" class="form-control mt-1" style="
              height: 16px;
              padding: 0px;
              background: none;
              font-size: 13px;
              color: #999;
              border: 0;
            ">
            {{ filePath }}
          </div>
          <!-- <input
            type="text"
            class="form-control mt-1"
            style="
              height: 16px;
              padding: 0px;
              background: none;
              font-size: 13px;
              color: #999;
              border: 0;
            "
            [(ngModel)]="filePath"
            placeholder=""
          /> -->

          <div class="upload-msg-success" *ngIf="isUploaded" style="color: green; font-size: 10px">
            {{ fileData.length + " " + "Contacts inserted" }}
          </div>
          <div style="color: red; font-size: 12px" *ngIf="error">
            {{ errorMessage }}
          </div>
        </div>
        <!-- <div class="valid-feedback">Looks good!</div> -->
      </div>
    </div>
    <div class="d-flex justify-content-end float-right mt-3">
      <div>
        <a ngbTooltip="Download Sample" placement="top" tooltipClass="my-custom-class" href="{{ downloadLink }}"
          class="btn btn-secondary btn-sm ml-2">
          <img src="../../../../assets/img/download-w.svg" class="img13" /></a>

        <button ngbTooltip="Create Customer" placement="top" tooltipClass="my-custom-class"
          class="btn btn-secondary btn-sm ml-2" (click)="open(content3)">
          <img src="../../../../assets/img/add-w.svg" class="img13" style="width: 11px" />
        </button>
      </div>
      <div style="clear: both"></div>
    </div>
    <div style="clear: both"></div>
    <div class="right-content-right-box-bottom-container align-items-center">
      <div class="bottom-content-box-header-container">
        <div class="ticket-requested-list-container">
          <div class="requsted-list-heading-container">
            <!-- <div class="check-box-div">
					<input type="checkbox">
				</div> -->
            <div class="table-scroll data-table">
              <table class="table table-md">
                <thead style="position: relative">
                  <tr>
                    <td colspan="7" style="padding: 8px">
                      <div class="table-search-content">
                        <div class="indived-search" style="position: relative">
                          <input
                            type="text"
                            class="form-control search-all"
                            [(ngModel)]="search"
                            placeholder="Search"
                          />
                           
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background: #eaf5fd; border-bottom: none">
                    <th>
                      <mat-checkbox (change)="selectAll()" [checked]="checked" class="custom-check"></mat-checkbox>
                    </th>
                    <!-- <th>SNo</th> -->
                    <th>
                      Name
                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('FirstName')" />
                    </th>
                    <th>
                      Phone Number

                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('PhoneNumber')" />
                    </th>
                    <th>
                      Email
                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('Email')" />
                    </th>
                    <th>
                      SMS Status

                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('SmsStatus')" />
                    </th>
                    <th>
                      Assigned To

                      <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                        (click)="sortOrder('AssignedTo')" />
                    </th>
                    <th>Action</th>
                  </tr>

                  <tr *ngFor="
                      let item of smsData
                        | filter: search
                        | slice: (page - 1) * pageSize:page * pageSize
                        | sort: orderFeild:orderString;
                      let id = index
                    ">
                    <td style="vertical-align: middle;">
                      <mat-checkbox (change)="singleChecked(item['id'])" [checked]="item['checked']"></mat-checkbox>
                    </td>
                    <td class="id-col-mod" style="
                        cursor: pointer;
                        text-decoration: none;
                        color: #163252;
                        text-transform: capitalize;
                        vertical-align: middle;
                      " (click)="openUpdateSms(content1, item['id'])">
                      {{
                      item["FirstName"] + " " + item["LastName"] | titlecase
                      }}
                    </td>
                    <td style="vertical-align: middle;">+{{ item["CountryCode"] + item["PhoneNumber"] }}</td>
                    <td style="vertical-align: middle;">{{ item["Email"] || "-" }}</td>
                    <td style="vertical-align: middle;text-transform: capitalize">
                      <div [ngClass]="{ 'status-inprogress': item['SmsStatus'] === 'pending'}">
                        {{ item["SmsStatus"] | titlecase }}
                      </div>
                    </td>
                    <td style="vertical-align: middle;">{{ item["AssignedTo"] || "-" }}</td>
                    <td style="vertical-align: middle;">
                      <button class="xaction-btn" style="
                          margin: 4px;
                          width: 28px;
                          height: 28px;
                          border: 1px solid #ccc;
                          border-radius: 25px;
                          background: none;
                        " (click)="sendSms(content, item['id'])">
                        <img ngbTooltip="Send SMS" placement="top" tooltipClass="my-custom-class" class="img7"
                          src="../../../../assets/img/message-b.svg" style="cursor: pointer; width: auto" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Send SMS</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="height:230px">
      <div>
        <div>
          <div class="pb-2">Selected Number</div>
          <div class="p-2 border rounded mb-3">
            <span *ngFor="let numbers of phoneNumbers" class="badge badge-pill badge-info mr-1">{{ "+" +
              numbers["CountryCode"] + numbers["PhoneNumber"] }}</span>
          </div>
          <div class="my-2">
            <div>Message</div>
            <textarea class="form-control my-2" rows="3" [(ngModel)]="sendingText"></textarea>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" (click)="sent()">Send SMS</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #content1 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Update SMS</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 13px;height:241px">
      <div>
        <div>
          <label>Selected Number</label>
          <div class="mb-3">
            <input type="text" class="form-control" [(ngModel)]="phoneNumber" />
          </div>
          <div class="mb-3">
            <div>Comment</div>
            <textarea class="form-control my-2" rows="3" [(ngModel)]="comments"></textarea>
          </div>
          <div class="d-flex justify-content-end mb-1">
            <button class="btn btn-primary btn-sm" (click)="updateSms()">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #content3 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Create Customer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <form #customer="ngForm" (ngSubmit)="createCustomer(customer.value)">
          <div class="row">
            <div class="col">
              <label class="my-1">First Name</label>
              <div>
                <input type="text" class="form-control mb-2" #first_name="ngModel" ngModel required name="firstName"
                  [ngClass]="{
                    'is-invalid': !first_name.valid && first_name.touched,
                    'is-valid': first_name.valid && first_name.touched
                  }" />
                <div class="invalid-feedback" *ngIf="!first_name.valid && first_name.touched">
                  {{ "please enter a valid name" | titlecase }}
                </div>
              </div>
            </div>
            <div class="col">
              <label class="my-1">Last Name</label>
              <div>
                <input type="text" #last_name="ngModel" class="form-control mb-2" ngModel required name="lastName"
                  [ngClass]="{
                    'is-invalid': !last_name.valid && last_name.touched,
                    'is-valid': last_name.valid && last_name.touched
                  }" />
                <div class="invalid-feedback" *ngIf="!last_name.valid && last_name.touched">
                  {{ "please enter a valid name" | titlecase }}
                </div>
              </div>
            </div>
          </div>
          <div class="my-2">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-default ct-code dropdown-toggle" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  +<span class="country-code">{{ selectedCountryCode }}</span>
                </button>

                <ul class="dropdown-menu" style="height: 10rem; overflow: auto">
                  <li *ngFor="let country of countries" class="my-1 " style="padding: 6px 10px;
                    cursor: pointer;
                    font-size: 13px;
                    color: #747474!important;">
                    <span href="#" (click)="selectCountry(country)">
                      <div class="flag flag-{{ country.cc }} mx-1"></div>
                      <span>{{ country.name }} (+{{ country.code }})</span>
                    </span>
                  </li>
                </ul>
              </div>
              <input type="tel" class="form-control" placeholder="please enter a valid phone number" id="number"
                name="phoneNumber" pattern="[- +()0-9]+" required #phone_number="ngModel" ngModel [ngClass]="{
                  'is-invalid': !phone_number.valid && phone_number.touched,
                  'is-valid': phone_number.valid && phone_number.touched
                }" />
              <div class="invalid-feedback" *ngIf="!phone_number.valid && phone_number.touched">
                please enter a valid phone number
              </div>
            </div>
          </div>
          <div class="my-2 mt-3">
            <label class="my-1">Email</label>
            <input
              type="text"
              class="form-control mb-2"
              #email="ngModel"
              ngModel
              email
              required
              name="email"
              [ngClass]="{
                'is-invalid': !email.valid && email.touched,
                'is-valid': email.valid && email.touched
              }" />
            <div class="invalid-feedback" *ngIf="!email.valid && email.touched">
              {{ "please enter a valid email" | titlecase }}
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" [disabled]="customer.invalid" type="submit">
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>