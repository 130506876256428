<div class="p-3">
    <div class="d-flex mb-2">
        <button class="btn btn-secondary btn-sm ml-auto" (click)="backToDashboard()">
            <img src="../../../assets/img/left-arrow-w.svg" class="img10 mr-1"> Back to Dashboard</button>
    </div>
    <div class="d-flex">
        <div class="mr-3" style="border-radius:10px;border: dashed 1px #979797;
        display: table-cell;">
            <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef"
                    (change)="fileBrowseHandler($event.target.files); fileUpload($event)" />
                <img src="assets/img/upload2.svg" class="img50 hand mt-3" alt="">
                <div class="header-12 pt-3 pb-3">Drag and drop file here</div>
                <div class="header-sm pb-3">or</div>
                <div class="btn btn-primary btn-md" for="fileDropRef">Browse for file</div>
            </div>
            <div class="instructions-block2" style="width: 450px;border-top-left-radius:0;border-top-right-radius:0">
                <div class="p-3">
                    <div class="files-list" style="background:#f1f1f1">
                        <div class="single-file" *ngFor="let file of files; let i = index">
                            <img src="assets/img/dnd/ic-file.svg" width="45px" alt="file">
                            <div class="info">
                                <h4 class="name">
                                    {{ file?.name }}
                                </h4>
                                <p class="size">
                                    {{ formatBytes(file?.size) }}
                                </p>
                                <app-progress [progress]="file?.progress"></app-progress>
                            </div>
                            <img src="assets/img/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file"
                                (click)="deleteFile(i)">
                        </div>
                    </div>


                    <div class="p-2 d-flex align-items-center" *ngIf="colList.length>0">
                        <div class="btn-set">
                            <button *ngIf="colList.length>0" #uploadFilesButton type="button"
                                class="btn btn-primary btn-sm mr-2" (click)="uploadAttachments()"
                                [disabled]="isLoading">
                                {{isLoading?'uploading...':'Upload'}}
                            </button>
                            <button *ngIf="colList.length>0" class="btn btn-light btn-sm"
                                (click)="deleteFile(0)" [disabled]="isLoading">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="instructions-block2" style="display: table-cell;">
            <div class="p-3 text-left">
                <div class="header-12 pb-3">Instructions</div>
                <ol class="olist">
                    <li>Download the sample file from the respective dashboard.</li>
                    <li>Open the downloaded 'csv' file and carefully fill the details of the student.</li>
                    <li> The date you are trying to enter i.e. "Created Date" or "Birthday", make sure the date format
                        is as specified in the sample document.</li>
                    <li> Make sure to fill the mandatory fields like phone number and email properly.</li>
                    <li> Do not use duplicate GUID or other identical fields that are defined as identifiers.</li>
                    <li>If the upload does not work, please check your document for any data format issues.</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="px-3 pt-0">
    <div class="table-responsive data-table">
        <table class="table table-bordered table-md" style="background:#fff" *ngIf="files.length>0">
            <tr>
                <th nowrap *ngFor=" let i of colList">{{i}}</th>
            </tr>
            <tbody>
                <tr *ngFor="let i of displayData">
                    <td nowrap *ngFor="let j of colList"> {{i[j]}}</td>
                </tr>
                <!-- <tr *ngIf="isLoading == true">
                    <td colspan="10">
                        <div class="norecords">
                            <img src="../../../../assets/img/waiting.gif" />

                            <div>
                                Uploading data...<br />Please wait while we upload the
                                data for you
                            </div>
                        </div>
                    </td>
                </tr> -->
            </tbody>
        </table>
    </div>
    <table id="display_excel_data" style="border: 1;">
    </table>
</div>