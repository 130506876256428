<div>
    <div class="dropdown_list">
        <button type="button" class="btn btn-light btn-lg d-flex align-items-center"
            (click)="showDropDown=!showDropDown">More +
        </button>
        <div class="drop-show" *ngIf="showDropDown">
            <ul class="more_dropdown_list">
                <li *ngFor="let a of list">
                    <div >
                        <input type="checkbox" [(ngModel)]="a.checked" (change)="getSelectedValue(a.checked,a.name)" />
                        <label><span>{{a.name}}</span></label>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>