<style>
  ::ng-deep.settingsClass {
    width: 1000px !important;
    max-height: 100% !important;
    position: static !important;
  }

  ::ng-deep.mat-dialog-container {
    padding: 0 !important;
  }
</style>
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel"> {{project + ' - Incoming Call'}}</h5>
    <button type="button" class="close" (click)="closeWindow()" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="overflow-y: auto; min-height: 500px;">
    <div class="row">
      <div class="call-module ml-3">
        <div class="ndialer">
          <!--.......code for no- incoming call screen.........  -->
          <div class="c-caller">Current Caller</div>
          <div class="dialer-middle-screen" style="text-align: center">
            <div class="custom-incomingcall-widget">
              <!--<h4 class="mt-3 text-ellipsis">
                        Krishna Jakkamsetti
                      </h4>-->
              <div class="my-1 lg-text">
                {{city}}
              </div>
              <div class="mb-2 mt-1 lg-text" style="font-weight: 100">
                {{from}}
              </div>
              <div class="custom-user-img my-3 mt-5">
                <img class="img80" src="./assets/img/user-w.svg" alt="" />
              </div>
              <h5>{{callStatus}}</h5>
              <p class="custom-call-timer" style="text-align: center">
                <countup-timer [countUpTimerConfig]="testConfig1"></countup-timer>
              </p>

              <div class="custom-incoming-btns my-3 mt-4">
                <button type="button" class="btn-round m-r-10" (click)="endCall()">
                  <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div *ngIf="project == 'Campbellsville'" class="call-module-update ml-4 mr-2">
        <div class="row">
          <div class="col-md-12 d-flex mb-2">
            <div class="header-md mt-2 mb-1">Student details</div>
            <!-- <div class="ml-auto">
              <button class="btn btn-primary btn-sm">Activity</button>
            </div> -->
          </div>
        </div>
        <div class="row custom-row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">Name</label>
              <div class="label2">{{leadInfo.FirstName+ ' ' +leadInfo.LastName}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">GUID</label>
              <div class="label2">{{leadInfo.GUID}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">APP STATUS</label>
              <div class="label2">{{leadInfo.APPStatus}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">SUBMITTED DATE</label>
              <div class="label2">{{leadInfo.createdAt | date: "medium"}}</div>
            </div>
          </div>
        </div>
        <div class="row custom-row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">DOB</label>
              <div class="label2">{{leadInfo.DOB | date: "dd MMM, yyyy"}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">ENROLLMENT YEAR</label>
              <div class="label2">{{leadInfo.EnrollmentYear}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">PROGRAM</label>
              <div class="label2">{{leadInfo.Program}}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="formGroupExampleInput">PHONE NUMBER</label>
              <div class="label2">{{'+'+leadInfo.CountryCode+' '+leadInfo.PhoneNumber}}</div>
            </div>
          </div>
        </div>

        <div class="row custom-row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="formGroupExampleInput">Email</label>
              <div class="label2">{{leadInfo.Email}}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="formGroupExampleInput">Address</label>
              <div class="label2">{{leadInfo.Address}}</div>
            </div>
          </div>
        </div>
        <hr class="hr-style mt-1 mb-1" />
        <div class="row">
          <div class="col-md-12">
            <div class="header-md mt-2 mb-1">Document details </div>
          </div>
        </div>
        <div class="row custom-row" *ngFor="
                  let item of documentsList;
                  let i = index;
                  let even = even;
                  let odd = odd
                ">
                <div class="col-md-6 py-2" *ngIf="even">
                  <img *ngIf="item.status == true" class="img18 mr-2" src="../../../../assets/img/tick2.svg" />
                  <img *ngIf="item.status == false" class="img18 mr-2" src="../../../../assets/img/minus.svg" />{{
                  documentsList[i].ProgramDoc }}
                </div>
                <div class="col-md-6 py-2" *ngIf="even && i + 1 < documentsList.length">
                  <img *ngIf="documentsList[i + 1].status == true" class="img18 mr-2"
                    src="../../../../assets/img/tick2.svg" />
                  <img *ngIf="documentsList[i + 1].status == false" class="img18 mr-2"
                    src="../../../../assets/img/minus.svg" />{{ documentsList[i + 1].ProgramDoc }}
                </div>
              </div>
        <div class="row">
          <div class="col-md-12">
            <div class="header-md mt-2 mb-1">Feedback</div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" id="check1" name="option1" value="notinterested" (change)="onStatusChange($event.target.value)" />Not
              Interested
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" id="check3" name="option1" value="informed" (change)="onStatusChange($event.target.value)" />Informed
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check4">
              <input type="radio" class="form-check-input" id="check4" name="option1" value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check5">
              <input type="radio" class="form-check-input" id="check5" name="option1" value="callback" (change)="onStatusChange($event.target.value)" />Call Back
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check6">
              <input type="radio" class="form-check-input" id="check6" name="option1" value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check7">
              <input type="radio" class="form-check-input" id="check7" name="option1" value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred
              Intake
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check8">
              <input type="radio" class="form-check-input" id="check8" name="option1"
                value="uncontactable" (change)="onStatusChange($event.target.value)" />Uncontactable
            </label>
          </div>
        </div>
        <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 15px">
          Please select any option above!
        </span>
        <div class="row custom-row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="commentText" class="header-md pt-3 pb-2" style="font-size: 14px">Comments</label>
              <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
              Reset
            </button>
            <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
              Update
            </button>
          </div>
        </div>
      </div>


      <div *ngIf="project == 'Referrals'" class="call-module-update ml-4 mr-2">
        <div class="row">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Referral details</div>
          </div>
        </div>
        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Name</label>
              <div>
                {{leadInfo.FirstName+" "+leadInfo.LastName}}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Email</label>
              <div>{{leadInfo.Email}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Mobile</label>
              <div>{{leadInfo.PhoneNumber}}</div>
            </div>
          </div>

        </div>

        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Created Date</label>
              <div>
                {{leadInfo.createdAt | date: "medium"}}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">University</label>
              <div class="d-flex">
                <div class="pic mr-1 float-left text-center">
                  <!-- <img class="img20" src="../../../../assets/img/university.svg" /> -->
                </div>
                <div class="txt-oflow2 lh180 float-left">
                  {{leadInfo.University || ''}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="hr-style mt-1 mb-1" />
        <div class="row">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Application status</div>
          </div>
        </div>
        <div class="row custom-row2 mb-3">
          <div class="col-md-4 mt-2">
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="option11">
                <input [(ngModel)]="applicationStatus" type="radio" class="form-check-input" id="option11"
                  name="yes" value='yes' (change)="applicationstatus($event.target.value)" />Yes
              </label>
            </div>
            <div class="form-check mr-5 d-inline">
              <label class="form-check-label" for="option2">
                <input [(ngModel)]="applicationStatus" type="radio" class="form-check-input" id="option2" name="no"
                  value='no' (change)="applicationstatus($event.target.value)" />No
              </label>
            </div>
          </div>
          <div class="col-md-4" *ngIf="applicationStatus=='yes'">
            <div class="form-check" style="padding-left:0">

              <select id="inputState" class="form-control" [(ngModel)]="selectedUniversity">
                <option disabled value="">Choose One</option>
                <option *ngFor="let i of universityList" value={{i.id}}>
                  {{ i.university }}
                </option>
                <option value="other">Other</option>
              </select>

            </div>
          </div>
        </div>

        <div class="row custom-row2 mb-3" *ngIf="selectedUniversity=='other'">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">University Name</label>
              <div class="d-flex">
                <input type="text" id="customUniversity" [(ngModel)]="customUniversityName" class="form-control"
                  id="" placeholder="" />
              </div>
            </div>
          </div>

        </div>

        <hr class="hr-style mt-1 mb-1" />
        <div class="row custom-row2">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Feedback</div>
          </div>

          <div class="col-md-12">
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="feedback">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="notinterested" (change)="onStatusChange($event.target.value)" />Not Interested
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check3">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="informed" (change)="onStatusChange($event.target.value)" />Informed
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check4">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check5">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="callback" (change)="onStatusChange($event.target.value)" />Call Back
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check6">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check7">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred Intake
              </label>
            </div>
          </div>
        </div>
        <span *ngIf="!newStatus" class="error-msg pt-2 pb-2" style="color: red; font-size: 13px">
          Please select any option above!
        </span>
        <div class="row custom-row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="commentText" class="header-12 pt-3 pb-2"  >Comments</label>
              <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button class="btn btn-light btn-sm mr-2" type="button" (click)="resetCallingScreen()">
              Reset
            </button>
            <button class="btn btn-primary btn-sm" type="submit" (click)="updateComments2()">
              Update
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="project == 'Referee'" class="call-module-update ml-4 mr-2">
        <div class="row">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Referee details</div>
          </div>
        </div>
        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Name</label>
              <div>
                {{leadInfo.FirstName+" "+leadInfo.LastName}}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Email</label>
              <div>{{leadInfo.Email}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Mobile</label>
              <div>{{leadInfo.PhoneNumber}}</div>
            </div>
          </div>

        </div>

        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Created Date</label>
              <div>
                {{leadInfo.createdAt | date: "medium"}}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">University</label>
              <div class="d-flex">
                <div class="pic mr-1 float-left text-center">
                  <!-- <img class="img20" src="../../../../assets/img/university.svg" /> -->
                </div>
                <div class="txt-oflow2 lh180 float-left">
                  {{leadInfo.University || ''}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Referral Count</label>
              <div>{{leadInfo.ReferralCount}}</div>
            </div>
          </div>
        </div>

        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Referral Bonus</label>
              <div>
                {{leadInfo.ReferralBonus}}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Referral Status</label>
              <div>
                {{leadInfo.ReferralStatus}}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Asigned To</label>
              <div>
                {{leadInfo.AssignedTo}}
              </div>
            </div>
          </div>
          
          
        </div>
        <hr class="hr-style mt-1 mb-1" />
        <div class="row custom-row2">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Feedback</div>
          </div>

          <div class="col-md-12">
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="feedback">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="notinterested" (change)="onStatusChange($event.target.value)" />Not Interested
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check3">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="informed" (change)="onStatusChange($event.target.value)" />Informed
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check4">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check5">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="callback" (change)="onStatusChange($event.target.value)" />Call Back
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check6">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check7">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred Intake
              </label>
            </div>
          </div>
        </div>
        <span *ngIf="!newStatus" class="error-msg pt-2 pb-2" style="color: red; font-size: 13px">
          Please select any option above!
        </span>
        <div class="row custom-row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="commentText" class="header-12 pt-3 pb-2"  >Comments</label>
              <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button class="btn btn-light btn-sm mr-2" type="button" (click)="resetCallingScreen()">
              Reset
            </button>
            <button class="btn btn-primary btn-sm" type="submit" (click)="updateComments4()">
              Update
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="project == 'University Hub'" class="call-module-update ml-4 mr-2">
        <div class="row">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Lead Details</div>
          </div>
        </div>
        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Name</label>
              <div>
                {{leadInfo.FirstName+" "+leadInfo.LastName}}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Email</label>
              <div>{{leadInfo.Email}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Mobile</label>
              <div>{{leadInfo.PhoneNumber}}</div>
            </div>
          </div>

        </div>

        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Created Date</label>
              <div>
                {{leadInfo.createdAt | date: "medium"}}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Form Name</label>
              <div>{{leadInfo.Miscellaneous.form_name}}</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Interested</label>
              <div>{{leadInfo.Interested}}</div>
            </div>
          </div>
        </div>
        
        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Assigned To</label>
              <div>
                {{leadInfo.Assignedto}}
              </div>
            </div>
          </div>
          
        </div>
        <hr class="hr-style mt-1 mb-1" />
        <div class="row custom-row2">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Feedback</div>
          </div>

          <div class="col-md-12">
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="feedback">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="notinterested" (change)="onStatusChange($event.target.value)" />Not Interested
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check3">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="informed" (change)="onStatusChange($event.target.value)" />Informed
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check4">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check5">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="callback" (change)="onStatusChange($event.target.value)" />Call Back
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check6">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
              </label>
            </div>
            <div class="form-check mr-3 d-inline">
              <label class="form-check-label" for="check7">
                <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                  value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred Intake
              </label>
            </div>
          </div>
        </div>
        <span *ngIf="!newStatus" class="error-msg pt-2 pb-2" style="color: red; font-size: 13px">
          Please select any option above!
        </span>
        <div class="row custom-row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="commentText" class="header-12 pt-3 pb-2"  >Comments</label>
              <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button class="btn btn-light btn-sm mr-2" type="button" (click)="resetCallingScreen()">
              Reset
            </button>
            <button class="btn btn-primary btn-sm" type="submit" (click)="updateComments3()">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>