<div *ngIf="is_showchat">
		<div id="ac-chat-window" style="z-index: 99999;">
			<div id="ac-chat-window-header">
				<h3>Accessque chat</h3>
				<div class="chat-control">
					<!-- <img src="assets/mini-w.png" style="width: 16px; position: cursor;margin-right: 5px;"> -->
					<img src="assets/img/close-w.png" (click)="is_showchat=false" style="width: 16px;" >
				</div>
			</div>
			<div class="chat-body">
				<form novalidate="" *ngIf="message && !message.length" class="ng-untouched ng-pristine ng-invalid" style="line-height: 20px;">
					<div class="chat-fields">Name<br>
						<input type="name" id="exampleInputEmail1" aria-describedby="emailHelp"
							placeholder="Please enter name" name="name" [(ngModel)]="name" required=""
							class="ng-untouched ng-pristine ng-invalid">
					</div>
					<div class="chat-fields">Email<br>
						<input type="email" id="exampleInputEmail1" autocomplete="false" aria-describedby="emailHelp"
							placeholder="Enter email" name="email" [(ngModel)]="email" required=""
							class="ng-untouched ng-pristine ng-invalid">
					</div>
					<div class="chat-fields">Phone<br><input type="text" id="exampleInputPassword1"
							placeholder="phoneNumber" name="phoneNumber" [(ngModel)]="phone" required=""
							class="ng-untouched ng-pristine ng-invalid"></div>
					<div class="chat-fields">Description<br><textarea [(ngModel)]="description" name="desc" required=""
							class="ng-untouched ng-pristine ng-invalid"></textarea></div>
					<div class="chat-fields" *ngIf="showNote">
						<span ><b>Note :</b> please wait connecting to available agents </span>
					</div>
					<div class="chat-fields">
						<button type="submit" [disabled]="showNote" class="chat-fields-btn pointer" [ngStyle]="showNote ? {'pointer-events': 'none'} : {'pointer-events': 'auto'}" (click)="submit()"> Submit
						</button>
						
					</div>
				</form>
				<div *ngIf="message && message.length>0" style="overflow: auto;height:350px" class="scrollbar" #chatContainer>
					<div *ngFor="let message of message" class="chat-block">
					 
						<div class="chat-messages-left-wrapper" *ngIf="!(message.author === username)">
							<!-- <div
							*ngIf="message.author === 'system'"
							class="system my-1"
						>
							{{ message.body | titlecase }}
						</div> -->
							<!-- <div *ngIf="message.author !== 'system'">
							<div class="messages-user-img-container">
								<img
									class="user-img"
									src="../../../assets/img/chat-user-icon.png"
									alt=""
								/>
								<div class="messages-status online"></div>
							</div>
						</div> -->
							<div style="width: auto; word-break: break-word" *ngIf="message.author !== 'system'">
								<div class="messages-user-img-container">
									<img
										class="user-img"
										src="../../../assets/img/chat-user-icon.png"
										alt=""
									/>
									<div class="messages-status online"></div>
								</div>
								<div class="message-left-bubble flex-column">
									<div class="author my-1">
										{{
										message.author
										}}:
									</div>
	
									<p class="message-text">
										{{ message.body }}
									</p>
								</div>
								<p class="message-time">
									{{ message.timestamp | date: "medium" }}
								</p>
							</div>
						</div>
						<div class="chat-messages-right-wrapper"  *ngIf="message.author === username">
							<div style="
								width: auto;
								margin-right: 15px;
								word-break: break-word;
							">
								<div class="message-right-bubble">
									<div class="author my-1">
										{{
										message.author
										}}:
									</div>
	
									<p class="message-text" style="color: black">
										{{ message.body }}
									</p>
								</div>
								<p class="message-time right-side" style="color: black">
									{{ message.timestamp | date: "medium" }}
								</p>
							</div>
							<div>
								<div class="messages-user-img-container">
									<img class="user-img" style="width: 25px; height: 25px"
										src="../../../assets/img/chat-accessque-logo.png" alt="" />
									<div class="messages-status online"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="message-send-box-wrapper" *ngIf="message && message.length>0">
					<div class="chat-bottom-block">
						<div class="chat-inner-block">
							<input type="text" class="" name="" (keyup)="enterKey($event)" [(ngModel)]="chatMessage" />
							<!-- <mat-icon
									style="position: absolute; top: 7px; right: 90px"
									(click)="isIconShow = !isIconShow"
									>mood</mat-icon
								> -->
							<!-- <emoji-mart
									*ngIf="isIconShow"
									(emojiClick)="addEmoji($event)"
									emoji="point_up"
									[hideObsolete]="true"
									[set]="'facebook'"
								></emoji-mart> -->
							<button class="message-send-btn" (click)="sendMessage()">Send</button>
						</div>
					</div>
				</div>
			</div>
	
		</div>
</div>

<div class="container-fluid">
	<!-- Header Starts -->

	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<div class="container-fluid">
			<div
				style="
					display: flex;
					justify-content: space-between;
					width: 100%;
					align-items: center;
				"
			>
				<div>
					<a class="navbar-brand" href="index.html"
						><img src="../../assets/images/acq-logo.png"
					/></a>
					<button
						class="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="navbar-toggler-icon"></span>
					</button>
				</div>
				<div class="" id="navbarSupportedContent">
					<div class="me-auto"></div>

					<!--
              <a href="#" class='btn btn-link'>Services</a>
              <a href="#" class='btn btn-link'>Plans</a>
              <a href="#" class='btn btn-link'>About Us</a>
              <a href="#" class='btn btn-link'>Contact Us</a>-->
					<a
						type="button"
						class="btn orange-btn"
						style="margin-right: 10px"
						[routerLink]="['/account/login']"
						>Member Login</a
					>
					<!--
              <button type="button" class='btn btn-primary btn-md' style='margin-right:10px'>Sign Up</button>
            </form>-->
					<!-- Login Modal -->
					<div
						class="modal fade"
						id="exampleModal"
						tabindex="-1"
						aria-labelledby="exampleModalLabel"
						aria-hidden="true"
					>
						<div class="modal-dialog" style="width: 390px">
							<div class="modal-content">
								<div class="modal-body">
									<button
										type="button"
										class="btn-close float-end"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
									<div class="md-txt primary p-l-10 p-b-10">
										Log in to your <span class="grey">Account</span>
									</div>
									<div class="p-a-15">
										<form class="row g-3">
											<div class="col-md-4">
												<label>Dial Code</label>
												<select
													class="form-select"
													aria-label="Default select example"
												>
													<option selected>India +91</option>
													<option value="code 1">code 1</option>
													<option value="code 2">code 2</option>
													<option value="code 3">code 3</option>
												</select>
											</div>
											<div class="col-md-8">
												<input
													type="text"
													class="form-control"
													style="margin-top: 23px"
													id=""
													placeholder="Mobile Number"
												/>
											</div>

											<div class="input-group">
												<label
													type="text"
													class="form-control text-center primary"
													style="border: 0"
													placeholder="Enter Mobile Number"
													aria-label="Enter Mobile Number"
													aria-describedby="button-addon2"
													value=""
													>OTP Sent
												</label>
												<div class="input-group-append">
													<button
														class="btn btn-outline-secondary btn-otp"
														type="button"
														id="button-addon2"
													>
														Send OTP
													</button>
												</div>
											</div>

											<div class="input-group" style="display: none">
												<select
													class="form-select"
													aria-label="Default select example"
													style="width: 80px"
												>
													<option selected>Ind +91</option>
													<option value="code 1">code 1</option>
													<option value="code 2">code 2</option>
													<option value="code 3">code 3</option>
												</select>

												<input
													type="text"
													class="form-control"
													placeholder="Enter Mobile Number"
													aria-label="Enter Mobile Number"
													aria-describedby="button-addon2"
													value="9866411591"
												/>
												<div class="input-group-append">
													<button
														class="btn btn-outline-secondary btn-otp"
														type="button"
														id="button-addon2"
													>
														Send OTP
													</button>
												</div>
											</div>
											<!-- For OTP Enter-->

											<div class="col-md-12">
												<input
													type="text"
													class="form-control text-center"
													style="font-weight: bold"
													id=" "
													placeholder=" "
													value="5 8 6 7 8 9"
												/>
											</div>
											<!-- For OTP close -->

											<div class="d-grid xgap-2">
												<button class="btn btn-primary" type="button">
													Login
												</button>
											</div>
											<div
												style="
													width: 250px;
													margin: 10px auto 0;
													text-align: center;
												"
											>
												<div class="brline"></div>
												<div style="float: left; padding: 0 10px">or</div>
												<div class="brline"></div>
											</div>
											<div class="d-grid gap-2">
												<button class="g-button" type="button">
													Continue with Gmail
												</button>
											</div>
											<div class="text-center">
												Don’t have an account?
												<a
													href="registration.html"
													class="link"
													style="display: inline"
													>Register Now</a
												>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Login Modal Ends -->
					<!-- Book my Trail Modal Starts -->
					<div
						class="modal fade"
						id="exampleModal2"
						tabindex="-1"
						aria-labelledby="exampleModalLabel"
						aria-hidden="true"
					>
						<div class="modal-dialog" style="width: 390px">
							<div class="modal-content">
								<div class="modal-body">
									<button
										type="button"
										class="btn-close float-end"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
									<div class="md-txt primary p-l-10 p-b-10">
										Book a Free<span class="grey">Trial</span>
									</div>
									<div class="p-a-15">
										<form class="row g-3">
											<div class="col-md-12">
												<input
													type="text"
													class="form-control"
													id=" "
													placeholder="Enter Student Name"
												/>
											</div>
											<div class="input-group">
												<input
													type="text"
													class="form-control"
													placeholder="Enter Mobile Number"
													aria-label="Enter Mobile Number"
													aria-describedby="button-addon2"
												/>
												<div class="input-group-append">
													<button
														class="btn btn-outline-secondary btn-otp"
														type="button"
														id="button-addon2"
													>
														Send OTP
													</button>
												</div>
											</div>

											<!-- For OTP Enter-->
											<div class="col-md-12 text-center primary">OTP sent</div>
											<div class="col-md-12">
												<input
													type="text"
													class="form-control text-center"
													style="font-weight: bold"
													id=" "
													placeholder=" "
													value="5 8 6 7 8 9"
												/>
											</div>
											<!-- For OTP close -->

											<div class="col-md-6">
												<input
													type="text"
													class="form-control"
													id=" "
													placeholder="Email address"
												/>
											</div>
											<div class="col-md-6">
												<select
													class="form-select"
													aria-label="Default select example"
												>
													<option selected>Select State</option>
													<option value="code 1">Andhra Pradesh</option>
													<option value="code 2">Delhi</option>
													<option value="code 3">Goa</option>
												</select>
											</div>

											<div
												style="
													width: 300px;
													margin: 10px auto 0;
													text-align: center;
												"
											>
												<div class="brline2"></div>
												<div style="float: left; padding: 0 10px">
													select your camp
												</div>
												<div class="brline2"></div>
											</div>

											<div class="d-grid gap-2 d-md-block text-center">
												<button type="button" class="btn btn-secondary btn-md">
													Basecamp
												</button>
												<button type="button" class="btn btn-secondary btn-md">
													Transitcamp
												</button>
												<button type="button" class="btn btn-secondary btn-md">
													Summit
												</button>
											</div>
											<div class="d-grid xgap-2">
												<button class="btn btn-primary" type="button">
													Schedule a Free Trial
												</button>
											</div>

											<!-- Message stats -->
											<div class="md-txt primary text-center">
												<img
													src="../../assets/images/check.svg"
													style="width: 48px; display: block; margin: 0 auto"
												/>
												Thank you!
											</div>
											<div class="md-txt primary text-center">
												<p class="text-center md-txt" style="font-size: 15px">
													Your account have been registered successfully.
												</p>
												<a
													href="basecamp.html"
													class="link"
													style="font-size: 16px; font-weight: bold"
												>
													Let's Experiment</a
												>
											</div>
											<!-- Message Ends -->
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>

	<div class="row">
		<div class="home-banner-bg">
			<div class="banenr-text">
				The best customer experiences <br />are built with <b>Accessque</b>
			</div>
		</div>
	</div>
	
	<div class="row b-w">
		<div class="col-md-7 bg-dots">
			<div class="p-50 bg-circle">
				<div class="brandfont">AccessQue</div>
				<p
					style="
						font-size: 16px;
						font-weight: 400;
						padding: 10px 0 15px 0;
						color: #4c514c;
					"
				>
					Accessque makes support, sales, and customer engagement software for
					everyone. It’s quick to implement, easy to use, and scales to fit your
					needs. With Accessque, it takes hours to get up and running.— not
					weeks
				</p>
				<p style="font-size: 16px; font-weight: 400; color: #4c514c">
					Your customers and employees want to talk to you—make it easy for
					them. Our support products are flexible and empower you to assist
					people when they need you most.
				</p>
			</div>
		</div>
		<div class="col-md-5 nomobile">
			<div class="p-t-10">
				<img style="width: 60%" src="../../assets/images/info-graphic1.png" />
			</div>
		</div>
	</div>
	<div class="row b-blue" style="min-height: 450px; padding-bottom: 15px">
		<div class="col-md-12 bg-dots-w">
			<div class="p-a-15">
				<div class="text-center brandfont-w2">Why AccessQue ?</div>
			</div>
			<div>
				<div class="ctrxx tctr" style="">
					<div class="btn-tab">
						<button
							class="tablinks2"
							(click)="currentIndex = 0"
							[ngClass]="{ active: currentIndex === 0 }"
						>
							<img src="../../assets/images/xcall-3.png" />
							<p>Instant Calls</p>
						</button>
						<button
							class="tablinks2"
							(click)="currentIndex = 1"
							[ngClass]="{ active: currentIndex === 1 }"
						>
							<img src="../../assets/images/chat-3.png" />
							<p>Live Chat</p>
						</button>
						<button
							(click)="currentIndex = 2"
							class="tablinks2"
							[ngClass]="{ active: currentIndex === 2 }"
						>
							<img src="../../assets/images/reports-3.png" />
							<p>Social</p>
						</button>
						<button
							(click)="currentIndex = 3"
							class="tablinks2"
							[ngClass]="{ active: currentIndex === 3 }"
						>
							<img src="../../assets/images/email-3.png" />
							<p>Mails</p>
						</button>
						<button
							class="tablinks2"
							(click)="currentIndex = 4"
							[ngClass]="{ active: currentIndex === 4 }"
						>
							<img src="../../assets/images/audio-3.png" />
							<p>Integrated Voice</p>
						</button>
						<button
							class="tablinks2"
							(click)="currentIndex = 5"
							[ngClass]="{ active: currentIndex === 5 }"
						>
							<img src="../../assets/images/tickets-3.png" />
							<p>Tickets</p>
						</button>
					</div>
					<div
						class="tabcontent-ctr bg-circle2"
						style="background-position: left 0px top 30px"
					>
						<div
							id="lm"
							class="tabcontent2 bg-dots-primary"
							style="display: block"
						>
							<div class="p-a-30">
								<h3 class="primary">{{ content[currentIndex]['title'] }}</h3>
								<p>
									{{ content[currentIndex]['content'] }}
								</p>
							</div>
						</div>

						<div id="rtl" class="tabcontent2 bg-dots-primary">
							<div class="p-a-30">
								<h3 class="primary">Live Chat</h3>
								<p>
									Create a personal connection with customers looking for
									support. It’ s fast and effective
								</p>
							</div>
						</div>

						<div id="sim" class="tabcontent2 bg-dots-primary">
							<div class="p-a-30">
								<h3 class="primary">Social messaging</h3>
								<p>
									Be where they want to be — connect popular messaging apps to
									Accessque.
								</p>
							</div>
						</div>

						<div id="ai" class="tabcontent2 bg-dots-primary">
							<div class="p-a-30">
								<h3 class="primary">Mails</h3>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Curabitur sit amet ipsum quis nulla viverra auctor.
								</p>
								<p>
									Ut orci dolor, porttitor a consequat sed, ultrices nec erat.
									Aliquam tempus.Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Curabitur sit amet ipsum quis nulla viverra
									auctor.Lorem ipsum dolor sit amet, consectetur adipiscing
									elit. Curabitur sit amet ipsum quis nulla viverra auctor.
								</p>
							</div>
						</div>

						<div id="ml" class="tabcontent2 bg-dots-primary">
							<div class="p-a-30">
								<h3 class="primary">Integrated Voice</h3>
								<p>
									Talk it out directly with customers and provide personalized
									solutions to complex issues.
								</p>
							</div>
						</div>

						<div id="il" class="tabcontent2 bg-dots-primary">
							<div class="p-a-30">
								<h3 class="primary">Ticketing system</h3>
								<p>
									Track, prioritize, and solve customer support tickets all in
									one place.
								</p>
							</div>
						</div>
					</div>
					<div class="clearfix"></div>
				</div>
			</div>
		</div>
	</div>
	<div class="row b-w">
		<div class="col-md-12">
			<div class="p-a-45">
				<div class="text-center brandfont2">
					The way it
					<span class="brandfont">Works</span>
				</div>
			</div>
		</div>
	</div>
	<div
		class="row b-w bg-circle2"
		style="min-height: 200px; background-position: left 0px top 30px"
	>
		<div class="col-md-3 b-w text-center">
			<img src="../../assets/images/enter.png" xstyle="width:50px" />
			<div class="md-txt p-a-15">
				Login to
				<div class="primary" style="display: inline-block">AccessQue</div>
			</div>
		</div>
		<div class="col-md-3 b-w text-center">
			<img src="../../assets/images/experiment.png" style="margin-top: 8px" />
			<div class="md-txt p-a-15">
				Select
				<div class="primary" style="display: inline-block">Plan</div>
			</div>
		</div>
		<div class="col-md-3 b-w text-center">
			<img src="../../assets/images/star-business.png" xstyle="width:50px" />
			<div class="md-txt p-a-15">
				Start
				<div class="primary" style="display: inline-block">Business</div>
			</div>
		</div>
		<div class="col-md-3 b-w text-center">
			<img src="../../assets/images/results.png" />
			<div class="md-txt p-a-15">
				Run
				<div class="primary" style="display: inline-block">Business</div>
			</div>
		</div>
	</div>
	<div class="row b-w">
		<div class="col-md-12">
			<div class="p-a-30">
				<div class="text-center brandfont2">Price</div>
			</div>
		</div>
	</div>
	<div class="row b-w">
		<div
			class="col-md-12 bg-lab"
			style="background: linear-gradient(0deg, transparent, #f1f3ff)"
		>
			<div class="bg-dots2" style="width: 760px; margin: 35px auto">
				<div class="price-block">
					<div class="price-block-top">
						<b>Starter</b>
					</div>
					<div class="price-block-bottom">
						<div class="price text-center">$30.00</div>
						<p class="pricefor text-center">User/Month</p>
						<button type="button" class="price-btn">Buy Now</button>
						<ul class="f-list">
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Email
								Ticketing
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Customer
								Management
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Help
								Center
							</li>
							<p
								style="
									font-size: 11px;
									opacity: 0.7;
									text-align: center;
									position: absolute;
									bottom: 9px;
									left: 0;
									right: 0;
								"
							>
								*Call charges as applicable
							</p>
						</ul>
					</div>
				</div>

				<div class="price-block">
					<div class="price-block-top">
						<b>Professional</b>
					</div>
					<div class="price-block-bottom">
						<div class="price text-center">$45.00</div>
						<p class="pricefor text-center">User/Month</p>
						<button type="button" class="price-btn">Buy Now</button>
						<ul class="f-list">
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Email
								Ticketing
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; SMS
								Ticketing
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Chat
								Communication
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Customer
								Management
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Help
								Center
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Private
								Knowledge Base
							</li>
							<p
								style="
									font-size: 11px;
									opacity: 0.7;
									text-align: center;
									position: absolute;
									bottom: 9px;
									left: 0;
									right: 0;
								"
							>
								*Call charges as applicable
							</p>
						</ul>
					</div>
				</div>

				<div class="price-block">
					<div class="price-block-top">
						<b>Enterprise</b>
					</div>
					<div class="price-block-bottom">
						<div class="price text-center">$60.00</div>
						<p class="pricefor text-center">User/Month</p>
						<button type="button" class="price-btn">Buy Now</button>
						<ul class="f-list">
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Email
								Ticketing
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; SMS
								Ticketing
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Chat
								Communication
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Customer
								Management
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Bulk SMS
								Services
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Help
								Center
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp; Private
								Knowledge Base
							</li>
							<li>
								<img src="../../assets/images/tick1.png" />&nbsp;&nbsp;
								Predefined SLAs
							</li>
							<p
								style="
									font-size: 11px;
									opacity: 0.7;
									text-align: center;
									position: absolute;
									bottom: 9px;
									left: 0;
									right: 0;
								"
							>
								*Call charges as applicable
							</p>
						</ul>
					</div>
				</div>

				<div style="clear: both"></div>
			</div>
		</div>

		<!--Price block ends-->
	</div>
	<!-- Students outlook starts -->
	<div class="row b-w bg-dots">
		<div class="col-md-12">
			<div class="p-a-30">
				<div class="text-center brandfont2">Testimonails</div>
			</div>
		</div>

		<div class="col-md-12 text-center">
			<div style="width: 990px; margin: 15px auto">
				<div class="testi-block">
					Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe
					ndisse eleif end...
					<br />
					<b>- Sunile Rathod</b>
				</div>
				<div class="testi-block">
					Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe
					ndisse eleif end...
					<br />
					<b>- Sunile Rathod</b>
				</div>
				<div class="testi-block">
					Lorem ipsum dolor sit amet, con sec tetur adipiscing elit. Suspe
					ndisse eleif end...
					<br />
					<b>- Sunile Rathod</b>
				</div>
			</div>
			<div class="p-a-30" style="clear: both"></div>
		</div>
	</div>
	<!-- Students outlook ends -->

	<!-- Footer starts -->
	<div class="row b-g">
		<div class="col-md-3 p-a-30">
			<img src="assets/images/acq-logo.png" style="width: 150px" />
			<div class="social">
				<a href="#"><img src="assets/images/facebook.svg" /></a>
				<a href="#"><img src="assets/images/youtube.svg" /></a>
				<a href="#"><img src="assets/images/twitter.svg" /></a>
				<a href="#"><img src="assets/images/instagram.svg" /></a>
				<a href="#"><img src="assets/images/linkedin.svg" /></a>
			</div>
		</div>
		<div class="col-md-3 p-a-30 f-link force-top-45">
			<b>Company</b>
			<a class="" href="#">About Us</a>
		</div>
		<div class="col-md-3 p-a-30 f-link force-top-45">
			<b>Policies</b>
			<a class="" routerLink="/privacy">Privacy Policy</a>
			<a class="" href="#">Terms & Conditions</a>
		</div>
		<div class="col-md-3 p-a-30 f-link text-end force-top-85">
			Developed by <br />
			<b>Avantao Technologies</b>
		</div>
	</div>
	<!-- Footer Ends -->

	<!-- Container div end -->
</div>
<div class="button-container">
	<button (click)="showChat();" class="floating-action-btn" style="position: relative">  
	  <img src="assets/logos/chat.png" alt="chat" style="width: 30px; height: 30px; padding: 0"
		 />
	</button>
</div>

