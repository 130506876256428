<div class="pl-3 pr-3" style="background:#fff">
<!-- <div class="header-md pt-3 pb-3 bg-white px-3">List of Agents</div>
<div class="d-flex bg-white">
  <div class="px-3 py-1">
    <p style="font-size:13px">Manage product access for all the users in your organisation. 
      <a href="#">Learn more about access settings.</a>
    </p>
  </div>
  <div class="ml-auto px-4">
    <div class="btn btn-secondary btn-sm mr-2" >Invite Users</div>
    <div class="btn btn-light btn-sm " >...</div>
  </div>
</div>
<div class="pb-0 pt-0 mt-2 ml-3 d-flex bg-white">
  <div class="d-block text-left">
    <div>Totals</div>
    <div class="header-lg mt-1">27</div>
  </div>
  <div class="d-block text-left">
    <div>Managers</div>
    <div class="header-lg mt-1">08</div>
  </div>
  <div class="d-block text-left">
    <div>Agents</div>
    <div class="header-lg mt-1">19</div>
  </div>
  <div class="d-block text-left">
    <div>Active</div>
    <div class="header-lg mt-1">17</div>
  </div>
  <div class="d-block text-left">
    <div>Inactive</div>
    <div class="header-lg mt-1">0</div>
  </div>     
</div>
<div class="d-flex ml-3 mr-3 mb-1 mt-2">
  <div class="mr-2"><input type="text" class="spl-search" placeholder="Enter name or email address" /></div>
  <div class="mr-3 btn btn-filter btn-sm"> Filter (5) </div>
  <div class="filter-block">
    <div class="d-flex border-bottom">
      <div class="header-sm py-2" style="color:#163252">Filter by</div>
      <div class="ml-auto"><a class="btn btn-link btn-sm" >Reset Filter</a></div>
    </div>
    <div class="form-group form-check">
      <input type="checkbox" class="form-check-input" id="exampleCheck1">
      <label class="form-check-label" for="exampleCheck1">Managers</label>
    </div>
    <div class="form-group form-check">
      <input type="checkbox" class="form-check-input" id="exampleCheck1">
      <label class="form-check-label" for="exampleCheck1">Agents</label>
    </div>
    <div class="form-group form-check">
      <input type="checkbox" class="form-check-input" id="exampleCheck1">
      <label class="form-check-label" for="exampleCheck1">Active Users</label>
    </div>
    <div class="form-group form-check">
      <input type="checkbox" class="form-check-input" id="exampleCheck1">
      <label class="form-check-label" for="exampleCheck1">Inactive</label>
    </div>
  </div>
  <div class="ml-auto" >Pagination</div>
</div> -->
<div class="text-left p-3 mt-2">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="right-content-wrapper" style="background:#fff">
  <div class="right-content-right-box-bottom-container">
    <div class="table-scroll data-table mb-4">
      <table class="table table-md table-hover">
        <tbody>
          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th style="width: 55px; text-align: center">S No</th> -->
            <th>
              Name
              <img
                class="img7 ml-1"
                src="../../../../assets/img/sort-data.svg"
                style="cursor: pointer"
                (click)="sortOrder('FirstName')"
              />
            </th>
            <th>
              Created Date
              <img
                class="img7 ml-1"
                src="../../../../assets/img/sort-data.svg"
                style="cursor: pointer"
                (click)="sortOrder('PhoneNumber')"
              />
            </th>
            <th>
              Last Active
              <img
                class="img7 ml-1"
                src="../../../../assets/img/sort-data.svg"
                style="cursor: pointer"
                (click)="sortOrder('PhoneNumber')"
              />
            </th>
            <th>
              Active Status
              <img
                class="img7 ml-1"
                src="../../../../assets/img/sort-data.svg"
                style="cursor: pointer"
                (click)="sortOrder('Status')"
              />
            </th>
            <th>Action</th>
            
          </tr>

          <tr
            *ngFor="
              let agent of totalAgents
                | filter: filterText
                | sort: orderFeild:orderString
            "
          >
            <!-- <td style="padding: 4px" class="align-middle text-center">
          
            </td> -->
            <td class="align-middle">
              <div class="d-flex align-items-center">
                  <div class="mr-2">
                    <span class="text-uppercase">
                      <img
                        class="img28"
                        style="border-radius: 50%;"
                        src="{{
                          agent['ProfilePhoto'] &&
                          agent['ProfilePhoto'].includes('https')
                            ? agent['ProfilePhoto']
                            : 'assets/img/user-profile.svg'
                        }}"
                      />
                    </span>
                  </div>
                  <div class="d-flex flex-column pt-1">
                    <div style="font-weight: bold; text-transform: capitalize; line-height:150%">
                      {{ agent["FirstName"] + " " + agent["LastName"] }} 
                      <span class="status-role">{{agent["admin"]=='true'?'Admin':'Agent'}}</span>
                    </div>
                    <div>
                      <span> {{ agent["Mailid"] }}</span>
                    </div>
                  </div>
              </div>
            </td>
            <td class="align-middle">
              {{ agent["CreatedDate"] |date:'YYYY-MM-dd' }}
              <!--
              <div class="">
                <span> +{{ agent["PhoneNumber"] }}</span>
              </div>
              -->
            </td>
            <td class="align-middle">
              -
            </td>
            <td class="align-middle">
              <div class="status-completed">Active</div>
              <!--
              <div
                class="{{
                  agent['Status'] ? agent['Status'] : 'status-pending'
                }}  align-middle "
              >
                {{ agent["Status"] ? agent["Status"] : "status-validated" }}
              </div>
              -->
              
            </td>

            <td class="align-middle" style="padding: 0 0 0 5px">
              <div>
                <div
                  ngbTooltip="Calls"
                  placement="top"
                  tooltipClass="my-custom-class"
                  (click)="callAgent(agent['workerFriendlyName'])"
                  class="img-hover float-left"
                >
                  <img class="img13" src="../../../../assets/img/call.svg" />
                </div>

                <div
                  ngbTooltip="Chat"
                  placement="top"
                  tooltipClass="my-custom-class"
                  class="img-hover float-left"
                  (click)="chatRequest(agent['workerFriendlyName'])"
                >
                  <img class="img13" src="../../../../assets/img/chat.svg" />
                </div>
                <div
                  class="img-hover float-left"
                  (click)="
                    openMaps(agent['LatLng'], agent['Address'], agent['id'])
                  "
                  *ngIf="admin"
                >
                  <img
                    class="img13"
                    src="../../../../assets/img/map.svg"
                    style="width: 11px"
                  />
                </div>
              </div>
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>