import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ReferreeService {
  baseApiUrl = environment.baseApiUrl3;
  baseApiUrl2 = environment.baseApiUrl2;
  SMSapiurl = environment.SMSapiurl;


  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  conferenceCall = new BehaviorSubject<any>(null);
  callStatus = new BehaviorSubject<any>(null);
  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
    CallStatus: [""],
  });

  addLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: [""],
  });

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) {}

  getReferralList(data) {
    return this.http.post(`${this.baseApiUrl}ref/getReferrerLeads`, data);
  }

  getReferralLead(data) {
    return this.http.get(`${this.baseApiUrl}ref/getReferrer/` + data);
  }

  getReferralLead2(data) {
    return this.http.get(`${this.baseApiUrl}ref/getReferredStudent/` + data);
  }

  getReferredList(data) {
    return this.http.get(
      `${this.baseApiUrl}ref/getReferralCount/` + data
    );
  }

  getTopBarCount(obj) {
    return this.http.post(`${this.baseApiUrl}ref/getTopBarReferrer`, obj);
  }

  getUniversityList(){
    return this.http.get(`${this.baseApiUrl}ref/universitryhub/listOfUniversities`);
  }

  uploadLeads(data) {
    return this.http.post(`${this.baseApiUrl}ref/createReferrer`, data);
  }

  updateLead(data) {
    return this.http.post(`${this.baseApiUrl}ref/updateReferrer`, data);
  }

  updateCallSid(data) {
    return this.http.post(`${this.baseApiUrl}ref/updateCallSid`, data);
  }

  updateCallStatus(data) {
    return this.http.post(`${this.baseApiUrl}ref/updateCallStatus`, data);
  }

  updateCallComments(data) {
    return this.http.post(`${this.baseApiUrl}ref/updateCallComments`, data);
  }

  assignLeads(body) {
    return this.http.post(`${this.baseApiUrl}ref/AssignCRMReferrer`, body);
  }

  convert2dArraytoObject(formatingData): ReferralData[] {
    let excelData: ReferralData[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};

      if (formatingData[inner][0]) {
        convertData["FirstName"] = formatingData[inner][0];
        convertData["LastName"] = formatingData[inner][1];
        convertData["CountryCode"] = formatingData[inner][2] || '';
        convertData["PhoneNumber"] = formatingData[inner][3];
        convertData["Email"] = formatingData[inner][4];
        convertData["AssignedTo"] = formatingData[inner][5] ||"";
        
        excelData.push(convertData);
      }
    }

    return excelData;
  }

  getSms(id) {
    return this.http.get(`${this.baseApiUrl2}communication/refereeSMS/` + id);
  }

  sendSms(data, projectId) {
    return this.http.post(`${this.SMSapiurl}`+projectId+`/messaging-adapter/sendSMS`,data);
  }

  search(data){
    return this.http.post(`${this.baseApiUrl2}/referrerSearch`, data);
  }
  getCountByCallStatusReferrerLeads(data){
    return this.http.post(`${this.baseApiUrl2}/getCountByCallStatusReferrerLeads`,data)
  }

  addPaid(data){
    return this.http.post(`${this.baseApiUrl}ref/paid/add`, data);
  }
}
