<div class="text-left mt-2" style="padding: 0 2rem;">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="xright-content-right-box-bottom-container">
  <div class="containers">
    <div class="d-flex justify-content-end mb-2">
      <button
        ngbTooltip="Create Template"
        placement="top"
        tooltipClass="my-custom-class"
        class="btn btn-secondary btn-sm float-right"
        (click)="createTemplate()"
      >
        <img alt="" class="img11" src="./assets/img/add-w.svg" />
      </button>
    </div>

    <div class="right-content-right-box-bottom-container">
      <div class="table-scroll data-table">
        <table class="table table-md">
          <thead>
            <tr>
              <td colspan="7" style="padding: 8px">
                <div class="table-search-content">
                  <div class="indived-search" style="position: relative">
                    <input
                      type="text"
                      class="form-control search-all"
                      [(ngModel)]="search"
                      placeholder="Search..."
                    />

                    <!-- <a
                      _ngcontent-khd-c3=""
                      class="search-icon-link"
                      href="javascript:void(0);"
                    >
                      <img
                        _ngcontent-khd-c3=""
                        alt=""
                        class="search-icon-img"
                        src="./assets/img/search (5).svg"
                      />
                    </a> -->
                  </div>

                  <div class="pagenation-right d-flex">
                    <ngb-pagination
                      [(page)]="page"
                      [pageSize]="pageSize"
                      [collectionSize]="templates.length"
                      [maxSize]="5"
                      [boundaryLinks]="true"
                      class="pagenation-right"
                    ></ngb-pagination>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="background: #eaf5fd; border-bottom: none">
              <th>Id</th>
              <th>Templates</th>
              <th>Created By</th>
              <th>Created Date</th>
              <th>Updated Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>

            <tr
              *ngFor="
                let item of templates
                  | filter: search
                  | slice: (page - 1) * pageSize:page * pageSize;
                let id = index
              "
            >
              <td class=" align-middle">
                {{ "Template" + item["id"] }}
              </td>
              <td class="align-middle">{{ item["Name"] | titlecase }}</td>
              <td class="align-middle">{{ item["Creator"] | titlecase }}</td>

              <td class="align-middle">
                {{ item["createdAt"] | date: "medium" }}
              </td>
              <td class="align-middle">
                {{ item["updatedAt"] | date: "medium" }}
              </td>
              <td class="vm">
                <div [ngClass]="{ 'status-open': item['Status'] }">
                  {{ item["Status"] ? "Active" : "In Active" }}
                </div>
              </td>
              <td class="align-middle">
                <div
                  ngbTooltip="View Template"
                  placement="top"
                  tooltipClass="my-custom-class"
                  class="img-hover float-left"
                  (click)="open(content, item['Html'])"
                >
                  <img class="img14" src="./assets/img/view2.svg" />
                </div>
                <button
                  class="btn-link float-left"
                  style="background: none; display: none"
                >
                  <img class="img18" src="./assets/img/sendmail.svg" />
                </button>
                <div
                  ngbTooltip="Edit Template"
                  placement="top"
                  tooltipClass="my-custom-class"
                  class="img-hover float-left"
                  (click)="editModal(item['id'])"
                >
                  <img class="img12" src="./assets/img/edit.svg" />
                </div>
                <div
                  ngbTooltip="Delete"
                  placement="top"
                  tooltipClass="my-custom-class"
                  *ngIf="item['Type'] !== null"
                  class="img-hover float-left"
                  (click)="deleteTemplate(item['id'])"
                >
                  <img class="img12" src="./assets/img/delete.svg" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Template</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [innerHTML]="currentTemplateHtml | safeHtml"></div>
  </div>
</ng-template>
