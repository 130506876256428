import { HttpClientService } from "../httpclient.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Injectable({
  providedIn: "root",
})
export class cuNextService {
  baseApiUrl = environment.baseCuNextUrl;

  editLead = this.formBuilder.group({
    application_status: [''],
    accepted_app_status: [''],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    id_number: ['', Validators.required],
    student_email: ['', [Validators.required, Validators.email]],
    when_like_to_begin_studies: ['', Validators.required],
    what_program: ['', Validators.required],
    program_enddate_listed: ['', Validators.required],
    address_line1: [''],
    address_line2: [''],
    city: [''],
    state: [''],
    country: [''],
    zipcode: [null],
    reject_reason: ['']
  });

  constructor(
    private http: HttpClientService,
    private readonly formBuilder: FormBuilder,
  ) {}

  getTopBarCuNext(data){
    return this.http.post(
      `${this.baseApiUrl}countOfCuNextDetail`,
      data
    );
    
  }

  getCardAccepetedType(data){
    return this.http.post(
      `${this.baseApiUrl}getCardAccepetedType`,
      data
    );
  }

  updateStudentInfo(data) {
    return this.http.post(`${this.baseApiUrl}updateStud`, data);
  }

  getStudentActivity(id) {
    return this.http.get(`${this.baseApiUrl}getStud1/` + id);
  }

  GetSearchDashboardStudent(data){
    return this.http.post(
      `${this.baseApiUrl}getSearchDashboardStudent`,
      data
    );
  }
  assignStudent(body) {
    return this.http.post(
      `${this.baseApiUrl}AssignCRMStudent`,
      body
    );
  }
  

}
