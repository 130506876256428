import { MarketingService } from "./../marketing.service";
import { AuthService } from "./../../../account/auth/auth.service";
import { SharedService } from "./../../../shared/shared.service";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Info } from "./models/Info.model";
import * as xlsx from "xlsx";
import { Subscription } from "rxjs";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";

@Component({
  selector: "app-infomarketing",
  templateUrl: "./infomarketing.component.html",
  styleUrls: ["./infomarketing.component.css"],
})
export class InfomarketingComponent implements OnInit, OnDestroy {
  private projectId: any;
  private admin: boolean;
  private userData: any;
  public callData: Info[] = [];
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public search: string;
  public statusValue: any = "";
  public page: any = 1;
  public pageSize: any = 8;
  public downloadLink: any;
  public errorMessage: string = "";
  public error: boolean = false;
  public fileData: any = [];
  public filePath: any;
  public apiKey: any = null;
  @ViewChild("uploadFile") uploadFile;
  public numberOfRecords: number = 0;
  public project: Subscription;
  constructor(
    private readonly sharedService: SharedService,
    private readonly marketingService: MarketingService,
    private handleError: ErrorHandlerService,
    public projectchangeService: ProjectchangeService,
  ) {}

  ngOnInit() {
    this.getCompanyId();
  }
  ngOnDestroy(): void {
      if (this.project) {
      this.project.unsubscribe();
    }
  }

  getCompanyId() {
    this.marketingService
      .getExcelLink("services/TemplateDocuments/AccessqueSchedulers.xlsx")
      .subscribe((data) => {
        this.downloadLink = data["url"];
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.project = this.sharedService.projectId.subscribe((data) => {
    //   if (data) {
    //     this.projectId = data;
        // this.getCompanydata();
    //   }
    // });
    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;
      this.getCompanydata();
    },500);
    this.sharedService.pageEvent.emit({ pageName: "Student Journey" });
  }

  getCompanydata() {
    if (!this.statusValue) {
      this.marketingService
        .getAutoDialCompany(this.projectId)
        .subscribe((data) => {
          
          if (data) {
            this.apiKey = data["API_KEY"];
            this.marketingService
              .getAutodialCompanyData(this.projectId, data["API_KEY"])
              .subscribe((data) => {
                this.callData = data;
              }, (error) => {
                this.handleError.handleError(error)
              });
          } else {
            this.callData = [];
            this.apiKey = null;
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
    } else {
      this.marketingService
        .getCallDialType({ ProjectId: this.projectId, type: this.statusValue })
        .subscribe((data) => {
          this.callData = data;
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  dropdownSelect() {
    this.getCompanydata();
  }

  openFileUpload() {
    this.uploadFile.nativeElement.click();
  }

  convertFile(e) {
    
    this.filePath = e.target.files[0]["name"].toString();
    
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      
      if (sheet_data[0][0] === "cc" && sheet_data.length !== 0) {
        this.fileData = this.marketingService.convert2dArraytocallData(
          sheet_data,
          this.projectId
        );
        this.marketingService
          .insertCallData(this.fileData, this.apiKey)
          .then((data) => {
            this.numberOfRecords = data;
          }).catch(err=>{
            this.handleError.handleError(err)

          })
        
        this.error = false;
        this.getCompanydata();
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }
  projectChange(data) {
    this.projectId = data;
    this.getCompanydata();
  }
}
