import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { HttpClientService } from './httpclient.service';

@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {
  baseApiUrl = environment.baseApiUrl2
  constructor(private http: HttpClientService,) { 

  }

  addAgentActivity(data){
    return this.http.post(`${this.baseApiUrl}activity/add/agent`,data);
  }
  agentActivityList(data){
    return this.http.post(`${this.baseApiUrl}activity/list/agent`,data);
  }
  agentActivityListTarget(data){
    return this.http.post(`${this.baseApiUrl}activity/list/targetAgent`,data);
  }
}
