import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profilePhoto'
})
export class ProfilePhotoPipe implements PipeTransform {

  transform(value: any, agents:any[],customer): any {
    let profilePhoto='assets/img/user.svg';
    if(agents.find(agent=>value===agent['Mailid'])){
      let profile=agents.find(agent=>value===agent['Mailid']);
      
      return profile['ProfilePhoto']||profilePhoto
    }
    if(value===customer['mail']){
      return customer['profilePhoto']['downloadUrl'];
    }
    return profilePhoto;
  }

}
