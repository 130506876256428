import { AuthService } from "./../../../account/auth/auth.service";
import { SharedService } from "./../../../shared/shared.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import * as xlsx from "xlsx";
import { Subscription } from "rxjs";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { MarketingService } from "../../marketing/marketing.service";
import { RoleService } from "../../role-management/role.service";
import { EventCalendarService } from "../event-calendar.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-auto-call-sms',
  templateUrl: './auto-call-sms.component.html',
  styleUrls: ['./auto-call-sms.component.css']
})
export class AutoCallSMSComponent implements OnInit {
  private ProjectId: any;
  private admin: boolean;
  private userData: any;
  public callData: any[] = [];
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public search: string;
  public statusValue: any = "autosms";
  public page: any = 1;
  public pageSize: any = 8;
  isLoading: Boolean = false
  noRecords: Boolean = false

  @ViewChild("uploadFile") uploadFile;
  public numberOfRecords: number = 0;
  public project: Subscription;


  constructor(
    private readonly sharedService: SharedService,
    private readonly marketingService: MarketingService,
    public projectchangeService: ProjectchangeService,
    private handleError: ErrorHandlerService,
    private companyService: RoleService,
    private EventCalendarService: EventCalendarService,
    private ToastrService: ToastrService,

  ) { }
  ngOnInit() {
    this.sharedService.pageEvent.emit({
      pageName: "Auto Call/SMS",
    });
    this.userData = JSON.parse(localStorage.getItem("userData"))

    setTimeout(() => {
      this.ProjectId = this.projectchangeService._projectId;
      this.getSMSDetails()

    }, 500);

    if (!this.ProjectId) {
      this.companyService.projectAdmin(this.userData.companyId).subscribe((projects) => {
        this.ProjectId = projects[0]["id"]
        this.getSMSDetails()

      });
    }

  }

  // getCompanyId() {
  //   this.userData = JSON.parse(localStorage.getItem("userData"));
  //   // this.project = this.sharedService.projectId.subscribe((data) => {
  //   //   if (data) {
  //   //     this.projectId = data;
  //   //     this.getCompanydata();
  //   //   }
  //   // });
  //   setTimeout(() => {
  //     this.ProjectId = this.projectchangeService._projectId;
  //     this.getSMSDetails();
  //   }, 500);
  //   this.sharedService.pageEvent.emit({ pageName: "Auto SMS Marketing" });
  // }


  getSMSDetails() {
    this.isLoading = true
    this.EventCalendarService.AutoSMSRecrds(this.ProjectId,this.userData.id).subscribe((Data: any) => {
      if (Data.status == true) {
        this.callData = Data.data;
        this.noRecords = false

      }
      else {
        this.noRecords = true
        this.callData=[]

        // this.ToastrService.error(Data.message)
      }
      this.isLoading = false

    })
  }
  getCallDetails() {
    this.isLoading = true
    this.EventCalendarService.AutoCallRecrds(this.ProjectId,this.userData.id).subscribe((Data: any) => {
      if (Data.status == true) {
        this.callData = Data.data;
        this.noRecords = false

      }
      else {
        this.noRecords = true
        this.callData=[]
        // this.ToastrService.error(Data.message)
      }
      this.isLoading = false

    })
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  dropdownSelect(Data: any) {
    if (this.ProjectId) {

      if (this.statusValue != 'autosms') {
        this.getCallDetails();
      }
      else {
        this.getSMSDetails();
      }
    }
  }

  projectChange(data) {
    this.ProjectId = data;
    if (this.ProjectId) {
      if (this.statusValue != 'autosms') {
        this.getCallDetails();
      }
      else {
        this.getSMSDetails();
      }
    }
  }
}
