import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatlistComponent } from './chatlist/chatlist.component';
import { ChatdetailsComponent } from './chatdetails/chatdetails.component';
import { ChatinfoComponent } from './chatinfo/chatinfo.component';
import { ChatviewComponent } from './chatview.component';
import { FormsModule } from '@angular/forms';
// import { MatDialogModule, MatProgressSpinnerModule, MatSelectModule, MatTabsModule, MatListModule, MatIconModule } from '@angular/material';
import { AgentnamePipe } from './agentname.pipe';
import { AgentsdialogComponent } from './agentsdialog/agentsdialog.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ProfilephotoPipe } from './profilephoto.pipe';
import { LoginstatusPipe } from './loginstatus.pipe'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    declarations: [
        ChatviewComponent,
        ChatlistComponent,
        ChatdetailsComponent,
        ChatinfoComponent,
        AgentnamePipe,
        AgentsdialogComponent,
        ProfilephotoPipe,
        LoginstatusPipe,
    ],
    imports: [
        CommonModule,
        ChatRoutingModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTabsModule,
        MatDialogModule,
        NgbModule,
        MatListModule,
        MatIconModule,
        PickerModule
    ],
    exports: [ChatviewComponent, ProfilephotoPipe, AgentnamePipe]
})
export class ChatModule { }
