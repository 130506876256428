import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LayoutService } from "src/app/layout/layout.service";
import { VideoCallingService } from "src/app/videocalling/video-calling.service";
import { MarketingService } from "../marketing.service";

@Component({
  selector: "app-demopage",
  templateUrl: "./demopage.component.html",
  styleUrls: ["./demopage.component.css"],
})
export class DemopageComponent implements OnInit {
  public comments: any = "";
  public callBack = "interested";
  public followUpdate = new Date();
  public isMouSent = false;
  public isUpdatedComments = false;
  public isFollowUp = false;
  public leadPerson: any = {};
  public lead: any = {};
  @Input()
  roomName: string;
  @Input()
  name: string;

  constructor(
    public readonly modal: NgbModal,
    private videoService: VideoCallingService,
    private marketingService: MarketingService,
    private router: Router,
    private layoutService: LayoutService
  ) {}

  scheduleDemo() {}

  async updateComments() {
    if (this.callBack !== "followup") {
      if (this.callBack === "interested") {
      }
      this.interestedLead();
    } else {

      await this.layoutService.scheduleDemo(
        this.lead.ProjectId,
        {
          id: this.lead.id,
          email: this.lead.Email,
          module: this.lead.Module,
          name: this.lead.FirstName,
          phoneNumber: `+${this.lead.CountryCode}${this.lead.PhoneNumber}`,
          status: this.lead.CallStatus,
        },
        this.followUpdate,
        this.comments,
        "demo"
      );
      this.interestedLead();
    }
    this.showMessage();
  }

  showMessage() {
    if (this.callBack === "interested") {
      this.isMouSent = true;
    }
    if (this.callBack === "notinterested") {
      this.isUpdatedComments = true;
    }
    if (this.callBack === "followup") {
      this.isFollowUp = true;
    }
    setTimeout(() => {
      this.isFollowUp = false;
      this.isUpdatedComments = false;
      this.isMouSent = false;
    }, 1000);
  }

  interestedLead() {
    this.videoService
      .updateComments({
        Comments: this.comments,
        Description: this.roomName,
        Status: this.callBack,
        FollowUpdate: this.followUpdate,
      })
      .subscribe((data) => {
        this.updatePhase();
      });
  }

  updatePhase() {
    let status;
    if (status === "interested") {
      status = "demo";
    } else {
      status = `demo`;
    }
    this.marketingService
      .updateLeadStatus({
        id: this.leadPerson.LeadId,
        phase: status,
      })
      .subscribe((data) => {
        this.router.navigate(["/leadsDashboard"]);
        this.modal.dismissAll();
      });
  }

  ngOnInit() {
    this.followUpdate.setDate(this.followUpdate.getDate() + 2);

    this.marketingService.getLeadByRoomId(this.roomName).subscribe((data) => {
      this.leadPerson = data;
      this.marketingService
        .getLead(this.leadPerson["LeadId"])
        .subscribe((lead) => {
          this.lead = lead;
          
        });
    });
  }
}
