import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { HttpClientService } from "../../httpclient.service";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  baseApiUrl2: any = environment.baseApiUrl2;

  filterData = new BehaviorSubject<any>(null);
  projectId = new BehaviorSubject<any>(null);


  constructor(private http: HttpClientService) { }


  getProjectList(id, token) {
    return this.http.post(`${this.baseApiUrl2}reports/projectsList`,{
      userId: id,
      token: token
    })
  }
  getCourses(id, token,projectId?:any) {
    return this.http.get(`${this.baseApiUrl2}campbellsville/getListOfPrograms/${id}/${token}/${projectId}`)
  }

  getAgents(id, token) {
    return this.http.get(`${this.baseApiUrl2}campbellsville/getListOfAgents/${id}/${token}/CRM`)
  }
  getEnrollmentTerm(id,token){
   
    return this.http.post(`${this.baseApiUrl2}reports/enrollmentTermList`,{
      userId: id,
      token: token,
      platform: "CRM"
    })
  }

  getEnrollmentYear(id,token){   
    return this.http.post(`${this.baseApiUrl2}reports/enrollmentYearList`,{
      userId: id,
      token: token,
      platform: "CRM"
    })
  }

  getReports(obj){
    return this.http.post(`${this.baseApiUrl2}reports`,obj)
  }
  getReportsByApplicationType(obj,type){
    return this.http.post(`${this.baseApiUrl2}reports/${type}`,obj)
  }
  getApplication(obj){
    return this.http.post(`${this.baseApiUrl2}reports/applicationslist`,obj)
  }

  gerAgentsCRM(body){
    return this.http.post(`${this.baseApiUrl2}reports/agentListCRM`,body)
  }
  gerAgentsCRM2(body){
    return this.http.post(`${this.baseApiUrl2}campbellsville/getListOfAgentsNew`,body)
  }


}
