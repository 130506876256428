import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formName",
})
export class FormNamePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (value.toLowerCase().includes("ielts")) {
      return "IELTS ";
    }
    if (value.toLowerCase().includes("usa")) {
      return "Study In USA ";
    }
    if (value) {
      return value.slice(0, 30);
    } else {
      return "-";
    }
  }
}
