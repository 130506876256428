<div class="text-left px-3 pt-3">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>

<div class="d-flex p-3">
  <div class="pr-2 py-2 flex-fill bd-highlight">
    <div class="ticket-info-box-wrapper openticket-block2 bred">
      <span class="open-tick-icon"><i class="fa fa-folder-open"></i></span>
      <div class="box-header">
        <p>Open Tickets</p>
      </div>
      <div class="box-content-wrapper">
        <div
          class="center-align circle-shape"
          (click)="getTicketsChannel('my')"
        >
          <p class="box-number-text">
            <span>{{ dashboard.myTickets }}</span>
          </p>
          <span class="box-number-text"></span>
          <p class="box-sub-text1">by you</p>
        </div>
        <div
          class="center-align circle-shape"
          (click)="getTicketsChannel('mygroups')"
        >
          <p class="box-number-text">
            <span>{{ dashboard.myGroups }}</span>
          </p>
          <span class="box-number-text"></span>
          <p class="box-sub-text1">by Groups</p>
        </div>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill bd-highlight">
    <div class="box-content-wrapper-one2">
      <div
        class="center-align circle-shape"
        (click)="getTicketsChannel('mail')"
      >
        <span><img src="../../../../assets/img/mail-icon.png" /></span>
        <p class="box-number-text">
          <span>{{ dashboard.mail }} </span>
        </p>
        <p class="box-sub-text1">Mails</p>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill bd-highlight">
    <div class="box-content-wrapper-one2">
      <div
        class="center-align circle-shape"
        (click)="getTicketsChannel('chat')"
      >
        <span><img src="../../../../assets/img/chants-icon.png" /></span>
        <p class="box-number-text">
          <span>{{ dashboard.chat }}</span>
        </p>
        <p class="box-sub-text1">Chat</p>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill bd-highlight">
    <div class="box-content-wrapper-one2">
      <div
        class="center-align circle-shape"
        (click)="getTicketsChannel('website')"
      >
        <span><img src="../../../../assets/img/wesite-icon.png" /></span>
        <p class="box-number-text">
          <span>{{ dashboard.website }}</span>
        </p>
        <p class="box-sub-text1">Website</p>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill bd-highlight">
    <div class="box-content-wrapper-one2">
      <div
        class="center-align circle-shape"
        (click)="getTicketsChannel('call')"
      >
        <span><img src="../../../../assets/img/calls-logo.png" /></span>
        <p class="box-number-text">
          <span>{{ dashboard.call }}</span>
        </p>
        <p class="box-sub-text1">Calls</p>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill bd-highlight">
    <div class="box-content-wrapper-one2">
      <div class="center-align circle-shape" (click)="getTicketsChannel('sms')">
        <span><img src="../../../../assets/img/sms-logo.png" /></span>
        <p class="box-number-text">
          <span>{{ dashboard.sms }}</span>
        </p>
        <p class="box-sub-text1">SMS</p>
      </div>
    </div>
  </div>
  <div class="p-2 flex-fill bd-highlight">
    <div class="box-content-wrapper-one2">
      <div
        class="center-align circle-shape"
        (click)="getTicketsChannel('accessque')"
      >
        <span><img src="../../../../assets/img/access-logo.png" /></span>
        <p class="box-number-text">
          <span>{{ dashboard.accessque }}</span>
        </p>
        <p class="box-sub-text1">Accessque</p>
      </div>
    </div>
  </div>
</div>

<div class="right-content-wrapper">
  <div class="right-content-right-box">
    
    <div class="headding-refresh">
      <div class="header-12">Latest Tickets</div>
      <div
        class="mt-2 mb-1"
        [ngClass]="{ rotating: rotating }"
        style="cursor: pointer"
        (click)="getTicketsChannel('mygroups')"
      >
        <i
          class="fa fa-refresh"
          aria-hidden="true"
          style="font-size: 21px; display: none"
        ></i>
      </div>
    </div>
    <div class="right-content-right-box-bottom-container mb-3">
      <div class="bottom-content-box-header-container">
        <div class="ticket-requested-list-container">
          <div class="requsted-list-heading-container">
            <div class="table-scroll">
              <div class="table-responsive data-table">
                <table class="table table-md table-hover" style="margin-bottom: 5px">
                  <thead style="position: relative" class="bg-blue">
                    <tr>
                      <td colspan="8" style="padding: 8px">
                        <div class="dashboard-search">
                          <div
                            class="indived-search" >
                            <input
                              type="search"
                              class="form-control"
                              [(ngModel)]="search"
                              placeholder="Search"
                            />
                            <!-- <a
                              _ngcontent-ogk-c3=""
                              class="search-icon-link"
                              href="javascript:void(0);"
                              ><img
                                _ngcontent-ogk-c3=""
                                alt=""
                                class="search-icon-img"
                                src="./assets/img/search (5).svg"
                            /></a> -->
                          </div>
                          <div class="pagenation-right">
                            <ngb-pagination
                              [(page)]="page"
                              [pageSize]="pageSize"
                              [collectionSize]="tickets.length"
                              class="pagenation-right"
                              [maxSize]="5"
                              [boundaryLinks]="true"
                            ></ngb-pagination>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tr style="background: #eaf5fd">
                    <th>
                      Incident Id
                      <span (click)="sortOrder('RequestId')"
                        ><img
                          class="img7 ml-1"
                          src="../../../../assets/img/sort-data.svg"
                      /></span>
                    </th>
                    <th>
                      Description
                      <span (click)="sortOrder('summary')"
                        ><img
                          class="img7 ml-1"
                          src="../../../../assets/img/sort-data.svg"
                      /></span>
                    </th>
                    <th>
                      Assigned To
                      <span (click)="sortOrder('Assignedto')"
                        ><img
                          class="img7 ml-1"
                          src="../../../../assets/img/sort-data.svg"
                      /></span>
                    </th>
                    <th>
                      Assigned Group
                      <span (click)="sortOrder('group')"
                        ><img
                          class="img7 ml-1"
                          src="../../../../assets/img/sort-data.svg"
                      /></span>
                    </th>
                    <th>
                      Status
                      <span (click)="sortOrder('RequestStatus')"
                        ><img
                          class="img7 ml-1"
                          src="../../../../assets/img/sort-data.svg"
                      /></span>
                    </th>
                    <th>
                      Requested By
                      <span (click)="sortOrder('ReportedBy')"
                        ><img
                          class="img7 ml-1"
                          src="../../../../assets/img/sort-data.svg"
                      /></span>
                    </th>
                    <th>
                      Created Date
                      <span (click)="sortOrder('CreatedDate')"
                        ><img
                          class="img7 ml-1"
                          src="../../../../assets/img/sort-data.svg"
                      /></span>
                    </th>
                  </tr>

                  <tbody>
                    <tr
                      *ngFor="
                        let item of tickets
                          | filter: search
                          | slice: (page - 1) * pageSize:page * pageSize
                          | sort: orderFeild:orderString;
                        let id = index
                      "
                    >
                      <td
                        class="px-1 vm"
                        [ngClass]="{
                          'font-weight-bold': item['TicketRead']
                        }"
                      >
                        <a
                          [routerLink]="['/tickets/ticket-view', item['id']]"
                          style="text-decoration: none; color: #163252;display: flex;
                          align-items: baseline;"
                        >
                          <span style="padding-right: 10px"
                            ><img
                              class="ml-2 img12"
                              style="margin-bottom:2px"
                              [src]="
                                'assets/dashboard/' +
                                item['TicketChannel'] +
                                '.svg'
                              "
                          /></span>
                          {{ item["RequestId"] }}
                        </a>
                      </td>
                      <td
                        class="text-capitalize vm"
                        [ngClass]="{ 'font-weight-bold': item['TicketRead'] }"
                      >
                        {{
                          item["summary"].length > 25
                            ? item["summary"].slice(0, 25) + "..."
                            : item["summary"]
                        }}
                      </td>
                      <td class="vm">{{ item["Assignedto"] || "-" }}</td>
                      <td
                        class="text-capitalize vm"
                        [ngClass]="{ 'font-weight-bold': item['TicketRead'] }"
                      >
                        {{ item["group"] }}
                      </td>
                      <td class="vm">
                        <div
                        class="text-capitalize status-{{ item['RequestStatus'] }}"
                        [ngClass]="{ 'font-weight-bold': item['TicketRead'] }"
                      > 
                        {{ item["RequestStatus"] | requeststatus  }}</div>
                      </td>
                      <td
                        class="text-capitalize vm"
                        [ngClass]="{ 'font-weight-bold': item['TicketRead'] }"
                      >
                        {{ item["ReportedBy"] }}
                      </td>
                      <td
                        class="text-capitalize vm"
                        [ngClass]="{ 'font-weight-bold': item['TicketRead'] }"
                      >
                        {{ item["CreatedDate"] | date: "medium" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>