import { Component, OnInit } from '@angular/core';
import { UhLeadsService } from '../../uhleads.service';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.css']
})
export class TableDataComponent implements OnInit {

  constructor(uhleadsService:UhLeadsService) { }

  ngOnInit(): void {
    
  }

}
