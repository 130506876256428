import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FreJunService } from './pages/frejun.service';
import { UhLeadsService } from './pages/uhleads.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  status = new Subject<any>();
  chatStatus = new Subject<any>();
  recruiterLeadAdd = new Subject<any>();
  socket: any;
  socketDetails: any = []
  constructor(
    private freJunService: FreJunService,
    private UuhLeadsService: UhLeadsService,
  ) { }
  connect(userId?: any) {
    this.socket = io(environment.socketUrl2, { transports: ['websocket'], query: { userId: userId } });
    this.socket.on('connect', (data) => {
    })
    this.socket.on('statusUpdated', data => {
      this.status.next('data emitted')
    })
    this.socket.on('chatStatus', data => {
      this.chatStatus.next(data);
    })
    this.socket.on('recruiterleadadd', data => {
      this.recruiterLeadAdd.next(data);
    });


    // FreJUn 

    this.socket.on('socket-user', (data) => {
      this.socketDetails = data
      console.log("socket-user", this.socketDetails)
      console.log("************************************************************************************")

    })

    
    this.socket.on('call-answered', (data: any) => {
      console.log("************************************************************************************")
      this.freJunService.callAnswered.next(data)
      console.log("call-answered", data);
      
    })
   
  }

  sendMessage(){
    this.socket.emit('message','Test message sent')
  }
  disConnect() {
    this.socket.disconnect({test:'test'});
  }
}
