<!--Referral page starts -->
<div class="p-3">
  <div class="pl-2 pr-2 d-flex mb-4">
    <div class="db-sm-header mr-auto" style="line-height: 330%">Activity</div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>
  <carousel [cellsToScroll]="2" style="padding-left: 50px; z-index: unset" class="fixheight">
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('total'); onClick(1)"
          [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.total || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Referrals</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('applicationSubmitted'); onClick(2)"
          [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.applicationSubmitted }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Applications Submitted</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('international'); onClick(3)"
          [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.international }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">International</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('USTransfer'); onClick(4)"
          [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.USTransfer }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">US Transfers</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('assigned'); onClick(5)"
          [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.assigned }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Assigned</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('notAssigned'); onClick(6)"
          [ngClass]="{ 'db-block-active': tab === 'tab6' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.unassigned }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Un Assigned</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('assignedtoMe'); onClick(7)"
          [ngClass]="{ 'db-block-active': tab === 'tab7' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.assignedtoMe }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Assigned to me</div>
          </div>
        </div>
      </div>
    </div>
    <!-- NEW CARDS -->
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('applicationnotsubmitted'); onClick(8)"
          [ngClass]="{ 'db-block-active': tab === 'tab8' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.applicationnotsubmitted || 0}}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Application not Submitted</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('enrolled'); onClick(9)"
          [ngClass]="{ 'db-block-active': tab === 'tab9' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.enrolled || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Enrolled</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('changeuniversity'); onClick(10)"
          [ngClass]="{ 'db-block-active': tab === 'tab10' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCountReferral?.changeuniversity || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Change University Applications</div>
          </div>
        </div>
      </div>
    </div>
    <!-- NEW CARDS -->
    <div class="carousel-cell"></div>
  </carousel>

  <div style="clear: both"></div>
</div>
<div class="pl-4 pr-4 pt-0 pb-4">
  <div class="d-flex align-items-center">
    <div class="header-12">List of Referrals</div>
    <div class="d-flex m-3 legend">
      <div class="mr-1">
        <img src="../../../assets/img/us-transfer.svg" class="img12" />
      </div>
      <div class="db-sm-header mr-3 mt-1">US Transfer</div>

      <div class="mr-1">
        <img src="../../../assets/img/international.svg" class="img12" />
      </div>
      <div class="db-sm-header mt-1">International</div>
    </div>
    <div class="ml-auto">
      <button *ngIf="admin" class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Lead Assign"
        placement="top" tooltipClass="my-custom-class" data-toggle="modal" data-target="#leadassign">
        <img src="../../../../assets/img/assign-w.svg" style="width: 14px" />
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <div class="data-table" style="background: #fff">
      <table class="table table-md mt-1 mb-2 ctable">
        <thead>
          <tr>
            <td colspan="5">
              <div class="indived-search">
                <input type="text" placeholder="Search" #inputString class="form-control search-all"
                  [(ngModel)]="filterString" (input)="onInput($event)" />
              </div>
            </td>
            <td colspan="8" class="vm">
              <div class="pagenation-right d-flex justify-content-end align-items-center">
                <select class="form-select form-select-dark mx-2" style="font-size: 12px; padding: 6px"
                  [(ngModel)]="statusValue" (ngModelChange)="dropdownSelect($event)">
                  <option value="all">All</option>
                  <option value="pending">Pending</option>
                  <option value="called">Called</option>
                  <option value="callback">Call Back</option>
                  <option value="informed">Informed</option>
                  <option value="callfailed">Wrong Number</option>
                  <option value="notanswered">Not Answered</option>
                  <option value="deferredintake">Deferred Intake</option>
                </select>
                <div style="color: #fff; font-size: 12px">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="collectionSize"
                    (pageChange)="onPageChange($event)" [maxSize]="5" [boundaryLinks]="true"
                    class="pagenation-right"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tr style="background: #eaf5fd">
          <th style="width: 30px; white-space: nowrap;padding-left: 10px;" *ngIf="admin">
            <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
              (change)="selectAllforAssignment()" />
          </th>
          <th nowrap>Actions</th>
          <th nowrap>
            Created
            <span><button class="sortButton" (click)="toggleSort('createdAt', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Name
            <span><button class="sortButton" (click)="toggleSort('FirstName', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Email
            <span><button class="sortButton" (click)="toggleSort('Email', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Assigned
            <span><button class="sortButton" (click)="toggleSort('AssignedToName', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Phone
            <span><button class="sortButton" (click)="toggleSort('PhoneNumber', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Referee
            <span><button class="sortButton" (click)="toggleSort('ReferredByName', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Uni
            <span><button class="sortButton" (click)="toggleSort('University', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Status
            <span><button class="sortButton" (click)="toggleSort('ApplicationStatus', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Call Status
            <span><button class="sortButton" (click)="toggleSort('CallStatus', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
        </tr>

        <tr *ngFor="let i of referralList; let id = index">
          <td class="vm" nowrap *ngIf="admin">
            <div style="width: 20px;padding-left: 10px;">
              <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead" id="selectLead"
                (change)="selectAgentforAssignment($event, i)" />
            </div>
          </td>
          <td  class="vm text-center" nowrap>
            <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" placement="top"
                tooltipClass="my-custom-class">
                <mat-sidenav-content style="display: inline !important">
                  <div style="display: inline !important; cursor: pointer" (click)="sideNav.toggle(); sideNavData(i)">
                    <img src="../../../../assets/img/view2.svg" class="img13" />
                  </div>
                </mat-sidenav-content>
              </mat-sidenav-container>
              <div ngbTooltip="Call" *ngIf="getCheckCallVisibleTiming(i)" placement="top" tooltipClass="my-custom-class"
                class="ml-3" style="display: inline; cursor: pointer" data-toggle="modal"
                (click)="startCallListener2(i, id)" data-target="#callingscreen1">
                <img src="../../../../assets/img/call.svg" class="img13" />
              </div>
          </td>
          <td class="vm" nowrap>{{ i.createdAt | date : "dd MMM, yyyy" }}</td>
          <td class="vm" nowrap>
            <div class="d-flex">
              <div *ngIf="i.StudentType == 'US Transfer'" class="mt-2 mr-1">
                <img src="../../../assets/img/us-transfer.svg" class="img12" />
              </div>
              <div *ngIf="i.StudentType == 'International Student'" class="mt-2 mr-1">
                <img src="../../../assets/img/international.svg" class="img12" />
              </div>

              <button class="btn btn-link btn-sm" (click)="openEditLead(editrecord1, i)">
                <div class="tooltip_title">
                  <div class="txt-oflow2">
                    <span>
                      {{ i.FirstName + " " + i.LastName | titlecase }}
                    </span>
                  </div>
                  <span class="tooltiptext">{{ i.FirstName + " " + i.LastName | titlecase }}</span>
                </div>
              </button>
            </div>
          </td>
          <td class="vm" nowrap>
            <div class="tooltip_title">
              <div class="txt-oflow2">
                <span>
                  {{ i.Email }}
                </span>
              </div>
              <span class="tooltiptext">{{ i.Email }}</span>
            </div>
          </td>

          <td class="vm" nowrap>
            <div class="d-flex">
              <div class="pic mr-1 float-left text-center">
                <img *ngIf="i.AssignedToProfilePhoto != '-'" class="img20" src="{{ i.AssignedToProfilePhoto }}" />

                <p [ngStyle]="{ background: i.AssignedtoColor }" class="initials"
                  *ngIf="i.AssignedToProfilePhoto == '-' && i.AssignedTo != '-'">
                  {{ i.AssignedToInitials }}
                </p>
              </div>
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  <span>
                    {{ i.AssignedToName }}
                  </span>
                </div>
                <span class="tooltiptext">{{ i.AssignedToName }}</span>
              </div>
            </div>
          </td>
          <td class="vm" nowrap>{{ i.PhoneNumber }}</td>
          <td class="vm" nowrap>
            <div class="tooltip_title">
              <div class="txt-oflow2">
                <span>
                  {{ i.ReferredByName }}
                </span>
              </div>
              <span class="tooltiptext">{{ i.ReferredByName }}</span>
            </div>

          </td>
          <td class="vm" nowrap>
            <div class="d-flex">
              <div class="pic mr-1 float-left text-center" *ngIf="i.University != '-'">
                <img class="img20" src="../../../../assets/img/university.svg" />
              </div>
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  <span>
                    {{ i.University }}
                  </span>
                </div>
                <span class="tooltiptext">{{ i.University }}</span>
              </div>
            </div>
          </td>
          <td class="vm" nowrap>
            <div *ngIf="i.ApplicationStatus == '-'">Pending</div>
            <div *ngIf="
                i.ApplicationStatus == 'yes' || i.ApplicationStatus == 'Yes'
              ">
              Submitted
            </div>
            <div *ngIf="i.ApplicationStatus == 'no' || i.ApplicationStatus == 'No'">
              Not Submitted
            </div>
            <div *ngIf="i.ApplicationStatus == 'Processing'">
              {{ i.ApplicationStatus }}
            </div>
            <div *ngIf="i.ApplicationStatus == 'Application Submitted'">
              Application Submitted
            </div>
            <div *ngIf="i.ApplicationStatus == 'Enrolled'">
              Enrolled
            </div>
            <div *ngIf="i.ApplicationStatus == 'Application Not Submitted'">
              Application Not Submitted
            </div>
          </td>
          <td class="vm" nowrap>
            <!-- <div class="status-pending">{{i.CallStatus}}</div> -->
            <span class="status-pending" *ngIf="i.CallStatus == 'pending'">Pending</span>
            <span class="status-pending" *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'completed'">Completed</span>
            <span class="status-callback" *ngIf="i.CallStatus == 'callback'">Call Back</span>
            <span class="status-informed" *ngIf="i.CallStatus == 'informed'">Informed</span>
            <span class="status-informed" *ngIf="i.CallStatus == 'called'">Called</span>
            <span class="status-failed" *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
            <span class="status-deferred" *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
            <span class="status-pending" *ngIf="
                i.CallStatus == 'callnotanswered' ||
                i.CallStatus == 'notanswered'
              ">Not Answered</span>
            <span class="status-deferred" *ngIf="i.CallStatus == 'deferred' || i.CallStatus == 'Deferred'">Deferred
              Intake</span>
            <span class="status-completed"
              *ngIf="i.CallStatus == 'applicationsubmitted' || i.CallStatus == 'Application Submitted' || i.ApplicationStatus == 'yes' ">Application
              Submitted</span>
            <span class="status-pending"
              *ngIf="i.CallStatus == 'applicationnotsubmitted' || i.CallStatus == 'Application Not Submitted'">Application
              Not Submitted</span>
            <span class="status-completed"
              *ngIf="i.CallStatus == 'enrolled' || i.CallStatus == 'Enrolled'">Enrolled</span>
            <span class="status-informed"
              *ngIf="i.CallStatus == 'changeuniversity' || i.CallStatus == 'Change University'">Change University</span>
          </td>
        </tr>
        <tr class="h30"></tr>
        <tr *ngIf="leadsLoading == true">
          <td colspan="10">
            <div class="norecords">
              <img src="../../../../assets/img/waiting.gif" />

              <div>
                Loading Data<br />Please wait while we fetch the data for you
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="noLeadsFound == true">
          <td colspan="10">
            <div class="norecords">
              <img src="../../../../assets/img/nodata.svg" />
              <div>
                No records found.<br />Please select a different date range.
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!-- <div class="d-flex m-3">
      <div class="mr-1"><img src="../../../assets/img/us-transfer.svg" class="img12"></div>
      <div class="db-sm-header mr-5 mt-1" style="text-transform:capitalize;">US Transfer</div>

      <div class="mr-1"><img src="../../../assets/img/international.svg" class="img12"></div>
      <div class="db-sm-header mt-1" style="text-transform:capitalize;">International</div>
    </div> -->
  </div>
</div>

<!--All models for REFERRAL-->
<!--Add refarral 1-->
<div class="modal fade modal-md" id="addrefarral1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">First Name</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">Last Name</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Email</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">Phone</label>
              <input type="text" class="form-control" id="" placeholder="" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">University</label>
              <select id="inputState" class="form-control">
                <option selected>Choose...</option>
                <option>...</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">Create Date</label>
              <input type="text" class="form-control" id="" placeholder="" disabled />
            </div>
          </div>
          <div class="d-flex">
            <div class="ml-auto">
              <button type="submit" class="btn btn-primary btn-sm mr-2">
                Add
              </button>
              <button type="submit" class="btn btn-light btn-sm">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Add referral Ends-->

<!--Calling screen starts-->
<div class="modal fade" id="callingscreen1" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg custom-model-w">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Calling Screen</h5>

        <button type="button" id="closeButton2" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto">
        <div class="row">
          <div class="call-module ml-3">
            <div class="ndialer">
              <!--.......code for no- incoming call screen.........  -->
              <div class="c-caller">Current Caller</div>
              <div class="dialer-middle-screen" style="text-align: center">
                <div class="custom-incomingcall-widget">
                  <h4 class="mt-3 text-ellipsis">
                    {{ marketingId.name }}
                  </h4>
                  <div class="mb-2 mt-1 lg-text" style="font-weight: 100"></div>
                  <div class="my-1 lg-text">
                    {{ marketingId.phoneNumber || agentCall }}
                  </div>
                  <div class="custom-user-img my-3 mt-5">
                    <img class="img80" src="./assets/img/user-w.svg" alt="" />
                  </div>
                  <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                  <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                    <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                  </p>
                  <p class="custom-call-timer" style="text-align: center"></p>

                  <div class="custom-incoming-btns my-3 mt-4" (click)="stopCurrentCall()">
                    <button type="button" class="btn-round m-r-10">
                      <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 p-3 ndialer2 ng-star-inserted" *ngIf="marketingId.nextCaller">
              <div class="mt-4" style="float: right">
                <img class="img50" src="./assets/img/user-w.svg" alt="" />
              </div>
              <div class="sm-text" style="
                  text-transform: uppercase;
                  font-weight: 100;
                  background: #f75c2b;
                  width: 110px;
                  padding: 5px;
                  border-radius: 15px;
                  letter-spacing: 1px;
                  text-align: center;
                  color: #fff;
                ">
                NEXT CALLER
              </div>
              <div class="lg-text pt-2 pb-1">{{ nextCallerName }}</div>
              <div class="md-text pb-2">{{ nextCallerCity }}</div>
              <div class="md-text">{{ nextCallerNumber }}</div>
            </div>

            <div class="mt-2 d-flex justify-content-between">
              <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class"
                (click)="startCallListener()">
                <img class="img18" src="./assets/img/redial3.svg" alt="" />
              </div>
              <button class="cbtn" type="button" placement="right" [ngbPopover]="calendarContent">
                <img class="img18" src="./assets/img/conference.svg" alt="" />
              </button>
              <ng-template #calendarContent>
                <form #form="ngForm">
                  <div class="xp-3" style="width: 185px">
                    <div class="mb-1">
                      <input id="phone_number" name="phone_number" type="text" class="form-control"
                        placeholder="Enter Phone Number" ngModel />
                    </div>
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option value="default" disabled selected>Select</option>
                      <option>Manager 1</option>
                      <option>Manager 2</option>
                      <option>Manager 3</option>
                      <option>Manager 4</option>
                    </select>

                    <button class="btn btn-primary btn-block btn-md mt-2">
                      Connect
                    </button>
                  </div>
                </form>
              </ng-template>

              <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top" tooltipClass="my-custom-class">
                <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
              </div>

              <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top" tooltipClass="my-custom-class">
                <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="call-module-update ml-4 mr-2">
            <div class="row">
              <div class="col-md-12">
                <div class="header-12 mt-2 mb-1">Referral details</div>
              </div>
            </div>
            <div class="row custom-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Name</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0].FirstName + " " + callData[0].LastName }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Email</label>
                  <div *ngIf="callData[0]">{{ callData[0].Email }}</div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Mobile</label>
                  <div *ngIf="callData[0]">
                    {{
                    "+" +
                    callData[0].CountryCode +
                    " " +
                    callData[0].PhoneNumber
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row custom-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Created Date</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0].createdAt | date : "medium" }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">University</label>
                  <div class="d-flex">
                    <div class="pic mr-1 float-left text-center">
                      <img class="img20" src="../../../../assets/img/university.svg" />
                    </div>
                    <div *ngIf="callData[0]" class="txt-oflow2 lh180 float-left">
                      {{ callData[0].University || "" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="hr-style mt-1 mb-1" />
            <div class="row">
              <div class="col-md-12">
                <div class="header-12 mt-2 mb-1">Application status</div>
              </div>
            </div>
            <div class="row custom-row2 mb-3">
              <div class="col-md-4 mt-2">
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="option11">
                    <input [(ngModel)]="applicationStatus" type="radio" class="form-check-input" id="option11"
                      name="yes" value="yes" (change)="applicationstatus($event.target.value)" />Yes
                  </label>
                </div>
                <div class="form-check mr-5 d-inline">
                  <label class="form-check-label" for="option2">
                    <input [(ngModel)]="applicationStatus" type="radio" class="form-check-input" id="option2" name="no"
                      value="no" (change)="applicationstatus($event.target.value)" />No
                  </label>
                </div>
              </div>
              <div class="col-md-4" *ngIf="applicationStatus == 'yes'">
                <div class="form-check" style="padding-left: 0">
                  <select id="inputState" class="form-control" [(ngModel)]="selectedUniversity">
                    <option disabled value="">Choose One</option>
                    <option *ngFor="let i of universityList" value="{{ i.id }}">
                      {{ i.university }}
                    </option>
                    <!-- <option value="other">Other</option> -->
                  </select>
                </div>
              </div>
            </div>

            <div class="row custom-row2 mb-3" *ngIf="selectedUniversity == 'other'">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">University Name</label>
                  <div class="d-flex">
                    <input type="text" id="customUniversity" [(ngModel)]="customUniversityName" class="form-control"
                      id="" placeholder="" />
                  </div>
                </div>
              </div>
            </div>

            <hr class="hr-style mt-1 mb-1" />
            <div class="row custom-row2">
              <div class="col-md-12">
                <div class="header-12 mt-2 mb-1">Feedback</div>
              </div>

              <div class="col-md-12">
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="feedback">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="notinterested" (change)="onStatusChange($event.target.value)" />Not Interested
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check3">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="informed" (change)="onStatusChange($event.target.value)" />Informed
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check4">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check5">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="callback" (change)="onStatusChange($event.target.value)" />Call Back
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check6">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check7">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="deferredintake" (change)="onStatusChange2($event.target.value)" />Deferred Intake
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check7">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="applicationsubmitted" (change)="onStatusChange($event.target.value)" />Application
                    Submitted
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check7">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="applicationnotsubmitted" (change)="onStatusChange($event.target.value)" />Application not
                    Submitted
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check7">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="enrolled" (change)="onStatusChange($event.target.value)" />Enrolled
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check7">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback" name="option1"
                      value="changeuniversity" (change)="onStatusChange($event.target.value)" />Change University
                  </label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="showdeferredintake">
              <div class="padding-width">
                <select class="form-control" name="DIyear" id="DIyear" [(ngModel)]="DIyear"
                  (change)="onDIYearChange($event.target.value)">
                  <option [ngValue]="null" [disabled]="true">
                    Enrollment Year
                  </option>
                  <option value="{{ item }}" *ngFor="let item of DIyearArray">
                    {{ item }}
                  </option>
                </select>
                <div *ngIf="requiredYear" class="invalid-feedback_error">
                  <div>Enrollment Year is required</div>
                </div>
              </div>
              <div class="padding-width">
                <select class="form-control" name="DIterms" id="DIterms" [(ngModel)]="DIterms"
                  (change)="onDIItermsChange($event.target.value)">
                  <option [ngValue]="null" [disabled]="true">
                    Enrollment Term
                  </option>
                  <option *ngFor="let item of intakeListArray" [value]="item" [matTooltip]="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <span *ngIf="!newStatus" class="error-msg pt-2 pb-2" style="color: red; font-size: 13px">
              Please select any option above!
            </span>
            <div class="row custom-row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="commentText" class="header-12 pt-3 pb-2">Comments</label>
                  <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <div class="mt-3">
                <button class="btn btn-light btn-sm mr-2" type="button" (click)="resetCallingScreen()">
                  Reset
                </button>
                <button class="btn btn-primary btn-sm" type="submit" (click)="updateComments()">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Calling screen ends-->

<!--REFERRAL overview -->
<!-- <div style="width:500px;height:550px; float:right; border:1px solid #ccc"> -->
<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Referral details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Name</td>
            <td>
              {{ sideViewerData.FirstName + " " + sideViewerData.LastName }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData.Email }}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>
              {{
              sideViewerData.CountryCode + " " + sideViewerData.PhoneNumber
              }}
            </td>
          </tr>
          <tr>
            <td>Created Date</td>
            <td>{{ sideViewerData.createdAt | date : "medium" }}</td>
          </tr>
          <tr>
            <td>University</td>
            <td>{{ sideViewerData.University }}</td>
          </tr>
          <tr>
            <td>Enrollment Term</td>
            <td>{{ sideViewerData.DIterms }}</td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Call Details">
      <div class="alert alert-info mb-0">
        {{ sideViewerData.CallDetails.length }} Calls
      </div>
      <div class="mx-2 my-2" *ngFor="let i of sideViewerData.CallDetails">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i.AgentName }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i.DateTime | date : "medium" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ i.CallStatus | titlecase }}</td>
          </tr>
          <tr>
            <td>Call Recording</td>
            <td>
              <audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL" controls
                controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{ i.Comments }}</td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>

    <mat-tab label="Communication" style="position: relative">
      <div class="mx-2 my-2"></div>
      <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
        <div class="mb-3 pb-1 ct pr" style="
            background: #f5f5fa;
            border-radius: 10px;
            margin: 0 20px 0 10px;
          ">
          <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
            placeholder="Write your message here..."></textarea>
          <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
            (click)="sendSms(sideViewerData, 'textMessage')">
            <img src="../../../assets/img/send-message.svg" class="img12" />
          </button>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="p-3">
        <!--Comments 2 section-->
        <div class="header-md">
          Total Messages
          <span style="font-size: 12px">{{
            "(" + (smsList?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="dyn-height">
            <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
              <div class="d-flex pt-1" style="width: 100%">
                <div style="width: 100%">
                  <div class="admin-comment" style="
                      border: 1px solid #f5e7e7;
                      border-radius: 10px;
                      border-bottom-right-radius: 0;
                    " *ngIf="j.Direction == 'inbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img src="../../../assets/img/student.svg" style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " />
                        </div>
                      </div>
                      <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                        <div class="d-flex">
                          <span class="header-12">Student</span>

                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date : "medium" }}
                          </div>
                        </div>
                        <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="agent-comment" style="
                      border-radius: 10px;
                      border-bottom-left-radius: 0;
                      border: 1px solid #def3f7;
                    " *ngIf="j.Direction == 'outbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " src="../../../assets/img/admin-comments.svg" />
                        </div>
                      </div>

                      <div class="pl-2 pr-2 d-flex flex-column">
                        <div class="d-flex">
                          <span class="header-12">Agent</span>
                          <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date : "medium" }}
                          </div>
                        </div>
                        <div class="sub-text" style="width: 315px; line-height: 125%">
                          {{ j.Text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height: 200%">
            {{ sideViewerData.ReferralActivities.length }} Actions taken
          </div>
          <div class="ml-auto" style="width: 180px">
            <input class="searchActivity" type="text" style="font-size: 13px" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString" (input)="
                onInputActivity(sideViewerData?.ReferralActivities?.length)
              " />
          </div>
        </div>
        <div *ngFor="
            let i of sideViewerData.ReferralActivities
              | slice : 0 : minItems
              | searchFilterTable : activityfilterString
          ">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i.Description }}
              <span class="sm-text ml-2">{{
                i.DateTime | date : "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore hand" *ngIf="minItems < sideViewerData.ReferralActivities.length"
          (click)="showmore(sideViewerData.ReferralActivities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>

<!-- </div> -->

<div style="clear: both"></div>

<!-- Lead Assign-->
<div class="modal fade" id="leadassign" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Assign to Agent</h5>
        <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="height: none">
        <div class="px-2">
          <div class="py-2 header-lg">
            <b>{{ leadIds.length }}</b> Students are selected to assign.
          </div>
          <div class="d-flex mt-2 mb-3">
            <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
              <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                (change)="agentSelected($event.target.value)">
                <option value="default" disabled selected>Select Agent</option>
                <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                  {{ agent.FirstName + " " + agent.LastName }}
                </option>
              </select>
            </div>
            <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Lead Assign ends-->

<!-- Edit Lead -->
<ng-template #editrecord1 let-modal>
  <div class="modal-header" style="width: 600px">
    <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 900px; width: 600px; background: #fff">
    <form class="pt-2" [formGroup]="ReferralService.editLead" (ngSubmit)="updateLead()">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>First Name</label>
          <input class="form-control" name="FirstName" formControlName="FirstName" [(ngModel)]="leadtoEdit.FirstName"
            [ngClass]="{
              'is-invalid': createLeads.FirstName.touched && createLeads.FirstName.invalid,
              'is-valid':
                createLeads.FirstName.valid &&
                createLeads.FirstName.touched
            }" />
          <div *ngIf="createLeads.FirstName.touched && createLeads.FirstName.errors" class="invalid-feedback">
            <div *ngIf="createLeads.FirstName.errors.required">First Name is required</div>
            <div *ngIf="createLeads.FirstName.errors.pattern">Please use valid Name</div>
            <div *ngIf="createLeads.FirstName.errors.minlength">Minimum length should be 2 characters .
            </div>
            <div *ngIf="createLeads.FirstName.errors.maxlength">Maximum length should be 50 characters .</div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Last Name</label>
          <input class="form-control" name="LastName" formControlName="LastName" [(ngModel)]="leadtoEdit.LastName"
            [ngClass]="{
            'is-invalid': createLeads.LastName.touched && createLeads.LastName.invalid,
            'is-valid':
              createLeads.LastName.valid &&
              createLeads.LastName.touched
          }" />
          <div *ngIf="createLeads.LastName.touched && createLeads.LastName.errors" class="invalid-feedback">
            <div *ngIf="createLeads.LastName.errors.required">Last Name is required</div>
            <div *ngIf="createLeads.LastName.errors.pattern">Please use valid Name</div>
            <div *ngIf="createLeads.LastName.errors.minlength">Minimum length should be 2 characters .
            </div>
            <div *ngIf="createLeads.LastName.errors.maxlength">Maximum length should be 50 characters .</div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Email</label>
          <input type="text" name="Email" class="form-control" formControlName="Email" [(ngModel)]="leadtoEdit.Email"
            [ngClass]="{
            'is-invalid': createLeads.Email.touched && createLeads.Email.invalid,
            'is-valid':
              createLeads.Email.valid &&
              createLeads.Email.touched
          }" />
          <div *ngIf="createLeads.Email.touched && createLeads.Email.errors" class="invalid-feedback">
            <div *ngIf="createLeads.Email.errors.required">Email is required</div>
            <div *ngIf="createLeads.Email.errors.pattern">Please use valid Name</div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Phone Number</label>
          <div class="form-row">
            <div class="col-4">
              <input class="form-control" name="CountryCode" formControlName="CountryCode"
                [(ngModel)]="leadtoEdit.CountryCode" [ngClass]="{
                  'is-invalid': createLeads.CountryCode.touched && createLeads.CountryCode.invalid,
                  'is-valid':
                    createLeads.CountryCode.valid &&
                    createLeads.CountryCode.touched
                }" />
              <div *ngIf="createLeads.CountryCode.touched && createLeads.CountryCode.errors" class="invalid-feedback">
                <div *ngIf="createLeads.CountryCode.errors.required">CountryCode is required</div>
              </div>
            </div>
            <div class="col-8">
              <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                [(ngModel)]="leadtoEdit.PhoneNumber" [ngClass]="{
                'is-invalid': createLeads.PhoneNumber.touched && createLeads.PhoneNumber.invalid,
                'is-valid':
                  createLeads.PhoneNumber.valid &&
                  createLeads.PhoneNumber.touched
              }" />
              <div *ngIf="createLeads.PhoneNumber.touched && createLeads.PhoneNumber.errors" class="invalid-feedback">
                <div *ngIf="createLeads.PhoneNumber.errors.required">PhoneNumber is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-8">
          <label for="">University </label>
          <select id="inputState" class="form-control" name="University" formControlName="University"
            [(ngModel)]="leadtoEdit.University" (change)="onSelectedUniversity($event.target.value)" [ngClass]="{
              'is-invalid': createLeads.University.touched && createLeads.University.invalid,
              'is-valid':
                createLeads.University.valid &&
                createLeads.University.touched
            }">
            <div *ngIf="createLeads.University.touched && createLeads.University.errors" class="invalid-feedback">
              <div *ngIf="createLeads.University.errors.required">University is required</div>
            </div>
            <option disabled value="">Choose One</option>
            <option *ngFor="let i of universityList" value="{{ i.id }}">
              {{ i.university }}
            </option>
            <!-- <option value="other">Other</option> -->
          </select>
        </div>
        <div class="form-group col-md-4" *ngIf="leadtoEdit.University == 'other'">
          <label for="">University Name</label>
          <input class="form-control" [(ngModel)]="customUniversityName2" type="text" formControlName="UniversityName"
            placeholder="" />
        </div>
      </div>

      <div class="form-row">
        <!-- Radio button  -->

        <div class="form-group col-md-12">
          <label for="">Feedback</label>
          <div class="mt-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" value="pending"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio1">Pending</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="notinterested"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Not Interested</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="informed"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Informed</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callfailed"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Wrong Number</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callback"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Call Back</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" value="notanswered"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio1">Not Answered</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="deferredintake"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Deferred Intake</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2"
                value="applicationsubmitted" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Application Submitted</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2"
                value="applicationnotsubmitted" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Application Not Submitted</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="enrolled"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Enrolled</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="changeuniversity"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                (change)="onStatusChange($event.target.value)" />
              <label class="form-check-label" for="inlineRadio2">Change University</label>
            </div>
          </div>
        </div>
        <div class="row w-100" *ngIf="showdeferredintake">
          <div class="padding-width">
            <label for="">Enrollment Year</label>
            <select class="form-control" name="DIyear" id="DIyear" [(ngModel)]="DIyear"
              (change)="onDIYearChange($event.target.value)">
              <option value="{{ item }}" *ngFor="let item of DIyearArray">
                {{ item }}
              </option>
            </select>
            <div *ngIf="requiredYear" class="invalid-feedback_error">
              <div>Enrollment Year is required</div>
            </div>
          </div>
          <div class="padding-width">
            <label for="">Enrollment Term</label>
            <select class="form-control" name="DIterms" id="DIterms" [(ngModel)]="DIterms"
              (change)="onDIItermsChange($event.target.value)">
              <option *ngFor="let item of intakeListArray" [value]="item" [matTooltip]="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="mt-3">
          <button class="btn btn-primary btn-sm" type="submit" [disabled]="!ReferralService.editLead.valid"
            *ngIf="!submitting">Update</button>
          <button class="btn btn-primary btn-sm" type="submit" disabled *ngIf="submitting">
            <div class="loading">Updating</div>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>