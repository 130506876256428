import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ReferralService {
  baseApiUrl = environment.baseApiUrl3;
  baseApiUrl2 = environment.baseApiUrl2;
  SMSapiurl = environment.SMSapiurl;


  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  viewReceivedLead = new BehaviorSubject<any>(null);

  conferenceCall = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  callStatus = new BehaviorSubject<any>(null);
  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required]],
    LastName: ["", [Validators.required]],
    CountryCode: ["", [Validators.required]],
    PhoneNumber: ["", [Validators.required]],
    Email: ["", Validators.required],
    University: ["", [Validators.required]],
    CallStatus: [""],
    UniversityName: [""],
  });

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder
  ) {


  }

  getReferralList(data) {
    return this.http.post(`${this.baseApiUrl}ref/getReferredLeads`, data);
  }

  getReferralLead(data) {
    return this.http.get(`${this.baseApiUrl}ref/getReferredStudent/` + data);
  }

  getUniversityList() {
    return this.http.get(
      `${this.baseApiUrl}ref/universitryhub/listOfUniversities`
    );
  }

  getTopBarCount(obj) {
    return this.http.post(`${this.baseApiUrl}ref/getTopBarReferral`, obj);
  }

  updateLead(data) {
    return this.http.post(`${this.baseApiUrl}ref/updateReferredStudent`, data);
  }

  // uploadLeads(data) {
  //   return this.http.post(`${this.baseApiUrl}ref/createReferrer`, data);
  // }

  createCustomUniversity(data){
    return this.http.post(`${this.baseApiUrl}ref/Universities/createCustom`,data)
  }

  updateCallStatus(data) {
    return this.http.post(
      `${this.baseApiUrl}ref/updateCallStatusReferred`,
      data
    );
  }

  updateCallSid(data) {
    return this.http.post(`${this.baseApiUrl}ref/updateCallSidReferred`, data);
  }

  updateCallComments(data) {
    return this.http.post(
      `${this.baseApiUrl}ref/updateCallCommentsReferred`,
      data
    );
  }

  assignLeads(body) {
    return this.http.post(`${this.baseApiUrl}ref/AssignCRMReferred`, body);
  }

  convert2dArraytoObject(formatingData): ReferralData[] {
    let excelData: ReferralData[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};

      if (formatingData[inner][0]) {
        convertData["GUID"] = formatingData[inner][0];
        convertData["firstName"] = formatingData[inner][1];
        convertData["lastName"] = formatingData[inner][2];
        convertData["countryCode"] = formatingData[inner][3] || '';
        convertData["phoneNumber"] = formatingData[inner][4];
        convertData["email"] = formatingData[inner][5];
        convertData["AssignedTo"] = formatingData[inner][6];
        // convertData["module"] = formatingData[inner][7];
        // convertData["channel"] = formatingData[inner][8];
        convertData["Program"] = formatingData[inner][7];
        // convertData["appStatus"] = formatingData[inner][10];

        // convertData["Miscellaneous"] = {
        //   form_id: "",
        //   form_name: formatingData[inner][8],
        //   "Choose Batch": "",
        //   "Training Mode": "",
        // };
        convertData["ApplicationType"] = formatingData[inner][8];
        convertData["CreatedDate"] = formatingData[inner][9];
        convertData["Comments"] = formatingData[inner][10];
        excelData.push(convertData);
      }
    }

    return excelData;
  }

  getSms(id) {
    return this.http.get(`${this.baseApiUrl2}communication/referralSMS/` + id);
  }

  sendSms(data, projectId) {
    return this.http.post(`${this.SMSapiurl}`+projectId+`/messaging-adapter/sendSMS`,data);
  }

  search(data){
    return this.http.post(`${this.baseApiUrl2}referredSearch`, data);
  }
  getCountByCallStatusReferredLeads(data){
    return this.http.post(`${this.baseApiUrl2}/getCountByCallStatusReferredLeads`,data)
  }
}
