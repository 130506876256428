<!-- <p>company-management works!</p> -->
<div class="containers">
  <div class="d-flex justify-content-end mb-2">
    <button
      ngbTooltip="Create Company"
      placement="top"
      tooltipClass="my-custom-class"
      class="btn btn-secondary btn-sm float-right"
      (click)="open(content)"
      *ngIf="userData['companyId'] === 0"
    >
      <img alt="" class="img11" src="./assets/img/add-w.svg" />
    </button>
  </div>
  <div class="content-container">
    <div class="table-scroll data-table" style="background:#fff">
      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="7" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input
                    type="text"
                    class="form-control search-all"
                    [(ngModel)]="search"
                    placeholder="Search..."
                  />
                </div>
                <div class="pagenation-right">
                  <ngb-pagination
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [collectionSize]="totalCompanies.length"
                    class="pagenation-right"
                    [maxSize]="5"
                    [boundaryLinks]="true"
                  ></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>

          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th style="display: none">Company id</th> -->
            <th>
              <span>
                Company Name

                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('Name')"
                />
              </span>
            </th>
            <!-- <th>
              <span>
                Twilio Number
                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('TwilioNumber')"
                />
              </span>
            </th> -->
            <th>
              <span>
                Support Mail
                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('SupportMail')"
                />
              </span>
            </th>
            <th>
              <span>
                Status
                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('ActiveStatus')"
                />
              </span>
            </th>
            <th>
              <span>
                Created Date
                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('createdAt')"
                />
              </span>
            </th>
            <th>
              <span>
                Updated Date
                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('updatedAt')"
                />
              </span>
            </th>
            <th>Projects</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of totalCompanies
                | filter: search
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString;
              let id = index
            "
          >
            <!-- <td
              class="id-col-mod"
              style="cursor: pointer; display: none; vertical-align: middle"
              (click)="onCompanySelect(item['id'], content)"
            >
              {{ "company" + item["id"] }}
            </td> -->
            <!-- class="id-col-mod" -->
            <td
              class="vm"
              style="
                cursor: pointer;
                text-decoration: none;
                color: #163252;
                text-transform: capitalize;
              "
              (click)="onCompanySelect(item['id'], content)"
            >
              {{ item["Name"] }}
            </td>

            <!-- <td style="vertical-align: middle">{{ item["TwilioNumber"] }}</td> -->
            <!-- <td>
							{{ item['ActiveStatus'] ? 'active' : 'inactive' }}
						</td> -->
            <td style="vertical-align: middle">{{ item["SupportMail"] }}</td>
            <td style="vertical-align: middle">
              <div
                [ngClass]="{
                  'status-open': item['ActiveStatus'],
                  'status-hold': !item['ActiveStatus']
                }"
              >
                {{ item["ActiveStatus"] ? "Active" : "In Active" }}
              </div>
            </td>
            <td style="vertical-align: middle">
              {{ item["createdAt"] | date: "medium" }}
            </td>
            <td style="vertical-align: middle">
              {{ item["updatedAt"] | date: "medium" }}
            </td>
            <td style="vertical-align: middle">
              <a
                [routerLink]="['/projects', item['id'], item['Name']]"
                class="btn btn-primary btn-sm"
                >Manage Projects</a
              >
            </td>
            <!-- <td>{{ item['updatedAt'] | date: 'short' }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <ngb-pagination class="pagination-right"></ngb-pagination> -->
    <!-- [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="roles.length"
        class="pagenation-right" -->
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ isUpdate ? "Update" : "Create" }} Company
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      errorMessage
    }}</ngb-alert>
    <form (ngSubmit)="submit(f)" #f="ngForm">
      <div class="row">
        <label for="name" class="form-label mt-2">Company Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Please enter name of role .."
          name="name"
          [(ngModel)]="companyInfo.name"
          required
          #userName="ngModel"
          (ngModelChange)="forwardMail($event)"
          max="10"
          [ngClass]="{
            'is-invalid': !userName.valid && userName.touched,
            'is-valid': userName.valid && userName.touched
          }"
        />
        <!-- <div
							class="valid-feedback"
							*ngIf="userName.valid && userName.touched"
						>
							looks good!
						</div> -->
        <div
          class="invalid-feedback"
          *ngIf="!userName.valid && userName.touched"
        >
          Please Enter Valid Name
        </div>
      </div>

      <div class="row">
        <label for="description" class="form-label">Support Mail</label>
        <input
          type="mail"
          class="form-control"
          placeholder="Please Enter Support Mail"
          id="description"
          name="supportMail"
          [(ngModel)]="companyInfo.supportMail"
          required
          #desc="ngModel"
          email
          [ngClass]="{
            'is-invalid': !desc.valid && desc.touched,
            'is-valid': desc.valid && desc.touched
          }"
        />
        <!-- <div class="valid-feedback" *ngIf="desc.valid && desc.touched">
							look good!
						</div> -->
        <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
          Please Enter Valid Support Mail
        </div>
      </div>
      <!-- <div class="row" *ngIf="isUpdate">
        <div class="p-0" [ngClass]="{ col: !isUpdate, 'col-8': isUpdate }">
          <label for="twilioNumber" class="form-label"
            >PhoneNumber(Twilio Number)</label
          >
          <div class="input-group">
            <div class="input-group-prepend" *ngIf="!isUpdate">
              <button
                type="button"
                class="btn btn-default dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                +<span class="country-code">{{ selectedCountryCode }}</span>
              </button>

              <ul class="dropdown-menu" style="height: 10rem; overflow: auto">
                <li
                  *ngFor="let country of countries"
                  class="my-1"
                  style="cursor: pointer"
                >
                  <span href="#" (click)="selectCountry(country)">
                    <div class="flag flag-{{ country.cc }} mx-1"></div>
                    <span>{{ country.name }} (+{{ country.code }})</span>
                  </span>
                </li>
              </ul>
            </div>
            <input
              type="tel"
              class="form-control"
              name="twilioNumber"
              ngModel
              [(ngModel)]="companyInfo.twilioNumber"
              required
              pattern="[- +()0-9]+"
              #twilioPhoneNumber="ngModel"
              placeholder="please enter twilio phone number"
              [ngClass]="{
                'is-invalid':
                  !twilioPhoneNumber.valid && twilioPhoneNumber.touched,
                'is-valid': twilioPhoneNumber.valid && twilioPhoneNumber.touched
              }"
            />
            <div
              class="invalid-feedback"
              *ngIf="!twilioPhoneNumber.valid && twilioPhoneNumber.touched"
            >
              please enter a valid phoneNumber
            </div>
          </div>
        </div>
        <div class="col-2 d-flex align-items-center" *ngIf="isUpdate">
          <mat-checkbox ngModel name="updateIvr" style="margin-top: 1.5rem"
            >Update IVR</mat-checkbox
          >
        </div>
      </div> -->

      <div class="row" *ngIf="isUpdate">
        <div class="col p-0">
          <label for="description" class="form-label">Active Status</label>
          <select
            required
            class="form-control"
            name="ActiveStatus"
            [(ngModel)]="companyInfo['ActiveStatus']"
            #activeStatus="ngModel"
          >
            <option value="true">Active</option>
            <option value="false">In Active</option>
          </select>
          <div
            class="invalid-feedback"
            *ngIf="!activeStatus.valid && activeStatus.touched"
          >
            please select value
          </div>
        </div>
      </div>

      <div class="row">
        <label for="description" class="form-label">Forward Mail:</label>
        <input
          type="mail"
          class="form-control"
          id="description"
          name="forwardMail"
          [(ngModel)]="companyInfo.forwardMail"
          #forward="ngModel"
          [disabled]="true"
          email
          [ngClass]="{
            'is-invalid': !forward.valid && forward.touched,
            'is-valid': forward.valid && forward.touched
          }"
        />
        <!-- <div class="valid-feedback" *ngIf="forward.valid && desc.touched">
							look good!
						</div> -->
        <div class="invalid-feedback" *ngIf="!forward.valid && forward.touched">
          please enter valid description
        </div>
      </div>
      <!-- <div class="row" *ngIf="isUpdate">
        <label for="fromEmail">Incoming Mail Group:</label>
        <ng-multiselect-dropdown
          [(ngModel)]="group"
          [data]="groups"
          [settings]="companySettings"
          style="width: 100%; padding: 0"
          name="incomingMailGroup"
        >
        </ng-multiselect-dropdown>
      </div> -->

      <div class="modal-footer">
        <button type="submit" class="btn btn-sm btn-primary" [disabled]="!f.valid">
          {{ isUpdate ? "Update" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</ng-template>