import { AgentsdialogComponent } from "./agentsdialog/agentsdialog.component";
import { ActivatedRoute } from "@angular/router";
import { Client, Conversation } from "@twilio/conversations";
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { LayoutService } from "src/app/layout/layout.service";
import { TicketsService } from "../tickets/tickets.service";
import { SharedService } from "src/app/shared/shared.service";
import { CreateAgent } from "../create-agent/create-agent.service";
// import { MatDialog } from "@angular/material";
import { SocketService } from "src/app/socket.service";
import { RoleService } from "../role-management/role.service";
import { MatDialog } from "@angular/material/dialog";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";

declare const Twilio: any;
@Component({
  selector: "app-chatview",
  templateUrl: "./chatview.component.html",
  styleUrls: ["./chatview.component.css"],
})
export class ChatviewComponent implements OnInit, OnDestroy {
  client;
  channel;
  messages = [];
  session = {
    token: null,
    identity: null,
    isInitialized: false,
    isLoading: false,
    expired: false,
  };
  customerInformation: any = {};
  chatMessage: string = "";
  task: any[] = [];
  index: number = 0;
  isloading: boolean = false;
  @Input() workerJS: any;
  @Input() worker: any;
  isAvailable: boolean = false;
  userData: any;
  subscriprion: any;
  prevTask: any;
  @Input() taskQueues;
  @Input() isChat;
  transferQueue: string;
  selectedIndex = 0;
  agents: any[] = [];
  prevChannelSid: any;
  currentChannelSid: any;
  unReadMessages: number = 0;
  customerTickets: any[] = [];
  projectId;
  companyAgents: any[] = [];
  companyAgent: any;
  totalAgents: any[] = [];
  previousChannels: any[] = [];
  isIconShow: boolean = false;
  projectChange: boolean = true;
  projects: any = []
  @ViewChild("chatContainer") chatContainer!: ElementRef;

  scrollTop() {
    if (this.messages && this.messages.length > 0) {
      setTimeout(() => {
        this.chatContainer.nativeElement.scrollTop =
          this.chatContainer.nativeElement.scrollHeight;
      }, 20);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private ticketsService: TicketsService,
    private sharedService: SharedService,
    private agentService: CreateAgent,
    private dialog: MatDialog,
    private socketService: SocketService,
    private roleService: RoleService,
    public projectchangeService: ProjectchangeService,

  ) {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.getProjects()
  }

  ngOnInit() {
    this.socketService.connect();

    // this.projectId = this.projectchangeService._projectId;

    // if(this.projectId){
    // this.fetchAgentChannels();
    // this.getAgents();
    //     this.roleService.fetchRoles(this.projectId).subscribe((data) => {
    //       this.taskQueues = this.projectId.map((role) => ({
    //         friendlyName: role["TwilioSkillName"],
    //         sid: role["RoleSid"],
    //       }));
    //     });
    // }

    // this.sharedService.projectId.subscribe((data) => {

    //   if (data) {
    //     this.projectId = data;
    //     if (this.projectChange) {
    //       this.fetchAgentChannels();
    //     }
    //     this.projectChange = false;
    //     this.getAgents();
    //     this.roleService.fetchRoles(data).subscribe((data) => {
    //       this.taskQueues = data.map((role) => ({
    //         friendlyName: role["TwilioSkillName"],
    //         sid: role["RoleSid"],
    //       }));
    //     });
    //   }
    // });
    this.getAgentNames();

    this.socketService.status.subscribe((data) => {
      this.getAgentNames();
    });

    // if (this.userData['companyId'] !== 0) {
    //   this.companyId = this.userData['companyId'];
    //   this.getAgents();
    //   this.agentService.GetAllRoles(this.companyId).subscribe(data => {
    //     this.taskQueues = data.map(role => ({ friendlyName: role['TwilioSkillName'], sid: role['RoleSid'] }));
    //   })
    // }


    this.isAvailable = true;
    this.fetchTasks();

    setTimeout(() => {

      this.layoutService.agentChats.subscribe((data) => {
        this.agents = data;
      });
    }, 3000);

    this.layoutService.openChat.subscribe((data) => {
      this.selectedIndex = 1;
    });
  }

  ngAfterViewChecked() {
    // this.chatContainer.nativeElement.scrollTop =
    //   this.chatContainer.nativeElement.scrollHeight;
  }

  ngOnDestroy() {
    this.layoutService.tasks.next(this.task);
    this.subscriprion.unsubscribe();
    this.layoutService.newTask.next("");
    this.layoutService.agentChats.next(this.agents);
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe((data) => {
      this.totalAgents = data.map((data) => ({
        name: data["FirstName"] + " " + data["LastName"],
        value: data["workerFriendlyName"],
        profilePhoto: data["ProfilePhoto"],
        Status: data["Status"],
      }));
      this.sharedService.totalAgents.next(this.totalAgents);
    });
  }

  async agentToagentChat() {

    //await Twilio.Chat.Client.create(this.worker.tokens.access).then(

    var client = await new Client(this.worker.tokens.access);
    //(client) => {
    this.client = client;
    //this.client.on("channelInvited", (channel) => {
    this.client.on("conversationJoined", (channel) => {
      // this.layoutService.openChat.next(true);
      this.selectedIndex = 1;

      this.prevChannelSid = channel.sid;
      if (this.prevChannelSid !== this.currentChannelSid) {

        if (
          !this.agents.find(
            (data) => data["channel"]["sid"] === channel["sid"]
          ) &&
          !this.previousChannels.find(
            (agentCh) => agentCh === channel["sid"]
          )
        ) {
          this.agents.push({
            channel: channel,
            unReadMessages: 0,
            messages: [],
            channelSid: "",
            name: "",
            lastMessage: { body: "", timestamp: "" },
            members: [],
          });
          this.setupChannel(channel, this.agents.length - 1, "agent");
          this.currentChannelSid = channel.sid;
          // data = [];
        }
      } else {

        if (channel.attributes["channelType"] === "outboundSMS") {
          this.agents.push({
            channel: channel,
            unReadMessages: 0,
            messages: [],
            channelSid: "",
            name: "",
            lastMessage: { body: "", timestamp: "" },
            members: [channel.attributes["to"]],
          });
          this.setupChannel(channel, this.agents.length - 1, "agent");
          this.currentChannelSid = channel.sid;
        }
      }
    });
    //this.client.on("channelRemoved", (channel) => {
    this.client.on("conversationLeft", (channel) => {
      this.agents = this.agents.filter(
        (data) => data["channelSid"] !== channel["sid"]
      );
      this.messages = [];
      if (this.agents.length !== 0) {
        if (this.index === 0) {
          this.index = this.index + 1;
        } else {
          this.index = this.index - 1;
        }
        this.selectedChat(this.index, "agent");
      }
    });
    //});
  }

  fetchAgentChannels() {

    this.layoutService
      .agentPreviousChannels(
        { identity: this.userData["workerFriendlyName"] },
        this.projectId
      )
      .subscribe((data) => {
        this.previousChannels = data["channels"] ? data["channels"] : [];

        setTimeout(() => {
          this.agentToagentChat();
        }, 5000);

        let index = 0;
        if (data["channels"]) {
          for (let agentCh of data["channels"]) {

            if (
              !this.agents.find((value) => value["channel"]["sid"] === agentCh)
            ) {
              this.agents.push({
                channel: "",
                unReadMessages: 0,
                messages: [],
                channelSid: "",
                name: "",
                lastMessage: { body: "", timestamp: "" },
                members: [],
              });
              this.setupClient(agentCh, index, "agent");
            }

            index = index + 1;
          }
          setTimeout(() => {
            this.previousChannels = [];
          }, 3000);
        }
      });
  }

  selectedChat(index, client) {

    if (this.isChat) {
      this.layoutService.unReadCount.next(0);
    }

    if (client === "client") {
      if (this.task.length !== 0) {
        this.index = index;
        this.task[index]["unReadMessages"] = 0;
        this.intitalizeChat(this.task[index]);
      }
    } else {
      this.intitalizeChat(this.agents[index]);
      this.agents[index]["unReadMessages"] = 0;
      this.index = index;
    }
  }

  intitalizeChat(data) {


    if (data) {
      this.client = null;

      this.channel = data["channel"];

      this.messages = data["messages"];
      this.session = {
        token: null,
        identity: null,
        isInitialized: false,
        isLoading: false,
        expired: false,
      };
      if (this.selectedIndex == 0) {
        if (data) {
          this.layoutService
            .getCustomerChannelSid(data["attributes"]["channelSid"])
            .subscribe((data) => {
              this.customerInformation["ticketId"] = data["ticketNumber"];
              this.customerInformation["desc"] = data["desc"];
              this.customerInformation["mail"] = data["mail"];
              this.customerInformation["phoneNumber"] = data["phoneNumber"];
              this.customerInformation["name"] = data["reportedby"];
              this.customerInformation["ticketId"] = data["TicketId"];
              if (this.customerInformation["mail"]) {
                this.customerSearch(this.customerInformation["mail"]);
              } else {
                this.searchPhoneNumber(this.customerInformation["phoneNumber"]);
              }
            });
        }
      }
      this.session.token = this.worker.tokens.access;
      this.session.identity = this.worker.worker.friendlyName;
      this.scrollTop();
    }
  }

  fetchTasks() {


    this.layoutService.tasks.subscribe((reservations: any[]) => {
      if (reservations.length > 0) {
        this.task = reservations;
        let index = 0;
        for (let data of reservations) {
          if (!data["channel"]) {
            this.setupClient(data["attributes"]["channelSid"], index, "client");
          } else {
            this.getMessages(data["channel"], index, "client");
          }
          index++;
        }
        if (this.task[0]) {
          this.selectedChat(0, "client");
        }
      } else {
        this.task = [];
      }
    });
    this.subscriprion = this.layoutService.newTask.subscribe((data) => {
      if (data !== "") {
        if (
          !this.task.find(
            (prevdata) =>
              data["attributes"]["channelSid"] ===
              prevdata["attributes"]["channelSid"]
          )
        ) {
          this.task.push(data);
          this.selectedIndex = 0;
          this.setupClient(
            data["attributes"]["channelSid"],
            this.task.length - 1,
            "client"
          );
          setTimeout(() => {
            this.selectedChat(this.task.length - 1, "client");
          }, 10);
        }
      }
    });
    this.isAvailable = false;
  }

  async setupClient(channelSid, index, client) {
    this.isloading = true;
    const accessManager = new Twilio.AccessManager(this.worker.tokens.access);
    accessManager.on("tokenExpired", function () {
      console.warn("token expired");
      this.session.expired = true;
    });

    accessManager.on("error", function (err) {
      console.error("An error occurred");
      console.error(err);
    });

    let client1 = await new Client(this.worker.tokens.access);

    client1.on("connectionStateChanged", (state) => {

      if (state == "connected") {
      }
      if (state == 'disconnecting') {
      }
      if (state == 'disconnected') {
      }
      if (state == 'denied') {
      }

    });


    if (client === "client") {
      // add Participant to conversation
      this.layoutService
        .addParticipantToConv(
          {
            ConversationSid: channelSid,
            identity: this.session.identity,

          },
          this.userData['companyId']
        )
        .subscribe((data) => { });

      var channel = await client1.getConversationBySid(channelSid);

      this.task[index]["channel"] = channel;

    } else {
      
      var channel = await client1.getConversationBySid(channelSid);
      
      this.agents[index]["channel"] = channel;
    }
    this.setupChannel(channel, index, client1);

  }

  addMessage(message) {


    var m = message.body;

    if (this.messages.find((data) => data["sid"] === message["sid"])) {
      return;
    }
    this.messages.push({
      body: m,
      author: message.author,
      timestamp: Date.now(),
      sid: message.sid,
    });
    this.scrollTop();

  }

  getMessages(channel, index, client) {


    if (client === "client") {
      if (this.task[index]["messages"].length === 0 && client === "client") {
        channel.getMessages().then((messages) => {
          this.task[index]["messages"].push({
            body: `You are now connected to  ${this.task[index]["attributes"]["name"]}`,
            author: "system",
            style: "special",
          });
          messages.items.map((message, indices) => {
            if (
              message["conversation"]["sid"] ===
              this.task[index]["attributes"]["channelSid"]
            ) {
              this.task[index]["messages"].push(message);
            }
            if (messages.items.length - 1 === indices) {
              this.task[index]["lastMessage"] = message;
            }
          });
          this.isloading = false;
        });
      }
    }
    if (
      client === "agent" &&
      channel["attributes"]["channelType"] === "agent"
    ) {

      this.agents[index]["messages"] = [];
      let name;
      name = channel["attributes"]["members"].filter(
        (data) => data !== this.userData["workerFriendlyName"]
      );
      channel.getMessages().then((messages) => {
        // this.agents[index]['messages'].push({
        //   body: `You are now connected to  ${name}`,
        //   author: 'system',
        //   style: 'special'
        // });

        messages.items.map((message, indexes) => {
          this.agents[index]["messages"].push(message);
          if (indexes === messages.items.length - 1) {
            this.agents[index]["lastMessage"] = message;
          }
        });
        this.isloading = false;
      });
      if (this.selectedIndex === 1) {
        this.selectedChat(index, "agent");
      }
    }
    if (channel["attributes"]["channelType"] === "outboundSMS") {


      this.agents[index]["messages"] = [];
      // let name;
      // name = channel['state']['attributes']['members'].filter(data => data !== this.userData['workerFriendlyName'])
      channel.getMessages().then((messages) => {
        this.agents[index]["messages"].push({
          body: `You are now connected to  ${channel["attributes"]["to"]}`,
          author: "system",
          style: "special",
        });
        messages.items.map((message, indexes) => {
          this.agents[index]["messages"].push(message);
          if (indexes === messages.items.length - 1) {
            this.agents[index]["lastMessage"] = message;
          }
        });
        this.isloading = false;
      });
      if (this.selectedIndex === 1) {
        this.selectedChat(index, "agent");
      }
    }
  }
  typingNotification
  setupChannel(channel, index, client) {


    if (channel.status === "joined") {

      if (client === "agent" && channel["attributes"]["channelType"] === "agent") {
        let name;
        name = channel["attributes"]["members"].filter(
          (data) => data !== this.userData["workerFriendlyName"]
        );
        let unique = [...new Set(name)];
        this.agents[index]["name"] = name[0];
        this.agents[index]["channelSid"] = channel.sid;
        this.agents[index]["attributes"] = channel["attributes"];
        this.agents[index]["members"] = unique;
      }
      this.getMessages(channel, index, client);
    } else {
      if (channel.status !== "joined") {
        channel
          .join()
          .then((member) => {
            if (client === "agent" && channel["attributes"]["channelType"] === "agent") {
              let name;
              name = this.previousChannels["attributes"]["members"].filter(
                (data) => data !== this.userData["workerFriendlyName"]
              );
              let unique = [...new Set(name)];

              this.agents[index]["name"] = name[0];
              this.agents[index]["channelSid"] = channel.sid;
              this.agents[index]["attributes"] = channel["attributes"];
              this.agents[index]["members"] = unique;
            }
            if (
              channel["attributes"]["channelType"] === "outboundSMS"
            ) {
              this.agents[index]["channelSid"] = channel.sid;
              this.agents[index]["attributes"] = channel["attributes"];
              // this.agents[index]["name"] = channel["state"]["attributes"]["to"];
              this.agents[index]["name"] = channel["attributes"]["to"];
              this.agents[index]["members"] = ["from"];
            }
          })
          .then(() => {
            this.getMessages(channel, index, client);
          })
          .catch((err) => {

            this.getMessages(channel, index, client);
          });
      }
    }
    channel.on("messageAdded", (message) => {
      this.layoutService.unReadCount.subscribe((data) => {
        this.unReadMessages = data;
      });

      const clientMessage = this.task.findIndex(
        (data) => message.conversation.sid === data["attributes"]["channelSid"]
      );
      const agentMessage = this.agents.findIndex(
        (data) => message.conversation.sid === data["channelSid"]
      );

      if (agentMessage >= 0) {

        if (agentMessage === this.index && this.selectedIndex === 1) {
          this.addMessage(message.state);
          this.unreadMessage();
          this.agents[agentMessage]["lastMessage"] = message;
        } else {
          this.agents[agentMessage]["messages"].push(message);
          this.agents[agentMessage]["unReadMessages"];
          this.agents[agentMessage]["lastMessage"] = message;
          this.unreadMessage();
        }
        this.scrollTop();
      }
      if (clientMessage >= 0) {
        if (clientMessage === this.index && this.selectedIndex === 0) {
          this.addMessage(message.state);
          this.task[clientMessage]["lastMessage"] = message;
          this.unreadMessage();
        } else {
          this.task[clientMessage]["messages"].push(message);
          this.task[clientMessage]["unReadMessages"];
          this.task[clientMessage]["lastMessage"] = message;
          this.unreadMessage();
        }
        this.scrollTop();
      }
      // this.layoutService.openChat.next(true);

      this.isloading = false
    });

    channel.on("participantJoined", (member) => {
      if (
        this.selectedIndex === 1 &&
        this.agents.find(
          (data) => data["channel"]["sid"] === member["conversation"]["sid"]
        )
      ) {
        let index = this.agents.findIndex(
          (data) => data["channel"]["sid"] === member["conversation"]["sid"]
        );
        let userName = this.totalAgents.find(
          (data) => data["value"] === member.identity
        );

        this.agents[index]["messages"].push({
          body:
            userName["name"] + " has joined the channel." ||
            member.identity + " has joined the channel.",
          author: "system",
          timestamp: Date.now(),
        });
        this.agents[index]["members"].push(member.identity);
        let members = [...new Set(this.agents[index]["members"])];
        this.agents[index]["members"] = members;
      }
    });
    channel.on("participantLeft", (member) => {
      if (
        this.selectedIndex === 1 &&
        this.agents.find(
          (data) => data["channel"]["sid"] === member["conversation"]["sid"]
        )
      ) {
        let index = this.agents.findIndex(
          (data) => data["channel"]["sid"] === member["conversation"]["sid"]
        );
        let userName = this.totalAgents.find(
          (data) => data["value"] === member.identity
        );

        this.agents[index]["messages"].push({
          body:
            userName["name"] + " has left the channel." ||
            member.identity + " has left the channel.",
          author: "system",
          timestamp: Date.now(),
        });
        this.agents[index]["members"] = this.agents[index]["members"].filter(
          (data) => data != member.identity
        );
        if (member.identity === this.userData["workerFriendlyName"]) {
          this.agents = this.agents.filter(
            (data) => data["channel"]["sid"] !== member["conversation"]["sid"]
          );
          this.messages = [];
          this.index = null;
        }
      }
    });


    channel.on("typingStarted", (member) => {
      this.typingNotification = member.identity + ' is typing ...';
    });

    channel.on("typingEnded", (member) => {


      this.typingNotification = '';
    });
  }

  send() {

    if (this.selectedIndex === 0 && this.chatMessage !== "") {
      this.task[this.index]["channel"].sendMessage(this.chatMessage);
      this.chatMessage = "";
    } else {
      if (this.chatMessage !== "") {
        this.agents[this.index]["channel"].sendMessage(this.chatMessage);
        this.chatMessage = "";
      }
    }
    // this.agents.sort(function (a, b) {
    //   return a.timestamp - b.timestamp;
    // });
    this.isIconShow=false
  }
  complete() {

    if (this.selectedIndex === 0) {
      if (this.task[this.index]["channel"]) {
        let task = this.task[this.index];
        this.task[this.index]["channel"].leave();
        this.workerJS.completeTask(task["taskId"], (error, task) => {
          if (error) {
            console.error(error);
            return;
          }
          this.index = null;
          this.messages = [];
          this.customerInformation = {};
          this.layoutService.newTask.next("");
          this.prevTask = this.layoutService.prevTasks.next(this.task);
        });
      }
    } else {
      if (this.agents[this.index]["channelSid"]) {
        this.agents[this.index]["channel"].leave();
        this.layoutService
          .agentChatend(
            {
              ChannelSid: this.agents[this.index]["channelSid"],
              to: this.agents[this.index]["attributes"]["to"],
              from: this.agents[this.index]["attributes"]["from"],
            },
            this.projectId
          )
          .subscribe((data) => { });
      }
    }
  }
  transferChat() {
    if (this.transferQueue && this.task[this.index]) {
      this.layoutService
        .transferChat(
          {
            to: this.transferQueue,
            ChannelSid: this.task[this.index]["attributes"]["channelSid"],
            From: this.task[this.index]["attributes"]["name"],
            taskSid: this.task[this.index]["taskId"],
            workerSid: JSON.parse(localStorage.getItem("userData")).workerSId,
          },
          this.projectId
        )
        .subscribe((data) => {
          this.messages = [];
          this.layoutService.newTask.next("");
          this.prevTask = this.layoutService.prevTasks.next(this.task);
          this.customerInformation = {};
        });
    }
  }

  tabChange(event) {

    this.messages = [];
    this.index = 0;
    if (this.selectedIndex === 0 && this.task.length !== 0) {
      this.selectedChat(0, "client");
    }
    if (this.selectedIndex === 1 && this.agents.length !== 0) {
      this.selectedChat(0, "agent");
    }

    this.customerInformation = {};
  }

  enterKey(event: any) {
    if (event.keyCode === 13) {
      this.send();
    }
  }

  unreadMessage() {
    if (!this.isChat) {
      this.layoutService.unReadCount.next(this.unReadMessages++);
      this.unReadMessages = this.unReadMessages + 1;
      this.layoutService.unReadCount.next(this.unReadMessages);
    }
  }

  customerSearch(value) {
    if (value) {
      this.ticketsService.getCustomerByMail(value).subscribe((data) => {
        if (!data["status"]) {
          this.ticketsService
            .getCustomerTickets(data["id"], this.projectId)
            .subscribe((data) => {
              if (data.length > 0) {
                this.customerTickets = data;
              }
            });
        }
      });
    }
  }
  searchPhoneNumber(value) {
    if (value) {
      this.ticketsService.getCustomerByPhone(value).subscribe((data) => {
        if (!data["status"]) {
          this.ticketsService
            .getCustomerTickets(data["id"], this.projectId)
            .subscribe((data) => {
              if (data.length > 0) {
                this.customerTickets = data;
              }
            });
        }
      });
    }
  }

  closeChat() {
    this.layoutService.openChat.next(false);
  }

  getAgents() {
    this.agentService
      .getContacts(this.projectId, this.userData.id)
      .subscribe((data) => {
        this.companyAgents = data.map((data) => ({
          name: data["FirstName"] + " " + data["LastName"],
          value: data["workerFriendlyName"],
          ProfilePhoto: data["ProfilePhoto"],
        }));
      });
  }

  addAgent() {
    this.dialog.open(AgentsdialogComponent, {
      data: {
        companyAgents: this.companyAgents,
        totalAgents: this.totalAgents,
      },
      width: "300px",
      height: "300px",
    });
    this.layoutService.addAgent.subscribe((data) => {
      if (this.agents[this.index]) {
        this.layoutService
          .addAgentToChannel(
            {
              ChannelSid: this.agents[this.index]["channel"]["sid"],
              to: data["value"],
            },
            this.projectId
          )
          .subscribe((person) => {
            this.agents[this.index]["members"].push(data["value"]);
            let unique = [...new Set(this.agents[this.index]["members"])];
            this.agents[this.index]["members"] = unique;
          });
      }
    });
  }
  removeAgent() {
    this.agents[this.index]["channel"].leave();
    this.layoutService
      .removeAgent(
        {
          ChannelSid: this.agents[this.index]["channel"]["sid"],
          identity: this.userData["workerFriendlyName"],
        },
        this.projectId
      )
      .subscribe((person) => {
      });
  }

  addEmoji(event) {
    this.chatMessage = this.chatMessage + event.emoji.native;
  }
  getProfilePhoto(value) {
    if (this.totalAgents.find((data) => data["value"] === value)) {
      return this.totalAgents.findIndex((data) => data["value"] === value);
    } else {
      return -1;
    }
  }

  getProjects() {

    this.roleService.projectAdmin(this.userData['companyId']).subscribe((projects) => {
      this.projects = projects;
      this.projectId = projects[0]["id"];
      this.fetchAgentChannels();
      this.getAgents();
      this.roleService.fetchRoles(this.projectId).subscribe((data) => {
        this.taskQueues = projects.map((role) => ({
          friendlyName: role["TwilioSkillName"],
          sid: role["RoleSid"],
        }));
      });
    });
  }
  projectChange1(data) {
    this.projectId = data;
    this.fetchAgentChannels();
    this.getAgents();
  }

  getMessageTime(item: any,from?:any) {
    if(from==2){
    if (item) {
      const then = new Date(item);
      const now = new Date();

      const msBetweenDates = Math.abs(then.getTime() - now.getTime());
      const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

      if (hoursBetweenDates < 24) {
        var suffix = then.getHours() >= 12 ? "PM" : "AM";
        var hours = ((then.getHours() + 11) % 12 + 1) + ":" + then.getMinutes() + " " + suffix
        return hours
      } else {
        let time 
        if(from){
          time=then.getDate() + "/" + (then.getMonth() + 1) + "/" + then.getFullYear()
        }else{
          time=then.getDate() + "/" + (then.getMonth() + 1) + "/" + then.getFullYear()
        }
        return time

      }
    }
  }
  else{

  }
  }

}
