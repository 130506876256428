<!-- Overview - Referree Details-->
<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Recruiter details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Active</td>
            <td>
              <mat-slide-toggle [(ngModel)]="sideViewerData.accountActive"
                (change)="changeActive(sideViewerData.id,$event)"></mat-slide-toggle>
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              {{ sideViewerData.firstName + " " + sideViewerData.lastName }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData.emailId }}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>
              {{ sideViewerData.phone }}
            </td>
          </tr>
          <tr>
            <td>Created Date</td>
            <td>{{ sideViewerData.createdAt | date : "medium" }}</td>
          </tr>
          <tr>
            <td>University</td>
            <td>{{ sideViewerData.University || "N/A" }}</td>
          </tr>
          <tr>
            <td>Student Added</td>
            <td *ngIf="sideViewerData.totalStudent > 0" data-toggle="modal" data-target="#stcount"
              (click)="getStudentList(sideViewerData.studentDetails)" style="cursor: pointer; color: blue">
              {{ sideViewerData.totalStudent }}
            </td>
            <td *ngIf="sideViewerData.totalStudent == 0">
              {{ sideViewerData.totalStudent }}
            </td>
          </tr>
          <tr>
            <td>Business Document</td>
            <td *ngIf="sideViewerData.businessCertificate != '-'">
                <a href="javascript:void(0)" (click)="openPreview(pdfContent, sideViewerData.businessCertificate)">{{
                  sideViewerData?.businessCertificateFilename
                  }}</a>
            </td>
            <td *ngIf="sideViewerData.businessCertificate == '-'">N/A</td>
          </tr>
          <tr>
            <td>MOU Document</td>
            <td *ngIf="sideViewerData.moubusinessCertificate != '-'">
                <a href="javascript:void(0)" (click)="openPreview(pdfContent, sideViewerData.moubusinessCertificate)">MOU</a>
            </td>
            <td *ngIf="sideViewerData.moubusinessCertificate == '-'">N/A</td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Call Details">
      <div class="alert alert-info mb-0">
        {{ sideViewerData?.CallDetails?.length }} Calls
      </div>
      <div class="mx-2 my-2" *ngFor="let i of sideViewerData.CallDetails">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i?.AgentName }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i?.DateTime | date : "medium" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ i?.CallStatus | titlecase }}</td>
          </tr>
          <tr>
            <td>Call Recording</td>
            <td>
              <audio *ngIf="i?.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i?.RecordingURL"
                controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{ i?.Comments }}</td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>
    <mat-tab label="Communication" style="position: relative">
      <div class="mx-2 my-2"></div>
      <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
        <div class="mb-3 pb-1 ct pr" style="
            background: #f5f5fa;
            border-radius: 10px;
            margin: 0 20px 0 10px;
          ">
          <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
            placeholder="Write your message here..."></textarea>
          <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
            (click)="sendSms(sideViewerData, 'textMessage')">
            <img src="../../../assets/img/send-message.svg" class="img12" />
          </button>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="p-3">
        <div class="header-md">
          Total Messages
          <span style="font-size: 12px">{{
            "(" + (smsList?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="dyn-height">
            <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
              <div class="d-flex pt-1" style="width: 100%">
                <div style="width: 100%">
                  <div class="admin-comment" style="
                      border: 1px solid #f5e7e7;
                      border-radius: 10px;
                      border-bottom-right-radius: 0;
                    " *ngIf="j.Direction == 'inbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img src="../../../assets/img/student.svg" style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " />
                        </div>
                      </div>
                      <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                        <div class="d-flex">
                          <span class="header-12">Student</span>

                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j?.DateTime | date : "medium" }}
                          </div>
                        </div>
                        <div class="sub-text flex-grow-1">{{ j?.Text }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="agent-comment" style="
                      border-radius: 10px;
                      border-bottom-left-radius: 0;
                      border: 1px solid #def3f7;
                    " *ngIf="j.Direction == 'outbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " src="../../../assets/img/admin-comments.svg" />
                        </div>
                      </div>

                      <div class="pl-2 pr-2 d-flex flex-column">
                        <div class="d-flex">
                          <span class="header-12">Agent</span>
                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j?.DateTime | date : "medium" }}
                          </div>
                        </div>
                        <div class="sub-text" style="width: 315px; line-height: 125%">
                          {{ j?.Text }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height: 200%">
            {{ sideViewerData?.Activities?.length }} Actions taken
          </div>
          <div class="ml-auto" style="width: 180px">
            <input class="searchActivity" type="text" style="font-size: 13px" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.Activities?.length)" />
          </div>
        </div>
        <div *ngFor="
            let i of sideViewerData?.Activities
              | slice : 0 : minItems
              | searchFilterTable : activityfilterString
          ">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i?.Description }}
              <span class="sm-text ml-2">{{
                i?.DateTime | date : "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore hand" *ngIf="minItems < sideViewerData?.Activities?.length"
          (click)="showmore(sideViewerData?.Activities?.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
    <!-- 
    
     -->
  </mat-tab-group>
</mat-sidenav>
<div class="p-3">
  <div class="pl-2 pr-2 d-flex mb-4">
    <div class="db-sm-header mr-auto" style="line-height: 330%">Activity</div>
  </div>
  <carousel [cellsToScroll]="2" style="padding-left: 50px; z-index: unset" class="fixheight">
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="leadBycards('all'); onClick(1)"
          [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.all || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="leadBycards('totalrecruiters'); onClick(2)"
          [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.totalrecruiters || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Recruiters</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="leadBycards('totalFreelancer'); onClick(3)"
          [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.totalFreelancer || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Freelancer</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div data-toggle="modal" data-target="#stcount" class="db-block w-175"
          (click)="getStudents('enrolled'); onClick(4)" [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.enrolled || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Students Enrolled</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" data-toggle="modal" data-target="#stcount"
          (click)="getStudents('registered'); onClick(5)" [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.registered || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Students Registered</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('paymentpending'); onClick(6)"
          [ngClass]="{ 'db-block-active': tab === 'tab6' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.paymentpending || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Payments Pending</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('paymentscomplete'); onClick(7)"
          [ngClass]="{ 'db-block-active': tab === 'tab7' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.paymentscomplete || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Payments Completed</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell"></div>
  </carousel>
  <div style="clear: both"></div>
</div>

<div class="pl-4 pr-4 pb-4">
  <div class="d-flex align-items-center">
    <div class="table-responsive">
      <div class="data-table" style="background: #fff">
        <table class="table table-md mt-1 mb-2 ctable">
          <thead>
            <tr>
              <td colspan="5">
                <div class="indived-search">
                  <input type="text" placeholder="Search" class="form-control search-all" #inputString />
                </div>
              </td>
              <td colspan="8" class="vm">
                <div class="pagenation-right d-flex justify-content-end align-items-center">
                  <select class="form-select form-select-dark mx-2" style="padding: 6px; font-size: 12px" [(ngModel)]="statusValue"
                  (ngModelChange)="dropdownSelect($event)">
                    <option value="all">All</option>
                    <option value="pending">Pending</option>
                    <option value="informed">Informed</option>
                    <option value="called">Called</option>
                    <option value="notanswered">Not Answered</option>
                    <option value="callfailed">Call Failed</option>
                    <option value="callback">Call Back</option>
                    <option value="wrongnumber">Wrong Number</option>
                  </select>
                  <div style="color: #fff; font-size: 12px">
                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalRecords"
                      (pageChange)="onPageChange($event)" [maxSize]="5" [boundaryLinks]="true" class="pagenation-right">
                    </ngb-pagination>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tr style="background: #eaf5fd">
            <th style="width: 30px; white-space: nowrap;padding-left: 10px;">
              <div style="width: 20px">
                <input type="checkbox" class="selectAll" id="selectAll" />
              </div>
            </th>
            <th nowrap>Actions</th>
            <th nowrap>
              Created
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Updated
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Name
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Email
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Role
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>

            <th nowrap>
              Phone
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Added
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Enrolled
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Payments
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>
              Payment Date
              <span><button class="sortButton">
                  <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
            </th>
            <th nowrap>Call Status</th>
          </tr>
          <tr *ngFor="let i of recruiterList; let id = index">
            <td class="vm" nowrap style="padding-left: 10px;">
              <div style="width: 20px">
                <input type="checkbox" name="lead" class="selectLead" id="selectLead" />
              </div>
            </td>
            <td class="vm" nowrap>
              <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" placement="top"
                tooltipClass="my-custom-class">
                <mat-sidenav-content style="display: inline !important">
                  <div style="display: inline !important; cursor: pointer" (click)="sideNav.toggle(); sideNavData(i)">
                    <img src="../../../../assets/img/view2.svg" class="img13" />
                  </div>
                </mat-sidenav-content>
              </mat-sidenav-container>
              <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="ml-3"
                style="display: inline; cursor: pointer" data-toggle="modal" (click)="startCallListener2(i, id)"
                data-target="#callingscreen1">
                <img src="../../../../assets/img/call.svg" class="img13" />
              </div>
            </td>
            <td class="vm" nowrap>{{ i.createdAt | date : "dd MMM, yyyy" }}</td>
            <td class="vm" nowrap>{{ i.updatedAt | date : "dd MMM, yyyy" }}</td>

            <td class="vm" nowrap>
              <div>
                <button class="btn btn-link btn-sm" (click)="openEditLead(editLead2, i)">
                  <div class="tooltip_title">
                    <div class="txt-oflow2">
                      <span>
                        {{ (i.firstName || '') + " " + (i.lastName || '') | titlecase }}
                      </span>
                    </div>
                    <span class="tooltiptext">{{ (i.firstName || '') + " " + (i.lastName || '') | titlecase }}</span>
                  </div>
                </button>
              </div>
            </td>
            <td class="vm" nowrap>
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  <span>
                    {{ i.emailId }}
                  </span>
                </div>
                <span class="tooltiptext">{{ i.emailId }}</span>
              </div>
              <!-- <div *ngIf="i.emailId.length < 25">{{ i.emailId }}</div>

              <div *ngIf="i.emailId.length > 24" data-title="{{ i.emailId }}">
                {{ i.emailId.substr(0, 20) + "..." }}
              </div> -->
            </td>

            <td class="vm" nowrap>
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  <span>
                    {{ i.category == "ORGANIZATION" ? "RECRUITER" : i.category }}
                  </span>
                </div>
                <span class="tooltiptext">
                  {{ i.category == "ORGANIZATION" ? "RECRUITER" : i.category }}
                </span>
              </div>
              
            </td>
            <td class="vm" nowrap>{{ i.phone }}</td>

            <td class="vm" nowrap>
              <a *ngIf="i.totalStudent > 0" class="btn-link" data-toggle="modal" data-target="#stcount"
                (click)="getStudentList(i.studentDetails)" style="cursor: pointer; color: blue">{{ i.totalStudent }}</a>
              <div *ngIf="i.totalStudent == 0">{{ i.totalStudent }}</div>
            </td>
            <td class="vm">
              <a *ngIf="i.enrolledStudents.length > 0" class="btn-link" data-toggle="modal" data-target="#stcount"
                (click)="getStudentList(i.enrolledStudents)" style="cursor: pointer; color: blue">{{
                i.enrolledStudents.length }}</a>
              <div *ngIf="i.enrolledStudents.length == 0">0</div>
            </td>
            <!-- Students Enrolled -->
            <td class="vm" nowrap>Pending</td>
            <!-- Payment Status -->
            <td class="vm" nowrap>-</td>
            <!-- Payment Date -->
            <td class="vm" nowrap>
              <span class="status-pending" *ngIf="i?.salesForceDataCRM?.CallStatus == 'pending'">Pending</span>
              <span class="status-informed" *ngIf="i?.salesForceDataCRM?.CallStatus == 'informed'">Informed</span>
              <span class="status-informed" *ngIf="i?.salesForceDataCRM?.CallStatus == 'called'">Called</span>
              <span class="status-pending" *ngIf="i?.salesForceDataCRM?.CallStatus == 'notanswered'">Not Answered</span>
              <span class="status-failed" *ngIf="i?.salesForceDataCRM?.CallStatus == 'callfailed'">Call Failed</span>
            </td>
          </tr>
          
          <tr class="h30"></tr>
          <tr *ngIf="leadsLoading == true">
            <td colspan="10">
              <div class="norecords">
                <img src="../../../../assets/img/waiting.gif" />

                <div>
                  Loading Data<br />Please wait while we fetch the data for you
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="noLeadsFound == true">
            <td colspan="10">
              <div class="norecords">
                <img src="../../../../assets/img/nodata.svg" />
                <div>
                  No records found.<br />Please select a different date range.
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="modal fade modal-md" id="stcount" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 950px; margin-left: -131px">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Student Count</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex my-3 legend">
            <div class="mr-1">
              <img src="../../../assets/img/us-transfer.svg" class="img12" style="margin-top: 3px" />
            </div>
            <div class="db-sm-header mr-3 mt-1">US Transfer</div>

            <div class="mr-1">
              <img src="../../../assets/img/international.svg" class="img12" style="margin-top: 3px" />
            </div>
            <div class="db-sm-header mt-1">International</div>
          </div>
          <!--<div class="header-md">Total 10 members added as referral</div>-->
          <input type="text" class="form-control mb-2" placeholder="Search by name" [(ngModel)]="studentSearch" />
          <div class="data-table" style="height: 250px; overflow: auto">
            <table class="table table-md">
              <tr>
                <th style="width: 21%">Created Date</th>
                <th style="width: 21%">Full Name</th>
                <th>Email Id</th>
                <th style="width: 21%">Phone Number</th>
                <th style="width: 21%">University</th>
                <!-- <th style="width: 21%;">Application status</th> -->
                <th>Status</th>
              </tr>
              <tr *ngFor="let i of studentList | searchFilterTable: studentSearch">
                <td class="vm" nowrap>
                  {{ i?.createdDate | date : "dd MMM, yyyy" }}
                </td>
                <td class="vm" style="white-space: nowrap">
                  <div>
                    <img [src]="
                        i.fromUS == '1'
                          ? '../../../assets/img/us-transfer.svg'
                          : '../../../assets/img/international.svg'
                      " class="img12" />

                    {{ (i.firstName || '') + " " + (i.lastName|| '') | titlecase }}
                  </div>
                </td>
                <!-- <td class="vm">
                {{ i.firstName + " " + i.lastName }}
              </td> -->
                <td class="vm" nowrap style="width: 100px">
                  {{ i.emailId || "--" }}
                </td>
                <td class="vm" nowrap style="width: 100px">
                  <span>{{ i?.phoneCountryCode ? "+" + i?.phoneCountryCode : "" }}
                    {{
                    i?.phoneWithoutCode
                    ? i?.phoneWithoutCode
                    : i?.phone || "--"
                    }}
                  </span>
                </td>

                <td class="vm" nowrap style="width: 100px">
                  {{ i?.ApplyingAs || "--" }}
                </td>
                <!-- <td class="vm" nowrap style="width: 100px">
               {{i?.applicationCompletion!='0'?i?.applicationCompletio:'Pending' || '--'}}
              </td> -->
                <td class="vm" nowrap style="width: 100px;text-transform: uppercase;">
                  <span>{{ getStatus(i?.status) }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Calling screen starts-->
<div class="modal fade" id="callingscreen1" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg custom-model-w">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Calling Screen</h5>

        <button type="button" id="closeButton2" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto">
        <div class="row">
          <div class="call-module ml-3">
            <div class="ndialer">
              <!--.......code for no- incoming call screen.........  -->
              <div class="c-caller">Current Caller</div>
              <div class="dialer-middle-screen" style="text-align: center">
                <div class="custom-incomingcall-widget">
                  <h4 class="mt-3 text-ellipsis">
                    {{ marketingId.name }}
                  </h4>
                  <div class="mb-2 mt-1 lg-text" style="font-weight: 100"></div>
                  <div class="my-1 lg-text">
                    {{ marketingId.phoneNumber }}
                  </div>
                  <div class="custom-user-img my-3 mt-5">
                    <img class="img80" src="./assets/img/user-w.svg" alt="" />
                  </div>
                  <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                  <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                    <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                  </p>
                  <p class="custom-call-timer" style="text-align: center"></p>

                  <div class="custom-incoming-btns my-3 mt-4" (click)="stopCurrentCall()">
                    <button type="button" class="btn-round m-r-10">
                      <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 p-3 ndialer2 ng-star-inserted" *ngIf="marketingId.nextCaller">
              <div class="mt-4" style="float: right">
                <img class="img50" src="./assets/img/user-w.svg" alt="" />
              </div>
              <div class="sm-text" style="
                  text-transform: uppercase;
                  font-weight: 100;
                  background: #f75c2b;
                  width: 110px;
                  padding: 5px;
                  border-radius: 15px;
                  letter-spacing: 1px;
                  text-align: center;
                  color: #fff;
                ">
                NEXT CALLER
              </div>
              <div class="lg-text pt-2 pb-1">{{ nextCallerName }}</div>
              <div class="md-text pb-2">{{ nextCallerCity }}</div>
              <div class="md-text">{{ nextCallerNumber }}</div>
            </div>

            <div class="mt-2 d-flex justify-content-between">
              <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class"
                (click)="startCallListener()">
                <img class="img18" src="./assets/img/redial3.svg" alt="" />
              </div>
              <button class="cbtn" type="button" placement="right" [ngbPopover]="calendarContent">
                <img class="img18" src="./assets/img/conference.svg" alt="" />
              </button>
              <ng-template #calendarContent>
                <form #form="ngForm" (ngSubmit)="conferenceCall(form.value)">
                  <div class="xp-3" style="width: 185px">
                    <div class="mb-1">
                      <input id="phone_number" name="phone_number" type="text" class="form-control"
                        placeholder="Enter Phone Number" ngModel />
                    </div>
                    <select class="form-control" id="exampleFormControlSelect1">
                      <option value="default" disabled selected>Select</option>
                      <option>Manager 1</option>
                      <option>Manager 2</option>
                      <option>Manager 3</option>
                      <option>Manager 4</option>
                    </select>

                    <button class="btn btn-primary btn-block btn-md mt-2">
                      Connect
                    </button>
                  </div>
                </form>
              </ng-template>

              <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top" tooltipClass="my-custom-class"
                (click)="stopCurrentCall()">
                <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
              </div>

              <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top" tooltipClass="my-custom-class"
                (click)="callNext()">
                <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="call-module-update ml-4 mr-2">
            <div class="row">
              <div class="col-md-12">
                <div class="header-12 mt-2 mb-1">Recruiter details</div>
              </div>
            </div>
            <div class="row custom-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Name</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0].firstName || '' + " " + callData[0].lastName || '' }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Email</label>
                  <div *ngIf="callData[0]">{{ callData[0].emailId }}</div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Mobile</label>
                  <div *ngIf="callData[0]">{{ callData[0].phone }}</div>
                </div>
              </div>
            </div>

            <div class="row custom-row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formGroupExampleInput">Created Date</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0].createdAt | date : "medium" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <!-- Radio button  -->

              <div class="form-group col-md-12">
                <label for="">Feedback</label>
                <div class="mt-2">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="pending"
                      [(ngModel)]="callStatusRecruiterCRM"
                      (change)="onStatusChange($event.target.value,callData[0]?.salesForceDataCRM?.id)" />
                    <label class="form-check-label" for="inlineRadio2">Pending</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="informed"
                      [(ngModel)]="callStatusRecruiterCRM"
                      (change)="onStatusChange($event.target.value,callData[0]?.salesForceDataCRM?.id)" />
                    <label class="form-check-label" for="inlineRadio2">Informed</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="called"
                      [(ngModel)]="callStatusRecruiterCRM"
                      (change)="onStatusChange($event.target.value,callData[0]?.salesForceDataCRM?.id)" />
                    <label class="form-check-label" for="inlineRadio2">Called</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="notanswered"
                      [(ngModel)]="callStatusRecruiterCRM"
                      (change)="onStatusChange($event.target.value,callData[0]?.salesForceDataCRM?.id)" />
                    <label class="form-check-label" for="inlineRadio2">Not Answered</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callfailed"
                      [(ngModel)]="callStatusRecruiterCRM"
                      (change)="onStatusChange($event.target.value,callData[0]?.salesForceDataCRM?.id)" />
                    <label class="form-check-label" for="inlineRadio2">Call Failed</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callback"
                      [(ngModel)]="callStatusRecruiterCRM"
                      (change)="onStatusChange($event.target.value,callData[0]?.salesForceDataCRM?.id)" />
                    <label class="form-check-label" for="inlineRadio2">Call Back</label>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="" class="form-label mt-2">Comments</label>
                <textarea class="form-control" rows="5" [(ngModel)]="comments"></textarea>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <div class="mt-3">
                <!--  <button class="btn btn-light btn-sm mr-2" type="button">
                  Reset
                </button> -->
                <button class="btn btn-primary btn-sm" type="button"
                  (click)="updateCallStatus(callData[0]?.salesForceDataCRM?.id)">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Calling screen ends-->



<ng-template #editLead2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Update Details
    </h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="background: #fff">
    <form [formGroup]="updateRecruiter" (ngSubmit)="updateRecruiterForm()" >
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>First Name</label>
          <input class="form-control" name="firstName" formControlName="firstName" disabled />
        </div>
        <div class="form-group col-md-4">
          <label>Middle Name</label>
          <input class="form-control" name="middleName" formControlName="middleName" disabled />
        </div>
        <div class="form-group col-md-4">
          <label>Last Name</label>
          <input class="form-control" name="lastName" formControlName="lastName" disabled />
        </div>
        <div class="form-group col-md-4">
          <label>Email</label>
          <input class="form-control" name="emailId" formControlName="emailId" />
        </div>
        <div class="form-group col-md-4">
          <label>Phone</label>
          <input class="form-control" name="phone" formControlName="phone" />
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <div class="mt-3">
          <button class="btn btn-primary btn-sm" type="submit">
            Update
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
  <div id="progressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
    googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
  </ngx-doc-viewer>
</ng-template>