<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top sideNav"
  style="display: none;">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Call Details">
      <div class="alert alert-info mb-0">
        {{ sideViewerData.CallDetails.length }} Calls
      </div>
      <div *ngFor="let i of sideViewerData.CallDetails" class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i.AgentName }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i.DateTime | date: "medium" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <span *ngIf="i.CallStatus == 'pending'">Pending</span>
              <span *ngIf="i.CallStatus == 'uncontactable'">Uncontactable</span>
              <span *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
              <span *ngIf="i.CallStatus == 'completed'">Completed</span>
              <span *ngIf="i.CallStatus == 'callback'">Call Back</span>
              <span *ngIf="i.CallStatus == 'informed'">Informed</span>
              <span *ngIf="i.CallStatus == 'called'">Called</span>
              <span *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
              <span *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
              <span *ngIf="
                  i.CallStatus == 'callnotanswered' ||
                  i.CallStatus == 'notanswered'
                ">Not Answered</span>
              <span *ngIf="i.CallStatus == 'deferred' || i.CallStatus == 'Deferred'">Deferred Intake</span>
            </td>
          </tr>
          <tr>
            <td>Direction</td>
            <td *ngIf="i.Direction=='inbound'">InComing Call</td>
            <td *ngIf="i.Direction=='outbound'">OutGoing Call</td>
            <!-- <td>{{i.Direction ||'-'}}</td> -->
          </tr>
          <tr>
            <td style="padding-top: 20px">Call Recording</td>
            <td>
              <audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL" controls
                controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{i.Comments ||'-'}}</td>
          </tr>

        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>

    <mat-tab label="Student Details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>CallSid
            </td>
            <td>{{sideViewerData.CallSid}}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{{sideViewerData.FirstName+' '+sideViewerData.LastName}}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{sideViewerData.Email}}</td>
          </tr>
          <tr>
            <td>Created Date Date</td>
            <td>{{sideViewerData.createdAt | date: "dd MMM, yyyy"}}</td>
          </tr>
          <tr>
            <td>Updated Date</td>
            <td>{{sideViewerData.updatedAt | date: "dd MMM, yyyy"}}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{{sideViewerData.Address}}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{{sideViewerData.City}}</td>
          </tr>
          <tr>
            <td>State</td>
            <td>{{sideViewerData.State}}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{{sideViewerData.Country}}</td>
          </tr>
          <tr>
            <td>Zip</td>
            <td>{{sideViewerData.Zip}}</td>
          </tr>
        </table>
      </div>
    </mat-tab>

    <mat-tab label="Communication" style="position:relative">
      <div class="mx-2 my-2">
      </div>
      <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
        <div class="mb-3 pb-1 ct pr" style="background:#f5f5fa;border-radius: 10px;
          margin: 0 20px 0 10px;">
          <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
            placeholder="Write your message here..."></textarea>
          <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
            (click)="sendSms(sideViewerData, 'textMessage')">
            <img src="../../../assets/img/send-message.svg" class="img12">
          </button>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="p-3">
        <!--Comments 2 section-->
        <div class="header-md">
          Total Messages
          <span style="font-size: 12px">{{
            "(" + (smsList?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="dyn-height">
            <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
              <div class="d-flex pt-1" style="width: 100%">
                <div style="width:100%">

                  <div class="admin-comment"
                    style="border:1px solid #f5e7e7;border-radius:10px; border-bottom-right-radius: 0;"
                    *ngIf="j.Direction == 'inbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img src="../../../assets/img/student.svg" style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" />

                        </div>
                      </div>
                      <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                        <div class="d-flex">
                          <span class="header-12">Student</span>

                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="agent-comment"
                    style="border-radius:10px;border-bottom-left-radius: 0;border:1px solid #def3f7"
                    *ngIf="j.Direction == 'outbound'">
                    <div class="d-flex">
                      <div>
                        <div class="pic mr-1 float-left text-center">
                          <img style="width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;" src="../../../assets/img/admin-comments.svg" />

                        </div>
                      </div>

                      <div class="pl-2 pr-2 d-flex flex-column">
                        <div class="d-flex">
                          <span class="header-12">Agent</span>
                          <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                          <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                            {{ j.DateTime | date: "medium" }}
                          </div>
                        </div>
                        <div class="sub-text" style="width:315px;line-height: 125%;">{{ j.Text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </mat-tab>

  </mat-tab-group>
</mat-sidenav>

<div class="container-fluid">
  <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
    <div class="db-sm-header" style="line-height: 330%">Activity</div>
    <div class="db-sm-header mb-1 ml-auto">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>
    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <carousel [cellsToScroll]="0" style="padding-left: 30px; z-index: unset" class="fixheight">
      <div class="carousel-cell">
        <div class="mr-3" #totalRecords (click)="showTotalApplications()">
          <div class="db-block w-175" (click)="onClick(1)" [ngClass]="{ 'db-block-active': tab === 'tab1' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../assets/newimages/enquiries-color.png" style="width: 50px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  <!-- {{ totalApplications }} -->
                  {{ topBarCounts.total }}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">All Enquires</div>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-cell">
        <div class="mr-3" #totalRecords (click)="showCampbellsvilleApplications()">
          <div class="db-block w-175" (click)="onClick(2)" [ngClass]="{ 'db-block-active': tab === 'tab2' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../assets/newimages/cu-logo-color.png" style="width: 50px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  <!-- {{ totalApplications }} -->
                  {{ topBarCounts.campbellsville }}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Campbellsville</div>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-cell">
        <div class="mr-3" #totalRecords (click)="showUniversityHubApplications()">
          <div class="db-block w-175" (click)="onClick(3)" [ngClass]="{ 'db-block-active': tab === 'tab3' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../assets/newimages/u-hub-color.png" style="width: 50px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  <!-- {{ totalApplications }} -->
                  {{ topBarCounts.universityhub }}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">University Hub</div>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-cell">
        <div class="mr-3" #totalRecords (click)="showBufApplications()">
          <div class="db-block w-175" (click)="onClick(4)" [ngClass]="{ 'db-block-active': tab === 'tab4' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../assets/newimages/gbp_buf.png" style="width: 50px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  <!-- {{ totalApplications }} -->
                  {{ topBarCounts.buf }}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">BUF</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="carousel-cell">
        <div class="mr-3" #totalRecords (click)="showReferralApplications()">
          <div class="db-block w-175" (click)="onClick(4)" [ngClass]="{ 'db-block-active': tab === 'tab4' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../assets/newimages/referrals-color.png" style="width: 50px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{ topBarCounts.referrals }}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Referrals</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>
      <div class="carousel-cell"></div>

    </carousel>
  </div>
</div>

<div class="container-fluid">
  <div class="d-flex align-items-end">
    <div class="pl-2 header-12">Total Enquires</div>
  </div>

  <div class="mt-1 ml-3 mr-3">
    <div class="row">
      <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
        <div class="table-responsive data-table mb-3">
          <table class="table table-md wbg table-shadow" style="margin-bottom: 0px; width: 100%">
            <thead>
              <tr>
                <td colspan="5">
                  <div class="indived-search" style="position: relative">
                    <input type="search" (input)="onInput($event)" #inputString class="form-control search-all"
                      style="padding: 4px 12px" [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                  </div>
                </td>
                <td colspan="5" class="vm">
                  <div class="pagenation-right d-flex justify-content-end align-items-center">
                    <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
                      (ngModelChange)="dropdownSelect($event)">
                      <option value="all">All</option>
                      <option value="pending">Pending</option>
                      <option value="called">Called</option>
                      <option value="callback">Call Back</option>
                      <option value="informed">Informed</option>
                      <option value="callfailed">Wrong Number</option>
                      <option value="notanswered">Not Answered</option>
                      <option value="deferredintake">Deferred Intake</option>
                      <!-- <option value="uncontactable">Un Contactable</option> -->
                    </select>
                    <div class="float-right" style="color: #fff; font-size: 12px">
                      <app-custom-pagination></app-custom-pagination>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
            <tr>
              <th nowrap>
                <div class="d-flex">
                  <!-- <div style="width: 20px">
                    <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                      (change)="selectAllforAssignment()" />
                  </div> -->
                  <div>Created Date</div>
                  <span><button class="sortButton" (click)="toggleSort('createdAt','asc')"><img class="img7 ml-1"
                        src="../../../../assets/img/sort-data.svg" /></button></span>
                </div>
              </th>

              <th nowrap>
                Updated Date
                <span><button class="sortButton" (click)="toggleSort('updatedAt','asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
              <th nowrap>
                Student Name
                <span><button class="sortButton" (click)="toggleSort('FirstName','asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
              <th nowrap>
                Student Email
                <span><button class="sortButton" (click)="toggleSort('Email','asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
              <th nowrap>
                Project
                <span><button class="sortButton" (click)="toggleSort('projectName','asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
              <th nowrap>
                To
                <span><button class="sortButton" (click)="toggleSort('To','asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
              <th nowrap>
                From
                <span><button class="sortButton" (click)="toggleSort('PhoneNumber','asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
              <th nowrap>
                Call Status
                <span><button class="sortButton" (click)="toggleSort('CallStatus','asc')"><img class="img7 ml-1"
                      src="../../../../assets/img/sort-data.svg" /></button></span>
              </th>
              <th nowrap>Actions</th>
            </tr>
            <tbody>
              <tr *ngFor="let item of leadsTable; let id = index">
                <td class="vm" nowrap>
                  <div class="d-flex">
                    <!-- <div style="width: 20px">
                      <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead"
                          id="selectLead" (change)="selectAgentforAssignment($event, item)" />
                    </div> -->
                    <div style="line-height: 125%">
                      <span>{{ item.createdAt | date: "dd MMM, yyyy" }}</span>
                    </div>
                  </div>
                </td>

                <td class="vm">{{ item.updatedAt | date: "dd MMM, yyyy" }}</td>
                <td class="vm" nowrap>
                  <div class="txt-oflow">
                    <button class="btn btn-link btn-sm" style="position:relative"
                      (click)="openEditLead(editLead2, item)">

                      {{ item.FirstName + " " + item.LastName | titlecase }}
                    </button>

                  </div>
                </td>
                <td class="vm" nowrap>{{ item.Email }}</td>
                <td class="vm" nowrap>
                  <div class="d-flex">
                    <div *ngIf="item.projectName == 'Referrals'" class="pic mr-1 float-left text-center">
                      <img class="img20" src="../../../assets/newimages/referrals-color.png" />
                    </div>
                    <div *ngIf="item.projectName == 'Campbellsville'" class="pic mr-1 float-left text-center">
                      <img class="img20" src="../../../assets/newimages/cu-logo-color.png" />
                    </div>
                    <div *ngIf="item.projectName == 'University Hub'" class="pic mr-1 float-left text-center">
                      <img class="img20" src="../../../assets/newimages/u-hub-color.png" />
                    </div>
                    <div class="txt-oflow2 float-left">
                      {{ item.projectName }}
                    </div>
                  </div>
                </td>
                <td class="vm">{{ item.To }}</td>
                <td class="vm">
                  {{ item.PhoneNumber }}
                </td>
                <td class="text-capitalize vm">
                  <span class="status-pending" *ngIf="item.CallStatus == 'pending'">Pending</span>
                  <span class="status-pending" *ngIf="item.CallStatus == 'notinterested'">Not Interested</span>
                  <span class="status-completed" *ngIf="item.CallStatus == 'completed'">Completed</span>
                  <span class="status-callback" *ngIf="item.CallStatus == 'callback'">Call Back</span>
                  <span class="status-informed" *ngIf="item.CallStatus == 'informed'">Informed</span>
                  <span class="status-informed" *ngIf="item.CallStatus == 'called'">Called</span>
                  <span class="status-failed" *ngIf="item.CallStatus == 'callfailed'">Wrong Number</span>
                  <span class="status-deferred" *ngIf="item.CallStatus == 'deferredintake'">Deferred Intake</span>
                  <span class="status-pending" *ngIf="
                      item.CallStatus == 'callnotanswered' ||
                      item.CallStatus == 'notanswered'
                    ">Not Answered</span>
                  <span class="status-deferred" *ngIf="
                      item.CallStatus == 'deferred' ||
                      item.CallStatus == 'Deferred'
                    ">Deferred Intake</span>
                  <span class="status-uncontactable" *ngIf="
                      item.CallStatus == 'uncontactable' ||
                      item.CallStatus == 'uncontactable'
                    ">Un Contactable</span>
                </td>
                <td class="text-capitalize vm" nowrap style="padding: 2px">
                  <div class="example-button-row mat-bset d-flex">
                    <mat-sidenav-container>
                      <mat-sidenav-content>
                        <button class="viewLeadButton" (click)="sideNav.toggle(); sideNavData(item)" mat-button>
                          <img class="img12" src="../../../../assets/img/view2.svg" />
                        </button>
                      </mat-sidenav-content>
                    </mat-sidenav-container>
                    <!-- <button mat-button ngbTooltip="Start Call" placement="top" tooltipClass="my-custom-class"
                      data-toggle="modal" data-target="#staticBackdrop">
                      <img class="img12" src="../../../../assets/img/call.svg" />
                    </button> -->
                    <button mat-button (click)="startCallListener2(item,id)" ngbTooltip="Start Calls" placement="top"
                      tooltipClass="my-custom-class" data-toggle="modal" data-target="#staticBackdrop">
                      <img class="img12" src="../../../../assets/img/call.svg" />
                    </button>
                  </div>
                </td>
              </tr>
              <tr class="h30"></tr>
              <tr *ngIf="isLoading == true">
                <td colspan="10">
                  <div class="norecords">
                    <img src="../../../../assets/img/waiting.gif" />

                    <div>
                      Loading Data<br />Please wait while we fetch the
                      data for you
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="noRecords == true">
                <td colspan="10">
                  <div class="norecords">
                    <img src="../../../../assets/img/nodata.svg" />
                    <div>
                      No records found.<br />Please select a different
                      date range.
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- for Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg custom-model-w">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Calling Screen</h5>

        <button type="button" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto">
        <div class="row">
          <div class="call-module ml-3">
            <div class="ndialer">
              <!--.......code for no- incoming call screen.........  -->
              <div class="c-caller">Current Caller</div>
              <div class="dialer-middle-screen" style="text-align: center" *ngIf="dialerScreen1 === 'outgoing1'">
                <div class="custom-incomingcall-widget">
                  <h4 class="mt-3 text-ellipsis">
                    {{ marketingId.name }}
                  </h4>
                  <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                    {{ marketingId.city }}
                  </div>
                  <div class="my-1 lg-text">
                    {{ marketingId.phoneNumber || agentCall }}
                  </div>
                  <div class="custom-user-img my-3 mt-5">
                    <img class="img80" src="./assets/img/user-w.svg" alt="" />
                  </div>
                  <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                  <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                    <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                  </p>

                  <div class="custom-incoming-btns my-3 mt-4">
                    <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                      <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2 p-3 ndialer2 ng-star-inserted" *ngIf="marketingId.nextCaller">
              <div class="mt-4" style="float: right">
                <img class="img50" src="./assets/img/user-w.svg" alt="" />
              </div>
              <div class="sm-text" style="
                text-transform: uppercase;
                font-weight: 100;
                background: #f75c2b;
                width: 110px;
                padding: 5px;
                border-radius: 15px;
                letter-spacing: 1px;
                text-align: center;
                color: #fff;
              ">
                NEXT CALLER
              </div>
              <div class="lg-text pt-2 pb-1">
                {{ nextCallerName }}
              </div>
              <div class="md-text pb-2">{{ nextCallerCity }}</div>
              <div class="md-text">
                {{ nextCallerNumber }}
              </div>
            </div>

            <div class="mt-2 d-flex justify-content-between">
              <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class">
                <img class="img18" src="./assets/img/redial3.svg" alt="" />
              </div>

              <button class="cbtn" type="button" placement="right" [ngbPopover]="calendarContent" [disabled]="disabled">
                <!-- tooltipClass="my-custom-class" -->
                <img class="img18" src="./assets/img/conference.svg" alt="" />
              </button>
              <ng-template #calendarContent>
                <form (ngSubmit)="conferenceCall(form.value)" #form="ngForm">
                  <div class="xp-3" style="width: 185px">
                    <div class="mb-1">
                      <input id="phone_number" name="phone_number" type="text" class="form-control"
                        placeholder="Enter Phone Number" ngModel />
                    </div>
                    <ng-multiselect-dropdown name="manager" [data]="layoutService.projectAgents"
                      [(ngModel)]="layoutService.projectSelectedAgent" [settings]="layoutService.dropdownSettings"
                      style="width: 100%; padding: 0" class="dropdown-primary"></ng-multiselect-dropdown>
                    <button class="btn btn-primary btn-block btn-md mt-2">
                      Connect
                    </button>
                  </div>
                </form>
              </ng-template>

              <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top" tooltipClass="my-custom-class"
                (click)="stopCurrentCall()">
                <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
              </div>

              <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top" tooltipClass="my-custom-class"
                (click)="callNext()">
                <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="call-module-update ml-4 mr-2">
            <div class="row">
              <div class="col-md-12">
                <div class="header-md mt-2 mb-1">Student details</div>
              </div>
            </div>
            <div class="row custom-row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">Name</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0]?.FirstName + " " + callData[0].LastName }}
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">GUID</label>
                  <div *ngIf="callData[0]">{{ callData[0]?.GUID }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">APP Status</label>
                  <div *ngIf="callData[0]">{{ callData[0]?.APPStatus }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">Submitted Date</label>
                  <div *ngIf="callData[0]">
                    {{ callData[0]?.createdAt | date: "medium" }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row custom-row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">DOB</label>
                  <div *ngIf="callData[0]">{{ callData[0].DOB| date: "dd MMM, yyyy" }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">Enrollment Year</label>
                  <div>{{ callData[0]?.EnrollmentYear }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">Program</label>
                  <div>{{ callData[0]?.Program }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formGroupExampleInput">Phone Number</label>
                  <div>{{ callData[0]?.PhoneNumber }}</div>
                </div>
              </div>
            </div>

            <div class="row custom-row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="formGroupExampleInput">Email</label>
                  <div>{{ callData[0]?.Email }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="formGroupExampleInput">Address</label>
                  <div>{{ callData[0]?.Address }}</div>
                </div>
              </div>
            </div>

            <hr class="hr-style mt-1 mb-1" />
            <div class="row custom-row2">
              <div class="col-md-12">
                <div class="header-md mt-2 mb-1">Feedback</div>
              </div>

              <div class="col-md-12">
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check1">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check1" name="option1"
                      value="notinterested" (change)="onStatusChange($event.target.value)" />Not Interested
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check3">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check3" name="option1"
                      value="informed" (change)="onStatusChange($event.target.value)" />Informed
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check4">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check4" name="option1"
                      value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check5">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check5" name="option1"
                      value="callback" (change)="onStatusChange($event.target.value)" />Call Back
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check6">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check6" name="option1"
                      value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check7">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check7" name="option1"
                      value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred Intake
                  </label>
                </div>
                <div class="form-check mr-3 d-inline">
                  <label class="form-check-label" for="check8">
                    <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check8" name="option1"
                      value="uncontactable" (change)="onStatusChange($event.target.value)" />Uncontactable
                  </label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="showdeferredintake">
              <div class="padding-width">
                <select class="form-control" name="DIyear" id="DIyear" [(ngModel)]="DIyear"
                  (change)="onDIYearChange($event.target.value)">
                  <option [ngValue]="null" [disabled]="true">Select Enrollment Year</option>
                  <option value="{{item}}" *ngFor="let item of DIyearArray">{{item}}</option>
                </select>
              </div>
              <div class="padding-width">
                <select class="form-control" name="DIterms" id="DIterms" [(ngModel)]="DIterms"
                  (change)="onDIItermsChange($event.target.value)">
                  <option [ngValue]="null" [disabled]="true">Select Enrollment Term</option>
                  <option value="G1">G1</option>
                  <option value="G2">G2</option>
                  <option value="G3">G3</option>
                  <option value="G4">G4</option>
                  <option value="G5">G5</option>
                  <option value="G6">G6</option>
                </select>
              </div>
            </div>
            <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 15px">
              Please select any option above!
            </span>
            <div class="row custom-row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="commentText" class="header-md pt-3 pb-2" style="font-size: 14px">Comments</label>
                  <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <div class="mt-3">
                <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
                  Reset
                </button>
                <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Lead New -->
<ng-template #editLead2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="background: #fff">
    <form class="pt-2" [formGroup]="EnquiryService.editLead" (ngSubmit)="updateLead()">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>First Name</label>
          <input class="form-control" name="FirstName" formControlName="FirstName" [(ngModel)]="leadtoEdit.FirstName" />
        </div>
        <div class="form-group col-md-4">
          <label>Last Name</label>
          <input class="form-control" name="LastName" formControlName="LastName" [(ngModel)]="leadtoEdit.LastName" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Email</label>
          <input type="text" name="Email" class="form-control" formControlName="Email" [(ngModel)]="leadtoEdit.Email" />
        </div>

        <div class="form-group col-md-4">
          <label>Phone Number</label>
          <div class="form-row">
            <div class="col-4">
              <input class="form-control" name="CountryCode" formControlName="CountryCode"
                [(ngModel)]="leadtoEdit.CountryCode" />
            </div>
            <div class="col-8">
              <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                [(ngModel)]="leadtoEdit.PhoneNumber" />

            </div>
          </div>
        </div>
      </div>

      <div class="form-row">

        <div class="form-group col-md-4">
          <label for="">Address </label>
          <input type="text" name="Address" class="form-control" formControlName="Address"
            [(ngModel)]="leadtoEdit.Address" />
        </div>

        <div class="form-group col-md-4">
          <label for="">City </label>
          <input type="text" name="City" class="form-control" formControlName="City" [(ngModel)]="leadtoEdit.City" />
        </div>

        <div class="form-group col-md-4">
          <label for="">State </label>
          <input type="text" name="State" class="form-control" formControlName="State" [(ngModel)]="leadtoEdit.State" />
        </div>

      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="">Zip </label>
          <input type="text" name="Zip" class="form-control" formControlName="Zip" [(ngModel)]="leadtoEdit.Zip" />
        </div>

        <div class="form-group col-md-4">
          <label for="">Country </label>
          <input type="text" name="Country" class="form-control" formControlName="Country"
            [(ngModel)]="leadtoEdit.Country" />
        </div>
      </div>




      <div class="form-row">
        <!-- Radio button  -->



      </div>
      <div class="d-flex justify-content-end">
        <div class="mt-3">
          <button class="btn btn-primary btn-sm" type="submit">Update</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>