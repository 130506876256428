import { LayoutService } from "./../layout.service";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { SocketService } from "src/app/socket.service";
import { NotificationsService } from "src/app/notification.service";
import { UhLeadsService } from "src/app/pages/uhleads.service";
import { ItemsList } from "@ng-select/ng-select/lib/items-list";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { RoleService } from "src/app/pages/role-management/role.service";
import { UhubDashboardService } from "src/app/pages/uhub-dashboard/uhub-dashboard.service";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class NotificationComponent implements OnInit {
  userData: any;
  chatCount: number = 0;
  totalCount: number = 0;
  currentPage: number = 1;
  notificationList: any = [];
  savedNotifications: any;
  unreadCount: number = 0;
  MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  totalNotifications: any;
  projectId: any;

  constructor(
    private layoutService: LayoutService,
    private socketService: SocketService,
    private handleError: ErrorHandlerService,
    private notificationService: NotificationsService,
    private uhleadsService: UhLeadsService,
    private companyService: RoleService,
    private uhubleadsServices: UhubDashboardService
  ) { }

  ngOnInit() {
    this.currentPage = 1;
    this.socketService.chatStatus.subscribe((data) => {
      if (
        data["userName"].includes(this.userData["workerFriendlyName"]) ||
        data["workerSid"] === this.userData["workerSId"]
      ) {
        this.getChatCount();
      }
    });
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.getNotifications(1);

    this.getChatCount();
    this.getSavedNotifications();
    // this.notificationService.currentPage.subscribe((data) => {
    //   if (data) {
    //     this.notificationList = [];
    //     this.currentPage = data.page;
    //     this.getNotifications(this.currentPage);
    //     this.getSavedNotifications();
    //   }
    // });
    this.companyService.projectAdmin(this.userData["companyId"]).subscribe(
      (projects) => {
        this.projectId = projects.filter(
          (x) => x.Name == "University Hub"
        )[0].id;
        this.getNewNotification();
      });
      this.uhubleadsServices.unreadCountRecObservable.subscribe((data) => {
        this.getNewNotification();
      })
  }

  getChatCount() {
    // this.layoutService
    //   .ActiveChatCount({ sid: this.userData["workerSId"] })
    //   .subscribe((data) => {
    //     this.chatCount = data["OpenChats"];
    //     this.totalCount = this.chatCount;
    //     this.layoutService.notificationCount.next(this.totalCount);
    //   });
  }
  openChat() {
    this.layoutService.openChat.next(true);
  }
  closeChat() {
    this.layoutService.openChat.next(false);
  }

  getNotifications(page) {
    let payload = {
      email: this.userData.mail,
      currentPage: page,
      Platform: "CRM",
      token: this.userData.token,
    };
    this.notificationService
      .getAllNotifications(payload)
      .subscribe((res: any) => {
        this.totalNotifications = res.totalNotifications;
        this.notificationList = this.notificationList.concat(
          res.MyFetchNotifications
        );
        this.unreadCount = this.notificationList.filter(function (el) {
          return el.Read == false;
        }).length;
        this.notificationService.unreadCount.next(this.unreadCount);
        for (let i = 0; i < this.notificationList.length; i++) {
          this.notificationList[i].age = this.timeAgo(
            this.notificationList[i].updatedAt
          );
          this.getSavedNotifications();
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getSavedNotifications() {
    this.notificationService
      .getSavedNotifications(this.userData.mail, "CRM", this.userData.token)
      .subscribe((res: any) => {
        this.savedNotifications = res.savedNotifications;
        for (let i = 0; i < this.savedNotifications.length; i++) {
          this.savedNotifications[i].age = this.timeAgo(this.savedNotifications[i].updatedAt)
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  loadMore() {
    this.currentPage = this.currentPage + 1;
    this.getNotifications(this.currentPage);
  }

  view(item, index,type) {
    switch (type) {
      case 'Recruiter':
        this.uhubleadsServices.viewReceivedLeadBehaviorSubject.next(item.GUID);
        // this.getNewNotification();
        break;
    
      default:
        this.uhleadsService.viewReceivedLead.next(item.LeadId);
        this.read(item, index);
        break;
    }
  }
  view2(item) {
    this.uhleadsService.viewReceivedLead.next(item.LeadId);
    // this.read(item, index);
  }

  deleteNotification(item) {
    this.notificationService
      .deleteNotification({
        email: this.userData.mail,
        Platform: "CRM",
        token: this.userData.token,
        notificationId: item.id,
      })
      .subscribe((res: any) => {
        if (res.status == 200) {
          var index = this.notificationList.findIndex((x) => x.id == item.id);
          this.notificationList.splice(index, 1);
          this.getSavedNotifications();
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  save(item, value, index) {
    this.notificationService
      .saveNotification({
        email: this.userData.mail,
        Platform: "CRM",
        saveType: value,
        token: this.userData.token,
        notificationId: item.id,
      })
      .subscribe((res: any) => {
        this.notificationList[index].saved = value;

        this.getSavedNotifications();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  unsave(item) {
    this.notificationService
      .saveNotification({
        email: this.userData.mail,
        Platform: "CRM",
        saveType: false,
        token: this.userData.token,
        notificationId: item.id,
      })
      .subscribe((res: any) => {
        this.notificationList = [];
        this.getNotifications(this.currentPage);
        this.getSavedNotifications();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  read(item, index) {
    this.notificationList[index].Read = true;
    this.notificationService
      .readNotification({
        userId: this.userData.id,
        notificationId: item.id,
        token: this.userData.token,
        Platform: "CRM",
      })
      .subscribe((res: any) => {
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate();
    const month = this.MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${minutes}`;
    }

    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${prefomattedDate} at ${hours}:${minutes}`;
    }

    if (hideYear) {
      // 10. January at 10:20
      return `${day}. ${month} at ${hours}:${minutes}`;
    }

    // 10. January 2017. at 10:20
    return `${day}. ${month} ${year}. at ${hours}:${minutes}`;
  }

  // --- Main function
  timeAgo(dateParam) {
    if (!dateParam) {
      return null;
    }
    const date =
      typeof dateParam === "object" ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    let today: any = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();

    if (seconds < 5) {
      return "now";
    } else if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (seconds < 90) {
      return "about a minute ago";
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (isToday) {
      return this.getFormattedDate(date); // Today at 10:20
    } else if (isYesterday) {
      return this.getFormattedDate(date); // Yesterday at 10:20
    } else if (isThisYear) {
      return this.getFormattedDate(date, false, true); // 10. January at 10:20
    }

    return this.getFormattedDate(date); // 10. January 2017. at 10:20
  }

  getNewNotification() {
    let obj = {
      type: "agent",
      agentemail: this.userData.mail,
      ProjectId: this.projectId,
      Platform: "Recruiter"
    }
    this.notificationService.getNewNotification(obj).subscribe((data: any) => {
      this.notificationList = data.MyFetchNotifications;
    }, (err) => {
      this.handleError.handleError(err)
    })
  }
}
