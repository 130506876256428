<div class="gbg p-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="p-2 float-right">
                Filter
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">Upcoming Prospects </div>
                        <div class="py-3 mt-1">
                                <img src="../../../../assets/img/call-completed.svg" class="img32 mr-2 float-left" />
                             <div class="ht mt-1 float-left" style="color:#FA7B52">235</div>
                             
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">Demo Scheduled </div>
                        <div class="py-3 mt-1">
                                <img src="../../../../assets/img/call-schedule.svg" class="img32 mr-2 float-left" />
                             <div class="ht mt-1 float-left" style="color:#163252">235</div>
                             
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">New Prospects</div>
                        <div class="py-3 mt-1">
                                <img src="../../../../assets/img/queqe.svg" class="img32 mr-2 float-left" />
                             <div class="ht mt-1 float-left" style="color:#FF9D36">235</div>
                             
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="db-block">
                    <div class="p-3">
                        <div class="head">Average Prospect Time</div>
                        <div class="py-3 mt-1">
                                <img src="../../../../assets/img/timer.svg" class="img32 mr-2 float-left" />
                             <div class="ht mt-1 float-left" style="color:#CC66CC">58 sec</div>
                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Sales Summary</div>
                        <div class="row p-2 mt-3">
                            <div class="col-md-4 d-flex mb-4">
                               <div>
                                   <img src="../../../../assets/img/universityhub.jpeg" class="img50"  />
                                </div>
                                <div class="ml-2">
                                    <div class="ht">126</div>
                                    <p>Leads </p>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex mb-4">
                                <div>
                                    <img src="../../../../assets/img/facebook-c.svg" class="img50"  />
                                 </div>
                                 <div class="ml-2">
                                    <div class="ht">87</div>
                                    <p>Leads </p>
                                 </div>
                             </div>
                             <div class="col-md-4 d-flex mb-4">
                                <div>
                                    <img src="../../../../assets/img/university.svg" class="img50"  />
                                 </div>
                                 <div class="ml-2">
                                    <div class="ht">96</div>
                                    <p>Leads</p>
                                 </div>
                             </div> 
                             <div class="col-md-4 d-flex">
                                <div>
                                    <img src="../../../../assets/img/bulb.svg" class="img50"  />
                                 </div>
                                 <div class="ml-2">
                                    <div class="ht">42</div>
                                    <p>Direct Leads </p>
                                 </div>
                             </div> 
                        </div> 
                         
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head"> Total Amount of Sales</div>
                        <div class="py-3 mt-1 text-center">
                            <img src="../../../../assets/img/dummy-call-summary.png" class="" />
                    </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="row mb-3">
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Ranking</div>
                        <div class="py-1 mt-1 text-center">
                            <table class="table">
                                <tr>
                                    <td class="vm" width="10px"  style="border-top:0; font-weight: bold;">1</td>
                                    <td width="30px" style="border-top:0;font-weight: bold;" >
                                        <img src="../../../../assets/img/u-profile.svg" class="img24" />
                                    </td>
                                    <td class="text-left vm" style="border-top:0;font-weight: bold;" >Krishna jakkamsett</td>
                                    <td width="50px" style="border-top:0;font-weight: bold;" >235</td>
                                </tr>
                                <tr>
                                    <td class="vm">2</td>
                                    <td class="vm"><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left vm">Krishna jakkamsett</td>
                                    <td class="vm">235</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left">Krishna jakkamsett</td>
                                    <td>235</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left">Krishna jakkamsett</td>
                                    <td>235</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td><img src="../../../../assets/img/u-profile.svg" class="img24" /></td>
                                    <td class="text-left">Krishna jakkamsett</td>
                                    <td>235</td>
                                </tr>
                            </table>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Inbound Close Rate</div>
                        <div class="py-3 mt-1">
                                <img src="../../../../assets/img/dummy-transfer-calls.png" />
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                        <div class="head">Transferred calls</div>
                        <div class="py-3 mt-1">
                                <img src="../../../../assets/img/dummy-transfer-calls.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="db-block h280">
                    <div class="p-3">
                         
                    </div>
                </div>
            </div>
        </div>
<p>agent-calls-dashboard works!</p>
</div>