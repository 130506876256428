import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientService } from "../httpclient.service";
import { BehaviorSubject, Observable } from "rxjs";
import { io } from 'socket.io-client';
import { UhLeadsService } from "./uhleads.service";

export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = "^[a-zA-Z /'.-]*$",
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}
@Injectable({
  providedIn: "root",
})
export class FreJunService {
  baseApiUrl = environment.baseApiUrl2;
  baseApiUrl2 = environment.SMSapiurl;
  freJunURL = environment.freJunURL;
  userData = JSON.parse(`${localStorage.getItem("userData")}`);
  twilioApiUrl: any = environment.twilioApiUrl;
  ActivityData: any;
  socket: any;
  callInitiate = new BehaviorSubject<any>(null);
  callAnswered = new BehaviorSubject<any>(null);
  callDisconnected = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private UuhLeadsService: UhLeadsService,

  ) { }

  createCall<T>(payload): Observable<any> {
    this.userData = JSON.parse(localStorage.getItem("userData"));

    let customHeaders = {
      headers: new HttpHeaders(
        { 'x-auth-token': 'asseccque', "Access-Control-Allow-Origin": "*" }
      )
    };
    if (this.userData) {
      customHeaders = {
        headers: new HttpHeaders(
          { 'x-auth-token': 'asseccque', "Access-Control-Allow-Origin": "*", 'Authentication': 'Bearer ' + this.userData.token }
        )
      }
    }
    return this.http.post<T>(this.baseApiUrl + "createCall", payload, customHeaders);
  }
  createCallLog<T>(payload): Observable<any> {
    this.userData = JSON.parse(localStorage.getItem("userData"));

    let customHeaders = {
      headers: new HttpHeaders(
        { 'x-auth-token': 'asseccque', "Access-Control-Allow-Origin": "*" }
      )
    };
    if (this.userData) {
      customHeaders = {
        headers: new HttpHeaders(
          { 'x-auth-token': 'asseccque', "Access-Control-Allow-Origin": "*", 'Authentication': 'Bearer ' + this.userData.token }
        )
      }
    }
    return this.http.post<T>(this.baseApiUrl + "createCallLog", payload, customHeaders);
  }

  connect() {
    this.socket = io(environment.socketUrl2, { transports: ['websocket'], query: { userId: 10 } });
    this.socket.on('connect', (data) => {
      console.log("************************************************************************************")
      console.log("connected", data)
    });


    
  }
  sendMesage() {
    this.socket.emit('message', 'Test Message');
  }
  disConnect() {
    this.socket.emit('disconnect', '')
  }
}
