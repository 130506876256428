import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MomentFormatService } from "src/app/moment-format-service";
import { SharedService } from "src/app/shared/shared.service";
import { EnquiryDashboardService } from "./enquiry.service";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { UhLeadsService } from "../uhleads.service";
import { ReportsService } from "../reports/reports.service";
import { RoleService } from "../role-management/role.service";
import { DOCUMENT } from "@angular/common";
import { LayoutService } from "src/app/layout/layout.service";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
var sortJsonArray = require("node_modules/sort-json-array");

@Component({
  selector: "app-enquiry",
  templateUrl: "./enquiry.component.html",
  styleUrls: ["./enquiry.component.css"],
})
export class EnquiryComponent implements OnInit {
  endDate: any;
  startDate: any;
  date1: any;
  date2: any;
  userData: any;
  leadType = "total";
  pageNo = 1;
  totalPages: number;
  topBarCounts: any;
  leadsTable: any;
  projectLists: any;
  sideViewerData: any = { Activities: [], CallDetails: [] };
  audio = new Audio();
  audioStatus: boolean = false;
  tab: string;
  dialerScreen1 = "dialer";
  callStatus: string = "Calling..";
  newStatus: any;
  public callData: any[] = [];
  selectedLead: any;
  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };
  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;
  referralList: any[] = [];
  public startIndex: number = 0;
  startCall: boolean = false;
  commentText: string = "";
  testConfig: any;
  agentCall: String = "";
  disabled: any;
  public statusValue = "pending";
  selectedAll: boolean = false;
  leadIds = [];
  sortColumn = "updatedAt";
  sortOrder = "des";
  showDate: boolean = false;
  public filterValue = "all";
  direction: string = "inbound";
  isLoading: boolean = true;
  noRecords: boolean = false;

  @ViewChild("closeButton2") closeButton2;
  @ViewChild("sideNav", { static: true }) sideNav;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  @ViewChild("totalRecords", { static: true }) totalRecords: ElementRef;

  leadsData: any;
  currentLeadId: any;
  smsList: [] = [];
  smsText: string;
  projectId: any;
  leadProjectId: any;

  DIyear: any;
  DIterms: any;
  showdeferredintake: Boolean = false;
  DIyearArray: any = [];
  callerProjectId: any;
  callSid: any;
  leadtoEdit: {
    id: string;
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    Address: string;
    City: string;
    State: string;
    Zip: string;
    Country: string;
    AgentMail: string;
  };
  editComment: string;
  editStatus: string;
  public edited: boolean = false;
  public search: string;
  pageNumber: Number = 1;

  constructor(
    public EnquiryService: EnquiryDashboardService,
    public uhleadsService: UhLeadsService,
    public SharedService: SharedService,
    public momentService: MomentFormatService,
    private reportService: ReportsService,
    private companyService: RoleService,
    public layoutService: LayoutService,
    private handleError: ErrorHandlerService,
    private uhleadsservice: UhLeadsService,
    private readonly modalService: NgbModal,
    @Inject(DOCUMENT) private domService: HTMLDocument,
    public countupTimerService: CountupTimerService
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }

  ngOnInit() {
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    this.SharedService.pageEvent.emit({
      pageName: "Enquiries",
    });

    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.getCounts();
    this.companyService.projectAdmin(environment.companyId).subscribe((res: any) => {
      this.projectLists = res;
    });

    this.uhleadsService.pageNumber.subscribe((pageNo: any) => {
      if (pageNo) {
        this.pageNo = pageNo;
        this.getLeads();
      }
    });
    this.EnquiryService.startTimer.subscribe((res) => {
      this.callStatus = "inProgress";
      this.startTimer();
    });
    //incoming sms check
    this.uhleadsservice.incomingSms.subscribe((from: any) => {
      if (from.from) {
        this.uhleadsservice
          .getSms(this.currentLeadId)
          .subscribe((smsList: any) => { });
      }
    });

    let d = new Date();
    this.DIyearArray.push(d.getFullYear());
    let year = d.getFullYear();
    for (let i = 0; i < 4; i++) {
      year = year + 1;
      this.DIyearArray.push(year);
    }
    //server side search
    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.search = this.input.nativeElement.value;
          this.pageNo = 1;
          // 
          // 
          this.searchLeads(this.input.nativeElement.value, this.pageNo);
        })
      )
      .subscribe();
    this.EnquiryService.getCallSid.subscribe((data) => {
      this.callSid = data;
    });
    this.EnquiryService.callStatus.subscribe((status) => {
      this.callStatus = status;
    });
  }

  dropdownSelect(event) {
    this.onClick(null);
    this.leadType = event;
    this.getCounts();
    this.uhleadsservice.pageNumber.next(1);
    this.EnquiryService.getCountByCallStatus({
      status: event,
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
    }).subscribe((data: any) => {

      this.totalPages = data.count;
      this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
      this.uhleadsService.totalPages.next(this.totalPages);
    });
  }

  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }

    this.leadType = "total";
    this.getCounts();
    this.getLeads();
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  //Date Filter
  filterData() {
    this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsService.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.getCounts();
      this.leadType = "total";
      this.getLeads();
      this.showDate = false;
    }
  }

  getLeads() {
    this.isLoading = true;
    this.noRecords = false;
    let payload = {
      start: this.startDate,
      end: this.endDate,
      leadType: this.leadType,
      page: this.pageNo,
    };

    // 
    this.EnquiryService.getLeads(payload).subscribe((res: any) => {
      // 
      this.leadsData = res;
      this.isLoading = false;
      if (this.leadsData.length > 0) {
        this.noRecords = false;
      } else {
        this.noRecords = true;
      }
      for (let i = 0; i < this.leadsData.length; i++) {
        for (let j = 0; j < this.projectLists.length; j++) {
          if (this.leadsData[i].ProjectId == this.projectLists[j].id) {
            this.leadsData[i].projectName = this.projectLists[j].Name;
            this.leadsData[i].To = this.projectLists[j].TwilioNumber;
          }
        }
      }
      this.sortTableData(this.sortColumn, this.sortOrder);
    }, (error: any) => {
      this.isLoading = false;
      this.noRecords = true;
    });
  }

  getCounts() {
    let payload = {
      start: this.startDate,
      end: this.endDate,
    };
    this.EnquiryService.getCounts(payload).subscribe((resp: any) => {
      this.totalPages = 1;

      if (this.leadType == "total") {
        this.totalPages = resp.TopBarCount.total;
      } else if (this.leadType == "assigned") {
        this.totalPages = resp.TopBarCount.assigned;
      } else if (this.leadType == "assignedtoMe") {
        this.totalPages = resp.TopBarCount.assignedtoMe;
      } else if (this.leadType == "completedDocs") {
        this.totalPages = resp.TopBarCount.completedDocs;
      } else if (this.leadType == "documentsSubmitted") {
        this.totalPages = resp.TopBarCount.documentsSubmitted;
      } else if (this.leadType == "pendingDocs") {
        this.totalPages = resp.TopBarCount.pendingDocs;
      } else if (this.leadType == "reopen") {
        this.totalPages = resp.TopBarCount.reopen;
      } else if (this.leadType == "revalidatedDocs") {
        this.totalPages = resp.TopBarCount.revalidatedDocs;
      } else if (this.leadType == "studentDenied") {
        this.totalPages = resp.TopBarCount.studentDenied;
      } else if (this.leadType == "unassigned") {
        this.totalPages = resp.TopBarCount.unassigned;
      } else if (this.leadType == "uncontactable") {
        this.totalPages = resp.TopBarCount.uncontactable;
      } else if (this.leadType == "validatedDocs") {
        this.totalPages = resp.TopBarCount.validatedDocs;
      } else if (this.leadType == "pending") {
        this.totalPages = resp.TopBarCount.pending;
      } else if (this.leadType == "called") {
        this.totalPages = resp.TopBarCount.called;
      } else if (this.leadType == "callback") {
        this.totalPages = resp.TopBarCount.callback;
      } else if (this.leadType == "callfailed") {
        this.totalPages = resp.TopBarCount.callfailed;
      } else if (this.leadType == "notinterested") {
        this.totalPages = resp.TopBarCount.notinterested;
      } else if (this.leadType == "universityhub") {
        this.totalPages = resp.TopBarCount.universityhub;
      } else if (this.leadType == "buf") {
        this.totalPages = resp.TopBarCount.buf;
      } else if (this.leadType == "campbellsville") {
        this.totalPages = resp.TopBarCount.campbellsville;
      } else if (this.leadType == "referrals") {
        this.totalPages = resp.TopBarCount.referrals;
      } else if (this.leadType == "informed") {
        this.totalPages = resp.TopBarCount.informed;
      } else if (this.leadType == "wrongnumber") {
        this.totalPages = resp.TopBarCount.wrongnumber;
      } else if (this.leadType == "notanswered") {
        this.totalPages = resp.TopBarCount.notanswered;
      } else if (this.leadType == "deferredintake") {
        this.totalPages = resp.TopBarCount.deferredintake;
      } else if (this.leadType == "uncontactable") {
        this.totalPages = resp.TopBarCount.uncontactable;
      }

      this.totalPages = ~~(Number(this.totalPages) / 12) + 1;
      this.uhleadsService.totalPages.next(this.totalPages);

      this.topBarCounts = resp.TopBarCount;
      // 
      // this.getLeads();
    });
  }

  showTotalApplications() {
    this.leadType = "total";
    this.getCounts();
    this.getLeads();
  }

  showCampbellsvilleApplications() {
    this.leadType = "campbellsville";
    this.getCounts();
    this.getLeads();
  }

  showUniversityHubApplications() {
    this.leadType = "universityhub";
    this.getCounts();
    this.getLeads();
  }

  showBufApplications() {
    this.leadType = "buf";
    this.getCounts();
    this.getLeads();
  }

  showReferralApplications() {
    this.leadType = "referrals";
    this.getCounts();
    this.getLeads();
  }

  sideNavData(data) {
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    //  x.style.display = none;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    var y = document.getElementsByClassName(
      "sideNav"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < y.length; i++) {
      y[i].style.display = "";
    }

    this.currentLeadId = data.id;
    this.leadProjectId = data.ProjectId;
    // 
    this.EnquiryService.getLeadById(data.id, data.ProjectId).subscribe(
      (res: any) => {
        // 
        this.sideViewerData = res;
        this.smsList = res.SMSDetails;
        for (let i = 0; i < this.projectLists.length; i++) {
          if (this.sideViewerData.ProjectId == this.projectLists[i].id) {
            this.sideViewerData.ProjectName = this.projectLists[i].Name;
          }
        }
        this.replaceNull(this.sideViewerData);
      }
    );
  }

  viewLead(id) {
    this.sideNav.toggle();
    this.sideNavData({ id: id });
  }

  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let fromNumber = "";
    this.companyService.projectAdmin(environment.companyId).subscribe((res: any) => {
      this.projectLists = res;
      for (let i = 0; i < res.length; i++) {
        if (res[i].id == this.leadProjectId) {
          fromNumber = res[i].TwilioNumber;
        }
      }
      let payload = {
        text: this.smsText,
        from: fromNumber,
        to: "+" + item.CountryCode + item.PhoneNumber,
        AgentMail: this.userData.mail,
      };
      this.EnquiryService.sendSms(payload, this.leadProjectId).subscribe(
        (res: any) => {
          this.uhleadsservice.showInfo("Message successfully sent.", "Sent");
          this.EnquiryService.getLeadById(item.id, item.ProjectId).subscribe(
            (res: any) => {
              this.smsList = res.SMSDetails;
              (<HTMLInputElement>document.getElementById(HTMLid)).value = "";
            }
          );
        }
      );
    });
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
    });
  }

  playAudio(data) {
    // let audio = new Audio();
    if (this.audioStatus == false) {
      this.audio.src = data.RecordingURL;
      this.audio.pause();
      this.audio.currentTime = 0;
      // this.audio.load();
      this.audio.play();
      this.audioStatus = true;
    } else if (this.audioStatus == true) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audioStatus = false;
    }
  }
  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }

  pauseAudio(data) {
    // audio.stop();
  }

  onClick(check) {
    this.uhleadsservice.pageNumber.next(1);
    if (check == 1) {
      this.tab = "tab1";
    } else if (check == 2) {
      this.tab = "tab2";
    } else if (check == 3) {
      this.tab = "tab3";
    } else if (check == 4) {
      this.tab = "tab4";
    } else if (check == 5) {
      this.tab = "tab5";
    } else if (check == 6) {
      this.tab = "tab6";
    } else if (check == 7) {
      this.tab = "tab7";
    } else if (check == 8) {
      this.tab = "tab8";
    } else if (check == 9) {
      this.tab = "tab9";
    } else if (check == 10) {
      this.tab = "tab10";
    } else if (check == 11) {
      this.tab = "tab11";
    } else if (check == 12) {
      this.tab = "tab12";
    } else if (check == 13) {
      this.tab = "tab13";
    } else {
      this.tab = null;
    }
  }

  startCallListener() {
    this.direction = "outbound";
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";

    this.callData = this.leadsTable;

    if (this.callData.length === 0) {
      this.startCall = false;
      return;
    }

    if (this.statusValue === "pending") {
      this.startIndex = 0;
    }
    this.marketingId = {
      id: this.callData[this.startIndex]["id"],
      status: this.callData[this.startIndex]["CallStatus"],
      name: this.callData[this.startIndex]["FirstName"],
      email: this.callData[this.startIndex]["Email"],
      phoneNumber:
        "+" +
        this.callData[this.startIndex]["CountryCode"] +
        this.callData[this.startIndex]["PhoneNumber"],
      // phoneNumber: "+919848311098",
      module: this.callData[this.startIndex]["Module"],
      city: this.callData[this.startIndex]["City"],
      nextCaller: this.callData.length > 1 ? 1 : 0,
      lead: this.callData[this.startIndex],
    };
    if (this.leadsTable.length > 1) {
      var nextFirstname = this.callData[this.startIndex + 1]?.FirstName
        ? this.callData[this.startIndex + 1]?.FirstName
        : "";
      var nextLastname = this.callData[this.startIndex + 1]?.LastName
        ? this.callData[this.startIndex + 1]?.LastName
        : "";
      var nextCallerCity = this.callData[this.startIndex + 1]?.City
        ? this.callData[this.startIndex + 1]?.City
        : "";
      var nextCallerCountryCode = this.callData[this.startIndex + 1]
        ?.CountryCode
        ? this.callData[this.startIndex + 1]?.CountryCode.replace("+", "")
        : "";
      var nextCallerPhoneNumber = this.callData[this.startIndex + 1]
        ?.PhoneNumber
        ? this.callData[this.startIndex + 1]?.PhoneNumber
        : "";

      this.nextCallerName = nextFirstname;
      this.nextCallerCity = nextCallerCity;
      this.nextCallerNumber = nextCallerCountryCode
        ? "+" + nextCallerCountryCode + nextCallerPhoneNumber
        : "";
      this.startIndex = this.startIndex + 1;
    }
    this.EnquiryService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
      ProjectId: this.callData[this.startIndex].ProjectId,
    });

    this.startCall = true;
  }

  startCallListener2(data, index) {
    this.direction = "outbound";
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;
    this.callerProjectId = data.ProjectId;
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);

    this.newStatus = null;

    this.callData[0] = data;
    this.selectedLead = data;

    this.marketingId = {
      id: data.id,
      status: data.CallStatus,
      name: data.FirstName,
      email: data.Email,
      phoneNumber: "+" + data.CountryCode + data.PhoneNumber,
      // phoneNumber: "+919848311098",
      module: data.Module,
      city: "",
      nextCaller: this.leadsTable.length > 1 ? 1 : 0,
      lead: 0,
    };
    if (this.leadsTable.length > 1) {
      var nextFirstname = this.referralList[index + 1]?.FirstName
        ? this.referralList[index + 1]?.FirstName
        : "";
      var nextLastname = this.referralList[index + 1]?.LastName
        ? this.referralList[index + 1]?.LastName
        : "";
      var nextCallerCity = this.referralList[index + 1]?.City
        ? this.referralList[index + 1]?.City
        : "";
      var nextCallerCountryCode = this.referralList[index + 1]?.CountryCode
        ? this.referralList[index + 1]?.CountryCode.replace("+", "")
        : "";
      var nextCallerPhoneNumber = this.referralList[index + 1]?.PhoneNumber
        ? this.referralList[index + 1]?.PhoneNumber
        : "";

      this.nextCallerName = nextFirstname;
      this.nextCallerCity = nextCallerCity;
      this.nextCallerNumber = nextCallerCountryCode
        ? "+" + nextCallerCountryCode + nextCallerPhoneNumber
        : "";
      this.startIndex = index + 1;
    }

    this.EnquiryService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
      ProjectId: data.ProjectId,
    });
    this.startCall = true;
  }

  resetCallingScreen() {
    this.commentText = "";
    this.newStatus = null;
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;
  }

  updateComments() {
    if (!this.newStatus) {
    } else {
      this.EnquiryService.updateComments({
        id: this.selectedLead.id,
        comments: this.commentText,
        followupDate: null,
        callStatus: this.newStatus,
        CallSid: this.callSid,
        diyear: this.DIyear,
        diterms: this.DIterms,
        ProjectId: this.callerProjectId,
      }).subscribe(
        (res) => {
          this.showdeferredintake = false;
          this.DIyear = null;
          this.DIterms = null;
          this.uhleadsService.showSuccess("Comment added!", "Success!");
        },
        (error) => {
          this.uhleadsService.showSuccess("Something went wrong!", "Error!");
          this.handleError.handleError(error);
        }
      );
      this.commentText = "";
      this.newStatus = null;
      this.closeButton2.nativeElement.click();
    }
    this.closeButton2.nativeElement.click();
  }

  onStatusChange(data) {
    this.newStatus = data;
    if (this.newStatus == "deferredintake") {
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
  }

  callNext() { }

  stopCurrentCall() {
    this.EnquiryService.startCall.next(null);
    this.EnquiryService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = "Call Ended";
  }

  conferenceCall(value: any) {
    this.EnquiryService.conferenceCall.next({ value });
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.leadsTable.length; i++) {
        this.leadIds.push(this.leadsTable[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }
  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  sortData(arr, value) {
    return arr.sort((a, b) => {
      return a[value] - b[value];
      //return a[value] < b[value] ? -1 : a[value] > b[value] ? 1: 0
    });
  }
  toggleSort(column, order) {
    if (this.sortOrder == "asc") {
      this.sortOrder = "des";
    } else {
      this.sortOrder = "asc";
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {
    this.sortColumn = column;
    this.leadsTable = sortJsonArray(this.leadsData, column, this.sortOrder);
  }
  onDIItermsChange(data) {
    // 
  }
  onDIYearChange(data) {
    // 
  }

  openEditLead(content, lead) {
    this.editStatus = lead.CallStatus;

    this.leadtoEdit = {
      id: lead.id,
      FirstName: lead.FirstName,
      LastName: lead.LastName,
      CountryCode: "+" + lead.CountryCode,
      PhoneNumber: lead.PhoneNumber,
      Email: lead.Email,
      Address: lead.Address,
      City: lead.City,
      State: lead.State,
      Zip: lead.Zip,
      Country: lead.Country,
      AgentMail: this.userData.mail,
    };

    this.modalService.open(content, { size: "lg" });
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  updateLead() {
    this.edited = true;
    if (this.EnquiryService.editLead.invalid) {
      return;
    }
    this.leadtoEdit.CountryCode = this.replaceAll(
      this.leadtoEdit.CountryCode,
      "+",
      ""
    );
    if (this.leadtoEdit.CountryCode == "-") {
      this.leadtoEdit.CountryCode = "00";
    }
    if (this.leadtoEdit.PhoneNumber == "-") {
      this.leadtoEdit.PhoneNumber = "0000000000";
    }
    var keys = Object.keys(this.leadtoEdit);
    keys.forEach((a) => {
      if (
        this.leadtoEdit[a] === "-" ||
        this.leadtoEdit[a] === "" ||
        this.leadtoEdit[a] === undefined
      ) {
        this.leadtoEdit[a] = null;
      }
    });

    this.EnquiryService.updateLead(this.leadtoEdit).subscribe(
      (res: any) => {
        this.uhleadsservice.showSuccess("Student details updated", "Success!");
        this.edited = false;
        this.getLeads();

        document.getElementById("closeButton3").click();
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  onInput(event: any) {
    let payload = {
      admin: true,
      ProjectId: this.projectId,
      start: "2021-08-02 09:40:25+00",
      end: "2022-08-03 09:40:25+00",
      page: 1,
      leadType: "All",
      mail: "All",
      callStatus: "All",
      course: "All",
      enrollmentYear: "All",
      enrollmentTerm: "All",
      export: false,
      search: this.search,
    };
    if (this.search != "") {
    } else if (this.search == "") {
      this.totalRecords.nativeElement.click();
    }
  }

  searchLeads(value, page) {
    let payload = {
      admin: true,
      ProjectId: this.projectId,
      start: "2021-08-02 09:40:25+00",
      end: "2022-08-03 09:40:25+00",
      page: page,
      leadType: "All",
      mail: "All",
      callStatus: "All",
      course: "All",
      enrollmentYear: "All",
      enrollmentTerm: "All",
      export: false,
      search: value,
    };

    if (value.length > 0) {
      this.leadType = "filter";
      this.EnquiryService.GetSearchEnquiryLeads(payload).subscribe(
        (res: any) => {
          // 
          this.leadsTable = res.data;

          let newTotal = ~~(Number(res.totalResults) / 12) + 1;
          this.uhleadsservice.totalPages.next(Number(newTotal));
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    } else {
      this.leadType = "all";
      this.getCounts();
      this.getLeads();
    }
  }

  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }
}
