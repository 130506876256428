import { TwilioSocketService } from "./../../twilio-socket.service";
import { AuthService } from "src/app/account/auth/auth.service";
import * as moment from "moment";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
} from "@angular/core";
import { SharedService } from "src/app/shared/shared.service";
import { LayoutService } from "../layout.service";
import { Router } from "@angular/router";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import CallLogs from "../models/calllogs-model";
import { RoleService } from "src/app/pages/role-management/role.service";
import { CreateAgent } from "src/app/pages/create-agent/create-agent.service";
import {
  ModalDismissReasons,
  NgbModal,
  NgbPopover,
  NgbPopoverConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { NgTemplateOutlet } from "@angular/common";
import {
  CreateAutoEmail,
  InputCallData,
  InputSMSData,
  MarketingService,
} from "src/app/pages/marketing/marketing.service";
import { ProjectService } from "src/app/pages/project-management/project.service";
import { AbstractControl, Validators } from "@angular/forms";
import { UhLeadsService } from "src/app/pages/uhleads.service";
import { FreJunService } from "src/app/pages/frejun.service";

import { getMessaging, onMessage } from "firebase/messaging";
import { NotificationsService } from "src/app/notification.service";
import { SettingsComponent } from "src/components/settings/settings.component";
import { MatDialog } from "@angular/material/dialog";
import { ReferralService } from "src/app/pages/referral/referral.service";
import { ReferreeService } from "src/app/pages/referree/referree.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TransfersService } from "src/app/pages/trasnfers/transfers.service";
import { IncomingCallsComponent } from "./components/incoming-calls/incoming-calls.component";
import { IncomingCallsService } from "./components/incoming-calls/incoming-calls.service";
import { EnquiryCallsComponent } from "./components/enquiry-calls/enquiry-calls.component";
import { EnquiryCallsService } from "./components/enquiry-calls/enquiry-calls.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { EnquiryDashboardService } from "src/app/pages/enquiry/enquiry.service";
import { RecruiterService } from "src/app/pages/recruiter/recruiter.service";
import { environment } from "src/environments/environment";
import { UhubDashboardService } from "src/app/pages/uhub-dashboard/uhub-dashboard.service";
import { SocialmedialeadsService } from "src/app/pages/uhub-dashboard/socialmedialeads/socialmedialeads.service";
import { RecruiterleadsService } from "src/app/pages/uhub-dashboard/recruiterleads/recruiterleads.service";
declare const $: any;
declare const Twilio: any;
const countryCodes = require('../../../assets/allcountryDetails.json');
import { Softphone } from "@frejun/softphone-web-sdk";
import { SocketService } from "src/app/socket.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.css"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TopbarComponent implements OnInit, AfterViewInit {
  pageName: any = "";
  workerJS: any = null;
  reservation: any;
  task: any;
  tasks: any[];
  configuration: any;
  worker: any;
  direction: any = null;
  connection: any = null;
  phoneNumber: any = "";
  showDailer: any = false;
  activities: any = [];
  currentStatus: any;
  dailerScreen: any = "nocall";
  transfer: any = {
    workers: [],
    to: null,
    isLoading: false,
  };
  taskQueue: any = [];
  selectedQueue: any = "";
  RolePermission = localStorage.getItem("RolePermission");
  isValue: number = 0;
  isCollapsed: any = true;
  mute: any = false;
  testConfig: any;
  hold: any = false;
  loginmailid: string = "";
  callLogs: CallLogs[] = [];
  snackBarRef: any;
  userData: any;
  isloading: boolean = false;
  unReadMessages: number;
  countries: any[] = [];
  selectedCountryCode = 1;
  audio: any;
  isChat: boolean = false;
  companies: any = [];
  superAdmin: Boolean = false;
  agentCall: String = "";
  isAgentCalling: boolean = false;
  isReservation = false;
  isCallingProgress: boolean = false;
  isOutgoing: boolean = false;
  isIncoming: boolean = false;
  isCallcomming: boolean = false;
  profilePhoto: any = "";
  isNotify: boolean = false;
  notificationCount: number;
  callerIds: any[] = [];
  selectedCallerId: any;
  marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };
  redialContactId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };
  isShownMarketing: boolean = false;
  dialerScreen1 = "dialer";
  comments: string = "";
  callBack: string = "notinterested";
  followUpdate: Date = new Date();
  companyId: any;
  demoScheduled: any;
  moduleConfig: any = {};
  callStopped: boolean = false;
  projects: any[] = [];
  projectId: any;
  @ViewChild("dailer", { static: true, read: ElementRef }) dailer!: ElementRef;
  closeResult: string;
  @ViewChild("content") content: NgTemplateOutlet;
  @ViewChild("contentDocs") contentDocs: NgTemplateOutlet;
  totalAgents: { name: string; value: any; profilePhoto: any; Status: any }[] =
    [];
  apiKey: any;
  isdemoScheduled: boolean = false;
  isCustomerUpdate = false;
  schedulers: boolean = false;
  demoHtml: string;

  submitted: boolean = false;

  updateLeadComments: boolean = false;
  UhubCallSid: any;

  @ViewChild(NgbPopover)
  public popover: NgbPopover;
  disabled: boolean = false;
  isIntrested: boolean = false;
  message: any[] = [];
  callStatus: string = "Calling..";
  currentItem: any;
  freJunLoginRes;
  audioElements;
  softphone = new Softphone();
  currentCompanyData: any;

  constructor(
    // @Inject(LOCALE_ID) private locale: string,
    public sharedService: SharedService,
    public layoutService: LayoutService,
    private snackbar: MatSnackBar,
    private router: Router,
    private companyService: RoleService,
    private agentService: CreateAgent,
    private twilioSocketService: TwilioSocketService,
    public countupTimerService: CountupTimerService,
    private modalService: NgbModal,
    private marketingService: MarketingService,
    public config: NgbPopoverConfig,
    private projectService: ProjectService,
    private readonly snackbarRef: MatSnackBar,
    private uhleadsservice: UhLeadsService,
    private uHubLeadsService: UhubDashboardService,
    private uHubLeadsSocialService: SocialmedialeadsService,
    private uHubLeadsRecruiterService: RecruiterleadsService,
    private referralService: ReferralService,
    private marketingServics: MarketingService,
    private ReferralService: ReferralService,
    private ReferreeService: ReferreeService,
    private TransferService: TransfersService,
    private NotificationService: NotificationsService,
    public dialog: MatDialog,
    private incomingCallService: IncomingCallsService,
    private enquiryCallService: EnquiryCallsService,
    private handleError: ErrorHandlerService,
    private EnquiryService: EnquiryDashboardService,
    private RecruiterService: RecruiterService,
    private freJunService: FreJunService,
    private socketService: SocketService,
  ) {
    config.autoClose = "outside";
    config.placement = "auto";
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":";
    this.testConfig.timerTexts.minuteText = ":";
    this.testConfig.timerTexts.secondsText = " ";
  }

  ngAfterViewInit() {
    // localStorage.setItem('currentStatus', 'Idle')
    this.socketService.connect(this.userData["id"])

  }

  async logout() {

    if (this.configuration) {
      this.toggleIsOnQueue(
        this.configuration.configuration.twilio.workerOfflineActivitySid
      );
    }
    this.agentService
      .updateStatus({
        id: this.userData["id"],
        status: "Offline",
        fcmtoken: this.userData.fcmtoken,
        token: this.userData.token,
      })
      .subscribe((data) => { }, (error) => {
        this.handleError.handleError(error)
      });
    localStorage.clear();
    sessionStorage.clear();
    await this.softphone.logout()
    this.socketService.disConnect()
    this.router.navigate(["/account/login"]);
  }
  toggleIsOnQueue(activitySid) {

    let payload = {
      ActivitySid: activitySid,
    };

    this.workerJS.update(payload, function (error, worker) { });
  }

  complete() {
    if (this.dailerScreen === "callcomming") {
      this.reservation.reject();
    } else {
      this.workerJS.completeTask(this.task.sid, (error, task) => {
        if (error) {
          console.error(error);
          return;
        }
      });
    }
    this.dailerScreen = "nocall";
  }

  async ngOnInit() {
    window.navigator.mediaDevices.getUserMedia({ audio: true }).then(() => { });
    this.followUpdate.setDate(this.followUpdate.getDate() + 2);
    this.companyAdmin();
    this.twiliointialCode();
    this.freJunintialCode();
    this.loginmailid = this.userData.mail;

    this.currentStatus = "Offline";
    this.listen();

    // ==========================================
    this.uHubLeadsService.unreadCountRecObservable.subscribe((data) => {
      // if(data){
      this.isNotify = false;
      this.NotificationService.recruiterUnreadCountAgent({
        agentemail: this.userData.mail,
        ProjectId: this.projectId,
        Platform: 'Recruiter'
      }).subscribe((res: any) => {
        this.notificationCount = res.unreadNotificationCount;
      }, (error) => {
        this.handleError.handleError(error)
      });
      // }
    })

    //=================== Frejun ===============
    let frejonObj = {
      type: "OAuth2.0",
      token: this.userData.freJunToken,
      email: this.userData.mail
    };
    this.freJunLoginRes = await this.softphone.login(frejonObj);
    this.audioElements = {
      remote: document.getElementById("sip-remote-audio"),
      local: document.getElementById("sip-local-audio"),
    }
  }

  companyAdmin() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    if (this.userData["companyId"] !== 0) {
      this.companyId = this.userData["companyId"];
      this.getProjects();
    } else {
      this.companyService.getCompanies().subscribe((data) => {
        this.companies = data;
        this.companyId = data[0]["id"];
        this.basicFucntionCalls();
        // this.getCallNumbers();
        this.companyService.getCompanyById(1).subscribe((data) => {
          if (data["CallerIds"]) {
            this.callerIds = data["CallerIds"];
            this.selectedCallerId = data["CallerIds"][0];
          } else {
            this.callerIds = [data["TwilioNumber"]];
            this.selectedCallerId = data["TwilioNumber"];
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
        this.getProjects();
      }, (error) => {
        this.handleError.handleError(error)
      });
    }

    if (this.userData["ProfilePhoto"]) {
      this.agentService
        .getSignedUrl({ url: this.userData["ProfilePhoto"] })
        .subscribe((data) => {
          this.profilePhoto = data["url"];
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }
  getCallNumbers() {
    if (this.currentCompanyData["CallerIds"]) {
      this.callerIds = this.currentCompanyData["CallerIds"];
      this.selectedCallerId = this.currentCompanyData["CallerIds"][0];
    } else {
      this.callerIds = [this.currentCompanyData["TwilioNumber"]];
      this.selectedCallerId = this.currentCompanyData["TwilioNumber"];
    }
  }
  getProjects() {
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projects = projects;
      if (this.projects.length !== 0) {
        this.projectId = projects[0]["id"];
        this.layoutService.twilioNumber = projects[0]["TwilioNumber"];

        this.layoutService.getRoleAgents(this.projectId);
        this.layoutService.getContacts(this.projectId);

        if (this.userData["companyId"] !== 0) {
          this.projectService.getProject(this.projectId).subscribe((data) => {
            this.currentCompanyData = data;
            this.basicFucntionCalls();
            if (data["CallerIds"]) {
              this.callerIds = data["CallerIds"];
              this.selectedCallerId = data["CallerIds"][0];
            } else {
              this.callerIds = [data["TwilioNumber"]];
              this.selectedCallerId = data["TwilioNumber"];
            }
          }, (error) => {
            this.handleError.handleError(error)
          });
        }
        this.sharedService.projectId.next(projects[0]["id"]);
        this.companyService.fetchRoles(projects[0]["id"]).subscribe((data) => {
          this.taskQueue = data;
        }, (error) => {
          this.handleError.handleError(error)
        });
        this.sharedService.currentProject.next(projects[0]);
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  basicFucntionCalls() {
    this.getAgentNames();
    let value: any = JSON.parse(localStorage.getItem("userData"));
    this.userData = value;
    this.fetchCountries();
    setTimeout(() => {
      this.twilioSocketService.connect();
    }, 1000);
    let notificationProject;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      notificationProject = projects.filter(x => x.Name.includes('University Hub'))[0].id;
    })
    this.NotificationService.recruiterUnreadCountAgent({
      agentemail: this.userData.mail,
      ProjectId: notificationProject,
      Platform: 'Recruiter'
    }).subscribe((res: any) => {
      this.notificationCount = res.unreadNotificationCount;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  twiliointialCode() {
    this.toggle();
    this.layoutService.openChat.subscribe((val) => { this.isChat = val; });
    this.layoutService.toggleSideBar.subscribe((data) => {
      var isClass = $("#mySidebar").hasClass("active");
      if (isClass) {
        $("#mySidebar").removeClass("active");
      }
    });
    this.twilioSocketService.channelJoin.next({ sid: this.userData.workerSId });

    this.layoutService.getCallHistory(this.userData.workerSId).subscribe((data) => {
      this.callLogs = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.sharedService.pageEvent.subscribe((data) => {
      if (data.pageName) {
        this.pageName = data.pageName;
      }
      if (data.togglePage) {
        this.toggle();
      }
    });
    this.agentService.updateStatus({
      id: this.userData["id"],
      status: "Online",
      fcmtoken: this.userData.fcmtoken,
      token: "",
    }).subscribe((data) => { }, (error) => {
      this.handleError.handleError(error)
    });
    this.sharedService.toggleSidebar.subscribe((data) => {
      this.toggle();
    });

    // CU DASHBOARD CALLS
    this.uhleadsservice.startCall.subscribe((data) => {
      if (data) {
        this.callNumber(data.phoneNumber, data.leadData.id);
      }
    });

    this.uhleadsservice.stopCall.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });
    this.uhleadsservice.conferenceCall.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });
    // SFC DASHBOARD CALLS
    this.uhleadsservice.startCallsfc.subscribe((data) => {
      if (data) {
        this.callNumberSFC(data.phoneNumber, data.leadData.id);
      }
    });
    this.uhleadsservice.stopCallsfc.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });
    this.uhleadsservice.conferenceCallsfc.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });
    // BUF DASHBOARD CALLS
    this.uhleadsservice.startCallbuf.subscribe((data) => {
      if (data) {
        this.callNumberBUF(data.phoneNumber, data.leadData.id);
      }
    });
    this.uhleadsservice.stopCallbuf.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });
    this.uhleadsservice.conferenceCallbuf.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });
    // UWLA 
    this.uhleadsservice.startCalluwla.subscribe((data) => {
      if (data) {
        this.callNumberuwla(data.phoneNumber, data.leadData.id);
      }
    });
    this.uhleadsservice.stopCalluwla.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });
    this.uhleadsservice.conferenceCalluwla.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });
    // JUDSON DASHBOARD CALLS
    this.uhleadsservice.startCalljudson.subscribe((data) => {
      if (data) {
        this.callNumberjudson(data.phoneNumber, data.leadData.id);
      }
    });
    this.uhleadsservice.stopCalljudson.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });
    this.uhleadsservice.conferenceCalljudson.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });

    // UHUB LEADS 
    this.uHubLeadsService.startCall.subscribe((data) => {
      if (data) {
        this.callNumberUHUB(data.phoneNumber, data.leadData.id);
      }
    });

    this.uHubLeadsService.stopCall.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });

    this.uHubLeadsService.conferenceCall.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });

    // UHUB LEADS SOCIAL MEDIA
    this.uHubLeadsSocialService.startCallSocial.subscribe((data) => {
      if (data) {
        this.callNumberUHUBSocial(data.phoneNumber, data.leadData.id);
      }
    });

    this.uHubLeadsSocialService.stopCallSocial.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }
    });

    this.uHubLeadsSocialService.conferenceCallSocial.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });

    // UHUB LEADS RECRUITER LEADS
    this.uHubLeadsRecruiterService.startCallRecruiter.subscribe((data) => {
      if (data) {
        this.callNumberUHUBSocial(data.phoneNumber, data.leadData.id);
      }
    });

    this.uHubLeadsRecruiterService.stopCallRecruiter.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });

    this.uHubLeadsRecruiterService.conferenceCallRecruiter.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });

    // UHUB LEADS Frejun SOCIAL MEDIA
    this.uHubLeadsSocialService.startCallFJSocial.subscribe((data) => {
      if (data) {
        this.callNumberFJUHUBSocial(data.phoneNumber, data.leadData);
      }
    });

    this.uHubLeadsSocialService.stopCallFJSocial.subscribe(async (data) => {
      if (data) {
        await this.softphone.getSession.end();
        this.getCallNumbers();
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }
    });

    // this.uHubLeadsSocialService.conferenceCallSocial.subscribe((data) => {
    //   if (data) {
    //     this.conferenceCall(data.value);
    //   }
    // });


    // this.uHubLeadsRecruiterService.conferenceCallRecruiter.subscribe((data) => {
    //   if (data) {
    //     this.conferenceCall(data.value);
    //   }
    // });

    this.ReferralService.startCall.subscribe((data) => {
      if (data) {
        this.callNumber2(data.phoneNumber, data.leadData.id);
        // this.userData= data.userData;
      }
    });

    this.ReferralService.stopCall.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });

    this.ReferralService.conferenceCall.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });

    this.EnquiryService.startCall.subscribe((data) => {
      if (data) {
        this.callNumber5(data.phoneNumber, data.leadData.id, data.ProjectId);
        // this.userData= data.userData;
      }
    });

    this.EnquiryService.stopCall.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });

    this.EnquiryService.conferenceCall.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });

    this.ReferreeService.startCall.subscribe((data) => {
      if (data) {
        this.callNumber3(data.phoneNumber, data.leadData.id);
        // this.userData= data.userData;
      }
    });
    this.ReferreeService.stopCall.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });

    this.ReferreeService.conferenceCall.subscribe((data) => {
      if (data) {
        this.conferenceCall(data.value);
      }
    });
    this.RecruiterService.startCall.subscribe((data) => {
      if (data) {
        this.callNumberRecruiter(data.phoneNumber, data.leadData.id);
        // this.userData= data.userData;
      }
    });
    this.RecruiterService.stopCall.subscribe((data) => {
      if (data) {
        this.hangUp();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });
    this.TransferService.startCall.subscribe((data) => {
      if (data) {
        this.callNumber4(data.phoneNumber, data.leadData.id)
      }
    })
    this.TransferService.startCallv2.subscribe((data) => {
      if (data) {
        this.callNumber6(data.phoneNumber, data.leadData.id)
      }
    })
    this.TransferService.stopCall.subscribe((data) => {
      if (data) {
        this.hangUp();
      }
      // this.stopCall(data.phoneNumber);	
    });
    this.layoutService.marketingCall.subscribe((data) => {
      if (data) {
        this.callStopped = false;
        this.layoutService.submitted = false;
        this.layoutService.commentsForm
          .get("counsellingDate")
          .patchValue(
            this.layoutService.dateFormatForAddUpdate(
              this.layoutService.commentsForm.get("counsellingDate").value
            )
          );
        this.layoutService.commentsForm
          .get("callBack")
          .patchValue(
            this.layoutService.dateFormatForAddUpdate(
              this.layoutService.commentsForm.get("callBack").value
            )
          );
        this.layoutService.commentsForm
          .get("dob")
          .patchValue(
            this.layoutService.dateFormatForAddUpdate(
              this.layoutService.commentsForm.get("dob").value
            )
          );
        this.layoutService.commentsForm
          .get("interested")
          .setValidators(Validators.required);
        this.layoutService.commentsForm
          .get("interested")
          .updateValueAndValidity();
        this.layoutService.commentsForm
          .get("interested")
          .valueChanges.subscribe((selectedValue) => {
            this.layoutService.commentsForm.get("callBack").clearValidators();
            this.layoutService.commentsForm
              .get("callBack")
              .updateValueAndValidity();
            this.layoutService.commentsForm
              .get("counsellingDate")
              .clearValidators();
            this.layoutService.commentsForm
              .get("counsellingDate")
              .updateValueAndValidity();
            this.layoutService.commentsForm.get("assignedTo").clearValidators();
            this.layoutService.commentsForm
              .get("assignedTo")
              .updateValueAndValidity();
            if (selectedValue === "callback") {
              this.layoutService.commentsForm
                .get("callBack")
                .setValidators([Validators.required]);
              this.layoutService.commentsForm
                .get("callBack")
                .updateValueAndValidity();
            } else if (
              selectedValue === "counsellingpending") {
              this.layoutService.commentsForm
                .get("counsellingDate")
                .setValidators([Validators.required]);
              this.layoutService.commentsForm
                .get("counsellingDate")
                .updateValueAndValidity();
              this.layoutService.commentsForm
                .get("assignedTo")
                .setValidators([Validators.required]);
              this.layoutService.commentsForm
                .get("assignedTo")
                .updateValueAndValidity();
            }
          });
        this.redialContactId = {
          id: data["id"],
          status: "notanswered",
          phoneNumber: data["number"],
          email: data["email"],
          name: data["name"],
          module: data["module"],
          city: data["city"],
          nextCaller: data["nextCaller"],
          lead: data.lead,
        };
        this.marketingId = {
          id: data["id"],
          status: "notanswered",
          phoneNumber: data["number"],
          email: data["email"],
          name: data["name"],
          module: data["module"],
          city: data["city"],
          nextCaller: data["nextCaller"],
          lead: data.lead,
        };
        this.marketingService
          .getModuleConfigs(data["module"], this.projectId)
          .subscribe((data) => {
            this.moduleConfig = data;
          }, (error) => {
            this.handleError.handleError(error)
          });
        this.layoutService.onReset();
        this.layoutService.commentsForm.patchValue({
          name: data.name,
          emailId: data.email,
          state: data.lead.City,
          qualification: data.lead.Education,
        });
        this.schedulers = false;
        this.comments =
          data.lead["Phase"] === "demo"
            ? data.lead["DemoComments"] || ""
            : data.lead["Comments"] || "";
        this.callBack = "interested";
        this.isShownMarketing = true;
        this.isCustomerUpdate = false;
        this.agentToCall(data["number"]);
        this.layoutService.callMarketingStatus.next({
          id: data["id"],
          status: "notanswered",
          followUpdate: this.followUpdate,
          calledBy: this.userData["firstName"] + this.userData["lastName"],
        });
        this.layoutService
          .getCommentLead(this.marketingId.id)
          .subscribe((data) => {
            if (data !== null) {
              this.updateLeadComments = true;
            } else {
              this.updateLeadComments = false;
            }
          }, (error) => {
            this.handleError.handleError(error)
          });
        if (!this.modalService.hasOpenModals()) {
          this.open(this.content);
        } else {
          if (this.isShownMarketing) {
            this.modalService.dismissAll();
            this.open(this.content);
          }
        }
      }
    });
    this.layoutService.marketingCallDocs.subscribe((data) => {
      if (data) {
        this.currentItem = data.item;
        this.callStopped = false;
        this.layoutService.submitted = false;
        this.layoutService.commentsForm
          .get("counsellingDate")
          .patchValue(
            this.layoutService.dateFormatForAddUpdate(
              this.layoutService.commentsForm.get("counsellingDate").value
            )
          );
        this.layoutService.commentsForm
          .get("callBack")
          .patchValue(
            this.layoutService.dateFormatForAddUpdate(
              this.layoutService.commentsForm.get("callBack").value
            )
          );
        this.layoutService.commentsForm
          .get("dob")
          .patchValue(
            this.layoutService.dateFormatForAddUpdate(
              this.layoutService.commentsForm.get("dob").value
            )
          );
        this.layoutService.commentsForm
          .get("interested")
          .setValidators(Validators.required);
        this.layoutService.commentsForm
          .get("interested")
          .updateValueAndValidity();
        this.layoutService.commentsForm
          .get("interested")
          .valueChanges.subscribe((selectedValue) => {
            this.layoutService.commentsForm.get("callBack").clearValidators();
            this.layoutService.commentsForm
              .get("callBack")
              .updateValueAndValidity();
            this.layoutService.commentsForm
              .get("counsellingDate")
              .clearValidators();
            this.layoutService.commentsForm
              .get("counsellingDate")
              .updateValueAndValidity();
            this.layoutService.commentsForm.get("assignedTo").clearValidators();
            this.layoutService.commentsForm
              .get("assignedTo")
              .updateValueAndValidity();
            if (selectedValue === "Callback") {
              this.layoutService.commentsForm
                .get("callBack")
                .setValidators([Validators.required]);
              this.layoutService.commentsForm
                .get("callBack")
                .updateValueAndValidity();
            } else if (selectedValue === "counsellingpending") {
              this.layoutService.commentsForm
                .get("counsellingDate")
                .setValidators([Validators.required]);
              this.layoutService.commentsForm
                .get("counsellingDate")
                .updateValueAndValidity();
              this.layoutService.commentsForm
                .get("assignedTo")
                .setValidators([Validators.required]);
              this.layoutService.commentsForm
                .get("assignedTo")
                .updateValueAndValidity();
            }
          });
        this.redialContactId = {
          id: data["id"],
          status: "notanswered",
          phoneNumber: data["number"],
          email: data["email"],
          name: data["name"],
          module: data["module"],
          city: data["city"],
          nextCaller: data["nextCaller"],
          lead: data.lead
        };
        this.marketingId = {
          id: data["id"],
          status: "notanswered",
          phoneNumber: data["number"],
          email: data["email"],
          name: data["name"],
          module: data["module"],
          city: data["city"],
          nextCaller: data["nextCaller"],
          lead: data.lead
        };
        this.marketingService
          .getModuleConfigs(data["module"], this.projectId)
          .subscribe(
            (data) => {
              this.moduleConfig = data;
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        this.layoutService.onReset();
        this.layoutService.commentsForm.patchValue({
          name: data.name,
          emailId: data.email,
          state: data.lead.City,
          qualification: data.lead.Education,
        });
        this.schedulers = false;
        this.comments =
          data.lead["Phase"] === "demo"
            ? data.lead["DemoComments"] || ""
            : data.lead["Comments"] || "";
        this.callBack = "interested";
        this.isShownMarketing = true;
        this.isCustomerUpdate = false;
        this.agentToCall(data["number"]);
        this.layoutService.callMarketingStatus.next({
          id: data["id"],
          status: "notanswered",
          followUpdate: this.followUpdate,
          calledBy: this.userData["firstName"] + this.userData["lastName"],
        });
        this.layoutService.getCommentLead(this.marketingId.id).subscribe(
          (data) => {
            if (data !== null) {
              this.updateLeadComments = true;
            } else {
              this.updateLeadComments = false;
            }
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
        if (!this.modalService.hasOpenModals()) {
          this.openDocCall(this.contentDocs);
        } else {
          if (this.isShownMarketing) {
            this.modalService.dismissAll();
            this.openDocCall(this.contentDocs);
          }
        }
      }
    });
    this.sharedService.companyCreated.subscribe((data) => {
      this.companyService.getCompanies().subscribe((data) => {
        this.companies = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
    });

    this.layoutService.callAgent.subscribe((data) => {
      this.agentToCall(data);
    });

    this.twilioSocketService.callCancelled.subscribe((data) => {
      if (data) {
        if (data["workerSID"] === this.userData["workerSId"]) {
          this.dailerScreen = "nocall";
          this.isCallcomming = false;
          this.isOutgoing = false;
          this.isIncoming = false;
          if (this.audio) {
            this.audio.pause();
          }
        }
      }
    });

    this.twilioSocketService.callAccepted.subscribe((data) => {
      if (data) {
        this.uhleadsservice.startTimer.next("StartingCall");
        this.uhleadsservice.startTimersfc.next("StartingCall");
        this.uhleadsservice.startTimerbuf.next("StartingCall");
        this.uhleadsservice.startTimerjudson.next("StartingCall");
        this.uhleadsservice.startTimeruwla.next("StartingCall");
        this.uHubLeadsService.startTimer.next("StartingCall");
        this.uHubLeadsSocialService.startTimerSocial.next("StartingCall");
        this.uHubLeadsRecruiterService.startTimerRecruiter.next("StartingCall");
        this.ReferralService.startTimer.next("StartingCall");
        this.ReferreeService.startTimer.next("StartingCall");
        this.TransferService.startTimer.next("StartingCall");
        this.EnquiryService.startTimer.next("StartingCall");
        this.RecruiterService.startTimer.next("StartingCall");
        this.callStatus = "inProgress";
        this.startTimer();
        if (
          data["workerSID"] === this.userData["workerSId"] &&
          this.connection.parameters.CallSid === data["CallSid"]
        ) {
          if (this.marketingId["id"]) {
            this.layoutService.callMarketingStatus.next({
              id: this.marketingId["id"],
              status: "answered",
              calledBy: this.userData["firstName"] + this.userData["lastName"],
              followUpdate: this.followUpdate,
            });
          }
          if (this.isShownMarketing) {
            this.dialerScreen1 = "oncall";
            this.schedulers = true;
            this.isCallcomming = false;
            this.isOutgoing = false;
            this.isIncoming = true;
            return;
          } else {
            this.dailerScreen = "oncall";
            this.isCallcomming = false;
            this.isOutgoing = false;
            this.isIncoming = true;
            this.startTimer();
            return;
          }
        }
      }
    });
    this.freJunService.callAnswered.subscribe((data) => {
      if (data) {
        this.uhleadsservice.startTimer.next("StartingCall");
        this.uhleadsservice.startTimersfc.next("StartingCall");
        this.uhleadsservice.startTimerbuf.next("StartingCall");
        this.uhleadsservice.startTimerjudson.next("StartingCall");
        this.uhleadsservice.startTimeruwla.next("StartingCall");
        this.uHubLeadsService.startTimer.next("StartingCall");
        this.uHubLeadsSocialService.startTimerSocial.next("StartingCall");
        this.uHubLeadsRecruiterService.startTimerRecruiter.next("StartingCall");
        this.uHubLeadsSocialService.startTimerFJSocial.next("StartingCall");
        this.ReferralService.startTimer.next("StartingCall");
        this.ReferreeService.startTimer.next("StartingCall");
        this.TransferService.startTimer.next("StartingCall");
        this.EnquiryService.startTimer.next("StartingCall");
        this.RecruiterService.startTimer.next("StartingCall");
        this.callStatus = "inProgress";
        this.startTimer();
        if (this.marketingId["id"]) {
          this.layoutService.callMarketingStatus.next({
            id: this.marketingId["id"],
            status: "answered",
            calledBy: this.userData["firstName"] + this.userData["lastName"],
            followUpdate: this.followUpdate,
          });
        }
        if (this.isShownMarketing) {
          this.dialerScreen1 = "oncall";
          this.schedulers = true;
          this.isCallcomming = false;
          this.isOutgoing = false;
          this.isIncoming = true;
          return;
        } else {
          this.dailerScreen = "oncall";
          this.isCallcomming = false;
          this.isOutgoing = false;
          this.isIncoming = true;
          this.startTimer();
          if (this.userData.workerSId && data.call_id) {
            this.layoutService
              .updateWorkerID({
                calledBy: this.userData.workerSId,
                callsid: data.call_id,
                status: "called",
              })
              .subscribe((res) => { }, (error) => {
                this.handleError.handleError(error)
              });
          }
          return;
        }
      }
    });

    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let prId = userData["companyId"] === 0 ? 5 : projects[0]["id"];
      this.layoutService.setupTwilio(prId).then((res: any) => {
        this.isloading = true;
        this.activities = JSON.parse(res).configuration.twilio.activities;
        this.worker = JSON.parse(res).worker;
        this.InitializePhone({ token: JSON.parse(res).tokens.access });
        this.configuration = JSON.parse(res);
        this.initWorker(res);
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  freJunintialCode() {

    // SFC DASHBOARD CALLS
    this.uhleadsservice.startCallFJsfc.subscribe((data) => {
      if (data) {
        console.log("data : ", data)
        this.callNumberFJSFC(data.phoneNumber, data.leadData);
      }
    });
    this.uhleadsservice.stopCallFJsfc.subscribe(async (data) => {
      if (data) {

        console.log("****** data : ", data)
        await this.softphone.getSession.end();

        this.hangUpfreJunCall();

        this.agentService.updateStatus({
          id: this.userData["id"],
          status: "online",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        });

        this.currentStatus = "Online";
      }

      // this.stopCall(data.phoneNumber);
    });

  }

  toggle() {
    $("#mySidebar").toggleClass("active");
    $("#tog-collapse").toggleClass("active-pad");
    var isClass = $("#mySidebar").hasClass("active");
    if (isClass) {
      this.layoutService.closeSideParent.next("close");
    }
    if ($("#SideLogo").attr("src") === "assets/theme/uh-small-logo.png") {
      $("#SideLogo").attr("src", "assets/theme/uh-small-logo.png");
      this.sharedService.switchToggle.next("toggling");
    } else {
      $("#SideLogo").attr("src", "assets/theme/uh-small-logo.png");
    }
  }

  async toggleMute() {
    this.mute = !this.mute;

    if (this.is_countryselected) {
      this.mute ? await this.softphone.getSession.mute() : await this.softphone.getSession.unmute()
    }
    else {
      this.connection.mute(this.mute);
    }
  }

  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }

  fetchCountries() {
    this.countries = countryCodes;
  }

  fetchTasks() {
    this.layoutService.unReadCount.subscribe((data) => {
      this.unReadMessages = data;
    });
    let prevTasks: any[] = [];
    this.layoutService.prevTasks.subscribe((data) => {
      prevTasks = data;
    });
    const operation = (list1, list2, isUnion = false) =>
      list1.filter((a) => isUnion === list2.some((b) => a.taskId === b.taskId));

    this.workerJS.fetchReservations((error, reservations) => {
      if (error) {
        return;
      }

      var data = reservations.data;
      if (data.length > 0) {
        let tasks: any[] = [];
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].task.attributes.channel === "chat" ||
            data[i].task.attributes.channel === "sms" ||
            data[i].task.attributes.channel === "whatsapp"
          ) {
            if (data[i].task.assignmentStatus === "assigned") {
              tasks.push({
                attributes: data[i].task.attributes,
                taskId: data[i].task.sid,
                unReadMessages: 0,
                messages: [],
                channel: "",
              });
            }
          }
        }
        if (prevTasks.length !== 0) {
          let comparetasks = tasks.map((data) => {
            if (
              prevTasks.find((olddata) => olddata["taskId"] === data["taskId"])
            ) {
              return prevTasks.find(
                (olddata) => olddata["taskId"] === data["taskId"]
              );
            }
          });
          this.tasks = comparetasks;
          return this.layoutService.tasks.next(comparetasks);
        }
        this.tasks = tasks;
        this.layoutService.tasks.next(this.tasks);
      } else {
      }
    });
  }

  initWorker(res) {
    this.workerJS = new Twilio.TaskRouter.Worker(JSON.parse(res).tokens.worker);
    if (this.workerJS) {
      this.workerJS.on("ready", (worker) => {

        this.currentStatus = worker.activityName;
        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status:
              worker.activityName === "Idle" ? "Online" : worker.activityName,
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });
        localStorage.setItem("currentStatus", this.currentStatus);
      });
      this.fetchTasks();
      this.layoutService.workerJs.next(this.workerJS);

      this.workerJS.on("reservation.created", (reservation) => {

        if (
          reservation.task.attributes.channel === "chat" ||
          reservation.task.attributes.channel === "sms" ||
          reservation.task.attributes.channel === "whatsapp"
        ) {
          if (reservation.task.attributes.channel == 'sms') {
            this.uhleadsservice.showInfo("Received incoming SMS", "New Message!");
            this.uhleadsservice.incomingSms.next({ from: reservation.task.attributes.name });
            this.uhleadsservice.incomingSmssfc.next({ from: reservation.task.attributes.name });
            this.uhleadsservice.incomingSmsbuf.next({ from: reservation.task.attributes.name });
            this.uhleadsservice.incomingSmsjudson.next({ from: reservation.task.attributes.name });
            this.uhleadsservice.incomingSmsuwla.next({ from: reservation.task.attributes.name });

          }
          this.reservation = reservation;
          this.snackBarRef = this.snackbar.open(
            "user is requesting for chat",
            "accept",
            {
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ["blue-snackbar"],
            }
          );
          this.snackBarRef.afterDismissed().subscribe(() => {
            this.accept(reservation);
            this.isChat = true;
          });

          return;
        }
        if (reservation.task.attributes.channel === "phone") {
          this.isReservation = true;
          this.dailerScreen = "callcomming";
          this.isCallcomming = true;
          this.isOutgoing = false;
          this.isIncoming = false;
          this.showDailer = true;
          this.reservation = reservation;
          this.audio = new Audio();
          this.audio.src = "../../assets/audio/caller_tune.mp3";
          this.audio.load();
          this.audio.play();
        }
      });
      this.workerJS.on("activity.update", (w) => {
        this.currentStatus = w.activityName;
        localStorage.setItem("currentStatus", this.currentStatus);
        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status:
              this.currentStatus === "Idle" ? "Online" : this.currentStatus,
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });
        this.worker = w;
      });
    }

    this.workerJS.on("reservation.wrapup", (reservation) => {
      this.complete();
      this.dailerScreen = "nocall";
    });
    this.workerJS.on("reservation.rejected", (reservation) => {
      this.audio.pause();
    });

    this.workerJS.on("reservation.accepted", (reservation) => {
      if (
        reservation.task.attributes.channel === "chat" ||
        reservation.task.attributes.channel === "sms" ||
        reservation.task.attributes.channel === "whatsapp"
      ) {
        this.snackBarRef.dismiss();
        let newtask: any;
        newtask = {
          attributes: reservation.task.attributes,
          taskId: reservation.task.sid,
          unReadMessages: 0,
          messages: [],
          channel: "",
        };
        this.layoutService.newTask.next(newtask);

        return;
      }
      this.task = reservation.task;

      var pattern = /(.*)(\+[0-9]{8,20})(.*)$/;

      if (pattern.test(this.task.attributes.name) === true) {
        this.task.attributes.nameIsPhoneNumber = true;
      }
      this.dailerScreen = "oncall";
      this.isOutgoing = false;
      this.isCallcomming = false;
      this.isIncoming = true;
      this.startTimer();
      if (this.audio) {
        this.audio.pause();
      }
    });

    this.workerJS.on("reservation.timeout", (reservation) => {
      this.showDailer = !this.showDailer;
      this.dailerScreen = "nocall";
      this.isOutgoing = false;
      this.isCallcomming = false;
      this.isIncoming = false;
      this.toggleIsOnQueue(
        this.configuration.configuration.twilio.workerOnlineActivitySid
      );
      if (
        reservation.task.attributes.channel === "chat" ||
        reservation.task.attributes.channel === "sms" ||
        reservation.task.attributes.channel === "whatsapp"
      ) {
        this.snackBarRef.dismiss();
      }
      this.isReservation = false;
    });

    this.workerJS.on("reservation.rescinded", (reservation) => {
      this.isReservation = false;
      this.isOutgoing = false;
      this.isCallcomming = false;
      this.isIncoming = false;
    });

    this.workerJS.on("reservation.canceled", (reservation) => {
      if (
        reservation.task.attributes.channel === "chat" ||
        reservation.task.attributes.channel === "sms" ||
        reservation.task.attributes.channel === "whatsapp"
      ) {
        this.snackBarRef.dismiss();
      }
      this.showDailer = false;
      this.dailerScreen = "nocall";
      this.isReservation = false;
      this.isOutgoing = false;
      this.isCallcomming = false;
      this.isIncoming = false;

      this.audio.pause();
    });
    this.workerJS.on("tokenExpired", () => { });

    this.workerJS.on("reservation.rejected", (reservation) => {
      this.isReservation = false;
      this.isOutgoing = false;
      this.isCallcomming = false;
      this.isIncoming = false;
    });

    this.workerJS.on("task.completed", (reservation) => {
      this.fetchTasks();
      this.isReservation = false;
      this.isOutgoing = false;
      this.isCallcomming = false;
      this.isIncoming = false;
    });

    this.workerJS.on("token.expired", () => {
    });

    this.workerJS.on("connected", () => { });
    this.workerJS.on("disconnected", () => {
    });

    this.workerJS.on("error", (error) => {
      console.error(
        "TaskRouter Worker: an error occurred: " +
        error.response +
        " with message: " +
        error.message
      );
    });
  }

  InitializePhone(data) {
    Twilio.Device.setup(data.token, {
      debug: true,
      enableRingingState: true,
      codecPreferences: ["opus", "pcmu"],
    });

    Twilio.Device.on("ready", (device) => { });
    Twilio.Device.on("error", (error) => { });

    Twilio.Device.on("connect", (connection) => {
      this.connection = connection;
      connection.on("accept", () => {
        this.isCallingProgress = true;
      });
      connection.on("pending", () => { });
      connection.on("connect", () => { });
      connection.on("ringing", () => { });
      connection.on("open", () => { });
      connection.on("close", () => { });
      connection.on("reject", () => { });
      connection.on("disconnect", () => {
        this.dailerScreen = "dailer";
        this.isCallingProgress = false;
        this.isOutgoing = false;
        this.isIncoming = false;
        this.isCallcomming = false;

        if (this.isShownMarketing) {
          this.dialerScreen1 = "dialer";
          this.isShownMarketing = false;
        }
        if (this.marketingId["id"]) {
          this.layoutService.callEnd.next(this.marketingId);
        }
        if (this.audio) {
          this.audio.pause();
        }
        this.toggleIsOnQueue(
          this.configuration.configuration.twilio.workerAvailableActivitySid
        );
        this.layoutService
          .getCallHistory(this.userData.workerSId)
          .subscribe((data) => {
            this.callLogs = data;
          }, (error) => {
            this.handleError.handleError(error)
          });
      });
    });

    Twilio.Device.on("disconnect", (connection) => {
      this.isOutgoing = false;
      this.phoneNumber = "";
      if (this.audio) {
        this.audio.pause();
      }
    });

    Twilio.Device.on("offline", (device) => { });

    Twilio.Device.on("incoming", (connection) => {
      this.connection = connection;
      this.mute = false;
      this.hold = false;
      this.direction = "inbound";
      this.phoneNumber = connection.parameters.From;
      if (this.isReservation) {
        connection.accept();
      } else {
        this.isAgentCalling = true;
        this.dailerScreen = "callcomming";
        this.toggleIsOnQueue(
          this.configuration.configuration.twilio.workerUnavailableActivitySid
        );
        this.isCallcomming = true;
        this.showDailer = true;
        this.audio = new Audio();
        this.audio.src = "../../assets/audio/caller_tune.mp3";
        this.audio.load();
        this.audio.play();
      }

      connection.disconnect((disconnect) => {
        this.isCallingProgress = false;
        this.isAgentCalling = false;
        this.isOutgoing = false;
        if (this.audio) {
          this.audio.pause();
        }
      });
    });
  }

  acceptAgentCall() {
    this.connection.accept();
    this.audio.pause();
    this.dailerScreen = "oncall";
    this.isCallcomming = false;
    this.isIncoming = true;
    this.isOutgoing = false;
    this.startTimer();
  }

  accept(reservation) {

    this.showDailer = false;
    if (this.audio) {
      this.audio.pause();
    }
    if (reservation.task.attributes.channel === "phone") {

      reservation.conference(
        this.configuration.configuration.twilio.callerId,
        null,
        null,
        null,
        (error, reservation) => {
          if (error) {
            console.error(error);
            return;
          }
        },
        {
          EndConferenceOnExit: true,
          EndConferenceOnCustomerExit: true,
          ConferenceRecord:
            this.configuration.configuration.twilio.voice.recording,
        }
      );
      this.enquiryCallService.getCallSid.next(reservation.task.attributes.call_sid);
      this.incomingCallService.checkLead(reservation.task.attributes.from, reservation.task.attributes.to).subscribe((res: any) => {

        this.incomingCallService.callInfo.next({
          CallSid: reservation.task.attributes.call_sid,
          From: reservation.task.attributes.from,
          To: reservation.task.attributes.to,
        });

        if (res.status == 201) {

          this.openIncomingCall();
        }
        else if (res.status == 200) {
          if (res.ProjectName == 'Campbellsville') {
            this.uhleadsservice.getStudentActivity(res.LeadIds[0]).subscribe((leadInfo: any) => {
              this.enquiryCallService.leadInfo.next({ project: 'Campbellsville', projectId: res.ProjectId, leadInfo: leadInfo });
            })
          } else if (res.ProjectName == 'Referrals') {
            if (res.referralIds.length > 0) {
              this.referralService.getReferralLead(res.referralIds[0]).subscribe((leadInfo: any) => {
                this.enquiryCallService.leadInfo.next({ project: 'Referrals', projectId: res.ProjectId, leadInfo: leadInfo });
              })
            } else if (res.refereeIds.length > 0) {
              this.ReferreeService.getReferralLead(res.refereeIds[0]).subscribe((leadInfo: any) => {
                this.enquiryCallService.leadInfo.next({ project: 'Referee', projectId: res.ProjectId, leadInfo: leadInfo });
              })

            }

          } else if (res.ProjectName == 'University Hub') {
            this.marketingService.showDetails(res.leadIds[0], 'Website').subscribe((leadInfo: any) => {
              this.enquiryCallService.leadInfo.next({ project: 'University Hub', projectId: res.ProjectId, leadInfo: leadInfo, id: res.leadIds[0] });
            })
          } else if (res.ProjectName == environment.Sfcleads) {
            this.uhleadsservice.getStudentActivity(res.LeadIds[0]).subscribe((leadInfo: any) => {
              this.enquiryCallService.leadInfo.next({ project: environment.Sfcleads, projectId: res.ProjectId, leadInfo: leadInfo });
            })
          } else if (res.ProjectName == environment.Bufleads) {
            this.uhleadsservice.getStudentActivity(res.LeadIds[0]).subscribe((leadInfo: any) => {
              this.enquiryCallService.leadInfo.next({ project: environment.Bufleads, projectId: res.ProjectId, leadInfo: leadInfo });
            })
          } else if (res.ProjectName == environment.Judsonuleads) {
            this.uhleadsservice.getStudentActivity(res.LeadIds[0]).subscribe((leadInfo: any) => {
              this.enquiryCallService.leadInfo.next({ project: environment.Judsonuleads, projectId: res.ProjectId, leadInfo: leadInfo });
            })
          } else if (res.ProjectName == environment.UwlaLeads) {
            this.uhleadsservice.getStudentActivity(res.LeadIds[0]).subscribe((leadInfo: any) => {
              this.enquiryCallService.leadInfo.next({ project: environment.UwlaLeads, projectId: res.ProjectId, leadInfo: leadInfo });
            })
          } else if (res.ProjectName == environment.UwlaLeads) {
            this.uhleadsservice.getStudentActivity(res.LeadIds[0]).subscribe((leadInfo: any) => {
              this.enquiryCallService.leadInfo.next({ project: environment.UwlaLeads, projectId: res.ProjectId, leadInfo: leadInfo });
            })
          }
          //fetch the data using the lead id and project id combination
          this.enquiryCallService.callerData.next({ from: reservation.task.attributes.from, to: reservation.task.attributes.to })
          this.openEnquiryCall();
        }
      });
      this.incomingCallService.endCall.subscribe((r: any) => {
        if (r) {
          this.hangUp();
        }
      });

      this.enquiryCallService.endCall.subscribe((r: any) => {
        if (r) {
          this.hangUp();
        }

      });
    }
    if (reservation.task.attributes.channel === "sms") {

    }
    if (
      reservation.task.attributes.channel === "chat" ||
      reservation.task.attributes.channel === "whatsapp"
    ) {
      reservation.accept(function (error, reservation) {
        if (error) {
          console.error(error);
          return;
        }
      });
    }


  }

  addDigit(digit) {
    if (this.phoneNumber) {
      this.phoneNumber += digit;
    } else {
      this.phoneNumber = `${digit}`;
    }
    if (this.connection) {
      this.connection.sendDigits(digit);
    }
  }

  async callPhoneNumber() {
    if (
      this.phoneNumber &&
      this.isOutgoing === false &&
      this.isIncoming === false &&
      this.isCallcomming === false &&
      this.isCallingProgress === false &&
      !this.isShownMarketing
    ) {
      this.callStatus = "Calling ...";
      this.direction = "outbound";
      this.phoneNumber = `+${this.selectedCountryCode}${this.phoneNumber}`;
      const universityhubproject = this.projects.filter(x => x.Name.includes('University Hub'));      
      const universityhubprojectId = universityhubproject.length > 0 ? universityhubproject[0].id : null;      
      if (universityhubprojectId !== null && this.selectedCountryCode.toString() === "91" && universityhubprojectId === this.projectId) {
        this.dailerScreen = "outgoing";

        const listeners = {
          onConnectionStateChange: (type, newState, attemptReconnection, error) => {
            // type : "UserAgentState" | "RegisterState"
            // if type is "UserAgentState", newState: "Started" | "Stopped"
            // if type is "RegisterState", newState: "Initial" | "Registered" | "Terminated" | "Unregistered"

            console.log("**************************************************")
            console.log("Type : ", type)
            console.log("newState : ", newState)
            console.log("attemptReconnection : ", attemptReconnection)
            console.log("**************************************************")
          },

          onCallCreated: (type, details) => {
            console.log("1onCallCreated **************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" 1onCallCreated**************************************************")

            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}

          },

          onCallRinging: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show on call action buttons to user
            console.log(" onCallRinging**************************************************");
            this.callStatus = "Ringing ...";

            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallRinging**************************************************")
            this.audio = new Audio();
            // this.audio.src = "../../assets/audio/caller_tune.mp3";
            // this.audio.load();
            // this.audio.play();
          },

          onCallHangup: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show apt ui
            console.log(" onCallHangup**************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            // this.uhleadsservice.stopCallFJsfc.next(details.candidate)
            console.log(" onCallHangup**************************************************")
            this.callStatus = "Ringing ...";
            this.dailerScreen = "dailer";
            this.isReservation = false;
            this.isOutgoing = false;
            this.phoneNumber = "";
            setTimeout(() => {

              this.layoutService
                .getCallHistory(this.userData.workerSId)
                .subscribe((data) => {
                  this.callLogs = data;
                }, (error) => {
                  this.handleError.handleError(error)
                });
            }, 5000);
          },
        }
        this.softphone.start(listeners, this.audioElements);

        const phoneNumber = this.phoneNumber;
        const virtualNumber = this.selectedCallerId;

        await this.softphone.makeCall(phoneNumber, virtualNumber);

      }
      else {
        let connection = Twilio.Device.connect({
          phone: this.phoneNumber,
          callerId: this.selectedCallerId,
        });
        this.toggleIsOnQueue(
          this.configuration.configuration.twilio.workerUnavailableActivitySid
        );
        this.connection = connection;
        this.dailerScreen = "outgoing";
        connection.on("accept", () => {
          // this.isCallingProgress = true;
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.callStatus = "Ringing ...";
        });
        connection.on("open", () => { });
        connection.on("close", () => { });
        connection.on("reject", () => { });

        connection.on("disconnect", () => {
          this.callStatus = "Ringing ...";
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isOutgoing = false;
          this.phoneNumber = "";
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
          this.isCallingProgress = false;
        });
      }
    }
  }

  //Place a call Function - uhleads
  callNumber(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('Campbellsville'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        // this.uhleadsservice
        //   .updateCallStatus({
        //     calledBy: this.userData.firstName + " " + this.userData.lastName,
        //     id: id,
        //     status: "notanswered",
        //   })
        //   .subscribe((res) => { }, (error) => {
        //     this.handleError.handleError(error)
        //   });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.uhleadsservice
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                // this.acceptAgentCall()
                this.uhleadsservice.getCallSid.next(
                  this.connection.parameters.CallSid
                );
                this.uhleadsservice
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uhleadsservice.callStatus.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      });
    });
  }

  //Place a call Function - uhleads
  callNumberSFC(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes(environment.Sfcleads))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.uhleadsservice
          .updateCallStatus({
            calledBy: this.userData.firstName + " " + this.userData.lastName,
            id: id,
            status: "notanswered",
          })
          .subscribe((res) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.uhleadsservice
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                // this.acceptAgentCall()
                this.uhleadsservice
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
                this.uhleadsservice.getCallSidsfc.next(
                  this.connection.parameters.CallSid
                );
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uhleadsservice.callStatussfc.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      });
    });
  }
  //Place a call Function - uhleads
  callNumberBUF(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes(environment.Bufleads))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.uhleadsservice
          .updateCallStatus({
            calledBy: this.userData.firstName + " " + this.userData.lastName,
            id: id,
            status: "notanswered",
          })
          .subscribe((res) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.uhleadsservice
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                // this.acceptAgentCall()
                this.uhleadsservice
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
                this.uhleadsservice.getCallSidbuf.next(
                  this.connection.parameters.CallSid
                );
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uhleadsservice.callStatusbuf.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      });
    });
  }


  callNumberFJSFC(from, leadData) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes(environment.Sfcleads))[0].id;
      this.projectService.getProject(this.projectId).subscribe(async (data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["FreJunNumber"]];
        transferCall = data["FreJunNumber"];
        // let connection = Twilio.Device.connect({
        //   phone: `${from}`,
        //   callerId: transferCall,
        // });
        // this.connection = connection;
        // let createCall = this.freJunService.createCall({
        //   user_email: "manasa@avantao.com",
        //   candidate_number: `${from}`,
        //   candidate_name: leadData.name
        // }).subscribe((data: any) => {
        // return data
        const listeners = {
          onConnectionStateChange: (type, newState, attemptReconnection, error) => {
            // type : "UserAgentState" | "RegisterState"
            // if type is "UserAgentState", newState: "Started" | "Stopped"
            // if type is "RegisterState", newState: "Initial" | "Registered" | "Terminated" | "Unregistered"

            console.log("**************************************************")
            console.log("Type : ", type)
            console.log("newState : ", newState)
            console.log("attemptReconnection : ", attemptReconnection)
            console.log("**************************************************")
          },

          onCallCreated: (type, details) => {
            console.log("2onCallCreated **************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" 2onCallCreated**************************************************")
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}

          },

          onCallRinging: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show on call action buttons to user
            console.log(" onCallRinging**************************************************");
            this.uhleadsservice.callStatussfc.next('Ringing...');

            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallRinging**************************************************")
            this.audio = new Audio();
            // this.audio.src = "../../assets/audio/caller_tune.mp3";
            // this.audio.load();
            // this.audio.play();
          },

          onCallHangup: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show apt ui
            console.log(" onCallHangup**************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            this.uhleadsservice.stopCallFJsfc.next(details.candidate)
            console.log(" onCallHangup**************************************************")
            this.dailerScreen = "dailer";
            this.isCallingProgress = false;
            this.isOutgoing = false;
            this.isIncoming = false;
            this.isCallcomming = false;
            this.phoneNumber = "";

            if (this.isShownMarketing) {
              this.dialerScreen1 = "dialer";
              this.isShownMarketing = false;
            }
            if (this.marketingId["id"]) {
              this.layoutService.callEnd.next(this.marketingId);
            }
            if (this.audio) {
              this.audio.pause();
            }
            // this.toggleIsOnQueue(
            //   this.configuration.configuration.twilio.workerAvailableActivitySid
            // );
            this.layoutService
              .getCallHistory(this.userData.workerSId)
              .subscribe((data) => {
                this.callLogs = data;
              }, (error) => {
                this.handleError.handleError(error)
              });
          },
        }
        this.softphone.start(listeners, this.audioElements);

        const phoneNumber = from;
        const virtualNumber = this.callerIds[0]
        await this.softphone.makeCall(phoneNumber, virtualNumber);
        // })
        // console.log(createCall)
        this.uhleadsservice
          .updateCallStatus({
            calledBy: this.userData.firstName + " " + this.userData.lastName,
            id: leadData.id,
            status: "notanswered",
          })
          .subscribe((res) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";



        this.freJunService.callDisconnected.subscribe((Data) => {

          if (Data) {
            this.dailerScreen = "dailer";
            this.isReservation = false;
            this.isCallingProgress = false;
            this.isOutgoing = false;
            this.agentCall = "";
            this.layoutService
              .getCallHistory(this.userData.workerSId)
              .subscribe((data) => {
                this.callLogs = data;
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        })


      });
    });
  }
  //Place a call Function - callNumberjudson
  callNumberjudson(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes(environment.Judsonuleads))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.uhleadsservice
          .updateCallStatus({
            calledBy: this.userData.firstName + " " + this.userData.lastName,
            id: id,
            status: "notanswered",
          })
          .subscribe((res) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.uhleadsservice
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                // this.acceptAgentCall()
                this.uhleadsservice
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
                this.uhleadsservice.getCallSidjudson.next(
                  this.connection.parameters.CallSid
                );
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uhleadsservice.callStatusjudson.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      });
    });
  }
  //Place a call Function - callNumberuwla
  callNumberuwla(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes(environment.Uwlauleads))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.uhleadsservice
          .updateCallStatus({
            calledBy: this.userData.firstName + " " + this.userData.lastName,
            id: id,
            status: "notanswered",
          })
          .subscribe((res) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.uhleadsservice
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                // this.acceptAgentCall()
                this.uhleadsservice
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
                this.uhleadsservice.getCallSiduwla.next(
                  this.connection.parameters.CallSid
                );
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uhleadsservice.callStatusuwla.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      });
    });
  }
  //Place a call Function - uhleads
  callNumberUHUB(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('University Hub'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.layoutService
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                this.layoutService.getCallSid.next(
                  this.connection.parameters.CallSid
                );
                // this.acceptAgentCall()
                this.layoutService
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
              }, (error) => {
                this.handleError.handleError(error)
              });
          }


        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uHubLeadsService.callStatus.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  //Place a call Function - uhleads
  callNumberUHUBSocial(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('University Hub'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.layoutService
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                this.layoutService.getCallSid.next(
                  this.connection.parameters.CallSid
                );
                // this.acceptAgentCall()
                this.layoutService
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
              }, (error) => {
                this.handleError.handleError(error)
              });
          }


        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uHubLeadsSocialService.callStatusSocial.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  //Place a call Function - uhleads
  callNumberUHUBRecruiter(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('University Hub'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.layoutService
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                this.layoutService.getCallSid.next(
                  this.connection.parameters.CallSid
                );
                // this.acceptAgentCall()
                this.layoutService
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
              }, (error) => {
                this.handleError.handleError(error)
              });
          }


        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.uHubLeadsRecruiterService.callStatusRecruiter.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }


  //Place a Frejun call Function - uhleads
  callNumberFJUHUBSocial(from, leadData) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('University Hub'))[0].id;
      this.projectService.getProject(this.projectId).subscribe(async (data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["FreJunNumber"]];
        transferCall = data["FreJunNumber"];
        const listeners = {
          onConnectionStateChange: (type, newState, attemptReconnection, error) => {
            console.log("**************************************************")
            console.log("Type : ", type)
            console.log("newState : ", newState)
            console.log("attemptReconnection : ", attemptReconnection)
            console.log("**************************************************")

          },

          onCallCreated: (type, details) => {
            console.log("4onCallCreated **************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" 4onCallCreated**************************************************")
          },

          onCallRinging: (type, details) => {
            console.log(" onCallRinging**************************************************");
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallRinging**************************************************")
            this.uHubLeadsSocialService.callStatusFJSocial.next('Ringing...');
            this.audio = new Audio();
          },

          onCallHangup: (type, details) => {

            console.log(" onCallHangup**************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallHangup**************************************************")
            this.uHubLeadsSocialService.stopCallFJSocial.next(details.candidate)
            this.dailerScreen = "dailer";
            this.isCallingProgress = false;
            this.isReservation = false;
            this.isCallingProgress = false;
            this.isOutgoing = false;
            this.agentCall = "";
            // this.phoneNumber = "";

            // if (this.isShownMarketing) {
            //   this.dialerScreen1 = "dialer";
            //   this.isShownMarketing = false;
            // }
            if (this.marketingId["id"]) {
              this.layoutService.callEnd.next(this.marketingId);
            }
            if (this.audio) {
              this.audio.pause();
            }
            this.getCallNumbers();;
            this.layoutService
              .getCallHistory(this.userData.workerSId)
              .subscribe((data) => {
                this.callLogs = data;
              }, (error) => {
                this.handleError.handleError(error)
              });
          },
        }
        this.softphone.start(listeners, this.audioElements);

        const phoneNumber = from;
        const virtualNumber = transferCall;
        await this.softphone.makeCall(phoneNumber, virtualNumber);

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";


        this.freJunService.callAnswered.subscribe((Data) => {
          if (Data) {
            console.log("Data", Data)
            this.isOutgoing = true;
            this.isIncoming = false;
            this.isCallcomming = false;
            this.isCallingProgress = false;
            if (leadData.id) {
              this.layoutService
                .updateCallSid({
                  id: leadData.id,
                  sid: Data.call_id,
                })
                .subscribe((data) => {
                  // this.acceptAgentCall()
                  this.layoutService
                    .updateCallStatus({
                      calledBy: this.userData.firstName + " " + this.userData.lastName,
                      calledByWorkerId: this.userData.workerSId,
                      id: leadData.id,
                      status: "answered",
                    })
                    .subscribe((res) => { }, (error) => {
                      this.handleError.handleError(error)
                    });
                    this.layoutService.getCallSid.next(
                    Data.call_id
                  );
                }, (error) => {
                  this.handleError.handleError(error)
                });
            }
          }
        })

      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  //Place a Frejun call Function - uhleads
  callNumberFJUHUBRecruiter(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('University Hub'))[0].id;
      this.projectService.getProject(this.projectId).subscribe(async (data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["FreJunNumber"]];
        transferCall = data["FreJunNumber"];
        const listeners = {
          onConnectionStateChange: (type, newState, attemptReconnection, error) => {
            // type : "UserAgentState" | "RegisterState"
            // if type is "UserAgentState", newState: "Started" | "Stopped"
            // if type is "RegisterState", newState: "Initial" | "Registered" | "Terminated" | "Unregistered"

            console.log("**************************************************")
            console.log("Type : ", type)
            console.log("newState : ", newState)
            console.log("attemptReconnection : ", attemptReconnection)
            console.log("**************************************************")
          },

          onCallCreated: (type, details) => {
            console.log("4onCallCreated **************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" 4onCallCreated**************************************************")
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}

          },

          onCallRinging: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show on call action buttons to user
            this.uhleadsservice.callStatussfc.next('Ringing...');

            console.log(" onCallRinging**************************************************");
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallRinging**************************************************")
            this.audio = new Audio();
            this.audio.src = "../../assets/audio/caller_tune.mp3";
            this.audio.load();
            this.audio.play();
          },

          onCallHangup: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show apt ui
            console.log(" onCallHangup**************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallHangup**************************************************")
            this.uhleadsservice.stopCallFJsfc.next(details.candidate)

            this.dailerScreen = "dailer";
            this.isCallingProgress = false;
            this.isOutgoing = false;
            this.isIncoming = false;
            this.isCallcomming = false;
            this.phoneNumber = "";

            if (this.isShownMarketing) {
              this.dialerScreen1 = "dialer";
              this.isShownMarketing = false;
            }
            if (this.marketingId["id"]) {
              this.layoutService.callEnd.next(this.marketingId);
            }
            if (this.audio) {
              this.audio.pause();
            }
            // this.toggleIsOnQueue(
            //   this.configuration.configuration.twilio.workerAvailableActivitySid
            // );
            this.layoutService
              .getCallHistory(this.userData.workerSId)
              .subscribe((data) => {
                this.callLogs = data;
              }, (error) => {
                this.handleError.handleError(error)
              });
          },
        }
        this.softphone.start(listeners, this.audioElements);

        const phoneNumber = from;
        const virtualNumber = this.callerIds
        await this.softphone.makeCall(phoneNumber, virtualNumber);

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  //Place a call Function - Referral
  callNumber2(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('Referrals'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.ReferralService.updateCallStatus({
          calledBy: this.userData.firstName + " " + this.userData.lastName,
          id: id,
          status: "notanswered",
        }).subscribe((res) => { }, (error) => {
          this.handleError.handleError(error)
        });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.ReferralService.updateCallSid({
              id: id,
              sid: this.connection.parameters.CallSid,
            }).subscribe((data) => {
              this.ReferralService.updateCallStatus({
                calledBy: this.userData.firstName + " " + this.userData.lastName,
                id: id,
                status: "answered",
              }).subscribe((res) => { }, (error) => {
                this.handleError.handleError(error)
              });
              this.ReferralService.getCallSid.next(
                this.connection.parameters.CallSid
              );
            }, (error) => {
              this.handleError.handleError(error)
            });
          }
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.ReferralService.callStatus.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      });
    });
  }

  //Place a call Function - Referral
  callNumber3(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('Referrals'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: this.selectedCallerId,
        });
        this.connection = connection;
        this.ReferreeService.updateCallStatus({
          calledBy: this.userData.firstName + " " + this.userData.lastName,
          id: id,
          status: "notanswered",
        }).subscribe((res) => { }, (error) => {
          this.handleError.handleError(error)
        });

        this.agentService
          .updateStatus({
            id: this.userData["id"],
            status: "Busy",
            fcmtoken: this.userData.fcmtoken,
            token: "",
          })
          .subscribe((data) => { }, (error) => {
            this.handleError.handleError(error)
          });

        this.currentStatus = "Busy";

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.ReferreeService.updateCallSid({
              id: id,
              sid: this.connection.parameters.CallSid,
            }).subscribe((data) => {
              // this.acceptAgentCall()

              this.ReferreeService.updateCallStatus({
                calledBy: this.userData.firstName + " " + this.userData.lastName,
                id: id,
                status: "answered",
              }).subscribe((res) => { }, (error) => {
                this.handleError.handleError(error)
              });
              this.ReferreeService.getCallSid.next(
                this.connection.parameters.CallSid
              );
            }, (error) => {
              this.handleError.handleError(error)
            });
          }


        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.ReferreeService.callStatus.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      });
    });
  }
  //Place a call Function - Transfer	
  callNumber4(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('Transfer'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
    setTimeout(() => {
      let connection = Twilio.Device.connect({
        phone: `${from}`,
        callerId: transferCall,
      });
      this.connection = connection;
      this.TransferService
        .updateCallStatus({
          calledBy: this.userData.firstName + " " + this.userData.lastName,
          id: id,
          status: "notanswered",
        })
        .subscribe((res) => { }, (error) => {
          this.handleError.handleError(error)
        });
      connection.on("accept", () => {
        this.isOutgoing = true;
        this.isIncoming = false;
        this.isCallcomming = false;
        this.isCallingProgress = false;
        if (id) {
          this.TransferService
            .updateCallSid({
              id: id,
              sid: this.connection.parameters.CallSid,
            })
            .subscribe((data) => {
              // this.acceptAgentCall()	
              this.TransferService
                .updateCallStatus({
                  calledBy: this.userData.firstName + " " + this.userData.lastName,
                  id: id,
                  status: "answered",
                })
                .subscribe((res) => { }, (error) => {
                  this.handleError.handleError(error)
                });
              this.TransferService.getCallSid.next(
                this.connection.parameters.CallSid
              );
            }, (error) => {
              this.handleError.handleError(error)
            });
        }
      });
      connection.on("pending", () => { });
      connection.on("connecting", () => { });
      connection.on("ringing", () => {
        this.TransferService.callStatus.next('Ringing...');
      });
      connection.on("open", () => { });
      connection.on("cancel", (connection) => { });
      connection.on("reject", (connection) => { });
      connection.on("close", () => { });
      connection.on("answered", () => { });
      connection.on("disconnect", () => {
        this.dailerScreen = "dailer";
        this.isReservation = false;
        this.isCallingProgress = false;
        this.isOutgoing = false;
        this.agentCall = "";
        this.getCallNumbers();;
        this.layoutService
          .getCallHistory(this.userData.workerSId)
          .subscribe((data) => {
            this.callLogs = data;
          }, (error) => {
            this.handleError.handleError(error)
          });
      });
    }, 1000);
  }
  //Place a call Function - Transfer Student WebApp
  callNumber6(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('University Hub'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        this.TransferService
          .updateCallStatus({
            calledBy: this.userData.firstName + " " + this.userData.lastName,
            id: id,
            status: "notanswered",
          })
          .subscribe((res) => { }, (error) => {
            this.handleError.handleError(error)
          });
        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.TransferService
              .updateCallSid({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                // this.acceptAgentCall()	
                this.TransferService
                  .updateCallStatus({
                    calledBy: this.userData.firstName + " " + this.userData.lastName,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
                this.TransferService.getCallSid.next(
                  this.connection.parameters.CallSid
                );
              }, (error) => {
                this.handleError.handleError(error)
              });
          }

        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.TransferService.callStatus.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  //Place a call Function - Enquiries	
  callNumber5(from, id, projectId) {
    this.userData = JSON.parse(localStorage.getItem("userData"));

    this.projectService.getProject(projectId).subscribe((data) => {
      this.currentCompanyData = data;
      this.basicFucntionCalls();
      if (data["CallerIds"]) {
        this.callerIds = data["CallerIds"];
        this.selectedCallerId = data["CallerIds"][0];

      } else {
        this.callerIds = [data["TwilioNumber"]];
        this.selectedCallerId = data["TwilioNumber"];
      }
      let connection = Twilio.Device.connect({
        phone: `${from}`,
        callerId: this.selectedCallerId,
      });
      this.connection = connection;
      this.EnquiryService
        .updateCallStatus({
          id: id,
          status: "notanswered",
          ProjectId: projectId
        })
        .subscribe((res) => { }, (error) => {
          this.handleError.handleError(error)
        });

      this.agentService
        .updateStatus({
          id: this.userData["id"],
          status: "Busy",
          fcmtoken: this.userData.fcmtoken,
          token: "",
        })
        .subscribe((data) => { }, (error) => {
          this.handleError.handleError(error)
        });

      this.currentStatus = "Busy";

      connection.on("accept", () => {
        this.isOutgoing = true;
        this.isIncoming = false;
        this.isCallcomming = false;
        this.isCallingProgress = false;
        if (id) {
          this.EnquiryService
            .updateCallSid({
              id: id,
              sid: this.connection.parameters.CallSid,
            })
            .subscribe((data) => {

              // this.acceptAgentCall()
              this.EnquiryService
                .updateCallStatus({
                  id: id,
                  status: "answered",
                  ProjectId: projectId
                })
                .subscribe((res) => { }, (error) => {
                  this.handleError.handleError(error)
                });
              this.EnquiryService.getCallSid.next(
                this.connection.parameters.CallSid
              );
            }, (error) => {
              this.handleError.handleError(error)
            });
        }
      });
      connection.on("pending", () => { });
      connection.on("connecting", () => { });
      connection.on("ringing", () => {
        this.EnquiryService.callStatus.next('Ringing...');
      });
      connection.on("open", () => { });
      connection.on("cancel", (connection) => { });
      connection.on("reject", (connection) => { });
      connection.on("close", () => { });
      connection.on("answered", () => { });
      connection.on("disconnect", () => {
        // this.dailerScreen = "dailer";
        // this.isReservation = false;
        this.isCallingProgress = false;
        this.isOutgoing = false;
        this.agentCall = "";
        this.getCallNumbers();;
        this.layoutService
          .getCallHistory(this.userData.workerSId)
          .subscribe((data) => {
            this.callLogs = data;
          }, (error) => {
            this.handleError.handleError(error)
          });
      });
    })
  }

  callNumberRecruiter(from, id) {
    var transferCall;
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      this.projectId = projects.filter(x => x.Name.includes('Recruiter'))[0].id;
      this.projectService.getProject(this.projectId).subscribe((data) => {
        this.currentCompanyData = data;
        this.callerIds = [data["TwilioNumber"]];
        transferCall = data["TwilioNumber"];
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: transferCall,
        });
        this.connection = connection;
        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (id) {
            this.RecruiterService
              .updateCallSidV2({
                id: id,
                sid: this.connection.parameters.CallSid,
              })
              .subscribe((data) => {
                this.RecruiterService.getCallSid.next(
                  this.connection.parameters.CallSid
                );
                this.RecruiterService
                  .updateCallStatusV2({
                    calledBy: this.userData.mail,
                    id: id,
                    status: "answered",
                  })
                  .subscribe((res) => { }, (error) => {
                    this.handleError.handleError(error)
                  });
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.RecruiterService.callStatus.next('Ringing...');
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        connection.on("answered", () => { });
        connection.on("disconnect", () => {
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.getCallNumbers();;
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  //Stop Call
  stopCall(phoneNumber) {
    this.agentService
      .updateStatus({
        id: this.userData["id"],
        status: "Online",
        fcmtoken: this.userData.fcmtoken,
        token: "",
      })
      .subscribe((data) => { }, (error) => {
        this.handleError.handleError(error)
      });

    this.currentStatus = "Online";

    this.uhleadsservice.stopCall.subscribe((data) => {
      this.connection.disconnect();
      Twilio.Device.disconnectAll();
    });
  }

  // To place Call
  async agentToCall(from) {
    if (
      this.isOutgoing === false &&
      this.isIncoming === false &&
      this.isCallcomming === false &&
      this.isCallingProgress === false
    ) {
      this.callStatus = "Calling..";
      if (!this.isShownMarketing) {
        this.showDailer = true;
        this.dailerScreen = "outgoing";
      }
      this.isAgentCalling = true;
      if (this.isShownMarketing) {
        this.dialerScreen1 = "outgoing1";
        this.dailerScreen = "nocall";
      }
      this.agentCall = from;
      this.direction = "outbound";
      this.phoneNumber = from;
      console.log("from",)
      if (from.split("+91").length == 2) {
        this.dailerScreen = "outgoing";

        const listeners1 = {
          onConnectionStateChange: (type, newState, attemptReconnection, error) => {
            // type : "UserAgentState" | "RegisterState"
            // if type is "UserAgentState", newState: "Started" | "Stopped"
            // if type is "RegisterState", newState: "Initial" | "Registered" | "Terminated" | "Unregistered"

            console.log("**************************************************")
            console.log("Type : ", type)
            console.log("newState : ", newState)
            console.log("attemptReconnection : ", attemptReconnection)
            console.log("**************************************************")
          },

          onCallCreated: (type, details) => {
            console.log("5onCallCreated **************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallCreated**************************************************")

            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}

          },

          onCallRinging: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show on call action buttons to user
            console.log(" onCallRinging**************************************************");
            this.callStatus = "Ringing ...";

            console.log("Type : ", type)
            console.log("details : ", details)
            console.log(" onCallRinging**************************************************")
            this.audio = new Audio();
            // this.audio.src = "../../assets/audio/caller_tune.mp3";
            // this.audio.load();
            // this.audio.play();
          },

          onCallHangup: (type, details) => {
            // type: "Incoming" | "Outgoing"
            // details : Object {candidate: "+91936*******"}
            // show apt ui
            console.log(" onCallHangup**************************************************")
            console.log("Type : ", type)
            console.log("details : ", details)
            // this.uhleadsservice.stopCallFJsfc.next(details.candidate)
            console.log(" onCallHangup**************************************************")
            this.callStatus = "Ringing ...";
            this.dailerScreen = "dailer";
            this.isReservation = false;
            this.isOutgoing = false;
            this.phoneNumber = "";
            setTimeout(() => {

              this.layoutService
                .getCallHistory(this.userData.workerSId)
                .subscribe((data) => {
                  this.callLogs = data;
                }, (error) => {
                  this.handleError.handleError(error)
                });
            }, 5000);
          },
        }
        this.softphone.start(listeners1, this.audioElements);

        const phoneNumber = this.phoneNumber;
        const virtualNumber = this.selectedCallerId
        await this.softphone.makeCall(phoneNumber, virtualNumber);

      }
      else {
        let connection = Twilio.Device.connect({
          phone: `${from}`,
          callerId: this.selectedCallerId,
        });
        this.toggleIsOnQueue(
          this.configuration.configuration.twilio.workerUnavailableActivitySid
        );
        this.connection = connection;
        // this.dailerScreen = 'outgoing';

        connection.on("accept", () => {
          this.isOutgoing = true;
          this.isIncoming = false;
          this.isCallcomming = false;
          this.isCallingProgress = false;
          if (this.isShownMarketing) {
            if (this.marketingId["id"]) {
              this.layoutService
                .updateCallSid({
                  id: this.marketingId["id"],
                  sid: this.connection.parameters.CallSid,
                })
                .subscribe((data) => {
                  this.UhubCallSid = this.connection.parameters.CallSid;
                  this.layoutService.updateCallStatus({
                    id: this.marketingId["id"],
                    status: 'accept',
                    calledBy: this.userData.mail
                  }).subscribe((res: any) => {
                    this.layoutService.getCallSid.next(this.connection.parameters.CallSid)
                  })
                }, (error) => {
                  this.handleError.handleError(error)
                });
            }
          }
          // this.acceptAgentCall()
        });
        connection.on("pending", () => { });
        connection.on("connecting", () => { });
        connection.on("ringing", () => {
          this.callStatus = "Ringing...";
        });
        connection.on("open", () => { });
        connection.on("cancel", (connection) => { });
        connection.on("reject", (connection) => { });
        connection.on("close", () => { });
        this.connection.on("answered", () => { });

        connection.on("disconnect", () => {
          this.callStatus = "Call Ended";
          this.dailerScreen = "dailer";
          this.isReservation = false;
          this.isCallingProgress = false;
          this.isOutgoing = false;
          this.agentCall = "";
          this.layoutService
            .getCallHistory(this.userData.workerSId)
            .subscribe((data) => {
              this.callLogs = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
        });
      }
    }
  }

  async hangUp() {
    if (this.is_countryselected) {
      this.selectedCountryCode = 91;
      await this.softphone.getSession.end();

    }
    else {
      this.selectedCountryCode = 1;

      Twilio.Device.disconnectAll();
    }
    this.isCallcomming = false;
    this.isIncoming = false;
    this.isOutgoing = false;
    this.isCallingProgress = false;


    if (this.audio) {
      this.audio.pause();
    }

    if (this.isReservation) {
      this.complete();
    }
    if (!this.isReservation) {
      this.dailerScreen = "nocall";
    }
    this.phoneNumber = "";

  }
  hangUpfreJunCall() {

    // Twilio.Device.disconnectAll();
    this.isCallcomming = false;
    this.isIncoming = false;
    this.isOutgoing = false;
    this.isCallingProgress = false;
    if (this.audio) {
      this.audio.pause();
    }

    if (this.isReservation) {
      this.complete();
    }
    this.countupTimerService.stopTimer();
    if (!this.isReservation) {
      this.dailerScreen = "nocall";
    }

  }

  async hangUpOutgoingCall() {

    if (this.isShownMarketing) {
      this.dialerScreen1 = "dialer";
    }
    this.dailerScreen = "dailer";
    this.isCallcomming = false;
    this.isIncoming = false;
    this.isOutgoing = false;
    this.isCallingProgress = false;
    this.phoneNumber = '';
    this.layoutService.stopCalls.next("stopping calls");
    if (this.is_countryselected) {
      this.selectedCountryCode = 91;
      await this.softphone.getSession.end();
    }
    else {
      this.selectedCountryCode = 1;
      this.connection.disconnect();
    }

    // Twilio.Device.disconnectAll();
    if (this.audio) {
      this.audio.pause();
    }
    this.callStatus = "Call Ended";
  }

  conferenceData() {
    if (this.direction === "outbound") {
      this.layoutService
        .getConferenceData(this.connection.parameters.CallSid, this.projectId)
        .then((res) => {
          return res;
        }).catch((err => {
          this.handleError.handleError(err)
        }))
    } else {
      return {
        conferenceSid: this.task.attributes.conference.sid,
        callSid: this.task.attributes.conference.participants.customer,
      };
    }
  }

  toggleTransferPanel() {
    this.transfer.isLoading = true;
    if (this.taskQueue[0]) {
      this.transfer.workers = this.taskQueue; //assigning queue data to transfer.workers
      this.transfer.isLoading = false;
    } else {
      this.transfer.workers = [];
      this.transfer.to = null;
    }
  }

  getConference() {
    this.isCollapsed = !this.isCollapsed;
    const request = {
      to: this.transfer.to, // queue name selected for transferring the call
      workerSid: this.worker.sid,
    };
    this.transfer.to = null;
    if (this.direction === "outbound") {
      this.layoutService
        .getConferenceData(this.connection.parameters.CallSid, this.projectId)
        .then((res: any) => {
          this.layoutService
            .transferCall(res.callSid, request, this.projectId)
            .then((response: any) => {
              this.transfer.isLoading = false;
              this.transfer.workers = [];
              this.transfer.to = null;
              Twilio.Device.disconnectAll();
              this.isOutgoing = false;
              this.isIncoming = false;
              this.isCallcomming = false;
              this.isCallingProgress = false;
              // this.complete();
              this.countupTimerService.stopTimer();
              this.dailerScreen = "nocall";
              this.showDailer = false;
            })
            .catch((error) => {
              console.error(error);
              this.handleError.handleError(error)

            });
        }).catch((err => {
          this.handleError.handleError(err)
        }))
    } else {
      this.layoutService
        .transferCall(
          this.task.attributes.conference.participants.customer,
          request,
          this.projectId
        )
        .then((response: any) => {
          this.transfer.isLoading = false;
          this.transfer.workers = [];
          this.transfer.to = null;
          setTimeout(() => {
            Twilio.Device.disconnectAll();
          });
          this.complete();
          this.countupTimerService.stopTimer();
          this.dailerScreen = "nocall";
          this.showDailer = false;
        })
        .catch((error) => {
          console.error(error);
          this.handleError.handleError(error)

        });
    }
  }

  async toggleHold() {
    this.hold = !this.hold;

    if (this.is_countryselected) {
      this.hold ? await this.softphone.getSession.hold() : await this.softphone.getSession.unhold()
    }
    else {
      let request = {
        conferenceSid: "",
        callSid: "",
        hold: this.hold,
      };
      if (this.direction === "outbound") {
        this.layoutService
          .getConferenceData(this.connection.parameters.CallSid, this.projectId)
          .then((res: any) => {
            request = {
              conferenceSid: res.conferenceSid,
              callSid: res.callSid,
              hold: this.hold,
            };
            this.layoutService
              .holdCall(request, this.companyId)
              .then((res) => { })
              .catch((err) => {
                this.handleError.handleError(err)

              });
          })
          .catch((err) => {
            this.handleError.handleError(err)

          });
      } else {
        request = {
          conferenceSid: this.task.attributes.conference.sid,
          callSid: this.task.attributes.conference.participants.customer,
          hold: this.hold,
        };
        this.layoutService
          .holdCall(request, this.projectId)
          .then((res) => { })
          .catch((err) => { });
      }
    }
  }

  selectCountry(country) {
    this.selectedCountryCode = country.callingCodes[0];
  }

  companyChange(data) {
    this.companyId = data;
    this.getProjects();
    this.sharedService.CompanyId.next(+data);
  }
  projectChange(data) {
    this.projectId = data;
    this.projectService.getProject(this.projectId).subscribe((data) => {
      this.currentCompanyData = data;
      this.basicFucntionCalls();
      if (data["CallerIds"]) {
        this.callerIds = data["CallerIds"];
        this.selectedCallerId = data["CallerIds"][0];

      } else {
        this.callerIds = [data["TwilioNumber"]];
        this.selectedCallerId = data["TwilioNumber"];
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.projectId = +data;
    let project = this.projects.find((data) => data["id"] === +data);
    this.layoutService.twilioNumber = project
      ? project["TwilioNumber"]
      : "+14847873052";
    this.sharedService.projectId.next(+data);
    this.sharedService.currentProject.next(
      this.projects.find((data) => data["id"] === +data)
    );
  }

  getCallerIdCountryCode(param) {
    return param.split("+91").length == 2
  }
  toggleShowDailer() {
    this.showDailer = false;
  }

  changeTab() {
    if (this.isOutgoing) {
      this.dailerScreen = "outgoing";
    }
    if (this.isIncoming) {
      this.dailerScreen = "oncall";
    }
    if (this.isCallcomming) {
      this.dailerScreen = "callcomming";
    }
  }

  toggleView() {
    this.NotificationService.currentPage.next({ page: 1 });
    this.NotificationService.recruiterUnreadCountAgent({
      agentemail: this.userData.mail,
      ProjectId: this.projectId,
      Platform: 'Recruiter'
    }).subscribe((data: any) => {
      this.notificationCount = data.unreadNotificationCount;
    }, (error) => {
      this.handleError.handleError(error)
    });
    // this.NotificationService.currentPage.next(12);
    if (this.isChat || this.isNotify) {
      this.isChat = false;
      this.isNotify = false;
    }
  }
  is_countryselected: Boolean = false
  selectCalledId(id) {
    this.selectedCallerId = id;
    if (this.selectedCallerId.split("+91").length == 2) {
      this.selectedCountryCode = 91;
      this.is_countryselected = true

    }
    else {
      this.is_countryselected = false
      this.selectedCountryCode = 1;
    }
  }

  open(content) {
    this.modalService.open(content, { size: "xl" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openDocCall(content) {
    this.modalService.open(content, { size: "xl", windowClass: 'docCall' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  stopCalls() {
    this.connection.disconnect();
    this.callStopped = true;
    this.layoutService.stopCalls.next("stopping calls");
  }

  updateComments() {
    this.layoutService
      .updateCallComments({
        id: this.marketingId["id"],
        agentMail: this.userData.mail,
        comments: this.comments,
        followUpdate:
          this.layoutService.commentsValue.interested === "interested" &&
            this.layoutService.commentsValue.counsellingDate
            ? moment(
              this.layoutService.commentsValue.counsellingDate,
              "YYYY-MM-DDTHH:mm:SSZ"
            ).format("YYYY-MM-DDTHH:mm:SSZ")
            : this.layoutService.commentsValue.callBack,
        callStatus: this.layoutService.commentsValue.interested,
        futureLead: "futureLead",
        assignedTo:
          this.layoutService.selectedAgent &&
            this.layoutService.selectedAgent.length !== 0
            ? this.layoutService.selectedAgent[0]
            : this.marketingId.lead["Assignedto"],
      })
      .subscribe((data) => {
        this.updateCallCommentsNew(this.comments, this.marketingId["id"], this.layoutService.commentsValue.interested)
        setTimeout(() => {
          this.isCustomerUpdate = false;
          this.isdemoScheduled = false;
          this.layoutService.onReset();
          this.submitted = false;
        }, 5000);
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  updateCallCommentsNew(comments, id, callstatus) {

    let payload = {
      sid: this.UhubCallSid,
      id: id,
      comments: comments,
      callstatus: callstatus
    }

    this.layoutService.updateCallCommentsNew(payload).subscribe((res: any) => {
    })

  }

  startCall() {
    this.layoutService.startCalls.next("startcalls");
  }

  async scheduleDemo() {
    let dt = this.layoutService.commentsValue.counsellingDate;
    dt =
      moment(dt).format("dddd D MMM YY") + " at " + moment(dt).format("h:m a");
    let template: string = this.marketingId.lead["Miscellaneous"]["form_name"]
      .toLowerCase()
      .includes("ielts")
      ? "ielts"
      : "usastudy";

    let num = Math.floor(Math.random() * 90000) + 10000;
    let room = "live-" + num.toString();
    this.demoScheduled = `${environment.BaseURL}videocalling/mainscreen/${this.projectId}/${room}`;

    const infoMarketingBefore16hr: InputCallData = {
      ProjectId: this.projectId,
      RecipientNumber: this.marketingId.phoneNumber.replace(/\n/g, ""),
      ScheduledCallTime: moment(
        this.layoutService.commentsValue.counsellingDate,
        "YYYY-MM-DDTHH:mm:SSZ"
      ).format("YYYY-MM-DDTHH:mm:SSZ"),
      Type: this.moduleConfig["callType"],
      CallerId: "+919398913602",
      Module: this.marketingId.module,
    };
    let smsId = null;
    let callId = null;
    let mailId = null;

    const mailRemainder: CreateAutoEmail = {
      ProjectId: this.projectId,
      Module: this.marketingId.module,
      ScheduledEmailTime: moment(
        this.layoutService.commentsValue.counsellingDate,
        "YYYY-MM-DDTHH:mm:SSZ"
      ).format("YYYY-MM-DDTHH:mm:SSZ"),
      RecipientEmail: this.marketingId.email,
      Type: this.moduleConfig["mailType"],
    };

    const smsMarketingBefore6hr: InputSMSData = {
      ProjectId: this.projectId,
      Module: this.marketingId.module,
      RecipientNumber: this.marketingId["phoneNumber"].replace(/\n/g, ""),
      ScheduledSMSTime: moment(
        this.layoutService.commentsValue.counsellingDate,
        "YYYY-MM-DDTHH:mm:SSZ"
      ).format("YYYY-MM-DDTHH:mm:SSZ"),
      Type: this.moduleConfig["smsType"],
    };
    // if(dt){}
    this.marketingService
      .getAutoDialCompany(this.projectId)
      .subscribe(async (data) => {
        if (data) {
          this.apiKey = data["API_KEY"];

          if (this.moduleConfig["callType"]) {
            if (dt) {
              callId = await this.marketingService.createAutoCallScheduler(
                infoMarketingBefore16hr,
                this.apiKey
              );
            }
          }

          if (this.moduleConfig["smsType"]) {
            if (dt) {
              smsId = await this.marketingService.createAutoSmsScheduler(
                smsMarketingBefore6hr,
                this.apiKey
              );
            }
          }

          if (this.moduleConfig["mailType"]) {
            if (dt) {
              mailId = await this.marketingService.createAutoEmail(
                mailRemainder,
                this.apiKey
              );
            }
          }

          setTimeout(() => {
            this.marketingService
              .createDemo({
                ProjectId: this.projectId,
                Name: this.marketingId.name,
                // ProspectId: this.marketingId,
                AutoEmailId: mailId ? mailId["mailId"] : null,
                AutoCallId: callId ? callId["callId"] : null,
                AutoSMSId: smsId ? smsId["smsId"] : null,
                Comments: this.comments,
                DateTime: moment(
                  this.layoutService.commentsValue.counsellingDate,
                  "YYYY-MM-DDTHH:mm:SSZ"
                ).format("YYYY-MM-DDTHH:mm:SSZ"),
                Presenter:
                  this.layoutService.selectedAgent &&
                    this.layoutService.selectedAgent.length !== 0
                    ? this.layoutService.selectedAgent[0]["value"]
                    : this.marketingId.lead["Assignedto"],
                Feedback: this.layoutService.commentsValue.interested,
                Url:
                  this.demoScheduled + "/" + this.userData.workerFriendlyName,
                ClientURL: this.demoScheduled + "/" + this.marketingId.name,
                RoomName: room,
                LeadId: this.marketingId.id,
                agentEmail: this.userData.mail,
              })
              .subscribe((demo) => {
                if (dt) {
                  this.marketingService
                    .updateDemo({
                      demoId: demo["id"],
                      leadId: this.marketingId.id,
                    })
                    .subscribe((data) => {
                      if (dt) {
                        this.layoutService.sendSmsForLead({
                          date: dt,
                          from: this.layoutService.twilioNumber,
                          projectId: this.projectId,
                          student_name: this.marketingId.name,
                          tosms: this.marketingId.phoneNumber,
                        });
                      }
                      if (dt) {
                        this.marketingService.sendMailForDemo(
                          {
                            name: this.marketingId.name,
                            demoDate: moment(
                              this.layoutService.commentsValue.counsellingDate
                            ).format("MMMM Do YYYY, h:mm:ss a"),
                            url:
                              this.demoScheduled + "/" + this.marketingId.name,
                          },
                          this.marketingId.email,
                          this.projectId,
                          template
                        );
                      }
                    }, (error) => {
                      this.handleError.handleError(error)
                    });
                }
              }, (error) => {
                this.handleError.handleError(error)
              });;
          }, 2000);

          setTimeout(() => {
            this.isdemoScheduled = false;
          }, 5000);
        }
      }, (error) => {
        this.handleError.handleError(error)
      });

    this.updateComments();
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe((data) => {
      this.totalAgents = data.map((data) => ({
        name: data["FirstName"] + " " + data["LastName"],
        value: data["workerFriendlyName"],
        profilePhoto: data["ProfilePhoto"],
        Status: data["Status"],
      }));
      this.sharedService.totalAgents.next(this.totalAgents);
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.layoutService.commentsForm.controls;
  }

  async submit() {
    this.comments = this.layoutService.commentsValue.comments;

    if (this.layoutService.commentsValue.name.length === 0) {
      return;
    } else {
      if (
        this.layoutService.commentsForm.get("interested").errors ||
        !this.layoutService.commentsForm.get("interested")
      ) {
        this.isIntrested = true;
      } else {
        this.isIntrested = false;
      }
      if (this.layoutService.commentsForm.invalid) {
        return;
      }
      this.layoutService.submitted = true;
      this.submitted = this.layoutService.submitted;

      if (
        this.layoutService.commentsValue.interested === "QualifiedLead" ||
        this.layoutService.commentsValue.interested === "QualifiedHotLead"
      ) {
        this.isdemoScheduled = true;
        await this.scheduleDemo();
      }
      if (
        (this.layoutService.commentsValue.interested === "QualifiedLead" ||
          this.layoutService.commentsValue.interested === "QualifiedHotLead") &&
        !this.layoutService.commentsValue.counsellingDate
      ) {
        this.isCustomerUpdate = true;
      }

      if (this.layoutService.commentsValue.interested !== "notinterested") {
        if (
          this.layoutService.commentsValue.counsellingDate &&
          (this.layoutService.commentsValue.interested === "QulaifiedLead" ||
            this.layoutService.commentsValue.interested === "QulaifiedHotLead")
        ) {
          //await this.scheduleDemo();
          if (this.updateLeadComments) {
            this.layoutService
              .updateCommentsBasedOnId(this.marketingId.id)
              .subscribe(() => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  // duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              }, (error) => {
                this.handleError.handleError(error)
              });
          } else {
            this.layoutService
              .updateComments(this.marketingId.id)
              .subscribe(() => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              }, (error) => {
                this.handleError.handleError(error)
              });
          }
        } else {
          if (this.updateLeadComments) {
            this.updateComments();
            this.layoutService
              .updateCommentsBasedOnId(this.marketingId.id)
              .subscribe(() => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              }, (error) => {
                this.handleError.handleError(error)
              });
          } else {
            this.layoutService
              .updateComments(this.marketingId.id)
              .subscribe(() => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              }, (error) => {
                this.handleError.handleError(error)
              });
            this.updateComments();
          }
        }
      } else {
        if (this.layoutService.commentsValue.interested === "notinterested") {
          if (this.updateLeadComments) {
            this.layoutService
              .updateCommentsBasedOnId(this.marketingId.id)
              .subscribe(() => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              }, (error) => {
                this.handleError.handleError(error)
              });

            this.updateComments();
          } else {
            this.layoutService
              .updateComments(this.marketingId.id)
              .subscribe(() => {
                this.snackbarRef.open("Update Successfully", "", {
                  verticalPosition: "top",
                  horizontalPosition: "end",
                  duration: 5000,
                  panelClass: ["success-snackbar"],
                });
              }, (error) => {
                this.handleError.handleError(error)
              });
            this.updateComments();
          }
        }
      }
    }
  }

  conferenceCall(value: any) {
    if (value.phone_number) {
      this.marketingService
        .conferenceCallConnect(
          {
            CallSid: this.connection.parameters.CallSid,
            phone: value.phone_number,
            callerId: this.selectedCallerId,
          },
          this.companyId
        )
        .subscribe((res) => { }, (error) => {
          this.handleError.handleError(error)
        });
    }
    if (value.manager[0]) {
      if (value.manager[0].Name) {
        this.marketingService
          .conferenceCallConnect(
            {
              CallSid: this.connection.parameters.CallSid,
              phone: "client:" + value.manager[0].value,
              callerId: this.selectedCallerId,
            },
            this.companyId
          )
          .subscribe((res) => { }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
  }

  redialContact() {
    if (this.dialerScreen1 === "dialer") {
      this.layoutService.redialContact.next(true);
      this.callStopped = false;
      this.marketingId = { ...this.redialContactId };
      this.layoutService.submitted = false;
      this.marketingService
        .getModuleConfigs(this.marketingId.module, this.projectId)
        .subscribe((data) => {
          this.moduleConfig = data;
        }, (error) => {
          this.handleError.handleError(error)
        });
      this.schedulers = false;
      this.callBack = "interested";
      this.isShownMarketing = true;
      this.isCustomerUpdate = false;
      this.agentToCall(this.marketingId.phoneNumber);
      this.layoutService.callMarketingStatus.next({
        id: this.marketingId["id"],
        status: "notanswered",
        followUpdate: this.followUpdate,
        calledBy: this.userData["firstName"] + this.userData["lastName"],
      });
      if (!this.modalService.hasOpenModals()) {
        this.open(this.content);
      } else {
        this.modalService.dismissAll();
        this.open(this.content);
      }
    }
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      this.userData = JSON.parse(localStorage.getItem("userData"));
      if (this.userData.notification_subscribe == true) {
        this.NotificationService.success(payload.data.Title, payload.data);
        this.NotificationService.currentPage.next({ page: 1 });
      }
    });
  }

  openSettings() {
    const dialogRef = this.dialog.open(SettingsComponent, {
      width: "50%",
      panelClass: "settingsClass",
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }
  openIncomingCall() {
    const dialogRef = this.dialog.open(IncomingCallsComponent, {
      width: "50%",
      panelClass: "settingsClass",
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openEnquiryCall() {
    const dialogRef = this.dialog.open(EnquiryCallsComponent, {
      width: "50%",
      panelClass: "settingsClass",
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }
  onInput(Event: any) {
    let search = Event.target.value;
    let serachData = [];
    if (search) {
      this.countries = countryCodes.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      this.countries = countryCodes;
    }
  }
  postComments(item, HTMLid) {
    var obj = {
      value: (<HTMLInputElement>document.getElementById(HTMLid)).value,
      item: this.currentItem,
    };
    this.layoutService.postComments.next(obj);
  }
  checkForRoutingNumber(item) {
    return item !== this.currentCompanyData.TwilioNumber && item !== this.currentCompanyData.FreJunNumber;
  }
}
