import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { HttpClientService } from '@angular/common/http';
import { TicketHistory } from './add-update-view-tickets/models/tickethistory.model';
import { map } from 'rxjs/internal/operators/map';
import { Ticket } from '../dashboard/models/ticket.model';
import { HttpClientService } from 'src/app/httpclient.service';


interface createCustomer {
  mail: string,
  customerName: string,
  phoneNumber: string,
  preferedContact: string,
  lastContacted: Date | string,
  requestId: string
}
interface updateCustomer {
  mail: string,
  customerName: string,
  phoneNumber: string,
  preferedContact: string,
  lastContacted: Date | string,
  address: string,
  latlng: any,
  profilePhoto:any
}

interface getCustomTickets {
  status: string,
  agentMail: string,
  admin: Boolean,
  companyId: number,
  offsetNumber: number,
  from: Date,
  to: Date,
  mail: string,


}

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  baseApiUrl: any = environment.baseApiUrl;
  baseApiUrl2: any = environment.baseApiUrl2;

  constructor(private http: HttpClientService) { }

  getUsersList(companyId) {
    return this.http.get(`${this.baseApiUrl2}getActiveAgents/${companyId}`)
  }

  getpriorityList() {
    return this.http.get(`${this.baseApiUrl2}prority`)
  }

  geturgencyList() {
    return this.http.get<any[]>(`${this.baseApiUrl2}urgency`)
  }

  getallgroups() {
    return this.http.get<any[]>(`${this.baseApiUrl2}Group`)
  }

  createTicket(body: Object) {
    return this.http.post(`${this.baseApiUrl2}createticket`, body)
  }

  getTicketByID(id) {
    return this.http.get(`${this.baseApiUrl2}ticket/${id}`)
  }

  updateTicket(object, id) {
    return this.http.put(`${this.baseApiUrl2}updateticket/${id}`, object)
  }

  getticketid() {
    return this.http.get(`${this.baseApiUrl2}ticketid`)
  }

  gettickethistory(id) {
    return this.http.get<TicketHistory[]>(`${this.baseApiUrl2}tickethistories/${id}`)
  }

  getgroupsusers(mailid) {
    return this.http.post<Array<Object>>(`${this.baseApiUrl2}getagentgroups`, { "mailid": mailid });
  }

  getusersgroups(name) {
    return this.http.post<Array<Object>>(`${this.baseApiUrl2}agentgroups`, { name: name })
  }
  createCustomer(body: createCustomer) {
    return this.http.post(`${this.baseApiUrl2}createCustomer`, body);
  }
  updateCustomer(body: updateCustomer, id) {
    return this.http.put(`${this.baseApiUrl2}updateCustomer/${id}`, body);
  }
  getCustomerByTicket(ticketNumber: string) {
    return this.http.get(`${this.baseApiUrl2}customerByticket/${ticketNumber}`)
  }
  getCustomerByMail(mail: string) {
    return this.http.get(`${this.baseApiUrl2}customerByMail/${mail}`)
  }
  getCustomerTickets(id, companyId) {
    return this.http.get<any[]>(`${this.baseApiUrl2}customerTickets/${id}/${companyId}`);
  }

  getCustomerByPhone(phoneNumber: string) {
    return this.http.get<any[]>(`${this.baseApiUrl2}customerByPhoneNumber/${phoneNumber}`)
  }

  getCustomTickets(body: getCustomTickets) {
    return this.http.post<any[]>(`${this.baseApiUrl2}getCustomTickets`, body).pipe(map(res => {
      let myTickets: Ticket[] = [];
      for (let item of res) {
        let Assignedto = item['Assignedto'];
        let CreatedDate = item['CreatedDate'];
        let ReportedBy = item['ReportedBy'];
        let RequestId = item['RequestId'];
        let RequestStatus = item['RequestStatus'];
        let UpdatedDate = item['UpdatedDate'];
        let group = item['AssignmentGroup'];
        let id = item['id'];
        let summary = item['RequestDetail'];
        let TicketRead = item['TicketUnRead']
        myTickets.push(new Ticket(id, summary, ReportedBy, UpdatedDate, Assignedto, group, CreatedDate, RequestId, RequestStatus, TicketRead, item['TicketChannel'], item['TotalCount']));
      }
      // 
      return myTickets;
    }))
  }

  getWebChat(ticketNumber: string) {
    return this.http.post<any>(`${this.baseApiUrl2}getWebChatByTicket`, { ticketId: ticketNumber });
  }
  getAgentLocation(mail: string) {
    return this.http.get(`${this.baseApiUrl2}agentLocation/${mail}`);
  }

}
