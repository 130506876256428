import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventScheduleService {
  baseApiUrl = environment.baseApiUrl2;

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder) {
     
  }
  getAgentEventDetails(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/getAgentEventDetails`, data);
  }
  createEventSchedule(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventSchedule/createEventSchedule`, data);
  }
  getScheduleEventById(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventSchedule/getScheduleEventById`, data);
  }
  updateScheduleEventDetails(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventSchedule/updateScheduleEventDetails`, data);
  }
  updateEventRespond(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventSchedule/updateEventRespond`, data);
  }
 
}
