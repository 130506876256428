<div class="mail-pop">
	<div class="modal-header">
		<h4 class="modal-title">Map View</h4>
		<button type="button" class="close" aria-label="Close" (click)="dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group" *ngIf="type === 'ticket'">
			<label>Enter address</label>
			<input
				class="form-control"
				ngx-google-places-autocomplete
				#placesRef="ngx-places"
				(onAddressChange)="handleAddressChange($event)"
				[(ngModel)]="address"
			/>
		</div>

		<agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
			<agm-marker
				[latitude]="lat"
				[longitude]="lng"
				[markerDraggable]="true"
				(dragEnd)="changeLocation($event)"
			>
				<agm-info-window>agent1</agm-info-window>
			</agm-marker>
		</agm-map>
	</div>
	<div class="modal-footer" *ngIf="type === 'ticket'">
		<button class="btn btn-primary" (click)="saveAddress()">Save</button>
	</div>
</div>
