import { InputSMSData, MarketingService } from "./../marketing.service";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import * as xlsx from "xlsx";
import { Exceldata } from "../smsmarketing/models/ExecelData.model";
import { SharedService } from "src/app/shared/shared.service";
import { LayoutService } from "src/app/layout/layout.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/account/auth/auth.service";
import { RoleService } from "../../role-management/role.service";
import { Subscription } from "rxjs";
import { ProjectService } from "../../project-management/project.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DemopageComponent } from "../demopage/demopage.component";
import { AbstractControl, Validators } from "@angular/forms";
import * as moment from "moment";
// import { MatSnackBar } from "@angular/material";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorHandlerService } from "src/app/error-handler.service";
@Component({
  selector: "app-leads-dashboard",
  templateUrl: "./leads-dashboard.component.html",
  styleUrls: ["./leads-dashboard.component.css"],
})
export class LeadsDashboardComponent implements OnInit, OnDestroy {
  public fileSource = environment.fileServer;
  public callData: any[] = [];
  public filePath: any;
  public fileData: Exceldata[] = [];
  public userData: any;
  public projectId: any;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public search: any;
  public page: any = 1;
  public pageSize: any = 8;
  public startCall: boolean = false;
  public stopCall: boolean = false;
  public startIndex: number = 0;
  public statusValue = "pending";
  public clearTime: any;
  public downloadLink: string;
  @ViewChild("uploadFile") uploadFile;
  public phoneNumber: any;
  public comments: any;
  public followUpdate: any;
  closeResult: string;
  admin: boolean = false;
  public error: boolean = false;
  public errorMessage: string = "";
  public insertCount: number = 0;
  public uploaded: boolean = false;
  public dashboardInfo: any = {
    Facebook: 0,
    Website: 0,
    pending: 0,
    AssignedTo: 0,
    followup: 0,
  };
  public project: Subscription;
  public currentUser: any = {};
  public leadType: string = "pending";
  @ViewChildren("icon") audioIcons: any;
  @ViewChildren("audio") audios: any;
  public mail: string;
  public sendMail: {
    from: string;
    to: string;
    subject: string;
    content: string;
  } = { from: "", to: "", subject: "", content: "" };
  public tempList: any[] = [];
  public modalRef: any;
  public counselling: boolean = false;
  public submitted: boolean = false;
  public isUpdateLead: boolean = false;
  public currentLead: any;

  constructor(
    public marketingService: MarketingService,
    private readonly sharedService: SharedService,
    public layoutService: LayoutService,
    private readonly modalService: NgbModal,
    private readonly loginService: AuthService,
    private readonly companyService: RoleService,
    private readonly projectService: ProjectService,
    private readonly route: ActivatedRoute,
    private readonly snackbarRef: MatSnackBar,
    private readonly handleError: ErrorHandlerService,
    private router: Router
  ) { }
  ngOnDestroy(): void {
    if (this.project) {
      this.project.unsubscribe();
    }
  }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      if (data["roomId"]) {
        this.modalRef = this.modalService.open(DemopageComponent, {
          size: "lg",
        });
        this.modalRef.componentInstance.roomName = data["roomId"];
        this.modalRef.componentInstance.name = data["name"];
      }
      if (data["lead"]) {
        this.statusValue = data["lead"];
      }
    });

    this.marketingService
      .getExcelLink(this.fileSource + "/TemplateDocuments/accessque.xlsx")
      .subscribe((data) => {
        this.downloadLink = data["url"];
        // 

      }, (error) => {
        this.handleError.handleError(error)
      });
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.project = this.sharedService.projectId.subscribe((data) => {
    this.companyService.projectAdmin(this.userData['companyId']).subscribe((projects) => {
      if (projects) {
        this.projectId = projects.filter(x => x.Name == 'University Hub')[0].id;
        // this.projectId = data;
        this.projectService.getProject(this.projectId).subscribe((data) => {
          this.mail = data["SupportMail"];
        }, (error) => {
          this.handleError.handleError(error)
        });
        this.sharedService.getProjectAdmin(this.projectId).subscribe((data) => {
          this.admin = data;
          localStorage.setItem("RolePermission", data);
          // 
          // 
          if (!this.admin) {
            this.marketingService
              .checkCounsellingCandidate({
                agentId: this.userData.id,
                projectId: this.projectId,
              })
              .subscribe((data) => {
                this.counselling = data["counselling"];
                if (data["counselling"]) {
                  this.statusValue = "interested";
                }
                this.getDashboardInfo();
                this.getDashboardCompletedCallsCount();
                this.topBarTitle();
                this.getDashboardLeads(this.statusValue);
              }, (error) => {
                this.handleError.handleError(error)
              });
          } else {
            this.getDashboardInfo();
            this.getDashboardCompletedCallsCount();
            this.topBarTitle();
            this.getDashboardLeads(this.statusValue);
          }
        }, (error) => {
          this.handleError.handleError(error)
        });
      }
    }, (error) => {
      this.handleError.handleError(error)
    });

    let stopCallsSub = this.layoutService.stopCalls.subscribe((data) => {
      // 
      if (data) {
        this.stopCallListener();
        this.startCall = false;
      }
    });
    let startCallsSub = this.layoutService.startCalls.subscribe((data) => {
      if (data) {
        // 

        this.startCallListener();
      }
    });

    let redial = this.layoutService.redialContact.subscribe((data) => {
      if (data) {
        clearTimeout(this.clearTime);
      }
    });

    let callEndSub = this.layoutService.callEnd.subscribe((data) => {
      // 
      if (this.statusValue === "interested") {
        this.stopCall = true;
        this.startCall = false;
        return;
      }
      if (this.startCall && this.callData.length !== 0) {
      } else {
        this.startCall = false;
      }
    });

    let marketingStatusSub = this.layoutService.callMarketingStatus.subscribe(
      (data) => {
        // 
        if (data["id"] !== "") {
          this.marketingService
            .updateCallStatus({
              status: data["status"],
              id: data["id"],
              calledBy: data["calledBy"],
              followUpdate: data.followUpdate,
            })
            .subscribe((data) => {
              this.counselling = data["counselling"];
              // 
              this.getDashboardLeads(this.leadType);
              this.getDashboardInfo();
              this.getDashboardCompletedCallsCount();
            }, (error) => {
              this.handleError.handleError(error)
            });
        }
      }
    );
  }

  getDashboardCompletedCallsCount() {
    if (!this.projectId) {
      return;
    }

  }

  getDashboardInfo() {
    if (!this.projectId) {
      return;
    }
    return this.marketingService
      .getLeadDashboardInfo({
        admin: this.admin,
        mail: this.userData["mail"],
        ProjectId: this.projectId,
        counselling: this.counselling,
      })
      .subscribe((data) => {
        this.dashboardInfo["Facebook"] = data["Facebook"] || 0;
        this.dashboardInfo["Website"] = data["Website"] || 0;
        this.dashboardInfo["pending"] = data["pending"] || 0;
        this.dashboardInfo["AssignedTo"] = data["AssignedTo"] || 0;
        this.dashboardInfo["followup"] = data["followup"] || 0;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  getDashboardLeads(leadType) {
    if (!this.projectId) {
      return;
    }
    // this.statusValue = "pending";
    this.leadType = leadType;
    return this.marketingService
      .getDashboardLeads({
        admin: this.admin,
        mail: this.userData["mail"],
        ProjectId: this.projectId,
        leadType: this.leadType,
        counselling: this.counselling,
      })
      .subscribe((data) => {
        this.callData = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  sectionLeads(leadType) {

    if (!this.counselling) {
      this.statusValue = "pending";
    }
    if (leadType === "facebook" || leadType === "Website") {
      this.statusValue = "Website"
    }
    else if (leadType === "followup") {
      this.statusValue = "callback"
    }

    else if (leadType === "pending") {
      this.statusValue = "pending";
    }
    else {
      if (leadType === "pending") {
        leadType = "interested";
      }

    }
    this.getDashboardLeads(leadType);
  }

  topBarTitle() {
    return this.companyService
      .getCompanyById(this.projectId)
      .subscribe((data) => {
        this.sharedService.pageEvent.emit({
          pageName: "Leads Dashboard",
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  convertFile(e) {
    this.filePath = e.target.files[0]["name"].toString();
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "First Name" && sheet_data.length !== 0) {
        this.fileData =
          this.marketingService.convert2dArraytoObject(sheet_data);
        this.marketingService
          .postCallData({
            data: this.fileData,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
            type: 'Excel'
          })
          .subscribe((data) => {
            this.uploaded = true;
            this.error = false;
            this.insertCount = data["count"];
            setTimeout(() => {
              this.uploaded = false;
              this.filePath = "";
            }, 5000);
            this.getDashboardLeads(this.leadType);
          }, (error) => {
            this.handleError.handleError(error)
          });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }

  public onInput(event: any) {
    this.statusValue = "Website";
    this.getDashboardLeads("All");
  }

  getCallData() {
    this.marketingService
      .getCallData({
        ProjectId: this.projectId,
        callStatus: this.statusValue,
        mailId: this.userData["mail"],
        admin: this.admin,
      })
      .subscribe((data) => {
        this.callData = data;
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  startCallListener() {
    if (this.callData.length === 0) {
      this.startCall = false;
      return;
    }

    if (!this.startCall) {
      if (this.statusValue === "pending") {
        this.startIndex = 0;
      }

      this.layoutService.marketingCall.next({
        id: this.callData[this.startIndex]["id"],
        number: "+" + this.callData[this.startIndex]["CountryCode"] + this.callData[this.startIndex]["PhoneNumber"],
        name: this.callData[this.startIndex]["FirstName"],
        email: this.callData[this.startIndex]["Email"],
        comments: this.callData[this.startIndex]["Comments"],
        module: this.callData[this.startIndex]["Module"],
        city: this.callData[this.startIndex]["city"],
        nextCaller: this.callData[this.startIndex + 1],
        lead: this.callData[this.startIndex],

      });
      this.startCall = true;
    }
  }

  followUpCall(data) {
    if (!this.startCall) {
      this.layoutService.marketingCall.next({
        id: data["id"],
        number: "+" + data["CountryCode"] + data["PhoneNumber"],
        name: data["FirstName"],
        email: data["Email"],
        comments: data["Comments"],
        module: data["Module"],
        city: data["city"],
        nextCaller: null,
        lead: data,
      });
      this.startCall = true;
    }

  }

  open(content) {
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    this.phoneNumber = "";
    this.comments = "";
    this.followUpdate = "";
    this.submitted = false;
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  stopCallListener() {
    this.stopCall = true;
    this.startCall = false;
    if (this.statusValue !== "pending") {
      this.startIndex += 1;
    }
    clearTimeout(this.clearTime);
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  dropdownSelect(event: string) {
    this.getDashboardLeads(event);
    // this.getCallData();
    this.search = "";
    this.startIndex = 0;
    this.getDashboardInfo();
    this.getDashboardCompletedCallsCount();
  }

  showCallDetails(content, id) {
    this.marketingService
      .showDetails(id, this.statusValue)
      .subscribe((data) => {
        this.currentUser = data;

        this.open(content);
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  deleteContact(id) {
    this.marketingService.deleteContact(id).subscribe((data) => {
      this.getDashboardLeads(this.leadType);
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  audioPlay(audio: any, icon: any) {
    if ([...icon.classList].includes("fa-play")) {
      this.audioIcons._results.forEach((element) => {
        if ([...element.nativeElement.classList].includes("fa-pause")) {
          element.nativeElement.classList.remove("fa-pause");
          element.nativeElement.classList.add("fa-play");
        }
      });
      this.audios._results.forEach((element) => {
        element.nativeElement.pause();
      });
      icon.classList.add("fa-pause");
      icon.classList.remove("fa-play");
      audio.play();
    } else {
      icon.classList.add("fa-play");
      icon.classList.remove("fa-pause");
      audio.pause();
    }

    let current = this.audios.filter((el) => el === audio);
    // 
  }

  updateComments(modal, item) {
    this.layoutService.commentsForm.get('interested').clearValidators();
    this.layoutService.commentsForm.get('interested').updateValueAndValidity();
    this.layoutService.commentsForm.get('counsellingDate').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('counsellingDate').value))
    this.layoutService.commentsForm.get('callBack').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('callBack').value))
    this.layoutService.commentsForm.get('dob').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('dob').value));
    // this.layoutService.commentsForm.get('callBack').patchValue(this.layoutService.dateFormatForAddUpdate(this.layoutService.commentsForm.get('callBack').value))
    this.layoutService.commentsForm.get('interested').valueChanges.subscribe(async (selectedValue) => {
      await this.layoutService.commentsForm.get('callBack').clearValidators();
      await this.layoutService.commentsForm.get('callBack').updateValueAndValidity()
      await this.layoutService.commentsForm.get('counsellingDate').clearValidators();
      await this.layoutService.commentsForm.get('counsellingDate').updateValueAndValidity()
      await this.layoutService.commentsForm.get('assignedTo').clearValidators()
      await this.layoutService.commentsForm.get('assignedTo').updateValueAndValidity()
      if (selectedValue == 'Callback') {
        await this.layoutService.commentsForm.get('callBack').setValidators([Validators.required]);
        this.layoutService.commentsForm.get('callBack').updateValueAndValidity();
      } else if (selectedValue == 'QualifiedLead' || selectedValue == 'QualifiedHotLead') {
        await this.layoutService.commentsForm.get('counsellingDate').setValidators([Validators.required])
        this.layoutService.commentsForm.get('counsellingDate').updateValueAndValidity();
        await this.layoutService.commentsForm.get('assignedTo').setValidators([Validators.required])
        this.layoutService.commentsForm.get('assignedTo').updateValueAndValidity();
      }
    })
    this.layoutService.onReset();
    this.currentLead = item;
    this.layoutService.commentsForm.patchValue({
      name: item["FirstName"],
      emailId: item["Email"],
      qualification: item["Education"],
      state: item["City"],
      countryCode: item["CountryCode"],
      phoneNumber: item["PhoneNumber"],
    });
    this.marketingService
      .showDetails(this.currentLead["id"], this.statusValue)
      .subscribe((data) => {
        this.layoutService.commentsForm.patchValue({
          comments:
            data["Phase"] === "demo"
              ? data["DemoComments"] || ""
              : data["Comments"] || "",
        });
      }, (error) => {
        this.handleError.handleError(error)
      });;

    this.layoutService.getCommentLead(item["id"]).subscribe((data) => {
      if (data) {
        this.isUpdateLead = true;
      } else {
        this.isUpdateLead = false;
      }
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.modalService.open(modal, { size: "xl" });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.layoutService.commentsForm.controls;
  }
  get createLeads(): { [key: string]: AbstractControl } {
    return this.marketingService.createLead.controls;
  }

  async submit() {
    // this.snackbarRef.open("Mail Sent Successfully", "Close", {
    //   verticalPosition: "top",
    //   horizontalPosition: "end",
    //   duration: 5000,
    // });
    if (this.isUpdateLead) {

      if (this.layoutService.commentsForm.invalid) {
        // 

      } else {
        this.layoutService
          .updateCommentsBasedOnId(this.currentLead["id"])
          .subscribe(() => {
            this.updateLeadComments();
            this.snackbarRef.open("Update Successfully", '', {
              verticalPosition: "top",
              horizontalPosition: "end",
              duration: 5000,
              panelClass: ['success-snackbar']
            });
            this.modalService.dismissAll();
            this.getDashboardLeads(this.leadType);
          }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
    else {
      if (this.layoutService.commentsForm.invalid) {
        // 
        // 

      } else {
        this.layoutService
          .updateComments(this.currentLead["id"])
          .subscribe(() => {
            this.updateLeadComments();
            this.modalService.dismissAll();
          }, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
    if (this.layoutService.commentsValue.name.length === 0) {
      return;
    }
    this.layoutService.submitted = true;
    this.submitted = this.layoutService.submitted;
    if (this.layoutService.commentsValue.interested !== "notinterested") {
      if (
        this.layoutService.commentsValue.counsellingDate &&
        (this.layoutService.commentsValue.interested === "QualifiedLead" ||
          this.layoutService.commentsValue.interested === "QualifiedHotLead")
      ) {
        // 
        await this.scheduleDemo();
      } else {
        this.updateLeadComments();
      }
    } else {
      if (this.layoutService.commentsValue.interested === "notinterested") {
        this.marketingService
          .updateLeadStatus({
            id: this.currentLead.id,
            phase: this.layoutService.commentsValue.interested,
          }).subscribe(()=>{}, (error) => {
            this.handleError.handleError(error)
          });
      }
    }
  }

  updateLeadComments() {
    if (this.currentLead.phase !== "demo") {
      let callStatus = "interested";
      let futureLead = "no";
      if (this.layoutService.commentsValue.interested === "futurelead") {
        callStatus = "interested";
        futureLead = "yes";
      } else {
        callStatus = this.layoutService.commentsValue.interested;
        futureLead = "no";
      }
      this.layoutService
        .updateCallComments({
          id: this.currentLead["id"],
          agentMail: this.userData.mail,
          comments: this.layoutService.commentsValue.comments,
          followUpdate:
            this.layoutService.commentsValue.interested === "interested" &&
              this.layoutService.commentsValue.counsellingDate
              ? moment(
                this.layoutService.commentsValue.counsellingDate,
                "YYYY-MM-DDTHH:mm:SSZ"
              ).format("YYYY-MM-DDTHH:mm:SSZ")
              : this.layoutService.commentsValue.callBack,
          callStatus: this.layoutService.commentsValue.interested,
          futureLead: futureLead,
          assignedTo:
            this.layoutService.selectedAgent &&
              this.layoutService.selectedAgent.length !== 0
              ? this.layoutService.selectedAgent[0]
              : this.currentLead["Assignedto"],
        })
        .subscribe((data) => {
          // 
          
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }

  scheduleDemo() {
    let dt = this.layoutService.commentsValue.counsellingDate;
    dt = moment(dt).format("dddd D MMM YY") + " at " + moment(dt).format("h:m a");
    let template: string = this.currentLead["Miscellaneous"]["form_name"]
      .toLowerCase()
      .includes("ielts")
      ? "ielts"
      : "usastudy";

    let num = Math.floor(Math.random() * 90000) + 10000;
    let room = "live-" + num.toString();
    let smsId = null;
    let callId = null;
    let mailId = null;

    let demoScheduled = `${environment.BaseURL}/videocalling/mainscreen/${this.projectId}/${room}`;
    const smsMarketingBefore6hr: InputSMSData = {
      ProjectId: this.projectId,
      Module: this.currentLead["Module"],
      RecipientNumber:
        "+" +
        this.currentLead["CountryCode"] +
        this.currentLead["PhoneNumber"].replace(/\n/g, ""),
      ScheduledSMSTime: moment(
        this.layoutService.commentsValue.counsellingDate,
        "YYYY-MM-DDTHH:mm:SSZ"
      ).format("YYYY-MM-DDTHH:mm:SSZ"),
      Type: "Information",
    };
    this.marketingService
      .getAutoDialCompany(this.projectId)
      .subscribe(async (data) => {
        // 
        if (data) {
          let apiKey = data["API_KEY"];
          if (dt) {
            smsId = await this.marketingService.createAutoSmsScheduler(
              smsMarketingBefore6hr,
              apiKey
            );
          }
        }
        setTimeout(() => {
          this.marketingService
            .createDemo({
              ProjectId: this.projectId,
              Name: this.currentLead["FirstName"],
              AutoEmailId: mailId ? mailId["mailId"] : null,
              AutoCallId: callId ? callId["callId"] : null,
              AutoSMSId: smsId ? smsId["smsId"] : null,
              Comments: this.layoutService.commentsValue.comments,
              DateTime: moment(
                this.layoutService.commentsValue.counsellingDate,
                "YYYY-MM-DDTHH:mm:SSZ"
              ).format("YYYY-MM-DDTHH:mm:SSZ"),
              Presenter:
                this.layoutService.selectedAgent &&
                  this.layoutService.selectedAgent.length !== 0
                  ? this.layoutService.selectedAgent[0]["value"]
                  : this.currentLead["Assignedto"],
              Feedback: this.layoutService.commentsValue.interested,
              Url: demoScheduled + "/" + this.userData.workerFriendlyName,
              ClientURL: demoScheduled + "/" + this.currentLead["FirstName"],
              RoomName: room,
              LeadId: this.currentLead.id,
              agentEmail: this.userData.mail,
            })
            .subscribe((demo) => {
              if (dt) {
                this.marketingService
                  .updateDemo({
                    demoId: demo["id"],
                    leadId: this.currentLead.id,
                  })
                  .subscribe((data) => {
                    if (dt) {
                      this.layoutService.sendSmsForLead({
                        date: dt,
                        from: this.layoutService.twilioNumber,
                        projectId: this.projectId,
                        student_name: this.currentLead["FirstName"],
                        tosms: this.currentLead.PhoneNumber,
                      });
                    }
                    if (dt) {
                      this.marketingService.sendMailForDemo(
                        {
                          name: this.currentLead["FirstName"],
                          demoDate: moment(
                            this.layoutService.commentsValue.counsellingDate
                          ).format("MMMM Do YYYY, h:mm:ss a"),
                          url:
                            demoScheduled + "/" + this.currentLead["FirstName"],
                        },
                        this.currentLead.Email,
                        this.projectId,
                        template
                      );
                      this.updateLeadComments();
                    }
                  }, (error) => {
                    this.handleError.handleError(error)
                  });
              }
            }, (error) => {
              this.handleError.handleError(error)
            });
        }, 2000);
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  createLead() {
    this.submitted = true;
    if (this.marketingService.createLead.invalid) {
      return;
    }
    let fileData: Exceldata[] = [
      new Exceldata(
        this.marketingService.createLeadValue["firstName"],
        this.marketingService.createLeadValue["lastName"],
        this.marketingService.createLeadValue["countryCode"],
        this.marketingService.createLeadValue["phoneNumber"],
        this.marketingService.createLeadValue["email"],
        this.userData.mail,
        this.marketingService.createLeadValue["module"],
        this.marketingService.createLeadValue["channel"],
        {
          form_id: "",
          form_name: this.marketingService.createLeadValue["form_name"],
          "Choose Batch": "",
          "Training Mode": "",
        },
        this.marketingService.createLeadValue["comments"],
        this.marketingService.createLeadValue["createdAt"],
      ),
    ];
    this.marketingService
      .postCallData({
        data: fileData,
        createdBy: this.userData["mail"],
        ProjectId: this.projectId,
        type: 'Manual'
      })
      .subscribe((data) => {
        this.marketingService.resetCreateLead();
        this.submitted = false;
        this.modalService.dismissAll();
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  openCreateLead(content) {
    this.modalService.open(content, { size: "md" });
  }
  setTimeZoneToZero(value) {
    var offset = moment().utcOffset();
    return moment.utc(value)
      .utcOffset(offset)
      .format('DD/MM/YY, hh:mm A');
  }

  redirectToUpload(){
    
    this.router.navigate(['./upload/'+this.projectId])
  }
}
