import { Component, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MomentFormatService } from 'src/app/moment-format-service';
import { SharedService } from 'src/app/shared/shared.service';
import * as XLSX from 'xlsx';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from './reports.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  reportsForm: FormGroup;
  userData: any;
  projectId: any;
  // AGENT MULTI SELECT
  agentsList = [];
  agentsSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'Mailid',
    textField: 'agentname',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  // project MULTI SELECT
  projectLists = [];
  projectSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'Name',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  // Courses MULTI SELECT
  coursesList = [];
  coursesSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'course',
    textField: 'course',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  // Applications MULTI SELECT
  applicationsList = [
    { name: 'Total Applications', id: 'apllicationsData' },
    { name: 'Pending Applications', id: 'pendingData' },
    { name: 'Completed Applications', id: 'completedData' },
    { name: 'Denied Applications', id: 'deniedData' },
    { name: 'Assigned Applications', id: 'assignedToData' },
    { name: 'Un Assigned Applications', id: 'unAssignedData' },
    { name: 'Validated Applications', id: 'validatedData' },
    { name: 'Not Validated Applications', id: 'notValidatedData' }
  ];
  applicationsSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  // Enrollment Year MULTI SELECT
  enrollmentYearList = [];
  enrollmentYearSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'EnrollmentYear',
    textField: 'EnrollmentYear',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  // Enrollment Term MULTI SELECT
  enrollmentTermList = [];
  enrollmentTermSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'EnrollmentTerm',
    textField: 'EnrollmentTerm',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  options: any;
  baroption: any
  listData: any;
  public page: any = 1;
  public pageSize: any = 12;
  public search: string;
  validatedData: any;
  notValidatedData: any;
  assignedToData: any;
  deniedData: any;
  completedData: any;
  pendingData: any;
  unAssignedData: any;
  type: any;
  colorPalette = ['#FAD5D0', '#B9EAF5', '#BCEDD3', '#BBD3FD', '#E8C8FC', '#FAD0A7', '#CDFAC4', '#96E0E2'];
  private colors = [
    [{ 'bg': '#FAE2DF', 'color': '#A52A1E' }],
    [{ 'bg': '#C8EAF2', 'color': '#014C5C' }],
    [{ 'bg': '#CDEDDC', 'color': '#04745A' }],
    [{ 'bg': '#D1E2FF', 'color': '#013CA3' }],
    [{ 'bg': '#e7fadf', 'color': '#39a51e' }],
    [{ 'bg': '#dffaeb', 'color': '#1ea55b' }],
    [{ 'bg': '#dfe2fa', 'color': '#1e2ea5' }],
  ]


  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private reportService: ReportsService,
    private momentFormatService: MomentFormatService,
    private ngbCalendar: NgbCalendar,
    private handleError: ErrorHandlerService,
    private dateAdapter: NgbDateAdapter<string>
  ) {

  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("userData") || '{}');

    this.sharedService.projectId.subscribe((data) => {
      if (data) {
        this.getAgentList();
        this.projectId = data;
        var postData = {
          "course": 'All',
          "agentEmail": 'All',
          "from": this.momentFormatService.dashboardDateFormat(
            new Date(new Date(Date.now() - 864e5 * 365)).getTime()
          ),
          "to": this.momentFormatService.dashboardDateFormat(
            new Date(new Date(Date.now() + 864e5))
          ),
          "enrollmentYear": 'All',
          "enrollmentTerm": 'All',
          "userId": this.userData.id,
          "token": this.userData.token,
          "platform": "CRM",
          "ProjectId": this.projectId,
          "application": 'All'
        };
        this.getDefaultData(postData);
      }
    });
  }
  shareCheckedListCreated(item: any) {
    
  }
  exportList() {
    var fd = this.listData.map(x => {
      const { GUID, FirstName, LastName, Program, createdAt, updatedAt, Assignedto, LeadDocStatus } = x;
      return { GUID, FirstName, LastName, Program, createdAt, updatedAt, Assignedto, LeadDocStatus };
    })
    var json = fd;
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    var x: Date = new Date();
    var link: string = "Reports_" + x.getFullYear() + "_" + x.getMonth() + "_" + x.getDay() + '.xlsx';
    a.download = link;
    a.click();
  }

  getDefaultData(postData) {
    this.reportService.getReports(postData).toPromise()
      .then((resp: any) => {
        this.listData = resp.data.map((x, i) => {
          x.showid = i + 1
          return x
        });
        
        
        this.options = {
          title: {
            text: 'Line Graph'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              show: false
            }
          },
          legend: {
            data: ['Count']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['Total',
                'Pending',
                'Completed',
                'Denied',
                'Assigned',
                'Unassigned',
                'Validated',
                'Not Validated'],
              splitLine: {
                show: false
              },
              axisLabel: { interval: 0, rotate: 30 }
            }
          ],
          yAxis: [
            {
              type: 'value',
              splitLine: {
                show: false
              }
            }
          ],
          series: [
            {
              type: 'line',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              data: [resp.totalApllications,
              resp.pendingCount,
              resp.completedCount,
              resp.deniedCount,
              resp.assignedToCount,
              resp.unAssignedCount,
              resp.validated,
              resp.notValidated]
            },
          ]
        };
        this.baroption = {
          title: {
            text: 'Bar Graph'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: ''
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: ['Total',
                'Pending',
                'Completed',
                'Denied',
                'Assigned',
                'Unassigned',
                'Validated',
                'Not Validated'],
                axisLabel: { interval: 0, rotate: 30 }
            }
          ],
          yAxis: [
            {
              type: 'value',
              splitLine: {
                show: false
              }
            },

          ],
          series: [
            {
              type: 'bar',
              barWidth: '20%',
              data: [
                resp.totalApllications,
                resp.pendingCount,
                resp.completedCount,
                resp.deniedCount,
                resp.assignedToCount,
                resp.unAssignedCount,
                resp.validated,
                resp.notValidated
              ]
            }
          ],
          color: '#ACDDDE'
        };
      })
      .catch(err => {
        this.handleError.handleError(err)

      })
  }
  showRevalidatedOnly(val: string) {
    if (val.includes('revalidate')) {
      return 'revalidated'
    } else {
      return val;
    }
  }
  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  generate(items: any) {
    this.reportService.getReports(items).toPromise()
    .then((resp: any) => {
      this.listData = resp.data.map((x, i) => {
        x.showid = i + 1
        return x
      });
      
      
      this.options = {
        title: {
          text: 'Line Graph'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            show: false
          }
        },
        legend: {
          data: ['Count']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['Total',
              'Pending',
              'Completed',
              'Denied',
              'Assigned',
              'Unassigned',
              'Validated',
              'Not Validated'],
            splitLine: {
              show: false
            },
            axisLabel: { interval: 0, rotate: 30 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'line',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            data: [resp.totalApllications,
            resp.pendingCount,
            resp.completedCount,
            resp.deniedCount,
            resp.assignedToCount,
            resp.unAssignedCount,
            resp.validated,
            resp.notValidated]
          },
        ]
      };
      this.baroption = {
        title: {
          text: 'Bar Graph'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: ''
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Total',
              'Pending',
              'Completed',
              'Denied',
              'Assigned',
              'Unassigned',
              'Validated',
              'Not Validated'],
              axisLabel: { interval: 0, rotate: 30 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            }
          },

        ],
        series: [
          {
            type: 'bar',
            barWidth: '20%',
            data: [
              resp.totalApllications,
              resp.pendingCount,
              resp.completedCount,
              resp.deniedCount,
              resp.assignedToCount,
              resp.unAssignedCount,
              resp.validated,
              resp.notValidated
            ]
          }
        ],
        color: '#ACDDDE'
      };
    })
    .catch(err => {
      this.handleError.handleError(err)

    })
  }
  getAgentList() {
    this.reportService.getAgents(this.userData.id, this.userData.token).toPromise()
      .then((resp: any) => {
        var agentList = resp.data;
        agentList.forEach(y => {
          y.AgentColor = this.colors[this.numberFromText(y.agentInitial) % this.colors.length][0]
        })
        this.agentsList = agentList;
      })
      .catch(err => {
        this.handleError.handleError(err)

      })
  }
  numberFromText(text) {
    const charCodes = text
      .split('') // => ["A", "A"]
      .map(char => char.charCodeAt(0)) // => [65, 65]
      .join(''); // => "6565"
    return parseInt(charCodes, 10);
  }
}
