import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";

interface CreateProject {
  name: string;
  description: string;
  companyId: any;
  twilioNumber: string;
  supportMail: string;
  forwardMail: string;
}

interface UpdateProject {
  name: string;
  twilioNumber: string;
  supportMail: string;
  ActiveStatus: any;
  id: any;
  incomingMailGroup: any;
  forwardMail: string;
}

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  baseApiUrl2: any = environment.baseApiUrl2;

  constructor(private http: HttpClientService) {}

  getProjects(companyId) {
    return this.http.get<any[]>(
      `${this.baseApiUrl2}getAllProjects/${companyId}`
    );
  }

  createProject(body: CreateProject) {
    return this.http.post(`${this.baseApiUrl2}CreateProject`, body);
  }

  getProject(id) {
    return this.http.get(`${this.baseApiUrl2}getProjectById/${id}`);
  }

  updateProject(body: UpdateProject) {
    return this.http.post(`${this.baseApiUrl2}updateproject`, body);
  }

  dashboardProjects(body: { admin: boolean; companyId: any; userId: any }) {
    return this.http.post<any[]>(`${this.baseApiUrl2}dashboardprojects`, body);
  }

  projectAdmin(body: { userId: any; projectId: any }) {
    return this.http.post(`${this.baseApiUrl2}projectAdmin`, body);
  }
}
