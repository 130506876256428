<div class="dashboard-main-wrapper">
	<div class="dashboard-left-wrapper" id="mySidebar">
		<app-sidebar></app-sidebar>
	</div>
	<div class="dashboard-right-wrapper">
		<app-topbar></app-topbar>
		<router-outlet> </router-outlet>
	</div>
	<!-- <app-footer></app-footer> -->
</div>
