<section class="content">
    <div class="container-fluid bg-g">
        <div class="block-header">
            <app-report-filter2></app-report-filter2>
        </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid pt-4 pb-5">
        <div class="row clearfix">
            <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Application status</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px"
                                (click)="leadsData()"></div>


                    </div>
                    <div class="body">
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="statusCounts" [animations]="animations" [legend]="true"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Completed vs Pending</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="leadsData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="completedStatus" [animations]="animations" [legend]="false"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Assigned to</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="leadsData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="assignedCounts" [animations]="animations" [legend]="false"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Incoming vs Completed</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="incomingVsCompletedData()"></div>

                    </div>
                    <div class="body">
                        <combo-chart-component class="chart-container" [view]="view" [scheme]="comboBarScheme"
                            [colorSchemeLine]="lineChartScheme" [results]="barChart" [animations]="animations"
                            [lineChart]="lineChartSeries" [tooltipDisabled]="false"
                            [yAxisTickFormatting]="yLeftTickFormat" [yLeftAxisScaleFactor]="yLeftAxisScale"
                            [yRightAxisScaleFactor]="yRightAxisScale" [yRightAxisTickFormatting]="yRightTickFormat"
                            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [showGridLines]="showGridLines" [showXAxisLabel]="false" [showYAxisLabel]="false"
                            [showRightYAxisLabel]="false" [autoScale]="true" [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel" [yAxisLabelRight]="yAxisLabelRight"
                            [noBarWhenZero]="noBarWhenZero" (select)="onSelect($event)">
                        </combo-chart-component>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Courses</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="incomingVsCompletedData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-tree-map class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="courseCounts" [animations]="animations" [tooltipDisabled]="tooltipDisabled"
                            [labelFormatting]="gdpLabelFormatting" [valueFormatting]="currencyFormatting"
                            [gradient]="gradient" (select)="select($event)">
                        </ngx-charts-tree-map>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Applications Processed - Top Performers</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="incomingVsCompletedData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-gauge class="chart-container" [view]="view" [legend]="showLegend"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition" [results]="topPerformers"
                            [animations]="animations" [textValue]="gaugeTextValue" [scheme]="colorScheme"
                            [min]="gaugeMin" [max]="gaugeMax" [units]="gaugeUnits" [angleSpan]="gaugeAngleSpan"
                            [startAngle]="gaugeStartAngle" [showAxis]="gaugeShowAxis" [bigSegments]="gaugeLargeSegments"
                            [smallSegments]="gaugeSmallSegments" [margin]="
                margin
                  ? [marginTop, marginRight, marginBottom, marginLeft]
                  : null
              " [tooltipDisabled]="tooltipDisabled" [showText]="showText" (select)="select($event)"
                            (activate)="activate($event)" (deactivate)="deactivate($event)">
                        </ngx-charts-gauge>
                    </div>
                </div>
            </div> -->

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Calls Trend - All Calls</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="callsData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-line-chart [view]="view" class="chart-container" [scheme]="colorScheme"
                            [schemeType]="schemeType" [results]="lineChartCalls" [animations]="animations"
                            [legend]="false" [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [showXAxisLabel]="false"
                            [showYAxisLabel]="false" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                            [autoScale]="autoScale" [xScaleMin]="xScaleMin" [xScaleMax]="xScaleMax"
                            [yScaleMin]="yScaleMin" [yScaleMax]="yScaleMax" [timeline]="timeline"
                            [showGridLines]="showGridLines" [curve]="curve" [rangeFillOpacity]="rangeFillOpacity"
                            [roundDomains]="roundDomains" [tooltipDisabled]="tooltipDisabled"
                            [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
                            [rotateXAxisTicks]="rotateXAxisTicks" [maxXAxisTickLength]="maxXAxisTickLength"
                            [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)"
                            (activate)="activate($event)" (deactivate)="deactivate($event)">
                        </ngx-charts-line-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Calls: Incoming vs Outgoing</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="callsData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-bar-vertical-2d class="chart-container" [view]="view" [scheme]="colorScheme"
                            [schemeType]="schemeType" [results]="inboundOutbound" [animations]="animations"
                            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]=true
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition" [showXAxisLabel]="false"
                            [showYAxisLabel]="false" [tooltipDisabled]="tooltipDisabled" [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel" [showGridLines]="showGridLines" [barPadding]="7"
                            [groupPadding]="groupPadding" [roundDomains]="roundDomains" [roundEdges]="roundEdges"
                            [yScaleMax]="yScaleMax" [noBarWhenZero]="noBarWhenZero" [showDataLabel]="showDataLabel"
                            [trimXAxisTicks]="trimXAxisTicks" [trimYAxisTicks]="trimYAxisTicks"
                            [rotateXAxisTicks]="rotateXAxisTicks" [maxXAxisTickLength]="maxXAxisTickLength"
                            [maxYAxisTickLength]="maxYAxisTickLength" (select)="select($event)"
                            (activate)="activate($event)" (deactivate)="deactivate($event)">
                        </ngx-charts-bar-vertical-2d>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Calls - Incoming vs Outgoing</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="callsData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="incomingVsOutgoing" [animations]="animations" [legend]=true
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Call Status</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="callsData()"></div>

                    </div>
                    <div class="body">
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="callStatusCounts" [animations]="animations" [legend]=true
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]=true [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>


            <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
                <div class="card">
                    <div class="header">
                        <h2>
                            Top 10 Leads that took the most time to Completion
                            <small>Time taken from Created date to Completed Date</small>
                        </h2>
                        <div class="ml-auto header-dropdown" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="top10CompletionData()"></div>
                        
                    </div>
                    <div class="body table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>GUID</th>
                                    <th>Name</th>
                                    <th>Program</th>
                                    <th>AssignedTo</th>
                                    <th>Time Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let i of top10Data">
                                    <td scope="row">{{i.GUID}}</td>
                                    <th>{{i.FirstName}} {{i.LastName}}</th>
                                    <td>{{i.Program}}</td>
                                    <td>{{i.Assignedto}}</td>
                                    <td>{{i.Time_taken_to_close_An_application.days||0}} days {{i.Time_taken_to_close_An_application.hours||0}}h {{i.Time_taken_to_close_An_application.minutes||0}}min</td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header">
                        <h2>
                            Top 10 Leads that took the most time to Validation
                            <small>Time taken from Created date to Last document validation</small>
                        </h2>
                        
                        <div class="ml-auto header-dropdown" style="cursor: pointer;"><img src="../../../assets/img/download.svg" style="width:16px" (click)="top10ValidationData()"></div>
                        
                    </div>
                    <div class="body table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>GUID</th>
                                    <th>Name</th>
                                    <th>Program</th>
                                    <th>AssignedTo</th>
                                    <th>Time Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let i of top10Validation">
                                    <td scope="row">{{i.GUID}}</td>
                                    <th>{{i.FirstName}} {{i.LastName}}</th>
                                    <td>{{i.Program}}</td>
                                    <td>{{i.Assignedto}}</td>
                                    <td>{{i.total_time_till_validation.days||0}} days {{i.total_time_till_validation.hours||0}}h {{i.total_time_till_validation.minutes||0}}min</td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="row clearfix">

    </div>
</section>