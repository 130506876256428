import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/httpclient.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventCalendarService {
  baseApiUrl = environment.baseApiUrl2;

  constructor(
    private http: HttpClientService,
    private toastr: ToastrService,
    private readonly formBuilder: FormBuilder) {

  }
  createEvent(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/createEvent`, data);
  }
  getEvent(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/getEvents`, data);
  }
  getUpcomingEvent(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/getUpcomingEvents`, data);
  }
  getScheduleEvent(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventSchedule/getScheduleEvent`, data);
  }
  shareEventDetails(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/shareEventDetails`, data);
  }
  getAllEventList(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/getAllEventList`, data);
  }
  getActivityCount(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/getActivityCount`, data);
  }
  exportDetails(data?: any) {
    return this.http.post(`${this.baseApiUrl}eventCal/exportDetails`, data);
  }
  AutoSMSRecrds(data?: any,agentId?:any) {
    return this.http.get(`${this.baseApiUrl}eventCal/AutoSMSRecrds/${data}/${agentId}`);
  }
  AutoCallRecrds(data?: any,agentId?:any) {
    return this.http.get(`${this.baseApiUrl}eventCal/AutoCallRecrds/${data}/${agentId}`);
  }
}
