import { BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { io } from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TwilioSocketService {
  socket: any;
  channelJoin = new Subject<any>();
  callAccepted = new BehaviorSubject<any>(null);
  callCancelled = new BehaviorSubject<any>(null);

  constructor() {}
  connect() {
    this.socket = io(environment.socketUrl, {
      transports: ["websocket"],
      upgrade: false,
      path: "/api/socket",
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
    });
    this.channelJoin.subscribe((data) => {
      this.socket.emit("join", { workerSID: data.sid });
    });

    //handle the event sent with socket.emit()
    this.socket.on("callAccepted", (data) => {
      this.callAccepted.next(data);
    });
    this.socket.on("callCanceled", (data) => {
      this.callCancelled.next(data);
    });
    // this.socket.on('connect', socket => {
    // });
    // this.socket.emit('test', "Hello server");

    this.socket.on("connect", (data) => {
    });
    this.socket.on("disconnect", () => {
      this.socket.connect();
    });
  }
  // disConnect() {
  //   this.socket.disconnect();
  // }
}
