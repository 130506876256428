<!-- Overview - Referree Details-->
<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle();selectIndex = 0">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer" [selectedIndex]="selectIndex">
    <mat-tab label="Lead details">
      <div class="mx-2 my-2" *ngIf="!sidenavLoading">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Name</td>
            <td>
              {{
              sideViewerData.FirstName !== "-" &&
              sideViewerData.LastName !== "-"
              ? sideViewerData.FirstName + " " + sideViewerData.LastName
              : sideViewerData.FirstName !== "-" &&
              sideViewerData.LastName === "-"
              ? sideViewerData.FirstName
              : ""
              }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData.Email }}</td>
          </tr>
          <tr>
            <td>Mobile</td>
            <td>
              {{
              sideViewerData.CountryCode + " " + sideViewerData.PhoneNumber
              }}
            </td>
          </tr>
          <tr *ngIf="sideViewerData?.transferDetails">
            <td>Address</td>
            <td *ngIf="sideViewerData?.transferDetails.fromUS == 'false'">
              {{
              sideViewerData?.transferDetails?.address ||
              "--" + " " + sideViewerData?.transferDetails.cityOrTown ||
              "--" + " " + sideViewerData?.transferDetails.state ||
              "--" + " " + sideViewerData?.transferDetails.zip ||
              "--" + " " + sideViewerData?.transferDetails.country ||
              "NA" | titlecase
              }}
            </td>
            <td *ngIf="sideViewerData?.transferDetails.fromUS == 'true'">
              {{ sideViewerData?.transferDetails.currentUsaAddr || "NA" }}
            </td>
          </tr>

          <tr *ngIf="sideViewerData?.transferDetails">
            <td>Universities</td>
            <td *ngIf="sideViewerData?.transferDetails?.ApplyingFor != '-' ">
              <ul *ngFor="let subCategory of sideViewerData?.transferDetails?.ApplyingFor?.split(','); let i = index;"
                class="m-0 p-0" style="list-style-type: none;">
                <li>
                  {{ (i+1) +" . "+ subCategory | titlecase }}
                </li>
              </ul>
            </td>
            <td *ngIf="sideViewerData?.transferDetails?.universityInfo">
              <ul *ngFor="let subCategory of sideViewerData?.transferDetails?.universityInfo; let i = index;"
                class="m-0 p-0" style="list-style-type: none;">
                <li>
                  {{ (i+1) +" . "+ subCategory.name | titlecase }} {{subCategory.program ? "- "+ subCategory.program :
                  ''}}
                </li>
              </ul>
            </td>
          </tr>
          <tr *ngIf="sideViewerData?.transferDetails">
            <td>Level of study</td>
            <td>
              {{
              sideViewerData?.transferDetails?.levelOfStudy
              }}
            </td>
          </tr>
          <tr *ngIf="sideViewerData?.transferDetails">
            <td>Password</td>
            <td>
              {{
              sideViewerData?.transferDetails?.password
              }}
            </td>
          </tr>
          <tr>
            <td>Created Date</td>
            <td>
              {{ sideViewerData?.createdAt | date : "medium" }}
            </td>
          </tr>
          <tr>
            <td>Channel</td>
            <td>{{ sideViewerData.Channel }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr *ngIf="recruiterDetails">
            <td>Recruiter Name</td>
            <td>
              {{ recruiterDetails.firstName }} {{ recruiterDetails.lastName }}
            </td>
          </tr>
          <tr *ngIf="recruiterDetails">
            <td>Recruiter EmailId</td>
            <td>{{ recruiterDetails.emailId }}</td>
          </tr>
          <tr *ngIf="recruiterDetails">
            <td>Recruiter Phone Number</td>
            <td>{{ recruiterDetails.phone }}</td>
          </tr>
          <tr *ngIf="recruiterDetails">
            <td>Recruiter Role</td>
            <td>
              {{
              recruiterDetails.category == "ORGANIZATION"
              ? "RECRUITER"
              : recruiterDetails.category
              }}
            </td>
          </tr>
          <tr *ngIf="recruiterDetails">
            <td>Recruiter Company Name</td>
            <td>{{ recruiterDetails.companyName || "--" }}</td>
          </tr>
          <tr *ngIf="recruiterDetails">
            <td>Recruiter Address</td>
            <td>
              {{
              recruiterDetails?.street ||
              "--" + " " + recruiterDetails?.city ||
              "--" + " " + recruiterDetails?.state ||
              "--" + " " + recruiterDetails?.zip ||
              "--" + " " + recruiterDetails?.country ||
              "NA" | titlecase
              }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mx-2 my-2" *ngIf="sidenavLoading">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>
              <ngx-skeleton-loader [theme]="{ height: '20px', width: '100%' }"></ngx-skeleton-loader>
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Call Details">
      <div>
        <div class="alert alert-info mb-0">
          {{ sideViewerData.CallDetails.length }} Calls
        </div>
        <div class="mx-2 my-2" *ngFor="let i of sideViewerData.CallDetails">
          <table class="table table-borderless table-md caller-table my-1">
            <tr>
              <td>Called by</td>
              <td>{{ i.CalledBy || "-" }}</td>
            </tr>
            <tr>
              <td>Date & Time</td>
              <td>{{ i.createdAt | date : "medium" }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{{ i.Status | titlecase }}</td>
            </tr>
            <tr>
              <td>Call Recording</td>
              <td>
                <audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL"
                  controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
              </td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>{{ i.Comments || "-" }}</td>
            </tr>
            <tr>
              <td>Direction</td>
              <td>{{ i.Direction | titlecase }}</td>
            </tr>
          </table>
          <hr class="hr-style" />
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Document Validation">
      <div
        *ngIf="sideViewerData?.transferDetails && sideViewerData?.transferDetails?.universityInfo != '-' && sideViewerData?.transferDetails?.universityInfo?.length>0; else regularStudent">
        <div class="universityInfo">
          <!-- <mat-expansion-panel *ngFor="let uni of sideViewerData?.transferDetails?.universityInfo">
            <mat-expansion-panel-header>
              <mat-panel-title>{{uni?.name | titlecase }} {{uni.program ? "- "+uni.program : ''}}</mat-panel-title>
            </mat-expansion-panel-header> -->
          <div class="mx-2 my-2">
            <div class="mx-2 my-2">
              <div *ngFor="let item of sideViewerData?.uploadlistofdocuments">
                <div class="border-bottom">
                  <div class="d-flex mb-1">
                    <div class="py-2 d-flex flex-column">
                      <img *ngIf="item?.StudentDocPath != '-'" src="./assets/img/docs-r.svg" style="width: 16px" />
                      <img *ngIf="item?.StudentDocPath == '-'" src="./assets/img/doc-grey-2.svg" style="width: 16px" />
                    </div>
                    <div class="p-2 d-flex flex-column w200">
                      <div class="sub-header-md mr-3 d-flex align-items-center">
                        <div style="line-height: 160%">
                          {{ item?.ProgramDoc | titlecase }}
                        </div>

                        <button *ngIf="item?.docType == 'customDoc'" style="background: none" (click)="
                          deleteCustomDocument(item?.StudentdocId, item?.ProgramDoc)
                        "
                          [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                          <img class="img12" style="width: 10px" src="../../../assets/img/delete.svg" />
                        </button>
                      </div>

                      <div class="sub-date" *ngIf="item?.StudentDocPath != '-'">
                        {{ item?.uploadedDate | date : "medium" }}
                      </div>
                    </div>
                    <div>
                      <div>
                        <span class="badge badge-pill badge-success mt-3"
                          *ngIf="item?.AgentValidation == 'validated'">Validated</span>
                        <span class="badge badge-pill badge-warning mt-3"
                          *ngIf="item?.AgentValidation == 'revalidate'">Re
                          Validate</span>
                      </div>

                      <ng-template #validation11>
                        <form #form="ngForm">
                          <div style="width: 185px; font-size: 12px">
                            <div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                  value="validated" (change)="
                                  changeValidation(
                                    $event.target.value,
                                    item?.StudentdocId
                                  )
                                " />
                                <label class="form-check-label" for="inlineRadio1">Validated</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                  value="revalidate" (change)="
                                  changeValidation(
                                    $event.target.value,
                                    item?.StudentdocId
                                  )
                                " />
                                <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ng-template>

                      <ng-template #comments11>
                        <form #form="ngForm">
                          <div class="d-flex align-items-end" style="width: 250px">
                            <div class="flex-grow-1">
                              <textarea class="form-control ct" type="text" #documentComment id="documentComments"
                                rows="2" placeholder="Write comments here..."></textarea>
                            </div>
                            <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                              (click)="postComments(item, 'documentComments')">
                              <img src="./assets/img/send-message.svg" style="width: 16px" />
                            </button>
                          </div>
                        </form>
                      </ng-template>
                    </div>
                    <div class="ml-auto p-2">
                      <button *ngIf="item?.uploadedDate != '-' && item?.StudentDocPath"
                        class="btn btn-light btn-sm mr-2" (click)="
                        downLoadDocument(item?.StudentDocPath, item?.ProgramDoc)
                      "
                        [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                        <img src="../../../assets/img/file-download.svg" style="width: 16px" />
                      </button>

                      <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

                      <button [disabled]="sideViewerData?.LeadDocStatus == 'completed'" data-toggle="modal"
                        data-target="#uploadFiles" placement="right" tooltipClass="my-custom-class"
                        class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)"
                        [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                        <img src="./assets/img/file-upload.svg" style="width: 16px" />
                      </button>
                      <button type="button" class="btn btn-light btn-sm mr-2"
                        (click)="openPreview(pdfContent, item?.StudentDocPath)" [disabled]="
                        item.StudentDocPath == '-' || !item.StudentDocPath
                      ">
                        <img src="./assets/img/view2.svg" style="width: 16px" />
                      </button>
                      <button type="button" class="btn btn-light btn-sm mr-2" placement="bottom"
                        [ngbPopover]="validation11" [disabled]="
                        item?.StudentDocPath == '-' ||
                        !item?.StudentDocPath ||
                        sideViewerData?.LeadDocStatus == 'completed'
                      "
                        [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                        <img *ngIf="
                          item?.AgentValidation == '-' ||
                          item?.AgentValidation == null
                        " src="./assets/img/tick-g.svg" style="width: 16px" />
                        <img *ngIf="item?.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                          style="width: 16px" />
                        <img *ngIf="item?.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                          style="width: 16px" />
                      </button>
                      <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="comments11"
                        ngbTooltip="Add Comment"
                        [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                        <img src="./assets/img/comments.svg" style="width: 16px" />
                      </button>
                      <button type="button" data-toggle="modal" data-target="#contentDocs"
                        class="btn btn-light btn-sm mr-2" (click)="callFromDocs(sideViewerData, item)"
                        [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                        <img src="./assets/img/calls-g.svg" style="width: 16px" />
                      </button>
                    </div>
                  </div>
                  <mat-expansion-panel *ngIf="item?.DocComments?.length > 0">
                    <mat-expansion-panel-header>
                      <mat-panel-title> Comments </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="comments-section" *ngFor="let i of item.DocComments">
                      <div class="admin-comment" *ngIf="i?.Admin == true">
                        <div class="d-flex">
                          <div>
                            <div *ngIf="i?.AgentName" class="pic mr-1 float-left text-center">
                              <img *ngIf="i?.ProfilePhoto != null" src="{{ i?.ProfilePhoto }}" style="
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 100px;
                              " />

                              <p [ngStyle]="{ background: i?.AgentColor }" class="initials"
                                *ngIf="i?.ProfilePhoto == null">
                                {{ i?.AgentInitials }}
                              </p>
                            </div>
                          </div>
                          <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                            <div class="d-flex">
                              <div class="sub-header-sm mr-3 flex-fill">
                                {{ i?.AgentName }}
                              </div>
                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ i?.DateTime | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text flex-grow-1">{{ i?.Text }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="agent-comment" *ngIf="i?.Admin == false">
                        <div class="d-flex">
                          <div>
                            <div *ngIf="i?.AgentName" class="pic mr-1 float-left text-center">
                              <img *ngIf="i?.ProfilePhoto != null" style="
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 100px;
                              " src="{{ i?.ProfilePhoto }}" />

                              <p [ngStyle]="{ background: i?.AgentColor }" class="initials"
                                *ngIf="i?.ProfilePhoto == null">
                                {{ i?.AgentInitials }}
                              </p>
                            </div>
                          </div>
                          <div class="pl-2 pr-2 d-flex flex-column">
                            <div class="d-flex">
                              <div class="sub-header-sm mr-3">
                                {{ i?.AgentName }}
                              </div>
                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ i?.DateTime | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text">{{ i?.Text }}</div>
                          </div>
                        </div>
                      </div>
                      <div style="clear: both"></div>
                    </div>
                  </mat-expansion-panel>
                </div>
                <!-- <div *ngIf="item?.docType=='customDoc'">
                    <div *ngIf="
                    ((uni?.name?.toLowerCase() == item?.universityName?.toLowerCase())
                    || (uni?.name?.toLowerCase() == item?.TransferUniversity?.toLowerCase())) &&
                    ((uni?.program?.toLowerCase() == item?.DocProgramType?.toLowerCase()) || (uni?.program?.toLowerCase() == item?.TransferProg?.toLowerCase()))
                    ">
                      <div class="d-flex mb-1">
                        <div class="py-2 d-flex flex-column">
                          <img *ngIf="item?.StudentDocPath != '-'" src="./assets/img/docs-r.svg" style="width: 16px" />
                          <img *ngIf="item?.StudentDocPath == '-'" src="./assets/img/doc-grey-2.svg"
                            style="width: 16px" />
                        </div>
                        <div class="p-2 d-flex flex-column w200">
                          <div class="sub-header-md mr-3 d-flex align-items-center">
                            <div style="line-height: 160%">
                              {{ item?.ProgramDoc | titlecase }}
                            </div>

                            <button *ngIf="item?.docType == 'customDoc'" style="background: none" (click)="
                            deleteCustomDocument(item?.StudentdocId, item?.ProgramDoc)
                          " [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                              <img class="img12" style="width: 10px" src="../../../assets/img/delete.svg" />
                            </button>
                          </div>

                          <div class="sub-date" *ngIf="item?.StudentDocPath != '-'">
                            {{ item?.uploadedDate | date : "medium" }}
                          </div>
                        </div>
                        <div>
                          <div>
                            <span class="badge badge-pill badge-success mt-3"
                              *ngIf="item?.AgentValidation == 'validated'">Validated</span>
                            <span class="badge badge-pill badge-warning mt-3"
                              *ngIf="item?.AgentValidation == 'revalidate'">Re
                              Validate</span>
                          </div>

                          <ng-template #validation11>
                            <form #form="ngForm">
                              <div style="width: 185px; font-size: 12px">
                                <div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                      id="inlineRadio1" value="validated" (change)="
                                    changeValidation(
                                      $event.target.value,
                                      item?.StudentdocId
                                    )
                                  " />
                                    <label class="form-check-label" for="inlineRadio1">Validated</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                      id="inlineRadio2" value="revalidate" (change)="
                                    changeValidation(
                                      $event.target.value,
                                      item?.StudentdocId
                                    )
                                  " />
                                    <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </ng-template>

                          <ng-template #comments11>
                            <form #form="ngForm">
                              <div class="d-flex align-items-end" style="width: 250px">
                                <div class="flex-grow-1">
                                  <textarea class="form-control ct" type="text" #documentComment id="documentComments"
                                    rows="2" placeholder="Write comments here..."></textarea>
                                </div>
                                <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                                  (click)="postComments(item, 'documentComments')">
                                  <img src="./assets/img/send-message.svg" style="width: 16px" />
                                </button>
                              </div>
                            </form>
                          </ng-template>
                        </div>
                        <div class="ml-auto p-2">
                          <button *ngIf="item?.uploadedDate != '-' && item?.StudentDocPath"
                            class="btn btn-light btn-sm mr-2" (click)="
                          downLoadDocument(item?.StudentDocPath, item?.ProgramDoc)
                        " [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                            <img src="../../../assets/img/file-download.svg" style="width: 16px" />
                          </button>

                          <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

                          <button [disabled]="sideViewerData?.LeadDocStatus == 'completed'" data-toggle="modal"
                            data-target="#uploadFiles" placement="right" tooltipClass="my-custom-class"
                            class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)"
                            [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                            <img src="./assets/img/file-upload.svg" style="width: 16px" />
                          </button>
                          <button type="button" class="btn btn-light btn-sm mr-2"
                            (click)="openPreview(pdfContent, item?.StudentDocPath)" [disabled]="
                          item.StudentDocPath == '-' || !item.StudentDocPath
                        ">
                            <img src="./assets/img/view2.svg" style="width: 16px" />
                          </button>
                          <button type="button" class="btn btn-light btn-sm mr-2" placement="bottom"
                            [ngbPopover]="validation11" [disabled]="
                          item?.StudentDocPath == '-' ||
                          !item?.StudentDocPath ||
                          sideViewerData?.LeadDocStatus == 'completed'
                        " [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                            <img *ngIf="
                            item?.AgentValidation == '-' ||
                            item?.AgentValidation == null
                          " src="./assets/img/tick-g.svg" style="width: 16px" />
                            <img *ngIf="item?.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                              style="width: 16px" />
                            <img *ngIf="item?.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                              style="width: 16px" />
                          </button>
                          <button type="button" class="btn btn-light btn-sm mr-2" placement="left"
                            [ngbPopover]="comments11" ngbTooltip="Add Comment"
                            [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                            <img src="./assets/img/comments.svg" style="width: 16px" />
                          </button>
                          <button type="button" data-toggle="modal" data-target="#contentDocs"
                            class="btn btn-light btn-sm mr-2" (click)="callFromDocs(sideViewerData, item)"
                            [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                            <img src="./assets/img/calls-g.svg" style="width: 16px" />
                          </button>
                        </div>
                      </div>
                      <mat-expansion-panel *ngIf="item?.DocComments?.length > 0">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Comments </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="comments-section" *ngFor="let i of item.DocComments">
                          <div class="admin-comment" *ngIf="i?.Admin == true">
                            <div class="d-flex">
                              <div>
                                <div *ngIf="i?.AgentName" class="pic mr-1 float-left text-center">
                                  <img *ngIf="i?.ProfilePhoto != null" src="{{ i?.ProfilePhoto }}" style="
                                  width: 100%;
                                  height: 100%;
                                  object-fit: cover;
                                  border-radius: 100px;
                                " />

                                  <p [ngStyle]="{ background: i?.AgentColor }" class="initials"
                                    *ngIf="i?.ProfilePhoto == null">
                                    {{ i?.AgentInitials }}
                                  </p>
                                </div>
                              </div>
                              <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                                <div class="d-flex">
                                  <div class="sub-header-sm mr-3 flex-fill">
                                    {{ i?.AgentName }}
                                  </div>
                                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                    {{ i?.DateTime | date : "medium" }}
                                  </div>
                                </div>
                                <div class="sub-text flex-grow-1">{{ i?.Text }}</div>
                              </div>
                            </div>
                          </div>

                          <div class="agent-comment" *ngIf="i?.Admin == false">
                            <div class="d-flex">
                              <div>
                                <div *ngIf="i?.AgentName" class="pic mr-1 float-left text-center">
                                  <img *ngIf="i?.ProfilePhoto != null" style="
                                  width: 100%;
                                  height: 100%;
                                  object-fit: cover;
                                  border-radius: 100px;
                                " src="{{ i?.ProfilePhoto }}" />

                                  <p [ngStyle]="{ background: i?.AgentColor }" class="initials"
                                    *ngIf="i?.ProfilePhoto == null">
                                    {{ i?.AgentInitials }}
                                  </p>
                                </div>
                              </div>
                              <div class="pl-2 pr-2 d-flex flex-column">
                                <div class="d-flex">
                                  <div class="sub-header-sm mr-3">
                                    {{ i?.AgentName }}
                                  </div>
                                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                    {{ i?.DateTime | date : "medium" }}
                                  </div>
                                </div>
                                <div class="sub-text">{{ i?.Text }}</div>
                              </div>
                            </div>
                          </div>
                          <div style="clear: both"></div>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </div> -->
              </div>

              <div class="py-2">
                <button data-toggle="modal" data-target="#exampleModal5" ngbTooltip="Add New Document" placement="right"
                  tooltipClass="my-custom-class" class="btn btn-primary btn-sm"
                  (click)="clearData(uni?.name,uni?.program)" style="padding: 0 5px"
                  [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                  <img src="./assets/img/add-w.svg" style="width: 10px" />
                  Add
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="validationStatus == true" class="text-center mb-2">
            <button class="btn btn-success btn-sm mt-2" disabled style="width: 180px">
              <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
              Validation Completed
            </button>
          </div>
          <div *ngIf="validationStatus == true" class="text-center mb-2">
            <button class="btn btn-success btn-sm mt-2" style="width: 180px" (click)="sendUniMailPopUp()">
              <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
              Send University Mail
            </button>
          </div>
          <div *ngIf="validationStatus == false" class="text-center mb-2">
            <button class="btn btn-danger btn-sm mt-2" disabled style="width: 180px">
              <img src="../assets/img/close-r.svg" style="width: 16px" /> Not
              Validated
            </button>
          </div>
          <!-- </mat-expansion-panel> -->
        </div>
      </div>
      <ng-template #regularStudent>
        <div>
          <div class="mx-2 my-2">
            <div class="mx-2 my-2">
              <div class="border-bottom" *ngFor="let item of sideViewerData?.uploadlistofdocuments">
                <div class="d-flex mb-1">
                  <div class="py-2 d-flex flex-column">
                    <img *ngIf="item.StudentDocPath != '-'" src="./assets/img/docs-r.svg" style="width: 16px" />
                    <img *ngIf="item.StudentDocPath == '-'" src="./assets/img/doc-grey-2.svg" style="width: 16px" />
                  </div>
                  <div class="p-2 d-flex flex-column w200">
                    <div class="sub-header-md mr-3 d-flex align-items-center">
                      <div style="line-height: 160%">
                        {{ item.ProgramDoc | titlecase }}
                      </div>

                      <button *ngIf="item.docType == 'customDoc'" style="background: none" (click)="
                        deleteCustomDocument(item.StudentdocId, item.ProgramDoc)
                      "
                        [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                        <img class="img12" style="width: 10px" src="../../../assets/img/delete.svg" />
                      </button>
                    </div>

                    <div class="sub-date" *ngIf="item.StudentDocPath != '-'">
                      {{ item.uploadedDate | date : "medium" }}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span class="badge badge-pill badge-success mt-3"
                        *ngIf="item.AgentValidation == 'validated'">Validated</span>
                      <span class="badge badge-pill badge-warning mt-3" *ngIf="item.AgentValidation == 'revalidate'">Re
                        Validate</span>
                    </div>

                    <ng-template #validation11>
                      <form #form="ngForm">
                        <div style="width: 185px; font-size: 12px">
                          <div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                value="validated" (change)="
                                changeValidation(
                                  $event.target.value,
                                  item.StudentdocId
                                )
                              " />
                              <label class="form-check-label" for="inlineRadio1">Validated</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                                value="revalidate" (change)="
                                changeValidation(
                                  $event.target.value,
                                  item.StudentdocId
                                )
                              " />
                              <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ng-template>

                    <ng-template #comments11>
                      <form #form="ngForm">
                        <div class="d-flex align-items-end" style="width: 250px">
                          <div class="flex-grow-1">
                            <textarea class="form-control ct" type="text" #documentComment id="documentComments"
                              rows="2" placeholder="Write comments here..."></textarea>
                          </div>
                          <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                            (click)="postComments(item, 'documentComments')">
                            <img src="./assets/img/send-message.svg" style="width: 16px" />
                          </button>
                        </div>
                      </form>
                    </ng-template>
                  </div>
                  <div class="ml-auto p-2">
                    <button *ngIf="item.uploadedDate != '-' && item.StudentDocPath" class="btn btn-light btn-sm mr-2"
                      (click)="
                      downLoadDocument(item.StudentDocPath, item.ProgramDoc)
                    "
                      [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                      <img src="../../../assets/img/file-download.svg" style="width: 16px" />
                    </button>

                    <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

                    <button [disabled]="sideViewerData.LeadDocStatus == 'completed'" data-toggle="modal"
                      data-target="#uploadFiles" placement="right" tooltipClass="my-custom-class"
                      class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)"
                      [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                      <img src="./assets/img/file-upload.svg" style="width: 16px" />
                    </button>
                    <button type="button" class="btn btn-light btn-sm mr-2"
                      (click)="openPreview(pdfContent, item.StudentDocPath)" [disabled]="
                      item.StudentDocPath == '-' || !item.StudentDocPath
                    ">
                      <img src="./assets/img/view2.svg" style="width: 16px" />
                    </button>
                    <button type="button" class="btn btn-light btn-sm mr-2" placement="bottom"
                      [ngbPopover]="validation11" [disabled]="
                      item.StudentDocPath == '-' ||
                      !item.StudentDocPath ||
                      sideViewerData.LeadDocStatus == 'completed'
                    "
                      [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                      <img *ngIf="
                        item.AgentValidation == '-' ||
                        item.AgentValidation == null
                      " src="./assets/img/tick-g.svg" style="width: 16px" />
                      <img *ngIf="item.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                        style="width: 16px" />
                      <img *ngIf="item.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                        style="width: 16px" />
                    </button>
                    <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="comments11"
                      ngbTooltip="Add Comment"
                      [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                      <img src="./assets/img/comments.svg" style="width: 16px" />
                    </button>
                    <button type="button" data-toggle="modal" data-target="#contentDocs"
                      class="btn btn-light btn-sm mr-2" (click)="callFromDocs(sideViewerData, item)"
                      [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                      <img src="./assets/img/calls-g.svg" style="width: 16px" />
                    </button>
                  </div>
                </div>
                <mat-expansion-panel *ngIf="item.DocComments.length > 0">
                  <mat-expansion-panel-header>
                    <mat-panel-title> Comments </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="comments-section" *ngFor="let i of item.DocComments">
                    <div class="admin-comment" *ngIf="i.Admin == true">
                      <div class="d-flex">
                        <div>
                          <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                            <img *ngIf="i.ProfilePhoto != null" src="{{ i.ProfilePhoto }}" style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " />

                            <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                              {{ i.AgentInitials }}
                            </p>
                          </div>
                        </div>
                        <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                          <div class="d-flex">
                            <div class="sub-header-sm mr-3 flex-fill">
                              {{ i.AgentName }}
                            </div>
                            <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                              {{ i.DateTime | date : "medium" }}
                            </div>
                          </div>
                          <div class="sub-text flex-grow-1">{{ i.Text }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="agent-comment" *ngIf="i.Admin == false">
                      <div class="d-flex">
                        <div>
                          <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                            <img *ngIf="i.ProfilePhoto != null" style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " src="{{ i.ProfilePhoto }}" />

                            <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                              {{ i.AgentInitials }}
                            </p>
                          </div>
                        </div>
                        <div class="pl-2 pr-2 d-flex flex-column">
                          <div class="d-flex">
                            <div class="sub-header-sm mr-3">
                              {{ i.AgentName }}
                            </div>
                            <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                              {{ i.DateTime | date : "medium" }}
                            </div>
                          </div>
                          <div class="sub-text">{{ i.Text }}</div>
                        </div>
                      </div>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                </mat-expansion-panel>
              </div>
              <div class="py-2">
                <button data-toggle="modal" data-target="#exampleModal4" ngbTooltip="Add New Document" placement="right"
                  tooltipClass="my-custom-class" class="btn btn-primary btn-sm" (click)="clearData()"
                  style="padding: 0 5px"
                  [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                  <img src="./assets/img/add-w.svg" style="width: 10px" />
                  Add
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="validationStatus == true" class="text-center mb-2">
            <button class="btn btn-success btn-sm mt-2" disabled style="width: 180px">
              <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
              Validation Completed
            </button>
          </div>
          <div *ngIf="validationStatus == true" class="text-center mb-2">
            <button class="btn btn-success btn-sm mt-2" style="width: 180px" (click)="sendUniMailPopUp()">
              <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
              Send University Mail
            </button>
          </div>
          <div *ngIf="validationStatus == false" class="text-center mb-2">
            <button class="btn btn-danger btn-sm mt-2" disabled style="width: 180px">
              <img src="../assets/img/close-r.svg" style="width: 16px" /> Not
              Validated
            </button>
          </div>
        </div>
      </ng-template>
      <div class="p-3">
        <div class="header-md">
          Comments
          <span style="font-size: 12px">{{
            "(" + (sideViewerData.LeadComments?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="mb-3 pb-1 ct pr" style="background: #f5f5f5; border-radius: 5px">
            <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="leadComments" rows="2"
              placeholder="Write comments here..."></textarea>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
              (click)="postComments(sideViewerData, 'leadComments','attachFileMainComment')"
              [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
              Submit
            </button>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
              (click)="attachFileMainComment.click()"
              [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
              Attach File
            </button>
            <input type="file" #attachFileMainComment id="attachFileMainComment" style="display: none" />
            <div style="clear: both"></div>
          </div>
          <div class="m-h200">
            <div class="d-flex mb-2 mt-1" *ngFor="let j of sideViewerData.LeadComments">
              <div class="d-flex border-bottom pt-1" style="width: 100%">
                <div class="d-flex" style="width: 100%">
                  <div class="">
                    <div *ngIf="j.AgentName" class="pic mr-1 float-left text-center">
                      <img *ngIf="j.ProfilePhoto != null" style="
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;
                          " src="{{ j.ProfilePhoto }}" />

                      <p [ngStyle]="{ background: j.AgentColor }" class="initials" *ngIf="j.ProfilePhoto == null">
                        {{ j.AgentInitials }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="flex-grow-1">
                      <div class="sub-header-sm mr-3 mt-1 pb-1 flex-fill">
                        {{ j.AgentName }}
                      </div>
                    </div>
                    <div class="sub-text flex-grow-1 pb-2">
                      {{ j.Text }}
                    </div>
                  </div>
                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                    <div>
                      {{ j.DateTime | date : "medium" }}
                    </div>
                    <div class="text-center" *ngIf="j?.Attachment?.length>0">
                      <button class="btn btn-light btn-sm mr-2" style="margin-top: 1rem;margin-bottom: 1rem;"
                        (click)="downloadCommentAttachment(j)">
                        <img src="../../../assets/img/file-download.svg" style="width: 16px" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Communication" style="position: relative">
      <mat-tab-group class="sideDataViewer">
        <mat-tab label="SMS">
          <div>
            <div class="mx-2 my-2"></div>
            <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
              <div class="mb-3 pb-1 ct pr" style="
                  background: #f5f5fa;
                  border-radius: 10px;
                  margin: 0 20px 0 10px;
                ">
                <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
                  placeholder="Write your message here..."></textarea>
                <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
                  (click)="sendSms(sideViewerData, 'textMessage')"
                  [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                  <img src="../../../assets/img/send-message.svg" class="img12" />
                </button>
                <div style="clear: both"></div>
              </div>
            </div>
            <div class="p-3">
              <div class="header-md">
                Total Messages
                <span style="font-size: 12px">{{
                  "(" + (smsList?.length || "0") + ")"
                  }}</span>
              </div>
              <div class="comments-block" style="background: #fff">
                <div class="dyn-height">
                  <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
                    <div class="d-flex pt-1" style="width: 100%">
                      <div style="width: 100%">
                        <div class="admin-comment" style="
                            border: 1px solid #f5e7e7;
                            border-radius: 10px;
                            border-bottom-right-radius: 0;
                          " *ngIf="j.Direction == 'inbound'">
                          <div class="d-flex">
                            <div>
                              <div class="pic mr-1 float-left text-center">
                                <img src="../../../assets/img/student.svg" style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 100px;
                                  " />
                              </div>
                            </div>
                            <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                              <div class="d-flex">
                                <span class="header-12">Student</span>

                                <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                  {{ j.DateTime | date : "medium" }}
                                </div>
                              </div>
                              <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                            </div>
                          </div>
                        </div>

                        <div class="agent-comment" style="
                            border-radius: 10px;
                            border-bottom-left-radius: 0;
                            border: 1px solid #def3f7;
                          " *ngIf="j.Direction == 'outbound'">
                          <div class="d-flex">
                            <div>
                              <div class="pic mr-1 float-left text-center">
                                <img style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 100px;
                                  " src="../../../assets/img/admin-comments.svg" />
                              </div>
                            </div>

                            <div class="pl-2 pr-2 d-flex flex-column">
                              <div class="d-flex">
                                <span class="header-12">Agent</span>
                                <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                  {{ j.DateTime | date : "medium" }}
                                </div>
                              </div>
                              <div class="sub-text" style="width: 315px; line-height: 125%">
                                {{ j.Text }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="WhatsApp">
          <div>
            <div class="mx-2 my-2"></div>
            <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
              <div class="mb-3 pb-1 ct pr" style="
                  background: #f5f5fa;
                  border-radius: 10px;
                  margin: 0 20px 0 10px;
                ">
                <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessageWp"
                  rows="2">Thank you for considering University Hub</textarea>
                <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
                  (click)="sendSmsWp(sideViewerData, 'textMessageWp')"
                  [ngClass]="{'customDisabled': (sideViewerData?.Assignedto == null || sideViewerData?.Assignedto == undefined || sideViewerData?.Assignedto == '-' || sideViewerData?.Assignedto == '')}">
                  <img src="../../../assets/img/send-message.svg" class="img12" />
                </button>
                <div style="clear: both"></div>
              </div>
            </div>
            <div class="p-3">
              <div class="header-md">
                Total Messages
                <span style="font-size: 12px">{{
                  "(" + (smsListWp?.length || "0") + ")"
                  }}</span>
              </div>
              <div class="comments-block" style="background: #fff">
                <div class="dyn-height">
                  <div class="d-flex mb-0 mt-1" *ngFor="let j of smsListWp">
                    <div class="d-flex pt-1" style="width: 100%">
                      <div style="width: 100%">
                        <div class="admin-comment" style="
                            border: 1px solid #f5e7e7;
                            border-radius: 10px;
                            border-bottom-right-radius: 0;
                          " *ngIf="j.Direction == 'inbound'">
                          <div class="d-flex">
                            <div>
                              <div class="pic mr-1 float-left text-center">
                                <img src="../../../assets/img/student.svg" style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 100px;
                                  " />
                              </div>
                            </div>
                            <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                              <div class="d-flex">
                                <span class="header-12">Student</span>

                                <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                  {{ j.createdAt | date : "medium" }}
                                </div>
                              </div>
                              <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                            </div>
                          </div>
                        </div>

                        <div class="agent-comment" style="
                            border-radius: 10px;
                            border-bottom-left-radius: 0;
                            border: 1px solid #def3f7;
                          " *ngIf="j.Direction == 'outbound'">
                          <div class="d-flex">
                            <div>
                              <div class="pic mr-1 float-left text-center">
                                <img style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 100px;
                                  " src="../../../assets/img/admin-comments.svg" />
                              </div>
                            </div>

                            <div class="pl-2 pr-2 d-flex flex-column">
                              <div class="d-flex" style="margin-right: 20px;">
                                <span class="header-12">Agent</span>
                                <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                  {{ j.createdAt | date : "medium" }}
                                </div>
                              </div>
                              <div class="sub-text" style="width: 315px; line-height: 125%">
                                {{ j.Text }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height: 200%">
            {{ sideViewerData.Activities.length }} Actions taken
          </div>
          <div class="ml-auto" style="width: 180px">
            <input class="searchActivity" type="text" style="font-size: 13px" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.Activities?.length)" />
          </div>
        </div>
        <div *ngFor="
            let i of sideViewerData.Activities
              | slice : 0 : minItems
              | searchFilterTable : activityfilterString
          ">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i.Description }}
              <span class="sm-text ml-2">{{
                i.createdAt | date : "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore" *ngIf="minItems < sideViewerData.Activities.length"
          (click)="showmore(sideViewerData.Activities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>

<!-- Date Filter -->

<div class="p-3">
  <div class="pl-2 pr-2 d-flex mb-4">
    <div class="db-sm-header mr-auto" style="line-height: 330%">Activity</div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>

  <carousel [cellsToScroll]="2" style="padding-left: 50px; z-index: unset" class="fixheight">
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('total'); onClick(1)"
          [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.total || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Students</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('assignedto'); onClick(11)"
          [ngClass]="{ 'db-block-active': tab === 'tab11' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.assignedToMe || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Assigned to Me</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('TransferStudent'); onClick(12)"
          [ngClass]="{ 'db-block-active': tab === 'tab12' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.TransferStudent || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Transfer Student</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('InternationalStudent'); onClick(13)"
          [ngClass]="{ 'db-block-active': tab === 'tab13' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.InternationalStudent || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">International Student</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('facebook'); onClick(2)"
          [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.facebook || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Facebook Leads</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('Website'); onClick(3)"
          [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.web || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Web Leads</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('callback'); onClick(4)"
          [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.followup || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Follow Up</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('pending'); onClick(5)"
          [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount?.pending || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Pending</div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('counsellingpending'); onClick(6)"
          [ngClass]="{ 'db-block-active': tab === 'tab6' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.counsellingpending || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Counselling Pending</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('counsellingcompleted'); onClick(7)"
          [ngClass]="{ 'db-block-active': tab === 'tab7' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.counsellingcompleted || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Counselling Completed</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('interested'); onClick(8)"
          [ngClass]="{ 'db-block-active': tab === 'tab8' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.interested || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Interested</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('duplicate'); onClick(9)"
          [ngClass]="{ 'db-block-active': tab === 'tab9' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.duplicate || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Duplicate Lead</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3">
        <div class="db-block w-175" (click)="getLeadsByCards('enrolled'); onClick(10)"
          [ngClass]="{ 'db-block-active': tab === 'tab10' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                {{ topBarCount.enrolled || 0 }}
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Enrolled Lead</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell"></div>
  </carousel>

  <div style="clear: both"></div>
</div>
<div class="pl-4 pr-4 pb-4">
  <div class="d-flex align-items-center">
    <div class="header-12 mr-5">List of Students</div>
    <div class="d-flex m-3 legend">
      <div class="mr-1">
        <img src="../../../assets/img/us-transfer.svg" class="img12" />
      </div>
      <div class="db-sm-header mr-3 mt-1">US Transfer</div>

      <div class="mr-1">
        <img src="../../../assets/img/international.svg" class="img12" />
      </div>
      <div class="db-sm-header mt-1">International</div>
    </div>
    <div class="ml-auto content-containers" style="margin-left: 30px">
      <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />
      <div class="d-flex justify-content-end">
        <div>
          <div class="mr-3">
            <div *ngIf="filePath" class="form-control" style="
                height: 16px;
                padding: 0px;
                background: none;
                font-size: 13px;
                color: #999;
                border: 0;
              ">
              {{ filePath }}
            </div>
            <div class="upload-msg-success" *ngIf="uploaded" style="color: green; font-size: 10px">
              {{ insertCount + " " + "Contacts inserted" }}
            </div>
            <div class="upload-msg-error" *ngIf="error">
              {{ errorMessage }}
            </div>
          </div>

          <div style="clear: both"></div>
        </div>

        <div class="ml-auto">
          <button data-toggle="modal" data-target="#exampleModal3" *ngIf="admin" (click)="assignAgentModal()"
            class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Assign" placement="top"
            tooltipClass="my-custom-class">
            <img src="../../../../assets/img/assign-w.svg" class="img13" />
          </button>
          <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Download Sample" placement="top"
            tooltipClass="my-custom-class">
            <a href="{{ downloadLink }}">
              <img src="../../../../assets/img/download-w.svg" class="img13" />
            </a>
          </button>
          <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Upload File" placement="top"
            (click)="redirectToUpload()" tooltipClass="my-custom-class">
            <img src="../../../../assets/img/upload-w.svg" class="img13" />
          </button>
          <!-- <button
            class="btn btn-secondary btn-sm ml-2"
            ngbTooltip="Start Call"
            placement="top"
            (click)="startCallListener()"
            tooltipClass="my-custom-class"
            data-toggle="modal"
            data-target="#callingscreen2"
          >
            <img
              src="../../../../assets/img/start-call-w.svg"
              style="width: 13px"
            />
          </button>
          <button
            class="btn btn-secondary btn-sm ml-2"
            ngbTooltip="Stop Call"
            placement="top"
            (click)="stopCallListener()"
            tooltipClass="my-custom-class"
          >
            <img
              src="../../../../assets/img/stop-call-w.svg"
              style="width: 13px"
            />
          </button> -->
          <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Create Lead" placement="top"
            tooltipClass="my-custom-class" (click)="openCreateLead(content3)">
            <img src="assets/img/add-w.svg" style="width: 13px" />
          </button>
          <div></div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <div class="data-table" style="background: #fff">
      <table class="table table-md mt-1 mb-2 ctable">
        <thead>
          <tr>
            <td colspan="5">
              <div class="indived-search" style="position: relative">
                <input type="search" (input)="onInput($event)" #inputString class="form-control search-all"
                  [(ngModel)]="search" placeholder="Search " autocomplete="off" />
              </div>
            </td>
            <td colspan="8" class="vm">
              <div class="pagenation-right d-flex justify-content-end align-items-center">
                <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
                  (ngModelChange)="dropdownSelect($event)">
                  <option value="all">All</option>
                  <option value="callback">Follow Up</option>
                  <option value="called">Called</option>
                  <option value="callfailed">Call Failed</option>
                  <option value="duplicate">Duplicate</option>
                  <option value="futurelead">Future Lead</option>
                  <option value="interested">Interested</option>
                  <option value="interestedpending">Interested Pending</option>
                  <option value="notanswered">Not Answered</option>
                  <option value="notinterested">Not Interested</option>
                  <option value="pending">Pending</option>
                  <option value="informed">Informed</option>
                  <option value="deferredintake">Deferred Intake</option>
                  <option value="counsellingpending">
                    Counselling Pending
                  </option>
                  <option value="counsellingcompleted">
                    Counselling Completed
                  </option>
                  <option value="applicationsubmitted">
                    Application Submitted
                  </option>
                  <option value="documentspending">Documents Pending</option>
                  <option value="documentssubmitted">
                    Documents Submitted
                  </option>
                  <option value="paymentpending">Payment Pending</option>
                  <option value="pendingi20">Pending I20</option>
                  <option value="pendingvisa">Pending VISA</option>
                  <option value="visadeclined">VISA Declined</option>
                  <option value="visaapproved">VISA Approved</option>
                  <option value="pendingtravel">Pending Travel</option>
                  <option value="travelcomplete">Travel Complete</option>
                  <option value="enrolled">Enrolled</option>
                  <option value="prospect">Prospect</option>
                </select>
                <div style="color: #fff; font-size: 12px">
                  <app-custom-pagination></app-custom-pagination>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tr style="background: #eaf5fd">
          <th style="width: 30px; white-space: nowrap;padding-left: 20px;" *ngIf="admin">
            <div style="width: 20px">
              <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                (change)="selectAllforAssignment()" />
            </div>
          </th>
          <th nowrap>Actions</th>
          <th nowrap>
            Created
            <span><button class="sortButton" (click)="toggleSort('createdAt', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Name
            <span><button class="sortButton" (click)="toggleSort('FirstName', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Email
            <span><button class="sortButton" (click)="toggleSort('Email', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Assigned To
            <span><button class="sortButton" (click)="toggleSort('Assignedto', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Recruiter
            <span><button class="sortButton">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Universities
            <span><button class="sortButton" (click)="toggleSort('FirstName', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Form
            <span><button class="sortButton" (click)="toggleSort('FormName', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>

          <th nowrap>
            Phone
            <span><button class="sortButton" (click)="toggleSort('PhoneNumber', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Channel
            <span><button class="sortButton" (click)="toggleSort('Channel', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
          <th nowrap>
            Call Status
            <span><button class="sortButton" (click)="toggleSort('CallStatus', 'asc')">
                <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
          </th>
        </tr>
        <tr *ngFor="let i of LeadList; let id = index">
          <td class="vm" nowrap *ngIf="admin" style="padding-left: 20px;">
            <div style="width: 20px">
              <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead" id="selectLead"
                (change)="selectAgentforAssignment($event, i)" />
            </div>
          </td>
          <td class="vm text-center" nowrap style="text-align: left !important" *ngIf="
              i.fromUS != 'true' &&
              i.fromUS != 'false' &&
              i.transferDetails.length == 0
            ">
            <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" placement="top"
              tooltipClass="my-custom-class">
              <mat-sidenav-content style="display: inline !important">
                <div style="display: inline !important; cursor: pointer; position: relative;"
                  (click)="sideNav.toggle(); sideNavData(i);">
                  <img src="../../../../assets/img/view2.svg" class="img13" />
                  <span class="notification d-flex justify-content-center" *ngIf="i?.unreadCount>0">
                    {{i?.unreadCount > 9 ? "9+" : i?.unreadCount}}</span>
                </div>
              </mat-sidenav-content>
            </mat-sidenav-container>
            <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="ml-2 noassign"
              style="display: inline; cursor: pointer"
              *ngIf="(i.Assignedto == null || i.Assignedto == undefined || i.Assignedto == '-' || i.Assignedto == '');else callNow">
              <img src="../../../../assets/img/call.svg" class="img13" (click)="notAssignedModel(i)" />
            </div>
            <ng-template #callNow>
              <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="ml-2"
                style="display: inline; cursor: pointer" data-toggle="modal" data-target="#content">
                <img src="../../../../assets/img/call.svg" class="img13" (click)="startCallListener2(i, id)" />
              </div>
            </ng-template>
          </td>
          <td class="vm text-center" nowrap style="text-align: left !important" *ngIf="
              i.fromUS == 'true' ||
              i.fromUS == 'false' ||
              i.transferDetails.length > 0
            ">
            <mat-sidenav-container style="display: inline !important" ngbTooltip="Overview" placement="top"
              tooltipClass="my-custom-class">
              <mat-sidenav-content style="display: inline !important">
                <div style="display: inline !important; cursor: pointer; position: relative;"
                  (click)="sideNav.toggle(); sideNavData(i); markNotificationRead(i.id);setUniversity(i.universityinfo);">
                  <img src="../../../../assets/img/view2.svg" class="img13" />
                  <span class="notification d-flex justify-content-center" *ngIf="i?.unreadCount>0">{{
                    i?.unreadCount > 9 ? "9+" : i?.unreadCount
                    }}</span>
                </div>
              </mat-sidenav-content>
            </mat-sidenav-container>
            <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="ml-3"
              style="display: inline; cursor: pointer"
              *ngIf="(i.Assignedto == null || i.Assignedto == undefined || i.Assignedto == '-' || i.Assignedto == '');else callNow">
              <img src="../../../../assets/img/call.svg" class="img13" (click)="notAssignedModel(i)" />
            </div>
            <ng-template #callNow>
              <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="ml-2"
                style="display: inline; cursor: pointer" data-toggle="modal" data-target="#content">
                <img src="../../../../assets/img/call.svg" class="img13" (click)="startCallListener2(i, id)" />
              </div>
            </ng-template>
          </td>
          <td class="vm" nowrap>{{ i?.createdAt | date : "dd MMM, yyyy" }}</td>
          <td class="vm longText" nowrap>
            <div *ngIf="
                i.fromUS == 'true' ||
                (i.transferDetails.length > 0 &&
                  i.transferDetails[0].fromUS == 'true')
              ">
              <img src="../../../assets/img/us-transfer.svg" class="img12 mr-1" />
              <button class="btn btn-link btn-sm" (click)="updateComments(content2, i)">
                <div class="tooltip_title">
                  <div class="txt-oflow2">
                    {{ i?.FirstName + " " + i?.LastName | titlecase }}
                  </div>
                  <span class="tooltiptext">{{ i?.FirstName + " " + i?.LastName | titlecase }}</span>
                </div>
                <!-- {{ i?.FirstName + " " + i?.LastName | titlecase }} -->
              </button>
            </div>
            <div *ngIf="
                i.fromUS == 'false' ||
                (i.transferDetails.length > 0 &&
                  i.transferDetails[0].fromUS == 'false')
              ">
              <img src="../../../assets/img/international.svg" class="img12 mr-1" />
              <button class="btn btn-link btn-sm" (click)="updateComments(content2, i)">
                <div class="tooltip_title">
                  <div class="txt-oflow2">
                    {{ i?.FirstName + " " + i?.LastName | titlecase }}
                  </div>
                  <span class="tooltiptext">{{ i?.FirstName + " " + i?.LastName | titlecase }}</span>
                </div>
                <!-- {{ i?.FirstName + " " + i?.LastName | titlecase }} -->
              </button>
            </div>
            <button *ngIf="i.transferDetails.length == 0" class="btn btn-link btn-sm"
              (click)="updateComments(content2, i)">
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  {{ i?.FirstName + " " + i?.LastName | titlecase }}
                </div>
                <span class="tooltiptext">{{ i?.FirstName + " " + i?.LastName | titlecase }}</span>
              </div>
            </button>
          </td>
          <td class="vm" nowrap>
            <div>
              <div class="tooltip_title">
                <div class="txt-oflow2">
                  <span>
                    {{ i?.Email }}
                  </span>
                </div>
                <span class="tooltiptext">{{ i?.Email }}</span>
              </div>
            </div>
          </td>
          <td class="vm" nowrap *ngIf="i?.AssignedtoName">
            <div class="d-flex align-items-center">
              <div *ngIf="i?.AssignedtoName" class="pic mr-1 float-left text-center">
                <img *ngIf="i.AssignedtoImageURL != null" class="img20" src="{{ i?.AssignedtoImageURL }}" />

                <p [ngStyle]="{ background: i?.AssignedtoColor }" class="initials"
                  *ngIf="i?.AssignedtoImageURL == null">
                  {{ i?.AssignedtoInitial }}
                </p>
              </div>
              <div class="float-left mt-2">
                <div class="tooltip_title">
                  <div class="txt-oflow2">
                    {{ i?.AssignedtoName | titlecase }}
                  </div>
                  <span class="tooltiptext">{{ i?.AssignedtoName | titlecase }}</span>
                </div>
              </div>
            </div>
          </td>
          <td class="vm" nowrap *ngIf="!i?.AssignedtoName">--
          </td>
          <td class="vm" nowrap>
            <div class="tooltip_title">
              <div class="txt-oflow2">
                <span>
                  {{i?.RecruiterName || '-'}}
                </span>
              </div>
              <span class="tooltiptext">{{i?.RecruiterName || '-'}}</span>
            </div>

          </td>
          <td class="vm" nowrap>
            <!-- <span *ngIf="i.UniversityCount > 0;else noUniversityCount" data-toggle="modal" data-target="#unicount"
              (click)="getUniList(i)" style="cursor: pointer;color: blue;">
              {{ i?.UniversityCount }}
            </span>
            <ng-template #noUniversityCount>
              <span>0</span>
            </ng-template> -->
            <span>{{i?.universityinfo?.name || '-'}}</span>
          </td>
          <td class="vm" nowrap>
            <div class="tooltip_title">
              <div class="txt-oflow2">
                <span>
                  {{ i?.FormName }}
                </span>
              </div>
              <span class="tooltiptext">{{ i?.FormName }}</span>
            </div>
          </td>

          <td class="vm" nowrap>{{ i?.PhoneNumber }}</td>
          <td class="vm" nowrap>
            <div class="d-flex" *ngIf="i.Channel != '-'">
              <div class="txt-oflow2 lh180 float-left longText2">
                <div class="tooltip_title">
                  <div class="txt-oflow2">
                    <span>
                      {{ i?.Channel }}
                    </span>
                  </div>
                  <span class="tooltiptext">{{ i?.Channel }}</span>
                </div>
              </div>
            </div>
          </td>

          <td class="vm text-center" nowrap style="text-align: left !important">
            <!-- <div class="status-pending">{{i.CallStatus}}</div> -->
            <span class="status-pending" *ngIf="i.CallStatus == 'pending'">Pending</span>
            <span class="status-not-ans" *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'completed'">Completed</span>
            <span class="status-callback" *ngIf="i.CallStatus == 'callback'">Follow Up</span>
            <span class="status-informed" *ngIf="i.CallStatus == 'informed'">Informed</span>
            <span class="status-informed" *ngIf="i.CallStatus == 'called'">Called</span>
            <span class="status-failed" *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
            <span class="status-deferred" *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
            <span class="status-pending" *ngIf="
                i.CallStatus == 'callnotanswered' ||
                i.CallStatus == 'notanswered'
              ">Not Answered</span>

            <span class="status-completed" *ngIf="i.CallStatus == 'duplicate'">Duplicate</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'futurelead'">Future Lead</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'interestedpending'">Interested Pending</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'interested'">Interested</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'counsellingpending'">Counselling Pending</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'counsellingcompleted'">Counselling Completed</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'applicationsubmitted'">Application Submitted</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'documentspending'">Documents Pending</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'documentssubmitted'">Documents Submitted</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'paymentpending'">Payment Pending</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'pendingi20'">Pending I20</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'pendingvisa'">Pending VISA</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'visadeclined'">VISA Declined</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'visaapproved'">VISA Approved</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'pendingtravel'">Pending Travel</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'travelcomplete'">Travel Complete</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'enrolled'">Enrolled</span>
            <span class="status-completed" *ngIf="i.CallStatus == 'prospect'">Prospect</span>
          </td>

        </tr>
        <tr class="h30"></tr>
        <tr *ngIf="isLoading == true">
          <td colspan="10">
            <div class="norecords">
              <img src="../../../../assets/img/waiting.gif" />

              <div>
                Loading Data<br />Please wait while we fetch the data for you
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="noRecords == true">
          <td colspan="10">
            <div class="norecords">
              <img src="../../../../assets/img/nodata.svg" />
              <div>No records found for the selected filters.<br /></div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<!-- edit lead -->
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="background: #fff">
    <form class="pt-2" [formGroup]="this.layoutService.commentsForm" (ngSubmit)="submit()">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Name</label>
          <input type="text" class="form-control" id="" placeholder="Name" formControlName="name" [ngClass]="{
              'is-invalid':
                submitted &&
                f.name.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.name.valid
            }" />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">name is required</div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Email Id</label>
          <input type="email" class="form-control" id="" placeholder="Email Id" formControlName="emailId" [ngClass]="{
              'is-invalid':
                submitted &&
                f.emailId.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.emailId.valid
            }" />
          <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
            `
            <div *ngIf="
                f.emailId.errors.required &&
                layoutService.commentsValue.interested !== 'notinterested'
              ">
              Please Enter a Valid EmailId
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Date of Birth</label>
          <input type="date" class="form-control" id="" placeholder="" formControlName="dob" [ngClass]="{
              'is-invalid':
                submitted &&
                f.dob.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.dob.valid && f.dob.touched
            }" />
          <div *ngIf="
              submitted &&
              f.dob.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
            <div *ngIf="f.dob.errors.required">Date of birth is required</div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>State/Country</label>
          <input type="email" class="form-control" id="" placeholder="" formControlName="state" [ngClass]="{
              'is-invalid':
                submitted &&
                f.state.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.state.valid && f.state.touched
            }" />

          <div *ngIf="
              submitted &&
              f.state.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
            <div *ngIf="f.state.errors.required">State is required</div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Alternative Contact Number</label>
          <input type="text" class="form-control" id="" placeholder="" formControlName="altContactNumber" [ngClass]="{
              'is-invalid':
                submitted &&
                f.altContactNumber.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.altContactNumber.valid && f.altContactNumber.touched
            }" />
          <div *ngIf="submitted && f.altContactNumber.errors" class="invalid-feedback">
            <div *ngIf="f.altContactNumber.errors">
              Alternate ContactNumber is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Phone Number</label>
          <div class="form-row">
            <div class="col-2">
              <input type="text" class="form-control" id="" placeholder="" formControlName="countryCode" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.altContactNumber.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.countryCode.valid && f.countryCode.touched
                }" />
              <div *ngIf="submitted && f.countryCode.errors" class="invalid-feedback">
                <div *ngIf="f.countryCode.errors">
                  Alternate ContactNumber is required
                </div>
              </div>
            </div>
            <div class="col-8">
              <input type="text" class="form-control" id="" placeholder="" formControlName="phoneNumber" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    f.altContactNumber.errors &&
                    layoutService.commentsValue.interested !== 'notinterested',
                  'is-valid': f.phoneNumber.valid && f.phoneNumber.touched
                }" />
              <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                <div *ngIf="f.phoneNumber.errors">
                  Alternate ContactNumber is required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label for="">Country Preference </label>
          <input type="text" class="form-control" id="" placeholder="" formControlName="countryPreference" [ngClass]="{
              'is-invalid':
                submitted &&
                f.countryPreference.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid':
                f.countryPreference.valid && f.countryPreference.touched
            }" />
          <div *ngIf="submitted && f.countryPreference.errors" class="invalid-feedback">
            <div *ngIf="f.countryPreference.errors.required">
              CountryPreference is required
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="">Existing Counselling</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio1" value="yes"
                checked="checked" formControlName="existingCounseling" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio2" value="no"
                formControlName="existingCounseling" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Valid Passport?</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="passport" id="inlineRadio1" value="yes"
                formControlName="passport" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="passport" id="inlineRadio2" value="no"
                formControlName="passport" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Applied for Visa?</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="visa" id="inlineRadio1" value="yes"
                formControlName="visa" />
              <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="visa" id="inlineRadio2" value="no"
                formControlName="visa" />
              <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <div style="float: left; width: 35%; margin-right: 10px">
            <label>IELTS</label>
            <input type="text" formControlName="IELETS" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.IELETS.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.IELETS.valid && f.IELETS.touched
              }" />

            <div *ngIf="
                submitted &&
                f.IELETS.errors &&
                layoutService.commentsValue.interested !== 'notinterested'
              " class="invalid-feedback">
              <div *ngIf="f.IELETS.errors.required">IELETS is required</div>
            </div>
          </div>
          <div class="" style="float: left; width: 35%; margin-right: 10px">
            <label>TOEFL</label>
            <input type="text" formControlName="TOEFL" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.TOEFL.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.TOEFL.valid && f.TOEFL.touched
              }" />
          </div>
          <div *ngIf="submitted && f.TOEFL.errors" class="invalid-feedback">
            <div *ngIf="f.TOEFL.errors.required">TOEFL is required</div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <div class="" style="float: left; width: 35%; margin-right: 10px">
            <label>PTE</label>
            <input type="text" formControlName="PTE" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.PTE.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.PTE.valid && f.PTE.touched
              }" />

            <div *ngIf="submitted && f.PTE.errors" class="invalid-feedback">
              <div *ngIf="f.PTE.errors.required">PTE is required</div>
            </div>
          </div>
          <div class="" style="float: left; width: 35%; margin-right: 10px">
            <label>Duolingo</label>
            <input type="text" formControlName="Dulingo" class="form-control" placeholder="" [ngClass]="{
                'is-invalid':
                  submitted &&
                  f.Dulingo.errors &&
                  layoutService.commentsValue.interested !== 'notinterested',
                'is-valid': f.Dulingo.valid && f.Dulingo.touched
              }" />

            <div *ngIf="submitted && f.Dulingo.errors" class="invalid-feedback">
              <div *ngIf="f.Dulingo.errors.required">Dulingo is required</div>
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>If not when planning to appear</label>
          <input type="" class="form-control" id="" placeholder="" formControlName="planning" [ngClass]="{
              'is-invalid':
                submitted &&
                f.planning.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.planning.valid && f.planning.touched
            }" />
          <div *ngIf="submitted && f.planning.errors" class="invalid-feedback">
            <div *ngIf="
                f.planning.errors.required &&
                layoutService.commentsValue.interested !== 'notinterested'
              ">
              Planning is required
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="">Current Qualification</label>
          <input type="text" formControlName="qualification" class="form-control" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.qualification.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.qualification.valid && f.qualification.touched
            }" />
          <div *ngIf="
              submitted &&
              f.qualification.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
            <div *ngIf="f.qualification.errors.required">
              Qualification is required
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label for="">Work Experience</label>
          <input type="text" class="form-control" id="" formControlName="workExperience" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.workExperience.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.workExperience.valid && f.workExperience.touched
            }" />
          <div *ngIf="
              submitted &&
              f.workExperience.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
            <div *ngIf="f.workExperience.errors.required">
              Work Experience is required
            </div>
          </div>
        </div>

        <div class="form-group col-md-4">
          <label>Course Interested</label>
          <input type="text" formControlName="courseInterested" class="form-control" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.courseInterested.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.courseInterested.valid && f.courseInterested.touched
            }" />

          <div *ngIf="
              submitted &&
              f.courseInterested.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
            <div *ngIf="f.courseInterested.errors.required">
              Course Interested is required
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-4">
          <label for="description" class="inputEmail4">Assigned To</label>
          <ng-multiselect-dropdown [settings]="layoutService.dropdownSettings" [data]="layoutService.roleAgents"
            name="manager" formControlName="assignedTo" [(ngModel)]="layoutService.selectedAgent"
            style="width: 100%; padding: 0">
          </ng-multiselect-dropdown>
          <div *ngIf="submitted && f.assignedTo.errors" class="invalid-feedback">
            <div *ngIf="f.assignedTo.errors.required">
              Assigned To is required
            </div>
          </div>
        </div>

        <div cGlass="form-group col-md-4">
          <label for="">Counselling Time and Date</label>
          <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="counsellingDate"
            [ngClass]="{ 'is-invalid': submitted && f.counsellingDate.errors }" />
          <div *ngIf="submitted && f.counsellingDate.errors" class="invalid-feedback">
            <div *ngIf="f.counsellingDate.errors.required">
              Counselling date is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Call Back</label>
          <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="callBack" [ngClass]="{
              'is-invalid': submitted && f.callBack.errors
            }" />
          <div *ngIf="submitted && f.callBack.errors" class="invalid-feedback">
            <div *ngIf="f.callBack.errors.required">
              callBack date is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Funding Source</label>
          <input type="text" formControlName="fundingResource" class="form-control" placeholder="" [ngClass]="{
              'is-invalid':
                submitted &&
                f.fundingResource.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.fundingResource.valid && f.fundingResource.touched
            }" />

          <div *ngIf="
              submitted &&
              f.fundingResource.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
            <div *ngIf="f.fundingResource.errors.required">
              Funding Resource is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Intake preferred</label>
          <input class="form-control" type="text" formControlName="intakePreferred" [ngClass]="{
              'is-invalid':
                submitted &&
                f.intakePreferred.errors &&
                layoutService.commentsValue.interested !== 'notinterested',
              'is-valid': f.intakePreferred.valid && f.intakePreferred.touched
            }" />

          <div *ngIf="
              submitted &&
              f.intakePreferred.errors &&
              layoutService.commentsValue.interested !== 'notinterested'
            " class="invalid-feedback">
            <div *ngIf="f.intakePreferred.errors.required">
              In Take Proffered is required
            </div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="">Comments</label>
          <input type="text" formControlName="comments" class="form-control" placeholder="" [ngClass]="{
              'is-valid': f.comments.valid && f.comments.touched
            }" />
        </div>

        <!-- Radio button  -->

        <div class="form-group col-md-12">
          <label for="">Feedback</label>
          <div></div>
          <div class="form-check form-check-inline">
            <select formControlName="interested" class="form-control">
              <option value="pending">Pending</option>
              <option value="notinterested">Not Interested</option>
              <option value="informed">Informed</option>
              <option value="callfailed">Call Failed</option>
              <option value="callback">Follow Up</option>
              <option value="notanswered">Not Answered</option>
              <option value="deferredintake">Deferred Intake</option>
              <option value="duplicate">Duplicate</option>
              <option value="futurelead">Future Lead</option>
              <option value="interestedpending">Interested Pending</option>
              <option value="interested">Interested</option>
              <option value="counsellingpending">Counselling Pending</option>
              <option value="counsellingcompleted">
                Counselling Completed
              </option>
              <option value="applicationsubmitted">
                Application Submitted
              </option>
              <option value="documentspending">Documents Pending</option>
              <option value="documentssubmitted">Documents Submitted</option>
              <option value="paymentpending">Payment Pending</option>
              <option value="pendingi20">Pending I20</option>
              <option value="pendingvisa">Pending VISA</option>
              <option value="visadeclined">VISA Declined</option>
              <option value="visaapproved">VISA Approved</option>
              <option value="pendingtravel">Pending Travel</option>
              <option value="travelcomplete">Travel Complete</option>
              <option value="enrolled">Enrolled</option>
              <option value="prospect">Prospect</option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button class="btn btn-primary btn-sm" type="submit">Update</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!-- for assign model-->
<div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Assign to Agent</h5>
        <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="height: none">
        <div class="px-2">
          <div class="py-2 header-lg">
            <b>{{ leadIds.length }}</b> Students are selected to assign.
          </div>
          <div class="d-flex mt-2 mb-3">
            <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
              <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                (change)="agentSelected($event.target.value)">
                <option value="default" disabled selected>Select Agent</option>
                <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                  {{ agent.FirstName + " " + agent.LastName }}
                </option>
              </select>
            </div>
            <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
              Assign
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- add lead -->
<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Lead</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3" style="max-height: 600px; background: #fff">
    <form (ngSubmit)="createLead()" [formGroup]="marketingService.createLead">
      <div class="form-group">
        <label class="form-label">First Name</label>
        <input class="form-control" name="firstName" formControlName="firstName" [ngClass]="{
            'is-invalid': createLeads.firstName.touched && createLeads.firstName.invalid,
            'is-valid':
              createLeads.firstName.valid && createLeads.firstName.touched
          }" />
        <div *ngIf="createLeads.firstName.touched && createLeads.firstName.errors" class="invalid-feedback">
          <div *ngIf="createLeads.firstName.errors.required">First Name is required</div>
          <div *ngIf="createLeads.firstName.errors.pattern">Please use valid Name</div>
          <div *ngIf="createLeads.firstName.errors.minlength">Minimum length should be 2 characters .</div>
          <div *ngIf="createLeads.firstName.errors.maxlength">Maximum length should be 50 characters .</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Last Name</label>
        <input class="form-control" name="lastName" formControlName="lastName" [ngClass]="{
            'is-invalid': createLeads.lastName.touched && createLeads.lastName.invalid,
            'is-valid':
              createLeads.lastName.valid && createLeads.lastName.touched
          }" />
        <div *ngIf="createLeads.lastName.touched && createLeads.lastName.errors" class="invalid-feedback">
          <div *ngIf="createLeads.lastName.errors.required">LastName is required .</div>
          <div *ngIf="createLeads.lastName.errors.pattern">Please use valid name .</div>
          <div *ngIf="createLeads.lastName.errors.minlength">Minimum length should be 2 characters .</div>
          <div *ngIf="createLeads.lastName.errors.maxlength">Maximum length should be 50 characters .</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Phone Number</label>
        <div class="row">
          <div class="col-2">
            <div class="dropdown">
              <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" data-toggle="dropdown" aria-expanded="false">
                +<span class="country-code">{{ selectedCountryCode }}</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="    max-height: 200px;
              overflow: auto;width: max-content;">
                <input type="search" (input)="onInputC($event)" class="form-control search-all" style="
                height: auto;
                padding: 4px 12px;
                margin: 0 9px;
              " placeholder="Search " autocomplete="off" />
                <li *ngFor="let country of countries" style="    padding: 5px;cursor: pointer;">
                  <span href="#" (click)="selectCountry(country)">
                    <img src="{{
                        country.flag || country.flags.svg || country.flags.png
                      }}" alt="" style="height: 15px; width: 20px" />
                    <span style="padding-left: 8px">{{ country.name }} (+{{ country.callingCodes[0] }})</span>
                  </span>
                </li>
              </ul>
            </div>
            <div *ngIf="createLeads.countryCode.touched && createLeads.countryCode.errors" class="invalid-feedback">
              <div *ngIf="createLeads.countryCode.errors.required">Country Code is required</div>
              <div *ngIf="createLeads.countryCode.errors.pattern">Only Numbers are allowed</div>
            </div>
          </div>
          <div class="col-10">
            <input class="form-control" name="phoneNumber" formControlName="phoneNumber" [ngClass]="{
                'is-invalid': createLeads.phoneNumber.touched && createLeads.phoneNumber.invalid,
                'is-valid':
                  createLeads.phoneNumber.valid &&
                  createLeads.phoneNumber.touched
              }" />
            <div *ngIf="createLeads.phoneNumber.touched && createLeads.phoneNumber.errors" class="invalid-feedback">
              <div *ngIf="createLeads.phoneNumber.errors.required">Phone Number is required .</div>
              <div *ngIf="createLeads.phoneNumber.errors.pattern">Only Numbers are allowed .</div>
              <div *ngIf="createLeads.phoneNumber.errors.minlength">Minimum length should be 7 characters .
              </div>
              <div *ngIf="createLeads.phoneNumber.errors.maxlength">Maximum length should be 15 characters .
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Email</label>
        <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
            'is-invalid': createLeads.email.touched && createLeads.email.invalid,
            'is-valid': createLeads.email.valid && createLeads.email.touched
          }" />
        <div *ngIf="createLeads.email.touched && createLeads.email.errors" class="invalid-feedback">
          <div *ngIf="createLeads.email.errors.required">Email is required</div>
          <div *ngIf="createLeads.email.errors.pattern">Please use valid email</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Form Name</label>
        <input type="text" name="form_name" class="form-control" formControlName="form_name" [ngClass]="{
            'is-invalid': createLeads.form_name.touched && createLeads.form_name.invalid,
            'is-valid':
              createLeads.form_name.valid && createLeads.form_name.touched
          }" />
        <div *ngIf="createLeads.form_name.touched && createLeads.form_name.errors" class="invalid-feedback">
          <div>Form Name is required</div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label">Channel</label>
        <input type="text" name="channel" class="form-control" formControlName="channel" [ngClass]="{
            'is-invalid': createLeads.channel.touched && createLeads.channel.invalid,
            'is-valid': createLeads.channel.valid && createLeads.channel.touched
          }" />
        <div *ngIf="createLeads.channel.touched && createLeads.channel.errors" class="invalid-feedback">
          <div>Channel is required</div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary btn-sm" [disabled]="!marketingService.createLead.valid">Submit</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- for add new document screen-->
<div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 440px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add New Document</h5>
        <button type="button" #closeButtonCustom class="close" data-dismiss="modal" aria-label="Close"
          (click)="customFileList = [];docUploadError='';">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group mr-2">
            <label for="exampleInputEmail1" style="font-size: 13px">Document Name</label>
            <input [(ngModel)]="customDocName" type="text" class="form-control"
              style="padding: 0px 5px; width: 300px; font-size: 13px" />
            <div class="mt-2">
              <small style="font-size: 14px;"><b>Please use CTRL to select multiple files .</b></small>
            </div>
          </div>
          <div>
            <input hidden type="file" #uploadFile2 (change)="uploadCustomDocument($event)" multiple />
            <button type="button" class="btn btn-secondary btn-sm mr-2"
              style="padding: 5px 10px; margin-top: 22px; font-size: 13px" (click)="uploadFile2.click()">
              <img class="img12" src="../../../assets/img/upload-w.svg" />
            </button>
          </div>
          <div>
            <button (click)="addCustomDocument()" type="button" class="btn btn-secondary btn-sm"
              style="padding: 5px 10px; margin-top: 22px; font-size: 13px">
              Add
            </button>
          </div>
        </div>
        <div>
          <div class="add-docs-item-sub ml-auto">
            <div class="txt-oflow-lg text-left">
              Selected Files: {{ customFileList.length }}
            </div>
          </div>
          <div *ngFor="let i of customFileList" class="d-flex add-docs-item align-items-center">
            <div class="">
              <img class="img12 mr-1 lh160" src="../../../assets/img/docs-r.svg" />
              {{ i.name | titlecase }}<br />
              <div class="add-docs-item-sub d-flex" style="margin-left: 20px">
                <div class="txt-oflow-lg">
                  {{ i.fileName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="customFileList.length == 0" class="d-flex p-3 flex-column">
          <div class="text-center p-3">
            <img src="../../../assets/img/upload-file-2.png" />
          </div>
          <div class="text-center">
            Upload below supported documents only <br />
            <span style="font-size: 18px; line-height: 180%">.doc .pdf .png .jpg .jpeg</span>
          </div>
        </div>
        <div>
          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
        </div>
        <div class="p-3 text-center docUploadError" style="color: green; font-size: 14px; font-weight: bold">
          {{ docUploadError }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- for add new document screen-->
<div class="modal fade" id="exampleModal5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 440px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add New Document</h5>
        <button type="button" #closeButtonCustom5 class="close" data-dismiss="modal" aria-label="Close"
          (click)="customFileList = [];docUploadError='';">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex">
          <div class="form-group mr-2">
            <label for="exampleInputEmail1" style="font-size: 13px">Document Name</label>
            <!-- <input [(ngModel)]="customDocName" type="text" class="form-control"
              style="padding: 0px 5px; width: 300px; font-size: 13px" /> -->
            <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="customDocName"
              (change)="customDocNameChange($event.target.value)">
              <option value="" disabled selected>
                Select Document
              </option>
              <option *ngFor="let item of customDocList" [value]="item.name" [disabled]="item.disabled">
                {{item?.name}}</option>
            </select>
            <div class="mt-2">
              <small style="font-size: 14px;"><b>Please use CTRL to select multiple files .</b></small>
            </div>
          </div>
          <div>
            <input hidden type="file" #uploadFile2 (change)="uploadCustomDocument($event)" multiple />
            <button type="button" class="btn btn-secondary btn-sm mr-2"
              style="padding: 5px 10px; margin-top: 22px; font-size: 13px" (click)="uploadFile2.click()">
              <img class="img12" src="../../../assets/img/upload-w.svg" />
            </button>
          </div>
          <div>
            <button (click)="addCustomDocument(true);" type="button" class="btn btn-secondary btn-sm"
              style="padding: 5px 10px; margin-top: 22px; font-size: 13px">
              Add
            </button>
          </div>
        </div>
        <div>
          <div class="add-docs-item-sub ml-auto">
            <div class="txt-oflow-lg text-left">
              Selected Files: {{ customFileList.length }}
            </div>
          </div>
          <div *ngFor="let i of customFileList" class="d-flex add-docs-item align-items-center">
            <div class="">
              <img class="img12 mr-1 lh160" src="../../../assets/img/docs-r.svg" />
              {{ i.name | titlecase }}<br />
              <div class="add-docs-item-sub d-flex" style="margin-left: 20px">
                <div class="txt-oflow-lg">
                  {{ i.fileName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="customFileList.length == 0" class="d-flex p-3 flex-column">
          <div class="text-center p-3">
            <img src="../../../assets/img/upload-file-2.png" />
          </div>
          <div class="text-center">
            Upload below supported documents only <br />
            <span style="font-size: 18px; line-height: 180%">.doc .pdf .png .jpg .jpeg</span>
          </div>
        </div>
        <div>
          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
        </div>
        <div class="p-3 text-center docUploadError" style="color: green; font-size: 14px; font-weight: bold">
          {{ docUploadError }}
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
  <div id="progressBar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
    googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
  </ngx-doc-viewer>
</ng-template>

<!-- for upload multiple files-->
<div class="modal fade" id="uploadFiles" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 400px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Documents</h5>
        <button type="button" id="closeButton4" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="uploadSuccess == 'none'">
          <div class="d-flex" *ngIf="!docUploadStatus">
            <div>
              <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                Select Files
              </button>
              <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                style="display: none" />
            </div>
            <div class="add-docs-item-sub ml-auto">
              <div class="txt-oflow-lg text-right">
                Selected Files: {{ selectedFiles.length }}
              </div>
            </div>
          </div>

          <!--Addon-->
          <div *ngIf="selectedFiles.length == 0" class="d-flex p-3 flex-column">
            <div class="text-center p-3">
              <img src="../../../assets/img/upload-file-2.png" />
            </div>
            <div class="text-center">
              Upload below supported documents only <br />
              <span style="font-size: 18px; line-height: 180%">.doc .pdf .png .jpg .jpeg</span>
            </div>
          </div>
          <!--End-->

          <div *ngIf="selectedFiles.length > 0">
            <div *ngIf="!docUploadStatus" class="py-2 mb-3" style="height: 160px; overflow: auto">
              <div *ngFor="let i of selectedFiles; let indexOfelement = index"
                class="d-flex add-docs-item align-items-center py-2">
                <div class="">
                  <img class="img12 mr-1 lh160 float-left" src="../../../assets/img/docs-r.svg" />
                  <div class="txt-oflow-lg" style="display: inline-block; line-height: 100%">
                    {{ i.name }}
                  </div>
                  <br />
                </div>
                <div class="ml-auto">
                  <button style="background: none" (click)="deleteFile(indexOfelement)">
                    <img class="img12" src="../../../assets/img/delete.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!docUploadStatus">
            <div class="d-flex">
              <div *ngIf="selectedFiles.length > 0">
                <div *ngIf="!docUploadStatus">
                  <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                    + Add more
                  </button>

                  <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                    style="display: none" />
                </div>
              </div>
              <div class="ml-auto">
                <button *ngIf="selectedFiles.length > 0" #uploadFilesButton type="button"
                  class="btn btn-primary btn-sm mr-2" (click)="uploadAttachments()">
                  Upload
                </button>
                <button (click)="cancelUpload()" type="button" class="btn btn-light btn-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!docUploadStatus">
          <!--Addon-->
          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess == 'success'">
            <div class="text-center p-3">
              <img src="../../../assets/img/upload-success.png" />
            </div>
            <div class="text-center">
              <b>Upload Success</b><br />
              <div style="font-size: 14px; line-height: 130%; padding: 5px">
                Total {{ selectedFiles.length }} files uploaded successfully
              </div>
            </div>
          </div>
          <!--End-->

          <!--Addon-->
          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess == 'failure'">
            <div class="text-center p-3">
              <img src="../../../assets/img/upload-failed.png" />
            </div>
            <div class="text-center">
              <b>Upload Failed</b><br />
              <div style="font-size: 14px; line-height: 130%; padding: 5px">
                Files not uploaded. Please check document extensions, upload
                supported documents
              </div>
            </div>
          </div>
          <!--End-->
        </div>
        <div>
          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
        </div>
        <div class="p-3 text-center docUploadError" *ngIf="uploadSuccess == 'none'"
          style="color: green; font-size: 14px; font-weight: bold">
          {{ docUploadError2 }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" #content id="content" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="contentLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg custom-model-w">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="contentLabel">Calling Screen</h5>

        <button type="button" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto">
        <div class="row">
          <div class="col-md-3">
            <div class="ndialer">
              <!--.......code for no- incoming call screen.........  -->
              <div class="sm-text" style="
                  text-align: center;
                  text-transform: uppercase;
                  font-weight: 100;
                  background: #1f344c;
                  width: 135px;
                  margin: 0 auto;
                  padding: 5px;
                  border-radius: 15px;
                  letter-spacing: 1px;
                ">
                Current Caller
              </div>

              <div class="dialer-middle-screen" style="text-align: center" *ngIf="dialerScreen1 === 'outgoing1'">
                <div class="custom-incomingcall-widget">
                  <h4 class="mt-3 text-ellipsis">
                    {{ marketingId.name }}
                  </h4>
                  <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                    {{ marketingId.city }}
                  </div>
                  <div class="my-1 lg-text">
                    {{ marketingId.phoneNumber }}
                  </div>
                  <div class="custom-user-img my-3 mt-5">
                    <img class="img80" src="./assets/img/user-w.svg" alt="" />
                  </div>
                  <!-- <h5>Calling ...</h5> -->
                  <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                  <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                    <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                  </p>
                  <div class="custom-incoming-btns my-3 mt-4">
                    <button type="button" class="btn-round m-r-10" (click)="stopCalls()">
                      <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <!--.......code for no- incoming call screen.........  -->

              <div *ngIf="dialerScreen1 === 'dialer'">
                <div class="end-call">
                  <span style="font-size: 14px; font-weight: 400">Call Ended</span><br />
                  {{ marketingId.phoneNumber }}
                </div>
              </div>

              <!--.......code for keypaid screen.........  -->

              <div class="dialer-keypad" *ngIf="dialerScreen1 === 'oncall'">
                <div class="custom-incomingcall-widget">
                  <h4 class="mt-3 text-ellipsis">
                    {{ marketingId.name }}
                  </h4>
                  <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                    {{ marketingId.city }}
                  </div>
                  <div class="my-1 lg-text">
                    {{ marketingId.phoneNumber }}
                  </div>
                  <div class="custom-user-img my-3 mt-5">
                    <img class="img80" src="./assets/img/user-w.svg" alt="" />
                  </div>
                  <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                  <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                    <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                  </p>
                  <div class="custom-incoming-btns my-3 mt-4">
                    <button type="button" class="btn-round m-r-10" (click)="stopCalls()">
                      <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <!--.......code for keypaid screen.........  -->
            </div>
            <div class="mt-2 p-3 ndialer2" *ngIf="marketingId.nextCaller">
              <div class="mt-4" style="float: right">
                <img class="img50" src="./assets/img/user-w.svg" alt="" />
              </div>
              <div class="sm-text" style="
                  text-transform: uppercase;
                  font-weight: 100;
                  background: #f75c2b;
                  width: 110px;
                  padding: 5px;
                  border-radius: 15px;
                  letter-spacing: 1px;
                  text-align: center;
                ">
                NEXT CALLER
              </div>
              <div class="lg-text pt-2 pb-1">
                {{ nextCallerName }}
              </div>
              <div class="md-text pb-2">{{ nextCallerCity }}</div>
              <div class="md-text">
                {{ nextCallerNumber }}
              </div>
            </div>

            <div class="mt-2 d-flex justify-content-between">
              <div class="cbtn" type="button" ngbTooltip="Redial" placement="top" tooltipClass="my-custom-class"
                (click)="startCallListener()">
                <img class="img18" src="./assets/img/redial3.svg" alt="" />
              </div>

              <!-- ngbTooltip="Conference" -->
              <button class="cbtn" type="button" placement="bottom-left" [ngbPopover]="calendarContent">
                <img class="img18" src="./assets/img/conference.svg" alt="" />
              </button>
              <ng-template #calendarContent>
                <form #form="ngForm" (ngSubmit)="conferenceCall(form.value)">
                  <div class="xp-3" style="width: 185px">
                    <div class="mb-1">
                      <input id="phone_number" name="phone_number" type="text" class="form-control"
                        placeholder="Enter Phone Number" ngModel />
                    </div>
                    <ng-multiselect-dropdown name="manager" [settings]="layoutService.dropdownSettings"
                      [data]="layoutService.projectAgents" [(ngModel)]="layoutService.projectSelectedAgent"
                      style="width: 100%; padding: 0" class="dropdown-primary"></ng-multiselect-dropdown>
                    <button class="btn btn-primary btn-block btn-md mt-2">
                      Connect
                    </button>
                  </div>
                </form>
              </ng-template>

              <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top" (click)="stopCalls()"
                tooltipClass="my-custom-class">
                <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
              </div>

              <div class="cbtn" type="button" ngbTooltip="Start Callss" placement="top" (click)="startCall1()"
                tooltipClass="my-custom-class">
                <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
              </div>
            </div>
          </div>
          <div class="col-md-9 qt">
            <!--New form started -->
            <mat-tab-group>
              <mat-tab label="Questionaries">
                <form class="pt-2" [formGroup]="this.layoutService.commentsForm" (ngSubmit)="submit()">
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label>Name</label>
                      <input type="text" class="form-control" id="" placeholder="Name" formControlName="name" [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.name.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid': f.name.valid
                        }" />
                      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">
                          name is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Email Id</label>
                      <input type="email" class="form-control" id="" placeholder="Email Id" formControlName="emailId"
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.emailId.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid': f.emailId.valid
                        }" />
                      <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                        <div *ngIf="
                            f.emailId.errors.required &&
                            layoutService.commentsValue.interested !==
                              'notinterested'
                          ">
                          Please Enter a Valid EmailId
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Date of Birth</label>
                      <input type="date" class="form-control" id="" placeholder="" formControlName="dob" [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.dob.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid': f.dob.valid && f.dob.touched
                        }" />
                      <div *ngIf="
                          submitted &&
                          f.dob.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested'
                        " class="invalid-feedback">
                        <div *ngIf="f.dob.errors.required">
                          Date of birth is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label>State/Country</label>
                      <input type="email" class="form-control" id="" placeholder="" formControlName="state" [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.state.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid': f.state.valid && f.state.touched
                        }" />
                      <div *ngIf="
                          submitted &&
                          f.state.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested'
                        " class="invalid-feedback">
                        <div *ngIf="f.state.errors.required">
                          State is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Alternative Contact Number</label>
                      <input type="text" class="form-control" id="" placeholder="" formControlName="altContactNumber"
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.altContactNumber.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid':
                            f.altContactNumber.valid &&
                            f.altContactNumber.touched
                        }" />
                      <div *ngIf="submitted && f.altContactNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.altContactNumber.errors">
                          Alternate ContactNumber is required
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label for="">Country Preference </label>
                      <input type="text" class="form-control" id="" placeholder="" formControlName="countryPreference"
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.countryPreference.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid':
                            f.countryPreference.valid &&
                            f.countryPreference.touched
                        }" />
                      <div *ngIf="submitted && f.countryPreference.errors" class="invalid-feedback">
                        <div *ngIf="f.countryPreference.errors.required">
                          CountryPreference is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="">Existing Counselling</label>
                      <div class="mt-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio1"
                            value="yes" formControlName="existingCounseling" />
                          <label class="form-check-label" for="inlineRadio1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="existingCounseling" id="inlineRadio2"
                            value="no" formControlName="existingCounseling" />
                          <label class="form-check-label" for="inlineRadio2">No</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="">Valid Passport?</label>
                      <div class="mt-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="passport" id="inlineRadio1" value="yes"
                            formControlName="passport" />
                          <label class="form-check-label" for="inlineRadio1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="passport" id="inlineRadio2" value="no"
                            formControlName="passport" />
                          <label class="form-check-label" for="inlineRadio2">No</label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>Applied for Visa?</label>
                      <div class="mt-1">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="visa" id="inlineRadio1" value="yes"
                            formControlName="visa" />
                          <label class="form-check-label" for="inlineRadio1">Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="visa" id="inlineRadio2" value="no"
                            formControlName="visa" />
                          <label class="form-check-label" for="inlineRadio2">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <div style="float: left; width: 35%; margin-right: 10px">
                        <label>IELETS</label>
                        <input type="text" formControlName="IELETS" class="form-control" placeholder="" [ngClass]="{
                            'is-invalid':
                              submitted &&
                              f.IELETS.errors &&
                              layoutService.commentsValue.interested !==
                                'notinterested',
                            'is-valid': f.IELETS.valid && f.IELETS.touched
                          }" />
                        <div *ngIf="
                            submitted &&
                            f.IELETS.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested'
                          " class="invalid-feedback">
                          <div *ngIf="f.IELETS.errors.required">
                            IELETS is required
                          </div>
                        </div>
                      </div>
                      <div class="" style="float: left; width: 35%; margin-right: 10px">
                        <label>TOEFL</label>
                        <input type="text" formControlName="TOEFL" class="form-control" placeholder="" [ngClass]="{
                            'is-invalid':
                              submitted &&
                              f.TOEFL.errors &&
                              layoutService.commentsValue.interested !==
                                'notinterested',
                            'is-valid': f.TOEFL.valid && f.TOEFL.touched
                          }" />
                      </div>
                      <div *ngIf="submitted && f.TOEFL.errors" class="invalid-feedback">
                        <div *ngIf="f.TOEFL.errors.required">
                          TOEFL is required
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <div class="" style="float: left; width: 35%; margin-right: 10px">
                        <label>PTE</label>
                        <input type="text" formControlName="PTE" class="form-control" placeholder="" [ngClass]="{
                            'is-invalid':
                              submitted &&
                              f.PTE.errors &&
                              layoutService.commentsValue.interested !==
                                'notinterested',
                            'is-valid': f.PTE.valid && f.PTE.touched
                          }" />
                        <div *ngIf="submitted && f.PTE.errors" class="invalid-feedback">
                          <div *ngIf="f.PTE.errors.required">
                            PTE is required
                          </div>
                        </div>
                      </div>
                      <div class="" style="float: left; width: 35%; margin-right: 10px">
                        <label>Duolingo</label>
                        <input type="text" formControlName="Dulingo" class="form-control" placeholder="" [ngClass]="{
                            'is-invalid':
                              submitted &&
                              f.Dulingo.errors &&
                              layoutService.commentsValue.interested !==
                                'notinterested',
                            'is-valid': f.Dulingo.valid && f.Dulingo.touched
                          }" />
                        <div *ngIf="submitted && f.Dulingo.errors" class="invalid-feedback">
                          <div *ngIf="f.Dulingo.errors.required">
                            Dulingo is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-4">
                      <label>If not when planning to appear</label>
                      <input type="" class="form-control" id="" placeholder="" formControlName="planning" [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.planning.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid': f.planning.valid && f.planning.touched
                        }" />
                      <div *ngIf="submitted && f.planning.errors" class="invalid-feedback">
                        <div *ngIf="
                            f.planning.errors.required &&
                            layoutService.commentsValue.interested !==
                              'notinterested'
                          ">
                          Planning is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-md-4">
                      <label for="">Current Qualification</label>
                      <input type="text" formControlName="qualification" class="form-control" placeholder="" [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.qualification.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid':
                            f.qualification.valid && f.qualification.touched
                        }" />
                      <div *ngIf="
                          submitted &&
                          f.qualification.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested'
                        " class="invalid-feedback">
                        <div *ngIf="f.qualification.errors.required">
                          Qualification is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="">Work Experience</label>
                      <input type="text" class="form-control" id="" formControlName="workExperience" placeholder=""
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.workExperience.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid':
                            f.workExperience.valid && f.workExperience.touched
                        }" />
                      <div *ngIf="
                          submitted &&
                          f.workExperience.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested'
                        " class="invalid-feedback">
                        <div *ngIf="f.workExperience.errors.required">
                          Work Experience is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Course Interested</label>
                      <input type="text" formControlName="courseInterested" class="form-control" placeholder=""
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.courseInterested.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid':
                            f.courseInterested.valid &&
                            f.courseInterested.touched
                        }" />
                      <div *ngIf="
                          submitted &&
                          f.courseInterested.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested'
                        " class="invalid-feedback">
                        <div *ngIf="f.courseInterested.errors.required">
                          Course Interested is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-4">
                      <label for="description" class="inputEmail4">Assigned To</label>
                      <ng-multiselect-dropdown name="manager" [settings]="layoutService.dropdownSettings"
                        [data]="layoutService.roleAgents" formControlName="assignedTo"
                        [(ngModel)]="layoutService.selectedAgent" style="width: 100%; padding: 0"
                        [ngClass]="{ 'is-invalid': f.assignedTo.errors }">
                      </ng-multiselect-dropdown>
                      <div *ngIf="f.assignedTo.errors" class="invalid-feedback">
                        <div *ngIf="f.assignedTo.errors.required">
                          Assigned To is required
                        </div>
                      </div>
                      <!-- #manager="ngModel" -->
                    </div>
                    <div class="form-group col-md-4">
                      <label for="">Counselling Time and Date</label>
                      <input type="datetime-local" class="form-control" id="" placeholder=""
                        formControlName="counsellingDate" [ngClass]="{
                          'is-invalid': f.counsellingDate.errors
                        }" />
                      <div *ngIf="f.counsellingDate.errors" class="invalid-feedback">
                        <div *ngIf="f.counsellingDate.errors.required">
                          Counselling Date is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="">Call Back</label>
                      <input type="datetime-local" class="form-control" id="" placeholder="" formControlName="callBack"
                        [ngClass]="{
                          'is-invalid': f.callBack.errors
                        }" />
                      <div *ngIf="f.callBack.errors" class="invalid-feedback">
                        <div *ngIf="f.callBack.errors.required">
                          Call Back Date is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Funding Source</label>
                      <input type="text" formControlName="fundingResource" class="form-control" placeholder=""
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.fundingResource.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid':
                            f.fundingResource.valid && f.fundingResource.touched
                        }" />
                      <div *ngIf="
                          submitted &&
                          f.fundingResource.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested'
                        " class="invalid-feedback">
                        <div *ngIf="f.fundingResource.errors.required">
                          Funding Resource is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="">Intake preferred</label>
                      <input class="form-control" type="text" formControlName="intakePreferred" [ngClass]="{
                          'is-invalid':
                            submitted &&
                            f.intakePreferred.errors &&
                            layoutService.commentsValue.interested !==
                              'notinterested',
                          'is-valid':
                            f.intakePreferred.valid && f.intakePreferred.touched
                        }" />
                      <div *ngIf="
                          submitted &&
                          f.intakePreferred.errors &&
                          layoutService.commentsValue.interested !==
                            'notinterested'
                        " class="invalid-feedback">
                        <div *ngIf="f.intakePreferred.errors.required">
                          In Take Proffered is required
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="">Feedback</label>

                      <div></div>
                      <div class="form-check form-check-inline">
                        <select formControlName="interested" class="form-control">
                          <option value="pending">Pending</option>
                          <option value="notinterested">Not Interested</option>
                          <option value="informed">Informed</option>
                          <option value="callfailed">Call Failed</option>
                          <option value="callback">Follow Up</option>
                          <option value="notanswered">Not Answered</option>
                          <option value="deferredintake">
                            Deferred Intake
                          </option>

                          <option value="duplicate">Duplicate</option>
                          <option value="futurelead">Future Lead</option>
                          <option value="interestedpending">
                            Interested Pending
                          </option>
                          <option value="interested">Interested</option>
                          <option value="counsellingpending">
                            Counselling Pending
                          </option>
                          <option value="counsellingcompleted">
                            Counselling Completed
                          </option>
                          <option value="applicationsubmitted">
                            Application Submitted
                          </option>
                          <option value="documentspending">
                            Documents Pending
                          </option>
                          <option value="documentssubmitted">
                            Documents Submitted
                          </option>
                          <option value="paymentpending">
                            Payment Pending
                          </option>
                          <option value="pendingi20">Pending I20</option>
                          <option value="pendingvisa">Pending VISA</option>
                          <option value="visadeclined">VISA Declined</option>
                          <option value="visaapproved">VISA Approved</option>
                          <option value="pendingtravel">Pending Travel</option>
                          <option value="travelcomplete">
                            Travel Complete
                          </option>
                          <option value="enrolled">Enrolled</option>
                          <option value="prospect">Prospect</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="" class="form-label mt-2">Comments</label>
                      <textarea class="form-control" rows="5" formControlName="comments"></textarea>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <div class="mt-3">
                      <button class="btn btn-light btn-sm mr-2" type="button" (click)="layoutService.onReset()">
                        Reset
                      </button>
                      <button class="btn btn-primary btn-sm" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Calling screen from Documents Validation-->
<div class="modal fade" id="contentDocs" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content" style="width: 280px; background: none; border: 0">
      <div class="modal-header" style="
          border-bottom: 0;
          display: inline;
          position: absolute;
          right: 5px;
          top: 2px;
          z-index: 999;
        ">
        <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close"
          style="margin-right: 0">
          <span aria-hidden="true"><img class="img14" style="width: 10px" src="./assets/img/close.svg" alt="" />
          </span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0">
        <div class="d-flex">
          <div class="ndialer" style="width: 275px">
            <!--.......code for no- incoming call screen.........  -->

            <div class="dialer-middle-screen" style="text-align: center">
              <div class="custom-incomingcall-widget">
                <h4 class="mt-3 text-ellipsis">
                  {{ sideViewerData.FirstName + " " + sideViewerData.LastName }}
                </h4>
                <div class="my-1 lg-text">
                  {{
                  "+" +
                  sideViewerData.CountryCode +
                  " " +
                  sideViewerData.PhoneNumber
                  }}
                </div>
                <div class="custom-user-img my-3">
                  <img class="img80" src="./assets/img/user-w.svg" alt="" />
                </div>

                <h5>
                  {{ callStatus }}
                </h5>
                <!-- <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                  <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                </p> -->

                <div class="custom-incoming-btns my-3 mt-4">
                  <button type="button" class="btn-round m-r-10" (click)="stopCalls()">
                    <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                  </button>
                </div>

                <button type="button" class="btn btn-link btn-sm ml-2 float-left" placement="right"
                  #popover="ngbPopover" [ngbPopover]="comments22">
                  <img src="./assets/img/comments.svg" style="width: 16px" />
                </button>

                <ng-template #comments22>
                  <form #form="ngForm">
                    <div class="d-flex align-items-end" style="width: 180px">
                      <div class="flex-grow-1">
                        <textarea class="form-control ct" type="text" #callingComment id="callingComments" rows="2"
                          placeholder="Write comments here..."></textarea>
                      </div>
                      <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                        (click)="postComments(currentItem, 'callingComments')">
                        <img src="./assets/img/send-message.svg" style="width: 16px" />
                      </button>
                    </div>
                  </form>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template id="sendUniversityDoc" class="modal-x" #sendUniversityDoc let-modal>
  <div class="modal-header nm">
    <h4 class="modal-title" id="modal-basic-title">Send Mail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="px-2">
      <div class="py-2 header-md" style="color: #163252;text-transform: unset;">
        All the documents has been Validated for this lead.<br>Do you want to send a mail regarding university
        application.
      </div>
      <button type="button" class="btn btn-primary btn-sm mr-4"
        (click)="sendUniMailConfirm();modal.dismiss('Cross click')">
        Confirm
      </button>
      <button type="button" class="btn btn-primary btn-sm" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </div>
</ng-template>

<!-- Ref count starts-->
<div class="modal fade modal-md" id="unicount" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 847px;margin-left: -131px;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Universities</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--<div class="header-md">Total 10 members added as referral</div>-->
        <!-- <input type="text" class="form-control mb-2" placeholder="Search by name" [(ngModel)]="refereeSearch"
          (input)="onRefereeSearch(referredList?.length)" /> -->
        <div class="dat-table" style="height: 250px; overflow: auto">
          <table class="table table-md">
            <tr>
              <th>University Name</th>
              <th style="width: 21%;">Enrollment Term</th>
              <th style="width: 21%;">Program</th>
            </tr>
            <tr *ngFor="let i of uniList">
              <td class="vm" nowrap style="width: 100px">
                {{i.name||'-'}}
              </td>
              <td class="vm" nowrap style="width: 100px">
                {{i.term ||'-'}}
              </td>
              <td class="vm" nowrap style="width: 100px">
                {{i.program || '-'}}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Ref count ends-->

<!-- Not Assigned -->
<ng-template #leadNotAssigned let-modal class="modal-x">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Assign Lead</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div style="font-size: 14px;">The lead is not assigned.</div>
      <br>
      <div style="font-size: 14px;">Please assign the lead first.</div>
    </div>
    <div class="py-4">
      <h3 class="modal-title" id="exampleModalLabel"
        style="color: #000;font-size: 14px;text-transform: unset !important;">Assign to Agent</h3>
      <div class="d-flex mt-2 mb-3">
        <div class="form-group" style="margin-bottom: 0; width: 220px">
          <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
            (change)="agentSelected($event.target.value)">
            <option value="default" disabled selected>Select Agent</option>
            <option *ngFor="let agent of agentList" [value]="agent.Mailid">
              {{ agent.FirstName + " " + agent.LastName }}
            </option>
          </select>
        </div>
        <button type="button" class="btn btn-secondary btn-sm ml-3" (click)="assignLEads()">
          Assign
        </button>
      </div>
    </div>
  </div>
</ng-template>