<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()"><span aria-hidden="true">&times;</span></div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Call Details">
      <div *ngIf="!sidenavLoading">
        <div class="alert alert-info mb-0">
          {{ sideViewerData?.CallDetails?.length }} Calls
        </div>
        <div *ngFor="let i of sideViewerData?.CallDetails" class="mx-2 my-2">
          <table class="table table-borderless table-md caller-table my-1">
            <tr>
              <td>Called by</td>
              <td>{{ i?.AgentName || '' }}</td>
            </tr>
            <tr>
              <td>Date & Time</td>
              <td>{{ i?.DateTime | date: 'dd MMM yyyy' }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <span *ngIf="i?.CallStatus == 'pending'">Pending</span>
                <span *ngIf="i?.CallStatus == 'uncontactable'">Uncontactable</span>
                <span *ngIf="i?.CallStatus == 'notinterested'">Not Interested</span>
                <span *ngIf="i?.CallStatus == 'completed'">Completed</span>
                <span *ngIf="i?.CallStatus == 'callback'">Call Back</span>
                <span *ngIf="i?.CallStatus == 'informed'">Informed</span>
                <span *ngIf="i?.CallStatus == 'called'">Called</span>
                <span *ngIf="i?.CallStatus == 'callfailed'">Wrong Number</span>
                <span *ngIf="i?.CallStatus == 'deferredintake'">Deferred Intake</span>
                <span *ngIf="
                                      i?.CallStatus == 'callnotanswered' ||
                                      i?.CallStatus == 'notanswered'
                                    ">Not Answered</span>
                <span *ngIf="
                                      i?.CallStatus == 'deferred' ||
                                      i?.CallStatus == 'Deferred'
                                    ">Deferred Intake</span>
              </td>
            </tr>
            <tr>
              <td style="padding-top: 20px">Call Recording</td>
              <td><audio *ngIf="i?.RecordingURL != null" style="height: 2.5rem; width: 15srem" [src]="i?.RecordingURL"
                  controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
              </td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>{{ i?.Comments }}</td>
            </tr>
          </table>
          <hr class="hr-style" />
        </div>
      </div>
      <div *ngIf="sidenavLoading">
        <div class="alert alert-info mb-0">
          0 Calls
        </div>
        <div class="mx-2 my-2">
          <table class="table table-borderless table-md caller-table my-1">
            <tr>
              <td>Called by</td>
              <td>
                <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
              </td>
            </tr>
            <tr>
              <td>Date & Time</td>
              <td>
                <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
              </td>
            </tr>
            <tr>
              <td style="padding-top: 20px">Call Recording</td>
              <td>
                <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
              </td>
            </tr>
            <tr>
              <td>Comments</td>
              <td>
                <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
              </td>
            </tr>
          </table>
          <hr class="hr-style" />
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Student Details">
      <div class="mx-2 my-2" *ngIf="!sidenavLoading">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Id</td>
            <td>{{ sideViewerData?.salesforceId || '-' }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              {{
                sideViewerData?.firstName || '-' | titlecase }} {{sideViewerData?.lastName || '-'
                | titlecase
                }}
            </td>
          </tr>
          <tr>
            <td>DOB</td>
            <td>{{ sideViewerData?.dateOfBirth || '-' | date: 'dd MMM yyyy'}}</td>
          </tr>
          <tr>
            <td>Submitted Date</td>
            <td>{{ sideViewerData?.createdAt || '-' | date: 'dd MMM yyyy'}}</td>
          </tr>
          <tr>
            <td>Document Status</td>
            <td>{{ sideViewerData?.LeadDocStatus || '-' | titlecase }}</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              {{sideViewerData?.phoneCountryCode?'+'+sideViewerData?.phoneCountryCode : '-'}}
              {{sideViewerData?.phoneWithoutCode
              ||
              '-'}}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData?.emailId || '-' }}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {{
              sideViewerData?.address +
              " " +
              sideViewerData?.cityOrTown +
              " " +
              sideViewerData?.state +
              " " +
              sideViewerData?.zip +
              " " +
              sideViewerData?.country || "NA" | titlecase
              }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mx-2 my-2" *ngIf="sidenavLoading">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Id</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>DOB</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>Submitted Date</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>Document Status</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              <ngx-skeleton-loader [theme]="{height: '20px',width: '200px'}"></ngx-skeleton-loader>
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Document Validation">
      <div class="mx-2 my-2">
        <div class="mx-2 my-2" *ngIf="!sidenavLoading">
          <div class="border-bottom" *ngFor="let item of sideViewerData?.uploadlistofdocuments">
            <div class="d-flex mb-1">
              <div class="py-2 d-flex flex-column">
                <img *ngIf="item?.uploadedDate != null" src="./assets/img/docs-r.svg" style="width: 16px" />
                <img *ngIf="item?.uploadedDate == null" src="./assets/img/doc-grey-2.svg" style="width: 16px" />
              </div>
              <div class="p-2 d-flex flex-column w200">
                <div class="sub-header-md mr-3 d-flex align-items-center">
                  <div style="line-height: 160%;">{{ item?.courseName |titlecase }}</div>
                </div>

                <div class="sub-date" *ngIf="item?.uploadedDate != null">
                  {{ item?.uploadedDate | date: "medium" }}
                </div>
              </div>
              <div>
                <div>
                  <span class="badge badge-pill badge-success mt-3"
                    *ngIf="item?.AgentValidation == 'validated'">Validated</span>
                  <span class="badge badge-pill badge-warning mt-3" *ngIf="item?.AgentValidation == 'revalidate'">Re
                    Validate</span>
                </div>

                <ng-template #validation11>
                  <form #form="ngForm">
                    <div style="width: 185px; font-size: 12px">
                      <div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="validated" (change)="
                                              changeValidation(sideViewerData.id,
                                                $event.target.value,
                                                item.StudentdocId
                                              )
                                            " />
                          <label class="form-check-label" for="inlineRadio1">Validated</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="revalidate" (change)="
                                              changeValidation(sideViewerData.id,
                                                $event.target.value,
                                                item.StudentdocId
                                              )
                                            " />
                          <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </ng-template>

                <ng-template #comments11>
                  <form #form="ngForm">
                    <div class="d-flex align-items-end" style="width: 250px">
                      <div class="flex-grow-1">
                        <textarea class="form-control ct" type="text" #documentComment id="documentComments" rows="2"
                          placeholder="Write comments here..."></textarea>
                      </div>
                      <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                        (click)="postComments(sideViewerData,item, 'documentComments')">
                        <img src="./assets/img/send-message.svg" style="width: 16px" />
                      </button>
                    </div>
                  </form>
                </ng-template>
              </div>
              <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

              <button [disabled]="sideViewerData.LeadDocStatus== 'completed'" data-toggle="modal"
                data-target="#uploadFiles" placement="right" tooltipClass="my-custom-class"
                class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)">
                <img src="./assets/img/file-upload.svg" style="width: 16px" />

              </button>
              <button class="btn btn-light btn-sm mr-2"
                (click)="downLoadDocument(item.StudentDocPath, item?.courseName)"
                [disabled]="item.StudentDocPath == null">
                <img src="../../../assets/img/file-download.svg" style="width: 16px" />
              </button>
              <button type="button" class="btn btn-light btn-sm mr-2"
                (click)="openPreview(pdfContent, item.StudentDocPath)" [disabled]="item.StudentDocPath == null">
                <img src="./assets/img/view2.svg" style="width: 16px" />
              </button>
              <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="validation11"
                [disabled]="item.StudentDocPath == null">
                <img *ngIf="item.AgentValidation == null" src="./assets/img/tick-g.svg" style="width: 16px" />
                <img *ngIf="item.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                  style="width: 16px" />
                <img *ngIf="item.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                  style="width: 16px" />
              </button>
              <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="comments11"
                [disabled]="item.StudentDocPath == null" ngbTooltip="Add Comment">
                <img src="./assets/img/comments.svg" style="width: 16px" />
              </button>
              <button type="button" data-toggle="modal" data-target="#exampleModal2" class="btn btn-light btn-sm mr-2"
                (click)="callFromDocs(sideViewerData, item)" [ngClass]="{
                  'disabled_custom': 
                  sideViewerData?.phoneCountryCode=='-' && sideViewerData?.phoneWithoutCode=='-'}">
                <img src="./assets/img/calls-g.svg" style="width: 16px" />
              </button>
            </div>

            <!--Comment section comes here-->
            <mat-expansion-panel *ngIf="item.DocComments.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title> Comments </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="comments-section" *ngFor="let i of item.DocComments">
                <div class="admin-comment" *ngIf="i.Admin == true">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" src="{{ i.ProfilePhoto }}" style="width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px;" />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3 flex-fill">
                          {{ i.AgentName }}
                        </div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i.DateTime | date: 'dd MMM yyyy' }}
                        </div>
                      </div>
                      <div class="sub-text flex-grow-1">{{ i.Text }}</div>
                    </div>
                  </div>
                </div>

                <div class="agent-comment" *ngIf="i.Admin == false">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" style="width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100px;" src="{{ i.ProfilePhoto }}" />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3">{{ i.AgentName }}</div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i.DateTime | date: "dd MMM yyyy" }}
                        </div>
                      </div>
                      <div class="sub-text">{{ i.Text }}</div>
                    </div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
        <div class="mx-2 my-2" *ngIf="sidenavLoading">
          <div class="border-bottom" *ngFor="let item of [1,2,3,4,5]">
            <div class="d-flex mb-1">
              <ngx-skeleton-loader [theme]="{height: '30px',width: '350px'}"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="validationStatus == true" class="text-center mb-2">
        <button class="btn btn-success btn-sm mt-2" disabled style="width: 180px">
          <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
          Validation Completed
        </button>
      </div>
      <div *ngIf="validationStatus == false" class="text-center mb-2">
        <button class="btn btn-danger btn-sm mt-2" disabled style="width: 180px">
          <img src="../assets/img/close-r.svg" style="width: 16px" /> Not
          Validated
        </button>
      </div>

      <div class="p-3">
        <div class="header-md">
          Comments
          <span style="font-size: 12px">{{
            "(" + (sideViewerData?.LeadComments?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="mb-3 pb-1 ct pr" style="background: #f5f5f5; border-radius: 5px">
            <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="leadComments" rows="2"
              placeholder="Write comments here..."></textarea>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
              (click)="postComments(sideViewerData,'', 'leadComments')">
              Submit
            </button>
            <div style="clear: both"></div>
          </div>
          <div class="m-h200">
            <div class="d-flex mb-2 mt-1" *ngFor="let j of sideViewerData?.LeadComments">
              <div class="d-flex border-bottom pt-1" style="width: 100%">
                <div class="d-flex" style="width:100%">
                  <div class="">
                    <div *ngIf="j.AgentName" class="pic mr-1 float-left text-center">
                      <img *ngIf="j.ProfilePhoto != null" style="width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: 100px;" src="{{ j.ProfilePhoto }}" />

                      <p [ngStyle]="{ background: j.AgentColor }" class="initials" *ngIf="j.ProfilePhoto == null">
                        {{ j.AgentInitials }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="flex-grow-1">
                      <div class="sub-header-sm mr-3 mt-1 pb-1 flex-fill">
                        {{ j.AgentName }}
                      </div>
                    </div>
                    <div class="sub-text flex-grow-1 pb-2">
                      {{ j.Text }}
                    </div>
                  </div>
                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                    {{ j.DateTime | date: 'dd MMM yyyy' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height:200%">
            {{ activitiesList?.length }} Actions taken
          </div>
          <div class="ml-auto" style="width:180px;">
            <input class="searchActivity" type="text" style="font-size:13px;" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(activitiesList?.length)" />
          </div>
        </div>
        <div *ngFor="let i of activitiesList | slice: 0:minItems | transferSearch: activityfilterString">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i?.Description }}
              <span class="sm-text ml-2">{{
                i?.DateTime | date: 'dd MMM yyyy'
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore hand" *ngIf="minItems < activitiesList?.length" (click)="showmore(activitiesList?.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>

<div class="bg-g">
  <div class="container-fluid mt-3">
    <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
      <div class="db-sm-header" style="line-height: 330%">Activity</div>
      <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
        <button class="form-group" style="padding: 0">
          <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
            (change)="updateDateFilter($event)">
            <option value="all">All</option>
            <option value="24hrs">24 hrs</option>
            <option value="72hrs">72 hrs</option>
            <option value="7days">07 days</option>
            <option value="14days">14 days</option>
            <option value="30days">30 days</option>
            <option value="custom">Custom</option>
          </select>
        </button>
      </div>

      <div *ngIf="showDate" class="datePicker">
        <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
          <mat-form-field class="mr-3">
            <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
              (dateInput)="getStartDate('input', $event)" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="mr-3">
            <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
              (dateInput)="getEndDate('input', $event)" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
          </mat-form-field>
          <button class="btn btn-secondary btn-sm" (click)="filterData()">
            Submit
          </button>
        </div>
      </div>
    </div>


    <!-- Carousel  -->

    <carousel [cellsToScroll]="5" style="padding-left: 30px; z-index: unset;" class="fixheight">

      <div class="carousel-cell">
        <div class="mr-3" #totalRecords (click)="getCardData('all')">
          <div class="db-block w-175" [ngClass]="{ 'db-block-active': tab === 'tab1' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.total}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Total Students</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="carousel-cell">
        <div class="mr-3" (click)="getCardData('completedDocs')">
          <div class="db-block w-175" (click)="onClick(13)" [ngClass]="{ 'db-block-active': tab === 'tab13' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.completedDocs || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Completed Documents</div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="carousel-cell">
        <div class="mr-3" (click)="getCardData('validatedDocs')">
          <div class="db-block w-175" (click)="onClick(2)" [ngClass]="{ 'db-block-active': tab === 'tab2' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.validatedDocs || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Validated Documents</div>
            </div>
          </div>
        </div>
      </div>


      <div class="carousel-cell">
        <div class="mr-3" (click)="getCardData('documentsSubmitted')">
          <div class="db-block w-175" (click)="onClick(5)" [ngClass]="{ 'db-block-active': tab === 'tab5' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/documents_submitting.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.documentsSubmitted || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Documents Submitted</div>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-cell">
        <div class="mr-3" (click)="getCardData('pendingDocs')">
          <div class="db-block w-175" (click)="onClick(4)" [ngClass]="{ 'db-block-active': tab === 'tab4' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/documents_pending.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.pendingDocs || 0}}
                </div>

              </div>
              <div class="box-sub-text1 mt-2">Documents Pending</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="carousel-cell">

        <div class="mr-3" (click)="getCardData('studentDenied')">
          <div class="db-block w-175" (click)="onClick(7)" [ngClass]="{ 'db-block-active': tab === 'tab7' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">

                  <img src="../../../../assets/img/applications-denied.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.studentDenied || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Students Denied</div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="carousel-cell">

        <div class="mr-3" (click)="getCardData('revalidatedDocs')">
          <div class="db-block w-175" (click)="onClick(9)" [ngClass]="{ 'db-block-active': tab === 'tab9' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">

                  <img src="../../../../assets/img/revalidate-docs.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.revalidatedDocs || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Revalidate Documents</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="carousel-cell">

        <div class="mr-3" (click)="getCardData('uncontactable')">
          <div class="db-block w-175" (click)="onClick(10)" [ngClass]="{ 'db-block-active': tab === 'tab10' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">

                  <img src="../../../../assets/img/uncontactable-3.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.uncontactable || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Uncontactable</div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="carousel-cell">

        <div class="mr-3" (click)="getCardData('unassigned')">
          <div class="db-block w-175" (click)="onClick(11)" [ngClass]="{ 'db-block-active': tab === 'tab11' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/total_applications.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.unassigned || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Unassigned</div>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-cell">

        <div class="mr-3" (click)="getCardData('assigned')">
          <div class="db-block w-175" (click)="onClick(12)" [ngClass]="{ 'db-block-active': tab === 'tab12' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/total_applications.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.assigned || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Assigned</div>
            </div>
          </div>
        </div>
      </div>


      <div class="carousel-cell">
        <div class="mr-3" (click)="getCardData('assignedtoMe')">
          <div class="db-block w-175" (click)="onClick(13)" [ngClass]="{ 'db-block-active': tab === 'tab13' }">
            <div class="p-3">
              <div class="d-flex">
                <div class="img-circle d-flex">
                  <img src="../../../../assets/img/total_applications.svg" style="width: 26px; margin-left: 3px" />
                </div>
                <div class="f25 text-right ml-auto mt-3">
                  {{topBarCounts?.assignedtoMe || 0}}
                </div>
              </div>
              <div class="box-sub-text1 mt-2">Assigned to me</div>
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-cell"></div>

    </carousel>

    <!-- End Carousel -->

    <!-- Activity -->

    <!-- End Carousel -->

    <!-- Activity -->
    <div>
      <div>
        <div>
          <div class="d-flex align-items-end mt-3">
            <div class="ml-3 header-12">
              Total Applications
            </div>
            <div class="ml-auto mr-3">
              <div class="content-containers" style="margin-left: 30px">
                <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />
                <div class="d-flex justify-content-end mt-3">
                  <!-- for assign model-->
                  <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Assign to Agent
                          </h5>
                          <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body" style="height: none;">
                          <div class="px-2">
                            <div class="py-2 header-lg">
                              <b>{{ leadIds.length }}</b> Students are selected to
                              assign.
                            </div>
                            <div class="d-flex mt-2 mb-3">
                              <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
                                <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                                  (change)="agentSelected($event.target.value)">
                                  <option value="default" disabled selected>
                                    Select Agent
                                  </option>
                                  <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                                    {{ agent.FirstName + " " + agent.LastName }}
                                  </option>
                                </select>
                              </div>
                              <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
                                Assign
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- attachment bar hidden ends -->
                  <button data-toggle="modal" data-target="#exampleModal3" *ngIf="admin" (click)="assignAgentModal()"
                    class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Assign" placement="top"
                    tooltipClass="my-custom-class">
                    <img src="../../../../assets/img/assign-w.svg" class="img13" />
                  </button>

                  <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Download Sample" placement="top"
                    tooltipClass="my-custom-class">
                    <a href="{{ downloadLink }}" download>
                      <img src="../../../../assets/img/download-w.svg" class="img13" /></a>
                  </button>
                  <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Upload File" placement="top"
                    (click)="uploadFile.click()" tooltipClass="my-custom-class">
                    <img src="../../../../assets/img/upload-w.svg" class="img13" />
                  </button>
                  <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Add New" placement="top"
                    (click)="openCreateLead(content3)" tooltipClass="my-custom-class">
                    <img src="../../../../assets/img/add-w.svg" class="img13" />
                  </button>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-1 ml-3 mr-3">
              <div class="row">
                <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
                  <div class="table-responsive data-table mb-3">
                    <table class="table table-md wbg table-shadow" style="margin-bottom: 0px; width: 100%">
                      <thead>
                        <tr>
                          <td colspan="2">
                            <div class="indived-search" style="position: relative">

                              <input type="search" (input)="onInput($event)" #inputString
                                class="form-control search-all" style="height: auto; padding: 4px 12px"
                                [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                            </div>
                            <!-- <div class="input-group">
                              <input type="text"
                                style="background-color: #163252 !important;border: 1px solid #f6f6f6 !important;color: #ffffff !important;"
                                class="form-control" placeholder="Search..." aria-label="Search..."
                                aria-describedby="basic-addon2" (input)="onInput($event)" [(ngModel)]="search">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary" style="border: 1px solid #f6f6f6 !important;"
                                  type="button" (click)="transferSearch();page = 1">
                                  <i class="fa fa-search" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div> -->
                          </td>
                          <td colspan="10" class="vm">
                            <div class="pagenation-right d-flex justify-content-end align-items-center">
                              <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
                                (ngModelChange)="dropdownSelect($event)">
                                <option value="all">All</option>
                                <option value="pending">Pending</option>
                                <option value="called">Called</option>
                                <option value="callback">Call Back</option>
                                <option value="informed">Informed</option>
                                <option value="callfailed">Wrong Number</option>
                                <option value="notanswered">Not Answered</option>
                                <option value="deferredintake">Deferred Intake</option>
                                <option value="uncontactable">Un Contactable</option>
                              </select>
                              <div class="mx-2 float-right" style="color: #fff; font-size: 12px">
                                <app-pagination-custom></app-pagination-custom>
                                <!-- <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [maxSize]="5"
                                  [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination> -->
                              </div>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tr>
                        <th nowrap>
                          <div class="d-flex">
                            <div style="width: 20px" *ngIf="admin">
                              <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                                (change)="selectAllforAssignment()" />
                            </div>
                            <div>Created Date</div>
                            <span><button class="sortButton" (click)="sortTableData('createdAt','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                          </div>
                        </th>
                        <th nowrap>
                          Updated Date
                          <span><button class="sortButton" (click)="sortTableData('updatedAt','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th nowrap>
                          Student Name
                          <span><button class="sortButton" (click)="sortTableData('firstName','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th nowrap>
                          Student Email
                          <span><button class="sortButton" (click)="sortTableData('emailId','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th style="width: 180px" nowrap>
                          Assigned To
                          <span><button class="sortButton" (click)="sortTableData('AssignedtoName','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th nowrap style="width: 180px">
                          Phone Number
                          <span><button class="sortButton" (click)="sortTableData('phoneCountryCode','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th nowrap style="width: 180px">
                          Applying As
                          <span><button class="sortButton" (click)="sortTableData('ApplyingAs','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th nowrap style="width: 180px">
                          Applying For
                          <span><button class="sortButton" (click)="sortTableData('ApplyingFor','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th nowrap>
                          Document Status
                          <span><button class="sortButton" (click)="sortTableData('LeadDocStatus','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th nowrap>
                          Call Status
                          <span><button class="sortButton" (click)="sortTableData('CallStatus','asc')"><img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </th>
                        <th>Actions</th>
                      </tr>

                      <tbody>
                        <tr *ngFor="
                    let item of studentsList;
                    let id = index
                  ">
                          <td class="vm" nowrap>
                            <div class="d-flex">
                              <div style="width: 20px" *ngIf="admin">
                                <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead"
                                  id="selectLead" (change)="selectAgentforAssignment($event, item)" />
                              </div>
                              <div style="line-height: 125%">
                                <span>{{item?.createdAt | date: 'dd MMM yyyy'}}</span>
                              </div>
                            </div>
                          </td>
                          <td class="vm">
                            <div class="txt-oflow">{{ item.updatedAt | date: 'dd MMM yyyy' }}</div>
                          </td>
                          <td class="vm" nowrap>
                            <div class="txt-oflow">
                              <button class="btn btn-link btn-sm" (click)="openEditLead(editLead2, item)">
                                {{ item.firstName + " " + item.lastName | titlecase }}
                              </button>

                            </div>

                          </td>
                          <td class="vm" nowrap>
                            <div class="txt-oflow">{{ item.emailId | lowercase }}</div>
                          </td>
                          <td class="vm" style="width: 200px" nowrap>
                            <div class="d-flex">
                              <div *ngIf="item.AssignedtoName" class="pic mr-1 float-left text-center">
                                <img *ngIf="item.AssignedtoImageURL != null" class="img20"
                                  src="{{ item.AssignedtoImageURL }}" />


                                <p [ngStyle]="{ background: item.AssignedtoColor }" class="initials"
                                  *ngIf="item.AssignedtoImageURL == null">
                                  {{ item.AssignedtoInitial }}
                                </p>


                              </div>
                              <div class="txt-oflow2 float-left">
                                {{ item.AssignedtoName | titlecase }}
                              </div>
                            </div>
                          </td>
                          <td class="text-capitalize vm" style="width: 180px" nowrap>
                            <span>{{item?.phoneCountryCode?'+'+item?.phoneCountryCode : '-'}} {{item?.phoneWithoutCode
                              ||
                              '-'}}</span>
                          </td>
                          <td class="text-capitalize vm" style="width: 180px" nowrap>
                            <div class="text-center" [ngbTooltip]="item.ApplyingAs" *ngIf="item.ApplyingAs">
                              {{getFirstLetter(item.ApplyingAs)}}
                            </div>
                            <div class="text-center" [ngbTooltip]="item.otherApplyingAs" *ngIf="item.otherApplyingAs">
                              {{getFirstLetter(item.otherApplyingAs)}}
                            </div>
                          </td>
                          <td class="text-capitalize vm" style="width: 180px" nowrap>
                            <div class="text-center" [ngbTooltip]="item.ApplyingFor" *ngIf="item.ApplyingFor">
                              {{getFirstLetter(item.ApplyingFor)}}
                            </div>
                            <div class="text-center" [ngbTooltip]="item.otherApplyingFor" *ngIf="item.otherApplyingFor">
                              {{getFirstLetter(item.otherApplyingFor)}}
                            </div>

                          </td>
                          <td class="text-capitalize vm">
                            <span *ngIf="item?.LeadDocStatus == 'notsubmitted' ">
                              Not Submitted
                            </span>
                            <span *ngIf="item?.LeadDocStatus != 'notsubmitted' ">
                              {{item?.LeadDocStatus | titlecase}}
                            </span>
                          </td>
                          <td class="text-capitalize vm">
                            <span class="status-pending" *ngIf="item.CallStatus == 'pending'">Pending</span>
                            <span class="status-pending" *ngIf="item.CallStatus == 'notinterested'">Not
                              Interested</span>
                            <span class="status-completed" *ngIf="item.CallStatus == 'completed'">Completed</span>
                            <span class="status-callback" *ngIf="item.CallStatus == 'callback'">Call Back</span>
                            <span class="status-informed" *ngIf="item.CallStatus == 'informed'">Informed</span>
                            <span class="status-informed" *ngIf="item.CallStatus == 'called'">Called</span>
                            <span class="status-failed" *ngIf="item.CallStatus == 'callfailed'">Wrong Number</span>
                            <span class="status-deferred" *ngIf="item.CallStatus == 'deferredintake'">Deferred
                              Intake</span>
                            <span class="status-pending" *ngIf="
                        item.CallStatus == 'callnotanswered' ||
                        item.CallStatus == 'notanswered'
                      ">Not Answered</span>
                            <span class="status-deferred" *ngIf="
                        item.CallStatus == 'deferred' ||
                        item.CallStatus == 'Deferred'
                      ">Deferred Intake</span>
                            <span class="status-uncontactable" *ngIf="
                        item.CallStatus == 'uncontactable' ||
                        item.CallStatus == 'uncontactable'
                      ">Un Contactable</span>
                          </td>
                          <td class="text-capitalize vm" nowrap style="padding: 2px">
                            <div class="example-button-row mat-bset d-flex align-items-center">
                              <mat-sidenav-container>
                                <mat-sidenav-content>
                                  <button class="viewLeadButton" (click)="sideNav.toggle(); sideNavData(item.id)"
                                    mat-button>
                                    <img class="img12" src="../../../../assets/img/view2.svg" />
                                  </button>
                                </mat-sidenav-content>
                              </mat-sidenav-container>
                              <div ngbTooltip="Call" placement="top" tooltipClass="my-custom-class" class="mr-2"
                                style="display:inline;cursor:pointer" data-toggle="modal" data-target="#callingscreen1"
                                (click)="startCallListener2(item, id)" 
                                [ngClass]="{
                                  'disabled_custom': 
                                  !item?.phoneCountryCode && !item?.phoneWithoutCode}">
                                <img src="../../../../assets/img/call.svg" class="img13" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="leadsLoading ==true">
                          <td colspan="10">
                            <div class="norecords">
                              <img src="../../../../assets/img/waiting.gif" />

                              <div>Loading Data<br />Please wait while we fetch the data for you</div>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="noLeadsFound == true">
                          <td colspan="10">
                            <div class="norecords">
                              <img src="../../../../assets/img/nodata.svg" />
                              <div>No records found.<br />Please select a different date range.</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>



                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- for Modal -->
          <!--Calling screen starts-->
          <div class="modal fade" id="callingscreen1" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg custom-model-w">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">Calling Screen</h5>

                  <button type="button" id="closeButton2" #closeButton2 class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" style="overflow-y: auto">
                  <div class="row">
                    <div class="call-module ml-3">
                      <div class="ndialer">
                        <!--.......code for no- incoming call screen.........  -->
                        <div class="c-caller">Current Caller</div>
                        <div class="dialer-middle-screen" style="text-align: center">
                          <div class="custom-incomingcall-widget">
                            <h4 class="mt-3 text-ellipsis">
                              {{ marketingId.name }}
                            </h4>
                            <div class="mb-2 mt-1 lg-text" style="font-weight: 100">
                            </div>
                            <div class="my-1 lg-text">
                              {{ marketingId.phoneNumber || agentCall }}
                            </div>
                            <div class="custom-user-img my-3 mt-5">
                              <img class="img80" src="./assets/img/user-w.svg" alt="" />
                            </div>
                            <h5 *ngIf="callStatus != 'inProgress'">{{ callStatus }}</h5>
                            <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                              <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                            </p>
                            <p class="custom-call-timer" style="text-align: center">

                            </p>

                            <div class="custom-incoming-btns my-3 mt-4" (click)="stopCurrentCall()">
                              <button type="button" class="btn-round m-r-10">
                                <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="mt-2 p-3 ndialer2 ng-star-inserted">
                        <div class="mt-4" style="float: right">
                          <img class="img50" src="./assets/img/user-w.svg" alt="" />
                        </div>
                        <div class="sm-text" style="
              text-transform: uppercase;
              font-weight: 100;
              background: #f75c2b;
              width: 110px;
              padding: 5px;
              border-radius: 15px;
              letter-spacing: 1px;
              text-align: center;
              color: #fff;
            ">
                          NEXT CALLER
                        </div>
                        <div class="lg-text pt-2 pb-1">{{ nextCallerName }}</div>
                        <div class="md-text">{{ nextCallerNumber }}</div>
                      </div>

                      <div class="mt-2 d-flex justify-content-between">
                        <!-- <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class">
                          <img class="img18" src="./assets/img/redial3.svg" alt="" />
                        </div> -->
                        <!-- <button class="cbtn" type="button" placement="right" [ngbPopover]="calendarContent">
                          <img class="img18" src="./assets/img/conference.svg" alt="" />
                        </button>
                        <ng-template #calendarContent>
                          <form #form="ngForm">
                            <div class="xp-3" style="width: 185px">
                              <div class="mb-1">
                                <input id="phone_number" name="phone_number" type="text" class="form-control"
                                  placeholder="Enter Phone Number" ngModel />
                              </div>
                              <select class="form-control" id="exampleFormControlSelect1">
                                <option value="default" disabled selected>
                                  Select
                                </option>
                                <option>Manager 1</option>
                                <option>Manager 2</option>
                                <option>Manager 3</option>
                                <option>Manager 4</option>
                              </select>

                              <button class="btn btn-primary btn-block btn-md mt-2">
                                Connect
                              </button>
                            </div>
                          </form>
                        </ng-template> -->

                        <!-- <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top"
                          tooltipClass="my-custom-class">
                          <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
                        </div> -->

                        <!-- <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top"
                          tooltipClass="my-custom-class">
                          <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
                        </div> -->
                      </div>
                    </div>
                    <div class="call-module-update ml-4 mr-2">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="header-md mt-2 mb-1">Transfer Details</div>
                        </div>
                      </div>
                      <div class="row custom-row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="formGroupExampleInput">Name</label>
                            <div *ngIf="callData[0]">
                              {{callData[0].firstName+" "+callData[0].lastName}}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="formGroupExampleInput">Email</label>
                            <div *ngIf="callData[0]">{{callData[0].emailId}}</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="formGroupExampleInput">Mobile</label>
                            <div *ngIf="callData[0]">{{callData[0]?.phoneCountryCode?'+'+callData[0]?.phoneCountryCode :
                              '-'}} {{callData[0]?.phoneWithoutCode ||
                              '-'}}</div>
                          </div>
                        </div>

                      </div>

                      <div class="row custom-row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="formGroupExampleInput">Created Date</label>
                            <div *ngIf="callData[0]">
                              {{callData[0].createdAt | date: 'dd MMM yyyy' }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="formGroupExampleInput">University</label>
                            <div class="d-flex">
                              <div class="pic mr-1 float-left text-center">
                              </div>
                              <div *ngIf="callData[0]" class="txt-oflow2 lh180 float-left">
                                {{callData[0].university || ''}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="hr-style mt-1 mb-1" />
                      <div class="row">
                        <div class="col-md-12">
                          <div class="header-md mt-2 mb-1">Application status</div>
                        </div>
                      </div>
                      <div class="row custom-row2 mb-3">
                        <div class="col-md-4 mt-2">
                          <div class="form-check mr-3 d-inline">
                            <label class="form-check-label" for="option11">
                              <input [(ngModel)]="applicationStatus" type="radio" class="form-check-input" id="option11"
                                name="yes" value='yes' (change)="applicationstatus($event.target.value)" />Yes
                            </label>
                          </div>
                          <div class="form-check mr-5 d-inline">
                            <label class="form-check-label" for="option2">
                              <input [(ngModel)]="applicationStatus" type="radio" class="form-check-input" id="option2"
                                name="no" value='no' (change)="applicationstatus($event.target.value)" />No
                            </label>
                          </div>
                        </div>
                      </div>

                      <hr class="hr-style mt-1 mb-1" />
                      <div class="row custom-row2">
                        <div class="col-md-12">
                          <div class="header-md mt-2 mb-1">Feedback</div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-check mr-3 d-inline">
                            <label class="form-check-label" for="feedback">
                              <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback"
                                name="option1" value="notinterested"
                                (change)="onStatusChange($event.target.value)" />Not Interested
                            </label>
                          </div>
                          <div class="form-check mr-3 d-inline">
                            <label class="form-check-label" for="check3">
                              <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback"
                                name="option1" value="informed"
                                (change)="onStatusChange($event.target.value)" />Informed
                            </label>
                          </div>
                          <div class="form-check mr-3 d-inline">
                            <label class="form-check-label" for="check4">
                              <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback"
                                name="option1" value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong
                              Number
                            </label>
                          </div>
                          <div class="form-check mr-3 d-inline">
                            <label class="form-check-label" for="check5">
                              <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback"
                                name="option1" value="callback" (change)="onStatusChange($event.target.value)" />Call
                              Back
                            </label>
                          </div>
                          <div class="form-check mr-3 d-inline">
                            <label class="form-check-label" for="check6">
                              <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback"
                                name="option1" value="notanswered" (change)="onStatusChange($event.target.value)" />Not
                              Answered
                            </label>
                          </div>
                          <div class="form-check mr-3 d-inline">
                            <label class="form-check-label" for="check7">
                              <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="feedback"
                                name="option1" value="deferredintake"
                                (change)="onStatusChange($event.target.value)" />Deferred Intake
                            </label>
                          </div>
                        </div>
                      </div>
                      <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 15px">
                        Please select any option above!
                      </span>
                      <div class="row custom-row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="commentText" class="header-md pt-3 pb-2"
                              style="font-size: 14px">Comments</label>
                            <textarea class="form-control" id="commentText" rows="3"
                              [(ngModel)]="commentText"></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-end">
                        <div class="mt-3">
                          <button class="btn btn-light btn-sm mr-2" type="button" (click)="resetCallingScreen()">
                            Reset
                          </button>
                          <button class="btn btn-primary btn-sm" type="submit" (click)="updateComments()">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Calling screen ends-->

          <!--Calling screen from Documents Validation-->
          <ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
            <div id="progressBar">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
              googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
            </ngx-doc-viewer>
          </ng-template>


          <!-- <simple-notifications [options]="options"></simple-notifications> -->
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Edit Lead New -->
<ng-template #editLead2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Lead Details</h4>
    <button type="button" id="closeButton3" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="background: #fff">
    <form class="pt-2" [formGroup]="editLead" (ngSubmit)="updateLead()">
      <div class="form-row">

        <div class="form-group col-md-4">
          <label>First Name</label>
          <input class="form-control" name="FirstName" formControlName="firstName" [(ngModel)]="leadtoEdit.firstName" />

        </div>

        <div class="form-group col-md-4">
          <label>Last Name</label>
          <input class="form-control" name="LastName" formControlName="lastName" [(ngModel)]="leadtoEdit.lastName" />

        </div>

        <div class="form-group col-md-4">
          <label>Date of Birth</label>
          <input type="date" name="DOB" [value]="leadtoEdit.dateOfBirth | date:'yyyy-MM-dd'" class="form-control"
            formControlName="dateOfBirth" [(ngModel)]="leadtoEdit.dateOfBirth " />

        </div>

      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Email</label>
          <input type="text" name="Email" class="form-control" formControlName="emailId"
            [(ngModel)]="leadtoEdit.emailId" />

        </div>

        <div class="form-group col-md-4">
          <label>Phone Number</label>
          <div class="form-row">
            <div class="col-4">
              <input class="form-control" name="CountryCode" formControlName="phoneCountryCode"
                [(ngModel)]="leadtoEdit.phoneCountryCode" />

            </div>
            <div class="col-8">
              <input class="form-control" name="PhoneNumber" formControlName="phoneWithoutCode"
                [(ngModel)]="leadtoEdit.phoneWithoutCode" />

            </div>
          </div>
        </div>
      </div>

      <div class="form-row">

        <div class="form-group col-md-4">
          <label for="">Address </label>
          <input type="text" name="Address" class="form-control" formControlName="address"
            [(ngModel)]="leadtoEdit.address" />

        </div>

        <div class="form-group col-md-4">
          <label for="">City </label>
          <input type="text" name="City" class="form-control" formControlName="cityOrTown"
            [(ngModel)]="leadtoEdit.cityOrTown" />

        </div>

        <div class="form-group col-md-4">
          <label for="">State </label>
          <input type="text" name="State" class="form-control" formControlName="state" [(ngModel)]="leadtoEdit.state" />

        </div>

      </div>

      <div class="form-row">

        <div class="form-group col-md-4">
          <label for="">Zip </label>
          <input type="text" name="Zip" class="form-control" formControlName="zip" [(ngModel)]="leadtoEdit.zip" />

        </div>

        <div class="form-group col-md-4">
          <label for="">Country </label>
          <input type="text" name="Country" class="form-control" formControlName="country"
            [(ngModel)]="leadtoEdit.country" />

        </div>

      </div>

      <div class="form-row">

        <div class="form-group col-md-4">
          <label for="">Comments </label>
          <input type="text" name="LeadComment" class="form-control" formControlName="Comments"
            [(ngModel)]="leadtoEdit.Comments" />

        </div>

      </div>


      <div class="form-row">
        <!-- Radio button  -->

        <div class="form-group col-md-12">
          <label for="">Feedback</label>
          <div class="mt-2">

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" value="pending"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio1">Pending</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="notinterested"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio2">Not Interested</label>
            </div>


            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="informed"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio2">Informed</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callfailed"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio2">Wrong Number</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="callback"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio2">Call Back</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" value="notanswered"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio1">Not Answered</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="deferredintake"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio2">Deferred Intake</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2" value="uncontactable"
                formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus" />
              <label class="form-check-label" for="inlineRadio2">Uncontactable</label>
            </div>

          </div>

        </div>

      </div>
      <div class="d-flex justify-content-end">
        <div class="mt-3">
          <button class="btn btn-primary btn-sm" type="submit">Update</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<!-- add lead -->
<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Lead</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3" style="max-height: 600px; background: #fff">
    <form (ngSubmit)="onCreateLead()" [formGroup]="createLead">
      <!-- FIRST NAME -->
      <div class="form-group">
        <label class="form-label">First Name</label>
        <input class="form-control" name="firstName" formControlName="firstName" [ngClass]="{
            'is-invalid': submitted && f.firstName.invalid,
            'is-valid':
              f.firstName.valid && f.firstName.touched
          }" />
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
          <div>First Name is required</div>
        </div>
      </div>
      <!-- LAST NAME -->
      <div class="form-group">
        <label class="form-label">Last Name</label>
        <input class="form-control" name="lastName" formControlName="lastName" [ngClass]="{
            'is-invalid': submitted && f.lastName.invalid,
            'is-valid':
              f.lastName.valid && f.lastName.touched
          }" />
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
          <div>LastName is required</div>
        </div>
      </div>
      <!-- PHONE NUMBER -->
      <div class="form-group">
        <label class="form-label">Phone Number</label>
        <div class="row">
          <div class="col-2">
            <input class="form-control" name="phoneCountryCode" formControlName="phoneCountryCode" [ngClass]="{
                'is-invalid': submitted && f.phoneCountryCode.invalid,
                'is-valid':
                  f.phoneCountryCode.valid &&
                  f.phoneCountryCode.touched
              }" />
            <div *ngIf="submitted && f.phoneCountryCode.errors" class="invalid-feedback">
              <div>Country Code is required</div>
            </div>
          </div>
          <div class="col-10">
            <input class="form-control" name="phoneWithoutCode" formControlName="phoneWithoutCode" [ngClass]="{
                'is-invalid': submitted && f.phoneWithoutCode.invalid,
                'is-valid':
                  f.phoneWithoutCode.valid &&
                  f.phoneWithoutCode.touched
              }" />
            <div *ngIf="submitted && f.phoneWithoutCode.errors" class="invalid-feedback">
              <div>Phone Number is required</div>
            </div>
          </div>
        </div>
      </div>
      <!-- EMAIL -->
      <div class="form-group">
        <label class="form-label">Email</label>
        <input type="text" name="emailId" class="form-control" formControlName="emailId" [ngClass]="{
            'is-invalid': submitted && f.emailId.invalid
          }" />
        <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
          <div>Email is required</div>
        </div>
      </div>
      <!-- APPLYING AS -->
      <div class="form-group">
        <label class="form-label">Applying as</label>
        <!-- <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
            'is-invalid': submitted && f.email.invalid,
            'is-valid': f.email.valid && f.email.touched
          }" /> -->
        <select id="inputState" class="form-control" name="ApplyingAs" class="form-control" formControlName="ApplyingAs"
          [ngClass]="{
          'is-invalid': submitted && f.ApplyingAs.invalid,
          'is-valid': f.ApplyingAs.valid && f.ApplyingAs.touched
        }" (change)="onSelectionChange()" [disabled]="requiredfieldApplyAs">
          <option disabled value="">Choose One</option>
          <option *ngFor="let i of applyAsStudent" [value]="i">
            {{ i }}
          </option>
        </select>
        <div *ngIf="submitted && f.ApplyingAs.errors" class="invalid-feedback">
          <div>Applying as is required</div>
        </div>
        <!-- applying as student => others -->
        <div *ngIf="requiredfieldApplyAs" class="mt-4">
          <input type="text" name="othersApplyingAs" class="form-control" formControlName="othersApplyingAs" [ngClass]="{
            'is-invalid': submitted && f.othersApplyingAs.invalid,
            'is-valid': f.othersApplyingAs.valid && f.othersApplyingAs.touched
          }" />
        </div>
      </div>


      <!-- APPLYING FOR -->
      <div class="form-group">
        <label class="form-label">Applying for</label>
        <!-- <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
            'is-invalid': submitted && f.email.invalid,
            'is-valid': f.email.valid && f.email.touched
          }" /> -->
        <select id="inputState" class="form-control" name="ApplyingFor" class="form-control"
          formControlName="ApplyingFor" [ngClass]="{
          'is-invalid': submitted && f.ApplyingFor.invalid,
          'is-valid': f.ApplyingFor.valid && f.ApplyingFor.touched
        }" (change)="onSelectionChange()" [disabled]="requiredfieldApplyFor">
          <option disabled value="">Choose One</option>
          <option *ngFor="let i of fromUsYesApplyFor" [value]="i">
            {{ i }}
          </option>
        </select>
        <div *ngIf="submitted && f.ApplyingFor.errors" class="invalid-feedback">
          <div>Applying for is required</div>
        </div>
        <!-- applying as student => others -->
        <div *ngIf="requiredfieldApplyFor" class="mt-4">
          <input type="text" name="othersApplyingFor" class="form-control" formControlName="othersApplyingFor"
            [ngClass]="{
            'is-invalid': submitted && f.othersApplyingFor.invalid,
            'is-valid': f.othersApplyingFor.valid && f.othersApplyingFor.touched
          }" />
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="btn btn-primary btn-sm">Submit</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- for upload multiple files-->
<div class="modal fade" id="uploadFiles" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 400px">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Upload Documents
        </h5>
        <button type="button" id="closeButton4" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="uploadSuccess=='none'">
          <div class="d-flex" *ngIf="!docUploadStatus">
            <div>
              <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                Select Files
              </button>
              <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                style="display: none;">
            </div>
            <div class="add-docs-item-sub ml-auto">
              <div class="txt-oflow-lg text-right">

                Selected Files: {{selectedFiles.length}}
              </div>
            </div>
          </div>

          <!--Addon-->
          <div *ngIf="selectedFiles.length==0" class="d-flex p-3 flex-column">
            <div class="text-center p-3"><img src="../../../assets/img/upload-file-2.png" /></div>
            <div class="text-center">
              Upload below supported documents only <br>
              <span style="font-size:18px; line-height:180%">.doc .pdf .png .jpg .jpeg</span>
            </div>
          </div>
          <!--End-->

          <!-- <button type="button" (click)="filesInput.click()">trigger</button> -->
          <div *ngIf="selectedFiles.length>0">
            <div *ngIf="!docUploadStatus" class="py-2 mb-3" style="height:160px;overflow:auto">
              <div *ngFor="let i of selectedFiles;let indexOfelement=index;"
                class="d-flex add-docs-item align-items-center py-2">
                <div class="">
                  <img class="img12 mr-1 lh160 float-left" src="../../../assets/img/docs-r.svg" />
                  <div class="txt-oflow-lg" style="display:inline-block;line-height:100%">{{ i.name }}</div>
                  <br />
                  <!-- <div class="add-docs-item-sub d-flex" style="margin-left: 20px">
            <div class="txt-oflow-lg">
              {{i.fileName}}
            </div>
          </div> -->
                </div>
                <div class="ml-auto">
                  <button style="background: none;" (click)="deleteFile(indexOfelement)">
                    <img class="img12" src="../../../assets/img/delete.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>




          <div *ngIf="!docUploadStatus">
            <div class="d-flex">
              <div *ngIf="selectedFiles.length > 0">
                <div *ngIf="!docUploadStatus">
                  <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                    + Add more
                  </button>

                  <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                    style="display: none;">
                </div>
              </div>
              <div class="ml-auto">
                <button *ngIf="selectedFiles.length > 0" #uploadFilesButton type="button"
                  class="btn btn-primary btn-sm mr-2" (click)="uploadAttachments()">
                  Upload
                </button>
                <!-- <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
              style="display: none;"> -->
                <button (click)="cancelUpload()" type="button" class="btn btn-light btn-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="p-3 header-sm" style="text-align: center;line-height: 140%;color: red;">
          {{docUploadError2}}
        </div> -->
        <div *ngIf="!docUploadStatus">
          <!--Addon-->
          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='success'">
            <div class="text-center p-3"><img src="../../../assets/img/upload-success.png" /></div>
            <div class="text-center">
              <b>Upload Success</b><br>
              <div style="font-size:14px; line-height:130%;padding:5px">Total {{selectedFiles.length}} files
                uploaded successfully
              </div>
            </div>
          </div>
          <!--End-->

          <!--Addon-->
          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess=='failure'">
            <div class="text-center p-3"><img src="../../../assets/img/upload-failed.png" /></div>
            <div class="text-center">
              <b>Upload Failed</b><br>
              <div style="font-size:14px; line-height:130%;padding:5px">Files not uploaded. Please check
                document extensions,
                upload supported documents</div>
            </div>
          </div>
          <!--End-->


        </div>
        <div>
          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
        </div>
        <div class="p-3 text-center docUploadError" *ngIf="uploadSuccess=='none'"
          style="color: green; font-size: 14px; font-weight: bold">
          {{docUploadError2}}
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content" style="width: 280px; background: none; border: 0">
      <div class="modal-header" style="
          border-bottom: 0;
          display: inline;
          position: absolute;
          right: 5px;
          top: 2px;
          z-index: 999;
        ">
        <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close"
          style="margin-right: 0">
          <span aria-hidden="true"><img class="img14" style="width: 10px" src="./assets/img/close.svg" alt="" />
          </span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0">
        <div class="d-flex">
          <div class="ndialer" style="width: 275px">
            <!--.......code for no- incoming call screen.........  -->

            <div class="dialer-middle-screen" style="text-align: center">
              <div class="custom-incomingcall-widget">
                <h4 class="mt-3 text-ellipsis">
                  {{
                    sideViewerData?.firstName || '-' | titlecase }} {{sideViewerData?.lastName || '-'
                    | titlecase
                    }}
                </h4>
                <div class="my-1 lg-text">
                  {{sideViewerData?.phoneCountryCode?'+'+sideViewerData?.phoneCountryCode : '-'}}
              {{sideViewerData?.phoneWithoutCode
              ||
              '-'}}
                </div>
                <div class="custom-user-img my-3">
                  <img class="img80" src="./assets/img/user-w.svg" alt="" />
                </div>

                <h5>{{ callStatus }}</h5>

                <div class="custom-incoming-btns my-3 mt-4">
                  <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                    <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                  </button>
                </div>

                <button type="button" class="btn btn-link btn-sm ml-2 float-left" placement="right"
                  #popover="ngbPopover" [ngbPopover]="comments22">
                  <img src="./assets/img/comments.svg" style="width: 16px" />
                </button>

                <ng-template #comments22>
                  <form #form="ngForm">
                    <div class="d-flex align-items-end" style="width: 180px">
                      <div class="flex-grow-1">
                        <textarea class="form-control ct" type="text" #callingComment id="callingComments" rows="2"
                          placeholder="Write comments here..."></textarea>
                      </div>
                      <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                        (click)="postComments(sideViewerData,currentItem, 'callingComments')">
                        <img src="./assets/img/send-message.svg" style="width: 16px" />
                      </button>
                    </div>
                  </form>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Calling popup from Validation  -->
</div>