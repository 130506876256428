import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UhLeadsService } from 'src/app/pages/uhleads.service';

@Component({
  selector: 'app-custom-paginationuhub',
  templateUrl: './custom-paginationuhub.component.html',
  styleUrls: ['./custom-paginationuhub.component.css']
})
export class CustomPaginationuhubComponent implements OnInit, OnChanges {
  @Input() pageNumber: Number = 1;
  @Input() totalPages: Number;
  @Output() pageChange : EventEmitter<any> = new EventEmitter();
  onPageChange(pageNo: Number) {
    this.pageNumber = pageNo;
    // this.dashService.pageNumber.next(this.pageNumber);
    this.pageChange.emit(this.pageNumber);
  }

  constructor( 
    private dashService :UhLeadsService,
    ) { }

  ngOnInit(): void {
    this.onPageChange(this.pageNumber);
    document.getElementById("firstPage").click();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.totalPages){
      this.totalPages = changes.totalPages.currentValue;
      console.log(this.totalPages)
    }
    if(changes.pageNumber){
      this.pageNumber = changes.pageNumber.currentValue;
    }
  }
}
