import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EnquiryCallsService } from "./enquiry-calls.service";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { UhLeadsService } from "src/app/pages/uhleads.service";
import { ReferralService } from "src/app/pages/referral/referral.service";
import { now } from "moment";
import { LayoutService } from "src/app/layout/layout.service";
import { ReferreeService } from "src/app/pages/referree/referree.service";
import { IncomingCallsService } from "../incoming-calls/incoming-calls.service";

@Component({
  selector: "app-enquiry-calls",
  templateUrl: "./enquiry-calls.component.html",
  styleUrls: ["./enquiry-calls.component.css"],
})
export class EnquiryCallsComponent implements OnInit {
  userData: any;
  testConfig1: any;
  from: string = "";
  to: any;
  callStatus: any = "Connected";
  city: string = "";
  project: any;
  customUniversityName: string = "";
  selectedUniversity: string;
  universityList: any[];
  applicationStatus: any = "";
  public callData: any[] = [];

  leadInfo: any = {
    FirstName: "",
    LastName: "",
    GUID: "",
    APPStatus: "",
    createdAt: "",
    DOB: "",
    EnrollmentYear: "",
    Program: "",
    CountryCode: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
  };

  documentsList: any;
  newStatus: any;
  commentText: string = "";
  @ViewChild("closeButton2") closeButton2;
  projectId: any;
  selectedLead: any;
  callSid: any;
  enquiryCallSid: any;
  referralCallSid: any;

  constructor(
    private EnquiryCallsService: EnquiryCallsService,
    public dialogRef: MatDialogRef<EnquiryCallsComponent>,
    public countupTimerService: CountupTimerService,
    private uhleadsservice: UhLeadsService,
    public ReferralService: ReferralService,
    public RefereeService: ReferreeService,
    public layoutService: LayoutService,
    public IncomingCallService: IncomingCallsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.testConfig1 = new countUpTimerConfigModel();
    this.testConfig1.timerClass = "test_Timer_class";
    this.testConfig1.timerTexts = new timerTexts();
    this.testConfig1.timerTexts.hourText = ":"; //default - hh
    this.testConfig1.timerTexts.minuteText = ":"; //default - mm
    this.testConfig1.timerTexts.secondsText = " ";
    //default - ss
  }

  ngOnInit(): void {
    this.newStatus = null;
    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    this.startTimer();
    this.EnquiryCallsService.callerData.subscribe((data: any) => {
      if (data) {
        this.from = data.from;
        this.to = data.to;
      }
    });
    this.EnquiryCallsService.leadInfo.subscribe((leadInfo: any) => {
      if (leadInfo) {
        this.project = leadInfo.project;
        this.projectId = leadInfo.projectId;
        this.selectedLead = leadInfo.leadInfo.id;
        this.leadInfo = leadInfo.leadInfo;
        if (leadInfo.project == "Campbellsville") {
          this.documentsList = this.leadInfo.uploadlistofdocuments;
        } else if (leadInfo.project == "Referrals") {
          this.getListofUniversities();
        } else if (leadInfo.project == "Referee") {
        } else if (leadInfo.project == "University Hub") {
          this.selectedLead = leadInfo.id;
        }
        this.IncomingCallService.callInfo.subscribe((callInfo:any)=>{
          this.callSid = callInfo.CallSid
        })

        this.EnquiryCallsService.updateIncomingCallDetails({id: this.selectedLead, project: this.project, sid: this.callSid}).subscribe((res: any)=>{
          
        })
      }
    });

    
    this.EnquiryCallsService.getCallSid.subscribe((data) => {
      this.enquiryCallSid = data;
    });
    
    this.ReferralService.getCallSid.subscribe((data) => {
      this.referralCallSid = data;
    });
  }

  getListofUniversities() {
    this.ReferralService.getUniversityList().subscribe((res: any) => {
      this.universityList = res.universities;
    });
  }

  endCall() {
    this.EnquiryCallsService.endCall.next({ disconnect: true });
    this.callStatus = "Call Ended";
    this.uhleadsservice.showInfo("", "Call has ended!");
    this.countupTimerService.stopTimer();
  }

  closeWindow() {
    this.dialogRef.close();
  }

  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.stopTimer();
    this.countupTimerService.startTimer(cdate);
  }

  onStatusChange(data) {
    this.newStatus = data;
  }
  // resetCallingScreen() {
  //   this.commentText = "";
  //   this.newStatus = null;
  // }
  resetCallingScreen() {
    this.commentText = "";
    this.newStatus = null;
    this.applicationStatus = "";
  }
  updateComments() {
    if (!this.newStatus) {
    } else {
        this.uhleadsservice
          .updateComments2({
            id: this.leadInfo.id,
            comments: this.commentText,
            callStatus: this.newStatus,
            CallSid: this.enquiryCallSid,
          })
          .subscribe((res) => {});
        this.commentText = "";
        this.newStatus = null;
        if (this.callStatus == "Call Ended") {
          this.uhleadsservice.showSuccess("Lead updated", "Success!");
          this.closeWindow();
        }
    }
    // this.closeButton2.nativeElement.click();
  }

  updateComments2() {
    if (!this.newStatus) {
    } else {
        this.ReferralService.updateCallSid(this.referralCallSid).subscribe((r: any) => {
          if (this.applicationStatus == "yes") {
            if (this.selectedUniversity == "other") {
              this.ReferralService.createCustomUniversity({
                ProjectId: this.projectId,
                UniversityName: this.customUniversityName,
              }).subscribe((res: any) => {
                this.selectedUniversity = res.UniversityId;
                this.ReferralService.updateCallComments({
                  id: this.selectedLead.id,
                  comments: this.commentText,
                  callStatus: this.newStatus,
                  CallSid: this.referralCallSid,
                  followupDate: new Date(now()),
                  ApplicationStatus: this.applicationStatus,
                  University: this.selectedUniversity,
                }).subscribe((res) => {
                  this.commentText = "";
                });
              });
            } else {
              this.ReferralService.updateCallComments({
                id: this.selectedLead.id,
                comments: this.commentText,
                callStatus: this.newStatus,
                CallSid: this.referralCallSid,
                followupDate: new Date(now()),
                ApplicationStatus: this.applicationStatus,
                University: this.selectedUniversity,
              }).subscribe((res) => {
                this.commentText = "";
              });
            }
          } else {
            this.selectedUniversity = "";

            this.ReferralService.updateCallComments({
              id: this.selectedLead.id,
              comments: this.commentText,
              callStatus: this.newStatus,
              CallSid: this.referralCallSid,
              followupDate: new Date(now()),
              ApplicationStatus: this.applicationStatus,
              University: this.selectedUniversity,
            }).subscribe((res) => {
              this.commentText = "";
            });
          }
        });
    }
  }

  updateComments3() {
    if (!this.newStatus) {
    } else {
        this.layoutService
          .updateCallSid({ id: this.selectedLead, sid: this.enquiryCallSid })
          .subscribe((res: any) => {
            this.layoutService
              .updateCallComments({
                id: this.selectedLead,
                agentMail: this.userData.mail,
                comments: this.commentText,
                followUpdate: "",
                callStatus: this.newStatus,
                futureLead: "futureLead",
                assignedTo: "",
              })
              .subscribe((data) => {
                this.commentText = "";
                this.newStatus = null;
                if (this.callStatus == "Call Ended") {
                  this.uhleadsservice.showSuccess("Lead updated", "Success!");
                  this.closeWindow();
                }
              });
          });
    }
  }

  updateComments4() {
    if (!this.newStatus) {
    } else {
          this.RefereeService.updateCallComments({
            id: this.selectedLead.id,
            comments: this.commentText,
            callStatus: this.newStatus,
            CallSid: this.enquiryCallSid,
          }).subscribe((res) => {
            this.commentText = "";
            this.newStatus = null;
            this.closeWindow();
          });
    }
  }

  applicationstatus(data) {
    this.applicationStatus = data;
    if (this.applicationStatus == "Yes") {
    }
  }
}
