<section class="content">
    <div class="container-fluid bg-g">
        <div class="block-header">
            <app-report-filter></app-report-filter>
        </div>
    </div>
</section>

<section class="content appreportChart">
    <div class="container-fluid pt-4 pb-5">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Application status</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg"
                                style="width:16px" (click)="leadsData()"></div>


                    </div>
                    <div class="body">
                        <!-- <div style="margin-top:10%">
                            <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                            <label for="loading">Loading...</label>
                          </div>
                           -->
                        <div class="no-record" *ngIf="isStatusCountLoader">
                            <img src="../../../../assets/img/waiting.gif" />
                            <div>
                                Loading Data<br />Please wait while we fetch the
                                data for you
                            </div>
                        </div>
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="statusCounts" [animations]="animations" [legend]="true"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)" *ngIf="!isStatusCountLoader">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Completed vs Pending</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg"
                                style="width:16px" (click)="leadsData()"></div>

                    </div>
                    <div class="body">
                        <div class="no-record" *ngIf="isStatusCountLoader">
                            <img src="../../../../assets/img/waiting.gif" />
                            <div>
                                Loading Data<br />Please wait while we fetch the
                                data for you
                            </div>
                        </div>
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="completedStatus" [animations]="animations" [legend]="false"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)" *ngIf="!isStatusCountLoader">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Assigned to</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg"
                                style="width:16px" (click)="leadsData()"></div>

                    </div>
                    <div class="body">
                        <div class="no-record" *ngIf="isAssignCountLoader">
                            <img src="../../../../assets/img/waiting.gif" />
                            <div>
                                Loading Data<br />Please wait while we fetch the
                                data for you
                            </div>
                        </div>
                        <ngx-charts-pie-chart class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="assignedCounts" [animations]="animations" [legend]="false"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [explodeSlices]="explodeSlices" [labels]="true" [doughnut]="doughnut" [arcWidth]="arcWidth"
                            [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipText]="pieTooltipText"
                            (dblclick)="dblclick($event)" (select)="select($event)" (activate)="activate($event)"
                            (deactivate)="deactivate($event)" *ngIf="!isAssignCountLoader">
                        </ngx-charts-pie-chart>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Incoming vs Completed</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg"
                                style="width:16px" (click)="incomingVsCompletedData()"></div>

                    </div>
                    <div class="body">
                        <div class="no-record" *ngIf="isIncomingCompletedLoader">
                            <img src="../../../../assets/img/waiting.gif" />
                            <div>
                                Loading Data<br />Please wait while we fetch the
                                data for you
                            </div>
                        </div>
                        <combo-chart-component class="chart-container" [view]="view" [scheme]="comboBarScheme"
                            [colorSchemeLine]="lineChartScheme" [results]="barChart" [animations]="animations"
                            [lineChart]="lineChartSeries" [tooltipDisabled]="false"
                            [yAxisTickFormatting]="yLeftTickFormat" [yLeftAxisScaleFactor]="yLeftAxisScale"
                            [yRightAxisScaleFactor]="yRightAxisScale" [yRightAxisTickFormatting]="yRightTickFormat"
                            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition"
                            [showGridLines]="showGridLines" [showXAxisLabel]="false" [showYAxisLabel]="false"
                            [showRightYAxisLabel]="false" [autoScale]="true" [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel" [yAxisLabelRight]="yAxisLabelRight"
                            [noBarWhenZero]="noBarWhenZero" (select)="onSelect($event)"
                            *ngIf="!isIncomingCompletedLoader">
                        </combo-chart-component>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Courses</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg"
                                style="width:16px" (click)="courseData()"></div>

                    </div>
                    <div class="body">
                        <div class="no-record" *ngIf="isCourseCountLoader">
                            <img src="../../../../assets/img/waiting.gif" />
                            <div>
                                Loading Data<br />Please wait while we fetch the
                                data for you
                            </div>
                        </div>
                        <ngx-charts-tree-map class="chart-container" [view]="view" [scheme]="colorScheme"
                            [results]="courseCounts" [animations]="animations" [tooltipDisabled]="tooltipDisabled"
                            [labelFormatting]="gdpLabelFormatting" [valueFormatting]="currencyFormatting"
                            [gradient]="gradient" (select)="select($event)" *ngIf="!isCourseCountLoader">
                        </ngx-charts-tree-map>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header d-flex align-items-center">
                        <div class="header-12">Applications Processed - Top Performers</div>
                        <div class="ml-auto" style="cursor: pointer;"><img src="../../../assets/img/download.svg"
                                style="width:16px" (click)="applicationsProcessedData()"></div>

                    </div>
                    <div class="body">
                        <div class="no-record" *ngIf="isTopPerformerLoader">
                            <img src="../../../../assets/img/waiting.gif" />
                            <div>
                                Loading Data<br />Please wait while we fetch the
                                data for you
                            </div>
                        </div>
                        <ngx-charts-gauge class="chart-container" [view]="view" [legend]="showLegend"
                            [legendTitle]="legendTitle" [legendPosition]="legendPosition" [results]="topPerformers"
                            [animations]="animations" [textValue]="gaugeTextValue" [scheme]="colorScheme"
                            [min]="gaugeMin" [max]="gaugeMax" [units]="gaugeUnits" [angleSpan]="gaugeAngleSpan"
                            [startAngle]="gaugeStartAngle" [showAxis]="gaugeShowAxis" [bigSegments]="gaugeLargeSegments"
                            [smallSegments]="gaugeSmallSegments" [margin]="
                margin
                  ? [marginTop, marginRight, marginBottom, marginLeft]
                  : null
              " [tooltipDisabled]="tooltipDisabled" [showText]="showText" (select)="select($event)"
                            (activate)="activate($event)" (deactivate)="deactivate($event)"
                            *ngIf="!isTopPerformerLoader">
                        </ngx-charts-gauge>
                    </div>
                </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 right-padding">
                <div class="card">
                    <div class="header d-flex align-items-center justify-center">
                        <div>
                            <div class="header-12"> Top 10 Leads that took the least time to Completion</div>
                            <div class="sub-text">Time taken from Created date to Completed Date</div>
                        </div>
                        <div class="ml-auto header-dropdown" style="cursor: pointer;"><img
                                src="../../../assets/img/download.svg" style="width:16px"
                                (click)="top10CompletionData()"></div>

                    </div>
                    <div class="table-body table-responsive data-table">
                        <table class="table table-md">
                            <thead>
                                <tr>
                                    <!-- <th>GUID</th> -->
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Program</th>
                                    <th>CRM AssignedTo</th>
                                    <th>GBP AssignedTo</th>
                                    <th>Time Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let i of top10Data" [hidden]="isCompletionLoader">
                                    <!-- <td scope="row">{{i.GUID}}</td> -->
                                    <th scope="row">{{i.FirstName}} {{i.LastName}}</th>
                                    <td>{{i.Email}}</td>
                                    <td>{{i.Program || '--'}}</td>
                                    <td>{{i.Assignedto || '--'}}</td>
                                    <td>{{i.GBPAssignedto || '--'}}</td>
                                    <td>{{i.Time_taken_to_close_An_application.days||0}} days
                                        {{i.Time_taken_to_close_An_application.hours||0}}h
                                        {{i.Time_taken_to_close_An_application.minutes||0}}min</td>
                                </tr>
                                <tr *ngIf="isCompletionLoader">
                                    <td colspan="6">
                                        <div class="no-record">
                                            <img src="../../../../assets/img/waiting.gif" />

                                            <div>
                                                Loading Data<br />Please wait while we fetch the
                                                data for you
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="!isCompletionLoader && isCompletionRecord == false">
                                    <td colspan="6">
                                        <div class="no-record">
                                            <img src="../../../../assets/img/nodata.svg" />
                                            <div>
                                                No records found.<br />Please select a different
                                                date range.
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="header d-flex align-items-center justify-center">
                        <div>
                            <div class="header-12"> Top 10 Leads that took the least time to Validation.</div>
                            <div class="sub-text">Time taken from Created date to Last document validation</div>
                        </div>
                        <div class="ml-auto header-dropdown" style="cursor: pointer;"><img
                                src="../../../assets/img/download.svg" style="width:16px"
                                (click)="top10ValidationData()"></div>

                    </div>


                    <div class="table-body table-responsive data-table">
                        <table class="table table-md">
                            <thead>
                                <tr>
                                    <!-- <th>GUID</th> -->
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Program</th>
                                    <th>CRM AssignedTo</th>
                                    <th>GBP AssignedTo</th>
                                    <th>Time Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let i of top10Validation" [hidden]="isValidationLoader">
                                    <!-- <td scope="row">{{i.GUID}}</td> -->
                                    <th scope="row">{{i.FirstName}} {{i.LastName}}</th>
                                    <td>{{i.Email}}</td>
                                    <td>{{i.Program || '--'}}</td>
                                    <td>{{i.Assignedto || '--'}}</td>
                                    <td>{{i.GBPAssignedto || '--'}}</td>
                                    <td>{{i.total_time_till_validation.days||0}} days
                                        {{i.total_time_till_validation.hours||0}}h
                                        {{i.total_time_till_validation.minutes||0}}min</td>
                                </tr>
                                <tr *ngIf="isValidationLoader">
                                    <td colspan="6">
                                        <div class="no-record">
                                            <img src="../../../../assets/img/waiting.gif" />

                                            <div>
                                                Loading Data<br />Please wait while we fetch the
                                                data for you
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="!isValidationLoader && isValidationRecord == false">
                                    <td colspan="6">
                                        <div class="no-record">
                                            <img src="../../../../assets/img/nodata.svg" />
                                            <div>
                                                No records found.<br />Please select a different
                                                date range.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row clearfix">

    </div>
</section>