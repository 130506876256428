<div class="mt-3" *ngIf="selectedRole['Name']">
  <div class="content-containerx">
    <div class="subnav">
      <h6
        class="header-12 float-left"
      >
        {{ selectedRole["Name"] | titlecase }}
      </h6>
      <div style="float: right">
        <div class="d-flex">
          <div *ngIf="selectedRole['Name']">
            <button
              ngbTooltip="Create Group"
              placement="top"
              tooltipClass="my-custom-class"
              class="btn btn-secondary btn-sm"
              (click)="open(content)"
            >
              <img alt="" class="img11" src="./assets/img/add-w.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-scroll table-view py-2 data-table">
      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="6" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input
                    type="text"
                    class="form-control ng-pristine ng-valid ng-touched"
                    [(ngModel)]="search"
                    placeholder="Search..."
                  />
                </div>
                <div class="pagenation-right">
                  <ngb-pagination
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [collectionSize]="groups.length"
                    class="pagenation-right"
                  ></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>
          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th>Group id</th> -->
            <th>
              <span>
                Group Name
                <img
                  class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg"
                  (click)="sortOrder('Name')"
                />
              </span>
            </th>
            <th>
              <span>
                Group Description
                <img
                  class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('Description')"
                />
              </span>
            </th>
            <th>
              <span>
                Status
                <img
                  class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('ActiveStatus')"
                />
              </span>
            </th>
            <th>
              <span>
                Created Date
                <img
                  class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('CreatedDate')"
                />
              </span>
            </th>
            <th>
              <span>
                Updated Date
                <img
                  class="img7 ml-1"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder('UpdatedDate')"
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of groups
                | filter: search
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString;
              let number = index
            "
          >
            <!-- <td
							class="id-col-mod"
							style="cursor: pointer"
							(click)="updateGroup(content, item['id'])"
						>
							{{ 'Group' + item['id'] }}
						</td> -->
            <td
              class="id-col-mod"
              style="cursor: pointer; color: #1091ad; text-decoration: none"
              (click)="updateGroup(content, item['id'])"
            >
              {{ item["Name"] | titlecase }}
            </td>
            <td>{{ item["Description"] | titlecase }}</td>
            <td>
              <div [ngClass]="{ 'status-open': item['ActiveStatus'] }">
                {{ item["ActiveStatus"] ? "Active" : "In Active" }}
              </div>
            </td>
            <td>{{ item["CreatedDate"] | date: "medium" }}</td>
            <td>{{ item["UpdatedDate"] | date: "medium" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
      {{ isUpdate ? "Update" : "Create" }} Group
    </h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngb-alert type="danger" *ngIf="isError" [dismissible]="false">{{
      error
    }}</ngb-alert>
    <form (ngSubmit)="submit(form.value)" #form="ngForm">
      <div class="row">
        <label for="name" class="form-label mt-2">Group Name</label>
        <input
          type="name"
          class="form-control"
          placeholder="please enter name of Group"
          name="name"
          required
          max="12"
          [(ngModel)]="inputData.name"
          #groupName="ngModel"
          [ngClass]="{
            'is-invalid': !groupName.valid && groupName.touched,
            'is-valid': groupName.valid && groupName.touched
          }"
        />
        <div
          class="invalid-feedback"
          *ngIf="!groupName.valid && groupName.touched"
        >
          please enter a valid group name
        </div>
      </div>

      <div class="row">
        <label for="description" class="form-label mt-2">Managers</label>
        <ng-multiselect-dropdown
          name="manager"
          [settings]="dropdownSettings"
          [data]="managers"
          [(ngModel)]="inputData.manager"
          required
          style="width: 100%; padding: 0"
          #manager="ngModel"
        ></ng-multiselect-dropdown>
        <div class="invalid-feedback" *ngIf="!manager.valid && manager.touched">
          please select a manager
        </div>
      </div>

      <div class="row">
        <label for="description" class="form-label mt-2"
          >Group Description</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="please enter description of Group"
          id="description"
          name="description"
          [(ngModel)]="inputData.description"
          max="25"
          required
          #desc="ngModel"
          [ngClass]="{
            'is-invalid': !desc.valid && desc.touched,
            'is-valid': desc.valid && desc.touched
          }"
        />
        <div class="invalid-feedback" *ngIf="!desc.valid && desc.touched">
          please enter a valid description
        </div>
      </div>

      <div class="row" *ngIf="isUpdate">
        <label for="description" class="form-label mt-2">Active Status</label>
        <select
          class="form-control"
          [(ngModel)]="inputData.activeStatus"
          name="ActiveStatus"
          required
          #activeStatus="ngModel"
          [ngClass]="{
            'is-invalid': !activeStatus.valid && activeStatus.touched,
            'is-valid': activeStatus.valid && activeStatus.touched
          }"
        >
          <option value="active">Active</option>
          <option value="inactive">In Active</option>
        </select>
        <div
          class="invalid-feedback"
          *ngIf="!activeStatus.valid && activeStatus.touched"
        >
          please select value
        </div>
      </div>

      <div class="modal-footer mt-1">
        <button
          type="submit"
          class="btn btn-primary btn-sm"
          [disabled]="!form.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</ng-template>
<!-- backdrop for create Group  -->
