import { RoleService } from "./../role-management/role.service";
import { Role } from "./../create-agent/models/role.model";
import { CreateAgent } from "./../create-agent/create-agent.service";
import { Group } from "./../create-agent/models/group.model";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { GroupService } from "../group.service";
import { SharedService } from "./../../shared/shared.service";
import { NgForm } from "@angular/forms";
import { TicketsService } from "../tickets/tickets.service";
import { AuthService } from "src/app/account/auth/auth.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";

@Component({
  selector: "app-group-management",
  templateUrl: "./group-management.component.html",
  styleUrls: ["./group-management.component.less"],
})
export class GroupManagementComponent implements OnInit, OnDestroy {
  public groups: Group[] = [];
  public page: any = 1;
  public pageSize: any = 5;
  public isError: boolean = false;
  public closeResult: string = "";
  public error: string = "";
  public search: string = "";
  public isUpdate: boolean = false;
  public inputData: any = {};
  public managers: any[] = [];
  public dropdownSettings: IDropdownSettings;
  public localData: any;
  public admin: Boolean;
  public roleSettings: IDropdownSettings;
  public selectedRole: any = { id: "" };
  private roleSubcription: Subscription;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";

  constructor(
    private modalService: NgbModal,
    private groupService: GroupService,
    private SharedService: SharedService,
    private authService: AuthService,
    private ticketService: TicketsService,
    private roleService: RoleService,
    private agentService: CreateAgent,
    private handleError: ErrorHandlerService,
    private route: ActivatedRoute
  ) {}

  getGroups() {
    if (this.selectedRole["id"]) {
      this.groupService
        .fetchGroups(this.selectedRole["id"])
        .subscribe((data) => {
          this.groups = data;
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }

  ngOnDestroy() {
    
    this.groups = [];
    this.roleService.selectedRole.next({ id: "" });
    this.roleSubcription.unsubscribe();
  }

  ngOnInit() {
    this.roleSubcription = this.roleService.selectedRole.subscribe((data) => {
      this.selectedRole = data;
      this.getGroups();
    });
    this.dropdownSettings = {
      singleSelection: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
      idField: "value",
    };
    this.roleSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
    };

    this.authService.admin.subscribe((data) => {
      if (this.admin) {
        return;
      }
      this.admin = data;
      
    });
    this.authService.companyAdmin.subscribe((data) => {
      if (this.admin) {
        return;
      }
      this.admin = data;
      
    });

    this.localData = JSON.parse(localStorage.getItem("userData"));

    this.route.params.subscribe((data) => {
      this.ticketService
        .getUsersList(data["projectId"])
        .subscribe((data: any[]) => {
          this.managers = data.map((data) => ({
            value: data["Mailid"],
            Name: data["FirstName"] + " " + data["LastName"],
          }));
        }, (error) => {
          this.handleError.handleError(error)
        });
    });

    this.getGroups();
  }

  open(content) {
    this.isError = false;
    this.modalService.open(content, { size: "md" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    this.getGroups();
    this.isUpdate = false;
    this.isError = false;
    this.inputData = {};
    this.isUpdate = false;

    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  public submit(form: NgForm) {
    if (!this.isUpdate) {
      
      this.groupService
        .createGroup({
          name: form.name,
          description: form["description"],
          manager: this.inputData.manager[0]["value"],
          createdBy: this.localData.mail,
          roleId: this.selectedRole["id"],
        })
        .subscribe(
          (data) => {
            if (data) {
              this.modalService.dismissAll();
            }
          },
          (err) => {
            this.isError = true;
            this.error = err.error.message;
            this.handleError.handleError(err)

          }
        );
    } else {
      this.groupService
        .updateGroup({
          name: this.inputData["name"],
          Description: this.inputData["description"],
          id: this.inputData["id"],
          activestatus: this.inputData["activeStatus"] === "active",
          manager: this.inputData["manager"][0]["value"],
          createdBy: "admin",
          roleId: this.selectedRole["id"],
        })
        .subscribe(
          (data) => {
            if (data) {
              this.modalService.dismissAll();
            }
          },
          (err) => {
            this.isError = true;
            this.error = err.error.message;
            this.handleError.handleError(err)

          }
        );
    }
  }

  public updateGroup(content, id) {
    const group: Group = this.groups[id];

    this.groupService.getGroup(id).subscribe((data) => {
      this.inputData.name = data["Name"];
      this.inputData.description = data["Description"];
      this.inputData.activeStatus = data["ActiveStatus"];
      this.inputData.id = data["id"];
      this.inputData.activeStatus = data["ActiveStatus"]
        ? "active"
        : "inactive";
      let manager = this.managers.find(
        (val) => val["value"] === data["Manager"]
      );
      this.inputData.manager = manager ? [manager] : ["select manager"];
      // this.inputData.selectedRole = [this.availableRoles.find(role => role['id'] === data['RoleId'])]

      this.isUpdate = true;

      this.open(content);
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }
}
