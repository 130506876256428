<div class="row" style="padding: 40px 0 0 30px; margin: 0">
	<div class="col-md-3">
		<div class="profile-bg text-center">
			<div class="" (click)="uploadFile(file)">
				<input
					type="file"
					name="file"
					#file
					style="display: none"
					accept="image/*"
					(change)="uploadFiles($event)"
				/>
				<img [src]="profilePhoto" alt="" class="profile-img" />
			</div>
			<div class="profile-name" style="text-transform: capitalize;">
				{{ userData.firstName }} {{ userData.lastName }}
			</div>
			<div class="profile-mail">{{ userData.mail }}</div>
			<span class="profile-phone">+{{ phoneNumber ? phoneNumber : '' }}</span>
			<div class="btn btn-secondary btn-sm mt-2" (click)="editProfile()" style="cursor: pointer"
				>Edit Profile</div
			>
		</div>
	</div>
	<div class="col-md-4">
		<div class="profile-bg">
			<div class="roles-block">
				<div class="header-12 mb-2">Roles ({{ userRoles.length }})</div>
				<div>
					<div class="badge badge-pill badge-secondary mr-2 mb-2" *ngFor="let name of userRoles">{{
						name
					}}</div>
				</div>
			</div>
			<div>
				<div class="header-12 mb-2 mt-3">Groups ({{ userGroups.length }})</div>
				<div>
					<div class="badge badge-pill badge-secondary mr-2" style="font-weight:400;text-transform: capitalize;" *ngFor="let name of userGroups">{{
						name
					}}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-4">
		<div class="profile-bg">
			<div class="roles-block">
				<div class="header-12 mb-2">Settings</div>
				<div>
					<div class="btn btn-secondary btn-sm" (click)="toggleShown()"
						>Change Password</div
					>
				</div>
				<ngb-alert
					*ngIf="alert1.success"
					[type]="alert1.type"
					(close)="alert1.success = false"
					[dismissible]="false"
					>{{ alert1.message }}</ngb-alert
				>
				<div *ngIf="isShown" class="change-password-block">
					<form
						(ngSubmit)="submit(f.value, content)"
						#f="ngForm"
						autocomplete="off"
					>
						<div class="chpass-block">
							<label class="form-label">Old Password</label>
							<input
								type="password"
								class="form-control"
								name="oldPassword"
								[(ngModel)]="oldPassword"
								autocomplete="new-password"
								required
							/>
						</div>
						<div class="chpass-block">
							<label for="oldPassword" class="form-label">New Password</label>
							<input
								type="password"
								class="form-control"
								name="newPassword"
								[(ngModel)]="newPassword"
								required
							/>
						</div>
						<div class="chpass-block">
							<label for="oldPassword" class="form-label"
								>Confirm Password</label
							>
							<input
								type="password"
								class="form-control"
								name="confirmPassword"
								ngModel
								required
							/>
						</div>
						<div class="d-flex justify-content-end">
							<button
								class="btn btn-primary btn-sm"
								type="submit"
								[disabled]="!f.valid"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="">

	

		<div>
			<div class="colgrid">
				<div class="d-flex justify-content-center">
					<div class="profile_photo" (click)="uploadFile(file)">
					
						<input type="file" name="file" #file style="display: none" (change)="uploadFiles($event)" />
						<img src="http://localhost:4000/{{ image }}" alt=""/>
					</div>
				</div>
				<div class="">
					<div class="">
						<label for="firstName" class="form-label m-1">FirstName</label>
						<input type="text" id="firstName" class="form-control" name="firstName" [(ngModel)]="userData.firstName" [disabled]="true"/>
					</div>
					<div class="m-1">
						<label for="lastName" class="form-label m-1">LastName</label>
						<input type="text" id="lastName" class="form-control" name="lastName" [disabled]="true" [(ngModel)]="userData.lastName"/>
					</div>
					<div class="m-1">
						<label for="firstName" class="form-label m-1">Email</label>
						<input type="text" class="form-control" name="email" [disabled]="true" [(ngModel)]="userData.mail" />
					</div>
				</div>
			</div> 
			
		</div> -->
	<!-- <mat-tab label="Roles & Groups">
			<div class="colgrid">
				<div class="px-2">
					<h1 class="h6">Roles</h1>
					<ul class="list-group">
						<li class="list-group-item" *ngFor="let role of userRoles">
							{{ role }}
						</li>
					</ul>
				</div>
				<div>
					<h1 class="h6">Groups</h1>
					<ul class="list-group">
						<li class="list-group-item" *ngFor="let item of userGroups">
							{{ item }}
						</li>
					</ul>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Password">
			<form
				(ngSubmit)="submit(f.value, content)"
				#f="ngForm"
				autocomplete="off"
			>
				<h1 class="h5">Profile:</h1>
		<hr />

		<hr />
		
		<h4 class="h5">Change Password:</h4>
		<hr /> 
				<div class="gridPassword px-2">
					<div>
						<label class="form-label">OldPassword:</label>
						<input
							type="password"
							class="form-control"
							name="oldPassword"
							autocomplete="new-password"
							required
							ngModel
						/>
					</div>
					<div>
						<label for="oldPassword" class="form-label">NewPassword:</label>
						<input
							type="password"
							class="form-control"
							name="newPassword"
							ngModel
							required
						/>
					</div>
					<div>
						<label for="oldPassword" class="form-label">ConfirmPassword:</label>
						<input
							type="password"
							class="form-control"
							name="confirmPassword"
							ngModel
							required
						/>
					</div>
				</div>
				<div class="d-flex justify-content-end mx-4">
					<button class="btn btn-primary" type="submit" [disabled]="!f.valid">
						Update Profile
					</button>
				</div>
			</form>
		</mat-tab> -->

	<!-- <ngb-alert
		*ngIf="alert1.success"
		[type]="alert1.type"
		(close)="alert1.success = false"
		[dismissible]="false"
		>{{ alert1.message }}</ngb-alert
	> -->
	<!-- <form (ngSubmit)="submit(f.value, content)" #f="ngForm" autocomplete="off">
		<h1 class="h5">Profile:</h1>
		<hr />

		<hr />
		
		<h4 class="h5">Change Password:</h4>
		<hr />
		<div class="gridPassword px-2">
			<div>
				<label class="form-label">OldPassword:</label>
				<input
					type="password"
					class="form-control"
					name="oldPassword"
					autocomplete="new-password"
					required
					ngModel
				/>
			</div>
			<div>
				<label for="oldPassword" class="form-label">NewPassword:</label>
				<input
					type="password"
					class="form-control"
					name="newPassword"
					ngModel
					required
				/>
			</div>
			<div>
				<label for="oldPassword" class="form-label">ConfirmPassword:</label>
				<input
					type="password"
					class="form-control"
					name="confirmPassword"
					ngModel
					required
				/>
			</div>
		</div>
		<div class="d-flex justify-content-end mx-4">
			<button class="btn btn-primary" type="submit" [disabled]="!f.valid">
				Update Profile
			</button>
		</div>
	</form>
</div> -->

	<ng-template #content let-modal>
		<div class="modal-header">
			<h4 class="modal-title">Verification</h4>
			<button
				type="button"
				class="close"
				aria-label="Close"
				(click)="modal.dismiss('Cross click')"
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<ngb-alert
				[type]="alert.type"
				*ngIf="alert.success"
				(close)="alert.success = false"
				>{{ alert.message }}</ngb-alert
			>
			<label for="verification" class="form-label">Verification:</label>
			<input
				type="text"
				class="form-control"
				[(ngModel)]="compareNumber"
				style="width: 100%"
			/>
		</div>
		<div class="modal-footer">
			<button class="btn orange-btn" (click)="updatePassword(compareNumber)">
				Update Password
			</button>
		</div>
	</ng-template>
</div>
