<style>
  :host::ng-deep .multiselect-dropdown {
    width: 150px;
    background: none !important;
    color: #fff;
    border: 1px solid #1D416C;
  }

  :host::ng-deep .dropdown-btn {
    border: 1px solid #1D416C !important;
  }
</style>
<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999" *ngIf="sidenavData">
    <div class="d-flex">
      <div class="close hand" style="cursor: pointer;" (click)="sideNav.toggle()"><span
          aria-hidden="true">&times;</span></div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer" *ngIf="sidenavData">
    <mat-tab label="Call Details">
      <div class="alert alert-info alert-md m-2" style="font-size:12px">
        Calls
      </div>
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{sidenavData.AgentName}}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ sidenavData.createdAt | date: "medium" }}</td>
          </tr>

          <tr>
            <td style="padding-top: 20px">Call Recording</td>
            <td><audio *ngIf="sidenavData.RecordingURL != '-'" style="height: 2.5rem; width: 15srem"
                [src]="sidenavData.RecordingURL" controls controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>

        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>


  </mat-tab-group>
</mat-sidenav>
<div class="text-left  mt-2" style="padding: 0 1.5rem;">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>

<div class="m-4">
  <div class="d-flex">
    <div class="col-md-4 active-calls-block">
      <h2 class="header-md py-2">Active Calls</h2>
      <h1 class="pt-2 pb-4">{{liveCalls.length || '00'}}</h1>
      <table class="table table-bordered table-md">
        <tr>
          <td class="incoming-block" style="border:0;">Incoming</td>
          <td class="outgoing-block" style="border:0;">Outgoing</td>
          <td class="missed-block" style="border:0;">Missed</td>
        </tr>
        <tr>
          <td style="border:0;height:2px" colspan="3"></td>
        </tr>
        <tr>
          <td class="text-center f-25" style="border-top:0"> {{incomingCalls || '00'}} </td>
          <td class="text-center f-25" style="border-top:0"> {{outgoingCalls || '00'}} </td>
          <td class="text-center f-25" style="border-top:0"> {{missedCalls || '00'}} </td>
        </tr>
      </table>
    </div>
    <div class="col-md-8 bg-w pr">
      <div class="date-filter">
        <!-- <select class="form-control cs" style="font-size:12px;">
          <option>Past 30min</option>
          <option>1 hour</option>
          <option>2 hours</option>
          <option>1 day</option>
          <option>Custom</option>
        </select> -->
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
        <div *ngIf="showDate" class="datePicker" style="margin-right: 50px;left:10%">
          <div style="background: #fff;width:345px; height: 76px; border: 1px solid #ccc" class="custom-date">
            <mat-form-field class="mr-3">
              <input class="label" matInput [matDatepicker]="picker3" placeholder="Start Date" [formControl]="date2"
                (dateInput)="getStartDate('input', $event)" />
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3 style="width: 80px"></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="mr-3">
              <input class="label" matInput [matDatepicker]="picker4" placeholder="End Date" [formControl]="date1"
                (dateInput)="getEndDate('input', $event)" />
              <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
              <mat-datepicker #picker4 style="width: 80px"></mat-datepicker>
            </mat-form-field>
            <button class="btn btn-secondary btn-sm" (click)="filterData()">
              Submit
            </button>
          </div>
        </div>
      </div>
      <mat-tab-group>
        <mat-tab label="Live Calls {{liveCalls.length?(liveCalls.length):''}}">
          <div class="data-table" style="height: 242px !important">
            <table class="table table-md">
              <tr>
                <th>Team Member</th>
                <th>Caller</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
              <tr *ngFor="let item of liveCalls">
                <td>{{item.AgentName}}</td>
                <td>{{item.Direction == 'outbound'?item.CalledNumber:item.CallerNumber}}</td>
                <td>{{item.RecordingDuration||'0'}} sec.</td>
                <td>
                  <div [className]="item.CallAnswered=='Yes'?'status-completed':'status-pending'">
                    {{
                    item.CallAnswered === "Yes"
                    ? 'Answered'
                    : "Not Answered"
                    }}
                  </div>
              </tr>
              <tr *ngIf="liveCalls && !liveCalls.length">
                <td colspan="4">
                  <div class="data-info">No live call logs...</div>
                </td>
              </tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Recent Calls (8)">
          <div class="data-table" style="height: 242px !important">
            <table class="table table-md">
              <tr>
                <th>Team Member</th>
                <th>Caller</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
              <tbody>
                <tr *ngFor="let item of recentCalls">
                  <td>{{item.AgentName}}</td>
                  <td>{{item.Direction == 'outbound'?item.CalledNumber:item.CallerNumber}}</td>
                  <td>{{item.RecordingDuration||'0'}} sec.</td>
                  <td>
                    <div [className]="item.CallAnswered=='Yes'?'status-completed':'status-pending'">
                      {{
                      item.CallAnswered === "Yes"
                      ? 'Answered'
                      : "Not Answered"
                      }}
                    </div>
                  </td>
                </tr>
                <tr *ngIf="recentCalls && !recentCalls.length">
                  <td colspan="4">
                    <div class="data-info">No recent call logs...</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<div class="d-flex mx-4 mt-4 mb-2">
  <div class="header-12">Agent Live status</div>
  <div class="ml-auto">

  </div>
  <div class="ml-auto" style="position:relative">
    <button (click)="showFilter=!showFilter" class="btn btn-filter btn-sm ml-2">
      <img src="../../../assets/newimages/filter-g.svg" class="img12">
    </button>
    <div *ngIf="showFilter" style="position:absolute;z-index:999; top:35px;right:5px;">
      <div class="filter-block">
        <div class="filter-head d-flex ">
          <label class="pt-1">Filter by</label>
          <a class="btn-link btn-sm ml-auto" style="cursor: pointer" (click)="resetFilter()">Reset Filter</a>
        </div>
        <div class="p-2">

          <div class="filter-selection-block p-2" style="border-right:0">
            <div class="mb-3">
              <div class="subheadtext">Roles</div>
              <div class="form-check">
                <input class="form-check-input position-static mr-2" type="checkbox" id="blankCheckbox" value="option1"
                  aria-label="...">
                <label class="form-check-label" for="inlineCheckbox1">Admin</label>
              </div>
              <div class="form-check">
                <input class="form-check-input position-static mr-2" type="checkbox" id="blankCheckbox"
                  [checked]="selectFilterAgent" (change)="onAgentFilterCheckbox($event)" aria-label="...">
                <label class="form-check-label" for="inlineCheckbox1">Agent</label>
              </div>
              <div class="filter_blocks" *ngIf="selectFilterAgent && showAgents">
                <div class="dropdown_list">
                  <!-- <button type="button" class="btndrop btn-light btn-sm d-flex align-items-center"  (click)="showAgents=!showAgents"
                        [ngClass]="{'btn-filter-active': usersSelectionArray.length>0}">
                        Select agent &nbsp;
                        <div *ngIf="usersSelectionArray.length>0">
                            {{getAgentName(usersSelectionArray[usersSelectionArray.length-1])}}</div>
                        <div *ngIf="usersSelectionArray.length>1" class="badge_count">+{{usersSelectionArray.length}}</div>
                        <div>
                            <i
                            class="fa-solid fa-angle-down arrow-down" style="top:0"></i>
                        </div>
                    </button> -->
                  <div class="drop-show drop-show-label" style="
                    position: absolute;
                    top: 0;
                    left:-15px;width: 220px;">
                    <div class="p-2 float-right"
                      style="width:20px;position: absolute;top:0;right:10px; cursor: pointer;"><mat-icon
                        (click)="showAgents=false">close</mat-icon></div>
                    <div class="p-2">
                      <input type="text" class="form-input spl-search-icon" placeholder="Search"
                        style="border:1px solid #ccc; border-radius: 3px;padding:8px 5px;font-size:12px;width:180px;"
                        (keyup)="agentSearch($event)">
                    </div>
                    <ul class="more_dropdown_list">

                      <li>
                        <div class="input_wrp_filter">
                          <input class="more_dropdown_list_input" type="checkbox" value="" id="defaultCheck1" name="all"
                            [checked]="isAllCheckedusers()" (change)="checkAllusers($event)" />
                          <label style="display: flex !important;">
                            <div class="pic mr-1 float-left text-center">
                              <p class="initials" style="background: #0092AB;">
                                <span style="font-size: 9px !important;color: #fff;">AL</span>
                              </p>
                            </div>
                            <span>All</span>
                          </label>
                        </div>
                      </li>
                      <li *ngFor="let a of contacts">
                        <div class="input_wrp_filter">
                          <input class="more_dropdown_list_input" type="checkbox"
                            (change)="usersSelection($event.target)" [value]="a.Mailid" id="defaultCheck1"
                            name="sizecb[]" [(ngModel)]="a.usersState" />
                          <label style="display: flex !important;">
                            <!-- <div *ngIf="a.agentname" class="pic mr-1 float-left text-center">
                                            <img *ngIf="a.ProfilePhoto != (null ||  '' )" src="{{ a.ProfilePhoto }}" style="width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                border-radius: 100px;" />
        
                                            <p [ngStyle]="{ background: a.AgentColor.bg }" class="initials"
                                                *ngIf="a.ProfilePhoto == null || a.ProfilePhoto == '' ">
                                                <span [ngStyle]="{ color: a.AgentColor.color }" style="font-size: 9px !important;"> {{ a.agentInitial }}</span>
                                            </p>
                                        </div> -->
                            <span>{{a.agentname | titlecase}}</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2">
              <div class="subheadtext">Type</div>
              <div class="form-check" *ngFor="let item of filtertype">
                <input class="form-check-input position-static mr-2" type="checkbox" id="{{item.id}}"
                  [checked]="item.checked" value="{{item.value}}" name="{{item.name}}"
                  (change)="onCheckboxChange($event)" aria-label="...">
                <label class="form-check-label" for="inlineCheckbox1">{{item.name}}</label>
              </div>

            </div>
            <div class="mb-1 pt-2">
              <div class="subheadtext">Status</div>
              <div class="form-check" *ngFor="let item of filterStatus">

                <input class="form-check-input position-static mr-2" type="checkbox" id="{{item.id}}"
                  [checked]="item.checked" value="{{item.value}}" name="{{item.name}}"
                  (change)="onCheckboxChangeStatus($event)" aria-label="...">
                <label class="form-check-label" for="inlineCheckbox1">{{item.name}}</label>
              </div>

              <!-- <div class="form-check">
                <input class="form-check-input position-static mr-2" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                <label class="form-check-label" for="inlineCheckbox1">Live Calls</label>
              </div> -->
            </div>
            <div class="mb-1 pt-2">
              <div class="subheadtext">Service provider</div>
              <div class="form-check" *ngFor="let item of filterVoip">

                <input class="form-check-input position-static mr-2" type="checkbox" id="{{item.id}}"
                  [checked]="item.checked" value="{{item.value}}" name="{{item.name}}" (change)="onCheckBoxVoip($event)"
                  aria-label="...">
                <label class="form-check-label" for="inlineCheckbox1">{{item.name}}</label>
              </div>

              <!-- <div class="form-check">
                <input class="form-check-input position-static mr-2" type="checkbox" id="blankCheckbox" value="option1" aria-label="...">
                <label class="form-check-label" for="inlineCheckbox1">Live Calls</label>
              </div> -->
            </div>
          </div>


          <div style="clear:both"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mx-4 mt-1 mb-4">
  <div class="bottom-content-box-header-container">
    <div class="ticket-requested-list-container">
      <!-- <div class=""> -->
      <!-- <div class="table-responsive"> -->
      <div class="table-responsive data-table bg-w table-top-raidus">
        <table class="table table-md table-hover mb-5">
          <thead style="position: relative; z-index: 9" class="">
            <tr>
              <td colspan="10"
                style="height: 0; padding:8px;;border-top-left-radius: 5px; border-top-right-radius: 5px;">

                <div class="d-flex" style="align-items: center">
                  <div class="indived-search">
                    <input type="search" (keyup)="onInput($event)" class="form-control search-all"
                      style="padding: 4px 12px" [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                  </div>

                  <div class="pagenation-right ml-auto">
                    <ngb-pagination [collectionSize]="pageCount" [(page)]="page" [maxSize]="5" [boundaryLinks]="true"
                      (pageChange)="onPageChange()"></ngb-pagination>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="background: #eaf5fd; border-bottom: none">
              <th style="width:40px;white-space: nowrap;">S No.</th>
              <th style="width: 15%">
                Agent
                <span style="padding-left: 10px">
                  <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                    (click)="sortOrder('AgentName')" /></span>
              </th>
              <th>
                Type
                <span style="padding-left: 10px">
                  <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                    (click)="sortOrder('callType')" /></span>
              </th>
              <th>
                Service provider
                <span style="padding-left: 10px">
                  <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                    (click)="sortOrder('callFrom')" /></span>
              </th>
              <th>
                Status
                <span style="padding-left: 10px">
                  <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                    (click)="sortOrder('callType')" /></span>
              </th>
              <th nowrap>
                Log Time
                <span style="padding-left: 10px">
                  <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                    (click)="sortOrder('createdAt')" /></span>
              </th>
              <th>
                Caller<span style="padding-left: 10px"><img class="img7" src="../../../../assets/img/sort-data.svg"
                    style="cursor: pointer" (click)="sortOrder('CallerNumber')" /></span>
              </th>
              <th nowrap>
                Called<span style="padding-left: 10px"><img class="img7" src="../../../../assets/img/sort-data.svg"
                    style="cursor: pointer" (click)="sortOrder('CalledNumber')" /></span>
              </th>
              <th nowrap>
                Duration<span style="padding-left: 10px"><img class="img7" src="../../../../assets/img/sort-data.svg"
                    style="cursor: pointer" (click)="sortOrder('RecordingDuration')" /></span>
              </th>
              <th nowrap>
                Actions
              </th>
            </tr>

            <tr *ngFor="
                  let item of callLogs | sort: orderFeild:orderString;
                  let index = index
                ">
              <td style="vertical-align: middle; white-space: nowrap">
                {{index+1}}
              </td>
              <td style="vertical-align: middle; white-space: nowrap">
                <div class="d-flex">
                  <div class="profile_photo mx-1" style="position: relative;">
                    <div [className]="item['Status']=='Online'?'status-green':'status-red'"></div>
                    <!-- {{item['ProfilePhoto']}} -->
                    <img class="profile-icon" src="{{
                            item['AgentProfilePhoto']
                              ? item['AgentProfilePhoto']
                              : '../../../assets/img/user.svg'
                          }}" />
                  </div>
                  <div class="d-flex flex-column ml-1">
                    <div class="header-md">{{ item["AgentName"] ||'--'}}</div>
                    <div class="text-sm" style="margin-left: 5px;">{{item["Mailid"] ||'--'}}</div>
                  </div>
                </div>

              </td>
              <td style="vertical-align: middle; white-space: nowrap">
                <img
                  src="../../../assets/img/{{item['Direction'] == 'outbound' ? 'outgoing-call' : 'incoming-call'}}.svg"
                  class="img12 mr-2">
                {{
                item["Direction"] == "outbound" ? "Outgoing" : "Incoming"
                }}
              </td>
              <td style="vertical-align: middle; white-space: nowrap">
                <img *ngIf="item['callFrom']=='Frejun'"
                  src="../../../assets/logos/freJun_logo_text.svg"
                  style="height:13px" />
                <img *ngIf="item['callFrom']=='Twilio'" src="../../../assets/logos/twilio_text.svg"
                  style="height:35px" />

              </td>

              <td style="vertical-align: middle; white-space: nowrap">
                <div [className]="item.CallAnswered=='Yes'?'status-completed':'status-pending'"
                  style="vertical-align: middle" [ngClass]="{
                      missedcall: item['CallAnswered'] === 'No',
                      outgoingcall:
                        item['Direction'] === 'outbound' &&
                        item['CallAnswered'] == 'Yes',
                      inbound:
                        item['Direction'] === 'inbound' &&
                        item['CallAnswered'] == 'Yes'
                    }">
                  {{
                  item["CallAnswered"] === "Yes"
                  ? 'Answered'
                  : "Not Answered"
                  }}
                </div>
              </td>
              <td nowrap>
                {{item["createdAt"]|date:"medium"}}
              </td>
              <td style="vertical-align: middle; white-space: nowrap" class="text-capitalize">
                <div class="d-flex align-items-center">
                  <!-- (click)="callNumber(index)" -->
                  <div class="mr-2" style="cursor: pointer">
                    <!-- <img alt="" src="./assets/img/u-hub-icon.png" /> -->
                    <!-- <mat-icon>call</mat-icon> -->
                  </div>
                  <div>
                    {{
                    item.Direction == 'outbound' ?item.CalledNumber:item.CallerNumber
                    }}
                  </div>
                </div>
              </td>
              <td class="text-left vm">
                {{
                item.Direction == 'outbound' ?item.CallerNumber:item.CalledNumber
                }}
              </td>

              <td class="text-capitalize" style="vertical-align: middle">
                {{ item["RecordingDuration"] || "0" }} secs
              </td>
              <td class="text-capitalize text-left" style="vertical-align: middle">
                <mat-sidenav-container>
                  <mat-sidenav-content>
                    <a style="cursor:pointer" (click)=" sideNavData(item);sideNav.toggle();">
                      <img src="../../../assets/img/view.svg" class="img12 mr-2">
                    </a>
                  </mat-sidenav-content>
                </mat-sidenav-container>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="callLogs && !callLogs.length" style="text-align: center;">
            <tr>
              <th colspan="10">
                <div class="norecords">
                  <img src="../../../../assets/img/nodata.svg" class="img50" style="padding: 0 0 20px 0;" />
                  <div>
                    No data available
                  </div>
                </div>

              </th>
            </tr>


          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>