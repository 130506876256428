declare const $: any;
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { SharedService } from "src/app/shared/shared.service";

@Directive({
  selector: "[appActiveLink]",
})
export class ActivelinkDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private sharedService: SharedService
  ) {}

  @Input() elementRefs: string;
  @Input() parentActive: boolean;

  ngOnInit(): void {
    this.sharedService.switchToggle.subscribe((data) => {
      this.renderer.removeClass(this.elementRef.nativeElement, "show");
    });
  }

}
