import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tablevalue",
})
export class TablevaluePipe implements PipeTransform {
  transform(value: any, lead: any): any {
    switch (value) {
      case "FirstName":
        return lead["FirstName"] + " " + (lead["LastName"] || "");
      case "PhoneNumber":
        return "+" + lead["CountryCode"] + lead["PhoneNumber"];
      case "Email":
        return lead["Email"];
      case "Channel":
        return lead["Channel"] !== null
          ? lead["Channel"].replace(/\b\w/g, (l) => l.toUpperCase())
          : "-";
      case "City":
        return lead["City"] || "-";
      case "Assignedto":
        return lead["Assignedto"];
      case "CallStatus":
        return lead["CallStatus"];
      case "createdAt":
        return lead["createdAt"];
      case "CalledDate":
        return lead["createdAt"];
      case "RecordingURL":
        return lead["RecordingURL"] || "";
      case "DemoScheduled":
        return lead["DemoScheduled"] || "No";
      case "RecordingDuration":
        return (lead["RecordingDuration"] || 0) + " secs";
      case "CalledBy":
        return lead["CalledBy"];
      default:
        return lead["value"];
    }
    // return null;
  }
}
