import { DashboardModule } from "./../dashboard/dashboard.module";
// import {
//   MatCheckboxModule,
//   MatChipsModule,
//   MatFormFieldModule,
//   MatIconModule,
//   MatSnackBarModule,
// } from "@angular/material";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MarketingRoutingModule } from "./marketing-routing.module";
import { SmsmarketingComponent } from "./smsmarketing/smsmarketing.component";
import {
  NgbPaginationModule,
  NgbModule,
  NgbDropdownModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CallmarketingComponent } from "./callmarketing/callmarketing.component";
import { InfomarketingComponent } from "./infomarketing/infomarketing.component";
import { ValuehelperPipe } from "./infomarketing/valuehelper.pipe";
import { AutosmsComponent } from "./autosms/autosms.component";
import { TextpipePipe } from "./helperpipes/textpipe.pipe";
import { LeadsDashboardComponent } from "./leads-dashboard/leads-dashboard.component";
import { AutoMailComponent } from "./auto-mail/auto-mail.component";
import { TablevaluePipe } from "./callmarketing/tablevalue.pipe";
import { TabletitlePipe } from "./helperpipes/tabletitle.pipe";
import { Ng2SearchPipeModule } from "ng2-search-filter";

import { MatTooltipModule } from "@angular/material/tooltip";
import { DemopageComponent } from "./demopage/demopage.component";
import { LayoutModule } from "src/app/layout/layout.module";
import { StatusvaluePipe } from "./pipes/statusvalue.pipe";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormNamePipe } from './leads-dashboard/form-name.pipe';
import { AgentCallsDashboardComponent } from './agent-calls-dashboard/agent-calls-dashboard.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { EventcalenderComponent } from './eventcalender/eventcalender.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
    declarations: [
        SmsmarketingComponent,
        CallmarketingComponent,
        InfomarketingComponent,
        ValuehelperPipe,
        AutosmsComponent,
        TextpipePipe,
        LeadsDashboardComponent,
        AutoMailComponent,
        TablevaluePipe,
        TabletitlePipe,
        DemopageComponent,
        StatusvaluePipe,
        FormNamePipe,
        AgentCallsDashboardComponent,
        SalesDashboardComponent,
        EventcalenderComponent,
    ],
    imports: [
        CommonModule,
        MarketingRoutingModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        MatCheckboxModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        DashboardModule,
        MatTooltipModule,
        Ng2SearchPipeModule,
        MatSnackBarModule,
        LayoutModule,
        ReactiveFormsModule,
        NgbDropdownModule,
        NgMultiSelectDropDownModule,
        FullCalendarModule
    ]
})
export class MarketingModule { }
