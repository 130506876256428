
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './account/auth/auth.service';
import { LayoutService } from './layout/layout.service';
import { CreateAgent } from './pages/create-agent/create-agent.service';
import { RoleService } from './pages/role-management/role.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { NotificationsService} from 'src/app/notification.service'

declare const Twilio: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NotificationsService]
})
export class AppComponent {
  title = 'TicketTool';
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  userData: any;
  isLoggedIn: any;
  configuration: any
  workerJS: any = null;
  //Notification Options
  options = {
    lastOnBottom: false,
    position: ["bottom", "right"],
    timeOut: 20000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: false,
    maxLength: 10,
  };

  constructor(private companyService: RoleService,
    public authService: AuthService,
    private agentService: CreateAgent,
    private NotificationService: NotificationsService,
    public layoutService: LayoutService, private router: Router, private bnIdle: BnNgIdleService) {
    this.bnIdle.startWatching(3600).subscribe((res) => {
      // if (res) {
      //   this.logout();
      // }
    })
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
      
    //   /** START : Code to Track Page View  */
    //    gtag('event', 'page_view', {
    //       page_path: event.urlAfterRedirects
    //    })
    //   /** END */
    // })
  }

  ngOnInit() {
  }

  async logout() {
    let userData = await JSON.parse(localStorage.getItem("userData"));
    this.companyService.projectAdmin(userData["companyId"]).subscribe((projects) => {
      let prId = userData["companyId"] === 0 ? 5 : projects[0]["id"];
      this.layoutService.setupTwilio(prId).then(async (res: any) => {
        this.configuration = await JSON.parse(res);
        this.workerJS = await new Twilio.TaskRouter.Worker(JSON.parse(res).tokens.worker);
        this.toggleIsOnQueue(
          this.configuration.configuration.twilio.workerOfflineActivitySid
        );
      });

      this.agentService
        .updateStatus({ id: userData["id"], status: "Offline", fcmtoken: this.userData.fcmtoken,
        token: "" })
        .subscribe((data) => {
        });
      localStorage.clear();
      sessionStorage.clear();

      setTimeout(() => {
        window.location.reload();
        this.router.navigate(['/home']);
      }, 1000);
    });
  }

  toggleIsOnQueue(activitySid) {
    let payload = {
      ActivitySid: activitySid,
    };
    this.workerJS.update(payload, function (error, worker) {
    });
  }
}
