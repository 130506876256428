import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/httpclient.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class IncomingCallsService {
  baseApiUrl = environment.baseApiUrl2;
  userData = JSON.parse(`${localStorage.getItem("userData")}`);

  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);

  newEnquiry = new BehaviorSubject<any>(null);
  endCall = new BehaviorSubject<any>(null);
  callInfo = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);


  constructor(
    private http: HttpClientService){}

  checkLead(from, to){
    return this.http.get(`${this.baseApiUrl}communication/leadByPhone/`+from+'/'+to);
  }

  checkenquiry(from, to){
    return this.http.get(`${this.baseApiUrl}communication/newInquiryByPhone/`+from+'/'+to);
  }

  createNewEnquiry(data){
    return this.http.post(`${this.baseApiUrl}communication/createNewInquiry`, data);
  }

  getEnquiryById(id, projectId){
    return this.http.get(`${this.baseApiUrl}communication/newInquiryById/`+id+'/'+projectId);
  }

  updateCallComments(data){
    return this.http.post(`${this.baseApiUrl}communication/incomingCallComments`,data);
  }

  updateOutgoingCallComments(data){
    return this.http.post(`${this.baseApiUrl}communication/outgoingCallComments`,data);
  }

}
