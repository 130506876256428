<div class="container-fluid bg-grey p-4">
  <div class="c-block mb-3 p-3">
    <app-report-filter (generate)="generate($event)" (export)="exportList()"></app-report-filter>
  </div>
  <div class="bg-grey">
    <div class="c-block mb-3 p-3 site-report">
      <div class="row">
        <div class="col-md-12">
          <div class="db-sm-header mb-2 mt-2">Statistics</div>
          <div>
            <div style="width: 100%; display: flex;justify-content: space-between;">
              <div echarts [options]="options" class="demo-chart" style="width: 49%;"></div>
              <div echarts [options]="baroption" class="demo-chart" style="width: 49%;"></div>
            </div>
          </div>
          <div class="row mt-4">
            <!-- <div class="col-md-12 text-right">
              <button type="button" mat-raised-button class="btn-orange" [ngClass]="{'disabled': listData?.length<=0}"
                style="background-color: #FA7B52;" (click)="exportList()"><img
                  src="../../../../assets/img/download-w.svg" class="img13 mr-2" />Export</button>
            </div> -->
            <div class="table-responsive data-table mx-4">
              <table class="table table-md">
                <thead>
                  <tr>
                    <td colspan="2">
                      <div class="indived-search" style="position: relative">
                        <input type="search" class="form-control search-all" style="height: auto; padding: 4px 12px"
                          [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                      </div>
                    </td>
                    <td colspan="8" class="vm">
                      <div class="pagenation-right d-flex justify-content-end align-items-center">
                        <div class="mt-3" style="color: #fff; font-size: 12px">
                          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="listData?.length"
                            [maxSize]="5" [boundaryLinks]="true" class="pagenation-right"></ngb-pagination>
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tr style="background: #eaf5fd">
                  <th class="thead_width" style="width:80px">S No</th>
                    <th class="thead_width">GUID</th>
                    <th class="thead_width">Name</th>
                    <th class="thead_width">Programme</th>
                    <th class="thead_width">Created At</th>
                    <th class="thead_width">Updated At</th>
                    <th class="thead_width">Assigned To</th>
                    <th class="thead_width">Lead Status</th>
                </tr>
                <tbody>
                  <tr *ngFor="let item of listData| filter: search
                | slice: (page - 1) * pageSize:page * pageSize;
              let id = index">
                  <td class="listData_values txt-oflow">{{item.showid}}</td>
                  <td class="listData_values">
                    <div class="tooltip_title">
                      <div class="txt-oflow2">
                        {{(item?.GUID).split('-')[0]}}
                      </div>
                      <span class="tooltiptext">{{item?.GUID || '-'}}</span>
                    </div>
                  </td>
                  <td class="listData_values text-left tooltip_title">
                    <div class="txt-oflow">{{item?.Name || '-'  | titlecase}}</div>
                    <span class="tooltiptext"
                      *ngIf="item?.Name?.length>10">{{item?.Name  | titlecase}}</span>
                  </td>
                  <td class="listData_values txt-oflow">{{item?.Program}}</td>
                  <td class="listData_values txt-oflow">{{item?.createdAt | date: 'dd MMMM yyyy'}}</td>
                  <td class="listData_values txt-oflow">{{item?.updatedAt | date: 'dd MMMM yyyy'}}</td>
                  <td class="listData_values txt-oflow">{{item?.AssignedTo || '-'}}</td>
                  <td class="listData_values txt-oflow">
                    <div class="" [ngClass]="{
                    'status-completed': showRevalidatedOnly(item?.LeadDocStatus) == 'completed',
                    'status-validated': showRevalidatedOnly(item?.LeadDocStatus) == 'validated',
                    'status-revalidate': showRevalidatedOnly(item?.LeadDocStatus) == 'revalidated',
                    'status-pending': showRevalidatedOnly(item?.LeadDocStatus) == 'pending',
                    'status-denied': showRevalidatedOnly(item?.LeadDocStatus) == 'denied',
                    'status-reopen': showRevalidatedOnly(item?.LeadDocStatus) == 'reopen',
                    'status-uncontactable': showRevalidatedOnly(item?.LeadDocStatus) == 'notsubmitted'
                  }">
                  <span *ngIf="item?.LeadDocStatus == 'notsubmitted' ">
                    Not Submitted
                  </span>
                  <span *ngIf="item?.LeadDocStatus != 'notsubmitted' ">
                    {{showRevalidatedOnly(item?.LeadDocStatus) | titlecase}}
                  </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
  
  
            </div>
            </div>

        </div>

      </div>
    </div>
  </div>
</div>