import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectchangeService } from 'src/app/layout/project-change/projectchange.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ProjectService } from '../../project-management/project.service';
import { RoleService } from '../../role-management/role.service';
import { EventCalendarService } from '../event-calendar.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { UhLeadsService } from '../../uhleads.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentsComponent implements OnInit,AfterViewInit {
  projectId: any;
  user_details: any;
  eventDetails: any = []
  search: any;
  page: any = 1;
  pageCount: any = 10;
  isLoading: Boolean = false
  noRecords: Boolean = false
  cardCount = {
    All: 0,
    Cancelled: 0,
    Reschdeuled: 0,
    Upcoming: 0
  }
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  sideViewerData: any;
  EventType: any = 'All'
  tab: any;
 

  constructor(
    private eventService: EventCalendarService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private projectchangeService: ProjectchangeService,
    private companyService: RoleService,
    private projectService: ProjectService,
    private SharedService: SharedService,
    private uhleadsService: UhLeadsService,
  ) {
    this.SharedService.pageEvent.emit({
      pageName: "Events",
    });
   
  }

  ngOnInit(): void {
    this.SharedService.pageEvent.emit({
      pageName: "Events",
    });
    this.user_details = JSON.parse(localStorage.getItem("userData"))

    setTimeout(() => {
      this.projectId = this.projectchangeService._projectId;

    }, 500);
    if (!this.projectId) {
      this.companyService.projectAdmin(this.user_details.companyId).subscribe((projects) => {
        this.projectId = projects[0]["id"]

      });
    }
    setTimeout(() => {
      this.getEventsList()
      this.getEventsCardCount()

      // this.setupCalendarDetails()
    }, 600);
    this.uhleadsService.pageNumber.subscribe((pageNo: any) => {
      if (pageNo) {
        this.page = pageNo;
        this.getEventsList();
      }
    });
  }
  ngAfterViewInit(): void {
  
  }
  getEventsList() {
    this.isLoading = true
    let paylaod = {
      projectId: this.projectId,
      AgentId: this.user_details.id,
      page: this.page,
      EventType: this.EventType
    }
    this.eventService.getAllEventList(paylaod).subscribe((Data: any) => {
      if (Data.status === true) {
        this.eventDetails = Data.data.rows
        this.pageCount = Data.data.count
        this.noRecords = false
      }
      else {
        this.noRecords = true
        this.eventDetails = []
        this.page = 1
        this.pageCount = 0
        // this.toastrService.error(Data.message)
      }
      this.isLoading = false

    })
  }
  
  getEventsCardCount() {
    this.isLoading = true
    let paylaod = {
      projectId: this.projectId,
      AgentId: this.user_details.id,

    }
    this.eventService.getActivityCount(paylaod).subscribe((Data: any) => {
      if (Data.status === true) {
        this.cardCount.All = Data.data.All
        this.cardCount.Cancelled = Data.data.Cancelled
        this.cardCount.Reschdeuled = Data.data.Reschdeuled
        this.cardCount.Upcoming = Data.data.Upcoming
      }
      else {

      }
      this.isLoading = false

    })
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  sideNavData(data) {
    this.sideViewerData = data
  }

  projectChange(data) {
    this.projectId = data;
    this.getEventsList()
    this.getEventsCardCount()
  }

  exportList() {
    let obj = {
      projectId: this.projectId,
      AgentId: this.user_details.id,
      EventType: this.EventType,
      export: true
    }
    this.eventService.exportDetails(obj).subscribe((resp: any) => {

      let eventDetails = resp.data

      var fd = eventDetails.map(x => {
        x.Is_cancel = x.Is_cancel ? 'Yes' : 'No'
        x.Is_reschedule = x.Is_reschedule ? 'Yes' : 'No'
        x.cancelScheduleDateTime = x.cancelScheduleDateTime ? moment(x.cancelScheduleDateTime).format('YYYY-MM-DD HH:mm') : '--'
        x.RescheduleDateTime = x.RescheduleDateTime ? moment(x.RescheduleDateTime).format('YYYY-MM-DD HH:mm') : '--'
        x.Name = x.Name ? x.Name : '--'
        x.EmailId = x.EmailId ? x.EmailId : '--'
        x.MobileNumber = x.MobileNumber ? x.MobileNumber : '--'
        x.cancel_reason = x.cancel_reason ? x.cancel_reason : '--'
        x.Members = x.Members ? x.Members : '--'

        const { Name, EmailId, MobileNumber, AgentaName, AgentMailId, EventTitle, EventDescription, StartDate, StartTime, EndDate, EndTime, Members, Is_cancel, cancel_reason, cancelScheduleDateTime, Is_reschedule, RescheduleDateTime } = x;
        return { Name, EmailId, MobileNumber, AgentaName, AgentMailId, EventTitle, EventDescription, StartDate, StartTime, EndDate, EndTime, Members, Is_cancel, cancel_reason, cancelScheduleDateTime, Is_reschedule, RescheduleDateTime };
      })
      var json = fd;
      
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      var a = document.createElement("a");
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      var blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      var x: Date = new Date();
      var link: string = "Appointment_Reports_" + x.getTime() + '.xlsx';
      a.download = link;
      a.click();
    });
  }

  onPageChange(event: any) {
    this.page = event
    this.getEventsList()
    this.getEventsCardCount()
  }

  onClick(check, item: any) {
    if (check == 1) {
      this.tab = "tab1";
    } else if (check == 2) {
      this.tab = "tab2";
    } else if (check == 3) {
      this.tab = "tab3";
    } else if (check == 4) {
      this.tab = "tab4";
    } else {
      this.tab = null;
    }
    this.EventType = item
    this.getEventsList()

  }

  getDateTime(data: any) {
    if (data) {
      return moment(data).format('YYYY-MM-DD HH:mm')
    }
    else {
      return '--'
    }
  }
}
