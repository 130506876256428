<!-- <app-loginheader></app-loginheader> -->
<div class="login-ctr">
	<div class="login-lt">
		<div class="login-lt-inner">
			 
		</div>
	</div>
	<div class="login-rt">
		<div class="login-block mx-auto">
			<div class="text-center">
				<img src="../../../../assets/theme/uh-Logo.png" style="width:200px" alt="" />
			</div>
			<div *ngIf="forgetpassword && !resetPassword">
				<form
					name="loginForm"
					[formGroup]="loginForm"
					(ngSubmit)="onSubmit()"
					novalidate
				>
					<div class="header-md text-center">Login</div>
					<div class="form-group mt-3 mb-3">
						<label for="email" class="label-txt">Email</label>
						<div class="input-group">
						  <input
							type="email"
							formControlName="email"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
							id="email"
							placeholder="Email"
						  />
						  <div class="input-group-append">
							<span class="input-group-text">
							  <i class="fa fa-envelope" aria-hidden="true"></i>
							</span>
						  </div>
						</div>
			
						<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
						  <div *ngIf="f.email.errors.required">Email is required</div>
						  <div *ngIf="f.email.errors.email">
							Email must be a valid email address
						  </div>
						</div>
					  </div>
			
					  <div class="form-group mb-3">
						<label for="password" class="label-txt">Password</label>
						<div class="input-group">
						  <input
							[type]="fieldTextType ? 'text' : 'password'"
							formControlName="password"
							class="form-control"
							[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
							id="password"
							placeholder="Password"
							(ngModelChange)="passwordChange()"
						  />
			
						  <div class="input-group-append">
							<span class="input-group-text">
							  <i
								class="fa"
								[ngClass]="{
								  'fa-eye-slash': !fieldTextType,
								  'fa-eye': fieldTextType
								}"
								(click)="toggleFieldTextType()"
							  ></i>
							</span>
						  </div>
						</div>
						<div
						  *ngIf="submitted && f.password.errors"
						  class="invalid-feedback"
						>
						  <div *ngIf="f.password.errors.required">Password is required</div>
						</div>
					  </div>
					<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
						error
					}}</ngb-alert>
					<div class="m-auto">
						<button class="btn submit-btn" type="submit">Log In</button>
					</div>
					<div
						class="forgotpassword-txt"
						(click)="cancel()"
						style="cursor: pointer"
					>
						Forgot Password?
					</div>
				</form>
			</div>
	
			<div class="mt-3">
				<form
					#resetform="ngForm"
					*ngIf="resetPassword && !forgetpassword"
					(ngSubmit)="resetForm(resetform.value)"
				>
					<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
						error
					}}</ngb-alert>
					<div class="forgot-txt">Change Password</div>
					<div class="form-group">
						<label for="email" class="label-txt">Old Password</label>
						<input
							type="password"
							class="form-control"
							id="oldPassword"
							placeholder="old password"
							name="oldPassword"
							ngModel
							required
							#oldPassword="ngModel"
							[ngClass]="{
								'is-invalid': !oldPassword.valid && oldPassword.touched,
								'is-valid': oldPassword.valid && oldPassword.touched
							}"
						/>
						<div
							class="invalid-feedback"
							*ngIf="!oldPassword.valid && oldPassword.touched"
						>
							Please Enter Valid Password
						</div>
					</div>
					<div class="form-group">
						<label for="email" class="label-txt">New Password</label>
						<input
							type="password"
							class="form-control"
							id="newPassword"
							placeholder="new password"
							name="newPassword"
							ngModel
							required
							#newPassword="ngModel"
							minlength="8"
							pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
							[ngClass]="{
								'is-invalid': !newPassword.valid && newPassword.touched,
								'is-valid': newPassword.valid && newPassword.touched
							}"
						/>
						<div
							class="invalid-feedback"
							*ngIf="!newPassword.valid && newPassword.touched"
						>
							<div *ngIf="newPassword.errors.required">
								Please Enter Valid Password
							</div>
							<div *ngIf="newPassword.errors.minlength">
								Please Enter Minimum 8 Characters
							</div>
							<div *ngIf="newPassword.errors.pattern">
								Password Must Contain UpperCase,Numbers,Alphabets and Special
								Characters
							</div>
						</div>
					</div>
					<div class="form-group">
						<label for="email" class="label-txt">Confirm Password</label>
						<input
							type="password"
							class="form-control"
							id="email"
							placeholder="confirm password"
							name="confirmPassword"
							ngModel
							required
							minlength="8"
							pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
							#confirmPassword="ngModel"
							[ngClass]="{
								'is-invalid': !confirmPassword.valid && confirmPassword.touched,
								'is-valid': confirmPassword.valid && confirmPassword.touched
							}"
						/>
						<div
							class="invalid-feedback"
							*ngIf="!confirmPassword.valid && confirmPassword.touched"
						>
							<div *ngIf="confirmPassword.errors.required">
								Please Enter Valid Password
							</div>
							<div *ngIf="confirmPassword.errors.minlength">
								Please Enter Minimum 8 Characters
							</div>
							<div *ngIf="confirmPassword.errors.pattern">
								Password Must Contain UpperCase,Numbers,Alphabets and Special
								Characters
							</div>
						</div>
					</div>
					<div class="m-auto">
						<button
							class="btn submit-btn"
							type="submit"
							[disabled]="!resetform.valid"
						>
							Submit
						</button>
					</div>
				</form>
			</div>
			<!-- forget password screen -->
	
			<div class="mt-3">
				<div>
					<div *ngIf="!forgetpassword && !resetPassword">
						<!-- <form (ngSubmit)="onForget(form.value)" #form="ngForm"> -->
							<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
								error
							}}</ngb-alert>
							<div class="forgot-txt">Forgot Password</div>
							<div *ngIf="fp === 'fp1'">
								<div class="form-group">
								  <label for="email" class="label-txt">Email</label>
								  <input
									type="email"
									class="form-control"
									id="email"
									placeholder="Email"
									name="mail"
									[(ngModel)]="email"
								  />
								</div>
								<div class="" class="btn-block">
								  <button
									class="btn sendmail-btn"
									type="button"
									(click)="checkEmail()"
								  >
									Send Mail
								  </button>
								  <button class="btn cancel-btn" type="button" (click)="cancel()">
									Cancel
								  </button>
								</div>
							  </div>
							  <div *ngIf="fp === 'fp2'">
								<div class="form-group mb-3">
								  <label for="old-password" class="label-txt">Verification</label>
								  <input
									type="password"
									class="form-control"
									id="old-password"
									placeholder="verification"
									[(ngModel)]="compareNumber"
									name="comparePassword"
								  />
								</div>
								<div class="btn-block">
								  <button
									class="btn sendmail-btn"
									type="button"
									(click)="comparePassword()"
								  >
									Confirm
								  </button>
								  <button class="btn cancel-btn" type="button" (click)="cancel()">
									Cancel
								  </button>
								</div>
							  </div>
							  <div *ngIf="fp === 'fp3'">
								<form (ngSubmit)="onForget(form.value)" #form="ngForm">
								  <div class="form-group mb-3">
									<label for="new-password" class="label-txt"
									  >New Password</label
									>
									<input
									  type="password"
									  class="form-control"
									  id="new-password"
									  placeholder="new password"
									  ngModel
									  name="newPassword"
									/>
								  </div>
								  <div class="form-group mb-3">
									<label for="confirm-password" class="label-txt"
									  >Confirm Password</label
									>
									<input
									  type="password"
									  class="form-control"
									  id="confirm-password"
									  placeholder="confirm password"
									  ngModel
									  name="confirmPassword"
									/>
								  </div>
								  <div class="btn-block">
									<button class="btn sendmail-btn" type="submit">submit</button>
									<button
									  class="btn cancel-btn"
									  type="button"
									  (click)="cancel()"
									>
									  Cancel
									</button>
								  </div>
								</form>
							  </div>
							<!-- <mat-tab-group class="invisible-tabs" [selectedIndex]="selectIndex">
								<mat-tab label="email">
									<div class="form-group">
										<label for="email" class="label-txt">Email</label>
										<input
											type="email"
											class="form-control"
											id="email"
											placeholder="Email"
											name="mail"
											[(ngModel)]="email"
										/>
									</div>
									<div class="" class="btn-block">
										<button
											class="btn sendmail-btn"
											type="button"
											(click)="checkEmail()"
										>
											Send Mail
										</button>
										<button
											class="btn cancel-btn"
											type="button"
											(click)="cancel()"
										>
											Cancel
										</button>
									</div>
								</mat-tab>
								<mat-tab label="verification">
									<div class="form-group mb-3">
										<label for="old-password" class="label-txt"
											>Verification</label
										>
										<input
											type="password"
											class="form-control"
											id="old-password"
											placeholder="verification"
											[(ngModel)]="compareNumber"
											name="comparePassword"
										/>
									</div>
									<div class="btn-block">
										<button
											class="btn sendmail-btn"
											type="button"
											(click)="comparePassword()"
										>
											Confirm
										</button>
										<button
											class="btn cancel-btn"
											type="button"
											(click)="cancel()"
										>
											Cancel
										</button>
									</div>
								</mat-tab>
								<mat-tab label="password">
									<div class="form-group mb-3">
										<label for="new-password" class="label-txt"
											>New Password</label
										>
										<input
											type="password"
											class="form-control"
											id="new-password"
											placeholder="new password"
											ngModel
											name="newPassword"
										/>
									</div>
									<div class="form-group mb-3">
										<label for="confirm-password" class="label-txt"
											>Confirm Password</label
										>
										<input
											type="password"
											class="form-control"
											id="confirm-password"
											placeholder="confirm password"
											ngModel
											name="confirmPassword"
										/>
									</div>
									<div class="btn-block">
										<button class="btn sendmail-btn" type="submit">submit</button>
										<button
											class="btn cancel-btn"
											type="button"
											(click)="cancel()"
										>
											Cancel
										</button>
									</div>
								</mat-tab>
							</mat-tab-group> -->
						<!-- </form> -->
					</div>
					<!-- <div class="col-lg-4">card</div> -->
				</div>
			</div>
			<div class="disclaimer-txt" style="display: none">
				<i class="fa fa-envelope" aria-hidden="true"></i>
				<p>Password reset link sent to your mail id.</p>
			</div>
			<div class="disclaimer-txt" style="display: none">
				<div class="icon">
					<span class="glyphicon glyphicon-ok"></span>
				</div>
				<p>Password changed successfully.</p>
			</div>
		</div>
	</div>
</div>

<div class="body-bg" style="display:none">
	<div class="login-block mx-auto">
		<div class="text-center">
			<img src="../../../../assets/theme/uh-Logo.png" style="width:200px" alt="" />
		</div>
		<div *ngIf="forgetpassword && !resetPassword">
			<form
				name="loginForm"
				[formGroup]="loginForm"
				(ngSubmit)="onSubmit()"
				novalidate
			>
				<div class="header-md text-center">Login</div>
				<div class="form-group mt-3 mb-3">
					<label for="email" class="label-txt">Email</label>
					<input
						type="email"
						formControlName="email"
						class="form-control"
						[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
						id="email"
						placeholder="Email"
					/>

					<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
						<div *ngIf="f.email.errors.required">Email is required</div>
						<div *ngIf="f.email.errors.email">
							Email must be a valid email address
						</div>
					</div>
				</div>

				<div class="form-group mb-3">
					<label for="password" class="label-txt">Password</label>

					<input
						type="password"
						formControlName="password"
						class="form-control"
						[ngClass]="{ 'is-invalid': submitted && f.password.errors }"
						id="password"
						placeholder="Password"
						(ngModelChange)="passwordChange()"
					/>

					<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
						<div *ngIf="f.password.errors.required">Password is required</div>
					</div>
				</div>
				<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
					error
				}}</ngb-alert>
				<div class="m-auto">
					<button class="btn submit-btn" type="submit">Log In</button>
				</div>
				<div
					class="forgotpassword-txt"
					(click)="cancel()"
					style="cursor: pointer"
				>
					Forgot Password?
				</div>
			</form>
		</div>

		<div class="mt-3">
			<form
				#resetform="ngForm"
				*ngIf="resetPassword && !forgetpassword"
				(ngSubmit)="resetForm(resetform.value)"
			>
				<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
					error
				}}</ngb-alert>
				<div class="forgot-txt">Change Password</div>
				<div class="form-group">
					<label for="email" class="label-txt">Old Password</label>
					<input
						type="password"
						class="form-control"
						id="oldPassword"
						placeholder="old password"
						name="oldPassword"
						ngModel
						required
						#oldPassword="ngModel"
						[ngClass]="{
							'is-invalid': !oldPassword.valid && oldPassword.touched,
							'is-valid': oldPassword.valid && oldPassword.touched
						}"
					/>
					<div
						class="invalid-feedback"
						*ngIf="!oldPassword.valid && oldPassword.touched"
					>
						Please Enter Valid Password
					</div>
				</div>
				<div class="form-group">
					<label for="email" class="label-txt">New Password</label>
					<input
						type="password"
						class="form-control"
						id="newPassword"
						placeholder="new password"
						name="newPassword"
						ngModel
						required
						#newPassword="ngModel"
						minlength="8"
						pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
						[ngClass]="{
							'is-invalid': !newPassword.valid && newPassword.touched,
							'is-valid': newPassword.valid && newPassword.touched
						}"
					/>
					<div
						class="invalid-feedback"
						*ngIf="!newPassword.valid && newPassword.touched"
					>
						<div *ngIf="newPassword.errors.required">
							Please Enter Valid Password
						</div>
						<div *ngIf="newPassword.errors.minlength">
							Please Enter Minimum 8 Characters
						</div>
						<div *ngIf="newPassword.errors.pattern">
							Password Must Contain UpperCase,Numbers,Alphabets and Special
							Characters
						</div>
					</div>
				</div>
				<div class="form-group">
					<label for="email" class="label-txt">Confirm Password</label>
					<input
						type="password"
						class="form-control"
						id="email"
						placeholder="confirm password"
						name="confirmPassword"
						ngModel
						required
						minlength="8"
						pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
						#confirmPassword="ngModel"
						[ngClass]="{
							'is-invalid': !confirmPassword.valid && confirmPassword.touched,
							'is-valid': confirmPassword.valid && confirmPassword.touched
						}"
					/>
					<div
						class="invalid-feedback"
						*ngIf="!confirmPassword.valid && confirmPassword.touched"
					>
						<div *ngIf="confirmPassword.errors.required">
							Please Enter Valid Password
						</div>
						<div *ngIf="confirmPassword.errors.minlength">
							Please Enter Minimum 8 Characters
						</div>
						<div *ngIf="confirmPassword.errors.pattern">
							Password Must Contain UpperCase,Numbers,Alphabets and Special
							Characters
						</div>
					</div>
				</div>
				<div class="m-auto">
					<button
						class="btn submit-btn"
						type="submit"
						[disabled]="!resetform.valid"
					>
						Submit
					</button>
				</div>
			</form>
		</div>
		<!-- forget password screen -->

		<div class="mt-3">
			<div>
				<div *ngIf="!forgetpassword && !resetPassword">
					<form (ngSubmit)="onForget(form.value)" #form="ngForm">
						<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
							error
						}}</ngb-alert>
						<div class="forgot-txt">Forgot Password</div>
						<mat-tab-group class="invisible-tabs" [selectedIndex]="selectIndex">
							<mat-tab label="email">
								<div class="form-group">
									<label for="email" class="label-txt">Email</label>
									<input
										type="email"
										class="form-control"
										id="email"
										placeholder="Email"
										name="mail"
										[(ngModel)]="email"
									/>
								</div>
								<div class="" class="btn-block">
									<button
										class="btn sendmail-btn"
										type="button"
										(click)="checkEmail()"
									>
										Send Mail
									</button>
									<button
										class="btn cancel-btn"
										type="button"
										(click)="cancel()"
									>
										Cancel
									</button>
								</div>
							</mat-tab>
							<mat-tab label="verification">
								<div class="form-group mb-3">
									<label for="old-password" class="label-txt"
										>Verification</label
									>
									<input
										type="password"
										class="form-control"
										id="old-password"
										placeholder="verification"
										[(ngModel)]="compareNumber"
										name="comparePassword"
									/>
								</div>
								<div class="btn-block">
									<button
										class="btn sendmail-btn"
										type="button"
										(click)="comparePassword()"
									>
										Confirm
									</button>
									<button
										class="btn cancel-btn"
										type="button"
										(click)="cancel()"
									>
										Cancel
									</button>
								</div>
							</mat-tab>
							<mat-tab label="password">
								<div class="form-group mb-3">
									<label for="new-password" class="label-txt"
										>New Password</label
									>
									<input
										type="password"
										class="form-control"
										id="new-password"
										placeholder="new password"
										ngModel
										name="newPassword"
									/>
								</div>
								<div class="form-group mb-3">
									<label for="confirm-password" class="label-txt"
										>Confirm Password</label
									>
									<input
										type="password"
										class="form-control"
										id="confirm-password"
										placeholder="confirm password"
										ngModel
										name="confirmPassword"
									/>
								</div>
								<div class="btn-block">
									<button class="btn sendmail-btn" type="submit">submit</button>
									<button
										class="btn cancel-btn"
										type="button"
										(click)="cancel()"
									>
										Cancel
									</button>
								</div>
							</mat-tab>
						</mat-tab-group>
					</form>
				</div>
				<!-- <div class="col-lg-4">card</div> -->
			</div>
		</div>
		<div class="disclaimer-txt" style="display: none">
			<i class="fa fa-envelope" aria-hidden="true"></i>
			<p>Password reset link sent to your mail id.</p>
		</div>
		<div class="disclaimer-txt" style="display: none">
			<div class="icon">
				<span class="glyphicon glyphicon-ok"></span>
			</div>
			<p>Password changed successfully.</p>
		</div>
		<div class="text-center" style="color: #a39e9e">
			© 2021 Copyright: Accessque
		</div>
	</div>
	<!-- </div> -->
</div>
