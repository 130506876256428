<div class="text-left mt-2" style="padding: 0 2rem;">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="containers">
  <div class="d-flex justify-content-end mb-2">
    <button ngbTooltip="Create Agent" placement="top" tooltipClass="my-custom-class"
      class="btn btn-secondary btn-sm float-right" (click)="open(content)">
      <img alt="" class="img11" src="./assets/img/add-w.svg" />
    </button>
  </div>
  <div class="content-container">
    <div>
      <span class="searchall-parent"> </span>
    </div>

    <div class="table-scroll data-table" style="background:#fff">
      <table class="table table-md">
        <thead>
          <tr>
            <td colspan="7" style="padding: 8px">
              <div class="table-search-content">
                <div class="indived-search" style="position: relative">
                  <input type="text" class="form-control search-all" [(ngModel)]="search" placeholder="Search" />
                </div>
                <div class="pagenation-right">
                  <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="agents.length"
                    class="pagenation-right"></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style="background: #eaf5fd; border-bottom: none">
            <!-- <th>User id</th> -->

            <th>
              <span>
                Agent Name
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('FirstName')" />
              </span>
            </th>
            <th>
              <span>
                Mail id
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('Mailid')" />
              </span>
            </th>
            <th>
              <span>
                Status
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('ActiveStatus')" />
              </span>
            </th>
            <th>
              <span>
                Created Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('CreatedDate')" />
              </span>
            </th>
            <th>
              <span>
                Updated Date
                <img class="img7" src="../../../../assets/img/sort-data.svg" style="cursor: pointer"
                  (click)="sortOrder('UpdatedDate')" />
              </span>
            </th>
            <th>Action</th>
          </tr>

          <tr *ngFor="
              let item of agents
                | filter: search
                | slice: (page - 1) * pageSize:page * pageSize
                | sort: orderFeild:orderString
            ">
            <td (click)="updateAgent(item['id'], content)" class="a-link vm">
              {{ item["FirstName"] + " " + item["LastName"] }}
            </td>
            <td class="vm">
              {{ item["Mailid"] }}
            </td>
            <td class="vm">
              <div [ngClass]="{
                  'status-open': item['ActiveStatus'] === 'true',
                  'status-inprogress': item['ActiveStatus'] === 'false'
                }">
                {{ item["ActiveStatus"] === "true" ? "Active" : "In Active" }}
              </div>
            </td>
            <td class="vm">{{ item["CreatedDate"] | date: "medium" }}</td>
            <td class="vm">{{ item["UpdatedDate"] | date: "medium" }}</td>
            <td class="text-center vm">
              <div class="example-button-row mat-bset d-flex">
                <mat-sidenav-container ngbTooltip="Overview" placement="top" tooltipClass="my-custom-class">
                  <mat-sidenav-content>
                    <button class="viewLeadButton" mat-button
                      (click)="sideNav.toggle(); sideNavData(item.id,item.Mailid)">
                      <img class="img12" src="../../../../assets/img/view2.svg" />
                    </button>
                  </mat-sidenav-content>
                </mat-sidenav-container>
                <img ngbTooltip="Delete" placement="top" tooltipClass="my-custom-class"
                  (click)="openDeleteAgent(item,deleteContent)" class="img7" src="../../../../assets/img/delete.svg"
                  style="cursor: pointer; width: 13px" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="creat-agent-model">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ isUpdate ? "Update" : "Create" }} Agent
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngb-alert type="danger" *ngIf="iserror" [dismissible]="false">{{
        error
        }}</ngb-alert>
      <form (ngSubmit)="submit(form)" #form="ngForm">
        <div class="row nomar0">
          <div class="col pl0 pb-2">
            <label for="firstname" class="form-label">First Name <span style="color: red">*</span></label>
            <input type="text" class="form-control" placeholder="First name" name="firstname" [(ngModel)]="FirstName"
              required pattern="[a-zA-Z]+" maxlength="35" #first_name="ngModel" [ngClass]="{
                'is-invalid': !first_name.valid && form.submitted,
                'is-valid': first_name.valid && first_name.touched
              }" />
            <div class="invalid-feedback" *ngIf="!first_name.valid && form.submitted">
              <span *ngIf="first_name.errors.required">please enter a First name</span>
              <span *ngIf="first_name.errors.pattern">please enter a valid First name (only letters are allowed)</span>
              <span *ngIf="first_name.errors.maxlength">Maximum length should be 35 characters .</span>
            </div>
          </div>
          <div class="col pr0 pb-2">
            <label for="lastname" class="form-label">Last Name <span style="color: red">*</span></label>
            <input type="text" class="form-control" placeholder="Last name" name="lastname" [(ngModel)]="LastName"
              required pattern="[a-zA-Z]+" maxlength="35" #last_name="ngModel" [ngClass]="{
                'is-invalid': !last_name.valid && form.submitted,
                'is-valid': last_name.valid && last_name.touched
              }" />
            <div class="invalid-feedback" *ngIf="!last_name.valid && form.submitted">
              <span *ngIf="last_name.errors.required">please enter a last name</span>
              <span *ngIf="last_name.errors.pattern">please enter a valid last name (only letters are allowed)</span>
            </div>
          </div>
        </div>
        <div class="row nomar0">
          <div class="col pl0 pb-2">
            <label for="email" class="form-label mt-2">Mail Id <span style="color: red">*</span></label>
            <input type="email" class="form-control" placeholder="please enter a valid email" name="email"
              [disabled]="isUpdate" [(ngModel)]="Mailid" required email maxlength="35" pattern="[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}" #userMail="ngModel" [ngClass]="{
                'is-invalid': !userMail.valid && form.submitted && !isUpdate,
                'is-valid': userMail.valid && userMail.touched
              }" />
            <div class="invalid-feedback" *ngIf="!userMail.valid && form.submitted && isUpdate">
              {{ "please enter a valid email" | titlecase }}
            </div>
            <div class="invalid-feedback" *ngIf="!userMail.valid && form.submitted && ! isUpdate">
              <span *ngIf="userMail.errors.required"> Please enter a valid email
              </span>
              <span *ngIf="userMail.errors.pattern"> Please enter a valid email
              </span>
            </div>
          </div>
          <div class="col pr0 pb-2">
            <label for="number" class="form-label mt-2">Phone Number <span style="color: red">*</span></label>
            <div class="input-group">
              <div class="input-group-prepend" *ngIf="!isUpdate">
                <button type="button" class="btn btn-default dropdown-toggle ct-code" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  +<span class="country-code">{{ selectedCountryCode }}</span>
                </button>

                <ul class="dropdown-menu" style="height: 10rem; overflow: auto">
                  <li *ngFor="let country of countries" class="my-1" style="
                      cursor: pointer;
                      padding: 6px 10px;
                      cursor: pointer;
                      font-size: 13px;
                      color: #747474 !important;
                    ">
                    <span href="#" (click)="selectCountry(country)">
                      <div class="flag flag-{{ country.cc }} mx-1"></div>
                      <span>{{ country.name }} (+{{ country.code }})</span>
                    </span>
                  </li>
                </ul>
              </div>
              <input type="tel" class="form-control" placeholder="please enter a valid phone number" id="number"
                name="number" pattern="[- +()0-9]+" required #phone_number="ngModel" [(ngModel)]="PhoneNumber" maxlength="12"
                [ngClass]="{
                  'is-invalid': !phone_number.valid && form.submitted,
                  'is-valid': phone_number.valid && phone_number.touched
                }" />
              <div class="invalid-feedback" *ngIf="!phone_number.valid && form.submitted">
                <span *ngIf="phone_number.errors.required">please enter a Valid phone number</span>
                <span *ngIf="phone_number.errors.pattern">Pattern not matched so please enter only digit</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row nomar0">
          <div class="col pl0 pb-2">
            <label for="Voip" class="form-label mt-2">Service provider <span style="color: red">*</span></label>
            <div class="row m-0">
              <div class="form-check col-md-3">
                <input class="form-check-input" type="checkbox" id="twilio" name="twilio" disabled="true"
                  [(ngModel)]="isTwilioSelected">
                <label class="form-check-label" for="twilio">Twilio</label>
              </div>
              <div class="form-check col-md-3">
                <input class="form-check-input" type="checkbox" id="Frejun" name="Frejun"
                  [(ngModel)]="isFrejunSelected" [disabled]="isUpdate && is_frejun_account">
                <label class="form-check-label" for="Frejun">FreJun</label>
              </div>
            </div>
          </div>
        </div>


        <div class="row nomar0">
          <div class="col-5" *ngIf="!isUpdate && superAdmin">
            <label class="form-label mt-2">Company</label>
            <input type="text" [disabled]="true" class="form-control" [value]="companyId[0]['Name']" />
          </div>
        </div>
        <div class="row nomar0 pb-2">
          <div class="dual-select my-2">
            <div class="dual-select__left">
              <div class="mb-2 form-label-headding">Available Projects </div>
              <ul class="dual-select__items">
                <li *ngFor="let contact of unselectedProjects" (dblclick)="addToSelectedProject(contact)"
                  class="dual-select__item">
                  <!-- [class.dual-select__item--selected]="
										pendingSelection[contact.id]
									" -->
                  <div class="contact">
                    <div class="contact__name">
                      {{ contact.Name }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="dual-select__controls">
              <!-- (click)="addToSelectedRoles()" -->
              <button class="dual-select__control" type="button">
                <i class="fa fa-arrow-right" style="color: #999"></i>
              </button>
              <!-- (click)="removeFromSelectedRoles()" -->
              <button class="dual-select__control" type="button">
                <i class="fa fa-arrow-left" style="font-size: 20px; color: #999"></i>
              </button>
            </div>
            <div class="dual-select__right">
              <div class="mb-2 form-label-headding">Assigned Projects <span style="color: red">*</span></div>
              <ul class="dual-select__items">
                <li *ngFor="let contact of selectedProjects" (dblclick)="removeFromSelectedProject(contact)"
                  class="dual-select__item dual-select__item--new">
                  <!-- [class.dual-select__item--selected]="
										pendingSelection[contact.id]
									" -->
                  <div class="contact">
                    <div class="contact__name">
                      {{ contact.Name }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row nomar0 pb-2">
          <div class="dual-select my-2">
            <div class="dual-select__left">
              <div class="mt-3 mb-2 form-label-headding">Available Roles </div>
              <ul class="dual-select__items">
                <li *ngFor="let contact of unselectedRoles" (dblclick)="addToSelectedRoles(contact)"
                  class="dual-select__item">
                  <!-- [class.dual-select__item--selected]="
										pendingSelection[contact.id]
									" -->
                  <div class="contact">
                    <div class="contact__name">
                      {{
                      contact.Name | roleName: contact["ProjectId"]:projects
                      }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="dual-select__controls">
              <button (click)="addToSelectedRoles()" class="dual-select__control" type="button">
                <i class="fa fa-arrow-right" style="color: #999"></i>
              </button>
              <button (click)="removeFromSelectedRoles()" class="dual-select__control" type="button">
                <i class="fa fa-arrow-left" style="font-size: 20px; color: #999"></i>
              </button>
            </div>
            <div class="dual-select__right">
              <div class="mt-3 mb-2 form-label-headding">Assigned Roles <span style="color: red">*</span></div>
              <ul class="dual-select__items">
                <li *ngFor="let contact of selectedRoles" (dblclick)="removeFromSelectedRoles(contact)"
                  class="dual-select__item dual-select__item--new">
                  <!-- [class.dual-select__item--selected]="
										pendingSelection[contact.id]
									" -->
                  <div class="contact">
                    <div class="contact__name">
                      {{
                      contact.Name | roleName: contact["ProjectId"]:projects
                      }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row nomar0">
          <div class="dual-select mt-4">
            <div class="dual-select__left">
              <div class="mt-3 mb-2 form-label-headding">Available groups </div>
              <ul class="dual-select__items">
                <li *ngFor="let contact of unselectedGroups" (dblclick)="addToSelectedGroups(contact)"
                  class="dual-select__item">
                  <!-- [class.dual-select__item--selected]="
										pendingselectiongroup[contact.id]
									" -->
                  <div class="contact">
                    <div class="contact__name">
                      {{ contact.Name }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="dual-select__controls">
              <button (click)="addToSelectedGroups()" class="dual-select__control" type="button">
                <i class="fa fa-arrow-right" style="color: #999"></i>
              </button>
              <button (click)="removeFromSelectedGroups()" class="dual-select__control" type="button">
                <i class="fa fa-arrow-left" style="color: #999"></i>
              </button>
            </div>
            <div class="dual-select__right">
              <div class="mt-3 mb-2 form-label-headding">Assigned groups <span style="color: red">*</span></div>
              <ul class="dual-select__items">
                <li *ngFor="let contact of selectedGroups" (dblclick)="removeFromSelectedGroups(contact)"
                  class="dual-select__item dual-select__item--new">
                  <!-- [class.dual-select__item--selected]="
										pendingselectiongroup[contact.id]
									" -->
                  <div class="contact">
                    <div class="contact__name">
                      {{ contact.Name }}
                    </div>
                  </div>
                </li>
              </ul>
              <div class="invalid-feedback" [ngClass]="{
                  'd-block': selectedGroups.length === 0 && form.submitted
                }">
                please select at least one group
              </div>
            </div>
          </div>
        </div>

        <div class="float-right pb-2 mt-3">
          <button type="submit" class="btn btn-primary btn-sm" [disabled]="is_submitted">
            <!-- [disabled]="!form.valid && selectedGroups.length === 0" -->
            {{ isUpdate ? "Update" : "Submit" }}
          </button>
          <button type="button" class="btn btn-default btn-sm" (click)="cancel()" style="margin-right: 10px">
            {{ isUpdate ? "cancel" : "Cancel" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #deleteContent let-modal>
  <div class="creat-agent-model">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Delete Agent
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="modalText">Are you sure you want to delete agent?</p>

      <div class="float-right pb-2 mt-3">
        <button type="button" class="btn btn-primary btn-sm normalText" (click)="deleteAgent()">
          Delete
        </button>
        <button type="button" class="btn btn-default btn-sm" (click)="cancel()" style="margin-right: 10px">
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>



<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height: 200%">
            {{ sideViewerData?.data?.length }} Actions taken
          </div>
          <div class="ml-auto" style="width: 180px">
            <input class="searchActivity" type="text" style="font-size: 13px" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.data?.length)" />
          </div>
        </div>
        <div *ngFor="
            let i of sideViewerData?.data
              | slice : 0 : minItems
              | searchFilterTable : activityfilterString
          ">

          <div class="myactivity d-flex align-items-start">
            <div class="mr-2">
              <img class="img22" src="../../../assets/images/plus.png" *ngIf="i?.Type == 'Create' " />
              <img class="img22" src="../../../assets/images/remove.png" *ngIf="i?.Type == 'Delete' " />
              <img class="img22" src="../../../assets/images/sync.png" *ngIf="i?.Type == 'Update' " />
            </div>
            <p>
              {{ i?.Description }}
              <span class="sm-text ml-2">{{
                i?.timestamp | date : "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore hand" *ngIf="minItems < sideViewerData?.data?.length"
          (click)="showmore(sideViewerData?.data?.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>