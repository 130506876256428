import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UhLeadsService } from 'src/app/pages/uhleads.service';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.css']
})
export class CustomPaginationComponent implements OnInit {
  pageNo: Number = 1;
  totalPages: Number;
  onPageChange(pageNo: Number) {
    this.pageNo = pageNo;
    this.dashService.pageNumber.next(this.pageNo);
  }

  constructor( 
    private dashService :UhLeadsService,
    ) { }

  ngOnInit(): void {
    this.dashService.totalPages.subscribe((res:any)=>{
      this.totalPages = res;
    });
    this.dashService.pageNumber.subscribe((res:any)=>{
      this.pageNo = res;
    });
    this.onPageChange(this.pageNo);
    document.getElementById("firstPage").click();
  }

}
