<style>
  ::ng-deep.settingsClass {
    width: 1000px !important;
    max-height: 100% !important;
    position: static !important;
  }

  ::ng-deep.mat-dialog-container {
    padding: 0 !important;
  }
</style>

<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="staticBackdropLabel">New Enquiry - Incoming Call</h5>

    <button type="button" (click)="closeWindow()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="overflow-y: auto; min-height: 500px;">
    <div class="row">
      <div class="call-module ml-3">
        <div class="ndialer">
          <!--.......code for no- incoming call screen.........  -->
          <div class="c-caller">Current Caller</div>
          <div class="dialer-middle-screen" style="text-align: center">
            <div class="custom-incomingcall-widget">
              <!--<h4 class="mt-3 text-ellipsis">
                        Krishna Jakkamsetti
                      </h4>-->
              <div class="mb-2 mt-1 lg-text" style="font-weight: 100">
                {{city}}
              </div>
              <div class="my-1 lg-text">
                {{from}}
              </div>
              <div class="custom-user-img my-3 mt-5">
                <img class="img80" src="./assets/img/user-w.svg" alt="" />
              </div>
              <h5>{{callStatus}}</h5>
              <p class="custom-call-timer" style="text-align: center">
                <countup-timer [countUpTimerConfig]="testConfig1"></countup-timer>
              </p>

              <div class="custom-incoming-btns my-3 mt-4">
                <button type="button" class="btn-round m-r-10" (click)="endCall()">
                  <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 d-flex justify-content-between">
          <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class">
            <img class="img18" src="./assets/img/redial3.svg" alt="" />
          </div>

          <button class="cbtn" type="button" placement="right">
            <!-- tooltipClass="my-custom-class" -->
            <img class="img18" src="./assets/img/conference.svg" alt="" />
          </button>
          <ng-template #calendarContent>
            <form>
              <div class="xp-3" style="width: 185px">
                <div class="mb-1">
                  <input id="phone_number" name="phone_number" type="text" class="form-control"
                    placeholder="Enter Phone Number" />
                </div>
                <!--
                        <ng-multiselect-dropdown name="manager" 
                           
                          style="width: 100%; padding: 0" class="dropdown-primary"></ng-multiselect-dropdown>
                          -->
                <button class="btn btn-primary btn-block btn-md mt-2">
                  Connect
                </button>
              </div>
            </form>
          </ng-template>

          <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class">
            <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
          </div>

          <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top" tooltipClass="my-custom-class">
            <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
          </div>
        </div>


      </div>

      <div class="call-module-update ml-4 mr-2">
        <div class="row">
          <div class="col-md-12 d-flex">
            <div class="header-md mt-2 mb-1">Student details</div>
            <!-- <div class="ml-auto">
                    <button class="btn btn-primary btn-sm">Activity</button>
                  </div> -->
          </div>
        </div>
        <hr class="hr-style mt-1 mb-2" />
        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">First Name</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.FirstName" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Last Name</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.LastName" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Email</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.Email" />
            </div>
          </div>

        </div>

        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Address</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.Address" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">City</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.City" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">State</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.State" />
            </div>
          </div>
        </div>

        <div class="row custom-row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Country</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.Country" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formGroupExampleInput">Zip</label>
              <input type="text" class="form-control" [(ngModel)]="leadInfo.Zip" />
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <div class="header-12 mt-2 mb-1">Feedback</div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" id="check1" name="option1" value="notinterested" (change)="onStatusChange($event.target.value)" />Not
              Interested
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" id="check3" name="option1" value="informed" (change)="onStatusChange($event.target.value)" />Informed
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check4">
              <input type="radio" class="form-check-input" id="check4" name="option1" value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong Number
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check5">
              <input type="radio" class="form-check-input" id="check5" name="option1" value="callback" (change)="onStatusChange($event.target.value)" />Call Back
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check6">
              <input type="radio" class="form-check-input" id="check6" name="option1" value="notanswered" (change)="onStatusChange($event.target.value)" />Not Answered
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check7">
              <input type="radio" class="form-check-input" id="check7" name="option1" value="deferredintake" (change)="onStatusChange($event.target.value)" />Deferred
              Intake
            </label>
          </div>
          <div class="form-check mr-3 d-inline">
            <label class="form-check-label" for="check8">
              <input type="radio" class="form-check-input" id="check8" name="option1"
                value="uncontactable" (change)="onStatusChange($event.target.value)" />Uncontactable
            </label>
          </div>
        </div>
        <span *ngIf="!newStatus" class="error-msg" style="color: red; font-size: 15px">
          Please select any option above!
        </span>
        <div class="mt-2 mb-1"></div>
        <div class="row">
          <div class="col-md-12">
            <label for="commentText" class="header-md pt-3 pb-2" style="font-size: 14px">Comments</label>
            <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
          </div>
        </div>


        <div class="d-flex justify-content-end">
          <div class="mt-3">
            <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
              Reset
            </button>
            <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>