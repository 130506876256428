import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TicketsRoutingModule } from "./tickets-routing.module";
import { AddUpdateViewTicketsComponent } from "./add-update-view-tickets/add-update-view-tickets.component";
import { FormsModule } from "@angular/forms";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DatePipe } from "@angular/common";
// import {
//   MatBadgeModule,
//   MatIconModule,
//   MatMenuModule,
//   MatSnackBarModule,
//   MatTooltipModule,
// } from "@angular/material";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { MatButtonModule } from "@angular/material/button";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatTabsModule } from "@angular/material/tabs";
import { AgmCoreModule } from "@agm/core";
import { MapsComponent } from "./maps/maps.component";
import { AgmDirectionModule } from "agm-direction";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { GoogledirectionsComponent } from "./googledirections/googledirections.component";
import { NameformattingPipe } from "./nameformatting.pipe";
import { AttachmentsPipe } from "./ticketpipes/attachments.pipe";
import { RequeststatusPipe } from "./ticketpipes/requeststatus.pipe";
import { ProfilePhotoPipe } from "./ticketpipes/profile-photo.pipe";
import { UpdatedbyNamePipe } from "./ticketpipes/updatedby-name.pipe";
import { LayoutModule } from "src/app/layout/layout.module";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
// import { LayoutModule } from "@angular/cdk/layout";

@NgModule({
    declarations: [
        AddUpdateViewTicketsComponent,
        SafeHtmlPipe,
        MapsComponent,
        GoogledirectionsComponent,
        NameformattingPipe,
        AttachmentsPipe,
        RequeststatusPipe,
        ProfilePhotoPipe,
        UpdatedbyNamePipe,
    ],
    imports: [
        LayoutModule,
        CommonModule,
        TicketsRoutingModule,
        AutocompleteLibModule,
        FormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgbModule,
        MatIconModule,
        MatMenuModule,
        MatBadgeModule,
        MatButtonModule,
        MatTooltipModule,
        NgSelectModule,
        MatTabsModule,
        MatSnackBarModule,
        AgmCoreModule,
        GooglePlaceModule,
        AgmDirectionModule,
    ],
    providers: [DatePipe],
    exports: [MapsComponent, SafeHtmlPipe, RequeststatusPipe]
})
export class TicketsModule {}
