import { Injectable } from '@angular/core';
import { RoleService } from 'src/app/pages/role-management/role.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectchangeService {
  public _projectId!:any;
  public _projects!:any;
  userData: any;
  companyId: any;

  constructor(private companyService: RoleService,) {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.companyId = this.userData["companyId"];
    this.getProjects();
  }
  getProjects() {
    this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
      const projectlist = projects.filter(x=>x.id!=10 && x.id!=11)
      this._projects = projectlist;
      if (this.projects.length !== 0) {
        this._projectId = projectlist[0]["id"]
      }
    })
  }
  set projectId(data:any){
    this._projectId = data;
  }

  get projectId():string{
    return this._projectId;
  }

  set projects(data:any){
    this._projects = data;
  }

  get projects():string{
    return this._projects;
  }
}
