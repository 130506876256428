<div class="logo">
  <a [routerLink]="['/uhleads']">
    <img id="SideLogo" src="../../../assets/theme/uh-small-logo.png" alt="" />
  </a>
</div>
<div class="dashboard-tabs-wrapper hcalc scroll-none">
  <div class="accordion" id="accordionExample">
    
    <!-- University Hub -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(34)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#uhub" aria-expanded="true" aria-controls="uhub">
        <img src="./assets/newimages/UniversityHub.png" alt="" title="University Hub" />
        <p>{{ getMenuName(34) }}</p>
      </a>
      <div id="uhub" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li *ngIf="checkingIdMenu(54)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
                <a routerLink="/socialmedia/leads" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{
                  getMenuName(54) }}</a>
            </li>
            <!-- <li *ngIf="checkingIdMenu(55)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
                <a routerLink="/recruiter/leads" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{
                  getMenuName(55) }}</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- Campbellsville -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(29)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#campbellsville" aria-expanded="true" aria-controls="campbellsville">
        <img src="./assets/newimages/cu-icon.png" alt="" title="Campbellsville" />
        <p>{{ getMenuName(29) | titlecase }}</p>
      </a>
      <div id="campbellsville" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/uhleads" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{ getMenuName(30)
                }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    
    <!-- Judsonu -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(48)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#judsonu" aria-expanded="true" aria-controls="judsonu">
        <img src="./assets/images/judson-logo-icon.svg" style="width:12px; margin-left: 2px; margin-right: 4px;" alt="" title="Judsonu"/>
        <p>{{ getMenuName(48) | titlecase }}</p>
      </a>
      <div id="judsonu" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/judsonuleads" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{ getMenuName(50)
                }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- SFC -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(49)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#sfc" aria-expanded="true" aria-controls="sfc">
        <img src="./assets/images/sfc-icon.svg" style="width:18px;" alt="" title="Judsonu"/>
        <p>{{ getMenuName(49) | titlecase }}</p>
      </a>
      <div id="sfc" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/sfcleads" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{ getMenuName(51)
                }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- BUF -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(56)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#buf" aria-expanded="true" aria-controls="buf">
        <img src="./assets/images/buf.svg" style="width:18px;" alt="" title="BUF"/>
        <p>{{ getMenuName(56) | titlecase }}</p>
      </a>
      <div id="buf" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/bufleads" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{ getMenuName(57)
                }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

     <!-- UWLA -->
     <div class="dashboard-tab-container" *ngIf="checkingIdMenu(52)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#uwla" aria-expanded="true" aria-controls="uwla">
        <img src="./assets/images/uwla-logo-icon.svg" style="width:18px;" alt="" title="Uwla"/>
        <p>{{ getMenuName(52) | titlecase }}</p>
      </a>
      <div id="uwla" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/uwlaleads" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{ getMenuName(53)
                }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

     <!-- CUNEXT -->
     <div class="dashboard-tab-container" *ngIf="checkingIdMenu(47)" 
     [routerLinkActive]="['link_active']" (click)="toggleSideBar()">
       <a class="dashboard-tab-item" routerLink="/cunext" title="cuNext" [routerLinkActive]="['sub_links']">
         <img src="./assets/newimages/cu-icon.png" alt="" title="Campbellsville" />
         <p>{{ getMenuName(47) | titlecase }} </p>
       </a>
     </div>
    <!-- Referral -->
    <!-- <div class="dashboard-tab-container" *ngIf="checkingIdMenu(31)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#referral" aria-expanded="true" aria-controls="referral">
        <img src="./assets/newimages/refer-w.svg" alt="" title="Referral" />
        <p>{{ getMenuName(31) | titlecase }}</p>
      </a>
      <div id="referral" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li *ngIf="checkingIdMenu(32)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/referre-icon-2.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/referree" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{ getMenuName(32)
                }}</a>
            </li>
            <li *ngIf="checkingIdMenu(33)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/referral-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/referral" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{ getMenuName(33)
                }}</a>
            </li>
            
          </ul>
        </div>
      </div>
    </div> -->

   

    <!-- Reports -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(35)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#bianalytics" aria-expanded="true" aria-controls="campbellsville">
        <img src="../../../assets/newimages/bi-w.svg" alt="" title="BI Analytics" />
        <p>{{ getMenuName(35) | titlecase }}</p>
      </a>
      <div id="bianalytics" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/appreports" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{
                getMenuName(37) }}</a>
            </li>
            <li [routerLinkActive]=" ['link_active']" *ngIf="getMenuName(38)">
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/callreports" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{
                getMenuName(38) }}</a>
            </li>
            <!-- <li [routerLinkActive]="['link_active']">
              <img src="../../../assets/img/g-analytics.svg" class="img13" style="padding-right: 2px;" alt=""
                title="Google Analytics" />
              <a routerLink="/analytics" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">Analytics</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>

    <!-- Marketting -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(21)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#marketting" aria-expanded="true" aria-controls="marketting">
        <img src="./assets/newimages/marketing.svg" alt="" title="Marketing" />
        <p>{{ getMenuName(21) | titlecase }}</p>
      </a>
      <div id="marketting" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <!-- CAMPAIGN  -->
            <li>
              <img src="./assets/newimages/email-campaigns-icon.svg" class="img13" style="padding-right:4px;" alt=""
                title="University Hub" />
              <a data-toggle="collapse" data-parent="#accordion1" href="javascript:void(0);"
                data-target="#collapseTwoCampaign" aria-expanded="true" aria-controls="#collapseTwoCampaign">Mail
                Campaign
              </a>
              <div id="collapseTwoCampaign" class="collapse in" aria-labelledby="collapseTwoCampaign"
                data-parent="#accordion1">
                <div class="card-body">
                  <ul id="tog-collapse" style="padding-left: 0;
                  margin-left: 20px;">
                    <li [routerLinkActive]="['link_active']">
                      <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="Mail Campaign Dashboard" />
                      <a routerLink="/mail/dashboard" [routerLinkActive]="['sub_links']"
                        style="padding-left: 2px;">Dashboard</a>
                    </li>
                    <li [routerLinkActive]="['link_active']">
                      <img src="./assets/newimages/create-campaign.svg" class="img13" style="padding-right: 2px;" alt=""
                title="Create Campaign" />
                      <a routerLink="/mail/createCampaign" [routerLinkActive]="['sub_links']"
                        style="padding-left: 2px;">Create Campaign</a>
                    </li>
                    <li [routerLinkActive]="['link_active']">
                      <img src="./assets/newimages/all-campaigns.svg" class="img13" style="padding-right: 2px;" alt=""
                title="All Campaigns" />
                      <a routerLink="/mail/allcampaigns" [routerLinkActive]="['sub_links']"
                        style="padding-left: 2px;">All Campaigns</a>
                    </li>
                    <li [routerLinkActive]="['link_active']">
                      <img src="./assets/newimages/mail-templates-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="Mail Templates" />
                      <a routerLink="/mail/email" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">Email
                        Tempates</a>
                    </li>
                    <li [routerLinkActive]="['link_active']">
                      <img src="./assets/newimages/contacts.svg" class="img13" style="padding-right: 2px;" alt=""
                title="Contacts" />
                      <a routerLink="/mail/contact-list" [routerLinkActive]="['sub_links']"
                        style="padding-left: 2px;">Contacts</a>
                    </li>
                    <li [routerLinkActive]="['link_active']">
                      <img src="./assets/newimages/senders.svg" class="img13" style="padding-right: 2px;" alt=""
                title="Senders" />
                      <a routerLink="/mail/sender" [routerLinkActive]="['sub_links']"
                        style="padding-left: 2px;">Senders</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- CAMPAIGN  -->
          </ul>
        </div>
      </div>
    </div>

    <!-- My Team -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(42)" [routerLinkActive]="['link_active']" (click)="toggleSideBar()">
      <a class="dashboard-tab-item" routerLink="/contacts" title="Contacts" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive">
        <img src="./assets/newimages/contacts.svg" alt="" />
        <p>{{ getMenuName(42) | titlecase }}</p>
      </a>
    </div>

    <!-- Enquiries -->
    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(43)" [routerLinkActive]="['link_active']" (click)="toggleSideBar()">
      <a class="dashboard-tab-item" routerLink="/enquiries" title="Enquiries" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive">
        <img src="./assets/newimages/enquiry-menu.svg" alt="" />
        <p>{{ getMenuName(43) | titlecase }}</p>
      </a>
    </div>

    <!-- Calendar -->
    <div class="dashboard-tab-container" (click)="toggleSideBar()" *ngIf="checkingIdMenu(46)">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);"  data-toggle="collapse"
        data-target="#Appointment" aria-expanded="true" aria-controls="Appointment">
        <img src="./assets/images/calendar-w.svg" alt="" title="Appointment" />
        <p>Calendar</p>
      </a>
      <div id="Appointment" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li [routerLinkActive]="['link_active']">
             
              <img src="./assets/newimages/leads-dashboard-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="Dashboard" />
                <a routerLink="/appointments" style="padding-left: 2px;" [routerLinkActive]="['sub_links']" >Dashboard</a>
            </li>
            <li  [routerLinkActive]="['link_active']">
              <img src="./assets/images/my-calendar.svg" class="img13" style="padding-right: 2px;" alt=""
                title="My calendar" />
              <a routerLink="/calendar" [routerLinkActive]="['sub_links']" style="padding-left: 2px;"> My calendar</a>
            </li>
            <li  [routerLinkActive]="['link_active']">
              <img src="./assets/images/automated-calls.svg" class="img16" style="padding-right: 2px;" alt=""
                title="Auto Call/SMS" />
              <a routerLink="/auto-call-sms" [routerLinkActive]="['sub_links']" style="padding-left: 2px;"> Auto Call/SMS</a>
            </li>

          </ul>
        </div>
      </div>
    </div>


    <!-- Recruiter  -->
     <!-- <div class="dashboard-tab-container" [routerLinkActive]="['link_active']" (click)="toggleSideBar()" *ngIf="checkingIdMenu(45)" >
      <a class="dashboard-tab-item" routerLink="/recruiter" title="Contacts" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive">
        <img src="./assets/newimages/contacts.svg" alt="" />
        <p>Recruiter</p>
      </a>
    </div> -->


    <!-- Administration -->

    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(11)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item accordion-button collapsed" href="javascript:void(0);" data-toggle="collapse"
        data-target="#administration" aria-expanded="true" aria-controls="administration">
        <img style="cursor: pointer" src="./assets/newimages/admin.svg" alt="" title="Administration" />
        <p>{{ getMenuName(11) | titlecase }}</p>
      </a>

      <div id="administration" class="collapse in" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <ul id="tog-collapse">
            <li *ngIf="checkingIdMenu(13)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/company-managment-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />
              <a routerLink="/company" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive" class="img13"
                style="padding-left: 2px;">{{ getMenuName(13) |
                titlecase }}</a>
            </li>
            <li *ngIf="checkingIdMenu(12)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/agents-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a [routerLink]="['/agents']" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive"
                style="padding-left: 2px;">{{
                getMenuName(12) | titlecase }}</a>
            </li>
            <li *ngIf="checkingIdMenu(15)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/email-campaigns-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a href="javascript:void(0)" style="padding-left: 2px;">{{ getMenuName(15) | titlecase }}</a>
            </li>
            <li *ngIf="checkingIdMenu(16)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/email-campaigns-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a href="javascript:void(0)" style="padding-left: 2px;">{{ getMenuName(16) | titlecase }}</a>
            </li>
            <li *ngIf="checkingIdMenu(17)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/email-campaigns-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a href="javascript:void(0)" style="padding-left: 2px;">{{ getMenuName(17) | titlecase }}</a>
            </li>
            <!-- <li *ngIf="checkingIdMenu(24)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/auto-calling-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a routerLink="/infoMarketing" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{
                getMenuName(24)
                }}</a>
            </li> -->
            <!-- <li *ngIf="checkingIdMenu(27)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/auto-sms-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a routerLink="/autoSms" [routerLinkActive]="['sub_links']" style="padding-left: 2px;">{{
                getMenuName(27)
                }}</a>
            </li> -->
            <li *ngIf="checkingIdMenu(18)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/mail-templates-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a routerLink="/templates" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive"
                style="padding-left: 2px;">{{ getMenuName(18)
                |
                titlecase }}</a>
            </li>
            <li *ngIf="checkingIdMenu(19)" [routerLinkActive]="['link_active']">
              <img src="./assets/newimages/call-logs-icon.svg" class="img13" style="padding-right: 2px;" alt=""
                title="University Hub" />

              <a routerLink="/calllogs" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive"
                style="padding-left: 2px;">{{ getMenuName(19)
                |
                titlecase }}</a>
            </li>

          </ul>
        </div>
      </div>
    </div>

    <div class="dashboard-tab-container" *ngIf="checkingIdMenu(4)" (click)="toggleSideBar()">
      <a class="dashboard-tab-item" href="javascript:void(0);" [routerLinkActive]="['link_active']">
        <img src="./assets/newimages/analytics.png" alt="" title="Analytics" />
        <p>{{ getMenuName(4) | titlecase }}</p>
      </a>
    </div>
    
    <!-- DOCUMENTATION -->
    <div class="dashboard-tab-container" [routerLinkActive]="['link_active']" (click)="toggleSideBar()">
      <a class="dashboard-tab-item" routerLink="/documentation" title="Documentation" [routerLinkActive]="['sub_links']" #rlRef="routerLinkActive">
        <img src="./assets/img/document (1).svg" alt="" />
        <p>Documentation</p>
      </a>
    </div>

  </div>
</div>
<div class="version" [ngStyle]="{'left': show ? '5px' : '20px' }">Version 1.2.5</div>