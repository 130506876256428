import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valuehelper'
})
export class ValuehelperPipe implements PipeTransform {

  transform(value: any[], val: any): any {
    // 
    // 
    if (value != null) {
      
      if (value.find(data => data['feedBackNumber'] === +val)) {
        return value.find(data => data['feedBackNumber'] === +val)['value']
      } else {
        return val
      }
    }
    return val;
  }

}
