import { MapsAPILoader } from '@agm/core';
import { Component, ContentChild, ElementRef, NgZone, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit, AfterViewInit {
  public lat = 28.7041;
  public lng = 77.1025;
  public zoom: number = 15;
  public type: string;
  @ViewChild('search', { static: true }) search: HTMLInputElement;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  public address: string;
  private geoCoder;

  ngAfterViewInit() {

  }

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private ngbModal: NgbModal,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.lat = 28.7041;
    this.lng = 77.1025;
    this.geoCoder = new google.maps.Geocoder();
    
    this.sharedService.saveAddress.subscribe(data => {
      if (data && data['latlng'] != null) {
        this.lng = data['latlng']['lng'];
        this.lat = data['latlng']['lat'];
        this.type = data['types'];
        this.address = data['address']
      } else {
        this.type = data['types'];
      }
    })

  }
  dismiss() {
    this.ngbModal.dismissAll();
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      
      
      if (status === 'OK') {
        if (results[0]) {
          // this.zoom = 12;
          
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  changeLocation(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    
    this.getAddress(this.lat, this.lng);
  }

  autocompleteFocus(search) {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      const autocomplete = new google.maps.places.Autocomplete(search, { types: ['geocode'] });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  handleAddressChange(address) {
    this.lat = address.geometry.location.lat();
    this.lng = address.geometry.location.lng();
    this.address = address.formatted_address;
  }

  saveAddress() {
    
    if (this.type === 'ticket') {
      this.sharedService.saveAddress.next({ latlng: { lat: this.lat, lng: this.lng }, address: this.address, types: this.type });
      
    }
    // if (this.type === 'agent') {
    //   this.sharedService.saveAddress.next({ latlng: { lat: this.lat, lng: this.lng }, address: this.address, types: this.type });
    // }
    this.ngbModal.dismissAll();
  }


}
