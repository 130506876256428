<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{
  error
}}</ngb-alert>
<div class="text-left p-3 mt-2">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="right-content-wrapper">
  <div class="add-ticket-right-content-container">
    <form
      style="display: contents"
      (ngSubmit)="submit(f.value, f.valid, f)"
      #f="ngForm"
    >
      <input
        type="file"
        #profile
        style="display: none"
        accept="image/*"
        (change)="uploadFiletoServer($event, 'profile')"
      />
      <div class="right-content-right-box">
        <div class="right-box-header">
          <div class="header-left-content">
            <div class="header-content-box bor-none">
              <p>{{ ticketid }}</p>
            </div>
            <div>
              <span class="status-{{ statusselected }}" style="display: inline">
                {{ statusselected | requeststatus }}
              </span>
            </div>
          </div>
          <div class="header-right-content">
            <div
              class=""
              (click)="open(content, 'mail')"
              style="cursor: pointer"
            >
              <img
                ngbTooltip="Mail view"
                placement="top"
                tooltipClass="my-custom-class"
                src="../../../../assets/img/email-w.png"
              />
            </div>

            <!-- <div
              class="my-1 mx-2"
              style="display: flex; align-items: center; xwidth: 94%"
            >
              <button
                class="btn btn-md btn-secondary ml-2"
                (click)="isMail = true"
                type="submit"
              >
                {{ isUpdate ? "Send By Mail" : "Submit By Mail " }}
              </button>
              <button class="btn btn-md btn-primary ml-2" type="submit">
                {{ isUpdate ? "Update" : "Submit" }}
              </button>
            </div> -->

            <!-- <div
              class="downolad-icon"
              (click)="uploadFile(file)"
              style="cursor: pointer"
            >
              <img src="../../../../assets/img/upload.png" />
            </div> -->
            <input
              type="file"
              name="file"
              #file
              style="display: none"
              (change)="uploadFiletoServer($event, 'attachments')"
            />
            <!-- <div style="cursor: pointer">
              <div mat-button [matMenuTriggerFor]="menu">
                <img src="../../../../assets/img/paper-clip.png" />
              </div>
              <mat-menu #menu="matMenu">
                <div
                  mat-menu-item
                  *ngIf="attachments.length === 0"
                  style="padding: 10px; line-height: 15px; height: auto"
                >
                  No Attachments
                </div>

                <div
                  mat-menu-item
                  *ngFor="let item of attachments; let items = index"
                  style="
                    padding: 0;
                    line-height: 15px;
                    height: auto;
                    padding-bottom: 10px;
                  "
                >
                  <a href="{{ item['downloadUrl'] }}" target="_blank">
                    {{ item["name"] }}
                  </a>
                </div>
              </mat-menu>
            </div> -->
          </div>
        </div>
        <div class="right-box-main-content">
          <div class="right-content-add-detail-wrapper det-wrap">
            <div class="card add-ticket-gap">
              <p>Details</p>
              <div>
                <label for="reportedby" class="form-label">Reported By</label>
                <input
                  type="text"
                  class="form-control"
                  name="reportedby"
                  [(ngModel)]="ReportedBy"
                  required
                  #name="ngModel"
                  [ngClass]="{
                    'is-invalid': !name.valid && f.submitted,
                    'is-valid': name.valid && name.touched
                  }"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="!name.valid && f.submitted"
                >
                  please select valid value
                </div>

                <label for="assignement group" class="form-label"
                  >Assignment Group</label
                >
                <ng-select
                  name="assignmentgroup"
                  (change)="ongroupselect($event)"
                  [(ngModel)]="selectedgroup"
                  required
                  [clearable]="false"
                  #assignmentgroups="ngModel"
                  [ngClass]="{
                    'ng-submit': !assignmentgroups.valid && f.submitted,
                    'is-valid':
                      assignmentgroups.valid && assignmentgroups.touched
                  }"
                >
                  <ng-option
                    *ngFor="let item of assignmentgroup"
                    [value]="item.id"
                    >{{ item["name"] }}</ng-option
                  >
                </ng-select>
                <div
                  class="invalid-feedback"
                  *ngIf="!assignmentgroups.valid && f.submitted"
                >
                  please select valid value
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <label for="Assigned to" class="form-label"
                    >Assigned To</label
                  >
                  <mat-icon
                    *ngIf="latlng && agentLocation"
                    (click)="mapContentOpen('directions')"
                    style="cursor: pointer"
                    >location_on</mat-icon
                  >
                </div>
                <ng-select
                  [(ngModel)]="selectedassigned"
                  name="assignedto"
                  (change)="onuserselect($event)"
                  required
                  #assigned="ngModel"
                  [clearable]="true"
                  [ngClass]="{
                    'ng-submit': !assigned.valid && f.submitted,
                    'is-valid': assigned.valid && assigned.touched
                  }"
                >
                  <ng-option
                    *ngFor="let item of assignedto"
                    [value]="item.name"
                    >{{ item["name"] }}</ng-option
                  >
                </ng-select>
                <div
                  class="invalid-feedback d-block"
                  *ngIf="!assigned.valid && f.submitted"
                >
                  please select valid value
                </div>
                <label for="Assigned to" class="form-label">Status</label>
                <ng-multiselect-dropdown
                [data]="status"
                [settings]="dropdownSettings"
                  name="status"
                  [(ngModel)]="statusselected"
                  required
                  closeDropDownOnSelection="true"
                ></ng-multiselect-dropdown>
                <ng-template
                  #optionsTemplate
                  let-item
                  let-option="option"
                  let-id="id"
                  let-isSelected="isSelected"
                >
                  {{ option | requeststatus }}
                </ng-template>
                <label for="priority" class="form-label">Priority</label>
                <ng-multiselect-dropdown
                [data]="priority"
                [settings]="dropdownSettings"
                  name="priority"
                  [(ngModel)]="selectedpriority"
                  required
                ></ng-multiselect-dropdown>
                <label for="urgency" class="form-label">Urgency</label>
                <ng-multiselect-dropdown
                [data]="urgency"
                [settings]="dropdownSettings"
                  name="urgency"
                  [(ngModel)]="selectedurgency"
                  required
                ></ng-multiselect-dropdown>

                <label for="creadteddate" class="form-label"
                  >Created Date</label
                >
                <input
                  name="createddate"
                  type="text"
                  class="form-control"
                  [(ngModel)]="createdDate"
                  required
                  [disabled]="true"
                />
                <label for="updateddate" class="form-label">Updated Date</label>
                <input
                  name="updateddate"
                  type="text"
                  class="form-control"
                  [(ngModel)]="updatedDate"
                  required
                  [disabled]="true"
                />
                <div class="form-group">
                  <label for="creadteddate" class="form-label"
                    >Follow Up Date</label
                  >
                  <div
                    class="input-group"
                    style="border: 1px solid #ced4da; height: 34px"
                  >
                    <!-- <app-date-time-picker
                    [(ngModel)]="followupdate"
                    name="followupdate"
                    class="rounded"
                  ></app-date-time-picker> -->
                    <input
                      class="form-control"
                      placeholder="yyyy-mm-dd"
                      name="followupdate"
                      [(ngModel)]="followupdate"
                      ngbDatepicker
                      #d1="ngbDatepicker"
                      style="border: 0px"
                      [placement]="'top-right'"
                    />
                    <div class="input-group-append">
                      <button class="btn" (click)="d1.toggle()" type="button">
                        <i
                          class="fa fa-calendar"
                          aria-hidden="true"
                          style="color: #999"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <mat-tab-group [(selectedIndex)]="selectIndex">
            <mat-tab label="Issue Details">
              <div class="main-content-chat-section chat-sec" id="chatDiv">
                <div class="profile-detail-container">
                  <!-- <div class="profile-image-wrapper">
										<div class="profile-image-container">
											<img src="./assets/img/issue-detail.svg" alt="" />
										</div>
									</div> -->
                  <div style="width: 100%">
                    <div class="nav nav-tabs message-box-tab-wrapper">
                      <p class="nav-link message-box-tab">Summary</p>
                    </div>
                    <input
                      class="add-ticket-detail-input form-control"
                      type="text"
                      placeHolder="Enter the issue summary"
                      name="detail"
                      [(ngModel)]="detial"
                      min="5"
                      required
                      [disabled]="isUpdate"
                      #summary="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          summary.invalid && f.submitted && !isUpdate,
                        'is-valid': summary.valid && summary.touched
                      }"
                    />
                    <!-- <div
							class="valid-feedback"
							*ngIf="summary.valid && summary.touched"
						>
							looks good!
						</div> -->
                    <div
                      class="invalid-feedback"
                      *ngIf="!summary.valid && f.submitted"
                    >
                      Please Enter Valid Summary
                    </div>
                  </div>
                </div>

                <div class="profile-detail-container">
                  <div
                    class="message-input-with-profile-pic"
                    style="padding: 0; width: 100%"
                  >
                    <!-- <div class="profile-image-wrapper">
											<div class="profile-image-container">
												<img
													class="profile-img"
													src="./assets/img/profile-pic.png"
													alt=""
												/>
											</div>
										</div> -->
                    <div style="width: 100%">
                      <div
                        class="message-input-main-wrapper"
                        *ngIf="!ticketChat"
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                          "
                        >
                          <div style="width: 100%">
                            <div
                              style="
                                position: relative;
                                display: flex;
                                align-items: center;
                                height: 30px;
                              "
                            >
                              <div class="nav nav-tabs message-box-tab-wrapper">
                                <p class="nav-link message-box-tab">
                                  Description
                                </p>
                              </div>
                            </div>
                            <div class="tab-content" style="height: 120px">
                              <p
                                style="
                                  font-size: 14px;
                                  color: #000000;
                                  font-weight: 400;
                                  line-height: 1.5;
                                "
                              >
                                <textarea
                                  name="description"
                                  class="add-ticket-detail-input form-control"
                                  style="
                                    height: auto;
                                    margin: 0;
                                    resize: none;
                                    padding: 10px;
                                    outline: none;
                                  "
                                  cols="75"
                                  rows="3"
                                  minlength="5"
                                  placeHolder="Enter the issue description"
                                  [(ngModel)]="desc"
                                  required
                                  [disabled]="isUpdate"
                                  #description="ngModel"
                                  [ngClass]="{
                                    'is-invalid':
                                      description.invalid && f.submitted,
                                    'is-valid':
                                      description.valid && description.touched
                                  }"
                                ></textarea>
                              </p>

                              <div
                                class="invalid-feedback d-block"
                                *ngIf="description.invalid && f.submitted"
                              >
                                Please Enter Valid description
                              </div>
                              <!-- </p> -->
                              <!-- <p style="font-size: 14px;color:#000000; font-weight: 400;padding:15px; line-height: 1.5;">
															But i must explain to you how all this mistaken idea od denauncing pleasure Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus dignissimos sint fugit deleniti ut, facilis aliquid soluta amet adipisci iusto? Lorem ipsum dolor sit amet,
															consectetur adipisicing elit. Voluptatem dolore delectus error, minus asperiores ipsum.</p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="ticketChat">
                        <div class="nav nav-tabs message-box-tab-wrapper">
                          <p class="nav-link message-box-tab">
                            Chat Conversation:
                          </p>
                        </div>
                        <div
                          style="
                            height: 150px;
                            overflow-y: auto;
                            border: 1px solid #ced4da;
                            padding: 12px;
                            border-radius: 6px;
                          "
                        >
                          <div *ngFor="let item of ticketChat" class="my-2">
                            <div>
                              <span class="mx-2"
                                >({{
                                  item["timestamp"] | date: "medium"
                                }}) :
                              </span>
                              <span>{{
                                item["author"] | titlecase | nameformatting
                              }}</span>
                              <span class="ml-2">: {{ item["body"] }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="isUpdate"
                        class=""
                        style="border-radius: 6px; color: #a19d9d"
                      >
                      <div class="nav nav-tabs message-box-tab-wrapper">
                          <p for=""  class="nav-link message-box-tab"> Last Update </p>
                        </div>
                        <div>
                          <textarea
                            type="text"
                            class="form-control"
                            cols="75"
                            rows="3"
                            name="lastupdate"
                            [disabled]="true"
                            [(ngModel)]="lastUpdate"
                            required
                            style="resize: none; font-size: 14px; height: auto"
                          ></textarea>
                        </div>
                        <!-- <hr style="margin: 0" /> -->
                      </div>

                      <div >
                        <div
                              style="
                                position: relative;
                                display: flex;
                                align-items: center;
                                margin-top: 10px;
                              "
                            >
                              <div class="nav nav-tabs message-box-tab-wrapper">
                                <p class="nav-link message-box-tab">
                                  Latest Activity
                                </p>
                              </div>
                            </div>
                        <div class="">
                          <textarea
                            type="text"
                            class="form-control"
                            cols="75"
                            rows="3"
                            name="latestupdate"
                            [(ngModel)]="latestupdate"
                            required
                            #activity="ngModel"
                            [ngClass]="{
                              'is-invalid': activity.invalid && f.submitted,
                              'is-valid': activity.valid && activity.touched
                            }"
                            style="resize: none; font-size: 14px; height: auto"
                            placeholder="Enter the Issue Activity"
                          ></textarea>
                          <div
                            class="invalid-feedback d-block"
                            *ngIf="activity.invalid && f.submitted"
                          >
                            Please Enter Valid Latest Activity
                          </div>
                        </div>
                        <div
                              style="
                                position: relative;
                                display: flex;
                                align-items: center;
                                height: 30px;
                              "
                            >
                              <div class="nav nav-tabs message-box-tab-wrapper mt-2">
                                <p class="nav-link message-box-tab" style="line-height: 150%;">
                                  Attachments
                                </p>
                              </div>
                            </div>
                        <div class="d-flex justify-content-start">
                          <div class="mt-1">
                            <button
                              class="btn btn-secondary btn-sm"
                              type="button"
                              (click)="uploadFile(file)"
                            >
                              <img
                                ngbTooltip="Attachments"
                                placement="top"
                                tooltipClass="my-custom-class"
                                src="../../../../assets/img/attach-w.svg"
                                class="img13"
                              />
                            </button>
                          </div>
                          <div>
                            <div
                              *ngIf="attachments.length === 0"
                              style="
                                padding: 10px;
                                font-size: 13px;
                                line-height: 15px;
                                height: auto;
                                font-family: 'Nunito', sans-serif;
                              "
                            >
                              No Attachments
                            </div>
                            <div
                              *ngFor="let item of attachments; let i = index"
                              class="attach-file"
                            >
                              <div
                                (click)="attachments.splice(i, 1)"
                                class="close-icon"
                              >
                                <img
                                  src="../../../../assets/img/close.svg"
                                  style="width: 10px"
                                />
                              </div>
                              <img
                                src="assets/img/{{
                                  item['name'] | attachments
                                }}.svg"
                                alt=""
                                style="width: 16px"
                              />
                              <a
                                href="{{ item['downloadUrl'] }}"
                                target="_blank"
                              >
                                {{
                                  item["name"].length > 15
                                    ? item["name"].slice(0, 15)
                                    : item["name"]
                                }}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div style="clear: both"></div>
                        <div class="d-flex justify-content-end mt-4 mb-2 mr-5">
                          <button
                            class="btn btn-md btn-secondary ml-2"
                            (click)="isMail = true"
                            type="submit"
                          >
                            <!-- [disabled]="!(email.value !== '')" -->
                            {{ isUpdate ? "Send By Mail" : "Submit By Mail " }}
                          </button>
                          <button
                            class="btn btn-md btn-primary ml-2"
                            type="submit"
                          >
                            <!-- [disabled]="!f.valid" -->
                            {{ isUpdate ? "Update" : "Submit" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- <label for=""></label> -->
                </div>

                <!-- <div class="">
		
								</div> -->
                <!-- <div>hello</div> -->
              </div>
            </mat-tab>
            <mat-tab label="History">
              <div>
                <div style="margin-top: 10px; margin-left: 10px" class="my-2">
                  <div class="profile-image-wrapper"></div>
                </div>
                <div class="history-description-list">
                  <div
                    class="profile-detail-container bor-none align-items-start"
                    *ngFor="
                      let item of tickethistory | slice: 0:loadMoreHistory;
                      let i = index
                    "
                    style="
                      border-bottom: 1px solid #ebebeb;
                      padding-bottom: 10px;
                      margin-bottom: 10px;
                    "
                  >
                    <div class="profile-image-wrapper">
                      <img
                        class="img38"
                        style="border-radius: 50%"
                        src="{{
                          item['UpdatedBY'] | profilePhoto: agents:customer
                        }}"
                      />
                      <!-- <div 
												class="profile-image-container"
												style="font-size: 24px"
											>
												{{ item['UpdatedBY'].trim().slice(0, 1) | titlecase }}
											</div> -->
                    </div>

                    <div style="width: 96%; padding-left: 10px">
                      <span
                        class="align-middle float-right status-{{
                          item['RequestStatus']
                        }}"
                        >{{ item["RequestStatus"] | requeststatus }}</span
                      >
                      <span class="agent-name xtext-capitalize">
                        {{
                          item["UpdatedBY"]
                            | updatedbyName: agents:customer:ReportedBy
                            | titlecase
                        }} </span
                      ><br />
                      <span class="agent-update mt-2 text-capitalize">
                        {{ item["UpdatedDate"] | date: "medium" }}
                      </span>

                      <div style="margin-top: 10px">
                        <span
                          class="agent-name-message-text text-capitalize p-0 inline-block"
                          #latest
                          [attr.content]="'more'"
                        >
                          {{ item["LatestUpdate"].slice(0, 100) }}
                        </span>
                        <ng-container *ngIf="item['LatestUpdate'].length > 100">
                          <span
                            >...<button
                              type="button"
                              class="readmore"
                              (click)="increaseText(i, item['LatestUpdate'])"
                            >
                              {{ latest.getAttribute("content") }}
                            </button>
                          </span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div
                    class="my-1"
                    style="margin: 0 auto; width: 100%; text-align: center"
                  >
                    <button
                      *ngIf="
                        tickethistory.length > 5 &&
                        loadMoreHistory < tickethistory.length
                      "
                      class="btn btn-primary btn-sm"
                      type="button"
                      (click)="loadMoreHistory = loadMoreHistory + 5"
                    >
                      Load More
                    </button>
                  </div>
                </div>
              </div></mat-tab
            >
            <mat-tab label="Customer Details">
              <div
                id="chatSideBar"
                class="main-content-ticket-info-boxes background-change tick-info"
              >
                <div
                  class="card add-ticket-gap"
                  style="margin-top: 10px; padding-top: 0"
                >
                  <div class="row">
                    <div class="col-md-3" style="border: 0px solid red">
                      <div class="row">
                        <div
                          class="col-md-12 col-sm-12"
                          style="border: 0px solid red"
                        >
                          <div class="mt-3">
                            <div class="user-profile-block">
                              <div class="user-head-bg">
                                <div
                                  class="user-photo"
                                  style="cursor: pointer"
                                  (click)="uploadFile(profile)"
                                >
                                  <img
                                    class="img38"
                                    style="width: 65px; border-radius: 50%"
                                    src="{{
                                      customer['profilePhoto']['downloadUrl']
                                    }}"
                                  />
                                </div>
                              </div>
                              <div class="user-data">
                                <a
                                  style="
                                    color: #6e6d6d;
                                    font-family: 'Nunito', sans-serif !important;
                                    cursor: pointer;
                                  "
                                  >Upload</a
                                >
                              </div>
                              <div class="user-last-contact">
                                Last Contacted<br />
                                <div
                                  class="form-control"
                                  style="
                                    height: 2rem;
                                    margin-top: 0px;
                                    text-align: center;
                                    border: 0;
                                    background: none;
                                  "
                                  id="userlast"
                                >
                                  {{
                                    customer["lastContacted"]
                                      ? customer["lastContacted"]
                                      : ""
                                  }}
                                </div>
                                <!-- <input
                                  type="text"
                                  name="userlastcontacted"
                                  [(ngModel)]="customer['lastContacted']"
                                  [disabled]="true"
                                /> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4" style="border: 0px solid red">
                      <div class="row">
                        <div
                          class="col-md-12 col-sm-12"
                          style="border: 0px solid red"
                        >
                          <div class="">
                            <label for="customername" class="">Name</label>
                            <input
                              type="text"
                              class="form-control m-0"
                              style="height: 2rem"
                              id="customername"
                              [(ngModel)]="ReportedBy"
                              name="customername"
                            />
                          </div>
                        </div>

                        <div
                          class="col-md-12 col-sm-12"
                          style="border: 0px solid red"
                        >
                          <label for="reportedby" class="">Phone Number</label>
                          <button
                            class="btn"
                            style="margin-top: 10px; padding: 0 10px"
                            type="button"
                          >
                            <!-- (click)="sendSms(content, item['id'])" -->

                            <img
                              ngbTooltip="Message"
                              placement="top"
                              tooltipClass="my-custom-class"
                              (click)="open(content1, 'phone')"
                              class="search-icon-img"
                              style="width: 16px"
                              src="./assets/img/message-b.svg"
                            />
                          </button>
                          <input
                            type="text"
                            class="form-control m-0"
                            style="height: 2rem"
                            name="userphonenumber"
                            (ngModelChange)="getCustomerByPhone($event)"
                            [(ngModel)]="customer['phoneNumber']"
                          />
                        </div>
                        <div
                          class="col-md-12 col-sm-12"
                          style="border: 0px solid red"
                        >
                          <div class="">
                            <label for="usermailid" class=""
                              >User Mail id</label
                            >
                            <input
                              type="text"
                              class="form-control m-0"
                              style="height: 2rem"
                              id="usermailid"
                              name="usermailid"
                              [(ngModel)]="customer['mail']"
                              email="true"
                              (ngModelChange)="customerSearch(mail.value)"
                              #mail="ngModel"
                              [required]="isMail"
                              [ngClass]="{
                                'is-invalid': mail.invalid,
                                'is-valid': mail.valid && mail.touched && isMail
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="mail.value === '' && f.submitted && isMail"
                            >
                              please enter a valid mail
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5" style="border: 0px solid red">
                      <div class="">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <label for="userlast">Address</label>
                          <mat-icon
                            ngbTooltip="Location"
                            placement="bottom"
                            tooltipClass="my-custom-class"
                            (click)="mapContentOpen('customer')"
                            style="cursor: pointer; color: #999"
                            >location_on</mat-icon
                          >
                        </div>
                        <textarea
                          rows="5"
                          cols="35"
                          [ngModel]="address"
                          name="address"
                          class="form-control"
                        ></textarea>
                      </div>

                      <!-- <label for="userlast" class="" style="display: block"
                        >Prefered Contact</label
                      >
                      <select
                        name="preferedcontact"
                        id="userlast"
                        class="form-control form-select p-1"
                        style="
                          height: 2.2rem;
                          font-size: 14px;
                          width: 100%;
                          border: 1px solid #ced4da;
                          display: none;
                        "
                        [(ngModel)]="customer['preferedContact']"
                      >
                        <option [value]="'phone'">Phone</option>
                        <option [value]="'mail'">Mail</option>
                        <option [value]="'chat'">Chat</option>
                      </select> -->
                      <label for="userlast" class="" style="display: block"
                        >Prefered Contact</label
                      >
                      <div
                        class="custom-control custom-radio custom-control-inline"
                      >
                        <input
                          type="radio"
                          value="chat"
                          id="customRadioInline1"
                          name="preferedcontact"
                          [(ngModel)]="customer['preferedContact']"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          style="margin: 0; line-height: 25px"
                          for="customRadioInline1"
                          >Chat</label
                        >
                      </div>
                      <div
                        class="custom-control custom-radio custom-control-inline"
                      >
                        <input
                          type="radio"
                          value="phone"
                          id="customRadioInline2"
                          name="preferedcontact"
                          [(ngModel)]="customer['preferedContact']"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label"
                          style="margin: 0; line-height: 25px"
                          for="customRadioInline2"
                          >Phone</label
                        >
                      </div>
                      <div
                        class="custom-control custom-radio custom-control-inline"
                      >
                        <input
                          type="radio"
                          id="customRadioInline3"
                          name="preferedcontact"
                          [(ngModel)]="customer['preferedContact']"
                          class="custom-control-input"
                          value="mail"
                        />
                        <label
                          class="custom-control-label"
                          style="margin: 0; line-height: 25px"
                          for="customRadioInline3"
                          >Mail</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ticket-status-list-box">
                  <div
                    class="status-box-header"
                    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                  >
                    <div class="header-12">Five most recents</div>
                    <div>
                      <button
                        class="right-arrow-btn"
                        type="button"
                        style="display: none"
                      >
                        <img
                          class="rotate-img"
                          src="./assets/img/right-arrow.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>

                  <div
                    class="status-list-item"
                    *ngFor="let item of customerTickets"
                  >
                    <div>
                      <div>
                        <p class="agent-status float-right">
                          <span class="status-{{ item['RequestStatus'] }}">{{
                            item["RequestStatus"] | requeststatus
                          }}</span>
                        </p>
                      </div>

                      <span
                        class="agent-name"
                        style="cursor: pointer"
                        (click)="changeTab()"
                        [routerLink]="['/tickets/ticket-view', item['id']]"
                        >{{ item["RequestId"] }}</span
                      >
                      <p class="agent-update">
                        {{ item["CreatedDate"] | date: "medium" }}
                      </p>
                      <p class="agent-name-message-text">
                        {{
                          item["RequestDetail"].length > 50
                            ? item["RequestDetail"]
                            : item["RequestDetail"]
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <div class="mt-3"></div>
        </div>
      </div>
    </form>
  </div>
</div>
 
<ng-template #content let-modal>
  <div class="mail-pop">
    <div class="modal-header">
      <h4 class="modal-title">Mail View</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [innerHTML]="emailView | safeHtml"></div>
  </div>
</ng-template>
<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Send SMS</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 14px; height: 220px">
    <div>
      <div class="my-2">
        <label>Selected Number</label>
        <div class="pt-1 pb-2">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="customer['phoneNumber']"
          />
        </div>
        <div class="my-2">
          <label>Message</label>
          <textarea
            class="form-control pb-2"
            [(ngModel)]="smsMessage"
          ></textarea>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" (click)="sendSms()">Send SMS</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
