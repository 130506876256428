<div class="text-left mt-2" style="padding: 0 2rem;">
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="containers">
  <!-- <div class="content-containers" style="margin-left: 30px; margin-right: 30px"> -->
  <!-- <input
      type="file"
      (change)="convertFile($event)"
      style="display: none"
      #uploadFile
      accept=".xlsx"
    /> -->
  <!-- <div>
      <div class="d-flex float-left">
        <div>
          <button
            ngbTooltip="Attachments"
            placement="top"
            tooltipClass="my-custom-class"
            class="btn btn-secondary btn-sm float-left"
            (click)="uploadFile.click()"
          >
            <img src="../../../../assets/img/attach-w.svg" class="img13" />
          </button>
          <div class="float-left ml-3">
            <div
              *ngIf="filePath"
              class="form-control"
              style="
                height: 16px;
                padding: 0px;
                background: none;
                font-size: 13px;
                color: #999;
                border: 0;
              "
            >
              {{ filePath }}
            </div>
           
            <div
              class="upload-msg-success"
              *ngIf="uploaded"
              style="color: green; font-size: 10px"
            >
              {{ insertCount + " " + "Contacts inserted" }}
            </div>
            <div class="upload-msg-error" *ngIf="error">
              {{ errorMessage }}
            </div>
          </div>

        </div>
      </div>
    </div> -->
  <!-- <div class="d-flex justify-content-end mt-3">
      <a
        ngbTooltip="Download Sample"
        placement="top"
        tooltipClass="my-custom-class"
        href="{{ downloadLink }}"
        class="btn btn-secondary btn-sm ml-2"
        ><img src="../../../../assets/img/download-w.svg" style="width: 13px"
      /></a>
      <button
        ngbTooltip="Start Calls"
        placement="top"
        tooltipClass="my-custom-class"
        class="btn btn-secondary btn-sm ml-2"
        (click)="startCallListener()"
        placement="top"
        ngbTooltip="Start Call"
      >
        <img
          src="../../../../assets/img/start-call-w.svg"
          style="width: 13px"
        />
      </button>
      <button
        ngbTooltip="Stop Calls"
        placement="top"
        tooltipClass="my-custom-class"
        class="btn btn-secondary btn-sm ml-2"
        (click)="stopCallListener()"
        placement="bottom"
        ngbTooltip="Stop Call"
      >
        <img src="../../../../assets/img/stop-call-w.svg" style="width: 13px" />
      </button>
    </div> -->
  <!-- </div> -->
  <div class="right-content-right-box-bottom-container">
    <div class="bottom-content-box-header-container">
      <div class="ticket-requested-list-container">
        <div class="requsted-list-heading-container">
          <div class="table-scroll data-table">
            <table class="table table-md xtable-bordered">
              <thead style="position: relative">
                <tr>
                  <td colspan="9" style="padding: 8px">
                    <div class="table-search-content indived-search">
                      <div class="searchall-parent">
                        <input
                          type="text"
                          class="form-control search-all"
                          [(ngModel)]="search"
                          placeholder="Search "
                        />
                      </div>
                      <div class="pagenation-right d-flex">
                        <!-- <select
                          class="form-select mx-2"
                          style="
                            background: #35475c;
                            color: #fff;
                            border: 1px solid #59758b;
                          "
                          [(ngModel)]="statusValue"
                          (change)="dropdownSelect()"
                        >
                          <option value="pending">Pending</option>
                          <option value="notanswered">Not Answered</option>
                          <option value="interested">Interested</option>
                          <option value="notinterested">Not Interested</option>
                        </select> -->
                        <ngb-pagination
                          [(page)]="page"
                          [pageSize]="pageSize"
                          [collectionSize]="callData.length"
                          [maxSize]="5"
                          [boundaryLinks]="true"
                          class="pagenation-right"
                        ></ngb-pagination>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr style="background: #eaf5fd; border-bottom: none">
                  <th *ngFor="let names of tableColumns">
                    {{ names | tabletitle }}
                    <img
                      *ngIf="names !== 'Actions'"
                      class="img7"
                      src="../../../../assets/img/sort-data.svg"
                      style="cursor: pointer"
                      (click)="sortOrder(names)"
                    />
                  </th>
                </tr>

                <tr
                  *ngFor="
                    let item of callData
                      | filter: search
                      | slice: (page - 1) * pageSize:page * pageSize
                      | sort: orderFeild:orderString;
                    let id = index
                  "
                >
                  <td *ngFor="let col of tableColumns">
                    <ng-container
                      *ngIf="
                        col !== 'Actions' &&
                        col !== 'RecordingURL' &&
                        col !== 'CalledDate' &&
                        col !== 'createdAt' &&
                        col !== 'CallStatus'
                      "
                    >
                      <span
                        *ngIf="col === 'Email'"
                        (click)="openMail(content2, item['Email'])"
                        style="cursor: pointer"
                      >
                        <img
                          ngbTooltip="Send Mail"
                          placement="top"
                          tooltipClass="my-custom-class"
                          alt=""
                          class="img15"
                          src="./assets/img/email-g.svg"
                        />&nbsp;
                        {{ col | tablevalue: item | textpipe | lowercase }}
                      </span>
                      <span *ngIf="col !== 'Email'">
                        {{ col | tablevalue: item | textpipe | lowercase }}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="col === 'Actions'">
                      <!-- {{ col | tablevalue: item }} -->
                      <!-- <img
                        (click)="deleteContact(item['id'])"
                        class="img7"
                        src="../../../../assets/img/delete.svg"
                        style="cursor: pointer; width: 13px"
                      /> -->
                      <!-- class="img-hover float-left" -->
                      <div class="d-flex align-items-center">
                        <img
                          (click)="showCallDetails(content1, item)"
                          class="img7 img-hover"
                          src="assets/img/view2.svg"
                          ngbTooltip="View Details"
                          placement="top"
                          tooltipClass="my-custom-class"
                        />
                        <ng-container *ngIf="item['RecordingURL']">
                          <i
                            class="fas fa-play img-hover"
                            style="color: #999999; font-size: 13px"
                            #icon
                            (click)="audioPlay(audio, icon)"
                          ></i>
                          <audio
                            style="height: 1.5rem; width: 14rem; display: none"
                            [src]="item['RecordingURL']"
                            #audio
                            controls
                            controlsList="nodownload"
                          ></audio>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="col === 'CalledDate' || col === 'createdAt'"
                    >
                      {{ item["createdAt"] | date: "medium" }}
                    </ng-container>
                    <ng-container *ngIf="col === 'CallStatus'">
                      <div class="callstatus">
                        <button
                          [ngClass]="{
                            currentstate:
                              item['Phase'] === null ||
                              item['Phase'] === 'called' ||
                              item['Phase'] === 'followup',
                            active:
                              item['Phase'] === 'demo' ||
                              item['Phase'] === 'demo-notinterested' ||
                              item['Phase'] === 'demo-followup' ||
                              item['Phase'] === 'MOU-Signed' ||
                              item['Phase'] === 'Payment-raised' ||
                              item['Phase'] === 'Payment-Received' ||
                              item['Phase'] === 'Invoice-Sent' ||
                              item['Phase'] === 'MOU-Sent' ||
                              item['Phase'] === 'prospect'
                          }"
                          (click)="dropdownSelect('pending')"
                          ngbTooltip="Called"
                          placement="top"
                          tooltipClass="my-custom-class"
                        >
                          1
                        </button>
                        <span>-</span>
                        <!-- item['Phase'] === 'called' || -->
                        <button
                          ngbTooltip="Demo"
                          placement="top"
                          tooltipClass="my-custom-class"
                          [ngClass]="{
                            active:
                              item['Phase'] === 'MOU-Signed' ||
                              item['Phase'] === 'MOU-Sent' ||
                              item['Phase'] === 'Payment-raised' ||
                              item['Phase'] === 'Payment-Received' ||
                              item['Phase'] === 'Invoice-Sent' ||
                              item['Phase'] === 'prospect',
                            currentstate:
                              item['Phase'] === 'demo' ||
                              item['Phase'] === 'demo-notinterested' ||
                              item['Phase'] === 'demo-followup'
                          }"
                          (click)="dropdownSelect('interested')"
                        >
                          2
                        </button>
                        <span>-</span>
                        <button
                          ngbTooltip="MOU"
                          placement="top"
                          tooltipClass="my-custom-class"
                          [ngClass]="{
                            active:
                              item['Phase'] === 'Payment-raised' ||
                              item['Phase'] === 'Payment-Received' ||
                              item['Phase'] === 'Invoice-Sent' ||
                              item['Phase'] === 'prospect',
                            currentstate:
                              item['Phase'] === 'MOU-Signed' ||
                              item['Phase'] === 'MOU-Sent'
                          }"
                          (click)="dropdownSelect('demo-interested')"
                        >
                          3
                        </button>
                        <span>-</span>
                        <!-- active: item['Phase'] === 'followup', -->
                        <button
                          ngbTooltip="Payment"
                          placement="top"
                          tooltipClass="my-custom-class"
                          [ngClass]="{
                            currentstate:
                              item['Phase'] === 'Payment-raised' ||
                              item['Phase'] === 'Payment-Received' ||
                              item['Phase'] === 'Invoice-Sent',
                            active: item['Phase'] === 'prospect'
                          }"
                          (click)="dropdownSelect('demo-interested')"
                        >
                          4
                        </button>
                        <span>-</span>
                        <!-- active: item['Phase'] === 'demo', -->
                        <button
                          ngbTooltip="Prospect"
                          placement="top"
                          tooltipClass="my-custom-class"
                          [ngClass]="{
                            active: item['Phase'] === 'prospect'
                          }"
                          (click)="dropdownSelect('demo-interested')"
                        >
                          5
                        </button>
                      </div>
                    </ng-container>
                    <!-- <ng-container *ngIf="col === 'Email'"> </ng-container> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Template For Modal To Display User Details -->
<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Overview</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="max-height: 480px">
    <div class="form-row">
      <div class="form-group col-md-4">
        <label>Name</label>
        <div class="font-weight-bold">
          {{
            currentUser["FirstName"] || "" + " " + currentUser["LastName"] || ""
          }}
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Email</label>
        <div class="font-weight-bold">{{ currentUser["Email"] }}</div>
      </div>
      <div class="form-group col-md-4">
        <label>Phone</label>
        <div class="font-weight-bold">
          +{{
            (currentUser["CountryCode"] || "") + currentUser["PhoneNumber"]
          }}
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label>City</label>
        <div class="font-weight-bold">{{ currentUser["City"] || "-" }}</div>
      </div>
      <div class="form-group col-md-4">
        <label>Channel</label>
        <div class="font-weight-bold">{{ currentUser["Channel"] || "-" }}</div>
      </div>
      <div class="form-group col-md-4">
        <label>Created Date</label>
        <div class="font-weight-bold">
          {{ currentUser["createdAt"] | date: "medium" }}
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Assigned To</label>
        <div class="font-weight-bold">{{ currentUser["Assignedto"] }}</div>
      </div>
    </div>
    <ng-container *ngIf="statusValue !== 'pending'">
      <hr />
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>No of Times Called</label>
          <div class="font-weight-bold">{{ currentUser["CallCount"] }}</div>
        </div>
        <div class="form-group col-md-4">
          <label>Last Called</label>
          <div class="font-weight-bold">
            {{
              currentUser["CalledDate"]
                ? (currentUser["CalledDate"] | date: "medium")
                : "-"
            }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Call Comments</label>
          <div class="font-weight-bold">
            {{ currentUser["Comments"] || "-" }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Last Called Agent</label>
          <div class="font-weight-bold">
            {{ currentUser["CalledBy"] || "-" }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Call Status</label><br />
          <div
            class="font-weight-bold"
            [ngClass]="{
              'status-inprogress': currentUser['CallStatus'] === 'pending',
              'status-closed':
                currentUser['CallStatus'] === 'notanswered' ||
                currentUser['CallStatus'] === 'notinterested',
              'status-open':
                currentUser['CallStatus'] === 'answered' ||
                currentUser['CallStatus'] === 'interested'
            }"
          >
            {{ currentUser["CallStatus"] }}
          </div>
        </div>
        <div
          class="form-group col-md-4"
          *ngIf="
            currentUser['Phase'] !== 'followup' &&
            currentUser['CallStatus'] === 'notinterested'
          "
        >
          <label>Demo Scheduled</label>
          <div class="font-weight-bold">
            {{ currentUser["DemoScheduled"] ? "Yes" : "No" }}
          </div>
        </div>
      </div>
      <div
        class="form-row"
        *ngIf="
          currentUser['Phase'] !== 'followup' &&
          currentUser['CallStatus'] === 'notinterested'
        "
      >
        <div class="form-group col-md-4">
          <label>Demo Presenter</label>
          <div class="font-weight-bold">
            {{ currentUser["Presenter"] || "-" }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Demo Date</label>
          <div class="font-weight-bold">
            {{
              currentUser["DateTime"]
                ? (currentUser["DateTime"] | date: "medium")
                : "-"
            }}
          </div>
        </div>
      </div>
    </ng-container>
    <hr />
    <div
      class="form-row"
      *ngIf="
        statusValue === 'demo-interested' ||
        statusValue === 'demo-notinterested'
      "
    >
      <div class="form-group col-md-4">
        <label>No of Demos</label>
        <div class="font-weight-bold">
          1
          <!-- {{ currentUser["Presenter"] || "-" }} -->
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Demo Status</label><br />
        <div
          class="font-weight-bold"
          [ngClass]="{
            'status-inprogress': currentUser['Status'] === 'notinterested',
            'status-open':
              currentUser['Status'] === null ||
              currentUser['Status'] === 'interested'
          }"
        >
          {{
            currentUser["Status"]
              ? (currentUser["Status"] | statusvalue)
              : "Interested"
          }}
        </div>
      </div>
      <div class="form-group col-md-4">
        <label>Demo Comments</label>
        <div class="font-weight-bold">
          {{ currentUser["DemoComments"] || "-" }}
        </div>
      </div>
      <div
        class="form-group col-md-4"
        *ngIf="
          currentUser['Phase'] !== 'demo-notinterested' &&
          currentUser['Phase'] !== 'demo-followup'
        "
      >
        <label>MOU Sent Date</label>
        <div class="font-weight-bold">
          {{
            currentUser["RequestSent"]
              ? (currentUser["RequestSent"] | date: "medium")
              : "-"
          }}
        </div>
      </div>
      <div
        class="form-group col-md-4"
        *ngIf="
          currentUser['Phase'] !== 'demo-notinterested' &&
          currentUser['Phase'] !== 'demo-followup'
        "
      >
        <label>MOU Signed Date</label>
        <div class="font-weight-bold">
          {{
            currentUser["SignedAt"]
              ? (currentUser["SignedAt"] | date: "medium")
              : "-"
          }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Send Mail</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="From" class="form-label">From</label>
        <input
          type="text"
          id="From"
          name="from"
          class="form-control"
          disabled
          [(ngModel)]="sendMails.from"
        />
      </div>
      <div class="form-group">
        <label for="To" class="form-label">To</label>
        <input
          type="text"
          id="To"
          name="to"
          class="form-control"
          disabled
          [(ngModel)]="sendMails.to"
        />
      </div>
      <div class="form-group">
        <label for="Subject" class="form-label">Subject</label>
        <input
          type="text"
          id="Subject"
          name="subject"
          class="form-control"
          [(ngModel)]="sendMails.subject"
        />
      </div>
      <div class="form-group">
        <label for="Body" class="form-label">Body</label>
        <textarea
          id="Body"
          name="body"
          class="form-control"
          rows="3"
          [(ngModel)]="sendMails.content"
        >
        </textarea>
        <!-- <input type="text" /> -->
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-sm btn-primary"
          type="submit"
          (click)="sendMail()"
        >
          Send Mail
        </button>
      </div>
    </form>
  </div>
</ng-template>