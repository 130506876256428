import { MailserviceService } from "./../mailservice.service";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "./../../shared/shared.service";
import { Component, ElementRef, OnInit } from "@angular/core";
import { AuthService } from "src/app/account/auth/auth.service";
import { CreateAgent } from "../create-agent/create-agent.service";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  image: File;
  isError: string = "";
  random = Math.random().toString(36).substring(7);
  compareNumber: string;
  closeResult: any;
  template: any;
  userRoles: any[] = [];
  userGroups: any[] = [];
  isUpdate: boolean = false;
  isShown: boolean = false;
  alert: any = {
    success: true,
    type: "success",
    message: "verification code sent to mail!!",
  };
  alert1: any = {
    success: false,
    type: "danger",
    message: "please enter confirm and new password as same!!",
  };
  userData: any;
  phoneNumber: any;
  oldPassword: any;
  newPassword: any;
  profilePhoto: any = "../../../assets/img/profile-dummy.png";
  locationKey: string;
  // image: string = '';

  constructor(
    private sharedService: SharedService,
    private modalService: NgbModal,
    private authService: AuthService,
    private mailService: MailserviceService,
    private handleError: ErrorHandlerService,
    private agentService: CreateAgent,
    private router: Router // private managementService: ManagementService
  ) {}

  ngOnInit() {
    // 
    this.userData = JSON.parse(localStorage.getItem("userData"));
    if (this.userData["ProfilePhoto"] !== null) {
      this.locationKey = this.userData["ProfilePhoto"];
      this.agentService
        .getSignedUrl({ url: this.locationKey })
        .subscribe((data) => {
          this.profilePhoto = data["url"];
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
    this.mailService
      .getTemplateByName("passwordverification")
      .subscribe((data) => {
        this.template = data["Html"];
      }, (error) => {
        this.handleError.handleError(error)
      });
    this.sharedService.pageEvent.emit({
      pageName: "Edit Profile",
      togglePage: true,
    });
    this.agentService.GetAgentbyid(this.userData.id).subscribe((data) => {
      this.phoneNumber = data["PhoneNumber"];
      this.userGroups = data["SelectedGroups"].map((data) => data["Name"]);
      this.userRoles = data["SelectedRoles"].map((data) => data["Name"]);
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  uploadFile(file: HTMLInputElement) {
    file.click();
  }

  uploadFiles(value: any) {
    let reader = new FileReader();
    reader.readAsDataURL(value.target.files[0]);
    
    const body = new FormData();
    body.append("image", value.target.files[0]);
    this.mailService.uploadFile(body).subscribe((data) => {
      this.locationKey = data["url"];
      this.agentService
        .getSignedUrl({ url: data["url"] })
        .subscribe((datas) => {
          this.profilePhoto = datas["url"];
          this.mailService
            .updateProfilePhoto({
              id: this.userData.id,
              photo: this.locationKey,
            })
            .subscribe((data) => {
              // this.router.navigate(['/dashboard/dashboard-view']);
              this.userData.ProfilePhoto = this.locationKey;
              localStorage.setItem("userData", JSON.stringify(this.userData));
            });
          }, (error) => {
            this.handleError.handleError(error)
          });
    });
  }

  editProfile() {}

  submit(value, content) {
    if (
      value.oldPassword === "" ||
      value.newPassword !== value.confirmPassword
    ) {
      setTimeout(() => {
        this.alert1.success = false;
      }, 10000);

      return (this.alert1.success = true);
    }
    this.open(content);
  }
  toggleShown() {
    this.isShown = !this.isShown;
  }
  open(content) {
    let replacements = {
      code: this.random,
      FirstName: this.userData.firstName,
      LastName: this.userData.lastName,
    };
    const result = this.template.replace(/{{([^{{]+)}}/g, (match, key) => {
      // If there's a replacement for the key, return that replacement with a `<br />`. Otherwise, return a empty string.
      return replacements[key] !== undefined ? replacements[key] : "";
    });
    if (!this.isUpdate) {
      this.mailService
        .sendmail(
          {
            From: { email: "support@accessque.com", name: "University Hub" },
            To: `${this.userData.mail}`,
            Subject: "sent verfication code",
            ReplyTo: "support@accessque.com",
            Body: {
              html: result,
            },
          },
          1
        )
        .subscribe((data) => {
          
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
    this.isUpdate = true;

    this.modalService.open(content, { size: "sm" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    setTimeout(() => {
      this.alert.success = false;
    }, 10000);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  updatePassword(value) {
    if (this.random !== this.compareNumber) {
      this.alert.type = "danger";
      this.alert.success = true;
      return (this.alert.message = "wrong verification code");
    }
    this.modalService.dismissAll();

    this.authService
      .forgetPassword({
        mail: this.userData.mail,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      })
      .subscribe(
        (data) => {
          
          this.router.navigate(["/dashboard/dashboard-view"]);
        },
        (err) => {
          this.alert1.success = true;
          this.alert1.message = "please enter valid password";
          setTimeout(() => {
            this.alert1.success = false;
          }, 10000);
          this.handleError.handleError(err)

        }
      );
  }
}
