import { Component, EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../reports.component';



@Component({
  selector: 'app-updated-at',
  templateUrl: './updated-at.component.html',
  styleUrls: ['./updated-at.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class UpdatedAtComponent implements OnInit {
  showDropDown: boolean = true;
  tab: string = '';
  type: string;
  withForm: FormGroup;
  morethanForm: FormGroup;
  betweenFrom: FormGroup;
  @Output() shareCheckedListUpdated = new EventEmitter();
  constructor(private fb:FormBuilder,private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>) { }

  ngOnInit() {
    this.type='';
    this.initwith();
    this.initmore();
    this.initbetween();
  }
  onClick(tab,type){
    this.type = type
    
    
    if(tab){
      this.tab = 'tab'+tab;
      
      
    } else {
      this.tab = ''
    }
  }
  save(){
    this.showDropDown = false;  
    if(this.type === 'within_the_last'){
      
      this.shareCheckedListUpdated.emit(this.withForm.value);
    } else if(this.type === 'more_than'){
      
      this.shareCheckedListUpdated.emit(this.morethanForm.value);
    } else if(this.type === 'in_between'){
      
      this.shareCheckedListUpdated.emit(this.betweenFrom.value);
    }
  }
  cancel(){
    this.showDropDown = false; 
  }
  initwith(){
    this.withForm = this.fb.group({
      inputVal: [''],
      selectVal: ['minutes']
    })
  }
  initmore(){
    this.morethanForm = this.fb.group({
      inputVal: [''],
      selectVal: ['minutes']
    })
  }
  initbetween(){
    this.betweenFrom = this.fb.group({
      inputVal1: [''],
      inputVal2: ['']
    })
  }
  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
}
