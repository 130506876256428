import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-agentsdialog',
  templateUrl: './agentsdialog.component.html',
  styleUrls: ['./agentsdialog.component.css']
})
export class AgentsdialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AgentsdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public layoutService: LayoutService
  ) { }

  ngOnInit() {
  }

  addAgent(data) {
    this.layoutService.addAgent.next(data);
    this.dialogRef.close();
  }

}
