<div class="calendar-info-bg">
<div class="ml-2 mr-2" *ngIf="agentDetails.respond == null && agentDetails.scheduleStatus == null">
    <div class="container" *ngIf="!showConfirmationDialogue">

        <div class="row py-3">
            <div class="col-md-8 col-sm-8 offset-md-2">
                <div class="header-12 py-2">{{showForm?'Discussion':'For Booking & Timings'}}</div>
            </div>
        </div>
        <div class="row p-3" [hidden]="showForm">
            <div class="col-md-8 col-sm-8 offset-md-2 wbg p-3 radius-10 shadow">

                <div class="py-2">
                    <div class="heading-18-input">Partnership Discussion</div>
                    <div class="heading-18-input float-right" style="margin-top:-16px">{{currentDateTime}}</div>

                </div>
                <hr class="hr-style mb-4">
                <div class="cal-block mr-2 float-left">
                    <div class="pl-2" *ngIf="showcalendar">

                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Select Zone</mat-label>
                            <mat-select disableOptionCentering panelClass="myPanelClass"
                                (selectionChange)="selectedZone($event)">
                                <!-- <mat-option>-- None --</mat-option> -->
                                <!-- <mat-form-field appearance="fill" style="width: 100%;position: sticky;">
                                    <input type="text" (keyup)="onKeyUpSearch($event.target.value)" matInput placeholder="Search">
                                </mat-form-field> -->

                                <mat-optgroup *ngFor="let item of timeZoneDetails" [label]="item.country_name">
                                    <mat-option *ngFor="let timezone of item.timezones" [value]="timezone">
                                        {{timezone.display_name}}
                                    </mat-option>
                                </mat-optgroup>

                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-card class="inline-calendar-card"> -->
                        <!-- <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker  (selectedChange)="onSelectedDate($event)" [dateClass]="dateClass" #picker></mat-datepicker> -->
                       
                            <!-- <mat-calendar [minDate]="minDate" [selected]="selectedDate" [dateFilter]="myFilter" -->
                        <mat-calendar [minDate]="minDate" [selected]="selectedDate"
                            [dateClass]="dateClass()" (selectedChange)="onSelectedDate($event)">
                        </mat-calendar>
                        <!-- </mat-card> -->
                    </div>
                    <div class="sub-text pt-3 pl-3 text-left">
                        <img src="../../../assets/img/timezone.svg" class="img12 mr-2">
                        {{payload.currentTimezone}} ({{payload.currentTime}})
                    </div>
                </div>
 
                <div class="d-flex">
                <div class="time-slot-block ml-2 text-center ml-auto">
                    <label class="sub-text">Start Time <span style="color: red;">*</span></label>

                    <div class="time-slot-ctr">
                        <div *ngFor="let item of startTimeSlot; let i=index">
                            <div class="d-flex px-1">

                                <div (click)="selectTimeSlot(item.time,1)"
                                    [className]="item.time === SelectedStartTime?'time-slot-confirm p-2 mb-2':'time-slot p-2 mb-2'">
                                    {{item.time}}
                                </div>
                                <!-- <button *ngIf="item.time === currentSelectedTime" class="time-slot-confirm p-2 mb-2"
                                    (click)="showConfirmationForm(item.time)">Confirm</button> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="time-slot-block mr-2 text-center ml-auto">
                    <label class="sub-text">End Time <span style="color: red;">*</span></label>

                    <div class="time-slot-ctrx" style="height:400px; overflow:auto">
                        <div *ngFor="let item of endTimeSlot; let i=index">
                            <div class="d-flex px-1">

                                <div (click)="selectTimeSlot(item.time,2)"
                                    [className]="item.time === SelectedEndTime?'time-slot-confirm p-2 mb-2':'time-slot p-2 mb-2'">
                                    {{item.time}}
                                </div>
                                <!-- <button *ngIf="item.time === currentSelectedTime" class="time-slot-confirm p-2 mb-2"
                                    (click)="showConfirmationForm(item.time)">Confirm</button> -->
                            </div>
                        </div>
                        <!-- <div *ngFor="let item of timeList; let i=index">
                            <div class="d-flex" *ngIf="item.Eventbooked!=true">

                                <div (click)="showConfirmationButton(item.time,i)"
                                    [className]="item.time === currentSelectedTime?'time-slot-select p-2 mb-2 mr-2':'time-slot p-2 mb-2'">
                                    {{item.time}}
                                </div>
                                <button *ngIf="item.time === currentSelectedTime" class="time-slot-confirm p-2 mb-2"
                                    (click)="showConfirmationForm(item.time)">Confirm</button>
                            </div>
                        </div> -->
                    </div>
                    <div class="d-flex pl-2 pt-3">
                        <button *ngIf="selectedDate && SelectedStartTime && SelectedEndTime"
                            class="btn btn-light btn-sm mr-2" (click)="ClearForm()">Cancel
                        </button>
                        <button *ngIf="SelectedStartTime && SelectedEndTime"
                            class="btn btn-primary btn-sm"
                            (click)="confirmedTimeSlot()">Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
</div>
            <!-- Partnership Discussion starts -->
            <!-- <hr class="hr-style my-4"> -->
            <!-- <div class="row pt-3">
            <div class="col-md-12 col-sm-12">
                <div class="header-12 py-2">Discussion</div>
            </div>
        </div> -->
         <div class="row" [hidden]="!showForm">
            <div class="col-md-8 col-sm-8 wbg offset-md-2 shadow radius-10">                
                <div class="py-2 mt-2">
                    <div class="heading-18-input"> <img src="../../../assets/images/left-arrow.svg" class="img8 mr-2"
                            (click)="showForm=false;SelectedStartTime=null;SelectedEndTime=null">Partnership
                        Discussion</div>
                </div>
                <div class="d-flex py-3">
                    <!-- <div class="sub-text pr-5"> <img src="../../../assets/img/timer-2.svg" class="img12 mr-2">30 Min
                    </div> -->
                    <div class="sub-text pr-5"> <img src="../../../assets/img/meeting-frame.svg"
                            class="img12 mr-2">{{SelectedStartTime}} - {{SelectedEndTime}} ,
                        {{this.payload.display_selected_date}}
                    </div>
                    <div class="sub-text pr-5"><img src="../../../assets/img/timezone.svg"
                            class="img12 mr-2">{{payload.currentTimezone}} ({{payload.currentTime}})</div>
                </div>
                <hr class="hr-style mb-4">
                <form action="" [formGroup]="scheduleEventForm">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">Name <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" required formControlName="name"
                                        placeholder="Type your name" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">Email <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" required formControlName="email"
                                        placeholder="Type your email" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">Guest Emails (Optional)</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="guestmail"
                                        placeholder="Type guest email" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="form-group">
                                <label class="sub-text">Mobile <span style="color: red;">*</span></label>
                                <div class="row">
                                    <div class="col-md-3 pr-2">
                                        <div class="form-group">
                                            <button type="button" class="form-control dropdown-toggle"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                +<span class="country-code">{{ selectedCountryCode }}</span>
                                            </button>

                                            <ul class="form-control dropdown-menu">
                                                <div>
                                                    <input type="search" (input)="onInput($event)"
                                                        formControlName="CountryCode" class="form-control search-all"
                                                        style="height: auto; padding: 4px 12px;width: 93%;margin: 0 9px;"
                                                        placeholder="Search " autocomplete="off" />
                                                    <li *ngFor="let country of countries" style="cursor: pointer;">
                                                        <span href="#" (click)="selectCountry(country)">
                                                            <img src="{{ country.flag || country.flags.svg || country.flags.png }}"
                                                                alt="" style="height: 15px;width: 20px;"
                                                                (click)="selectCountry(country)">
                                                            <span style="padding-left: 8px"
                                                                (click)="selectCountry(country)">{{ country.name }} (+{{
                                                                country.callingCodes[0] }})</span>
                                                        </span>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-9 pl-0">
                                        <div class="form-group">
                                            <input type="text" class="form-control" required formControlName="mobile_no"
                                                placeholder="Type your mobile number" />
                                        </div>
                                    </div>
                                </div>


                                <!-- <input type="text" class="form-control" required formControlName="mobile_no"
                                placeholder="Type your mobile number" /> -->

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-sm-6">

                            <div class="form-group">
                                <label class="sub-text">Title <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input name="text" formControlName="EventTitle" required class="form-control" id=""
                                        placeholder="Type a title ... " />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <label class="sub-text">Event Type <span style="color: red;">*</span></label>
                            <div class="form-group">
                                <select class="form-control" required formControlName="EventType">
                                    <option [ngValue]="null" [disabled]="true">Select Event type
                                    </option>
                                    <option value="General Meetings">General Meetings</option>
                                    <option value="Conference">Conference</option>
                                    <option value="Board Meeting">Board Meeting</option>
                                    <option value="Client Meeting">Client Meeting</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label class="sub-text">Description <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <textarea name="Message" formControlName="message" required class="form-control"
                                        id="" cols="30" rows="4" placeholder="Type a message ... "></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row py-2">
                    <div class="col-md-12 col-sm-12 text-right">
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-light mr-2" (click)="ClearForm()">Cancel</button>
                        <button type="button" class="btn btn-primary" (click)="scheduleEvent()">Schedule Event</button>
                    </div>
                </div>

            </div>
        </div>

        <!--Partener disucssion ends -->

        <!-- Confirmed starts -->


    </div>
    <div class="container-fluid" *ngIf="showConfirmationDialogue">
        <div class="row pt-3">
            <div class="col-md-8 col-sm-8 offset-md-2 ">
                <div class="header-12 py-2">Confirmed</div>
            </div>
        </div>
        <div class="row p-3">
            <div class="col-md-8 col-sm-8 wbg p-3 offset-md-2 radius-10 shadow ">
                <div class="py-2">
                    <div class="heading-18-input">Confirmed</div>
                    <div class="sub-text">You are scheduled with {{scheduledDetails.AgenetName}}.</div>
                </div>
                <hr class="hr-style my-2">
                <div class="d-flex">
                    <div class="p-4">
                        <div class="header-lg">{{scheduledDetails.EventTitle}} </div>
                        <div class="sub-text py-3"> {{scheduledDetails.EventDescription}}</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/meeting-frame.svg"
                                class="img12 mr-2">{{scheduledDetails.StartTime}} - {{scheduledDetails.EndTime}},
                            {{scheduledDetails.fullDate}}</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/timezone.svg"
                                class="img12 mr-2">{{scheduledDetails.ScheduledTimezone}} (Timezone)</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/web.svg" class="img12 mr-2">{{scheduledDetails.EventType || '--'}}</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/people.svg"
                                class="img12 mr-2">{{scheduledDetails.Members || '--'}}</div>
                    </div>
                    <div class="p-4">
                        <img src="../../../assets/img/meeting-confirm.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="gbg mt-3 ml-2 mr-2" *ngIf="agentDetails.scheduleStatus == 'cancellation'">
    <div class="container-fluid">
        <hr class="hr-style my-4">

        <div class="row p-3" style="width: 50%;margin-left: auto;margin-right: auto;">

            <div class="col-md-10 col-sm-10 wbg p-3" [hidden]="!scheduleDetailsForCancelReschedle.StartTime">
                <!-- <div class="heading-18-input">Cancel Event ?</div><br/> -->

                <p>Meeting : {{scheduleDetailsForCancelReschedle.StartTime}} -
                    {{scheduleDetailsForCancelReschedle.EndTime}}
                </p>
                <hr class="hr-style my-2">
                <div class="d-flex">
                    <div class="p-2">
                        <div class="sub-text py-3">Event Title : {{scheduleDetailsForCancelReschedle.EventTitle}}</div>
                        <div class="sub-text py-3">Event Description :{{scheduleDetailsForCancelReschedle.EventDescription}}</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/people.svg"
                                class="img12 mr-2">{{scheduleDetailsForCancelReschedle.Name}}</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/meeting-frame.svg"
                                class="img12 mr-2">{{scheduleDetailsForCancelReschedle.StartTime}} -
                            {{scheduleDetailsForCancelReschedle.EndTime}},
                            {{scheduleDetailsForCancelReschedle.fullDate}}</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/timezone.svg"
                                class="img12 mr-2">{{scheduleDetailsForCancelReschedle.ScheduledTimezone||'--'}}
                            (Timezone)
                        </div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/web.svg" class="img12 mr-2">Web
                            conferencing details to follow.</div>
                        <div class="sub-text py-3"> <img src="../../../assets/img/people.svg"
                                class="img12 mr-2">{{scheduleDetailsForCancelReschedle.Members ||'--'}}</div>
                    </div>

                </div>


                <hr class="hr-style my-2">
                <div class="d-flex">
                    <form action="" [formGroup]="cancelEventForm">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="sub-text">Reasong for cancelling <span
                                            style="color: red;">*</span></label>
                                    <div class="form-group">
                                        <textarea name="cancellationReason" id="cancellationReason" class="form-control"
                                            [(ngModel)]="cancellationReason" placeholder="Enter reason" cols="100"
                                            formControlName="cancel_reason" rows="4"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 text-right">
                                <button type="button" class="btn btn-primary" (click)="cancelEvent()">Cancel
                                    Event</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <div class="col-md-10 col-sm-10 wbg p-3" [hidden]="scheduleDetailsForCancelReschedle.StartTime">
                <!-- <div class="heading-18-input">Cancel Event ?</div><br/> -->
                <hr class="hr-style my-2">
                <div class="d-flex">
                    <div class="p-2">
                        <div class="sub-text py-3">
                            <h4>No Event Found!</h4>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
<div class="gbg mt-3 ml-2 mr-2" *ngIf="agentDetails.scheduleStatus == 'rescheduling'">
    <div class="container-fluid">
        <hr class="hr-style my-4">

        <div class="row p-3" style="width: 50%;margin-left: auto;margin-right: auto;">

            <div class="col-md-10 col-sm-10 wbg p-3">
                <div class="heading-18-input" style="text-align: center;"><b>Reschedule Event</b></div><br />
                <hr class="hr-style my-2">

                <p>
                    Meeting : {{scheduleDetailsForCancelReschedle.StartTime}} -
                    {{scheduleDetailsForCancelReschedle.EndTime}}, {{scheduleDetailsForCancelReschedle.fullDate}} </p>
                <div class="sub-text py-3"> <img src="../../../assets/img/timezone.svg"
                        class="img12 mr-2">{{scheduleDetailsForCancelReschedle.ScheduledTimezone}} (Timezone)</div>


                <hr class="hr-style my-2">

                <form action="" [formGroup]="rescheduleEventForm">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">Name <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" required formControlName="Name"
                                        placeholder="Type your name" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">Email <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="text" readonly class="form-control" required formControlName="EmailId"
                                        placeholder="Type your email" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">Guest Emails (Optional)</label>
                                <div class="form-group">
                                    <input type="text" readonly class="form-control" formControlName="Members"
                                        placeholder="Type guest email" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="form-group">
                                <label class="sub-text">Mobile <span style="color: red;">*</span></label>
                                <div class="row">
                                    <div class="col-md-3 pr-2">
                                        <div class="form-group">
                                            <button type="button"  class="form-control dropdown-toggle"
                                                data-toggle="dropdown" aria-haspopup="true" style="pointer-events: none;" aria-expanded="false">
                                                +<span class="country-code">{{ selectedCountryCode }}</span>
                                            </button>
<!-- 
                                            <ul class="form-control dropdown-menu">
                                                <div>
                                                    <input type="search"  (input)="onInput($event)"
                                                        formControlName="CountryCode" class="form-control search-all"
                                                        style="height: auto; padding: 4px 12px;width: 93%;margin: 0 9px;"
                                                        placeholder="Search " autocomplete="off" />
                                                    <li *ngFor="let country of countries">
                                                        <span href="#" (click)="selectCountry(country)">
                                                            <img src="{{ country.flag || country.flags.svg || country.flags.png }}"
                                                                alt="" style="height: 15px;width: 20px;"
                                                                (click)="selectCountry(country)">
                                                            <span style="padding-left: 8px"
                                                                (click)="selectCountry(country)">{{ country.name }} (+{{
                                                                country.callingCodes[0] }})</span>
                                                        </span>
                                                    </li>
                                                </div>
                                            </ul> -->
                                        </div>
                                    </div>
                                    <div class="col-md-9 pl-0">
                                        <div class="form-group">
                                            <input type="text" readonly class="form-control" required
                                                formControlName="MobileNumber" placeholder="Type your mobile number" />
                                        </div>
                                    </div>
                                </div>


                                <!-- <input type="text" class="form-control" required formControlName="mobile_no"
                            placeholder="Type your mobile number" /> -->

                            </div>
                        </div>

                        <!-- <div class="col-md-6">
                           
                            <div class="form-group">
                                <label class="sub-text">Mobile <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" required formControlName="MobileNumber"
                                        placeholder="Type your mobile number" />
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">Start Date <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="date" [min]="startMinDate" class="form-control" formControlName="StartDate"
                                        placeholder="Start Date" (change)="onSelectedRecheduleDate($event)"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">From Times <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="time" list="times" class="form-control" formControlName="StartTime" required (change)="selectedResheduleStartTime($event)"/>
                                    <datalist id="times">
                                        <option value="{{item.time}}" *ngFor="let item of startTimeSlot">
                                    </datalist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">End Date <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <div class="form-control">
                                        {{selectedDate | date:'MM/dd/yyyy'}}
            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="sub-text">To Time <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="time" list="endtimes" class="form-control" required placeholder="To Time"
                                    formControlName="EndTime" />
                                <datalist id="endtimes">
                                    <option value="{{item.time}}" *ngFor="let item of endTimeSlot">
                                </datalist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label class="sub-text">Title <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <input type="text" name="Title" formControlName="EventTitle" required class="form-control"
                                        id=""  placeholder="Type a Title ... "/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label class="sub-text">Message <span style="color: red;">*</span></label>
                                <div class="form-group">
                                    <textarea name="Message" formControlName="Message" required class="form-control"
                                        id="" cols="30" rows="4" placeholder="Type a message ... "></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row py-2">
                    <div class="col-md-12 col-sm-12 text-right">
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-light mr-2" (click)="showForm=false">Cancel</button>
                        <button type="button" class="btn btn-primary" (click)="rescheduleEvent()">Reschdule
                            Event</button>
                    </div>
                </div>

                <hr class="hr-style my-2">

            </div>
            <div class="col-md-10 col-sm-10 wbg p-3" [hidden]="scheduleDetailsForCancelReschedle.StartTime">
                <!-- <div class="heading-18-input">Cancel Event ?</div><br/> -->
                <hr class="hr-style my-2">
                <div class="d-flex">
                    <div class="p-2">
                        <div class="sub-text py-3">
                            <h4>No Event Found!</h4>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>