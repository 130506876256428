<div *ngIf="projects?.length>1">
  <!-- <label class="form-label1">Projects</label> -->
  <select (ngModelChange)="projectChanged($event)" [(ngModel)]="projectId" class="select-company" 
    style="text-transform: capitalize">
    <option value="0" disabled>
      Select Project
    </option>
    <option *ngFor="let company of projects" [value]="company['id']">
      {{ company["Name"] }}
    </option>
  </select>
</div>