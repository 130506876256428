import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SettingsService } from "./settings.service";
// import { ServiceService } from 'src/app/pages/services/service.service';
// import { SettingsService } from '../settings.service';

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  desktopOffValue = false;
  desktopOnValue = false;
  soundValue = false;
  soundId = 1;
  sounsData: any = [];
  currentItem: any;
  // audio: HTMLAudioElement;
  audio = new Audio();
  userData: any;
  sound = false;
  sounds: any;
  selectedSound: any;
  showNotifications = false;
  constructor(
    private SettingsService: SettingsService,
    public dialogRef: MatDialogRef<SettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) // public settingsService: SettingsService,
  // public services: ServiceService
  {}

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    this.selectedSound = this.userData.soundId;
    if (this.userData.notification_subscribe == null) {
      this.userData.notification_subscribe = true;
    } else {
      this.sound = this.userData.notification_subscribe;
    }
    if (this.userData.notification_subscribe == true) {
      this.sound = true;
    }
    this.showNotifications = this.userData.notification_subscribe;
    // this.desktopOffValue = this?.userData?.notification_subscribe;
    // this.soundId = this.userData?.soundId;
    // this.listOfSounds()
    this.getSoundsList();
  }

  getSoundsList() {
    this.SettingsService.getSounds({
      email: this.userData.mail,
      Platform: "CRM",
      token: this.userData.token,
    }).subscribe((res: any) => {
      this.sounds = res.listOfSounds;
    });
  }

  playAudio(item) {
    this.audio = new Audio();
    const source = document.createElement("source");
    source.setAttribute("src", item.soundPath);
    source.setAttribute("type", "audio/mpeg");
    this.audio.append(source);
    this.audio.load();
    const playPromise = this.audio.play();
    if (playPromise !== null) {
      playPromise.catch(() => {
        this.audio.play();
      });
    }

    this.SettingsService.updateNotificationAlert({
      email: this.userData.mail,
      Platform: "CRM",
      token: this.userData.token,
      id: item.id,
    }).subscribe((res: any) => {

      this.userData.soundId = item.id;
      localStorage.setItem("userData", JSON.stringify(this.userData));

    });
  }

  subscription() {
    // if (this.showNotifications == true) {
    //   this.showNotifications = false;
    // } else if(this.showNotifications == false){
    //   this.showNotifications = true;
    // }
    this.SettingsService.updateSubscription({
      userId: this.userData.id,
      notification_subscribe: this.showNotifications,
      token: this.userData.token,
      Platform: "CRM",
    }).subscribe((res: any) => {
      this.showNotifications = res.notification_subscribe;
      this.userData.notification_subscribe = this.showNotifications;
      localStorage.setItem("userData", JSON.stringify(this.userData));
    });
  }
}
