import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ElementRef,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { SharedService } from "src/app/shared/shared.service";
import { ReferreeService } from "./referree.service";
import { UhLeadsService } from "../uhleads.service";
import * as xlsx from "xlsx";
import { DOCUMENT } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { LayoutService } from "src/app/layout/layout.service";
import { ReferralData } from "../marketing/smsmarketing/models/ReferralData.model";
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { MomentFormatService } from "src/app/moment-format-service";
import { post } from "jquery";
import { MarketingService } from "../marketing/marketing.service";
import { RoleService } from "../role-management/role.service";
import { CreateAgent } from "../create-agent/create-agent.service";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
import * as moment from "moment";
import { environment } from "src/environments/environment";
var sortJsonArray = require("node_modules/sort-json-array");
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";

@Component({
  selector: "app-referree",
  templateUrl: "./referree.component.html",
  styleUrls: ["./referree.component.css"],
})
export class ReferreeComponent implements OnInit {
  userData: any;
  projectId: any;
  admin: any;
  public error: boolean = false;
  public errorMessage: string = "";
  public filePath: any;
  public fileData: ReferralData[] = [];
  referralList: any[] = [];
  referredList: any[] = [];
  public page: any = 1;
  public pageSize: any = 12;
  public collectionSize: any = 1;
  dialerScreen1 = "dialer";
  callStatus: string = "Calling..";
  newStatus: any;
  applicationStatus: any = "";
  public callData: any[] = [];
  public startIndex: number = 0;
  selectedLead: any;
  startCall: boolean = false;
  public stopCall: boolean = false;
  public statusValue = "all";
  public clearTime: any;
  sideViewerData: any = { ReferralActivities: [], CallDetails: [] };
  currentLeadId: any;
  activityfilterString: string = "";
  refereeSearch: string = "";
  minItems = 5;
  commentText: string = "";
  selectedAll: boolean = false;
  leadIds = [];
  defaultAgent = "default";
  selectedAgent: any;
  agentList: any;
  universityList: any[];
  otherUniversity = "Other";
  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;

  public filterValue = "all";
  showDate: boolean = false;
  endDate: any;
  startDate: any;
  date1: any;
  date2: any;
  agentCall: String = "";
  testConfig: any;
  leadTypereferee: string = "total";
  editStatus: string;
  public edited: boolean = false;

  public uploaded: boolean = false;
  public insertCount: number = 0;

  public marketingId: {
    id: string;
    status: string;
    phoneNumber: string;
    email: string;
    name: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      status: "",
      name: "",
      email: "",
      phoneNumber: "",
      module: "",
      city: "",
      nextCaller: null,
      lead: {},
    };

  leadtoEdit: {
    LeadId: string;
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    University: string;
    CallStatus: string;
    AgentMail: string;
    DIterms?: string;
    DIyear?: string;
  };

  addLead: {
    FirstName: string;
    LastName: string;
    CountryCode: string;
    PhoneNumber: string;
    Email: string;
    University: string;
    AgentMail: string;
  } = {
      FirstName: "",
      LastName: "",
      CountryCode: "",
      PhoneNumber: "",
      Email: "",
      University: "",
      AgentMail: "",
    };
  currentCard: string = "total";
  sortColumn = "createdAt";
  sortOrder = "des";
  smsText: string;
  smsList: [] = [];
  projectLists: any;

  @ViewChild("closeButton2") closeButton2;
  @ViewChild("closeAssignModel") closeAssignModel;
  @ViewChild("uploadFile") uploadFile;
  topBarCountReferee: {
    total: number;
    referral: number;
    signedUp: number;
    notSignedUp: number;
    unassigned: number;
    assigned: number;
    assignedtoMe: number;
    androidSignup: number;
    iosSignup: number;
    webSignup: number;
    deactivated: number;
  } = {
      total: 0,
      referral: 0,
      signedUp: 0,
      notSignedUp: 0,
      unassigned: 0,
      assigned: 0,
      assignedtoMe: 0,
      androidSignup: 0,
      iosSignup: 0,
      webSignup: 0,
      deactivated: 0
    };
  allLeads: any;
  filterString: string;
  leadsLoading: boolean = false;
  noLeadsFound: boolean = false;
  tab!: string;
  downloadLink: any;
  referralList1: any;
  DIyear: any;
  DIterms: any;
  showdeferredintake: Boolean = false;
  DIyearArray: any = [];
  callSid: any;
  @ViewChild("inputString", { static: true }) input: ElementRef;
  cardNumber: any = 1;
  paidDate = new Date();
  todayDate = new Date().toISOString().slice(0, 10);

  constructor(
    private SharedService: SharedService,
    public ReferreeService: ReferreeService,
    public countupTimerService: CountupTimerService,
    private momentService: MomentFormatService,
    private uhleadsservice: UhLeadsService,
    private readonly modalService: NgbModal,
    public layoutService: LayoutService,
    public marketingService: MarketingService,
    public companyService: RoleService,
    private handleError: ErrorHandlerService,
    private agentService: CreateAgent,
    private router: Router,

    @Inject(DOCUMENT) private domService: HTMLDocument
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }
  ngOnInit() {
    this.SharedService.pageEvent.emit({
      pageName: "Referee",
    });
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }

    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );

    this.userData = JSON.parse(localStorage.getItem("userData"));
    // this.SharedService.projectId.subscribe((data: any) => {
    this.companyService.projectAdmin(this.userData["companyId"]).subscribe(
      (projects) => {
        this.projectId = projects.filter((x) => x.Name == "Referrals")[0].id;
        // this.projectId = data;
        if (this.projectId) {
          this.SharedService.getProjectAdmin(this.projectId).subscribe(
            (data: any) => {
              this.admin = data;
              setTimeout(() => {

                this.getListofUniversities();
                this.getTopBarCount();

                this.getLeadsByCards(this.leadTypereferee);
                // this.getLead();
              }, 500);

              this.uhleadsservice
                .getAgentList(this.projectId, this.userData.id)
                .subscribe(
                  (data: any) => {
                    this.agentList = data;
                  },
                  (error) => {
                    this.handleError.handleError(error);
                  }
                );
            },
            (error) => {
              this.handleError.handleError(error);
            }
          );
        }
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
    this.getDownloadLink();
    this.ReferreeService.startTimer.subscribe((res) => {

      this.callStatus = "inProgress";
      this.startTimer();

    });
    this.ReferreeService.getCallSid.subscribe((data) => {
      this.callSid = data;
    });
    //incoming sms check
    this.uhleadsservice.incomingSms.subscribe((from: any) => {
      // 
      if (from.from) {
        this.ReferreeService.getSms(this.currentLeadId).subscribe(
          (smsList: any) => {
            // 
          }
        );
      }
    });
    let d = new Date();
    this.DIyearArray.push(d.getFullYear());
    let year = d.getFullYear();
    for (let i = 0; i < 4; i++) {
      year = year + 1;
      this.DIyearArray.push(year);
    }
    // server-side search
    fromEvent(this.input.nativeElement, "keyup")
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.filterString = this.input.nativeElement.value;
          this.page = 1;
          this.searchLeads();
        })
      )
      .subscribe();
    this.ReferreeService.callStatus.subscribe((status) => {
      this.callStatus = status;
    });
  }

  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }

  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }

  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }

  filterData() {
    // this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      // this.getReferralList(
      //   this.admin,
      //   this.projectId,
      //   this.userData.mail,
      //   "all",
      //   this.startDate,
      //   this.endDate
      // );
      this.getLeadsByCards("all");
    }
  }

  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    // this.getReferralList(
    //   this.admin,
    //   this.projectId,
    //   this.userData.mail,
    //   "all",
    //   this.startDate,
    //   this.endDate
    // );
    this.getTopBarCount();
    this.getLeadsByCards("all");
  }

  getReferralList(admin, projectId, mail, leadType, start, end) {
    let payload = {
      admin: admin,
      mail: mail,
      ProjectId: projectId,
      leadType: leadType,
      start: start,
      end: end,
    };

    this.ReferreeService.getReferralList(payload).subscribe(
      (res: any) => {


        this.referralList = res;

        this.referralList.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
            a[key] === "" ? (a[key] = "-") : a[key];
          });
        });
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
    this.getTopBarCount();
    this.getLeadsByCards(this.leadTypereferee);
  }

  toggleSort(column, order) {
    if (this.sortOrder == "asc") {
      this.sortOrder = "des";
    } else {
      this.sortOrder = "asc";
    }
    this.sortTableData(column, this.sortOrder);
  }

  sortTableData(column, order) {
    // 
    // 
    this.sortColumn = column;
    this.referralList = sortJsonArray(
      this.referralList,
      this.sortColumn,
      this.sortOrder
    );

    // 
  }

  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null ? (a[key] = "-") : a[key];
      a[key] === "" ? (a[key] = "-") : a[key];
    });
  }

  getLead() {
    this.ReferreeService.getReferralLead(2).subscribe(
      (res: any) => { },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getReferredList(id) {
    this.refereeSearch = "";
    this.referredList = [];
    this.ReferreeService.getReferredList(id).subscribe(
      (res: any) => {
        this.referredList = res.myReferralsList;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  getListofUniversities() {
    this.ReferreeService.getUniversityList().subscribe(
      (res: any) => {
        this.universityList = res.universities;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  openEditLead(content, lead) {
    this.editStatus = lead.CallStatus;
    this.DIterms = lead.DIterms;
    this.DIyear = parseInt(lead.DIyear);
    if (this.editStatus == "deferredintake") {
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
    this.leadtoEdit = {
      LeadId: lead.id,
      FirstName: lead.FirstName,
      LastName: lead.LastName,
      CountryCode: "+" + lead.CountryCode,
      PhoneNumber: lead.PhoneNumber,
      Email: lead.Email,
      University: lead.University,
      CallStatus: lead.CallStatus,
      AgentMail: this.userData.mail,
    };

    this.modalService.open(content, { size: "md" });
  }

  fileUpload(event) {
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(file.type)) {
        return;
      }
    }
  }

  openAddRecord(content) {
    this.addLead = {
      FirstName: "",
      LastName: "",
      CountryCode: "",
      PhoneNumber: "",
      Email: "",
      University: "",
      AgentMail: "",
    };
    this.modalService.open(content, { size: "md" });
  }

  addSingleLead() {
    let payload = {
      CreatedBy: this.userData.mail,
      ProjectId: this.projectId,
      data: [this.addLead],
    };

    if (
      this.addLead.FirstName == "" ||
      this.addLead.LastName == "" ||
      this.addLead.PhoneNumber == "" ||
      this.addLead.Email == "" ||
      this.addLead.CountryCode == "" ||
      this.addLead.University == ""
    ) {
      this.uhleadsservice.showError("Please fill all the data", "Error");
      return;
    } else {
      this.ReferreeService.uploadLeads(payload).subscribe(
        (res: any) => {
          // this.getReferralList(
          //   this.admin,
          //   this.projectId,
          //   this.userData.mail,
          //   "all",
          //   this.startDate,
          //   this.endDate
          // );
          this.getTopBarCount();
          this.getLeadsByCards("all");
          document.getElementById("closeAddModal").click();
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    }
  }

  updateLead() {
    this.edited = true;
    if (this.ReferreeService.editLead.invalid) {
      this.uhleadsservice.showError("Form is not valid . Please check all the fields", "Error!");
      return;
    }

    this.leadtoEdit.CountryCode = this.leadtoEdit.CountryCode.replace(
      /\+/g,
      ""
    );
    this.leadtoEdit.DIterms = this.DIterms;
    this.leadtoEdit.DIyear = this.DIyear;
    if (this.leadtoEdit.CountryCode == "-") {
      this.leadtoEdit.CountryCode = "00";
    }
    if (this.leadtoEdit.PhoneNumber == "-") {
      this.leadtoEdit.PhoneNumber = "0000000000";
    }

    let obj: any;
    obj = this.leadtoEdit;
    obj.paidDate = this.paidDate;
    this.ReferreeService.updateLead(this.leadtoEdit).subscribe(
      (res: any) => {
        this.edited = false;
        // this.getReferralList(
        //   this.admin,
        //   this.projectId,
        //   this.userData.mail,
        //   "all",
        //   this.startDate,
        //   this.endDate
        // );
        this.getTopBarCount();
        this.getLeadsByCards("all");
        document.getElementById("closeButton3").click();
        let dataobj = {
          userid: this.leadtoEdit.LeadId,
          paidDate: this.paidDate,
          paidStatus: 'paid'
        };
        this.ReferreeService.addPaid(dataobj).subscribe((res: any) => {
          // this.paidDate = null;
        }, (err) => {

        })
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "sheet") {
      return true;
    } else {
      // window.alert("Please upload a valid file!");
      this.uhleadsservice.showError("Please upload a valid file!", "Error!");
      return false;
    }
  }

  sideNavData(data) {
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.ReferreeService.getReferralLead(data.id).subscribe(
      (res: any) => {
        this.ReferreeService.getSms(data.id).subscribe((smsList: any) => {
          // 
          this.smsList = smsList.SMSDetails;
        });
        this.sideViewerData = res;
        this.replaceNull(this.sideViewerData);
        this.currentLeadId = this.sideViewerData.id;
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }

  sendSms(item, HTMLid) {
    this.smsText = (<HTMLInputElement>document.getElementById(HTMLid)).value;
    let fromNumber = "";
    this.companyService.projectAdmin(environment.companyId).subscribe((res: any) => {
      this.projectLists = res;
      // 
      for (let i = 0; i < res.length; i++) {
        if (res[i].Name == "Referrals") {
          fromNumber = res[i].TwilioNumber;
        }
      }
      let payload = {
        text: this.smsText,
        from: fromNumber,
        to: "+" + item.CountryCode + item.PhoneNumber,
        AgentMail: this.userData.mail,
      };
      // 
      this.ReferreeService.sendSms(payload, this.projectId).subscribe(
        (res: any) => {
          this.uhleadsservice.showInfo("Message successfully sent.", "Sent");
          this.ReferreeService.getSms(this.currentLeadId).subscribe(
            (smsList: any) => {
              this.smsList = smsList.SMSDetails;
              (<HTMLInputElement>document.getElementById(HTMLid)).value = "";
            }
          );
        }
      );
    });
  }

  onInputActivity(event: any) {
    if (this.refereeSearch != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }

  onRefereeSearch(event: any) {
    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }
  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }

  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }

  convertFile(e) {
    this.filePath = e.target.files[0]["name"].toString();
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "FirstName" && sheet_data.length !== 0) {
        this.fileData = this.ReferreeService.convert2dArraytoObject(sheet_data);
        this.ReferreeService.uploadLeads({
          data: this.fileData,
          CreatedBy: this.userData.mail,
          ProjectId: this.projectId,
          addType: 'Excel'
        }).subscribe(
          (data) => {
            this.uploaded = true;
            this.error = false;
            this.insertCount = data["count"];
            setTimeout(() => {
              this.uploaded = false;
              this.filePath = "";
            }, 5000);
          },
          (error) => {
            this.handleError.handleError(error);
          }
        );
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }

  startCallListener() {
    this.ReferreeService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      userData: this.userData,
      leadData: this.marketingId,
    });
  }

  startCallListener2(data, index) {
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;
    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);

    this.newStatus = false;

    this.callData[0] = data;
    // 
    this.selectedLead = data;
    this.marketingId = {
      id: data.id,
      status: data.CallStatus,
      name: data.FirstName,
      email: data.Email,
      phoneNumber: "+" + data.CountryCode + data.PhoneNumber,
      // phoneNumber: "+919848311098",
      module: data.Module,
      city: "",
      nextCaller: this.referralList.length > 1 ? 1 : 0,
      lead: 0,
    };
    if (this.referralList.length > 1) {
      var nextFirstname = this.referralList[index + 1]?.FirstName
        ? this.referralList[index + 1]?.FirstName
        : "";
      var nextLastname = this.referralList[index + 1]?.LastName
        ? this.referralList[index + 1]?.LastName
        : "";
      var nextCallerCity = this.referralList[index + 1]?.City
        ? this.referralList[index + 1]?.City
        : "";
      var nextCallerCountryCode = this.referralList[index + 1]?.CountryCode
        ? this.referralList[index + 1]?.CountryCode.replace("+", "")
        : "";
      var nextCallerPhoneNumber = this.referralList[index + 1]?.PhoneNumber
        ? this.referralList[index + 1]?.PhoneNumber
        : "";

      this.nextCallerName = nextFirstname;
      this.nextCallerCity = nextCallerCity;
      this.nextCallerNumber = nextCallerCountryCode
        ? "+" + nextCallerCountryCode + nextCallerPhoneNumber
        : "";
      this.startIndex = index + 1;
    }
    this.ReferreeService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
    });
    this.startCall = true;
  }

  conferenceCall(value: any) {
    this.ReferreeService.conferenceCall.next({ value });
  }

  stopCallListener() {

    this.ReferreeService.startCall.next(null);
    this.ReferreeService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.stopCall = true;
    this.startCall = false;
    // if (this.statusValue !== "pending") {
    //   this.startIndex += 1;
    // }
    this.startIndex += 1;

    clearTimeout(this.clearTime);
  }

  stopCurrentCall() {

    this.ReferreeService.startCall.next(null);
    this.ReferreeService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = "Call Ended";
  }

  applicationstatus(data) {
    this.applicationStatus = data;
    if (this.applicationStatus == "Yes") {
    }
  }

  onStatusChange(data) {
    this.newStatus = data;
    if (this.newStatus == "deferredintake") {
      this.showdeferredintake = true;
    } else {
      this.showdeferredintake = false;
      this.DIyear = null;
      this.DIterms = null;
    }
  }

  updateComments() {
    if (!this.newStatus) {
    } else {
      // this.ReferreeService.getCallSid.subscribe((data) => {
      this.ReferreeService.updateCallComments({
        id: this.selectedLead.id,
        comments: this.commentText,
        callStatus: this.newStatus,
        CallSid: this.callSid,
        diyear: this.DIyear,
        diterms: this.DIterms,
      }).subscribe(
        (res) => {
          this.showdeferredintake = false;
          this.DIyear = null;
          this.DIterms = null;
          this.commentText = "";
          this.getTopBarCount();
          this.getLeadsByCards(this.leadTypereferee);
          this.closeButton2.nativeElement.click();
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
      // });
    }
  }

  resetCallingScreen() {
    this.commentText = "";
    this.newStatus = null;
    this.showdeferredintake = false;
    this.DIyear = null;
    this.DIterms = null;
  }

  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.referralList.length; i++) {
        this.leadIds.push(this.referralList[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }

  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }

  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  assignAgentModal() {
    this.defaultAgent = "default";
    this.selectedAgent = null;
  }
  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }

  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning("Select leads to be assigned", "Alert!");
      return;
    }
    if (this.selectedAgent) {
      this.ReferreeService.assignLeads({
        AssignedTo: this.selectedAgent,
        InsertedBy: this.userData.mail,
        LeadIds: this.leadIds,
      }).subscribe(
        (res) => {
          this.uhleadsservice.showSuccess(
            "Successfully assigned the agents",
            "Success"
          );
          this.leadIds = [];
          // this.getReferralList(
          //   this.admin,
          //   this.projectId,
          //   this.userData.mail,
          //   "all",
          //   this.startDate,
          //   this.endDate
          // );
          this.getTopBarCount();
          this.getLeadsByCards("all");
          this.selectedAll = false;
          document.getElementById("closeAssignButton").click();
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    } else {
      this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }
  getTopBarCount() {
    var postData = {
      AgentMail: this.userData.mail,
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
    };
    this.ReferreeService.getTopBarCount(postData).subscribe(
      (res: any) => {
        this.topBarCountReferee = res.TopBarCount;
        this.onClick(this.cardNumber);
      },
      (error) => {
        this.handleError.handleError(error);
      }
    );
  }
  getUniversalRecords(cardValue) {
    this.filterString = "";
    let obj;
    this.leadTypereferee = cardValue;
    this.page = 1;
    obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: this.leadTypereferee,
      start: this.startDate,
      end: this.endDate,
      page: this.page,
    };
    this.getLeadsSuccess(obj);
  }
  getLeadsByCards(cardValue) {
    this.filterString = "";
    let obj;
    this.leadTypereferee = cardValue;
    this.page = 1;
    obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: this.leadTypereferee,
      start: this.startDate,
      end: this.endDate,
      page: this.page,
    };
    this.getLeadsSuccess(obj);
    this.getTopBarCount();
  }

  getLeadsSuccess(obj) {
    this.leadsLoading = true;
    this.noLeadsFound = false;
    this.referralList = [];
    this.referralList1 = [];
    this.ReferreeService.getReferralList(obj)
      .toPromise()
      .then(
        (resp: any) => {
          this.referralList = resp;
          this.referralList1 = resp;
          this.referralList.forEach(function (a) {
            Object.keys(a).map((key) => {
              a[key] === null ? (a[key] = "-") : a[key];
              a[key] === "" ? (a[key] = "-") : a[key];
            });
          });
          this.leadsLoading = false;
          if (this.referralList.length <= 0) {
            this.noLeadsFound = true;
          }
        },
        (error) => {

        }
      )
      .catch((err) => {

      });
  }
  onClick(check: any) {
    this.uhleadsservice.pageNumber.next(1);
    this.cardNumber = check;
    switch (check) {
      case 1:
        this.collectionSize = this.topBarCountReferee.total;
        break;
      case 2:
        this.collectionSize = this.topBarCountReferee.referral;
        break;
      case 3:
        this.collectionSize = this.topBarCountReferee.signedUp;
        break;
      case 4:
        this.collectionSize = this.topBarCountReferee.notSignedUp;
        break;
      case 5:
        this.collectionSize = this.topBarCountReferee.assigned;
        break;
      case 6:
        this.collectionSize = this.topBarCountReferee.unassigned;
        break;
      case 7:
        this.collectionSize = this.topBarCountReferee.assignedtoMe;
        break;
      case 8:
        this.collectionSize = this.topBarCountReferee.androidSignup;
        break;
      case 9:
        this.collectionSize = this.topBarCountReferee.iosSignup;
        break;
      case 10:
        this.collectionSize = this.topBarCountReferee.webSignup;
        break;
      case 11:
        this.collectionSize = this.topBarCountReferee.deactivated;
        break;
      default:
        break;
    }
    if (check) {
      this.tab = "tab" + check;
    } else {
      this.tab = "";
    }
  }
  dropdownSelect(event) {
    this.getUniversalRecords(event);
    let payload = {
      status: event,
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
    };
    this.ReferreeService.getCountByCallStatusReferrerLeads(payload).subscribe(
      (data: any) => {

        this.collectionSize = data.count;
      }
    );
  }
  getDownloadLink() {
    this.downloadLink = environment.referreeCSV;
  }
  onInput(event) {
    if (this.filterString != "") {
    } else {

      this.referralList = this.referralList1;
      this.referralList.forEach(function (a) {
        Object.keys(a).map((key) => {
          a[key] === null ? (a[key] = "-") : a[key];
          a[key] === "" ? (a[key] = "-") : a[key];
        });
      });

      this.sortTableData(this.sortColumn, this.sortOrder);
    }
  }

  redirectToUpload() {

    this.router.navigate(["./upload/" + this.projectId]);
  }
  onDIItermsChange(data) {
    this.DIterms = data;
    // 
  }
  onDIYearChange(data) {
    // 
    this.DIyear = data;
  }
  getCheckCallVisibleTiming(data: any) {
    if (moment(data.createdAt).add(48, "hours").format() >= moment().format()) {
      return false;
    } else {
      return true;
    }
  }
  searchLeads() {
    this.referralList = [];
    this.noLeadsFound = false;
    this.leadsLoading = true;
    let payload = {
      ProjectId: this.projectId,
      search: this.filterString,
      page: this.page
    };
    if (this.filterString.length > 0) {
      this.leadTypereferee = 'filter';
      this.ReferreeService.search(payload).subscribe((resp: any) => {
        this.referralList = resp.data;
        this.referralList.forEach(function (a) {
          Object.keys(a).map((key) => {
            a[key] === null ? (a[key] = "-") : a[key];
            a[key] === "" ? (a[key] = "-") : a[key];
          });
        });
        this.collectionSize = resp.totallength;
        this.sortTableData(this.sortColumn, this.sortOrder);
        this.leadsLoading = false;
        if (this.referralList.length <= 0) {
          this.noLeadsFound = true;
        }
      });
    } else {
      this.leadTypereferee = "total";
      this.getTopBarCount();
      this.getLeadsByCards(this.leadTypereferee);
    }
  }

  onPageChange(event: any) {
    this.page = event;
    this.referralList = [];
    this.noLeadsFound = false;
    this.leadsLoading = true;
    if (this.leadTypereferee != "filter") {
      let obj = {
        admin: this.admin,
        mail: this.userData.mail,
        ProjectId: this.projectId,
        leadType: this.leadTypereferee,
        start: this.startDate,
        end: this.endDate,
        page: this.page,
      };
      this.getLeadsSuccess(obj);
    } else {
      let payload = {
        ProjectId: this.projectId,
        search: this.filterString,
        page: this.page
      };
      if(this.filterString.length>0){
        this.ReferreeService.search(payload).subscribe((resp: any) => {
          this.referralList = resp.data;
          this.referralList.forEach(function (a) {
            Object.keys(a).map((key) => {
              a[key] === null ? (a[key] = "-") : a[key];
              a[key] === "" ? (a[key] = "-") : a[key];
            });
          });
          this.sortTableData(this.sortColumn, this.sortOrder);
          this.leadsLoading = false;
          if (this.referralList.length <= 0) {
            this.noLeadsFound = true;
          }
        });
      } else{
        this.leadTypereferee = "total";
      }
    }
    
  }
}
