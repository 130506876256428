<style>
  :host::ng-deep .multiselect-dropdown {
    width: 150px;
    background: none !important;
    color: #fff;
    /* xborder: 1px solid #59758b; */
  }
  :host::ng-deep .dropdown-btn {
    border: 1px solid #1d426c !important;
    font-size: 12px;
  }
  :host::ng-deep .dropdown-btn span {
    font-size: 11px;
  }
  .filter-textbox > input{background:red!important;}

</style>
<div class="text-left px-3 pt-3 " >
  <app-project-change (projectChange)="projectChange($event)"></app-project-change>
</div>
<div class="containers p-3">
  <div class="content-container">
    <div
    
    class="d-flex justify-content-end mb-2 mt-2" style="cursor: pointer">
      <i ngbTooltip="Settings"
      placement="top"
      tooltipClass="my-custom-class" class="fa fa-cog" style="color: #999" (click)="open(content)"></i>
    </div>
    <div
      class="table-scroll ctlist data-table2"
      *ngIf="loading.displayColumns && loading.remainingColumns"
    >
      <table class="table table-md">
        <thead>
          <tr>
            <td
              [attr.colspan]="selectedColumns.length"
              style="padding: 0 0.75rem; background: var(--primary-color);"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 48px;
                "
              >
                <div style="display: flex" *ngIf="admin">
                  <div style="width: 10rem">
                    <ng-multiselect-dropdown
                    [settings]="dropdownSettings"
                      [data]="contacts"
                      [(ngModel)]="selectAgent"
                      (onSelect)="selectedAgent()"
                      placeholder="Select Agent"
                      class="drop-selectcccc"
                      style="padding: 5px; font-size: 13px; color: #000"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="searchall-parent" *ngIf="!admin">
                  <input
                    type="text"
                    class="form-control search-all"
                    [(ngModel)]="search"
                    placeholder="search..."
                  />
                  <a class="search-icon-link" href="javascript:void(0);"
                    ><img
                      alt=""
                      class="search-icon-img"
                      src="./assets/img/search (5).svg"
                  /></a>
                </div>
                <div class="d-flex align-items-center">
                  <div class="form-group mx-1" style="margin-bottom: 0">
                    <div class="input-group">
                      <input
                        class="form-control form-select-dark "
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="fromDate"
                        ngbDatepicker
                        #ds="ngbDatepicker"
                        style="font-size: 11px;"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          (click)="ds.toggle()"
                          type="button"
                          style="
                             border:1px solid #1d426c!important
                          "
                        >
                          <img src="../../../assets/img/calendar-icon.svg" class="img12" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mx-1" style="margin-bottom: 0">
                    <div class="input-group">
                      <input
                        class="form-control form-select-dark"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="toDate"
                        ngbDatepicker
                        #dps="ngbDatepicker"
                        style="font-size: 11px;"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-sm btn-outline-secondary"
                          (click)="dps.toggle()"
                          type="button"
                          style="
                            border:1px solid #1d426c!important;
                          "
                        >
                        <img src="../../../assets/img/calendar-icon.svg" class="img12" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mx-1">
                    <div
                      class="btn btn-primary btn-sm"
                      (click)="getDateChange()"
                    >
                      Go
                    </div>
                  </div>
                </div>

                <div class="pagenation-right">
                  <ngb-pagination
                    [collectionSize]="TicketCount"
                    [(page)]="page"
                    [maxSize]="5"
                    [boundaryLinks]="true"
                    (pageChange)="onPageChange()"
                  ></ngb-pagination>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style="background: #eaf5fd; border-bottom: none">
            <th class="dtable-th" *ngFor="let items of selectedColumns">
              <span>
                {{ items | pageheaders }}

                <img
                  class="img7"
                  src="../../../../assets/img/sort-data.svg"
                  style="cursor: pointer"
                  (click)="sortOrder(items)"
                />
              </span>
            </th>
          </tr>

          <tr *ngFor="let item of tickets | sort: orderFeild:orderString">
            <td class="vm"
              *ngFor="let items of selectedColumns; let i = index"
              [ngStyle]="{
                cursor: i === 0 ? 'pointer' : 'text',
                'font-weight': i === 0 ? 'bold' : 'normal'
              }"
              [routerLink]="
                i === 0
                  ? ['/tickets/ticket-view', item['id']]
                  : (route.url | async)
              "
            >
              <span [ngSwitch]="items">
                <span *ngSwitchCase="'CreatedDate'">{{
                  item[items] | date: "medium"
                }}</span>
                <span *ngSwitchCase="'UpdatedDate'">{{
                  item[items] | date: "medium"
                }}</span>
                <span
                  *ngSwitchCase="'summary'"
                  ngbTooltip="{{ items[item] }}"
                  >{{ item[items].slice(0, 30) + "..." }}</span
                >

                <span
                  *ngSwitchCase="'RequestId'"
                  style="color: #163252; text-decoration: none"
                  ><img class="img12"
                    style="margin-right: 10px"
                    [src]="'assets/dashboard/' + item['TicketChannel'] + '.svg'"
                  />{{ item[items] }}</span
                >

                <div
                  *ngSwitchCase="'RequestStatus'"
                  class="status-{{ item['RequestStatus'] }}"
                  >{{ item[items] | requeststatus }}</div
                >
                <span *ngSwitchCase="'Assignedto'" class="text-center">{{
                  item[items] || "-"
                }}</span>
                <span *ngSwitchCase="'group'">{{ item[items] }}</span>
              </span>
            
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div style="float: left; margin-left: 20px" class="page-select m-2">
				<label>Show : </label>
				<select
					aria-labelledby="dropdownConfig"
					[(ngModel)]="pageSize"
					class="mx-2"
				>
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="25">25</option>
				</select>
			</div> -->
    </div>
  </div>
 
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Headers</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="height: 225px">
    <div class="row">
      <div class="dual-select my-2">
        <div class="dual-select__left">
          <div class="mb-2 form-label">Select Columns</div>
          <ul class="dual-select__items">
            <li
              *ngFor="let contact of unSelectedColumns"
              (dblclick)="addToSelectedColumns(contact)"
              class="dual-select__item"
            >
              <div class="contact">
                <div class="contact__name">
                  {{ contact | pageheaders }}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="dual-select__controls">
          <button class="dual-select__control" type="button">
            <i class="fa fa-arrow-right" style="color: #999"></i>
          </button>
          <button class="dual-select__control" type="button">
            <i class="fa fa-arrow-left" style="color: #999"></i>
          </button>
        </div>
        <div class="dual-select__right">
          <div class="mb-2 form-label">Displayed Columns</div>
          <ul
            class="dual-select__items"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
          >
            <li
              *ngFor="let contact of selectedColumns"
              (dblclick)="removeFromColumns(contact)"
              class="dual-select__item dual-select__item--new"
              cdkDrag
            >
              <div class="contact">
                <div class="contact__name">
                  {{ contact | pageheaders }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="updateColumns()" class="btn orange-btn">Update</button>
  </div>
</ng-template>