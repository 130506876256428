import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MarketingService } from "../pages/marketing/marketing.service";

@Component({
  selector: "app-mail-response",
  templateUrl: "./mail-response.component.html",
  styleUrls: ["./mail-response.component.css"],
})
export class MailResponseComponent implements OnInit {
  interview: any;
  constructor(
    private route: ActivatedRoute,
    private marketingService: MarketingService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.interview = data;
      this.marketingService
        .updateMailFeedback({
          Feedback: data["response"],
          EmailReferenceId: data["refid"],
          flag: "EmailGather",
        })
        .subscribe();
      // this.http.put(``)
    });
  }
}
