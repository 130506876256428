import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TransfersService } from '../transfers.service';
import {
  countUpTimerConfigModel,
  CountupTimerService,
  timerTexts,
} from "ngx-timer";
import { SharedService } from 'src/app/shared/shared.service';
import { DOCUMENT } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UhLeadsService } from '../../uhleads.service';
import { CreateAgent } from '../../create-agent/create-agent.service';
import { now } from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentFormatService } from 'src/app/moment-format-service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MarketingService } from '../../marketing/marketing.service';
import { environment } from 'src/environments/environment';
import { RoleService } from '../../role-management/role.service';
import * as xlsx from "xlsx";
import { Exceldata2 } from '../../marketing/smsmarketing/models/ExecelData.model2';
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";
import { ErrorHandlerService } from 'src/app/error-handler.service';
var sortJsonArray = require('node_modules/sort-json-array')

export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = "^[a-zA-Z /'.-]*$",
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {

  public fileSource = environment.fileServer;
  public page: any = 1;
  public pageSize: any = 12;
  totalCount: any;
  studentsList: any[] = [];
  leadsLoading: boolean = true;
  noLeadsFound: boolean = false;
  sidenavLoading: boolean = false;
  selectedAll: boolean = false;
  admin: boolean = true;
  startCall: boolean = false;
  validationStatus: boolean = false;
  showDate: boolean = false;
  public edited: boolean = false;
  // CALLING THINGS
  applicationStatus: any = "";
  dialerScreen1 = "dialer";
  callStatus: string = "Calling..";
  newStatus: any;
  public callData: any[] = [];
  selectedLead: any;
  public marketingId: {
    id: string;
    phoneNumber: string;
    email: string;
    name: string;
    nextCaller: any;
    lead: any;
  } = {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
      nextCaller: null,
      lead: {},
    };
  nextCallerName: any;
  nextCallerCity: any;
  nextCallerNumber: any;
  public startIndex: number = 0;
  testConfig: any;
  agentCall: String = "";
  sideViewerData: any;
  activityfilterString: string = "";
  file: File;
  customDocName: string;
  minItems = 5;
  docUrl: any;
  topBarCounts = {
    validatedDocs: "0",
    completedDocs: "0",
    studentDenied: "0",
    reopen: "0",
    total: "0",
    revalidatedDocs: "0",
    pendingDocs: "0",
    documentsSubmitted: "0",
    unassigned: "0",
    assigned: "0",
    assignedtoMe: "0",
    uncontactable: "0",
  };
  userData: any;
  documentComments: string;
  leadIds = [];
  defaultAgent = "default";
  selectedAgent: any;
  @ViewChild("closeButton") closeButton;
  @ViewChild("uploadFile") uploadFile;
  agentList: any;
  projectId: any;
  totalAgents: any;
  GBPagentList: any;
  private colors = [
    [{ bg: "blueviolet", color: "white" }],
    [{ bg: "AntiqueWhite", color: "white" }],
    [{ bg: "Coral", color: "white" }],
    [{ bg: "Crimson", color: "white" }],
    [{ bg: "DarkOrange", color: "white" }],
    [{ bg: "DeepPink", color: "white" }],
    [{ bg: "#c5924e", color: "white" }],
  ]
  activitiesList: any;
  thresItem: any;
  thresId: any;
  thresModal: NgbModalRef;
  selectedUniversity: string;
  commentText: string = "";
  startDate: any;
  endDate: any;
  date1: any;
  date2: any;
  public filterValue: string = "all";
  public leadType: string = "all";
  search: string = '';
  tab: any;
  @ViewChild("closeButton2") closeButton2;
  @ViewChild("closeAssignModel") closeAssignModel;
  public statusValue = "pending";
  studentsList1: any;
  totalCount1: number;
  editStatus: string;
  leadtoEdit: {
    id: string;
    firstName: string;
    lastName: string;
    phoneCountryCode: string;
    phoneWithoutCode: string;
    emailId: string;
    dateOfBirth: Date;
    address: string;
    cityOrTown: string;
    state: string;
    zip: string;
    country: string;
    Comments: string;
    CallStatus: string;
    AgentMail: string;
  };
  editComment: string;
  editLead = this.formBuilder.group({
    firstName: ["", [Validators.required]],
    lastName: ["", [Validators.required]],
    phoneCountryCode: ["", [Validators.required]],
    phoneWithoutCode: ["", [Validators.required]],
    emailId: ["", Validators.required],
    dateOfBirth: [""],
    address: [""],
    cityOrTown: [""],
    state: [""],
    zip: [""],
    country: [""],
    Comments: [""],
    CallStatus: [""],
  });
  downloadLink: any;
  companyId: any;
  public filePath: any;
  public fileData: Exceldata2[] = [];
  public uploaded: boolean = false;
  public error: boolean = false;
  public insertCount: number = 0;
  public errorMessage: string = "";
  public submitted: boolean = false;
  pageNumber: Number = 1;
  isSearch: boolean = false;
  createLead: FormGroup;

  // CREATE LEAD
  public university: string = 'HUB';
  public requiredfieldApplyAs: boolean;
  public requiredfieldApplyFor: boolean;
  public ifFromUs: any;
  public applyAsStudent = ['High School Graduate Student', 'Undergraduate Student', 'Graduate Student', 'Others'];
  public fromUsYesApplyFor = ['Undergrad Transfer Program', 'Graduate Transfer Program', 'Second Masters', 'Others'];
  public fromUsNoApplyFor = ['International Program', 'Others'];

  @ViewChild("inputString", { static: true }) input: ElementRef;
  docUploadID: any;
  docUploadStatus: boolean = false;
  uploadSuccess: string = "none";
  selectedFiles: File[] = [];
  docUploadError2: string = "Select files to upload.";
  currentLeadId: any;
  currentItem: any;
  sortColumn = "updatedAt";
  sortOrder = "asc";
  callSid: any;

  constructor(
    private transferService: TransfersService,
    public countupTimerService: CountupTimerService,
    private SharedService: SharedService,
    @Inject(DOCUMENT) private domService: HTMLDocument,
    private readonly modalService: NgbModal,
    private uhleadsservice: UhLeadsService,
    public sharedService: SharedService,
    private agentService: CreateAgent,
    private momentService: MomentFormatService,
    private readonly formBuilder: FormBuilder,
    public marketingService: MarketingService,
    private companyService: RoleService,
    private handleError: ErrorHandlerService,
  ) {
    this.testConfig = new countUpTimerConfigModel();
    this.testConfig.timerClass = "test_Timer_class";
    this.testConfig.timerTexts = new timerTexts();
    this.testConfig.timerTexts.hourText = ":"; //default - hh
    this.testConfig.timerTexts.minuteText = ":"; //default - mm
    this.testConfig.timerTexts.secondsText = " ";
  }

  ngOnInit(): void {

    this.SharedService.pageEvent.emit({
      pageName: "Transfer Students",
    });

    this.getAgentNames();
    this.initCreateLead();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.date1 = new FormControl(new Date());
    this.date2 = new FormControl(new Date(Date.now() - 864e5));
    this.filterValue == "24hrs";
    var today = new Date();
    this.endDate = new Date(Date.now());
    this.startDate = new Date(Date.now() - 864e5 * 365);

    this.startDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() - 864e5 * 365)).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(new Date(Date.now() + 864e5))
    );
    this.companyId = this.userData["companyId"];
    setTimeout(() => {

      this.companyService.projectAdmin(this.companyId).subscribe((projects) => {
        this.projectId = projects.filter(x => x.Name.includes('Transfer'))[0].id;

        this.getDownloadLink();
        this.transferService.startTimer.subscribe((res) => {
          this.callStatus = "inProgress";
          this.startTimer();
        });
        this.uhleadsservice
          .getAgentList(this.projectId, this.userData.id)
          .subscribe((data: any) => {
            this.agentList = data;
          }, (error) => {
            this.handleError.handleError(error)
          });
        this.fetchStudents();
        this.getTopBarCounts();
        this.transferService.pageNumber.subscribe((resp: any) => {
          if (resp) {
            this.page = resp;
            
            if (this.isSearch === true) {
              this.transferSearch1();
            } else {
              this.fetchStudents();
              this.getTopBarCounts();
            }

          }
        });
        // server-side search
        fromEvent(this.input.nativeElement, "keyup")
          .pipe(
            filter(Boolean),
            debounceTime(1500),
            distinctUntilChanged(),
            tap((event: KeyboardEvent) => {
              this.search = this.input.nativeElement.value;
              this.pageNumber = 1;
              // 
              // 
              // this.searchLeads(this.input.nativeElement.value, this.pageNumber);
              this.transferSearch(this.input.nativeElement.value, this.page)
            })
          )
          .subscribe();
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, 3000);
    
    this.transferService.getCallSid.subscribe((data) => {
      this.callSid = data;
    })
  }
  startTimer() {
    let cdate = new Date();
    cdate.setHours(cdate.getHours());
    this.countupTimerService.startTimer(cdate);
  }
  fetchStudents() {
    this.studentsList = []
    this.leadsLoading = true;
    this.noLeadsFound = false;
    var obj = {
      admin: this.admin,
      mail: this.userData.mail,
      ProjectId: this.projectId,
      leadType: this.leadType,
      start: this.startDate,
      end: this.endDate,
      page: this.page
    }
    this.transferService.fetchStudents(obj).toPromise()
      .then((resp: any) => {
        this.leadsLoading = false;
        this.totalCount = parseInt(resp.count[0].count);
        this.totalCount1 = parseInt(resp.count[0].count);
        
        this.transferService.totalPages.next(this.isFloat(Number(this.totalCount) / 12));
        if (resp.count[0].count <= 0) {
          this.noLeadsFound = true;
        }
        this.studentsList = resp.rows;
        this.studentsList1 = resp.rows;
        for (let i = 0; i < this.studentsList.length; i++) {

          if (this.studentsList[i].PhoneNumber == "0000000000") {
            this.studentsList[i].CountryCode = "-";
            this.studentsList[i].PhoneNumber = "-";
          }
          this.studentsList[i].AssignedtoName = this.getAgentName(
            this.studentsList[i].Assignedto
          );

          this.studentsList[i].AssignedtoInitial = this.getAgentInitial(
            this.studentsList[i].Assignedto
          );
          this.studentsList[i].AssignedtoColor = this.getAgentColor(
            this.studentsList[i].Assignedto
          );
          this.studentsList[i].AssignedtoImageURL = this.getAgentphoto(
            this.studentsList[i].Assignedto
          );
          if (
            this.studentsList[i].LeadDocStatus == "CRM-Revalidate" ||
            this.studentsList[i].LeadDocStatus == "Admin-Revalidate" ||
            this.studentsList[i].LeadDocStatus == "Admin-revalidate" ||
            this.studentsList[i].LeadDocStatus == "CRM-revalidate"
          ) {
            this.studentsList[i].LeadDocStatus = "Revalidate";
          }
        }

      })
      .catch(err => {
        this.leadsLoading = false;
        this.noLeadsFound = true;
      })
  }
  // loadPage(ev) {
  //   
  //   this.page = ev;
  //   if (this.search.length > 0) {
  //     this.isSearch = true;
  //     this.transferSearch();
  //   } else {
  //     this.isSearch = false;
  //     this.fetchStudents();
  //   }
  // }
  startCallListener2(data, index) {

    this.applicationStatus = "";

    this.dialerScreen1 = "outgoing1";
    this.callStatus = "Calling..";
    $("input[name=option1]").prop("checked", false);

    this.newStatus = false;

    this.callData[0] = data;
    this.selectedLead = data;
    this.selectedUniversity = data.university;
    this.marketingId = {
      id: data.id,
      name: data.firstName,
      email: data.emailId,
      phoneNumber: "+" + data.phoneCountryCode + data.phoneWithoutCode,
      nextCaller: 1,
      lead: 0,
    };


    // this.nextCallerName = this.studentsList[index + 1].firstName;
    // this.nextCallerNumber = "+" + this.studentsList[index + 1].phoneCountryCode + this.studentsList[index + 1].phoneWithoutCode;
    // this.startIndex = index + 1;

    this.transferService.startCall.next({
      phoneNumber: this.marketingId.phoneNumber,
      leadData: this.marketingId,
    });
    this.startCall = true;
  }
  stopCurrentCall() {
    
    this.transferService.startCall.next(null);
    this.transferService.stopCall.next({
      phoneNumber: this.marketingId.phoneNumber,
    });
    this.callStatus = "Call Ended";
  }
  sideNavData(id) {
    this.sidenavLoading = true;
    this.sideViewerData = [];
    this.activitiesList = [];
    this.activityfilterString = "";
    this.file = null;
    this.customDocName = "";
    this.minItems = 5;
    var x = document.getElementsByClassName(
      "sideDataViewer"
    ) as HTMLCollectionOf<HTMLElement>;
    //  x.style.display = none;
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = "";
    }
    this.transferService.getLeadById(id).toPromise()
      .then((resp: any) => {
        this.sideViewerData = resp;
        this.currentLeadId = this.sideViewerData.id;
        this.replaceNull(this.sideViewerData);
        
        this.sidenavLoading = false;
        this.checkValidation();
        this.activitiesList = resp.Activities;
      })
      .catch(err => {

      })
  }
  audioPlay(e) {
    let eAudio = this.domService.getElementsByTagName("audio");
    if (eAudio && eAudio.length > 0) {
      for (var i = 0; i < eAudio.length; i++) {
        if (e.target !== eAudio[i]) {
          eAudio[i].pause();
        }
      }
    }
  }
  pauseAudio(data) {
    // audio.stop();
  }
  replaceNull(a: any) {
    Object.keys(a).map((key) => {
      a[key] === null || a[key] === undefined ? (a[key] = "-") : a[key];
    });
  }
  changeValidation(id, value, docId) {
    this.transferService.updateValidationStatus({
      StudentdocId: docId,
      admin: false,
      validationStatus: value,
      Platform: "CRM",
      MailId: this.userData.mail,
    }).toPromise()
      .then((resp: any) => {
        this.sideNavData(id);
      })
      .catch(err => {

      })
  }
  postComments(sideViewerData, item, HTMLid) {
    this.documentComments = (<HTMLInputElement>(
      document.getElementById(HTMLid)
    )).value;
    var body = {
      ProjectId: 8,
      salesforceId: sideViewerData.salesforceId,
      StudentDocId: item.StudentdocId,
      Text: this.documentComments,
      Admin: false,
      // Admin: false,
      LeadId: sideViewerData.id,
      AgentMail: this.userData.mail,
      Platform: "CRM",
    };

    if (HTMLid == "leadComments") {
      body.StudentDocId = null;
    }
    this.transferService.updateComments(body).toPromise()
      .then((resp: any) => {
        this.sideNavData(sideViewerData.id);
        this.uhleadsservice.showSuccess("Comment Posted!", "Success!");
        (<HTMLInputElement>document.getElementById(HTMLid)).value = "";
      })
  }
  openPreview(content, url) {
    // this.docUrl = '';
    this.docUrl = url;
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "myCustomModalClass",
      })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
  contentLoaded() {
    document.getElementById("progressBar").style.display = "none";
  }
  checkValidation() {
    if (this.sideViewerData.uploadlistofdocuments.length == 0) {
      this.validationStatus = false;
      return;
    }
    for (var i = 0; i < this.sideViewerData.uploadlistofdocuments.length; i++) {
      if (
        this.sideViewerData.uploadlistofdocuments[i].AgentValidation == "revalidate" ||
        this.sideViewerData.uploadlistofdocuments[i].AgentValidation == "-"
      ) {
        this.validationStatus = false;
        return;
      } else if (this.sideViewerData.uploadlistofdocuments[i].AgentValidation == "validated") {
        this.validationStatus = true;
        //AddNotification
      } else {
        this.validationStatus = false;
      }
    }
  }
  selectAllforAssignment() {
    if (this.selectedAll == false) {
      this.selectedAll = true;
      this.leadIds = [];
      for (let i = 0; i < this.studentsList.length; i++) {
        this.leadIds.push(this.studentsList[i].id);
      }
    } else if (this.selectedAll == true) {
      this.selectedAll = false;
      this.leadIds = [];
    }
  }
  selectAgentforAssignment(e, item) {
    if (e.target.checked) {
      this.leadIds.push(item.id);
    } else if (!e.target.checked) {
      this.removeItem(this.leadIds, item.id);
    }
  }
  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  assignAgentModal() {
    this.defaultAgent = "default";
    this.selectedAgent = null;
  }
  agentSelected(selectedAgent) {
    this.selectedAgent = selectedAgent;
  }
  assignLEads() {
    if (this.leadIds.length == 0) {
      this.uhleadsservice.showWarning("Select leads to be assigned", "Alert!");
      return;
    }
    if (this.selectedAgent) {
      this.transferService
        .assignTransferLead({
          AssignedTo: this.selectedAgent,
          InsertedBy: this.userData.mail,
          LeadIds: this.leadIds,
        })
        .subscribe((res) => {
          this.getTopBarCounts();
          this.fetchStudents();
          this.uhleadsservice.showSuccess(
            "Successfully assigned the agents",
            "Success"
          );
          this.selectedAll = false;
          this.closeButton.nativeElement.click();
          document.getElementById("closeAssignButton").click();
        }, (error) => {
          this.handleError.handleError(error)
        });
    } else {
      this.uhleadsservice.showWarning("Please select an agent", "Alert!");
    }
  }
  getAgentName(email) {
    let name = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        name =
          this.totalAgents[k].FirstName + " " + this.totalAgents[k].LastName;
        return name;
      }
    }
  }
  getAgentInitial(email) {
    let initial = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        initial =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);
        return initial;
      }
    }
  }

  getAgentColor(email) {
    let color = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        color =
          this.totalAgents[k].FirstName.charAt(0) +
          this.totalAgents[k].LastName.charAt(0);

        let randomIndex =
          this.colors[
          this.numberFromText(this.getAgentInitial(email)) %
          this.colors.length
          ];
        color = randomIndex[0]["bg"];
        return color;
      }
    }
  }
  getAgentphoto(email) {
    let imageUrl = "";
    for (var k = 0; k < this.totalAgents.length; k++) {
      if (this.totalAgents[k].Mailid == email) {
        imageUrl = this.totalAgents[k].ProfilePhoto;
        return imageUrl;
      }
    }
  }
  numberFromText(text) {
    const charCodes = text
      .split("") // => ["A", "A"]
      .map((char) => char.charCodeAt(0)) // => [65, 65]
      .join(""); // => "6565"
    return parseInt(charCodes, 10);
  }

  getAgentNames() {
    this.agentService.getAgentNames().subscribe((data) => {
      this.totalAgents = data;

      this.uhleadsservice.getGBPAgentList().subscribe((res: any) => {
        this.GBPagentList = res;
        for (let i = 0; i < this.GBPagentList.length; i++) {
          this.totalAgents.push({
            FirstName: this.GBPagentList[i].FIRST_NAME,
            LastName: this.GBPagentList[i].LAST_NAME,
            Mailid: this.GBPagentList[i].EMAIL_ADDRESS,
            ProfilePhoto: this.GBPagentList[i].ProfilePhoto,
          });
        }
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  onInputActivity(event: any) {
    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }
  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }
  applicationstatus(data) {
    this.applicationStatus = data;
    if (this.applicationStatus == "Yes") {
    }
  }
  onStatusChange(data) {
    this.newStatus = data;
  }
  resetCallingScreen() {
    this.commentText = "";
    this.newStatus = null;
    this.applicationStatus = '';
  }
  updateComments() {
    if (!this.newStatus) {
    } else {
        if (this.applicationStatus == "yes") {
          this.transferService.updateCallComment({
            id: this.selectedLead.id,
            comments: this.commentText,
            callStatus: this.newStatus,
            CallSid: this.callSid,
            followupDate: new Date(now()),
            ApplicationStatus: this.applicationStatus,
            University: this.selectedUniversity,
          }).subscribe((res) => {
            this.commentText = "";
            this.getTopBarCounts();
            this.fetchStudents();
            this.closeButton2.nativeElement.click();
          }, (error) => {
            this.handleError.handleError(error)
          });
        } else {
          this.selectedUniversity = "";

          this.transferService.updateCallComment({
            id: this.selectedLead.id,
            comments: this.commentText,
            callStatus: this.newStatus,
            CallSid: this.callSid,
            followupDate: new Date(now()),
            ApplicationStatus: this.applicationStatus,
            University: this.selectedUniversity,
          }).subscribe((res) => {
            this.commentText = "";
            this.getTopBarCounts();
            this.fetchStudents();
            this.closeButton2.nativeElement.click();
          }, (error) => {
            this.handleError.handleError(error)
          });
        }
    }
  }
  getTopBarCounts() {
    var postData = {
      ProjectId: this.projectId,
      start: this.startDate,
      end: this.endDate,
      AgentMail: this.userData.mail,
    };
    this.transferService.getTopBarCount(postData).toPromise()
      .then((resp: any) => {
        this.topBarCounts = resp.TopBarCount;
      })
      .catch(err => {
        this.handleError.handleError(err)

      })
  }
  onClick(check) {
    if (check == 1) {
      this.tab = "tab1";
    } else if (check == 2) {
      this.tab = "tab2";
    } else if (check == 3) {
      this.tab = "tab3";
    } else if (check == 4) {
      this.tab = "tab4";
    } else if (check == 5) {
      this.tab = "tab5";
    } else if (check == 6) {
      this.tab = "tab6";
    } else if (check == 7) {
      this.tab = "tab7";
    } else if (check == 8) {
      this.tab = "tab8";
    } else if (check == 9) {
      this.tab = "tab9";
    } else if (check == 10) {
      this.tab = "tab10";
    } else if (check == 11) {
      this.tab = "tab11";
    } else if (check == 12) {
      this.tab = "tab12";
    } else if (check == 13) {
      this.tab = "tab13";
    } else {
      this.tab = null;
    }
  }
  getCardData(leadType) {
    this.leadType = leadType;
    this.fetchStudents();
  }
  hideDate() {
    // this.filterValuev
    if (this.filterValue == "custom" && this.showDate == false) {
      this.showDate = true;
    }
  }
  updateDateFilter(event: any) {
    if (event.target.value != "custom") {
      this.endDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now())).getTime()
      );
    }
    if (event.target.value == "custom") {
      //show custom date filter
      this.showDate = true;
    } else if (event.target.value == "all") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 365)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "24hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "72hrs") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 3)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "7days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 7)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "14days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 14)).getTime()
      );

      this.showDate = false;
    } else if (event.target.value == "30days") {
      this.startDate = this.momentService.dashboardDateFormat(
        new Date(new Date(Date.now() - 864e5 * 30)).getTime()
      );

      this.showDate = false;
    }
    this.getTopBarCounts();
    this.fetchStudents();
  }
  getStartDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.startDate).getTime
    );
  }
  getEndDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.endDate).getTime
    );
  }
  //Date Filter
  filterData() {
    this.onClick(null);
    this.startDate = this.momentService.dashboardDateFormat(
      new Date(this.date2.value).getTime()
    );
    this.endDate = this.momentService.dashboardDateFormat(
      new Date(this.date1.value).getTime()
    );

    if (this.endDate < this.startDate) {
      // window.alert("Choose the right date format!");
      this.uhleadsservice.showWarning(
        "End date cannot be less than Start date!",
        "Wrong Date!"
      );
      return;
    } else {
      this.showDate = false;
      this.getTopBarCounts();
      this.fetchStudents();
    }
  }
  dropdownSelect(event) {
    this.onClick(null);
    this.leadType = event;
    this.getTopBarCounts();
    this.fetchStudents();
  }
  transferSearch(search, page) {
    let obj = {
      page: page,
      search: search,
      ProjectId: this.projectId
    }
    this.transferService.transferSearch(obj).toPromise()
      .then((resp: any) => {
        this.studentsList = resp.data;
        this.totalCount = parseInt(resp.totalResults);
        this.transferService.totalPages.next(~~(Number(this.totalCount) / 12) + 1);
        for (let i = 0; i < this.studentsList.length; i++) {

          if (this.studentsList[i].PhoneNumber == "0000000000") {
            this.studentsList[i].CountryCode = "-";
            this.studentsList[i].PhoneNumber = "-";
          }
          this.studentsList[i].AssignedtoName = this.getAgentName(
            this.studentsList[i].Assignedto
          );

          this.studentsList[i].AssignedtoInitial = this.getAgentInitial(
            this.studentsList[i].Assignedto
          );
          this.studentsList[i].AssignedtoColor = this.getAgentColor(
            this.studentsList[i].Assignedto
          );
          this.studentsList[i].AssignedtoImageURL = this.getAgentphoto(
            this.studentsList[i].Assignedto
          );
          if (
            this.studentsList[i].LeadDocStatus == "CRM-Revalidate" ||
            this.studentsList[i].LeadDocStatus == "Admin-Revalidate" ||
            this.studentsList[i].LeadDocStatus == "Admin-revalidate" ||
            this.studentsList[i].LeadDocStatus == "CRM-revalidate"
          ) {
            this.studentsList[i].LeadDocStatus = "Revalidate";
          }
        }
      })
      .catch(err => {
        this.handleError.handleError(err)

      })
  }
  onInput(ev) {
    if (this.search.length > 0) {
      this.isSearch = true;
      // this.transferSearch();
    } else {
      this.isSearch = false;
      this.studentsList = this.studentsList1;
      this.totalCount = this.totalCount1;
      this.page = 1;
    }
  }
  openEditLead(content, lead) {
    if (lead.DOB == "-") {
      lead.DOB = "";
    }

    this.editStatus = lead.CallStatus;
    this.leadtoEdit = {
      id: lead.id,
      firstName: lead.firstName,
      lastName: lead.lastName,
      phoneCountryCode: lead.phoneCountryCode,
      phoneWithoutCode: lead.phoneWithoutCode,
      emailId: lead.emailId,
      dateOfBirth: lead.dateOfBirth,
      address: lead.address,
      cityOrTown: lead.cityOrTown,
      state: lead.state,
      zip: lead.zip,
      country: lead.country,
      Comments: this.editComment,
      CallStatus: this.editStatus,
      AgentMail: this.userData.mail,
    };

    this.modalService.open(content, { size: "lg" });
  }
  updateLead() {
    this.edited = true;
    if (this.editLead.invalid) {
      return;
    }

    this.leadtoEdit.phoneCountryCode = this.leadtoEdit.phoneCountryCode.replace("+", "");
    if (this.leadtoEdit.phoneCountryCode == "-") {
      this.leadtoEdit.phoneCountryCode = "00";
    }
    if (this.leadtoEdit.phoneWithoutCode == "-") {
      this.leadtoEdit.phoneWithoutCode = "0000000000";
    }
    var keys = Object.keys(this.leadtoEdit);
    keys.forEach((a) => {
      if (
        this.leadtoEdit[a] === "-" ||
        this.leadtoEdit[a] === "" ||
        this.leadtoEdit[a] === undefined
      ) {
        this.leadtoEdit[a] = null;
      }
    });

    this.transferService.updateLead(this.leadtoEdit).subscribe((res: any) => {
      this.uhleadsservice.showSuccess("Student details updated", "Success!");
      this.edited = false;

      document.getElementById("closeButton3").click();
      // this.getCounts(this.userData, "all", this.startDate, this.endDate, "all");
      this.getTopBarCounts();
      this.fetchStudents();
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  getDownloadLink() {
    this.marketingService
      .getExcelLink(this.fileSource + "/TemplateDocuments/transfer.xlsx")
      .subscribe((data) => {
        this.downloadLink = data["url"];
      }, (error) => {
        this.handleError.handleError(error)
      });
  }
  convertFile(e) {
    this.filePath = e.target.files[0]["name"].toString();
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (eve) => {
      let data = new Uint8Array(reader.result as any);
      let wb = xlsx.read(data, { type: "array" });
      let sheet_name = wb.SheetNames;
      let sheet_data: any[][] = xlsx.utils.sheet_to_json(
        wb.Sheets[sheet_name[0]],
        { header: 1 }
      );
      if (sheet_data[0][0] === "GUID" && sheet_data.length !== 0) {
        this.fileData = this.uhleadsservice.convert2dArraytoObjectTransfer(sheet_data);
        this.transferService
          .postCallData({
            data: this.fileData,
            createdBy: this.userData["mail"],
            ProjectId: this.projectId,
            addType: 'Excel'
          })
          .subscribe((data) => {
            this.uploaded = true;
            this.error = false;
            this.insertCount = data["count"];
            setTimeout(() => {
              this.uploaded = false;
              this.filePath = "";
            }, 5000);
            this.getTopBarCounts();
            this.fetchStudents();
          }, (error) => {
            this.handleError.handleError(error)
          });
      } else {
        this.error = true;
        this.errorMessage = "Please Upload a Valid File";
      }
    };
    this.uploadFile.nativeElement.value = "";
  }
  getFirstLetter(str) {
    if (str) {
      return str.split(' ').map(function (item) { return item[0] }).join('')
    } else {
      return '-'
    }
  }
  currPage(ev) {

  }
  openCreateLead(content) {
    //   gtag('event', 'add_new_lead', {
    //     page_path: window.location.href,
    //     page_title:'Leads Dashboard'
    //  })
    this.modalService.open(content, { size: "md" });
  }
  initCreateLead() {
    this.createLead = this.formBuilder.group({
      emailId: [null, Validators.compose([
        Validators.required,
        Validators.pattern(REGEX.EMAIL),
      ])],
      firstName: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(REGEX.CHARACTER)]),
      fromUS: new FormControl(true),
      ApplyingAs: new FormControl('', Validators.required),
      othersApplyingAs: new FormControl('', Validators.required),
      ApplyingFor: new FormControl('', Validators.required),
      othersApplyingFor: new FormControl('', Validators.required),
      phoneCountryCode: new FormControl('', [Validators.required]),
      phoneWithoutCode: new FormControl('', [Validators.required, Validators.pattern(REGEX.NUMBER)]),
    });
  }
  get f() {
    return this.createLead.controls;
  }
  onCreateLead() {
    if (this.createLead.invalid) {
      return;
    }
    let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    let guid = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join('-');
    var postData = this.createLead.value;
    postData.uniqueId = guid;
    postData.university = this.university;
    this.transferService.createLeadCRM(postData).toPromise()
      .then((resp: any) => {
        this.getTopBarCounts();
        this.fetchStudents();
      })
      .catch(err => {

      })
  }
  public onSelectionChange() {
    
    const applyAs = this.createLead.controls.ApplyingAs.value;
    const applyFor = this.createLead.controls.ApplyingFor.value;
    

    if (applyAs === 'Others') {
      this.requiredfieldApplyAs = true;
      this.createLead.controls.othersApplyingAs.setValidators([Validators.required]);
    } else {
      this.requiredfieldApplyAs = false;
      this.createLead.controls.othersApplyingAs.clearValidators();
      this.createLead.controls.othersApplyingAs.reset();
    }

    if (applyFor === 'Others') {
      this.requiredfieldApplyFor = true;
      this.createLead.controls.othersApplyingFor.setValidators([Validators.required]);
    } else {
      this.requiredfieldApplyFor = false;
      this.createLead.controls.othersApplyingFor.clearValidators();
      this.createLead.controls.othersApplyingFor.reset();
    }
  }
  // fromUs selection changes
  onfromUsSelectionChange() {
    this.createLead.controls.ApplyingFor.reset();
  }

  public onChangeFromUs() {
    this.ifFromUs = this.createLead.controls.fromUS.value;
  }
  transferSearch1() {
    let obj = {
      page: this.page,
      search: this.search,
      ProjectId: this.projectId
    }
    this.transferService.transferSearch(obj).toPromise()
      .then((resp: any) => {
        this.studentsList = resp.data;
        this.totalCount = parseInt(resp.totalResults);
        this.transferService.totalPages.next(~~(Number(this.totalCount) / 12) + 1);
        for (let i = 0; i < this.studentsList.length; i++) {

          if (this.studentsList[i].PhoneNumber == "0000000000") {
            this.studentsList[i].CountryCode = "-";
            this.studentsList[i].PhoneNumber = "-";
          }
          this.studentsList[i].AssignedtoName = this.getAgentName(
            this.studentsList[i].Assignedto
          );

          this.studentsList[i].AssignedtoInitial = this.getAgentInitial(
            this.studentsList[i].Assignedto
          );
          this.studentsList[i].AssignedtoColor = this.getAgentColor(
            this.studentsList[i].Assignedto
          );
          this.studentsList[i].AssignedtoImageURL = this.getAgentphoto(
            this.studentsList[i].Assignedto
          );
          if (
            this.studentsList[i].LeadDocStatus == "CRM-Revalidate" ||
            this.studentsList[i].LeadDocStatus == "Admin-Revalidate" ||
            this.studentsList[i].LeadDocStatus == "Admin-revalidate" ||
            this.studentsList[i].LeadDocStatus == "CRM-revalidate"
          ) {
            this.studentsList[i].LeadDocStatus = "Revalidate";
          }
        }
      })
      .catch(err => {

      })
  }
  downLoadDocument(url, name) {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("type", "hidden");
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  uploadAttachments() {
    
    let check = true;
    this.selectedFiles.forEach((element) => {
      if (this.validateFile(element.type) == false) {
        this.docUploadError2 =
          "One or more files you uploaded is not a valid format. Please upload either doc, pdf, png or JPG/JPEG format.";
        check = false;
        var x = document.getElementsByClassName(
          "docUploadError"
        ) as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < x.length; i++) {
          x[i].style.color = "red";
        }
      }
    });
    if (check == true) {
      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
      this.docUploadError2 =
        "Compressing your documents. Please wait for the process to finish";
      this.docUploadStatus = true;

      let formData: FormData = new FormData();
      formData.append("studentdocid", this.docUploadID);
      Array.from(this.selectedFiles).forEach((file) =>
        formData.append("document", file, file.name)
      );
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);

      this.transferService
        .uploadDocument(formData, this.docUploadID)
        .subscribe((res: any) => {
          // this.uhleadsservice.showSuccess("File upload successful!", "Success");

          if (res.status == 200) {
            
            this.uploadSuccess = "success";
            this.sideNavData(this.currentLeadId);
            this.uhleadsservice.showSuccess(
              "File upload successful!",
              "Success"
            );
            this.docUploadStatus = false;

            // document.getElementById("closeButton4").click();
            try {
              // document.getElementById("closeButton4").click();
            } catch (err) {
              this.uploadSuccess = "failure";
            }
          }
        }, (err) => {
          this.handleError.handleError(err);
          this.uploadSuccess = "failure";
          this.docUploadStatus = false;
          if (err.error.status == 400) {
            this.uhleadsservice.showError(
              `The file ${err.error.file} is corrupted. Please upload valid files .`,
              ""
            );
          } else if (err.error.status == 500) {
            this.uhleadsservice.showError(
              err.error.message,
              ""
            );
          } else {
            this.uhleadsservice.showError(
              'Files not uploaded. Please check document extensions, upload supported documents.',
              ""
            );
          }
        });
    }
  }
  cancelUpload() {
    document.getElementById("closeButton4").click();
  }

  clearSelectedFiles(item) {
    this.docUploadID = item.StudentdocId;
    this.docUploadStatus = false;
    this.selectedFiles = [];
    this.docUploadError2 = "Select files to upload.";
    this.uploadSuccess = "none";

    var x = document.getElementsByClassName(
      "docUploadError"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < x.length; i++) {
      x[i].style.color = "green";
    }
  }
  //Validate Uploaded file
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "image/jpeg") {
      return true;
    } else if (ext.toLowerCase() == "document") {
      return true;
    } else if (ext.toLowerCase() == "image/png") {
      return true;
    } else if (ext.toLowerCase() == "application/msword") {
      return true;
    } else if (ext.toLowerCase() == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      return true;
    } else if (ext.toLowerCase() == "application/pdf") {
      return true;
    } else {
      this.uhleadsservice.showError("Please upload a valid file!", "Error!");
      return false;
    }
  }
  fileUpload(event, item) {
    

    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    if (fileList.length > 0) {
      if (!this.validateFile(file.type)) {
        return;
      }
      let formData: FormData = new FormData();
      formData.append("studentdocid", item.id);
      formData.append("document", file, file.name);
      formData.append("Platform", "CRM");
      formData.append("MailId", this.userData.mail);

      this.transferService
        .uploadDocument(formData, item.id)
        .subscribe((res) => {
          
          this.uhleadsservice.showSuccess("File upload successful!", "Success");
          this.sideNavData({ id: this.currentLeadId });
        }, (error) => {
          this.handleError.handleError(error)
        });
    }
  }
  getFileDetails(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.selectedFiles.push(e.target.files[i]);
    }
    if (this.selectedFiles.length > 0) {
      this.docUploadError2 = "Click on upload to start uploading your files";

      var x = document.getElementsByClassName(
        "docUploadError"
      ) as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < x.length; i++) {
        x[i].style.color = "green";
      }
    }
    e.target.value = '';
  }
  deleteFile(i) {
    this.selectedFiles.splice(i, 1);
  }
  callFromDocs(data, item) {
    this.startCallListener2(data, 0);
    this.currentItem = item;
  }
  sortTableData(column, order) {
    this.studentsList = sortJsonArray(this.studentsList, column, this.sortOrder);

    if (this.sortOrder == 'asc') {
      this.sortOrder = 'des'
    } else {
      this.sortOrder = 'asc';
    }
  }
  isFloat(value) {
    if (
      typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
    ) {
      return ~~value + 1;
    }

    return value;
  }
}